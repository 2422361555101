// 通知管理
<template>
    <div class="manageManage">
      <!-- 卡片视图区 -->
      <el-card>
        <!-- 状态选择区 -->
        <el-row>
          <p style="margin-bottom: 16px; font-size: 16px; font-weight: bold;">通知管理</p>
          <el-button class="createBtn" @click="createOpen()"><i class="el-icon-plus"></i>新增通知</el-button>
        </el-row>
        <!-- 内容搜索区 -->
        <div class="search">
          <el-input placeholder="请输入标题" v-model="manageListData.messageTitle" @keyup.enter.native="getMessageList()"></el-input>
          <i @click="getMessageList()" class="iconfont icon-search"></i>
          <el-button @click="routerJump('manageCenter')">订单通知</el-button>
        </div>
        <!-- 物料定义表格区 -->
        <el-row>
          <el-table tooltip-effect="dark" ref="tableHeight" :max-height="tableHeight" min-height="30px" :data="tableData" style="width: 100%" :default-sort = "{prop: 'date', order: 'descending'}" @row-click="examineManage">
            <el-table-column type="index" label="序号" min-width="18%" align="center">
              <template slot-scope="scope">
                <span style="color: rgba(18, 62, 131, 1)">{{scope.$index + 1}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="messageTitle" label="标题" min-width="50%"></el-table-column>
            <el-table-column show-overflow-tooltip prop="createName" label="创建人" min-width="15%" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="messageCategoryName" label="接收对象类型" min-width="22%" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="publishStatusName" label="状态" min-width="16%" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="30%" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="publishTime" label="发布时间" min-width="16%" align="center">
              <template slot-scope="scope">
                {{scope.row.publishTime === null ? '暂未发布' : scope.row.publishTime}}
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="阅读人数" min-width="22%" align="center">
              <template slot-scope="scope">
                {{'(' + scope.row.hasReadNum + '/' + scope.row.allNum + ')'}}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="prev, pager, next"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="manageListData.size"
            :total="total">
          </el-pagination>
        </el-row>
        <template>
          <el-dialog :title="addMessage === true ? '创建通知' : '修改通知'" :visible.sync="createDialog" :close-on-click-modal="false" class="createDialog" v-dialogDrag>
            <el-input v-model="keepMessageData.messageTitle" placeholder="输入文档标题" style="padding-left: 10px; box-sizing: border-box"></el-input>
            <quill-editor
              ref="myQuillEditor"
              v-model="keepMessageData.messageContent"
              :options="editorOption"
            />
            <p>附件</p>
            <div class="uploadBox">
              <el-upload
                class="upload-demo"
                ref="uploadOrder"
                :action="interAddrss + 'sys/attachment/upload'"
                :headers="headers"
                :data="{type: 'MESSAGE_ATTACHMENT', pid: pid}"
                multiple
                :limit="10"
                :on-success="uploadSuccess"
                :on-preview="downloadFile"
                :before-remove="delFileUpload"
                :show-file-list="true"
                :file-list="fileList">
                <el-button class="uploadBtn" v-show="pid !== '0'"><i class="el-icon-plus"></i></el-button>
              </el-upload>
            </div>
            <p class="acceptType">接收对象类型</p>
            <el-select v-model="keepMessageData.receiveType" placeholder="请选择对象类型" size="mini" @change="chooseObject()">
              <el-option label="客户" :value="1"></el-option>
              <el-option label="供应商" :value="2"></el-option>
              <el-option label="员工" :value="3"></el-option>
            </el-select>
            <p class="acceptType">接收对象清单</p>
            <div class="transferBox">
              <el-transfer v-model="transferValue" :data="transferList" :titles="['待添加清单', '已添加清单']" filterable></el-transfer>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button class="defaultStyle" @click="publishMessage()">发 布</el-button>
              <el-button class="keepBtn" @click="keepMessage()">保 存</el-button>
            </div>
          </el-dialog>
        </template>
        <!-- 查看消息弹出框 -->
        <template>
          <el-dialog title="消息查看" :visible.sync="dialogVisible" :close-on-click-modal="false" class="examineDialog" v-dialogDrag>
            <h5>{{messageDetail.messageTitle}}</h5>
            <div class="ql-editor contentBox" v-html="messageDetail.messageContent"></div>
            <div class="enclosureBox" v-if="messageDetail.attachmentEntityList.length !== 0">
              <p>附件</p>
              <ul>
                <li v-for="(item, index) in messageDetail.attachmentEntityList" :key="index">
                  <div class="prefixBox">{{'.' + item.attachmentType}}</div>
                  <a :href="item.attachmentPath">{{item.attachmentName + '.' + item.attachmentType}}</a>
                  <i class="iconfont icon-download1"></i>
                </li>
              </ul>
              <p>阅读情况</p>
              <el-table tooltip-effect="dark" :data="messageDetail.userMessageList" style="width: 100%">
                <el-table-column show-overflow-tooltip prop="receiveCompanyName" label="所属公司" min-width="50%"></el-table-column>
                <el-table-column show-overflow-tooltip prop="receiveUserName" label="用户姓名" min-width="50%" align="center"></el-table-column>
                <el-table-column show-overflow-tooltip prop="readTime" label="阅读时间" min-width="50%" align="center">
                  <template slot-scope="scope">
                    {{scope.row.readTime === null ? '暂未阅读' : scope.row.readTime}}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-dialog>
        </template>
      </el-card>
    </div>
</template>
<script>
import { publishMessageApi, keepMessageApi, getUserManageApi, getMessageSellListApi, getMessagePurchaseListApi, getMessageManageListApi, examineManage2Api } from '@/api'
export default {
  name: 'manageCenter',
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem('interAddrss'),
      headers: {
        'token': window.$cookies.get('token')
      },
      // 关联数据主键Id
      pid: '-1',
      fileList: [],
      // 获取分页数据
      getUserListData: [],
      // 获取自定义分类数据
      matterCustomData: [],
      // 获取表格数据
      tableData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 展示参数
      manageListData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        messageTitle: ''
      },
      // 存储当前消息详情
      messageDetail: {
        attachmentEntityList: []
      },
      // 获取当前浏览器的宽度
      screenWidth: '',
      // 控制消息查看弹出框
      dialogVisible: false,
      // 创建消息
      createDialog: false,
      // 自定义富文本内容
      editorOption: {
        theme: 'snow',
        placeholder: '点击开始输入正文',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
            [{ script: 'sub' }, { script: 'super' }], // 下角标，上角标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 颜色选择
            [{ align: [] }], // 居中
            [('link', 'image')] // 控制能上传的内容
          ]
        }
      },
      // 穿梭框列表
      transferList: [],
      // 右侧穿梭框数据
      transferValue: [],
      // 获取用户列表传参
      purchaseListData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 999,
        // 搜索内容
        name: '',
        companyId: window.$cookies.get('companyId'),
        state: '1',
        isActivate: '1'
      },
      // 保存消息传参
      keepMessageData: {
        messageTitle: '',
        messageContent: '',
        messageCategory: '',
        messageType: 1,
        userMessageList: [],
        attachmentIdList: [],
        attachmentIdDeleteList: [],
        receiveType: ''
      },
      // 判断是创建消息还是编辑消息
      addMessage: true
    }
  },
  created() {
    this.getMessageList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 255
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 255
      })()
    }
  },
  methods: {
    // 点击附件列表时下载文件
    downloadFile(file) {
      console.log(file, 'hahaha')
    },
    // 发布消息
    async publishMessage() {
      // 先执行保存操作
      if (this.keepMessageData.messageTitle === '') return this.$message.error('文档标题不能为空!')
      if (this.keepMessageData.messageContent === '') return this.$message.error('文档内容不能为空!')
      if (this.transferValue.length === 0) return this.$message.error('接收对象不能为空!')
      this.keepMessageData.userMessageList = this.transferValue
      const { data: res } = await keepMessageApi(this.keepMessageData)
      if (res.code !== 0) return this.$message.error(res.msg)
      // 将消息id保存后
      let messageId = res.data.id
      // 再执行发布
      const { data: value } = await publishMessageApi(messageId)
      if (value.code !== 0) return this.$message.error(value.msg)
      this.createDialog = false
      this.$message.success({ duration: 1000, message: '发布成功!' })
      this.getMessageList()
    },
    // 点击创建时触发
    async createOpen() {
      this.addMessage = true
      // 清空弹框内容再打开
      this.keepMessageData = {
        messageTitle: '',
        messageContent: '',
        messageCategory: '',
        messageType: 1,
        userMessageList: [],
        attachmentIdList: [],
        attachmentIdDeleteList: [],
        receiveType: ''
      }
      // 穿梭框列表
      this.transferList = []
      // 右侧穿梭框数据
      this.transferValue = []
      // 附件
      this.fileList = []
      this.createDialog = true
    },
    // 保存消息
    async keepMessage() {
      if (this.keepMessageData.messageTitle === '') return this.$message.error('文档标题不能为空!')
      if (this.keepMessageData.messageContent === '') return this.$message.error('文档内容不能为空!')
      if (this.transferValue.length === 0) return this.$message.error('接收对象不能为空!')
      this.keepMessageData.userMessageList = this.transferValue
      const { data: res } = await keepMessageApi(this.keepMessageData)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (this.addMessage === true) {
        this.$message.success({ duration: 1000, message: '创建通知成功!' })
      } else {
        this.$message.success({ duration: 1000, message: '修改通知成功!' })
      }
      this.createDialog = false
      this.getMessageList()
    },
    // 选择接收对象
    async chooseObject(editValue) {
      // // 清空穿梭框数据
      this.transferList = []
      this.transferValue = []
      // 选择客户时获取客户列表
      if (this.keepMessageData.receiveType === 1) {
        // 选择客户时是外部通知
        this.keepMessageData.messageCategory = '2'
        const { data: res } = await getMessagePurchaseListApi()
        if (res.code !== 0) return this.$message.error(res.msg)
        let transData = res.data.data
        let newTransList = []
        transData.forEach(item => {
          newTransList.push({
            label: item.receiveCompanyName + ' ' + item.receiveUserName,
            key: { receiveCompanyId: item.receiveCompanyId, receiveUserId: item.receiveUserId }
          })
        })
        // 让穿梭框左侧获得客户列表
        this.transferList = newTransList
      } else if (this.keepMessageData.receiveType === 2) {
        // 选择供应商时获取供应商列表
        // 选择供应商时是外部通知
        this.keepMessageData.messageCategory = '2'
        const { data: res } = await getMessageSellListApi()
        if (res.code !== 0) return this.$message.error(res.msg)
        let transData = res.data.data
        let newTransList = []
        transData.forEach(item => {
          newTransList.push({
            label: item.receiveCompanyName + ' ' + item.receiveUserName,
            key: { receiveCompanyId: item.receiveCompanyId, receiveUserId: item.receiveUserId }
          })
        })
        // 让穿梭框左侧获得客户列表
        this.transferList = newTransList
      } else {
        // 选择员工时获取用户列表
        // 选择供应商时是内部通知
        this.keepMessageData.messageCategory = '1'
        const { data: res } = await getUserManageApi(this.purchaseListData)
        if (res.code !== 0) return this.$message.error(res.msg)
        let transData = res.data.page.list
        let newTransList = []
        transData.forEach(item => {
          newTransList.push({
            label: item.company + ' ' + item.name,
            key: { receiveUserId: item.userId }
          })
        })
        // 让穿梭框左侧获得客户列表
        this.transferList = newTransList
      }
    },
    // 路由跳转
    routerJump(url) {
      this.$router.push(url)
    },
    // 获取消息展示数据
    async getMessageList(reset) {
      if (reset !== 'reset') {
        this.manageListData.page = 1
      }
      const { data: res } = await getMessageManageListApi(this.manageListData)
      if (res.code !== 0) return this.$message.error('获取消息失败')
      // 获取表单数据
      this.tableData = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.manageListData.size = val
      this.manageListData.page = 1
      this.getMessageList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.manageListData.page = val
      this.currentPage = val
      this.getMessageList('reset')
    },
    // 点击切换展示数据
    switchData(status) {
      this.manageListData.messageCategory = status
      this.getMessageList()
    },
    // 查看消息详情
    async examineManage(result) {
      // 未发布时打开编辑弹框
      if (result.publish === 0) {
        this.addMessage = false
        // 清空弹框内容再打开
        this.keepMessageData = {
          messageTitle: '',
          messageContent: '',
          messageCategory: '',
          messageType: 1,
          userMessageList: [],
          attachmentIdList: [],
          attachmentIdDeleteList: []
        }
        this.fileList = []
        // 获取消息详情
        const { data: res } = await examineManage2Api(result.id)
        this.keepMessageData = res.data.data
        // 后台传过来的时候attachmentIdDeleteList,attachmentIdList值为null 保存会报错,将其置为空数组
        this.keepMessageData.attachmentIdDeleteList = []
        this.keepMessageData.attachmentIdList = []
        this.keepMessageData.attachmentEntityList.forEach(item => {
          this.fileList.push({
            name: item.attachmentName + '.' + item.attachmentType,
            url: item.attachmentPath,
            id: item.id
          })
        })
        // 根据详情的接收类型获取文件列表
        this.chooseObject('edit').then(() => {
          // 判断如果接收对象类型不是员工类型
          if (this.keepMessageData.receiveType !== 3) {
            // 将获取到的接收对象根据详情移动到穿梭框右侧
            if (this.transferList.length !== 0) {
              for (let i = 0; i < this.transferList.length; i++) {
                for (let j = 0; j < this.keepMessageData.userMessageList.length; j++) {
                  if ((this.transferList[i].key.receiveCompanyId === this.keepMessageData.userMessageList[j].receiveCompanyId) && (this.transferList[i].key.receiveUserId === this.keepMessageData.userMessageList[j].receiveUserId)) {
                    // 满足条件receiveCompanyId和receiveUserId相等时
                    this.transferValue.push(this.transferList[i].key)
                  }
                }
              }
            }
          } else {
            // 接收对象是员工类型
            // 将获取到的接收对象根据详情移动到穿梭框右侧
            if (this.transferList.length !== 0) {
              for (let i = 0; i < this.transferList.length; i++) {
                for (let j = 0; j < this.keepMessageData.userMessageList.length; j++) {
                  if (this.transferList[i].key.receiveUserId === this.keepMessageData.userMessageList[j].receiveUserId) {
                    // 满足条件receiveUserId相等时
                    this.transferValue.push(this.transferList[i].key)
                  }
                }
              }
            }
          }
        })
        this.createDialog = true
      } else {
        // 发布后打开查看弹框
        const { data: res } = await examineManage2Api(result.id)
        this.messageDetail = res.data.data
        this.dialogVisible = true
      }
    },
    // 上传文件成功回调
    uploadSuccess(res) {
      console.log(this.fileList)
      this.keepMessageData.attachmentIdList.push(res.data.data)
    },
    // 上传前大小限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 删除附件前触发钩子
    delFileUpload(result) {
      // 如果是新添加的附件是有response值得,原本就有的附件没有该值
      if (result.response === undefined) {
        this.keepMessageData.attachmentIdDeleteList.push(result.id)
      } else {
        this.keepMessageData.attachmentIdDeleteList.push(result.response.data.data)
      }
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  margin: 20px;
  height: calc(100vh - 105px);
  width: calc(100% - 40px);
  background:rgba(255,255,255,1);
  border-radius:8px;
  /deep/ .el-card__body {
    padding: 20px;
  }
  p {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    color: #000;
  }
  .createBtn {
    margin-bottom: 10px;
    padding: 0 8px;
    display: block;
    height: 30px;
    line-height: 16px;
    background-color: rgba(18, 62, 131, 1);
    border: 1px solid rgba(18, 62, 131, 1);
    color: #fff;
    i {
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  .createBtn:hover {
    background-color: rgba(18, 62, 131, .8);
    border: 1px solid rgba(18, 62, 131, .8);
  }
  .search {
    position: absolute;
    top: 82px;
    right: 35px;
    height: 30px;
    width: 380px;
    .el-button {
      padding: 0;
      display: inline-block;
      width: 85px;
      height: 30px;
      line-height: 30px;
      background-color: rgba(18, 62, 131, 1);
      color: #fff;
      font-size: 13px;
    }
    .el-button:hover {
      background-color: rgba(18, 62, 131, .8);
    }
    .el-input {
      margin-right: 12px;
      width: 274px;
      /deep/ .el-input__inner {
        height: 30px;
      }
    }
    i {
      position: absolute;
      top: 5px;
      left: 248px;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
.layout_row {
  position: relative;
}
.el-pagination {
  position: absolute;
  top: 5px;
  right: 0;
}
.el-table {
  margin-top: 3px;
  /deep/ .el-table__body {
    cursor: pointer;
  }
  .cell {
    div {
      display: flex;
    }
    p {
      margin: 8px 10px;
      width: 8px;
      height: 8px;
      background-color: #B7B7B7;
      border-radius: 50%;
      box-shadow: 1px 1px 1px black,-1px -1px 1px white;
    }
    .el-button {
      border: 0;
    }
    .passBtn {
      background-color: #009688;
    }
    .passBtn:hover {
      background-color: rgba(0,150,136,.7)
    }
    .passBtn:active {
      background-color: rgba(0,150,136,.9)
    }
    a {
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.el-dialog ul li p {
  display: inline-block;
  margin-left: 20px;
  width: 380px;
  // background-color: red;
  border-bottom: 1px solid #ccc;
}
.closeBtn {
  background-color: rgba(204, 204, 204, .7);
  border: 1px solid rgb(204, 204, 204);
}
.closeBtn:hover {
  background-color: rgba(204, 204, 204);
  border: 1px solid rgb(204, 204, 204);
  color: #000;
}
/deep/ .createDialog .el-dialog {
  margin-top: 10px!important;
  width: 672px;
  height: 834px;
  line-height: 16px;
  .el-dialog__body {
    padding: 8px 0;
    height: 725px;
    width: 672px;
    .el-input {
      line-height: 0;
      .el-input__inner {
        border: 0;
        font-size: 16px;
      }
    }
    >p {
      margin: 0;
      padding: 14px 25px;
      font-size: 14px;
      border-top: 1px solid rgba(230, 230, 230, 1);
    }
    .acceptType {
      border: 0;
    }
    .el-select {
      margin-left: 25px;
      height: 38px;
      .el-input__inner {
        width: 602px;
        height: 38px;
        border: 1px solid #E6E6E6;
        font-size: 14px;
      }
    }
    .uploadBox .upload-demo {
      .el-upload {
        margin: 10px 25px 0;
        .uploadBtn {
          height: 50px;
          width: 602px;
          border: 1px dashed #123e83;
          border-radius: 3px;
          i {
            font-size: 20px;
            color: #123e83;
          }
        }
      }
      .el-upload-list {
        display: block;
        .el-upload-list__item {
          margin-left: 25px;
          height: 38px;
          width: 602px;
          line-height: 38px;
          border: 1px solid rgba(222, 226, 230, 1);
          a:hover {
            color: #123e83;
          }
          .el-icon-close {
            color: #123e83;
            line-height: 31px;
          }
          .el-upload-list__item-status-label {
            display: none;
          }
          .el-icon-close-tip {
            color: #fff;
          }
        }
      }
    }
    .transferBox {
      padding-left: 25px;
      .el-transfer-panel {
        width: 260px;
        .el-transfer-panel__header .el-checkbox .el-checkbox__label {
          font-size: 14px;
        }
        .el-transfer-panel__body {
          height: 170px;
        }
      }
      .el-input__inner {
        font-size: 14px;
      }
      .el-transfer__buttons {
        padding: 0;
        width: 82px;
        .el-button {
          margin-left: 28px;
          display: block;
          padding: 0;
          height: 22px;
          width: 26px;
          background-color: #fff;
          border: 1px solid rgba(125, 127, 130, 1);
          color: rgba(125, 127, 130, 1);
        }
        .el-button:nth-child(2) {
          background-color: #123e83;
          border: 1px solid #123e83;
          color: #fff;
        }
      }
    }
  }
  .dialog-footer {
    text-align: right;
    .keepBtn {
      background-color: #123e83;
      border: 1px solid #123e83;
      color: #fff;
    }
    .keepBtn:hover {
      background-color: rgba(18,62,131, .9);
      border: 1px solid rgba(18,62,131, .8);
    }
  }
}
.examineDialog {
  /deep/ .el-dialog {
    margin-top: 30px!important;
    height: 768px;
    width: 878px;
    overflow: auto;
    .el-dialog__header {
      font-size: 20px;
      font-weight: 400;
    }
    .el-dialog__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      h5 {
        margin: 0;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
      }
      span {
        font-size: 13px;
        font-weight: 400;
        color: rgba(96, 98, 102, 1);
      }
      .contentBox {
        width: 728px;
        p {
          width: 685px;
          white-space: pre-wrap;
          line-height: 25px;
        }
      }
      .enclosureBox {
        width: 685px;
        p {
          margin: 5px 0;
          margin-bottom: 0;
          line-height: 20px;
        }
        ul {
          li {
            position: relative;
            margin: 10px 0;
            height: 40px;
            width: 602px;
            border: 1px solid rgba(222, 226, 230, 1);
            border-radius: 3px;
            line-height: 38px;
            .prefixBox {
              margin: 4px 10px 4px 4px;
              display: inline-block;
              width: 48px;
              height: 30px;
              background-color: rgba(220, 222, 252, 1);
              border-radius: 3px;
              line-height: 24px;
              text-align: center;
              vertical-align: middle;
              color: rgba(114, 124, 245, 1);
              font-size: 16px;
              font-weight: 300;
            }
            a {
              position: absolute;
              left: 58px;
              display: inline-block;
              width: 544px;
              height: 40px;
              color: rgba(67, 67, 67, 1);
              cursor: pointer;
            }
            i {
              position: absolute;
              top: 1px;
              right: 20px;
              color: #123e83;
            }
          }
        }
        .el-table {
          th {
            padding: 0;
            line-height: 38px;
          }
        }
      }
    }
  }
}
</style>
