// 出库详情
<template>
  <div class="outDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/outManage' }">出库单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="outDetailContent">
      <header>
        <div>
          <h5>出库单号: {{outDetail.code}}</h5>
          <p>出库仓库: <span>{{outDetail.warehouseName}}</span> | 出库形式:  <span>{{ outDetail.type===1?'手动出库':outDetail.type===2?'转仓出库':outDetail.type===3?'盘亏出库': outDetail.type===4 ?'线下销售出库':'销售出库'}}</span> | 出库时间:  <span>{{outDetail.outboundTime}}</span> | 创建时间:  <span>{{outDetail.createdTime}}</span></p>
        </div>
      </header>
      <main>
        <h6>出库明细</h6>
        <el-table ref="tableHeight" :data="outDetail.warehouseOutboundLineVoList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="100px" align="left">
            <template slot-scope="scope">
              <span style="padding-left: 10px;">{{scope.row.productName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
              </template>
            </el-table-column>
          <el-table-column show-overflow-tooltip prop="productUnit" label="单位" min-width="130px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="quantity" label="出库数量" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span style="color: rgba(18, 62, 131, 1);">{{scope.row.quantity}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="quantityEnd" label="出库后剩余数量" min-width="100px" align="center"></el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
      </main>
    </div>
  </div>
</template>
<script>
import { outDetailApi } from '@/api'
export default {
  data() {
    return {
      id: '',
      // 获取采购单出库详情
      outDetail: {
        warehouseOutboundLineVoList: []
      }
    }
  },
  created() {
    this.id = this.$route.query.result
    this.getoutDetail()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 获取出库单详情
    async getoutDetail() {
      const { data: res } = await outDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.outDetail = res.data.page
    },
    routerJump(val) {
      this.$router.back()
    }
  }
};
</script>
<style lang="less" scoped>
.outDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .outDetailContent {
    margin-top: 40px;
    header {
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    main {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
  }
}
</style>