// 销售退料
<template>
  <div class="purchaseReturn">
    <nav>
      <h5>销售退货单</h5>
      <div>
        <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="purchaseReturnData.code" placeholder="退货单编号搜索" @input="getPurchaseReturnList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="purchaseReturnData.orderCode" placeholder="销售单编号搜索" @input="getPurchaseReturnList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="purchaseReturnData.productName" placeholder="产品名称搜索" @input="getPurchaseReturnList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="下单日期区间"
          end-placeholder="下单日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
    </section>
    <article>
      <div @click="changeActive(2)" style="border-top-left-radius: 4px; border-bottom-left-radius: 4px;" :class="purchaseReturnData.state === 2 ? 'changeBox highlight' : 'changeBox'">已确认</div>
      <div @click="changeActive(1)" :class="purchaseReturnData.state === 1 ? 'changeBox highlight' : 'changeBox'">待确认</div>
      <div @click="changeActive(3)" style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;" :class="purchaseReturnData.state === 3 ? 'changeBox highlight' : 'changeBox'">作废</div>
    </article>
    <main>
      <el-table ref="tableHeight" row-key="index" :height="tableHeight" :data="purchaseReturnList" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineEntityList'}" :show-overflow-tooltip="true">
        <el-table-column show-overflow-tooltip prop="code" label="退货单编号" min-width="100px" align="center" fixed="left">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(3, scope.row.id)">{{scope.row.code}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="companyName" label="客户" min-width="100px" align="center" fixed="left"></el-table-column>
        <el-table-column show-overflow-tooltip prop="orderCode" label="销售单编号" min-width="100px" align="center" fixed="left">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.orderCode === '' || scope.row.orderCode === null ? '暂未填写' : scope.row.orderCode}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="number" label="退货数量" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.number === '' || scope.row.number === null ? '暂无' : scope.row.number}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="money" label="退货总金额" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.money === '' || scope.row.money === null ? '暂无' : scope.row.money}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="制单时间" min-width="120px" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">{{scope.row.createTime}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createName" label="制单人" min-width="100px" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">{{scope.row.createName}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170px"  align="center" fixed="right">
          <template slot-scope="scope">
            <div class="controlBox" v-if="scope.row.lineEntityList">
              <p v-if="purchaseReturnData.state === 2 && btnLimit.includes('撤回')" @click="chooseCommand(2, scope.row)"><i class="iconfont icon-fanhui-baise"></i><span>撤回</span></p>
              <p v-if="purchaseReturnData.state === 1" @click="chooseCommand(1, scope.row)"><i class="iconfont icon-shoudongwancheng"></i><span>确认</span></p>
              <p v-if="purchaseReturnData.state === 1" @click="chooseCommand(3, scope.row)"><i class="iconfont icon-quxiaoxinzeng"></i><span>取消</span></p>
              <p v-if="purchaseReturnData.state === 3" @click="chooseCommand(4, scope.row)"><i class="el-icon-delete"></i><span>删除</span></p>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="purchaseReturnData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import { purchaseReturnListApi, delPurchaseReturnApi, editPurchaseReturnStateApi } from '@/api'
export default {
  name: 'purchaseReturn',
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      // 销售退料列表
      purchaseReturnList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      purchaseReturnData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        purchaseCode: '',
        productName: '',
        createTimeBegin: '',
        createTimeEnd: '',
        approveStatus: '',
        sellId: '',
        state: 2,
        type: 2
      },
      chooseTime: '',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getPurchaseReturnList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 切换标签页
    changeActive(val) {
      this.purchaseReturnData.state = val
      this.getPurchaseReturnList()
      // 解决样式错乱的问题
      this.$nextTick(() => {
        this.$refs.tableHeight.doLayout()
      })
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command !== 4) {
        this.editPurchaseReturnState(command, result.id)
      } else {
        this.delPurchaseReturn(result.id)
      }
    },
    // 修改订单状态
    editPurchaseReturnState(type, id) {
      // 1 确认 2 撤回 3 取消 type
      let showVal
      if (type === 1) {
        showVal = '确认'
      } else if (type === 2) {
        showVal = '撤回'
      } else {
        showVal = '取消'
      }
      this.$confirm('是否' + showVal + '该退货单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await editPurchaseReturnStateApi({ id: id, type: type })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success(showVal + '成功!')
          this.getPurchaseReturnList()
        })
    },
    // 删除
    delPurchaseReturn(id) {
      this.$confirm('是否确认删除该退货单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await delPurchaseReturnApi({ id: id, type: 2 })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('删除成功!')
          this.getPurchaseReturnList()
        })
    },
    // 路由跳转
    routerJump(val, result) {
      this.$router.push({ path: '/sellerReturnDetail', query: { result: JSON.stringify(result) } })
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.purchaseReturnData.createTimeBegin = ''
        this.purchaseReturnData.createTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.purchaseReturnData.createTimeBegin = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.purchaseReturnData.createTimeEnd = getYear + '-' + nowMonth + '-' + getDate + ' 23:59:59'
          }
        })
      }
      this.getPurchaseReturnList()
    },
    // 获取销售退料列表
    async getPurchaseReturnList(reset) {
      if (reset !== 'reset') {
        this.purchaseReturnData.page = 1
      }
      const { data: res } = await purchaseReturnListApi(this.purchaseReturnData)
      // 获取表单数据
      this.purchaseReturnList = res.data.data.list
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
      // 给列表添加唯一值
      this.purchaseReturnList.forEach((item, index) => {
        item.index = index + 1
        item.lineEntityList.forEach((val, i) => {
          val.index = (index + 1 + '' + i + 1) - 0
        })
      })
      console.log(this.purchaseReturnList)
    },
    // -----------------------------------------------------------------

    // 触发每页显示条数
    handlesizeChange(val) {
      this.purchaseReturnData.size = val
      this.purchaseReturnData.page = 1
      this.getPurchaseReturnList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.purchaseReturnData.page = val
      this.currentPage = val
      this.getPurchaseReturnList('reset')
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseReturn {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  article {
    margin: 20px 20px 10px;
    display: flex;
    .changeBox {
      margin-left: -1px;
      height: 32px;
      width: 68px;
      border: 1px solid #CACACA;
      cursor: pointer;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.60);
    }
    .highlight {
      background-color: rgba(18, 62, 131, 1);
      border-color: rgba(18, 62, 131, 1);
      color: #fff;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
    .controlBox {
      display: flex;
      justify-content: space-around;
      p {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.80);
        i {
          margin-right: 3px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
