<!-- eslint-disable vue/valid-template-root -->
// 配置模块
<template>
  <div class="matterCategory">
    <nav>
      <h5>配置模块</h5>
    </nav>
    <div class="matterCategoryContent">
        <div class="contentBox" v-for="(item,index) in tableData" :key="index">
        <p>{{ item.name }}
        <!-- 1是对账 -->
        <span v-if="item.type===1">{{ item.state===1?'入库单':'收货单' }}</span>
        <!-- 2是收货单 -->
        <span v-else>{{ item.state===1?'可以生成 ':'不可以生成' }}</span>
        </p>
        <el-button v-if="btnLimit.includes('变更')" @click="dialogVisibleFn(item.state,item.type,item.id)">变更</el-button>
        </div>
    </div>
    <el-dialog
        :title="isTitle!==1?'在收货校验单是否能直接生成入库单':'可生成对账单的单据依据'"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <div v-if="isTitle===1">
        <el-radio v-model="paramsData.state" :label="parseInt(0)">收货单</el-radio><br>
        <el-radio disabled="true" v-model="paramsData.state" :label="parseInt(1)">入库单</el-radio>
       </div>
       <div v-else>
        <el-radio v-model="paramsData.state" :label="parseInt(1)">可以生成</el-radio><br>
        <el-radio v-model="paramsData.state" :label="parseInt(0)">不可以生成</el-radio>
       </div>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeFn">取 消</el-button>
            <el-button type="primary" @click="okFn">确 定</el-button>
        </span>
    </el-dialog>
  </div>

</template>
<script>
import { moduleApi, moduleUpdateApi } from '@/api'
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      // 1是对账2是收货单
      isTitle: 1,
      // 变更状态绑定
      paramsData: {
        state: '',
        id: ''
      },
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
    this.getModuleList()
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
  },
  methods: {
    async getModuleList() {
      const { data: res } = await moduleApi()
      if (res.code === 0) {
        this.tableData = res.data.data
      }
    },
    // 弹出变更弹框
    dialogVisibleFn(state, type, id) {
      if (type === 1) {
        console.log(state, 'state')
        console.log(type, 'type')

        this.paramsData.state = state
        this.dialogVisible = true
        this.isTitle = type
        this.paramsData.id = id
        // this.paramsData.type = 1
      } else {
        console.log(state, 'state')
        console.log(type, 'type')
        this.paramsData.state = state
        this.paramsData.id = id
        this.isTitle = type
        this.dialogVisible = true
        // this.paramsData.type = 2
      }
    },
    // 弹框关闭事件
    handleClose(done) {
      this.dialogVisible = false
    },
    // 确定关闭弹框
    async okFn() {
      const { data: res } = await (moduleUpdateApi(this.paramsData))
      if (res.code === 0) {
        this.$message({
          type: 'success', // success error warning
          message: '变更成功',
          duration: 2000
        })
        this.getModuleList()
        this.dialogVisible = false
      } else {
        this.$message({
          type: 'error', // success error warning
          message: '变更失败',
          duration: 2000
        })
        this.getModuleList()
        this.dialogVisible = false
      }
    },
    // 取消关闭弹框
    closeFn() {
      this.dialogVisible = false
      // this.paramsData.state = ''
    }
  }
}
</script>
<style lang="less" scoped>
.matterCategory {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
  }
  .matterCategoryContent{
    width: calc(100vw - 260px);
    height: calc(100vh - 260px);
    margin-bottom: 20px;
    padding: 0 20px;
    overflow-y: auto;
    .contentBox{
        width: 100%;
        background: #f2f2f2;
        margin: 15px 0px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 2.5rem;
        .el-button {
            padding: 6px 11px;
            height: 32px;
            line-height: 0;
            background: #448ef7;
            color: white;
        }
         p{
            margin: 0 1.5625rem;
            font-size: .75rem;
            span{
                display: inline-block;
                margin-left: 3.125rem;
                color: #448ef7;
            }
         }
    }
  }
  .el-dialog__header span{
        font-size: .875rem;
    }
}
</style>
