// 流程管理
<template>
  <div class="approvalManage">
    <nav>
      <h5>流程管理</h5>
    </nav>
    <section>
      <el-input class="sectionInp" v-model="approvalManageData.name" placeholder="流程名称" @input="approvalManage()" prefix-icon="el-icon-search"></el-input>
      <el-select class="sectionInp" v-model="approvalManageData.status" placeholder="请选择" size="mini" @change="approvalManage()">
        <el-option label="启用" :value="1"></el-option>
        <el-option label="挂起" :value="0"></el-option>
      </el-select>
    </section>
    <main>
      <el-table ref="tableHeight" :height="tableHeight" min-height="30px" :data="tableData" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="id" label="流程编码" min-width="10%" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="name" label="流程名称" min-width="30%" align="center"></el-table-column>
        <el-table-column prop="" label="状态" min-width="10%" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status === 1"><p class="start_p"></p>启用</div>
            <div v-if="scope.row.status === 0"><p class="end_p"></p>挂起</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="updateTime" label="更新日期" min-width="30%" align="center"></el-table-column>
        <el-table-column prop="" label="操作" min-width="20%" align="center">
          <template slot-scope="scope">
            <el-button v-if="btnLimit.includes('配置')" class="disposeBtn" size="mini" @click="routerJump(scope.row)">配置</el-button>
            <el-button v-if="scope.row.status === 0 && btnLimit.includes('启用挂起')" class="startBtn" size="mini" @click="editApprovalStatus(scope.row, 0)">启用</el-button>
            <el-button v-if="scope.row.status === 1 && btnLimit.includes('启用挂起')" class="stopBtn" size="mini" @click="editApprovalStatus(scope.row, 1)">挂起</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
    </main>
  </div>
</template>
<script>
import { approvalManageApi, editApprovalStatusApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      tableData: [],
      approvalManageData: {
        name: '',
        // 1:开启 0:关闭
        status: ''
      },
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
    this.approvalManage()
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 102
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 102
      })()
    }
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.sendShort(result)
      } else if (command === '2') {
        this.sendShort(result)
      } else if (command === '3') {
        this.delUser(result.id)
      }
    },
    routerJump(result) {
      this.$router.push({ path: "/approvalManageDispose", query: { approveId: result.id, name: result.name, status: result.status }})
    },
    // 获取审批列表
    async approvalManage() {
      const { data: res } = await approvalManageApi(this.approvalManageData)
      if (res.code !== 0) return this.$message.errror('获取审批列表失败!')
      this.tableData = res.data.list
    },
    // 修改状态
    editApprovalStatus(result, num) {
      if ((result.name === '采购方合同审批流程' && result.status === 1) || (result.name === '销售方合同审批流程' && result.status === 1)) {
        return this.$message.error('该审批流不支持挂起')
      }
      let newStatus = ''
      if (result.status === 1) {
        newStatus = 0
      } else if (result.status === 0) {
        newStatus = 1
      }
      this.$confirm('是否' + (num === 0 ? '启用' : '挂起') + '当前审批流', '提示', {
        confirmButtonText: num === 0 ? '启用' : '挂起',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await editApprovalStatusApi({ id: result.id, status: newStatus })
        if (res.code !== 0) return this.$message.errror(res.msg)
        this.$message.success({ duration: 1000, message: (num === 0 ? '启用成功!' : '挂起成功!') })
        this.approvalManage()
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped lang='less'>
.approvalManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
    .el-button {
      color: #fff;
      border: 0;
    }
    .disposeBtn {
      background-color: #123E83;
    }
    .startBtn {
      background-color: #3CC9AC;
    }
    .stopBtn {
      background-color: #E8654B;
    }
  }
}
</style>
