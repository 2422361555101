// 库存管理详情
<template>
  <div class="purchaseEntry">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/allocationManage' }">库存管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="purchaseEntryContent">
        <nav>
          <h5>
            {{ headerData.productName }}:
            ({{ headerData.productCode }})
        </h5>
            <div class="navBox">
                <p>单位：
                    <span>{{ headerData.unitName }}</span>
                </p>
                <p>规格：
                    <span>{{ headerData.productSpecs }}</span>
                </p>
                <p>颜色:<span>{{headerData.productNewColour}}</span></p>
                <p>类型:<span>{{headerData.productTypeName}}</span></p>
                <p>材料:<span>{{headerData.productMaterials}}</span></p>
          </div>
        </nav>
        <main>
          <h5>产品配置明细</h5>
          <div class="searchBox">
            <el-input class="sectionInp" v-model="searchData.orderNo" placeholder="订单号搜索" @input="sellerList()" prefix-icon="el-icon-search"></el-input>
            <!-- 审核日期 -->
            <div class="block">
                <el-date-picker
                v-model="chooseTime"
                @change="getTimess"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期区间"
                end-placeholder="申请日期区间"
                :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
            </div>
              <el-cascader
              placeholder="搜索入库类型"
              :options="typeData"
              @change="typeFn"
              clearable></el-cascader>
          </div>
          <el-table v-if="list.length !== 0"
      :data="list"
      style="width: 100%">
      <el-table-column
        prop="createTime"
        align="center"
        label="时间"
        width="180">
        <template slot-scope="scope">
        <span>{{scope.row.createTime===null||scope.row.createTime===''?'暂无':scope.row.createTime.substring(0,10) }}</span>
        </template>
    </el-table-column>
    <el-table-column
    align="center"
    prop="createUserName"
    label="创建人">
</el-table-column>
<el-table-column
align="center"
prop="type"
label="类型">
<template slot-scope="scope">
    <div v-if="scope.row.status===1">
    <span>{{ scope.row.type===0?'采购入库':scope.row.type===1?'手动入库':scope.row.type===2?'盘盈入库':scope.row.type===3?'转仓入库':scope.row.type===4?'线下入库':'自动入库' }}</span>
    <span style="color: skyblue;">【入库】</span>
    </div>
    <div v-else>
    <span>{{ scope.row.type===1?'手动出库':scope.row.type===2?'转仓出库':scope.row.type===3?'盘亏出库':scope.row.type===4?'线下销售出库':'销售出库' }}</span>
    <span style="color: rgb(235, 205, 135);">【出库】</span>

    </div>
</template>
</el-table-column>
<el-table-column
prop="orderNo"
align="center"
label="订单号">
</el-table-column>
<el-table-column
align="center"
prop="num"
label="数量">
</el-table-column>
<el-table-column
align="center"
prop="lastNum"
label="库存剩余数量">
<!-- <template slot-scope="scope">
  <span>{{scope.row.arriveTime===null||scope.row.arriveTime===''?'暂无':scope.row.arriveTime.substring(0,10) }}</span>
</template> -->
</el-table-column>
          </el-table>
<div v-else class="emptyBg">
    <img src="../../assets/img/notData.png" style="width:30%; margin-top: -2%;">
    <span style="display: block; margin-top: -5%">暂无数据</span>
</div>
</main>
</div>
</div>
</template>
<script>
import { purchaseRepertoryDetail } from '@/api'
export default {
  name: 'receipCheckDetail',
  data() {
    return {
      chooseTime: '',
      headerData: {},
      searchData: {
        startTime: '',
        endTime: '',
        id: '',
        orderNo: '',
        type: '',
        status: ''
      },
      list: [],
      typeData: [{
        value: 1,
        label: '入库',
        children: [{
          value: 0,
          label: '采购入库'
        }, {
          value: 1,
          label: '手动入库'
        }, {
          value: 2,
          label: '盘盈入库'
        }, {
          value: 3,
          label: '转仓入库'
        }, {
          value: 4,
          label: '线下入库'
        }, {
          value: 5,
          label: '自动入库'
        } ]
      }, {
        value: 0,
        label: '出库',
        children: [{
          value: 1,
          label: '手动出库'
        }, {
          value: 2,
          label: '转仓出库'
        }, {
          value: 3,
          label: '盘亏出库'
        }, {
          value: 4,
          label: '线下销售出库'
        }, {
          value: 5,
          label: '销售出库'
        }]
      }]
    }
  },
  created() {
    this.searchData.id = this.$route.query.id
    this.sellerList()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    typeFn(val) {
      this.searchData.status = val[0]
      this.searchData.type = val[1]
      this.sellerList()
    },
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await purchaseRepertoryDetail(this.searchData)
      this.list = res.data.page.records
      this.headerData = res.data.product
    },
    getTimess(result) {
      if (result === null) {
        this.searchData.startTime = ''
        this.searchData.endTime = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.searchData.startTime = getYear + '-' + nowMonth + '-' + getDate
          } else {
            this.searchData.endTime = getYear + '-' + nowMonth + '-' + getDate
          }
        })
      }
      this.sellerList()
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseEntry {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .purchaseEntryContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 180px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 20px;
      padding: 20px;
      height: 92px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .navBox {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.467);
        p{
            margin: 0px .625rem 0 0;
            span{
                color: rgba(0, 0, 0, 0.843);
            }
        }
       }
    }
    main {
      position: relative;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .searchBox{
        margin: 10px 0px;
            display: flex;
            // justify-content: space-around;
            .el-input, .el-textarea {
                display: inline-block;
                width: 20%;
                margin-right: 20px;
            }
            .el-select {
            width: 20%;
            margin-left: 20px;
            display: block;
            }
            .el-cascader{
              line-height: 0px;
              margin-left: 10px;
            }
        }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        height: calc(100vh - 470px);
        overflow-y: auto;
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              color: rgba(0, 0, 0, 0.60);
            }
            >p {
              position: relative;
              height: 24px;
              width: 100%;
              font-size: 14px;
              >span {
                position: absolute;
                left: 40%;
                display: inline-block;
                width: 220px;
                color: rgba(0, 0, 0, 0.60);
              }
              i {
                float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 100%;
              .el-form-item__content {
                width: 100%;
                width: calc(100% - 102px);
                .el-input {
                  width: 100%;
                }
              }
            }
            .showNumBox {
              position: absolute;
              right: 10px;
              height: 32px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 12px;
              line-height: 32px;
              font-weight: 400;
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
