<!-- 收货检验单 -->
<template>
  <div class="purchaseOrder">
    <nav>
      <h5>收货检验单</h5>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="purchaseOrderData.code" placeholder="收货编号搜索" @input="getpurchaseOrderList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="purchaseOrderData.orderCode" placeholder="采购编号搜索" @input="getpurchaseOrderList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="创建日期区间"
          end-placeholder="创建日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select  @change="getpurchaseOrderList()" v-model="purchaseOrderData.takeState" placeholder="订单状态筛选" size="mini" clearable>
        <el-option label="待收货" :value="1"></el-option>
        <el-option label="已收货" :value="3"></el-option>
      </el-select>
    </section>
    <main>
      <el-table ref="tableHeight" row-key="id" :height="tableHeight" :data="purchaseOrderList" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineEntityList'}" :show-overflow-tooltip="true">
        <el-table-column show-overflow-tooltip prop="code" label="收货编号" min-width="140px" align="center" fixed="left">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList && scope.row.type===1" @click="routerJump('1',scope.row)" style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.code}}</span>
            <span v-else-if="scope.row.lineEntityList && scope.row.type===2" @click="routerJump('1',scope.row)" style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.code}}(补)</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="sellName" label="发货供应商" min-width="120px" align="center" fixed="left">
        </el-table-column>
        <!-- <el-table-column show-overflow-tooltip type="index" label="需求行号" width="80px"  align="center"></el-table-column> -->
        <el-table-column show-overflow-tooltip type="index" label="需求行号" width="80px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="140px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.unitName }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="number" label="本次发货数量" min-width="100px"  align="center"></el-table-column>
        <el-table-column v-if="active === 1" key="1" show-overflow-tooltip prop="state" label="订单状态" min-width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <div>
              <span class="stateBox1" v-if="scope.row.takeState === 1">待收货</span>
              <span class="stateBox2" v-if="scope.row.takeState === 3">已收货</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-dropdown v-if="scope.row.lineEntityList" @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="1" v-if="(scope.row.takeState === 1 || scope.row.takeState==='') && btnLimit.includes('收货')">
                  <i class="el-icon-edit-outline"></i><span>收货</span>
                </el-dropdown-item>
                  <span style="font-size: 12px; padding: 0 5px;" v-else>暂无操作</span>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="purchaseOrderData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import { ReceiptCheckListApi, delPurchaseOrderApi, purchaseOrderEndApi, overPurchaseOrderApi, confirmReceiptApi } from '@/api'
export default {
  data() {
    return {
      // 选中的标签
      active: 1,
      // 列表动态高度
      tableHeight: '',
      // 采购单列表
      purchaseOrderList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      purchaseOrderData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        companyName: '',
        createTimeBegin: '',
        createTimeEnd: '',
        takeState: '',
        state: '1',
        orderCode: '',
        ifAwait: '',
        type: 2
      },
      // 确认收货参数
      okData: {
        id: '',
        qualityTestingTime: '',
        qualityTestingBy: '',
        warehouseId: '',
        takeDeliveryType: '',
        lineEntityList: []
      },
      chooseTime: '',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getpurchaseOrderList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 完结
    overPurchaseOrder(id) {
      this.$confirm('是否确认完结该采购单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await overPurchaseOrderApi({ id: id, state: 1, stateType: 1 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('完结成功!')
        this.getpurchaseOrderList()
      })
    },
    // 选择下拉
    chooseCommand(command, result) {
      this.routerJump('2', result)
    },
    // 提交计划单
    async submitPurchaseOrder(result) {
      const { data: res } = await confirmReceiptApi(result)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('收货成功!')
      this.getpurchaseOrderList()
    },

    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.purchaseOrderData.createTimeBegin = ''
        this.purchaseOrderData.createTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.purchaseOrderData.createTimeBegin = getYear + '-' + nowMonth + '-' + getDate + ' ' + '00:00:00'
          } else {
            this.purchaseOrderData.createTimeEnd = getYear + '-' + nowMonth + '-' + getDate + ' ' + '23:59:59'
          }
        })
      }
      this.getpurchaseOrderList()
    },
    // 获取采购单列表
    async getpurchaseOrderList(reset) {
      if (reset !== 'reset') {
        this.purchaseOrderData.page = 1
      }
      const { data: res } = await ReceiptCheckListApi(this.purchaseOrderData)
      // 获取表单数据
      console.log(res)
      let newPurchaseOrderList = res.data.data.list
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
      // 给数据添加唯一值index,用于展开
      if (newPurchaseOrderList.length !== 0) {
        newPurchaseOrderList.forEach((item, index) => {
          item.index = index + ''
          if (item.lineEntityList.length !== 0) {
            item.lineEntityList.forEach((val, i) => {
              val.id = index + '' + i
            })
          }
        })
      }
      this.purchaseOrderList = newPurchaseOrderList
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.purchaseOrderData.size = val
      this.purchaseOrderData.page = 1
      this.getpurchaseOrderList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.purchaseOrderData.page = val
      this.currentPage = val
      this.getpurchaseOrderList('reset')
    },
    // -----------------------------------------------------------------
    // 删除
    delPurchaseOrder(id) {
      this.$confirm('是否确认删除该采购单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        console.log('????', id)
        const { data: res } = await delPurchaseOrderApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getpurchaseOrderList()
      })
    },
    // 结束
    endPurchaseOrder(id) {
      this.$confirm('是否手动结束该采购单审批流', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await purchaseOrderEndApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('结束成功!')
        this.getpurchaseOrderList()
      })
    },
    // 路由跳转
    routerJump(val, res) {
      if (val === '1') {
        this.$router.push({ path: '/receipCheckDetail', query: { id: JSON.stringify(res.id) } })
      } else {
        this.$router.push({ path: '/receiptCheckUpdate', query: { id: JSON.stringify(res.id), companyId: JSON.stringify(res.companyId) } })
      }
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseOrder {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  article {
    margin: 20px 20px 10px;
    display: flex;
    .changeBox {
      margin-left: -1px;
      height: 32px;
      width: 68px;
      border: 1px solid #CACACA;
      cursor: pointer;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.60);
    }
    .highlight {
      background-color: rgba(18, 62, 131, 1);
      border-color: rgba(18, 62, 131, 1);
      color: #fff;
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
    .stateBox1, .stateBox2, .stateBox3 {
      display: block;
      width: 64px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
  }
}
</style>
