// 仓库资料
<template>
  <div class="storeHouseData">
    <nav>
      <h5>仓库资料</h5>
      <div>
        <!-- v-if="btnLimit.includes('新增仓库')" -->
        <el-button class="lightDeepStyle" @click="openDialog()"><i class="el-icon-plus"></i>新增仓库</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model="storeHouseListData.code" placeholder="仓库编号搜索" @input="approvalManage()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model="storeHouseListData.name" placeholder="仓库名称搜索" @input="approvalManage()" prefix-icon="el-icon-search"></el-input>
    </section>
    <main>
      <el-table ref="tableHeight" :height="tableHeight" min-height="30px" :data="storeHouseList" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="code" label="仓库编号" min-width="80px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="name" label="仓库名称" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="typeName" label="仓库类型" width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="address" label="地址" min-width="140px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.address === '' ? '--' : scope.row.address}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="acreage" label="面积(m²)" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.acreage === '' ? '--' : scope.row.acreage + 'm²'}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="person" label="联系人" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.person === '' ? '--' : scope.row.person}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="phone" label="电话" min-width="100px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.phone === null ? '--' : scope.row.phone}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="btnLimit.includes('设为默认')" prop="quantity" label="设为默认" width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.ifDefault">
            <el-radio :label="true" @change="setDefaultStoreHouse(scope.row)"></el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <!--  v-if="btnLimit.includes('启用停用')" -->
        <el-table-column prop="ifDefault" label="状态" width="90px"  align="center" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-switch
                v-model="scope.row.enabled"
                :active-value="true"
                :inactive-value="false"
                active-color="rgba(18, 62, 131, 1)"
                inactive-color="#eee"
                @change="storeHouseUse(scope.row)">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="220px"  align="center" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button class="blueStyle" v-if="scope.row.ifInitialize === false" size="mini" @click="routeJump(scope.row, 1)">初始化</el-button>
              <el-button class="lightDeepStyle" v-else size="mini" @click="routeJump(scope.row, 2)">查看</el-button>
              <el-button class="keepBtn" size="mini" @click="editStoreHouse(scope.row)">编辑</el-button>
              <el-button class="rejectStyle" v-if="scope.row.enabled === false" size="mini" @click="delStoreHouse(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <!-- <el-dropdown-item command="1" v-if="scope.row.ifInitialize === false">
                  <i class="el-icon-magic-stick"></i><span>初始化</span>
                </el-dropdown-item> -->
                <el-dropdown-item command="2">
                  <i class="el-icon-tickets"></i><span>查看</span>
                </el-dropdown-item>
                <el-dropdown-item command="3" v-if="btnLimit.includes('编辑仓库')">
                  <i class="el-icon-edit-outline"></i><span>编辑</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.enabled === false" command="4">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="storeHouseListData.size"
        :total="total">
      </el-pagination>
    </main>
    <!-- 新增修改弹出框 -->
    <el-dialog
      :title="controlStatus === true ? '修改仓库' : '新增仓库'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      class="storeHouseDialog"
      v-dialogDrag>
      <!-- 注册表单 -->
      <el-form :model="changeStoreHouseData" :rules="rules" ref="ruleForm">
        <el-form-item prop="code">
          <p><i style="color: red;">*</i>仓库编码: </p><el-input v-model="changeStoreHouseData.code" placeholder="请输入仓库编码" clearable autocomplete="off" size="mini"></el-input>
        </el-form-item>
        <el-form-item prop="name">
          <p><i style="color: red;">*</i>仓库名称: </p><el-input v-model="changeStoreHouseData.name" placeholder="请输入仓库名称" clearable autocomplete="off" size="mini"></el-input>
        </el-form-item>
        <el-form-item prop="type">
          <p><i style="color: red;">*</i>仓库类型: </p>
          <el-select v-model="changeStoreHouseData.type" placeholder="请选择" size="mini">
            <el-option label="成品仓库" :value="0"></el-option>
            <el-option label="物料仓库" :value="1"></el-option>
            <el-option label="半成品仓库" :value="2"></el-option>
            <el-option label="废料仓库" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="acreage">
          <p>仓库面积: </p><el-input v-model="changeStoreHouseData.acreage" placeholder="请输入仓库面积(m²)" clearable autocomplete="off" size="mini"></el-input>
        </el-form-item>
        <el-form-item prop="address">
          <p>仓库地址: </p><el-input v-model="changeStoreHouseData.address" placeholder="请输入仓库地址" clearable autocomplete="off" size="mini"></el-input>
        </el-form-item>
        <el-form-item prop="person">
          <p>联系人: </p><el-input v-model="changeStoreHouseData.person" placeholder="请输入联系人" clearable autocomplete="off" size="mini"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <p>联系电话: </p><el-input v-model="changeStoreHouseData.phone" placeholder="请输入联系电话" clearable autocomplete="off" size="mini" oninput="value=value.replace(/[^(0-9)|-]+?/,'')"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="defaultStyle" @click="dialogVisible = false">取 消</el-button>
        <el-button class="lightDeepStyle" v-if="controlStatus === false" type="info" @click="addStore(1)">新 增</el-button>
        <el-button class="lightDeepStyle" v-else type="info" @click="addStore(2)">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStoreHouseCodeApi, storeHouseListAllApi, addStoreApi, storeHouseUseApi, setDefaultStoreHouseApi, delStoreHouseApi, storeHouseMatterListApi } from '@/api'
export default {
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      isFirstEnter: false,
      // 仓库列表
      storeHouseList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      storeHouseListData: {
        page: 1,
        // 当前页条数
        size: 50,
        code: '',
        name: '',
        enabled: '',
        businessTypes: ''
      },
      companyId: window.$cookies.get('companyId'),
      // 控制是否弹出修改框(新增,修改)
      dialogVisible: false,
      // 判断当前是新增还是修改
      controlStatus: false,
      // 新增修改参数
      changeStoreHouseData: {
        code: '',
        name: '',
        type: '',
        acreage: '',
        address: '',
        person: '',
        id: ''
      },
      // 输入框校验
      rules: {
        name: [
          { required: false, message: '请输入仓库名称', trigger: 'blur' }
        ]
      },
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getStoreHouseList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routeJump(result, 1)
      } else if (command === '2') {
        this.routeJump(result, 2)
      } else if (command === '3') {
        this.editStoreHouse(result)
      } else if (command === '4') {
        this.delStoreHouse(result)
      }
    },
    // 获取仓库列表
    async getStoreHouseList(reset) {
      if (reset !== 'reset') {
        this.storeHouseListData.page = 1
      }
      const { data: res } = await storeHouseListAllApi(this.storeHouseListData)
      // 获取表单数据
      this.storeHouseList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
      this.allRowId = []
    },
    // 跳转到新增或修改页面
    dialogJump(res, result) {
      if (res === '2') {
        this.$router.push({ path: '/changepurchaseOrder', query: { headerId: result.headerId } })
      } else if (res === '1') {
        this.$router.push({ path: '/changepurchaseOrder', query: { headerId: 0 } })
      }
    },
    // 点击新增触发
    async openDialog() {
      this.controlStatus = false
      this.changeStoreHouseData = {
        code: '',
        name: '',
        type: '',
        acreage: '',
        address: '',
        person: '',
        id: ''
      }
      // 获取仓库默认编码
      const { data: res } = await getStoreHouseCodeApi()
      this.changeStoreHouseData.code = res.data.data
      this.dialogVisible = true
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.storeHouseListData.size = val
      this.storeHouseListData.page = 1
      this.getStoreHouseList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.storeHouseListData.page = val
      this.currentPage = val
      this.getStoreHouseList('reset')
    },
    // 仓库列表导出
    async storeHouseListExport() {
      const { data: res } = await storeHouseListExportApi(this.storeHouseListData)
      console.log(res)
      this.getStoreHouseList()
    },
    // 新增修改仓库
    async addStore(status) {
      if (this.changeStoreHouseData.code === '') return this.$message.error('仓库编码不能为空!')
      if (this.changeStoreHouseData.name === '') return this.$message.error('仓库名称不能为空!')
      if (this.changeStoreHouseData.type === '') return this.$message.error('仓库类型不能为空!')
      const { data: res } = await addStoreApi(this.changeStoreHouseData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.dialogVisible = false
      if (status === 1) {
        this.$message.success({ duration: 1000, message: '新增成功!' })
      } else {
        this.$message.success({ duration: 1000, message: '修改成功!' })
      }
      this.getStoreHouseList()
    },
    // 仓库是否启用停用
    async storeHouseUse(result) {
      const { data: res } = await storeHouseUseApi({ id: result.id, enabled: result.enabled })
      this.getStoreHouseList()
      if (res.code !== 0) return this.$message.error(res.msg)
      if (result.enabled === false) {
        this.$message.success({ duration: 1000, message: result.name + '仓库停用成功!' })
      } else {
        this.$message.success({ duration: 1000, message: result.name + '仓库启用成功!' })
      }
    },
    // 仓库设为默认
    async setDefaultStoreHouse(result) {
      const { data: res } = await setDefaultStoreHouseApi(result.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getStoreHouseList()
      this.$message.success({ duration: 1000, message: '已将' + result.name + '设为默认仓库' })
    },
    // 编辑仓库
    editStoreHouse(result) {
      this.dialogVisible = true
      this.changeStoreHouseData = result
      this.controlStatus = true
    },
    // 删除仓库
    async delStoreHouse(result) {
      let matterManageList = []
      // 传参参数
      let storeHouseData = {
        page: 1,
        // 当前页条数
        size: 99999,
        status: '',
        productCode: '',
        productName: '',
        productSpecs: '',
        warehouseId: result.id
      }
      const { data: res } = await storeHouseMatterListApi(storeHouseData)
      // 获取表单数据
      matterManageList = res.data.page.list
      if (matterManageList.length !== 0) {
        this.$confirm('该仓库中存在物料,是否继续删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(async() => {
          const { data: res } = await delStoreHouseApi(result.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '删除成功!' })
          this.getStoreHouseList()
        }).catch(() => {
        })
      } else {
        this.$confirm('确定删除' + result.name, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(async() => {
          const { data: res } = await delStoreHouseApi(result.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '删除成功!' })
          this.getStoreHouseList()
        }).catch(() => {
        })
      }
    },
    // 初始化仓库页面跳转
    routeJump(result, index) {
      if (index === 1) {
        this.$router.push({ path: '/initializeStoreHouse', query: { result: JSON.stringify(result) } })
      } else {
        this.$router.push({ path: '/storeHouseDetail', query: { result: JSON.stringify(result) } })
      }
    }
  }
}
</script>
<style scoped lang='less'>
.storeHouseData {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
  }
  /deep/ .storeHouseDialog {
    .el-dialog {
      width: 422px;
      height: 430px;
    }
    .el-dialog__header{
      padding: 10px 18px 0;
      .el-dialog__headerbtn {
        top: 12px;
      }
    }
    .el-dialog__body {
      padding: 0 20px 10px;
      height: 343px;
      .el-form-item {
        height: 46px;
        margin-bottom: 0;
        .el-form-item__content {
          margin-top: 9px;
          height: 28px;
          line-height: 28px;
          p {
            margin-right: 10px;
            height: 28px;
          }
        }
      }
    }
    .el-dialog__footer {
      height: 48px;
      border-top: 1px solid rgba(240, 240, 240, 1);
      .el-button {
        float: right;
        margin: 10px;
        padding: 0;
        height: 28px;
        width: 60px;
        line-height: 0;
      }
    }
  }
}
</style>
