// 预收款管理
<template>
  <div class="prePaymentManage">
    <nav>
      <h5>预收款管理</h5>
      <div>
        <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-upload1"></i>导 出</el-button>
        <!-- <el-button class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增预收款</el-button> -->
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="prePaymentData.orderNum" placeholder="请输入销售单编号" @input="prePaymentList" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="prePaymentData.cgCompanyName" placeholder="请输入客户名称" @input="prePaymentList" prefix-icon="el-icon-search"></el-input>
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTime"
          type="daterange"
          range-separator="至"
          start-placeholder="下单日期区间"
          end-placeholder="下单日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <div class="block">
        <el-date-picker
          v-model="chooseTime2"
          @change="getTimes"
          type="daterange"
          range-separator="至"
          start-placeholder="收款日期区间"
          end-placeholder="收款日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select v-model="prePaymentData.status" placeholder="请选择结算状态" clearable size="mini" @change="prePaymentList">
        <el-option label="未结算" :value="0"></el-option>
        <el-option label="待结算" :value="1"></el-option>
        <el-option label="已结算" :value="2"></el-option>
      </el-select>
      <el-select v-model="prePaymentData.auditStatus" placeholder="请选择确认状态" clearable size="mini" @change="prePaymentList">
        <el-option label="未确认" :value="0"></el-option>
        <el-option label="已确认" :value="1"></el-option>
        <el-option label="已取消" :value="2"></el-option>
      </el-select>
    </section>
    <main>
      <el-table ref="tableHeight" :height="tableHeight" :data="chargeList" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="number" label="预收款单号" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(4, scope.row.id)">{{scope.row.number}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="cgCompanyName" label="客户名称" min-width="80px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="orderNum" label="销售单编号" min-width="130px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="price" label="预收金额" min-width="80px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="type" label="收款方式" min-width="100px" align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.type === 1 ? '现金' : '转账'}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="payTime" label="收款时间" min-width="100px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="下单时间" min-width="100px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="status" label="结算状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.auditStatus != 2">
              <span class="stateBox1" v-if="scope.row.status == 0">未结算</span>
              <span class="stateBox3" v-else-if="scope.row.status == 1">待结算</span>
              <span class="stateBox2" v-else-if="scope.row.status == 2">已结算</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="status" label="状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span style="color: rgba(239, 188, 96, 1)" v-if="scope.row.auditStatus == 0">未确认</span>
              <span style="color: rgba(70, 147, 235, 1)" v-else-if="scope.row.auditStatus == 1">已确认</span>
              <span style="color: rgba(0, 0, 0, 0.40)" v-else-if="scope.row.auditStatus == 2">已取消</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="1" v-if="scope.row.auditStatus === '0' && btnLimit.includes('确认取消')">
                  <i class="el-icon-edit-outline"></i><span>确认</span>
                </el-dropdown-item>
                <el-dropdown-item command="2" v-if="scope.row.auditStatus === '0' && btnLimit.includes('确认取消')">
                  <i class="el-icon-edit-outline"></i><span>取消</span>
                </el-dropdown-item>
                <el-dropdown-item command="3" v-if="scope.row.auditStatus ==='2' && btnLimit.includes('删除')">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
                <el-dropdown-item command="4" v-if="scope.row.auditStatus === '1' && scope.row.stauts === '0' && btnLimit.includes('撤回')">
                  <i class="el-icon-delete"></i><span>撤回</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="prePaymentData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import Utils from "../../util.js"
import { sellPrePaymentListApi, sellDelPrePaymentApi, sellCanclePrePaymentApi, sellReturnPrePaymentApi, sellConfirmPrePaymentApi, GyYskExportApi } from "@/api"
export default {
  name: "entryManage",
  data() {
    return {
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      prePaymentData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 12,
        cgCompanyName: '',
        createTimeStart: '',
        createTimeEnd: '',
        payTimeStart: '',
        payTimeEnd: '',
        auditStatus: '',
        status: '',
        orderNum: ''
      },
      chooseTime: '',
      chooseTime2: '',
      // 物料管理列表
      chargeList: [],
      // 列表动态高度
      tableHeight: '',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.prePaymentList()
    // 动态获取表格高度
    const that = this;
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152;
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152;
      })();
    };
  },
  activated() {
    if (this.$route.meta.isNeedRefresh || this.isFirstEnter) {
      this.prePaymentList()
    }
    this.isFirstEnter = false;
    this.$route.meta.isNeedRefresh = true;
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      // 1确认 2取消 3删除 4撤回
      if (command === '1') {
        this.editPrePaymentStatus(1, result.id)
      } else if (command === '2') {
        this.editPrePaymentStatus(2, result.id)
      } else if (command === '2') {
        this.editPrePaymentStatus(3, result.id)
      } else if (command === '2') {
        this.editPrePaymentStatus(4, result.id)
      }
    },
    // 修改预收单状态
    editPrePaymentStatus(val, result) {
      let newText
      if (val === 1) {
        newText = '确认'
      } else if (val === 2) {
        newText = '取消'
      } else if (val === 3) {
        newText = '删除'
      } else if (val === 4) {
        newText = '撤回'
      }
      this.$confirm('是否' + newText + '当前预收款单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        console.log(val, '?????????????????')
        if (val === 1) {
          // 确认
          const { data: res } = await sellConfirmPrePaymentApi(result)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '确认成功!' })
        } else if (val === 2) {
          // 取消
          const { data: res } = await sellCanclePrePaymentApi(result)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '取消成功!' })
        } else if (val === 3) {
          // 撤销
          const { data: res } = await sellReturnPrePaymentApi(result)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '撤销成功!' })
        } else if (val === 4) {
          // 删除
          const { data: res } = await sellDelPrePaymentApi([result])
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '删除成功!' })
        }
        this.prePaymentList()
      })
    },
    // 路由跳转
    routerJump(val, id) {
      this.$router.push({ path: "/sellPrePaymentDetail", query: { result: JSON.stringify(id) }})
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.prePaymentData.size = val
      this.prePaymentData.page = 1
      this.prePaymentList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.prePaymentData.page = val
      this.currentPage = val
      this.prePaymentList('reset')
    },
    // 点击切换时间表
    // 点击切换时间表
    getTime(result) {
      if (result === null) {
        this.prePaymentData.createTimeStart = ''
        this.prePaymentData.createTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.prePaymentData.createTimeStart = getYear + '-' + nowMonth + '-' + getDate
          } else {
            this.prePaymentData.createTimeEnd = getYear + '-' + nowMonth + '-' + getDate
          }
        })
      }
      this.prePaymentList()
    },
    // 点击切换时间表
    getTimes(result) {
      if (result === null) {
        this.prePaymentData.payTimeStart = ''
        this.prePaymentData.payTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value);
          let getYear = timers.getFullYear();
          let nowMonth = timers.getMonth() + 1;
          let getDate = timers.getDate();
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.prePaymentData.payTimeStart = getYear + '-' + nowMonth + '-' + getDate
          } else {
            this.prePaymentData.payTimeEnd = getYear + '-' + nowMonth + '-' + getDate
          }
        });
      }
      this.prePaymentList()
    },
    // 获取预收款列表
    async prePaymentList(reset) {
      if (reset !== 'reset') {
        this.prePaymentData.page = 1
      }
      const { data: res } = await sellPrePaymentListApi(this.prePaymentData)
      // 获取表单数据
      this.chargeList = res.data.list
      // 展示当前页面
      this.currentPage = res.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.totalPage
      // 展示总条数
      this.total = res.data.totalCount
    },
    async purchaseReturnExport() {
      const { data: res } = await GyYskExportApi(this.prePaymentData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.prePaymentList()
    }
  }
};
</script>
<style scoped lang='less'>
.prePaymentManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .el-select {
      margin: 0 20px 0 0;
    }
    .el-cascader {
      margin: 0 20px 0 0;
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    .el-table .cell.el-tooltip {
      img {
        width: 60px;
        height: 60px;
        border-radius: 4px;
      }
    }
    .stateBox1, .stateBox2, .stateBox3 {
      display: block;
      // width: 64px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
  }
}
</style>


