// 销售订单详情
<template>
  <div class="purchaseOrderDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/sellerOrder' }">销售单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button v-if="purchaseOrderDetail.state === 0" class="lightDeepStyle" @click="chooseCommand('1')"><i class="iconfont icon-fanhui-baise"></i>撤回</el-button>
      <el-button v-if="purchaseOrderDetail.state === 1" class="lightDeepStyle" @click="chooseCommand('2')"><i class="iconfont icon-wanjie"></i>完结</el-button>
      <el-button v-if="purchaseOrderDetail.state === 7" class="lightDeepStyle" @click="chooseCommand('3')"><i class="iconfont icon-shoudongwancheng"></i>确认</el-button>
      <el-button v-if="purchaseOrderDetail.state === 7" class="lightDeepStyle" @click="chooseCommand('4')"><i class="iconfont icon-quxiaoxinzeng"></i>取消</el-button>
      <el-button v-if="purchaseOrderDetail.state === 6" class="dangerStyle" @click="chooseCommand('5')"><i class="el-icon-delete"></i>删除</el-button>
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="purchaseOrderDetailContent">
      <header>
        <div class="headerTop">
          <h5>销售单编号: {{purchaseOrderDetail.code}}
            <span>({{purchaseOrderDetail.proposerName}})</span>
            <i @click="drawer = true">操作日志查询>></i>
            <!-- 抽屉 -->
            <el-drawer
              :visible.sync="drawer"
              :with-header="false"
              size="540px">
              <div class="drawerBox">
                <h6>操作日志<i @click="drawer = false" class="el-icon-close"></i></h6>
                <ul>
                  <li v-for="(item, index) in drawerData" :key="index">
                    <span>{{item.peopleName}}</span><span style="color: rgba(0, 0, 0, 0.60)">{{item.item1 + ': ' + (item.createTime).substring(0,16)}}</span>
                  </li>
                </ul>
              </div>
            </el-drawer>
          </h5>
          <p>签订人: <span>{{purchaseOrderDetail.proposerName}}</span> | 签订日期:  <span v-if="purchaseOrderDetail.createTime">{{(purchaseOrderDetail.createTime).substring(0, 10)}}</span> | 送货方式:  <span>{{purchaseOrderDetail.deliveryTypeName}}</span> | 收货地址:  <span>{{purchaseOrderDetail.deliveryAddressName ===  null ? '暂无' : purchaseOrderDetail.deliveryAddressName}}</span></p>
        </div>
        <div class="headerBottom">
          <div>
            <p>发货进度: </p>
            <el-progress :width="24" :show-text="false" type="circle" :percentage="purchaseOrderDetail.deliveryNumber / purchaseOrderDetail.number * 100"></el-progress>
            <span style="margin: 0 10px;">{{(purchaseOrderDetail.deliveryNumber / purchaseOrderDetail.number * 100).toFixed(2).replace('.00', '')}}%</span>
            <span>{{purchaseOrderDetail.deliveryNumber}}</span>
          </div>
          <div>
            <p>收货进度: </p>
            <el-progress color="#20D4CA" :width="24" :show-text="false" type="circle" :percentage="purchaseOrderDetail.takeDeliveryNumber / purchaseOrderDetail.number * 100"></el-progress>
            <span style="margin: 0 10px;">{{(purchaseOrderDetail.takeDeliveryNumber / purchaseOrderDetail.number * 100).toFixed(2).replace('.00', '')}}%</span>
            <span>{{purchaseOrderDetail.takeDeliveryNumber}}</span>
          </div>
        </div>
      </header>
      <main>
        <h6>汇总明细<i @click="routerJump(2)">相关订单</i><span>总金额: {{this.allPrice}}</span></h6>
        <el-table ref="tableHeight" :data="purchaseOrderDetail.lineEntityList" style="width: 100%;">
          <!-- <el-table-column key="1" show-overflow-tooltip prop="productCode" label="产品编号" min-width="100px" align="center"></el-table-column> -->
          <el-table-column key="2" show-overflow-tooltip prop="productName" label="产品名称" min-width="100px" align="center"></el-table-column>
          <el-table-column key="12" show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column key="13" show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column key="14" show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column key="3" show-overflow-tooltip prop="unitName" label="单位  " min-width="100px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="planNumber" label="数量" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span style="color: rgba(18, 62, 131, 1);">{{scope.row.number}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="9" show-overflow-tooltip prop="price" label="单价" min-width="100px" align="center"></el-table-column>
          <el-table-column key="10" show-overflow-tooltip label="单品总价" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{(scope.row.price * scope.row.number).toFixed(2)}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="11" show-overflow-tooltip prop="arriveTime" label="要求交货时间" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.arriveTime !== null">{{(scope.row.arriveTime).substring(0, 10)}}</span>
                <span v-else>-</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="3" show-overflow-tooltip prop="deliveryType" label="发货进度" min-width="160px"  align="center">
          <template slot-scope="scope">
            <el-progress :width="24" :show-text="false" type="circle" :percentage="scope.row.deliveryNumber / scope.row.number * 100"></el-progress>
            <span style="margin: 0 10px;">{{(scope.row.deliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
            <span>{{scope.row.deliveryNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column key="4" show-overflow-tooltip prop="deliveryType" label="收货进度" min-width="160px"  align="center">
          <template slot-scope="scope">
            <el-progress color="#20D4CA" :width="24" :show-text="false" type="circle" :percentage="scope.row.takeDeliveryNumber / scope.row.number * 100"></el-progress>
            <span style="margin: 0 10px;">{{(scope.row.takeDeliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
            <span>{{scope.row.takeDeliveryNumber}}</span>
          </template>
        </el-table-column>
          <el-table-column v-if="purchaseOrderDetail !== 7 && purchaseOrderDetail !== 6" key="5" show-overflow-tooltip prop="sellWarehouseNumber" label="实时库存数量" min-width="100px" align="center"></el-table-column>
          <el-table-column v-if="purchaseOrderDetail.state === 0 || purchaseOrderDetail.state === 1" key="6" show-overflow-tooltip prop="arriveTime" label="操作" min-width="100px" align="center">
            <template slot-scope="scope">
              <p style="cursor: pointer" @click="chooseCommand('6', scope.row)"><i style="margin-right: 5px;" class="el-icon-edit-outline"></i><span>录入实时库存</span></p>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
      </el-table>
      </main>
    </div>
    <!-- 录入实时库存弹出框 -->
    <template class="enterRealStockBox">
      <div>
        <el-dialog title="录入实时库存" :visible.sync="enterRealStockDialog" :close-on-click-modal="false" class="enterRealStockStyle" v-dialogDrag>
          <el-form :model="enterRealStockData" ref="ruleForm">
            <el-form-item v-for="(item, index) in  enterRealStockData" :key="index">
              <div>
                <span class="textHidden">{{item.productName}}</span><el-input @input="formatNum(item.sellWarehouseNumber, index)" oninput="if(value.length > 9) value=value.slice(0, 9)" v-model="item.sellWarehouseNumber" placeholder="输入库存数" clearable autocomplete="off"></el-input>
              </div>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="enterRealStockDialog = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="confirmEnter()">确认</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
  </div>
</template>
<script>
import { purchaseOrderDetailApi, getApprovalProcessApi, approvalpurchaseOrderApi, getsellerOrderChangeMsgApi, delSellerOrderApi, editPurchaseOrderStateApi, confirmEnterApi } from '@/api'
export default {
  data() {
    return {
      // 总金额
      allPrice: '',
      // 录入实时库存弹框
      enterRealStockDialog: false,
      enterRealStockData: [],
      // 抽屉切换
      drawerTab: true,
      // 抽屉开关
      drawer: false,
      // 抽屉数据
      drawerData: '',
      id: '',
      // 获取销售单详情
      purchaseOrderDetail: {
        lineEntityList: []
      },
      // 流程进度参数
      approvalProcessList: [],
      // 审批流程id
      approvalId: '',
      // 审批流程备注
      approvalRemark: '',
      showPlan: false
    }
  },
  created() {
    this.id = this.$route.query.result
    this.getPurchaseOrderDetail().then(() => {
      this.getAllChangeMsg()
    })
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.enterRealStockData[index].sellWarehouseNumber = temp;
    },
    // 确认录入
    async confirmEnter() {
      const { data: res } = await confirmEnterApi(this.enterRealStockData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.enterRealStockDialog = false
      this.$message.success('录入成功!')
      this.getPurchaseOrderDetail()
    },
    // 修改订单状态
    async editPurchaseOrderState(state) {
      let stateType = (state === 6 ? 1 : 0)
      const { data: res } = await editPurchaseOrderStateApi({ id: this.id, state: state, stateType: stateType })
      if (res.code !== 0) return this.$message.error(res.msg)
      if (state === 0) {
        this.$message.success('确认成功!')
      } else if (state === 6) {
        this.$message.success('取消成功!')
      } else if (state === 7) {
        this.$message.success('撤回成功!')
      } else if (state === 9) {
        this.$message.success('完结成功!')
      }
      this.getPurchaseOrderDetail().then(() => {
        this.getAllChangeMsg()
      })
    },
    chooseCommand(command, item) {
      if (command === '1') {
        // 确认=0 完结=9 撤回=7 取消=6
        this.$confirm("是否撤回该销售单?撤回后销售单状态变为【未确认】状态.", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(async () => {
          this.editPurchaseOrderState(7)
        })
      } else if (command === '2') {
        this.$confirm("还有部分产品未发货,是否直接完结该销售订单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(async () => {
          this.editPurchaseOrderState(9)
        })
      } else if (command === '3') {
        this.$confirm("是否确认该销售单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(async () => {
          this.editPurchaseOrderState(0)
        })
      } else if (command === '4') {
        this.$confirm("是否取消确认该销售单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(async () => {
          this.editPurchaseOrderState(6)
        })
      } else if (command === '5') {
        this.delSellerOrder()
      } else if (command === '6') {
        // 录入实时库存
        // 获取当前列表下的子列表数
        this.enterRealStockData = []
        this.enterRealStockData.push(item)
        this.enterRealStockDialog = true
      }
    },
    // 销售方删除采购订单
    delSellerOrder() {
      this.$confirm("是否确认删除该销售单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(async () => {
        const { data: res } = await delSellerOrderApi(this.id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getpurchaseOrderList()
      })
    },
    // 获取流程动态和数据日志
    async getAllChangeMsg() {
      const { data: res } = await getsellerOrderChangeMsgApi(this.id)
      this.drawerData = res.data.data
    },
    // 获取销售单详情
    async getPurchaseOrderDetail() {
      const { data: res } = await purchaseOrderDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.purchaseOrderDetail = res.data.data
      this.allPrice = 0
      this.purchaseOrderDetail.lineEntityList.forEach((item, index) => {
        this.allPrice = this.allPrice + ((item.price * item.number).toFixed(2) - 0)
      })
    },
    routerJump(val) {
      if (val === 1) {
        this.$router.back()
      } else {
        this.$router.push({ path: 'relateOrder', query: { id: this.id, code: this.purchaseOrderDetail.code } })
      }
    }
  }
};
</script>
<style lang="less" scoped>
.purchaseOrderDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
      font-size: 14px;
    }
  }
  .purchaseOrderDetailContent {
    margin-top: 40px;
    header {
      position: relative;
      margin: 20px 20px 0;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      .headerTop {
        margin-bottom: 20px;
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          .stateBox1, .stateBox2, .stateBox3 {
            margin-left: 10px;
            display: inline-block;
            width: 64px;
            height: 22px;
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            background: rgba(59,212,144,0.05);
            border: 1px solid rgba(59,212,144,0.2);
            color: rgba(59, 212, 144, 1);
            text-align: center;
            line-height: 22px;
          }
          .stateBox2 {
            background: rgba(239, 188, 96, 0.05);
            border: 1px solid rgba(239, 188, 96, 0.2);
            color: rgba(239, 188, 96, 1);
          }
          .stateBox3 {
            background-color: rgba(70, 147, 235, 0.05);
            border-color: rgba(70, 147, 235, 0.20);
            color: rgba(70, 147, 235, 1);
          }
          >i {
            position: absolute;
            right: 20px;
            padding: 0 8px;
            display: inline-block;
            height: 22px;
            border-radius: 4px 4px 4px 4px;
            cursor: pointer;
            font-size: 12px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            color: rgba(0, 0, 0, 0.4);
            text-align: center;
          }
        }
        >p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
      .headerBottom {
        border-top: 2px solid rgba(248, 249, 250, 1);
        display: flex;
        height: 60px;
        width: 100%;
        align-items: center;
        >div {
          display: flex;
          justify-content: flex-start;
          width: 50%;
          p {
            margin-right: 10px;
            color: rgba(0, 0, 0, 0.40);
          }
          span {
            font-weight: bold;
          }
        }
        
      }
    }
    aside {
      margin: 20px;
      padding: 20px 20px 1px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .el-button {
          padding: 0;
        }
      }
      ul {
        margin-top: 10px;
        width: 100%;
        li {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          height: 40px;
          width: 100%;
          line-height: 40px;
          .stepBox1, .stepBox2, .stepBox3 {
            position: relative;
            margin-top: 8px;
            margin-left: 8px;
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.10);
            line-height: 20px;
            color: rgba(0, 0, 0, 0.80);
            text-align: center;
            i {
              font-size: 12px;
              font-weight: bold;
            }
            .circle {
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #3BD490;
            }
            u {
              position: absolute;
              top: 32px;
              left: 12px;
              width: 2px;
              height: 17px;
              background-color: rgba(0, 0, 0, 0.15);
            }
          }
          .stepBox2 {
            background-color: rgba(59, 212, 144, 0.10);
          }
          .stepBox3 {
            margin-top: 4px;
            margin-left: 4px;
            width: 32px;
            height: 32px;
            background-color: rgba(18, 62, 131, 1);
            .circleBox {
              margin-top: 6.25px;
              display: inline-block;
              width: 17.5px;
              height: 17.5px;
              border-radius: 50%;
              border: 1px solid #fff;
              background-color: rgba(18, 62, 131, 1);
              line-height: 0;
              .circle {
                margin-top: 6.25px;
                width: 5px;
                height: 5px;
                border-radius: 0;
                background-color: #fff;
              }
            }
            u {
              top: 39px;
              left: 15px;
            }
          }
          .stepContent {
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            height: 40px;
            width: calc(100% - 44px);
            background: rgba(18,62,131,0.05);
            border-radius: 8px;
            box-sizing: border-box;
            font-size: 14px;
            p {
              width: 120px;
            }
            p:nth-child(4) {
              width: 35%;
              /deep/ .el-input {
                height: 40px;
                width: calc(100% - 80px);
                .el-input__inner {
                  height: 40px;
                  border: 0;
                  background-color: transparent;
                  font-size: 14px;
                }
                ::-webkit-input-placeholder {
                  color: rgba(18, 62, 131, 1);
                }
                ::-moz-input-placeholder {
                  color: rgba(18, 62, 131, 1);
                }
              }
            }
            p:nth-child(5) {
              float: right;
              width: 140px;
              text-align: right;
            }
            .stepBtn {
              padding: 0 16px;
            }
          }
        }
      }
    }
    main {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        span {
          float: right;
        }
        i {
          float: right;
          margin-top: -7px;
          margin-left: 10px;
          margin-bottom: 14px;
          width: 76px;
          height: 32px;
          background: #123E83;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          color: #fff;
          line-height: 32px;
          font-size: 14px;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
      .sellTypeBox1, .sellTypeBox2, .sellTypeBox3, .sellTypeBox4 {
        display: inline-block;
        width: 80px;
        height: 22px;
        border-radius: 4px;
        background-color: rgba(70, 147, 235, 0.05);
        border: 1px solid rgba(70, 147, 235, 0.2);
        color: rgba(70, 147, 235, 1);
      }
      .sellTypeBox2 {
        background-color: rgba(59, 212, 144, 0.05);
        border: 1px solid rgba(59, 212, 144, 0.2);
        color: rgba(59, 212, 144, 1);
      }
      .sellTypeBox3 {
        background-color: rgba(239, 188, 96, 0.05);
        border: 1px solid rgba(239, 188, 96, 0.2);
        color: rgba(239, 188, 96, 1);
      }
      .sellTypeBox4 {
        background-color: rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.20);
        color: rgba(0, 0, 0, 0.40);
      }
    }
  }
  .drawerBox {
    padding: 20px;
    width: 100%;
    height: 100vh;
    h6 {
      margin: 0;
      color: rgba(0, 0, 0, 0.60);
      font-size: 16px;
      font-weight: bold;
      i {
        float: right;
        font-size: 18px;
        cursor: pointer;
      }
    }
    ul {
      li {
        margin-top: 20px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        height: 40px;
        background-color: rgba(18, 62, 131, 0.05);
        border-radius: 40px;
        box-sizing: border-box;
        span {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
  }
  // 录入实时库存弹框
  .enterRealStockStyle {
    margin-top: 15vh;
    /deep/.el-dialog {
      height: 189px;
      width: 424px;
      .el-dialog__header {
        padding: 20px 20px 0px;
        .el-dialog__title {
          font-size: 16px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.88);
        }
        .el-dialog__headerbtn {
          top: 20px;
          .el-icon-close:before {
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
      .el-dialog__body {
        height: 70px;
        overflow-y: auto;
        .el-form-item {
          margin: 0;
          .el-form-item__content {
            margin: 20px 0 0;
            >div {
              display: flex;
              justify-content: space-between;
              span {
                height: 32px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.80);
                text-align: left;
              }
              .el-input {
                width: 270px;
              }
            }
          }
        }
      }
    }
  }
}
</style>