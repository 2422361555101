// 对账单列表
<template>
  <div class="balanceAccount">
    <nav>
      <h5>对账单</h5>
      <div>
        <el-button v-if="btnLimit.includes('新增对账单')" class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增对账单</el-button>
        <el-button class="lightDeepStyle" @click="createPay()"><i class="iconfont icon-change"></i>转付款单</el-button>
        <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="balanceAccountData.number" placeholder="请输入对账单编号" @input="getBalanceAccountList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="balanceAccountData.sellerName" placeholder="请输入客户名称" @input="getBalanceAccountList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="创建日期区间"
          end-placeholder="创建日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select v-model="balanceAccountData.status" placeholder="请选择付款状态" clearable size="mini" @change="getBalanceAccountList()">
        <el-option label="未付款" :value="0"></el-option>
        <el-option label="付款中" :value="1"></el-option>
        <el-option label="已付款" :value="2"></el-option>
      </el-select>
    </section>
    <main>
      <el-table @select="handleSelectionChange" @select-all="handleSelectionChange" ref="tableHeight" :height="tableHeight" :data="balanceAccountList" style="width: 100%;">
        <el-table-column type="selection" width="45" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="number" label="对账单编号" min-width="100px" align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.number}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="sellerName" label="客户" min-width="130px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="amountTax" label="总金额(含税)" min-width="100px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="amount" label="总金额(不含税)" min-width="100px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="120px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="status" label="付款状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.auditStatus != 2">
              <span v-if="scope.row.status === 2" class="statusBox1">已付款</span>
              <span v-else-if="scope.row.status === 0" class="statusBox2">未付款</span>
              <span v-else class="statusBox3">付款中</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="status" label="状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span style="color: rgba(239, 188, 96, 1)" v-if="scope.row.auditStatus == 0">未确认</span>
              <span style="color: rgba(70, 147, 235, 1)" v-else-if="scope.row.auditStatus == 1">已确认</span>
              <span style="color: rgba(0, 0, 0, 0.40)" v-else-if="scope.row.auditStatus == 2">已取消</span>
              <span style="color: rgba(0, 0, 0, 0.40)" v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="1" v-if="scope.row.auditStatus === 0 && btnLimit.includes('编辑对账单')">
                  <i class="el-icon-edit-outline"></i><span>编辑</span>
                </el-dropdown-item>
                <el-dropdown-item command="2" v-if="scope.row.auditStatus === 2">
                  <i class="el-icon-refresh-left"></i><span>删除</span>
                </el-dropdown-item>
                <el-dropdown-item command="3" v-if="scope.row.auditStatus === 1 && scope.row.status === 0">
                  <i class="el-icon-warning-outline"></i><span>取消对账</span>
                </el-dropdown-item>
                <el-dropdown-item command="4" v-if="scope.row.auditStatus === 0 && btnLimit.includes('撤回重做')">
                  <i class="el-icon-refresh-left"></i><span>撤回</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="balanceAccountData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import Utils from '../../util.js'
import { balanceAccountListApi, cancleBalanceAccountApi, createPayApi, delBalanceAccountApi, dzdCExportApi, returnBalanceAccountApi } from '@/api'
export default {
  name: 'balanceAccount',
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      // 对账单列表
      balanceAccountList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      balanceAccountData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        number: '',
        sellerName: '',
        startTime: '',
        endTime: '',
        status: ''
      },
      chooseTime: '',
      // 选择的对账单
      chooseBalance: [],
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getBalanceAccountList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      // 1编辑 2删除 3取消对账
      if (command === '1') {
        this.routerJump(3, result.id)
      } else if (command === '2') {
        this.delBalanceAccount(result.id)
      } else if (command === '3') {
        this.cancleBalanceAccount(result.id)
      } else {
        this.returnBalanceAccount(result.id)
      }
    },
    // 转付款单
    async createPay() {
      if (this.chooseBalance.length === 0) return this.$message.error('请先勾选对账单!')
      let controlError = false
      console.log(this.chooseBalance, '66666666 ')
      this.chooseBalance.forEach((item, index) => {
        if (item.auditStatus != 1) {
          controlError = true
        }
      })
      if (controlError === true) return this.$message.error('只有已确认的对账单能转订单')
      const { data: res } = await createPayApi({ list: this.chooseBalance })
      if (res.code !== 0) return this.$message.error('只能勾选未付款的对账单且客户必须唯一!')
      this.$message.success('操作成功!')
      this.getBalanceAccountList()
    },
    // 手动勾选的参数(对账单专用)
    handleSelectionChange(selection) {
      let arr = []
      selection.forEach(item => {
        arr.push({ id: item.id, sellerId: item.sellerId, auditStatus: item.auditStatus  })
      })
      this.chooseBalance = arr
    },
    // 撤回对账单
    returnBalanceAccount(id) {
      this.$confirm("是否确认撤回该对账单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(async () => {
          const { data: res } = await returnBalanceAccountApi(id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('撤回成功!')
          this.getBalanceAccountList()
        })
    },
    // 取消对账单
    cancleBalanceAccount(id) {
      this.$confirm("是否确认取消该对账单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(async () => {
          const { data: res } = await cancleBalanceAccountApi(id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('取消成功!')
          this.getBalanceAccountList()
        })
    },
    // 删除对账单
    delBalanceAccount(id) {
      this.$confirm("是否确认删除该对账单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await delBalanceAccountApi({ id: id, status: 3 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getBalanceAccountList()
      })
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: "/addBalanceAccount" })
      } else if (val === 2) {
        this.$router.push({ path: "/balanceAccountDetail", query: { result: JSON.stringify(result) } })
      } else {
        this.$router.push({ path: "/editBalanceAccount", query: { result: JSON.stringify(result) }})
      }
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.balanceAccountData.startTime = ''
        this.balanceAccountData.endTime = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value);
          let getYear = timers.getFullYear();
          let nowMonth = timers.getMonth() + 1;
          let getDate = timers.getDate();
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.balanceAccountData.startTime = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.balanceAccountData.endTime = getYear + '-' + nowMonth + '-' + getDate + ' 23:59:59'
          }
        });
      }
      this.getBalanceAccountList()
    },
    // 获取对账单列表
    async getBalanceAccountList(reset) {
      if (reset !== 'reset') {
        this.balanceAccountData.page = 1
      }
      const { data: res } = await balanceAccountListApi(this.balanceAccountData)
      // 获取表单数据
      this.balanceAccountList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    // -----------------------------------------------------------------
    
    // 触发每页显示条数
    handlesizeChange(val) {
      this.balanceAccountData.size = val
      this.balanceAccountData.page = 1
      this.getBalanceAccountList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.balanceAccountData.page = val
      this.currentPage = val
      this.getBalanceAccountList('reset')
    },
    async purchaseReturnExport() {
      const { data: res } = await dzdCExportApi(this.balanceAccountData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getBalanceAccountList()
    }
  }
}
</script>
<style scoped lang='less'>
.balanceAccount {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      .statusBox1, .statusBox2, .statusBox3{
        padding: 5px 8px;
        width: 52px;
        height: 22px;
        border-radius: 4px;        
        background: rgba(70,147,235,0.05);
        border: 1px solid rgba(70,147,235,0.2);
        font-size: 12px;
        color: rgba(70, 147, 235, 1);
      }
      .statusBox2 {
        border: 1px solid rgba(242,90,90,0.2);
        background: rgba(242,90,90,0.05);
        color: rgba(242, 90, 90, 1);
      }
      .statusBox3 {
        border: 1px solid rgba(59, 212, 144, 0.20);
        background: rgba(59, 212, 144, 0.05);
        color: rgba(59, 212, 144, 1);
      }
    }
  }
}
</style>
