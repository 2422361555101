// 修改扣款单
<template>
  <div class="editChargeBack">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/chargeBackManage' }">扣款管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">修改扣款单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editChargeBackContent">
      <nav>
        <h5>修改扣款单</h5>
      </nav>
      <main>
        <el-form ref="ruleForm" :model="chargeBackData" label-width="102px" :rules="rules">
          <el-form-item label="扣款名称:" prop="name">
            <el-input v-model="chargeBackData.name" placeholder="请输入扣款名称"></el-input>
          </el-form-item>
          <el-form-item label="供应商名称:" prop="sellerId">
            <el-select @change="chooseSell" v-model="chargeBackData.sellerId" placeholder="请选择供应商" size="mini" filterable>
              <el-option v-for="(item, index) in sellList" :key="index" :label="item.name" :value="item.sellerId" :value-key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="扣款金额:" prop="price">
            <el-input v-model="chargeBackData.price" placeholder="请输入扣款金额" type="number" @input="formatNum(chargeBackData.price, index)" oninput="if(value.length > 8) value=value.slice(0, 8)"></el-input>
            <!-- <p class="posBox">剩余金额: {{residuePirce === '' ? '自动识别' : residuePirce}}</p> -->
          </el-form-item>
          <el-form-item label="扣款理由:" prop="remark">
            <el-input v-model="chargeBackData.remark" placeholder="请输入扣款理由"></el-input>
          </el-form-item>
          <el-form-item label="关联采购单:" prop="type">
            <el-radio-group @input="changeType" style="margin: 10px 0 20px" v-model="chargeBackData.type">
              <el-radio :label="1">采购单</el-radio>
              <el-radio :label="2">产品</el-radio>
            </el-radio-group>
            <el-select v-if="chargeBackData.sellerId === ''" disabled placeholder="请先选择供应商" size="mini"></el-select>
            <el-select @change="chooseOrder" v-else-if="chargeBackData.type === 1" v-model="chargeBackData.orderId" placeholder="请输入关联单号/产品名称" clearable size="mini" filterable>
              <el-option v-for="(item, index) in purchaseOrderList" :key="index" :label="item.code" :value="item.id" :value-key="index"></el-option>
            </el-select>
            <el-select @change="chooseLine" v-else v-model="chargeBackData.lineId" placeholder="请输入关联单号/产品名称" clearable size="mini" filterable>
              <el-option v-for="(item, index) in purchaseMatterList" :key="index" :label="item.code + ' - ' +item.productName + '-' + (item.productSpecs === null || item.productSpecs === undefined ? '暂无' : item.productSpecs) + '-' + (item.productNewColour === null || item.productNewColour === undefined ? '暂无' : item.productNewColour)" :value="item.id" :value-key="index"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </main>
    </div>
    <footer>
      <el-button class="lightDeepStyle" @click="editChargeBack()">保存</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { sellerListApi, purchaseMatterListApi, purchaseOrderListApi, editChargeBackApi, chargeBackDetailApi, getBackPriceApi, moduleApi } from '@/api'
export default {
  data() {
    return {
      // 剩余金额
      residuePirce: '',
      // 供应商列表
      sellList: [],
      // 产品列表
      purchaseOrderList: [],
      // 传参参数
      purchaseOrderData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        productName: '',
        approveTimeBegin: '',
        approveTimeEnd: '',
        approveStatus: 2,
        state: 1,
        stateType: '',
        sellId: '',
        ifAwait: '',
        companyType: 1
      },
      // 采购单产品列表
      purchaseMatterList: [],
      // 传参参数
      purchaseMatterData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        sellId: '',
        codeAndProductName: '',
        codeAndProductNameAndProductCode: '',
        state: 1,
        kkstat: -1,
        approveStatus: 2
      },
      // 修改物料数据
      chargeBackData: {
        id: '',
        name: '',
        sellerId: '',
        price: '',
        remark: '',
        type: '',
        orderId: '',
        lineId: ''
      },
      // -------------------
      // 级联选择器分类
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'newChildList',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false,
        // 不关联父子节点
        checkStrictly: true
      },
      // 校验
      rules: {
        name: [
          { required: true, message: '扣款名称不能为空!', trigger: 'blur' },
          { min: 1, max: 15, message: '最多可输入15个字符', trigger: 'blur' }
        ],
        sellerId: [
          { required: true, message: '请选择供应商!', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '扣款金额不能为空!', trigger: 'blur' }
        ],
        remark: [
          { min: 1, max: 15, message: '最多可输入15个字符', trigger: 'blur' }
        ]
      },
      // 仓库列表
      storeHouseList: [],
      // 单位列表
      unitUserList: [],
      // 物料分类列表
      matterClassList: []
    }
  },
  created() {
    this.sellerList()
    this.chargeBackData.id = JSON.parse(this.$route.query.result)
    // 根据配置模块修改获取列表的传参
    this.getModuleList().then(() => {
      this.getChargeBackDetail()
    })
  },
  methods: {
    // 获取配置模块列表
    async getModuleList() {
      const { data: res } = await moduleApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      let moduleList = res.data.data
      if (moduleList[0].state === 1) {
        this.purchaseMatterData.state = 1
        this.purchaseOrderData.state = 1
      } else {
        // 旧版本是1,3,11
        this.purchaseMatterData.state = '0,1,3,8,9,10,11'
        this.purchaseOrderData.state = '0,1,3,8,9,10,11'
      }
    },
    // 选择订单 
    chooseOrder() {
      this.chargeBackData.lineId = ''
      this.getBackPrice()
    },
    // 获取扣款单剩余数量
    async getBackPrice() {
      const { data: res } = await getBackPriceApi({ id: '', orderId: this.chargeBackData.orderId, lineId: this.chargeBackData.lineId })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.residuePirce = res.data.balance
    },
    // 获取扣款单详情
    async getChargeBackDetail() {
      const { data: res } = await chargeBackDetailApi(this.chargeBackData.id);
      if (res.code !== 0) return this.$message.error(res.msg)
      let newData = res.data.detail
      this.chargeBackData = {
        id: newData.id,
        name: newData.name,
        sellerId: newData.sellerId,
        price: newData.price,
        remark: newData.remark,
        type: newData.type,
        orderId: newData.orderId,
        lineId: newData.lineId
      }
      if (this.chargeBackData.orderId === 0) {
        this.chargeBackData.orderId = ''
      }
      if (this.chargeBackData.type === 1) {
        this.getMatterManageList()
      } else {
        this.getPurcahseMatterList()
      }
      if (newData.lineList !== null && newData.lineList !== '' && newData.lineList.length !== 0) {
        this.chargeBackData.lineId = newData.lineList[0].id
      }
      this.getBackPrice()
    },
    // 选择供应商
    chooseSell() {
      this.purchaseMatterData.sellId = this.chargeBackData.sellerId
      this.purchaseOrderData.sellId = this.chargeBackData.sellerId
      if (this.chargeBackData.type === 1) {
        this.getMatterManageList()
      } else {
        this.getPurcahseMatterList()
      }
    },
    // 选择产品
    chooseLine(val) {
      this.purchaseMatterList.forEach((item, index) => {
        if (item.id === val) {
          this.chargeBackData.orderId = item.orderId
        }
      })
      this.getBackPrice()
    },
    // 选择类型
    changeType(val) {
      this.chargeBackData.orderId = ''
      this.chargeBackData.lineId = ''
      this.purchaseMatterData.sellId = this.chargeBackData.sellerId
      this.purchaseOrderData.sellId = this.chargeBackData.sellerId
      if (val === 1) {
        this.getMatterManageList()
      } else {
        this.getPurcahseMatterList()
      }
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.chargeBackData.price = temp;
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await sellerListApi({ search: '', page: 1, size: 9999, roleType: 1, status: 3 })
      this.sellList = res.data.page.list
    },
    // 获取采购单产品列表
    async getPurcahseMatterList() {
      const { data: res } = await purchaseMatterListApi(this.purchaseMatterData);
      if (res.code !== 0) return this.$message.error(res.msg)
      this.purchaseMatterList = res.data.data.list
    },
    // 获取采购单列表
    async getMatterManageList() {
      const { data: res } = await purchaseOrderListApi(this.purchaseOrderData);
      if (res.code !== 0) return this.$message.error(res.msg)
      this.purchaseOrderList = res.data.data.list
    },
    // 页面跳转
    routerJump () {
      this.$router.back()
    },
    // 新增物料
    editChargeBack() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const { data: res } = await editChargeBackApi(this.chargeBackData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '保存成功!' })
          setTimeout(() => {
          this.$router.back()
        }, 300)
        } else {
          return false
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
.editChargeBack {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editChargeBackContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    background: #fff;
    border-radius: 10px;
    overflow-y: auto;
    nav {
      padding: 20px;
      display: flex;
      height: 32px;
      justify-content: space-between;
      align-items: center;
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
    }
    main {
      padding: 0 20px;
      height: calc(100vh - 250px);
      overflow-y: auto;
      .el-form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 100%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 112px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
            .el-cascader {
              width: 100%;
            }
          }
        }
      }
      .avatar-uploader{
        width: 120px;
        height: 120px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: url('../../../assets/img/defaultPicture.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: top;
      }
      .avatar-uploader-icon{
        font-size: 0;
        color: #fff;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
      }
      .avatar-uploader-icon:hover{
        font-size: 28px;
        background-color: rgba(0, 0, 0, .3);
      }
      .avatar {
        position: relative;
        width: 120px;
        height: 120px;
        display: block;
      }
      .el-upload-action {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 120px;
        height: 120px;
        font-size: 0;
        color: #fff;
        text-align: center;
        line-height: 120px;
      }
      .el-upload-action:hover {
        font-size: 20px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .3)
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
}
</style>