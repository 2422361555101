// 转采购单
<template>
  <div class="addPurchaseOrder">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/purchaseOrder' }">采购单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">转采购单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addPurchaseOrderContent">
      <el-form ref="ruleForm" :model="addData" label-width="117px" :rules="rules">
        <nav>
          <h5>转采购单</h5>
          <div class="navBox">
            <el-form-item label="采购单编号:" prop="code">
              <el-input v-model="addData.code" placeholder="自动生成(可输入)"></el-input>
            </el-form-item>
            <el-form-item label="订单类型" prop="type">
              <el-radio-group v-model="addData.type">
                <el-radio :label="0">原材料采购</el-radio>
                <el-radio :label="1">外协生产订单</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="发票要求" prop="taxType">
              <el-select disabled v-model="addData.taxType" placeholder="请选择发票要求">
                <el-option label="不需要发票" :value="1"></el-option>
                <el-option label="增值税普通发票" :value="2"></el-option>
                <el-option label="增值税专用发票" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="供应商名称:" prop="sellId">
              <el-select disabled v-model="addData.sellId" placeholder="请选择供应商" clearable size="mini">
                <el-option v-for="(item, index) in sellList" :key="index" :label="item.name" :value="item.sellerId" :value-key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="采购签订人" prop="proposerId">
              <el-select v-model="addData.proposerId" placeholder="请选择签订人" clearable>
                <el-option v-for="(item, index) in userManageList" :key="index" :label="item.name" :value="item.userId" :value-key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收货地址" prop="deliveryAddress">
              <el-select v-model="addData.deliveryAddress" placeholder="请选择收货地址">
                <el-option v-for="(item, index) in addressList" :key="index" :label="item.province + item.city + item.district + item.area" :value="item.id"></el-option>
                <el-option label="" value="" class="addSelectBtn" @click.native="dialogVisibleFn()"><i class="el-icon-plus"></i>新增</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="送货方式" prop="deliveryType">
              <el-radio-group v-model="addData.deliveryType">
                <el-radio :label="0">自提</el-radio>
                <el-radio :label="1">供方送货</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </nav>
        <main>
          <h5>订单明细</h5>
          <ul v-if="addData.lineEntityList.length !== 0">
            <el-checkbox-group v-model="chooseList">
              <li :style="index + 1 === addData.lineEntityList.length ? 'border-bottom: 0' : ''" v-for="(item, index) in addData.lineEntityList" :key="index">
                <div class="liUp">
                  <p>
                    <el-checkbox :label="item.productId"><span class="textHidden">{{item.productCode + '-' + item.productName + '-' + (item.unitName === null ? '暂无' : item.unitName) + '-' + (item.productSpecs === null ? '暂无' : item.productSpecs) + '-' + (item.productNewColour === null ? '暂无' : item.productNewColour)}}</span><span>需求数量: {{item.needNumber}}</span></el-checkbox>
                    <!-- <i @click="delMatter(index)" class="el-icon-delete"></i> -->
                  </p>
                </div>
                <div class="liDown">
                  <el-form-item label="本次订购数量:" :prop="'lineEntityList.' + index + '.number'" :rules="rules.number">
                    <el-input disabled type="number" @input="formatNum(item.number, index, item.needNumber)" oninput="if(value.length > 8) value=value.slice(0, 8)" v-model="item.number" placeholder="输入数值"></el-input>
                  </el-form-item>
                  <el-form-item label="单价:" :prop="'lineEntityList.' + index + '.price'" :rules="rules.price">
                    <el-input type="number" @input="formatNum2(item.price, index, '')" oninput="if(value.length > 10) value=value.slice(0, 10)" v-model="item.price" placeholder="输入数值"></el-input>
                    <i v-if="item.number === '' || item.price === ''">单品总价: 自动读取</i>
                    <i class="textHidden" v-else>单品总价: {{((item.number * 100) * (item.price * 100) / 10000).toFixed(2)}}</i>
                  </el-form-item>
                  <el-form-item label="要求到货时间:">
                    <el-date-picker :picker-options="pickerOptions" v-model="item.arriveTime" type="date" placeholder="到货日期选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
                  </el-form-item>
                </div>
              </li>
            </el-checkbox-group>
          </ul>
          <div v-else class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:14%; margin-top: 0%;">
          </div>
        </main>
      </el-form>
      <!-- 新增收货地址弹框 -->
      <el-dialog class="addressDialog" title="新增收货地址" :visible.sync="dialogVisible" :close-on-click-modal="false" v-dialogDrag>
        <el-form :model="addAddressData" :rules="rules2" ref="ruleForm2">
          <el-form-item label="收货人" prop="name">
            <el-input id="formId" v-model="addAddressData.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input id="formId" v-model="addAddressData.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-row class="selectRow">
            <el-col :span="6">
              <label><i style="color: red;">*</i>所在地区</label>
            </el-col>
            <el-col :span="6">
              <el-form-item  prop="province" class="selectStyle">
                <div class="grid-content bg-purple">
                  <el-select class="custom-select" style="width:80px;" v-model="addAddressData.province" placeholder="省" @change="areaChange">
                    <el-option v-for="(item,index) in oneArealist" id="formId" :key="index" :label="item.name" :value="item.name"/>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="city" class="selectStyle">
                <div class="grid-content bg-purple-light">
                  <el-select v-model="addAddressData.city" placeholder="市" @change="areaChange2" style="width:80px;">
                    <el-option v-for="(item,index) in twoArealist" id="formId" :key="index" :label="item.name" :value="item.name"/>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="district" class="selectStyle">
                <div class="grid-content bg-purple">
                  <el-select v-model="addAddressData.district" placeholder="区" style="width:80px;">
                    <el-option v-for="(item,index) in treeArealist" id="formId" :key="index" :label="item.name" :value="item.name"/>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="详细地址" prop="area">
            <el-input id="formId" v-model="addAddressData.area" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item style="float: right;">
            <el-button type="primary" @click="okFn('ruleForm2')">确 定</el-button>
          <el-button  @click="dialogVisible = false">取 消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <footer>
      <div class="priceBox">
        <p v-if="allMoney.moneyTax && allMoney.moneyTax !== -1">报价总金额(含税)<span>{{(allMoney.moneyTax).toFixed(2)}}</span></p>
        <p v-if="allMoney.money">报价总金额(不含税)<span>{{(allMoney.money).toFixed(2)}}</span></p>
        <p>订单总金额(不含税)<span>{{(allMoney.orderMoney).toFixed(2)}}</span></p>
      </div>
      <el-button class="lightDeepStyle" @click="addPurchaseOrder(1)">提交</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { addPurchaseOrderApi, submitPurchaseOrderApi, getPlanOrderListApi, getUserManageApi, sellerListApi, matterListApi, siteListApi, askPricePurchaseDetailApi, quoteList2Api, getProvinceAllApi, siteAddApi } from '@/api'
export default {
  data() {
    return {
      // 收货地址弹框
      dialogVisible: false,
      // 新增收货地址
      addAddressData: {
        province: '',
        city: '',
        district: '',
        area: '',
        name: '',
        phone: ''
      },
      // 一级
      oneArealist: [],
      // 二级
      twoArealist: [],
      // 三级
      treeArealist: [],
      // 表单验证
      rules2: {
        name: [
          { required: true, message: '请输入收货人名称' }
        ],
        province: [
          { required: true, message: '请选择省份' }
        ],
        city: [
          { required: true, message: '请选择城市' }
        ],
        district: [
          { required: true, message: '请选择区' }
        ],
        area: [
          { required: true, message: '请输入详细地址' }
        ],
        phone: [
          { required: true, message: '请输入手机号' },
          { pattern: /^1[0-9]{10}$/, message: '手机号格式不正确' }
        ]
      },
      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // 收货地址
      addressList: [],
      // 日期选择器限制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 8.64e7
        }
      },
      // 控制弹框
      choosePlanDialog: false,
      chooseOrderDialog: false,
      // 新增参数
      addData: {
        sellId: "",
        type: 0,
        proposerId: JSON.parse(window.sessionStorage.userInfo).userId,
        deliveryType: 1,
        deliveryAddress: '',
        source: '',
        taxType: '',
        lineEntityList: []
      },
      // 签订人列表传参
      userManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 999,
        // 搜索内容
        name: "",
        companyId: window.$cookies.get("companyId"),
        state: "",
        isActivate: "",
        deptId: ''
      },
      // 签订人列表
      userManageList: [],
      // 选中的项
      chooseList: [],
      // 计划单对应物料传参
      matterManageData: {
        page: 1,
        size: 100,
        productName: '',
        selectAll: '',
        planIds: '',
        approveStatus: 2
      },
      // 传参参数
      matterData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        status: "1",
        typeId: "",
        name: "",
        codeAndName: "",
        productType: ""
      },
      // 产品列表
      matterManageList: [],
      /// ----------------------------------------------
      // 选中的物料
      matterList: [],
      // 校验
      rules: {
        price: [
          { required: true, message: '单价不能为空!', trigger: 'blur' },
        ],
        number: [
          { required: true, message: '本次订购数不能为空!', trigger: 'blur' },
        ],
        sellId: [
          { required: true, message: '供应商不能为空!', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '订单类型不能为空!', trigger: 'blur' },
        ],
        proposerId: [
          { required: true, message: '签订人不能为空!', trigger: 'blur' },
        ],
        deliveryType: [
          { required: true, message: '送货方式不能为空!', trigger: 'blur' },
        ],
        deliveryAddress: [
          { required: true, message: '收货地址不能为空!', trigger: 'blur' },
        ]
      },
      // 供应商列表
      sellList: [],
      // 判断是不是转订单过来的
      showPlan: false,
      askPriceId: '',
      allMoney: {
        money: 0.00,
        moneyTax: 0.00,
        orderMoney: 0.00
      }
    }
  },
  created() {
    if (this.$route.query.result !== undefined) {
      this.matterList = JSON.parse(this.$route.query.result)
      this.choosePlanMatter()
      this.showPlan = true
    } else {
      this.showPlan = false
    }
    this.getUserManage()
    this.sellerList()
    this.getModuleList()
    this.askPriceId = this.$route.query.askPriceId
    this.addData.source = this.$route.query.source
    this.getAskPricePurchaseDetail()
    this.getDistrictList()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 收货地址弹出框
    dialogVisibleFn() {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields()
      }
      this.dialogVisible = true
    },
    // 省市区联动事件
    areaChange(value) {
      // value就是一级分类code值
      // 先遍历所有的分类 里面包含一级和二级
      for (var i = 0; i < this.oneArealist.length; i++) {
        // 每个一级分类
        var oneSubject = this.oneArealist[i]
        // 判断：所有一级分code和点击一级分code是否一样
        // 如果没code，使用数组内其他属性对比也可以，比如name等
        if (value === oneSubject.name) { //= ==即比较值 还要比较类型
          // 从一级分类中获取所有的二级分类
        //   console.log();
          this.twoArealist = oneSubject.childList
          console.log(this.twoArealist)
          // 把二级分code值清空
          this.addAddressData.city = ''
        }
      }
    },
    areaChange2(value) {
      // value就是一级分类code值
      // 先遍历所有的分类 里面包含一级和二级
      for (var i = 0; i < this.twoArealist.length; i++) {
        // 每个一级分类
        var oneSubject = this.twoArealist[i]
        // 判断：所有一级分code和点击一级分code是否一样
        // 如果没code，使用数组内其他属性对比也可以，比如name等
        if (value === oneSubject.name) { //= ==即比较值 还要比较类型
          // 从一级分类中获取所有的二级分类
          this.treeArealist = oneSubject.childList
          // 把二级分code值清空
          this.addAddressData.district = ''
        }
      }
    },
    // 获取省市区数据
    async getDistrictList() {
      const { data: res } = await getProvinceAllApi()
      this.oneArealist = res.data.list
    },
    // 确定关闭弹框
    okFn(ruleForm) {
      this.$refs[ruleForm].validate(async(valid) => {
        if (valid) {
          const { data: res } = await siteAddApi(this.addAddressData)
          if (res.code === 0) {
            this.$message({
              type: 'success', // success error warning
              message: '添加成功',
              duration: 2000
            })
            this.getModuleList()
            this.$refs[ruleForm].resetFields()
            this.dialogVisible = false
          } else {
            this.$message({
              type: 'error', // success error warning
              message: '添加失败',
              duration: 2000
            })
            this.getModuleList()
            this.$refs[ruleForm].resetFields()
            this.dialogVisible = false
          }
        } else {
          return false
        }
      })
    },
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // 获取询价详情
    async getAskPricePurchaseDetail() {
      const { data: res } = await askPricePurchaseDetailApi(this.askPriceId)
      if (res.code !== 0) return this.$message.error(res.msg)
      let askPricePurchaseDetail = res.data.data
      // 报价列表(审核报价用)
      const { data: ress } = await quoteList2Api({ round: askPricePurchaseDetail.nowRound, enquiryId: this.askPriceId, offerIds: askPricePurchaseDetail.offerIds })
      if (ress.code !== 0) return this.$message.error(ress.msg)
      // 新增参数
      this.addData = {
        sellId: ress.data.data[0].sellId,
        type: 0,
        proposerId: JSON.parse(window.sessionStorage.userInfo).userId,
        deliveryType: 1,
        deliveryAddress: askPricePurchaseDetail.jiaofuId,
        source: this.$route.query.source - 0,
        taxType: askPricePurchaseDetail.invoiceType,
        lineEntityList: []
      }
      // 获取确认报价的单据
      let newIndex = ''
      askPricePurchaseDetail.offerEntity[askPricePurchaseDetail.nowRound - 1].some((item, index) => {
        if (item.state === 4) {
          newIndex = index
        }
      })
      askPricePurchaseDetail.offerEntity[askPricePurchaseDetail.nowRound - 1][newIndex].offerLineEntityList.forEach((item, index) => {
        let newData = {
          productId: item.productId,
          productCode: item.productCode,
          productName: item.productName,
          productSpecs: item.productSpecs,
          productNewColour: item.productNewColour,
          unitName: item.productUnitName,
          number: item.number,
          needNumber: item.number,
          arriveTime: item.arriveTime, //要求交货时间
          price: item.price,
          residueNumber: '', // 剩余数量
          planResidueNumber: '', // 计划剩余数量
          planLineId: ''
        }
        this.addData.lineEntityList.push(newData)
        // 判断是不是整单报价
        if (askPricePurchaseDetail.offerType === 1) {
          // 1如果是单品报价
          this.allMoney.money = this.allMoney.money  + (item.money)
          this.allMoney.orderMoney = this.allMoney.orderMoney + ((item.number * 100) * (item.price * 100) / 10000)
          // 如果是不含税报价
          if (askPricePurchaseDetail.offerAsk === 2) {
            this.allMoney.moneyTax = -1
          } else {
            this.allMoney.moneyTax = this.allMoney.moneyTax + (item.moneyTax)
          }
        } else {
          // 如果是整单报价
          this.allMoney.money = askPricePurchaseDetail.offerEntity[askPricePurchaseDetail.nowRound - 1][newIndex].money
          this.allMoney.moneyTax = askPricePurchaseDetail.offerEntity[askPricePurchaseDetail.nowRound - 1][newIndex].moneyTax
          this.allMoney.orderMoney = 0
        }
        
        console.log(this.allMoney, '23333', askPricePurchaseDetail.offerAsk, this.addData)
      })
    },
    // 获取收货地址
    async getModuleList() {
      const { data: res } = await siteListApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.addressList = res.data
      // 获取默认地址
      this.addressList.forEach((item, index) => {
        if (item.def === 1) {
          this.addData.deliveryAddress = item.id
        }
      })
    },
    // 申请人列表
    async getUserManage() {
      const { data: res } = await getUserManageApi(this.userManageData)
      this.userManageList = res.data.page.list
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await sellerListApi({ search: '', page: 1, size: 9999, roleType: 1, status: 3 })
      this.sellList = res.data.page.list
    },
    // 打开添加弹框
    openAddDialog(val) {
      if (val === 1) {
        this.getMatterManageList()
        this.chooseOrderDialog = true
      } else {
        this.getPlanOrderList()
        this.choosePlanDialog = true
      }
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    },
    // 获取产品列表
    async getMatterManageList() {
      const { data: res } = await matterListApi(this.matterData);
      if (res.code !== 0) return this.$message.error(res.msg)
      let newMatterManageList = res.data.page.list
      // 去除列表重复项
      if (this.addData.lineEntityList.legnth !== 0) {
        this.addData.lineEntityList.forEach(item => {
          newMatterManageList.forEach((val, index) => {
            if (item.productId === val.productId) {
              newMatterManageList.splice(index, 1)
            }
          })
        })
      }
      this.matterManageList = newMatterManageList
    },
    // 获取计划单对应的物料列表
    async getPlanOrderList() {
      const { data: res } = await getPlanOrderListApi(this.matterManageData)
      if (res.code !== 0) return this.$message.error(res.msg)
      let newMatterManageList = res.data.data.list
      // 去除列表重复项
      if (this.addData.lineEntityList.legnth !== 0) {
        this.addData.lineEntityList.forEach(item => {
          newMatterManageList.forEach((val, index) => {
            if (item.planLineId === val.id) {
              newMatterManageList.splice(index, 1)
            }
          })
        })
      }
      this.matterManageList = newMatterManageList
    },
    // 计划选择
    choosePlanMatter() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      this.matterList.forEach((item, index) => {
        let newData = {
          planCode: item.planCode,
          productId: item.productId,
          productCode: item.productCode,
          productName: item.productName,
          productSpecs: item.productSpecs,
          productNewColour: item.productNewColour,
          unitName: item.unitName,
          number: '',
          needNumber: item.number,
          arriveTime: item.arriveTime, //要求交货时间
          price: '',
          planResidueNumber: item.residueNumber, // 计划剩余数量
          residueNumber: '', //剩余数量
          planLineId: item.id
        }
        this.addData.lineEntityList.push(newData)
      })
      this.choosePlanDialog = false
    },
    // 产品选择
    chooseMatter() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      this.matterList.forEach((item, index) => {
        let newData = {
          productId: item.id,
          productCode: item.code,
          productName: item.name,
          productSpecs: item.specs,
          productNewColour: item.newColour,
          unitName: item.unit,
          number: '',
          needNumber: '',
          arriveTime: '', //要求交货时间
          price: '',
          residueNumber: '', // 剩余数量
          planResidueNumber: item.residueNumber, // 计划剩余数量
          planLineId: ''
        }
        this.addData.lineEntityList.push(newData)
      })
      this.chooseOrderDialog = false
    },
    // 删除产品
    delMatter(index) {
      if (index === 'all') {
        if (this.chooseList.length === 0) return this.$message.error('请先勾选需要删除的产品')
        this.$confirm('是否删除勾选产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.chooseList.forEach(porductId => {
            this.addData.lineEntityList.forEach((val, index) => {
              if (porductId === val.productId) {
                this.addData.lineEntityList.splice(index, 1)
              }
            })
          })
          // 清空选中项
          this.chooseList = []
        })
      } else {
        this.$confirm('是否确认删除当前产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.addData.lineEntityList.splice(index, 1)
        })
      }
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index, max) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      // 判断计划数不得大于最大可计划数
      if (temp > max) {
        temp = max
      }
      this.addData.lineEntityList[index].number = temp;
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum2(val, index) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.addData.lineEntityList[index].price = temp;
      // 计算总金额
      this.allMoney.orderMoney = 0
      this.addData.lineEntityList.forEach((item, index) => {
        if (item.number !== '' && item.price !== '') {
          this.allMoney.orderMoney = this.allMoney.orderMoney + ((item.number * 100) * (item.price * 100) / 10000)
        }
      })
    },
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 新增采购单
    addPurchaseOrder(val) {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.addData.lineEntityList.length == 0) return this.$message.error('订单明细不能为空!')
          const { data: res } = await addPurchaseOrderApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          // 判断是否直接提交
          if (val === 1) {
            // --------
            if (this.allMoney.orderMoney !== this.allMoney.money) {
              this.$confirm('报价总价(不含税)与订单总价(不含税)不等,是否确认提交?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false
              }).then(() => {
                this.submitPurchaseOrder(res.data.data.id)
              })
            } else {
              this.submitPurchaseOrder(res.data.data.id)
            }
          } else {
            this.$message.success('新增采购单成功!')
            setTimeout(() => {
              this.$router.replace({ path: "/purchaseOrderDetail", query: { result: JSON.stringify(res.data.data.id) }})
            }, 300)
          }
        } else {
          return false
        }
      })
    },
    // 提交计划单
    async submitPurchaseOrder(id) {
      const { data: res } = await submitPurchaseOrderApi({ id: id, enquiryId: this.askPriceId })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('新增并提交采购单成功!')
      setTimeout(() => {
        this.$router.replace({ path: "/purchaseOrderDetail", query: { result: JSON.stringify(id) }})
      }, 300)
    },
    // -----------------------------------------------
    // 清空列表数据
    clearValue() {
      if (this.addData.lineEntityList.length !== 0) {
        this.$confirm('变更供应商将清空已添加的计划明细?', '变更确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          // 清空选中项
          this.addData.lineEntityList = []
        })
      }
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    }
  }
}
</script>
<style scoped lang='less'>
.addPurchaseOrder {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addPurchaseOrderContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 190px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 2px;
      padding: 20px;
      height: 218px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      h6 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .navBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 33.3%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 102px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
          }
        }
        /deep/.el-form-item:nth-child(4), /deep/.el-form-item:nth-child(5), /deep/.el-form-item:nth-child(6), /deep/.el-form-item:nth-child(7) {
          width: 50%;
        }
      }
    }
    main {
      position: relative;
      padding: 20px;
      height: calc(100vh - 470px);
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        overflow-y: auto;
        height: calc(100vh - 572px);
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              width: 100%;
              color: rgba(0, 0, 0, 0.60);
              span:nth-child(1) {
                display: inline-block;
                width: 400px;
              }
              span:nth-child(2) {
                display: inline-block;
                width: 120px;
              }
              span:nth-child(3) {
                display: inline-block;
                width: 120px;
              }
            }
            >p {
              height: 24px;
              width: 100%;
              font-size: 14px;
              >span {
                margin-left: 30%;
                display: inline-block;
                width: 220px;
                color: rgba(0, 0, 0, 0.60);
              }
              i {
                float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 33.3%;
              .el-form-item__content {
                position: relative;
                width: calc(100% - 117px);
                .el-input {
                  width: 100%;
                }
                .el-select {
                  width: 100%;
                  /deep/ .el-input {
                    width: 100%;
                  }
                }
                >i {
                  position: absolute;
                  top: 0;
                  right: 10px;
                  width: 140px;
                  text-align: right;
                }
              }
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 0 20px;
    height: 112px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
    .priceBox {
      padding: 16px 0px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      p {
        margin-right: 20px;
        width: 27%;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.80);
        span {
          margin-left: 22px;
          padding: 0 10px;
          display: inline-block;
          width: calc(100% - 180px);
          height: 32px;
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          color: rgba(0, 0, 0, 0.80);
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
          vertical-align: middle;
        }
      }
    }
  }
  /deep/ .choosePlanDialog, /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
  /deep/ .addressDialog {
    .el-dialog {
      width: 405px;
      height: 400px;
      font-weight: 400;
      color: rgba(0,0,0,1);
      line-height: 34px;
      .el-dialog__body {
        height: 100%;
        .el-form-item .el-input{
            width: 100%;
        }
      }
    }
  }
}
</style>
