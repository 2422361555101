// 库存管理
<template>
  <div class="outManage">
    <nav>
      <h5>库存管理</h5>
      <div>
        <!-- <el-button class="lightDeepStyle" @click="routerJump(2, '')"><i class="el-icon-plus"></i>产品入库</el-button> -->
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="wareData.productName" placeholder="产品名称搜索" @input="getWarehouseList" prefix-icon="el-icon-search"></el-input>
      <el-select v-model="wareData.warehouseName" placeholder="请选择归属仓库" size="mini" clearable @change="getWarehouseList">
        <el-option v-for="item in storeHouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </section>
    <main>
      <el-table ref="tableHeight" :height="tableHeight" :data="warehouseList" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="code" label="产品名称" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="detailFn(scope.row.id)">{{scope.row.productName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="warehouseName" label="归属仓库" min-width="120px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="newMaterials" label="材料" min-width="100px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.newMaterials === '' || scope.row.newMaterials === null ? '暂未填写' : scope.row.newMaterials}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="100px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.unitName === '' || scope.row.unitName === null ? '暂未填写' : scope.row.unitName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="number" label="库存数量" min-width="100px" align="center">
          <template slot-scope="scope">
            <span style="color: rgba(0, 0, 0, 0.60)">{{scope.row.number}}</span>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="wareData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import Utils from "../util.js"
import { storeHouseListAllApi, warehouseListApi, delEntryApi } from "@/api"
export default {
  name: "outManage",
  data() {
    return {
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      wareData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 12,
        warehouseName: '',
        productName: ''
      },
      chooseTime: '',
      // 获取仓库列表参数
      storeHouseListData: {
        page: 1,
        // 当前页条数
        size: 9999,
        code: '',
        name: '',
        enabled: 1
      },
      // 仓库列表
      storeHouseList: [],
      // 物料管理列表
      warehouseList: [],
      // 列表动态高度
      tableHeight: '',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getWarehouseList()
    this.getStoreHouseList()
    // 动态获取表格高度
    const that = this;
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152;
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152;
      })();
    };
  },
  activated() {
    if (this.$route.meta.isNeedRefresh || this.isFirstEnter) {
      this.getWarehouseList()
      
    }
    this.isFirstEnter = false;
    this.$route.meta.isNeedRefresh = true;
  },
  methods: {
    // 路由跳转
    routerJump(val, result) {
      if (val === 2) {
        this.$router.push({ path: "/addOutbound" })
      } else {
        this.$router.push({ path: "/outDetail", query: { result: JSON.stringify(result) }})
      }
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.wareData.size = val
      this.wareData.page = 1
      this.getWarehouseList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.wareData.page = val
      this.currentPage = val
      this.getWarehouseList('reset')
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.wareData.outboundTimeBegin = ''
        this.wareData.outboundTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value);
          let getYear = timers.getFullYear();
          let nowMonth = timers.getMonth() + 1;
          let getDate = timers.getDate();
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.wareData.outboundTimeBegin = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.wareData.outboundTimeEnd = getYear + '-' + nowMonth + '-' + getDate + ' 23:59:59'
          }
        });
      }
      this.getWarehouseList()
    },
    // 获取仓库列表
    async getStoreHouseList() {
      const { data: res } = await storeHouseListAllApi(this.storeHouseListData)
      this.storeHouseList = res.data.page.list
    },
    // 获取库存列表
    async getWarehouseList(reset) {
      if (reset !== 'reset') {
        this.wareData.page = 1
      }
      const { data: res } = await warehouseListApi(this.wareData)
      // 获取表单数据
      this.warehouseList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    detailFn(id){
      this.$router.push({
        path: '/detailAllocationManage',
        query: {
          id:id
        },
      })
    }
  }
};
</script>
<style scoped lang='less'>
.outManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .el-select {
      margin: 0 20px 0 0;
    }
    .el-cascader {
      margin: 0 20px 0 0;
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      font-weight: bold;
    }
  }
}
</style>


