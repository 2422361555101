// 采购订单详情
<template>
  <div class="purchaseOrderDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/purchaseOrder' }">采购单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <!-- 0 制单 1进行中 2 审批完成 3驳回 4 手动结束 -->
      <el-button v-if="purchaseOrderDetail.approveStatus === 3" class="lightDeepStyle" @click="routerJump(2)"><i class="el-icon-edit-outline"></i>变更</el-button>
      <el-button v-else-if="purchaseOrderDetail.approveStatus !== 4 && (purchaseOrderDetail.state === 4 || purchaseOrderDetail.state === 5 || purchaseOrderDetail.state === 7)" class="lightDeepStyle" @click="routerJump(2)"><i class="el-icon-edit-outline"></i>编辑</el-button>
      <el-button v-if="purchaseOrderDetail.approveStatus !== 1 && purchaseOrderDetail.state === 7" class="dangerStyle" @click="delPurchaseOrder()"><i class="el-icon-delete"></i>删除</el-button>
      <el-button v-if="purchaseOrderDetail.approveStatus === 1" class="dangerStyle" @click="purchaseOrderEnd()"><i class="el-icon-switch-button"></i>结束</el-button>
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="purchaseOrderDetailContent">
      <header>
        <div class="headerTop">
          <h5>采购单编号: {{purchaseOrderDetail.code}}
            <span class="stateBox1" v-if="purchaseOrderDetail.state === 0">待发货</span>
            <span class="stateBox1" v-if="purchaseOrderDetail.state === 1">已入库</span>
            <span class="stateBox3" v-if="purchaseOrderDetail.state === 2">已对账</span>
            <span class="stateBox2" v-if="purchaseOrderDetail.state === 3">部分入库</span>
            <i @click="drawer = true">流程动态/数据日志查询>></i>
            <!-- 抽屉 -->
            <el-drawer
              :visible.sync="drawer"
              :with-header="false"
              size="540px">
              <div class="drawerBox">
                <p @click="drawerTab = !drawerTab"><span :class="drawerTab === true ? 'drawerShow' : ''">流程动态</span><span :class="drawerTab === false ? 'drawerShow' : ''">数据日志</span></p>
                <div v-if="drawerTab === true" class="drawerLeft">
                  <ul v-if="drawerData.approveMsg && drawerData.approveMsg.length !== 0">
                    <li v-for="(item, index) in drawerData.approveMsg" v-bind:key="index">
                      <div class="drawerNav">
                        <div v-if="item.item1 === '流程结束'" class="stepBox3"><i class="circleBox"><i class="circle"></i></i></div>
                        <div v-else class="stepBox1"><i class="el-icon-arrow-up"></i></div>
                        <div class="drawerHeader">
                          <span>{{item.item1}}</span><span>{{item.createTime}}</span>
                        </div>
                      </div>
                      <div class="drawerBody">
                        <div class="drawerContent">
                          <p v-if="item.item1 !== '流程结束'"><span>{{item.peopleName}}</span><span style="color: rgba(0, 0, 0, 0.60);">{{item.item2}}</span></p>
                          <p v-if="item.expendTime !== null"><span style="color: rgba(0, 0, 0, 0.60);" v-if="item.item1 !== '流程结束'">开始处理时间: {{(item.createTime).substring(0, 16)}}</span><span style="color: rgba(0, 0, 0, 0.60);">累积耗时: {{item.expendTimeAll}}</span></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div v-else slot="empty" class="emptyBg">
                    <img src="../../assets/img/notData.png" style="width:36%; margin-top: 150px;">
                    <span style="display: block; margin-top: -4%">暂无数据</span>
                  </div>
                </div>
                <div v-else class="drawerRight">
                  <ul class="drawerRightBox">
                    <li v-for="(item, index) in drawerData.changeMsg" v-bind:key="index">
                      <div>
                        <span>{{item.proposerName}}</span><span style="color: rgba(0, 0, 0, 0.60);">修改时间: {{(item.createTime).substring(0, 16)}}</span>
                      </div>
                      <div v-if="item.showDetail === false && item.listProduct" @click="changeShow(index, true)">
                        <span style="color: rgba(0, 0, 0, 0.60);"><i style="margin-right: 5px; color: #123E83;">{{item.changeNumber}}</i>处修改</span><span style="cursor: pointer; color: rgba(0, 0, 0, 0.60);">查看修改状态>></span>
                      </div>
                      <div class="openBox" v-if="item.showDetail === true && item.listProduct">
                        <span style="color: rgba(0, 0, 0, 0.60);"><i style="margin-right: 5px; color: #123E83;">{{item.changeNumber}}</i>处修改</span><span @click="changeShow(index, false)" style="float:right; cursor: pointer; color: rgba(0, 0, 0, 0.60);">收起</span>
                        <li v-if="item.typeNameNew && item.typeNameNew !== ''">
                          <span>订单类型: <i style="text-decoration: line-through;">{{item.typeNameOld}}</i>改为 {{item.typeNameNew}}</span>
                        </li>
                        <li v-if="item.proposerNameNew && item.proposerNameNew !== ''">
                          <span>签订人: <i style="text-decoration: line-through;">{{item.proposerNameOld}}</i>改为 {{item.proposerNameNew}}</span>
                        </li>
                        <li v-if="item.sellNameNew && item.sellNameNew !== ''">
                          <span>供应商名称: <i style="text-decoration: line-through;">{{item.sellNameOld}}</i>改为 {{item.sellNameNew}}</span>
                        </li>
                        <li v-if="item.deliveryNameNew && item.deliveryNameNew !== ''">
                          <span>送货方式: <i style="text-decoration: line-through;">{{item.deliveryNameOld}}</i>改为 {{item.deliveryNameNew}}</span>
                        </li>
                        <li>
                          <el-popover
                            placement="right"
                            width="880"
                            trigger="click">
                            <el-table ref="tableHeight" :data="item.changeArray" style="width: 100%;">
                              <el-table-column show-overflow-tooltip prop="productCode" label="产品编号" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{scope.row.productCode}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.productCode}}</span>
                                  <span v-if="scope.row.type === 3">{{scope.row.productCode}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{scope.row.productName}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.productName}}</span>
                                  <span v-if="scope.row.type === 3">{{scope.row.productName}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column show-overflow-tooltip prop="unitName" label="单位  " min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{scope.row.unitName}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.unitName}}</span>
                                  <span v-if="scope.row.type === 3">{{scope.row.unitName}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column v-if="showPlan === true" show-overflow-tooltip prop="planNumber" label="需求数量" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{scope.row.planNumber}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.planNumber}}</span>
                                  <span v-if="scope.row.type === 3">{{scope.row.planNumber}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column v-if="showPlan === true" show-overflow-tooltip prop="planResidueNumber" label="剩余需求数量" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{scope.row.planResidueNumber}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.planResidueNumber}}</span>
                                  <span v-if="scope.row.type === 3">{{scope.row.planResidueNumber}}</span>
                                </template>
                              </el-table-column>
                              <!-- <el-table-column show-overflow-tooltip prop="warrantNumber" label="已入库数量" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{scope.row.warrantNumber}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.warrantNumber}}</span>
                                  <span v-if="scope.row.type === 3">{{scope.row.warrantNumber}}</span>
                                </template>
                              </el-table-column> -->
                              <el-table-column show-overflow-tooltip prop="number" label="本次订购数量" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{scope.row.number}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.number}}</span>
                                  <p v-if="scope.row.type === 3 && scope.row.number !== scope.row.newNumber" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.number}}</p>
                                  <span v-if="scope.row.type === 3 && scope.row.number !== scope.row.newNumber">{{scope.row.newNumber}}</span>
                                  <span v-if="scope.row.type === 3 && scope.row.number === scope.row.newNumber">{{scope.row.number}}</span>
                                </template>
                              </el-table-column>
                              <!-- <el-table-column show-overflow-tooltip prop="residueNumber" label="剩余数量" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{scope.row.residueNumber}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.residueNumber}}</span>
                                  <span v-if="scope.row.type === 3">{{scope.row.residueNumber}}</span>
                                </template>
                              </el-table-column> -->
                              <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{scope.row.price}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.price}}</span>
                                  <p v-if="scope.row.type === 3 && scope.row.price !== scope.row.newPrice" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{scope.row.price}}</p>
                                  <span v-if="scope.row.type === 3 && scope.row.price !== scope.row.newPrice">{{scope.row.newPrice}}</span>
                                  <span v-if="scope.row.type === 3 && scope.row.price === scope.row.newPrice">{{scope.row.price}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column show-overflow-tooltip label="单品总价" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{(scope.row.price * scope.row.number).toFixed(2)}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{(scope.row.price * scope.row.number).toFixed(2)}}</span>
                                  <p v-if="scope.row.type === 3 && scope.row.price * scope.row.number !== scope.row.newPrice * scope.row.newNumber" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{(scope.row.price * scope.row.number).toFixed(2)}}</p>
                                  <span v-if="scope.row.type === 3 && scope.row.price * scope.row.number !== scope.row.newPrice * scope.row.newNumber">{{((scope.row.newPrice - 0) * (scope.row.newNumber - 0)).toFixed(2)}}</span>
                                  <span v-if="scope.row.type === 3 && scope.row.price * scope.row.number === scope.row.newPrice * scope.row.newNumber">{{(scope.row.price * scope.row.number).toFixed(2)}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column show-overflow-tooltip prop="arriveTime" label="要求到货时间" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <span v-if="scope.row.type === 1" style="color: rgba(18, 62, 131, 1)">{{(scope.row.arriveTime).substring(0, 16)}}</span>
                                  <span v-if="scope.row.type === 2" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{(scope.row.arriveTime).substring(0, 16)}}</span>
                                  <span v-if="scope.row.type === 3 && scope.row.arriveTime !== scope.row.newArriveTime" style="text-decoration:line-through; color: rgba(0, 0, 0, 0.6)">{{(scope.row.arriveTime).substring(0, 16)}}</span>
                                  <span v-if="scope.row.type === 3 && scope.row.arriveTime !== scope.row.newArriveTime">{{(scope.row.newArriveTime).substring(0, 16)}}</span>
                                  <span v-if="scope.row.type === 3 && scope.row.arriveTime === scope.row.newArriveTime">{{(scope.row.arriveTime).substring(0, 16)}}</span>
                                </template>
                              </el-table-column>
                              <div slot="empty" class="emptyBg">
                                <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
                                <span style="display: block; margin-top: -14%">暂无数据</span>
                              </div>
                          </el-table>
                            <span slot="reference" style="color: #123e83; cursor: pointer;">查看修改明细</span>
                          </el-popover>
                        </li>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-drawer>
          </h5>
          <p>创建人: <span>{{purchaseOrderDetail.proposerName}}</span> | 创建日期:  <span v-if="purchaseOrderDetail.createTime">{{(purchaseOrderDetail.createTime).substring(0, 10)}}</span></p>
        </div>
        <div class="headerCenter">
          <p>订单类型: <span>{{purchaseOrderDetail.typeName}}</span> | 送货方式:  <span>{{purchaseOrderDetail.deliveryTypeName}}</span> | 签订人:  <span>{{purchaseOrderDetail.proposerName}}</span> | 发票要求:  <span>{{purchaseOrderDetail.taxType == 1 ? '不需要发票' : (purchaseOrderDetail.taxType == 2 ? '增值税普通发票' : (purchaseOrderDetail.taxType == 3 ? '增值税普通发票' : '暂无'))}}</span> | 税率:  <span>{{purchaseOrderDetail.taxRate}}%</span> | 收货地址:  <span>{{purchaseOrderDetail.deliveryAddressName == null ? '暂无' : purchaseOrderDetail.deliveryAddressName}}</span></p>
        </div>
        <div class="headerBottom">
          <div>
            <p>发货进度: </p>
            <el-progress :width="24" :show-text="false" type="circle" :percentage="purchaseOrderDetail.deliveryNumber / purchaseOrderDetail.number * 100"></el-progress>
            <span style="margin: 0 10px;">{{(purchaseOrderDetail.deliveryNumber / purchaseOrderDetail.number * 100).toFixed(2).replace('.00', '')}}%</span>
            <span>{{purchaseOrderDetail.deliveryNumber}}</span>
          </div>
          <div>
            <p>收货进度: </p>
            <el-progress color="#20D4CA" :width="24" :show-text="false" type="circle" :percentage="purchaseOrderDetail.takeDeliveryNumber / purchaseOrderDetail.number * 100"></el-progress>
            <span style="margin: 0 10px;">{{(purchaseOrderDetail.takeDeliveryNumber / purchaseOrderDetail.number * 100).toFixed(2).replace('.00', '')}}%</span>
            <span>{{purchaseOrderDetail.takeDeliveryNumber}}</span>
          </div>
        </div>
      </header>
      <!-- 流程进度 -->
      <aside v-if="purchaseOrderDetail.approveStatus !== 0 && approvalProcessList.length !== 0">
        <h6>流程进度</h6>
        <ul>
          <li v-for="(item, index) in approvalProcessList" v-bind:key="index">
            <div v-if="index === 0 && item.status === 2" class="stepBox3"><i class="circleBox"><i class="circle"></i></i><u v-if="approvalProcessList.length !== index + 1"></u></div>
            <div v-else-if="item.status === 1" class="stepBox2"><i class="circle"></i><u v-if="approvalProcessList.length !== index + 1"></u></div>
            <div v-else class="stepBox1"><i class="el-icon-arrow-up"></i><u v-if="approvalProcessList.length !== index + 1"></u></div>
            <div class="stepContent">
              <p>{{index === approvalProcessList.length - 1 ? '采购申请' : '采购审批'}}</p>
              <p>{{item.operatName === null ? '-' : item.operatName}}</p>
              <p>{{item.operatDate === null ? '-' : (item.operatDate).substring(0, 13)}}</p>
              <p v-if="item.status === 1 && purchaseOrderDetail.approveStatus === 1 && purchaseOrderDetail.approveLogId !== '' && purchaseOrderDetail.approveLogId !== null">审批意见 : <el-input v-model.trim="approvalRemark" size="mini" placeholder="【点击这里】输入审批意见"></el-input></p>
              <p v-else :title="item.remark">{{item.remark === null || item.remark === '' ? '审批意见 : 暂无' : '审批意见 : ' + item.remark}}</p>
              <p v-if="index === approvalProcessList.length - 1 && item.status === 2">提交</p>
              <p v-else-if="item.status === 2">同意</p>
              <p v-else-if="item.status === 1">
                <el-button class="lightDeepStyle stepBtn" v-if="purchaseOrderDetail.approveStatus === 1 && purchaseOrderDetail.approveLogId !== '' && purchaseOrderDetail.approveLogId !== null" @click="approvalpass(2)">同意</el-button>
                <el-button class="dangerStyle stepBtn" v-if="purchaseOrderDetail.approveStatus === 1 && purchaseOrderDetail.approveLogId !== '' && purchaseOrderDetail.approveLogId !== null" @click="approvalpass(3)">驳回</el-button>
                <span v-else>等待处理</span>
              </p>
              <p v-else-if="item.status === 3" style="color: rgba(242, 90, 90, 1)">驳回</p>
              <p v-else-if="item.status === 4" style="color: rgba(239, 188, 96, 1)">手动结束</p>
              <p v-else>-</p>
            </div>
          </li>
        </ul>
      </aside>
      <main>
        <h6>采购清单明细<i @click="routerJump(3)">相关订单</i><span>总数量: {{this.allNum}}</span><span style="margin-right: 20px;">总价格: {{this.allPrice}}</span></h6>
        <el-table ref="tableHeight" :data="purchaseOrderDetail.lineEntityList" style="width: 100%;">
          <el-table-column key="1" show-overflow-tooltip prop="productCode" label="产品编号" min-width="100px" align="center"></el-table-column>
          <el-table-column key="2" show-overflow-tooltip prop="productName" label="产品名称" min-width="100px" align="center"></el-table-column>
          <el-table-column key="12" show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column key="13" show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column key="14" show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column key="3" show-overflow-tooltip prop="unitName" label="单位  " min-width="100px" align="center"></el-table-column>
          <el-table-column key="4" v-if="showPlan === true" show-overflow-tooltip prop="planNumber" label="需求数量" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span style="color: rgba(18, 62, 131, 1);">{{scope.row.planNumber}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="5" v-if="showPlan === true" show-overflow-tooltip prop="planResidueNumber" label="剩余需求数量" min-width="100px" align="center"></el-table-column>
          <el-table-column key="6" show-overflow-tooltip prop="warrantNumber" label="已入库数量" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.warrantNumber}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="7" show-overflow-tooltip prop="number" label="本次订购数量" min-width="100px" align="center"></el-table-column>
          <el-table-column key="8" show-overflow-tooltip label="剩余数量" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.residueNumber - scope.row.residueLockDelivery - scope.row.deliveryNumber}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="sellWarehouseNumber" label="供应商实时库存" min-width="120px"  align="center"></el-table-column>
          <el-table-column key="9" show-overflow-tooltip prop="price" label="单价" min-width="100px" align="center"></el-table-column>
          <el-table-column key="10" show-overflow-tooltip label="单品总价" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{(scope.row.price * scope.row.number).toFixed(2)}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="11" show-overflow-tooltip prop="arriveTime" label="要求到货时间" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.arriveTime">{{(scope.row.arriveTime).substring(0, 10)}}</span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
      </el-table>
      </main>
    </div>
  </div>
</template>
<script>
import { purchaseOrderDetailApi, getApprovalProcessApi, approvalpurchaseOrderApi, getOrderAllChangeMsgApi, purchaseOrderEndApi, delPurchaseOrderApi } from '@/api'
export default {
  data() {
    return {
      // 抽屉切换
      drawerTab: true,
      // 抽屉开关
      drawer: false,
      // 抽屉数据
      drawerData: '',
      id: '',
      // 获取采购单详情
      purchaseOrderDetail: {
        lineEntityList: []
      },
      // 流程进度参数
      approvalProcessList: [],
      // 审批流程id
      approvalId: '',
      // 审批流程备注
      approvalRemark: '',
      showPlan: false,
      allNum: 0,
      allPrice: 0
    }
  },
  created() {
    this.id = this.$route.query.result
    this.getPurchaseOrderDetail().then(() => {
      this.getAllChangeMsg()
    })
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 改变展示
    changeShow(index, val) {
      this.drawerData.changeMsg[index].showDetail = val
      this.$forceUpdate()
    },
    // 删除
    delPurchaseOrder() {
      this.$confirm("是否确认删除该采购单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await delPurchaseOrderApi(this.id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.$router.replace('purchaseOrder')
      })
    },
    // 结束
    purchaseOrderEnd() {
      this.$confirm("是否手动结束该采购单审批流", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(async () => {
          const { data: res } = await purchaseOrderEndApi(this.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('结束成功!')
          this.getPurchaseOrderDetail().then(() => {
            this.getAllChangeMsg()
          })
        })
    },
    // 获取流程动态和数据日志
    async getAllChangeMsg() {
      const { data: res } = await getOrderAllChangeMsgApi(this.id)
      this.drawerData = res.data.data
      if (this.drawerData.changeMsg.length !== 0) {
        this.drawerData.changeMsg.forEach(item => {
          item.showDetail = false
          if (item.listProduct && item.listProduct.length > 0) {
            let newArray = []
            item.listProduct.forEach((val, i) => {
              // 我需要将自己整合一个数组 分为三种情况 新增 删除 修改(type 1新增 2删除 3修改)
              // 1.新增
              // 判断如果oldLine === null
              if (val.oldLine === null && val.newLine !== null) {
                val.newLine.type = 1
                newArray.push(val.newLine)
              }
              // 2.删除
              if (val.oldLine !== null && val.newLine === null) {
                val.oldLine.type = 2
                newArray.push(val.oldLine)
              }
              // 3.修改
              if (val.oldLine !== null && val.newLine !== null) {
                let object = {
                  id: val.newLine.id,
                  code: val.newLine.code,
                  companyId: val.newLine.companyId,
                  arriveTime: val.oldLine.arriveTime,
                  newArriveTime: val.newLine.arriveTime,
                  number: val.oldLine.number,
                  newNumber: val.newLine.number,
                  orderId: val.newLine.orderId,
                  planLineId: val.newLine.planLineId,
                  planNumber: val.newLine.planNumber,
                  planResidueNumber: val.newLine.planResidueNumber,
                  price: val.oldLine.price,
                  newPrice: val.newLine.price,
                  productId: val.newLine.productId,
                  productCode: val.newLine.productCode,
                  productName: val.newLine.productName,
                  residueNumber: val.newLine.residueNumber,
                  unitName: val.newLine.unitName,
                  warrantNumber: val.newLine.warrantNumber,
                  warrantQuantity: val.newLine.warrantQuantity,
                  type: 3
                }
                newArray.push(object)
              }
            })
            item.changeArray = newArray
          }
        })
      }
    },
    // 流程进度数据
    async approvalProcess() {
      const { data: res } = await getApprovalProcessApi({ id: this.id, type: 5 })
      this.approvalProcessList = res.data.list
      if (this.approvalProcessList.length !== 0) {
        this.approvalProcessList.forEach(item => {
          if (item.status === 1) {
            this.approvalId = item.id
          }
        })
      }
      this.approvalProcessList.reverse()
    },
    // 审批通过
    async approvalpass(value) {
      if (value === 2 && this.approvalRemark === '') {
        this.approvalRemark = '同意'
      }
      if (value === 3 && this.approvalRemark === '') return this.$message.error('请在审批意见中输入驳回理由!')
      const { data: res } = await approvalpurchaseOrderApi({ approveLogs: [{ id: this.approvalId, remark: this.approvalRemark }], id: [{ id: this.approvalId }], operation: value, remark: this.approvalRemark })
      if (res.code !== 0) return this.$message.error(res.msg)
      if (value === 2) {
        this.$message.success({ duration: 1000, message: '审批通过!' })
      } else {
        this.$message.success({ duration: 1000, message: '拒绝成功!' })
      }
      this.getPurchaseOrderDetail()
    },
    // 获取采购单详情
    async getPurchaseOrderDetail() {
      const { data: res } = await purchaseOrderDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      let newPurchaseOrderDetail = res.data.data
      if (newPurchaseOrderDetail.lineEntityList[0].planLineId !== null) {
        this.showPlan = true
      } else {
        this.showPlan = false
      }
      this.purchaseOrderDetail = newPurchaseOrderDetail
      this.allNum = 0
      this.allPrice = 0
      this.purchaseOrderDetail.lineEntityList.forEach((item => {
        this.allNum = this.allNum + item.number
        this.allPrice = this.allPrice + item.price * item.number
      }))
      this.approvalProcess()
      console.log(this.purchaseOrderDetail, '999999')
    },
    routerJump(val) {
      if (val === 1) {
        this.$router.back()
      } else if (val === 2) {
        this.$router.push({ path: "/editpurchaseOrder", query: { result: JSON.stringify(this.id) }})
      } else {
        this.$router.push({ path: 'relateOrder2', query: { id: this.id, code: this.purchaseOrderDetail.code } })
      }
      
    }
  }
};
</script>
<style lang="less" scoped>
.purchaseOrderDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .purchaseOrderDetailContent {
    margin-top: 40px;
    header {
      position: relative;
      margin: 20px 20px 0;
      padding: 20px 20px 0;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      .headerTop {
        margin-bottom: 20px;
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          .stateBox1, .stateBox2, .stateBox3 {
            margin-left: 10px;
            display: inline-block;
            width: 64px;
            height: 22px;
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            background: rgba(59,212,144,0.05);
            border: 1px solid rgba(59,212,144,0.2);
            color: rgba(59, 212, 144, 1);
            text-align: center;
            line-height: 22px;
          }
          .stateBox2 {
            background: rgba(239, 188, 96, 0.05);
            border: 1px solid rgba(239, 188, 96, 0.2);
            color: rgba(239, 188, 96, 1);
          }
          .stateBox3 {
            background-color: rgba(70, 147, 235, 0.05);
            border-color: rgba(70, 147, 235, 0.20);
            color: rgba(70, 147, 235, 1);
          }
          >i {
            position: absolute;
            right: 20px;
            padding: 0 8px;
            display: inline-block;
            height: 22px;
            border-radius: 4px 4px 4px 4px;
            cursor: pointer;
            font-size: 12px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            color: rgba(0, 0, 0, 0.4);
            text-align: center;
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
      .headerCenter {
        margin-bottom: 20px;
        border-top: 2px solid rgba(248, 249, 250, 1);
        p {
          margin-top: 20px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
      .headerBottom {
        border-top: 2px solid rgba(248, 249, 250, 1);
        display: flex;
        height: 60px;
        width: 100%;
        align-items: center;
        >div {
          display: flex;
          justify-content: flex-start;
          width: 50%;
          p {
            margin-right: 10px;
            color: rgba(0, 0, 0, 0.40);
          }
          span {
            font-weight: bold;
          }
        }
      }
    }
    aside {
      margin: 20px;
      padding: 20px 20px 1px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .el-button {
          padding: 0;
        }
      }
      ul {
        margin-top: 10px;
        width: 100%;
        li {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          height: 40px;
          width: 100%;
          line-height: 40px;
          .stepBox1, .stepBox2, .stepBox3 {
            position: relative;
            margin-top: 8px;
            margin-left: 8px;
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.10);
            line-height: 20px;
            color: rgba(0, 0, 0, 0.80);
            text-align: center;
            i {
              font-size: 12px;
              font-weight: bold;
            }
            .circle {
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #3BD490;
            }
            u {
              position: absolute;
              top: 32px;
              left: 12px;
              width: 2px;
              height: 17px;
              background-color: rgba(0, 0, 0, 0.15);
            }
          }
          .stepBox2 {
            background-color: rgba(59, 212, 144, 0.10);
          }
          .stepBox3 {
            margin-top: 4px;
            margin-left: 4px;
            width: 32px;
            height: 32px;
            background-color: rgba(18, 62, 131, 1);
            .circleBox {
              margin-top: 6.25px;
              display: inline-block;
              width: 17.5px;
              height: 17.5px;
              border-radius: 50%;
              border: 1px solid #fff;
              background-color: rgba(18, 62, 131, 1);
              line-height: 0;
              .circle {
                margin-top: 6.25px;
                width: 5px;
                height: 5px;
                border-radius: 0;
                background-color: #fff;
              }
            }
            u {
              top: 39px;
              left: 15px;
            }
          }
          .stepContent {
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            height: 40px;
            width: calc(100% - 44px);
            background: rgba(18,62,131,0.05);
            border-radius: 8px;
            box-sizing: border-box;
            font-size: 14px;
            p {
              width: 120px;
            }
            p:nth-child(4) {
              width: 35%;
              /deep/ .el-input {
                height: 40px;
                width: calc(100% - 80px);
                .el-input__inner {
                  height: 40px;
                  border: 0;
                  background-color: transparent;
                  font-size: 14px;
                }
                ::-webkit-input-placeholder {
                  color: rgba(18, 62, 131, 1);
                }
                ::-moz-input-placeholder {
                  color: rgba(18, 62, 131, 1);
                }
              }
            }
            p:nth-child(5) {
              float: right;
              width: 140px;
              text-align: right;
            }
            .stepBtn {
              padding: 0 16px;
            }
          }
        }
      }
    }
    main {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        span {
          float: right;
        }
        i {
          float: right;
          margin-top: -7px;
          margin-left: 10px;
          margin-bottom: 14px;
          width: 76px;
          height: 32px;
          background: #123E83;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          color: #fff;
          line-height: 32px;
          font-size: 14px;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
      .sellTypeBox1, .sellTypeBox2, .sellTypeBox3, .sellTypeBox4 {
        display: inline-block;
        width: 80px;
        height: 22px;
        border-radius: 4px;
        background-color: rgba(70, 147, 235, 0.05);
        border: 1px solid rgba(70, 147, 235, 0.2);
        color: rgba(70, 147, 235, 1);
      }
      .sellTypeBox2 {
        background-color: rgba(59, 212, 144, 0.05);
        border: 1px solid rgba(59, 212, 144, 0.2);
        color: rgba(59, 212, 144, 1);
      }
      .sellTypeBox3 {
        background-color: rgba(239, 188, 96, 0.05);
        border: 1px solid rgba(239, 188, 96, 0.2);
        color: rgba(239, 188, 96, 1);
      }
      .sellTypeBox4 {
        background-color: rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.20);
        color: rgba(0, 0, 0, 0.40);
      }
    }
  }
  .drawerBox {
    padding: 20px;
    width: 100%;
    height: 100vh;
    >p {
      height: 48px;
      width: 500px;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      span {
        margin: 4px;
        display: inline-block;
        height: 40px;
        width: 246px;
        border-radius: 8px;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        color: rgba(0, 0, 0, 0.60);
      }
      .drawerShow {
        background-color: rgba(18, 62, 131, 1);
        color: #fff;
      }
    }
    .drawerLeft {
      ul {
        margin-top: 10px;
        width: 100%;
        li {
          .drawerNav {
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            height: 40px;
            width: 100%;
            line-height: 40px;
            .stepBox1, .stepBox2, .stepBox3 {
              margin-top: 8px;
              margin-left: 8px;
              display: inline-block;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: rgba(0, 0, 0, 0.10);
              line-height: 20px;
              color: rgba(0, 0, 0, 0.80);
              text-align: center;
              i {
                font-size: 12px;
                font-weight: bold;
              }
              .circle {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #3BD490;
              }
            }
            .stepBox2 {
              background-color: rgba(59, 212, 144, 0.10);
            }
            .stepBox3 {
              margin-top: 4px;
              margin-left: 4px;
              width: 32px;
              height: 32px;
              background-color: rgba(18, 62, 131, 1);
              .circleBox {
                margin-top: 6.25px;
                display: inline-block;
                width: 17.5px;
                height: 17.5px;
                border-radius: 50%;
                border: 1px solid #fff;
                background-color: rgba(18, 62, 131, 1);
                line-height: 0;
                .circle {
                  margin-top: 6.25px;
                  width: 5px;
                  height: 5px;
                  border-radius: 0;
                  background-color: #fff;
                }
              }
            }
            .drawerHeader {
              display: flex;
              justify-content: space-between;
              width: calc(100% - 50px);
              color: rgba(0, 0, 0, 0.80);
            }
          }
          .drawerBody {
            margin-left: 20px;
            width: 100%;
            border-left: 1px solid rgba(0, 0, 0, 0.15);
            .drawerContent {
              margin-left: 24px;
              padding: 10px 10px 1px;
              width: calc(100% - 44px);
              background: rgba(18,62,131,0.05);
              border-radius: 8px;
              box-sizing: border-box;
              font-size: 14px;
              p {
                margin: 0 0 10px;
                display: flex;
                width: 100%;
                justify-content: space-between;
              }
            }
          }
          
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
    .drawerRight {
      .drawerRightBox {
        >li {
          margin-top: 20px;
          width: 100%;
          background: rgba(18,62,131,0.05);
          border-radius: 8px;
          box-sizing: border-box;
          font-size: 14px;
          div {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            width: 100%;
          }
          div:nth-child(2) {
            border-top: 1px solid #fff;
          }
          .openBox {
            display: block;
            border: 1px solid rgba(18,62,131,0.05);
            background-color: #fff;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            li {
              display: flex;
              justify-content: space-between;
              height: 30px;
              color: rgba(0, 0, 0, 0.80);
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>