<!-- 销售汇总 -->
<template>
  <div class="balanceAccount">
    <nav>
      <h5>销售汇总</h5>
      <div>
        <el-button class="searchStyle" @click="purchaseReturnExport()"><i class="iconfont icon-upload1"></i>导 出</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="balanceAccountData.companyName" placeholder="客户名称搜索" @input="getBalanceAccountList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="balanceAccountData.productName" placeholder="产品名称搜索" @input="getBalanceAccountList()" prefix-icon="el-icon-search"></el-input>
    </section>
    <main>
      <el-table @select="handleSelectionChange" @select-all="handleSelectionChange" ref="tableHeight" :height="tableHeight" :data="balanceAccountList" style="width: 100%;">
          <el-table-column type="selection" width="45" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="companyName" label="客户名称" min-width="100px" align="center">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="100px"  align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="newColour" label="颜色" min-width="130px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productTypeName" label="类型" min-width="100px"  align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="130px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格" min-width="100px"  align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="number" label="订单数量" min-width="80px"  align="center">
          </el-table-column>
          <el-table-column label="总金额" prop="money">
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="balanceAccountData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
// import Utils from '../../util.js'
import { salesSummaryListApi, hzExportApi } from '@/api'
export default {
  name: 'balanceAccount',
  data() {
    return {
    // 列表动态高度
      tableHeight: '',
      // 销售汇总列表
      balanceAccountList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      balanceAccountData: {
      // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        productName: '',
        companyName: ''
      },
      chooseTime: '',
      // 选择的销售汇总
      chooseBalance: []
    }
  },
  created() {
  },
  mounted() {
    this.getBalanceAccountList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
  // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.createPay(result.id)
      } else if (command === '2') {
        this.delBalanceAccount(result.id)
      } else if (command === '3') {
        this.BalanceAccountWithdraw(result.id)
      } else {
        this.BalanceAccountdelete(result.id)
      }
    },
    // 手动勾选的参数(销售汇总专用)
    handleSelectionChange(selection) {
      let arr = []
      selection.forEach(item => {
        arr.push({ id: item.id, sellerId: item.sellerId })
      })
      this.chooseBalance = arr
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.balanceAccountData.createTimeStart = ''
        this.balanceAccountData.createTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.balanceAccountData.createTimeStart = getYear + '-' + nowMonth + '-' + getDate
            this.balanceAccountData.createTimeEnd = getYear + '-' + nowMonth + '-' + getDate
          }
        })
      }
      this.getBalanceAccountList()
    },
    // 获取销售汇总列表
    async getBalanceAccountList(reset) {
      if (reset !== 'reset') {
        this.balanceAccountData.page = 1
      }
      const { data: res } = await salesSummaryListApi(this.balanceAccountData)
      // 获取表单数据
      this.balanceAccountList = res.data.data.list
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
    },
    // -----------------------------------------------------------------

    // 触发每页显示条数
    handlesizeChange(val) {
      this.balanceAccountData.size = val
      this.balanceAccountData.page = 1
      this.getBalanceAccountList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.balanceAccountData.page = val
      this.currentPage = val
      this.getBalanceAccountList('reset')
    },
    async purchaseReturnExport() {
      const { data: res } = await hzExportApi(this.balanceAccountData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getPurchaseReturnList()
    }
  }
}
</script>
<style scoped lang='less'>
.balanceAccount {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      .statusBox1, .statusBox2, .statusBox3{
        padding: 5px 8px;
        width: 52px;
        height: 22px;
        border-radius: 4px;
        background: rgba(70,147,235,0.05);
        border: 1px solid rgba(70,147,235,0.2);
        font-size: 12px;
        color: rgba(70, 147, 235, 1);
      }
      .statusBox2 {
        border: 1px solid rgba(242,90,90,0.2);
        background: rgba(242,90,90,0.05);
        color: rgba(242, 90, 90, 1);
      }
      .statusBox3 {
        border: 1px solid rgba(59, 212, 144, 0.20);
        background: rgba(59, 212, 144, 0.05);
        color: rgba(59, 212, 144, 1);
      }
    }
  }
}
</style>
