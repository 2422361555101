// 销售退货详情
<template>
  <div class="sellerReturnDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/sellerReturn' }">销售退货单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button v-if="sellerReturnDetail.state === 2" class="lightDeepStyle" @click="chooseCommand(2)"><i class="iconfont icon-fanhui-baise"></i>撤回</el-button>
      <el-button v-if="sellerReturnDetail.state === 1" class="lightDeepStyle" @click="chooseCommand(1)"><i class="iconfont icon-shoudongwancheng"></i>确认</el-button>
      <el-button v-if="sellerReturnDetail.state === 1" class="lightDeepStyle" @click="chooseCommand(3)"><i class="iconfont icon-quxiaoxinzeng"></i>取消</el-button>
      <el-button v-if="sellerReturnDetail.state === 3" class="dangerStyle" @click="chooseCommand(4)"><i class="el-icon-delete"></i>删除</el-button>
      <el-button class="lightDeepStyle" @click="routerJump()"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="sellerReturnDetailContent">
      <header>
        <div>
          <h5>退货单编号: {{sellerReturnDetail.code}}
            <span>({{sellerReturnDetail.companyName}})</span>
          </h5>
          <p>下单日期:  <span v-if="sellerReturnDetail.createTime">{{(sellerReturnDetail.createTime).substring(0, 10) + '(' + sellerReturnDetail.createName + ')'}}</span></p>
        </div>
      </header>
      <main>
        <h6>退货明细</h6>
        <el-table ref="tableHeight" :data="sellerReturnDetail.lineEntityList" style="width: 100%;">
          <el-table-column show-overflow-tooltip type="index" label="行号" min-width="100px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="orderCode" label="销售单号" min-width="100px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="100px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="100px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="numberMax" label="本次订单可退" min-width="100px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="100px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="number" label="退货数量" min-width="100px" align="center">
            <template slot-scope="scope">
              <span style="color: rgba(18, 62, 131, 1)">{{scope.row.number}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="money" label="退货总金额" min-width="100px" align="center"></el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
      </el-table>
      </main>
    </div>
  </div>
</template>
<script>
import { purchaseReturnDetailApi, editPurchaseReturnStateApi, delPurchaseReturnApi } from '@/api'
export default {
  data() {
    return {
      id: '',
      // 获取销售退货详情
      sellerReturnDetail: {
        lineEntityList: []
      }
    }
  },
  created() {
    this.id = this.$route.query.result
    this.getPurchaseReturnDetail()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 修改订单状态
    editPurchaseReturnState(type, id) {
      // 1 确认 2 撤回 3 取消 type
      let showVal
      if (type === 1) {
        showVal = '确认'
      } else if (type === 2) {
        showVal = '撤回'
      } else {
        showVal = '取消'
      }
      this.$confirm("是否" + showVal + "该退货单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await editPurchaseReturnStateApi({ id: id, type: type })
        if (res.code !==0) return this.$message.error(res.msg)
        this.$message.success(showVal + '成功!')
        this.getPurchaseReturnDetail()
      })
    },
    // 选择下拉
    chooseCommand(command) {
      if (command !== 4) {
        this.editPurchaseReturnState(command, this.id)
      } else {
        this.delPurchaseReturn(this.id)
      }
    },
    // 删除
    delPurchaseReturn(id) {
      this.$confirm("是否确认删除该退货单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await delPurchaseReturnApi({ id: id, type: 2 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getPurchaseReturnList()
      })
    },
    // 获取销售退货单详情
    async getPurchaseReturnDetail() {
      const { data: res } = await purchaseReturnDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.sellerReturnDetail = res.data.data
    },
    routerJump() {
      this.$router.back()
    }
  }
};
</script>
<style lang="less" scoped>
.sellerReturnDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
      font-size: 14px;
    }
  }
  .sellerReturnDetailContent {
    margin-top: 40px;
    header {
      position: relative;
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          .stateBox1, .stateBox2, .stateBox3 {
            margin-left: 10px;
            display: inline-block;
            width: 64px;
            height: 22px;
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            background: rgba(59,212,144,0.05);
            border: 1px solid rgba(59,212,144,0.2);
            color: rgba(59, 212, 144, 1);
            text-align: center;
            line-height: 22px;
          }
          .stateBox2 {
            background: rgba(239, 188, 96, 0.05);
            border: 1px solid rgba(239, 188, 96, 0.2);
            color: rgba(239, 188, 96, 1);
          }
          .stateBox3 {
            background-color: rgba(70, 147, 235, 0.05);
            border-color: rgba(70, 147, 235, 0.20);
            color: rgba(70, 147, 235, 1);
          }
          >i {
            position: absolute;
            right: 20px;
            padding: 0 8px;
            display: inline-block;
            height: 22px;
            border-radius: 4px 4px 4px 4px;
            cursor: pointer;
            font-size: 12px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            color: rgba(0, 0, 0, 0.4);
            text-align: center;
          }
        }
        >p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    aside {
      margin: 20px;
      padding: 20px 20px 1px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .el-button {
          padding: 0;
        }
      }
      ul {
        margin-top: 10px;
        width: 100%;
        li {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          height: 40px;
          width: 100%;
          line-height: 40px;
          .stepBox1, .stepBox2, .stepBox3 {
            position: relative;
            margin-top: 8px;
            margin-left: 8px;
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.10);
            line-height: 20px;
            color: rgba(0, 0, 0, 0.80);
            text-align: center;
            i {
              font-size: 12px;
              font-weight: bold;
            }
            .circle {
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #3BD490;
            }
            u {
              position: absolute;
              top: 32px;
              left: 12px;
              width: 2px;
              height: 17px;
              background-color: rgba(0, 0, 0, 0.15);
            }
          }
          .stepBox2 {
            background-color: rgba(59, 212, 144, 0.10);
          }
          .stepBox3 {
            margin-top: 4px;
            margin-left: 4px;
            width: 32px;
            height: 32px;
            background-color: rgba(18, 62, 131, 1);
            .circleBox {
              margin-top: 6.25px;
              display: inline-block;
              width: 17.5px;
              height: 17.5px;
              border-radius: 50%;
              border: 1px solid #fff;
              background-color: rgba(18, 62, 131, 1);
              line-height: 0;
              .circle {
                margin-top: 6.25px;
                width: 5px;
                height: 5px;
                border-radius: 0;
                background-color: #fff;
              }
            }
            u {
              top: 39px;
              left: 15px;
            }
          }
          .stepContent {
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            height: 40px;
            width: calc(100% - 44px);
            background: rgba(18,62,131,0.05);
            border-radius: 8px;
            box-sizing: border-box;
            font-size: 14px;
            p {
              width: 120px;
            }
            p:nth-child(4) {
              width: 35%;
              /deep/ .el-input {
                height: 40px;
                width: calc(100% - 80px);
                .el-input__inner {
                  height: 40px;
                  border: 0;
                  background-color: transparent;
                  font-size: 14px;
                }
                ::-webkit-input-placeholder {
                  color: rgba(18, 62, 131, 1);
                }
                ::-moz-input-placeholder {
                  color: rgba(18, 62, 131, 1);
                }
              }
            }
            p:nth-child(5) {
              float: right;
              width: 140px;
              text-align: right;
            }
            .stepBtn {
              padding: 0 16px;
            }
          }
        }
      }
    }
    main {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
      .sellTypeBox1, .sellTypeBox2, .sellTypeBox3, .sellTypeBox4 {
        display: inline-block;
        width: 80px;
        height: 22px;
        border-radius: 4px;
        background-color: rgba(70, 147, 235, 0.05);
        border: 1px solid rgba(70, 147, 235, 0.2);
        color: rgba(70, 147, 235, 1);
      }
      .sellTypeBox2 {
        background-color: rgba(59, 212, 144, 0.05);
        border: 1px solid rgba(59, 212, 144, 0.2);
        color: rgba(59, 212, 144, 1);
      }
      .sellTypeBox3 {
        background-color: rgba(239, 188, 96, 0.05);
        border: 1px solid rgba(239, 188, 96, 0.2);
        color: rgba(239, 188, 96, 1);
      }
      .sellTypeBox4 {
        background-color: rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.20);
        color: rgba(0, 0, 0, 0.40);
      }
    }
  }
  .drawerBox {
    padding: 20px;
    width: 100%;
    height: 100vh;
    h6 {
      margin: 0;
      color: rgba(0, 0, 0, 0.60);
      font-size: 16px;
      font-weight: bold;
      i {
        float: right;
        font-size: 18px;
        cursor: pointer;
      }
    }
    ul {
      li {
        margin-top: 20px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        height: 40px;
        background-color: rgba(18, 62, 131, 0.05);
        border-radius: 40px;
        box-sizing: border-box;
        span {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
  }
  // 录入实时库存弹框
  .enterRealStockStyle {
    margin-top: 15vh;
    /deep/.el-dialog {
      height: 189px;
      width: 424px;
      .el-dialog__header {
        padding: 20px 20px 0px;
        .el-dialog__title {
          font-size: 16px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.88);
        }
        .el-dialog__headerbtn {
          top: 20px;
          .el-icon-close:before {
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
      .el-dialog__body {
        height: 70px;
        overflow-y: auto;
        .el-form-item {
          margin: 0;
          .el-form-item__content {
            margin: 20px 0 0;
            >div {
              display: flex;
              justify-content: space-between;
              span {
                height: 32px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.80);
                text-align: left;
              }
              .el-input {
                width: 270px;
              }
            }
          }
        }
      }
    }
  }
}
</style>