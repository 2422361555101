// 预付款详情
<template>
  <div class="prePaymentDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/prePaymentManage' }">预付款管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
      <el-button v-if="prePaymentDetail.auditStatus === 0" class="lightDeepStyle" @click="routerJump(2)"><i class="el-icon-edit-outline"></i>编辑</el-button>
      <el-button v-if="prePaymentDetail.auditStatus === 1" class="lightDeepStyle" @click="cancelPrePayment()"><i class="el-icon-edit-outline"></i>取消付款</el-button>
      <el-button v-if="prePaymentDetail.auditStatus === 2 || prePaymentDetail.auditStatus === 0" class="dangerStyle" @click="delPrePayment()"><i class="el-icon-delete"></i>删除</el-button>
    </div>
    <div class="prePaymentDetailContent">
      <header>
        <div>
          <h5>预付款单编号: {{prePaymentDetail.number + ' (' + prePaymentDetail.sellerName + ')'}}<i>{{prePaymentDetail.status === 0 ? '未结算' : (prePaymentDetail.status === 1 ? '结算中': (prePaymentDetail.status === 1 ? '已结算': ''))}}</i></h5>
          <p>关联采购单: <span>{{prePaymentDetail.orderNum}}</span> | 创建时间: <span>{{prePaymentDetail.createTime + '(' + prePaymentDetail.nickName + ')'}}</span></p>
        </div>
      </header>
      <main>
        <h6>付款方式: {{prePaymentDetail.type === 1 ? '现金' : '转账'}}</h6>
        <p v-if="prePaymentDetail.type !== 1">付款时间: <span>{{prePaymentDetail.payTime}}</span> | 银行名称: <span>{{prePaymentDetail.bank}}</span> | 银行账号: <span>{{prePaymentDetail.bankNum}}</span></p>
        <p v-else>付款时间: <span>{{prePaymentDetail.payTime}}</span></p>
      </main>
      <main style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
        <h6>付款金额: {{prePaymentDetail.price}}</h6>
      </main>
      <footer>
        <h6 style="font-size: 14px;">汇总明细<span>总金额: {{allPrice}}</span></h6>
        <el-table ref="tableHeight" :data="chooseList.lineEntityList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="number" label="产品数量" min-width="80px"  align="center">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.number}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="退货数量" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.returnNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip label="单品总价" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{(scope.row.number - scope.row.returnNumber) * scope.row.price}}</span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
      </footer>
    </div>
  </div>
</template>
<script>
import { prePaymentDetailApi, delPrePaymentApi, purchaseOrderListApi, cancelPrePaymentApi } from '@/api'
export default {
  data() {
    return {
      id: '',
      // 获取预付款单详情
      prePaymentDetail: '',
      // 总金额
      allPrice: 0,
      // 传参参数
      purchaseOrderData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        productName: '',
        approveTimeBegin: '',
        approveTimeEnd: '',
        approveStatus: 2,
        state: '0,1',
        stateType: '',
        sellId: '',
        ifAwait: '',
        companyType: 1
      },
      chooseList: ''
    }
  },
  created() {
    this.id = JSON.parse(this.$route.query.result)
    this.getPrePaymentDetail().then(() => {
      this.purchaseOrderData.sellId = this.prePaymentDetail.sellerId
      this.getMatterManageList()
    })
  },
  methods: {
    // 获取采购单列表
    async getMatterManageList() {
      const { data: res } = await purchaseOrderListApi(this.purchaseOrderData);
      if (res.code !== 0) return this.$message.error(res.msg)
      this.purchaseOrderList = res.data.data.list
      this.purchaseOrderList.forEach((item, index) => {
        if (item.id === this.prePaymentDetail.orderId) {
          this.chooseList = item
        }
      })
      // 同时获取总金额
      if (this.chooseList.lineEntityList && this.chooseList.lineEntityList.length !== 0) {
        this.allPrice = 0
        this.chooseList.lineEntityList.forEach((item, index) => {
          this.allPrice += item.price * item.number
        })
      }
    },
    // 获取预付款详情
    async getPrePaymentDetail() {
      const { data: res } = await prePaymentDetailApi(this.id);
      if (res.code !== 0) return this.$message.error(res.msg)
      this.prePaymentDetail = res.data.detail
    },
    // 删除预付款单
    delPrePayment() {
      this.$confirm('是否确认删除当前预付款单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(async() => {
          const { data: res } = await delPrePaymentApi({ id: this.id, status: 3 })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '删除成功!' })
          this.$router.back()
        })
    },
    // 取消预付款单
    cancelPrePayment() {
      this.$confirm('是否确认取消当前预付款单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await cancelPrePaymentApi({ id: this.id, auditStatus: 2 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '取消成功!' })
        this.getPrePaymentDetail()
      })
    },
    routerJump(val) {
      if (val === 1) {
        this.$router.back()
      } else {
        this.$router.push({ path: "/editPrePayment", query: { result: JSON.stringify(this.id) }})
      }
    }
  }
};
</script>
<style lang="less" scoped>
.prePaymentDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .prePaymentDetailContent {
    margin-top: 40px;
    header {
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          i {
            margin-left: 20px;
            display: inline-block;
            width: 52px;
            height: 22px;
            background: rgba(70,147,235,0.05);
            border-radius: 4px;
            border: 1px solid rgba(70,147,235,0.2);
            font-size: 12px;
            font-weight: 400;
            color: rgba(70, 147, 235, 1);
            text-align: center;
            line-height: 22px;
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    main {
      margin: 2px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
    footer {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        span {
          float: right;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
  }
}
</style>