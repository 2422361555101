import axios from 'axios'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import router from '../router'
// axios.defaults.baseURL = 'http://apisrm.bmxgj.cn/' // 测试服
axios.defaults.baseURL = 'https://newapi.z-srm.com/' // 正式服
// 请求拦截器
axios.interceptors.request.use(res => {
  Nprogress.start()
  // res.headers['token'] = window.sessionStorage.getItem('token')
  res.headers['token'] = window.$cookies.get('token')
  res.headers['menuUrl'] = router.history.current.name
  res.headers['roleType'] = window.sessionStorage.getItem('roleType')
  res.headers['device'] = 'pc'
  return res
})
// 响应拦截
axios.interceptors.response.use(res => {
  Nprogress.done()
  if (res.data.code === -11) {
    window.$cookies.remove('token')
    window.sessionStorage.removeItem('userInfo')
    window.$cookies.remove('buyId')
    window.$cookies.remove('companyId')
    window.sessionStorage.removeItem('workStatus')
    window.sessionStorage.removeItem('companyNum')
    window.sessionStorage.removeItem('editableTabs')
    window.sessionStorage.removeItem('editableTabsValue')
    this.$cookies.remove('auto')
    this.$router.replace('/login')
  }
  return res
})

// ---------------------------------------------------------

// 获取用户自己的详情
export const getUserInfoApi = params => {
  return axios.get('/sys/user-info/getUserInfo?buyId=' + params.buyId + '&companyId=' + params.companyId + '&userId' + params.userId)
}

// 侧边栏
// 获取导航菜单栏
export const getNavMenuApi = params => {
  return axios.get('/sys/menu/nav?roleType=' + params)
}

// 获取所有菜单
export const getAllMenuApi = params => {
  return axios.get('/sys/menu/list?roleType=' + params)
}

// 登录
// 密码登录
export const loginApi = params => {
  return axios.post('/sys/user/login', params)
}

// 手机登录
export const phoneLoginApi = params => {
  return axios.post('/sys/user/phone-login', params)
}

// 退出登录
export const logoutApi = params => {
  return axios.get('/sys/user/logout')
}

// 发送验证码 ☆
export const sendVerificationApi = params => {
  return axios.get(
    '/sys/common/send-sms?type=' + params.type + '&mobile=' + params.mobile
  )
}

// 用户注册
export const userRegisterApi = params => {
  return axios.post('/sys/gw/addReg', params)
}

// 注册成为供应商
export const registerSellerApi = params => {
  return axios.post('/sys/user/register', params)
}

// 验证短信验证码
export const checkShortMsgApi = params => {
  return axios.get(
    '/sys/user/check-sms-code?code=' + params.code + '&mobile=' + params.mobile
  )
}

// 修改密码
export const changePasswordApi = params => {
  return axios.post('/sys/user/password', params)
}

// 工作台数据
export const indexMsgApi = params => {
  return axios.get('/new/index/getMsg?startTime=' + params.startTime + '&endTime=' + params.endTime)
}

// 工作台头部数据
export const indexTitleApi = params => {
  return axios.get('/new/index/indexBase')
}

// 工作台我的待办
export const indexMyApi = params => {
  return axios.get('/new/index/mydealt?page=' + params.page + '&size=' + params.size + '&type=' + params.type)
}

// 待我审批的采购单列表
export const myWaitOrderListApi = params => {
  return axios.get('/new/purchase/order/approve-list?page=' + params.page + '&size=' + params.size)
}

// 待我审批的采购计划单列表
export const myWaitPlanListApi = params => {
  return axios.get('/new/purchase/plan/approve-list?page=' + params.page + '&size=' + params.size)
}

// 首页供应商列表
export const indexSellerListApi = params => {
  return axios.get('/new/seller/indexSeller?page=' + params.page + '&size=' + params.size)
}

// 多公司列表
export const moreCompanyListApi = params => {
  return axios.get('/sys/user/more-company-list?type=' + params.type + '&roleType=' + params.roleType)
}

// 切换默认公司
export const updateDefaultCompanyApi = params => {
  return axios.post('/sys/user/update-default-company?companyId=' + params)
}

// 供应商账单分析
export const indexSellerDataApi = params => {
  return axios.get('/new/index/zdDetail?sellerId=' + params)
}

// 消息模块
// 2.消息中心
// 获取消息中心列表
export const getMessageListApi = params => {
  return axios.get('/sys/message/messageList?messageTitle=' + params.messageTitle + '&page=' + params.page + '&size=' + params.size + '&messageCategory=' + params.messageCategory + '&messageStatus=' + params.messageStatus + '&messageType=' + params.messageType)
}

// 获取消息管理列表
export const getMessageManageListApi = params => {
  return axios.get('/sys/message/createdMessageList?messageTitle=' + params.messageTitle + '&page=' + params.page + '&size=' + params.size)
}

// 新增消息时获取客户列表
export const getMessagePurchaseListApi = params => {
  return axios.get('/sys/message/getCustomerList')
}

// 新增消息时获取供应商列表
export const getMessageSellListApi = params => {
  return axios.get('/sys/message/getSellList')
}

// 保存消息(新增,修改)
export const keepMessageApi = params => {
  return axios.post('/sys/message/saveMessage', params)
}

// 发布消息
export const publishMessageApi = params => {
  return axios.get('/sys/message/sendMessage?id=' + params)
}

// 查看消息详情(消息中心专用)
export const examineManageApi = params => {
  return axios.get('/sys/message/info?userMessageId=' + params)
}

// 查看消息详情(消息管理专用)
export const examineManage2Api = params => {
  return axios.get('/sys/message/infoCreate?id=' + params)
}

// 删除消息详情
export const delManageApi = params => {
  return axios.get('/sys/message/removeMessage?userMessageId=' + params)
}

// ---------------------------------------------------------
// 供应商
// 获取供应商列表
export const sellerListApi = params => {
  return axios.get('/new/seller/list?search=' + params.search + '&page=' + params.page + '&size=' + params.size + '&roleType=' + params.roleType + '&status=' + params.status)
}

// 待我确认供应商列表(采购共用)
export const waitMySellerListApi = params => {
  return axios.get('/new/seller/auditList?search=' + params.search + '&page=' + params.page + '&size=' + params.size + '&roleType=' + params.roleType + '&industry=' + params.industry)
}

// 删除供应商
export const delSellerApi = params => {
  return axios.post('/new/seller/delete?id=' + params)
}
// 新增供应商
export const addSellerApi = params => {
  return axios.post('/new/seller/add', params)
}
// 供应商详情
export const sellerDetailApi = params => {
  return axios.get('/new/seller/detail?id=' + params)
}
// 获取省市区
export const getProvinceApi = params => {
  return axios.get('/sys/common/position-list?pcode=' + params)
}
// 获取全部省市区
export const getProvinceAllApi = params => {
  return axios.get('/sys/common/positionAllList')
}
// 获取行业表
export const industryListApi = params => {
  return axios.get('/sys/common/industry-list?name=' + params)
}
// 获取多级行业表
export const industryLevelListApi = params => {
  return axios.get('/sys/common/industry-level')
}
// 修改供应商
export const editSellerApi = params => {
  return axios.post('/new/seller/edit', params)
}

// 查询供应商或客户工商信息
export const industryInfoApi = params => {
  return axios.get('/new/seller/businessDetail?name=' + params)
}

// 修改供应商状态(客户共用 待确认同意专用)
export const editSellerStatusApi = params => {
  return axios.post('/new/seller/editStatus', params)
}

// ----------------------------------------王逸start
// 库存----------------------
// 入库单
export const entryManageListApi = params => {
  return axios.get(
    '/new/warehouse/warrant/list?warrantTimeBegin=' +
      params.warrantTimeBegin +
      '&warrantTimeEnd=' +
      params.warrantTimeEnd +
      '&productNameSum=' +
      params.productNameSum +
      '&sellName=' +
      params.sellName +
      '&warehouseId=' +
      params.warehouseId +
      '&type=' +
      params.type +
      '&page=' +
      params.page +
      '&size=' +
      params.size
  )
}

// 入库单新增
export const addEntryApi = params => {
  return axios.post('/new/warehouse/warrant/save', params)
}

// 删除入库单
export const delEntryApi = params => {
  return axios.post('/sys/warehouse/warrant/delete?id=' + params)
}

// 入库单详情
export const entryDetailApi = params => {
  return axios.get('/sys/warehouse/warrant/info?id=' + params)
}

// 获取用户计量单位列表
export const unitUserListApi = params => {
  return axios.get('/sys/unit/user/list?enabled=' + params.enabled + '&name=' + params.name + '&code=' + params.code + '&page=' + params.page + '&size=' + params.size)
}

// 仓库列表
export const storeHouseListApi = params => {
  return axios.get('/sys/warehouse/basics/list?code=' + params.code + '&name=' + params.name + '&enabled=' + params.enabled + '&page=' + params.page + '&size=' + params.size)
}

// 仓库对应的产品列表
export const houseMatterListApi = params => {
  return axios.get('/new/warehouse/basics/list?warehouseName=' + params.warehouseName + '&productName=' + params.productName + '&warehouseId=' + params.warehouseId + '&page=' + params.page + '&size=' + params.size)
}

// 采购单产品列表
export const purchaseMatterListApi = params => {
  return axios.get('/new/purchase/order/lineList?sellId=' + params.sellId + '&page=' + params.page + '&size=' + params.size + '&codeAndProductNameAndProductCode=' + params.codeAndProductNameAndProductCode + '&codeAndProductName=' + params.codeAndProductName + '&state=' + params.state + '&kkstat=' + params.kkstat + '&approveStatus=' + params.approveStatus)
}

// 产品--------------------------------
// 产品分类列表
export const matterClassApi = params => {
  return axios.get('/sys/product/type-list?fullName=' + params)
}

// 新增产品
export const addMatterApi = params => {
  return axios.post('/sys/product/save', params)
}

// 修改产品
export const editMatterApi = params => {
  return axios.post('/sys/product/update', params)
}

// 修改产品
export const editMatterStatusApi = params => {
  return axios.post('/sys/product/set-status', params)
}

// 获取默认产品分类编码
export const getMatterCategoryCodeApi = params => {
  return axios.get('/sys/product/getTypeDefaultCode')
}

// 添加产品分类
export const addCategoryApi = params => {
  return axios.post('/sys/product/save-type', params)
}

// 删除产品分类
export const delCategoryApi = params => {
  return axios.post('/sys/product/delete-type?id=' + params)
}

// 修改产品分类
export const editCategoryApi = params => {
  return axios.post('/sys/product/update-type', params)
}

// 删除产品
export const delMatterApi = params => {
  return axios.post('/sys/product/delete', params)
}

// 产品分类导入保存
export const matterCategoryUploadKeepApi = params => {
  return axios.post('/sys/product/batch-save-type', params)
}

// 产品列表
export const matterListApi = params => {
  return axios.get('/sys/product/list?page=' + params.page + '&size=' + params.size + '&status=' + params.status + '&typeId=' + params.typeId + '&name=' + params.name + '&productType=' + params.productType + '&codeAndName=' + params.codeAndName)
}

// 采购---------------------------------------
// 采购模块(和销售订单共用接口)----------
// 采购订单列表
export const purchaseOrderListApi = params => {
  return axios.get('/new/purchase/order/list?page=' + params.page + '&size=' + params.size + '&code=' + params.code + '&productName=' + params.productName + '&approveTimeBegin=' + params.approveTimeBegin + '&approveTimeEnd=' + params.approveTimeEnd + '&state=' + params.state + '&approveStatus=' + params.approveStatus + '&sellId=' + params.sellId + '&ifAwait=' + params.ifAwait + '&companyType=' + params.companyType + '&stateType=' + params.stateType)
}
// 采购单删除
export const delPurchaseOrderApi = params => {
  return axios.post('/new/purchase/order/delete?id=' + params)
}
// 采购订单每日新增
export const getOrderDayCountApi = params => {
  return axios.post('/new/purchase/order/dayCount')
}

// 销售方删除采购单
export const delSellerOrderApi = params => {
  return axios.post('/new/purchase/order/deleteSell?id=' + params)
}
// 签订人列表
export const signatoryApi = params => {
  return axios.get('/sys/user-info/user-list?companyId=' + params.companyId + '&name=' + params.name)
}
// 新增采购单
export const addPurchaseOrderApi = params => {
  return axios.post('/new/purchase/order/saveMsg', params)
}

// 根据信息查询协议价格
export const  findPriceApi = params => {
  return axios.get('/new/newdeal/findOnePrice?sellerId=' + params.sellerId + '&productId=' + params.productId + '&type=' + params.type)
}

// 采购计划变更
export const changePurchaseOrderApi = params => {
  return axios.post('/new/purchase/order/saveChangeMsg', params)
}
// 采购单提交
export const submitPurchaseOrderApi = params => {
  return axios.get('/new/purchase/order/submit?id=' + params.id + '&enquiryId=' + params.enquiryId)
}
// 采购单详情
export const purchaseOrderDetailApi = params => {
  return axios.get('/new/purchase/order/info?id=' + params)
}
// 采购订单结束审批流
export const purchaseOrderEndApi = params => {
  return axios.post('/new/purchase/order/finish?id=' + params)
}

// 修改采购订单状态(销售订单专用)
export const editPurchaseOrderStateApi = params => {
  return axios.get('/new/purchase/order/changeState?id=' + params.id + '&state=' + params.state + '&stateType=' + params.stateType)
}

// 采购订单完结
export const overPurchaseOrderApi = params => {
  return axios.get('/new/purchase/order/changeState?id=' + params.id + '&state=' + params.state + '&stateType=' + params.stateType)
}

// 获取流程动态和数据日志(采购计划专用)
export const getOrderAllChangeMsgApi = params => {
  return axios.get('/new/purchase/order/getAllChangeMsg?id=' + params)
}
// 获取操作日志)(销售单专用)
export const getsellerOrderChangeMsgApi = params => {
  return axios.get('/new/purchase/order/getAllChangeMsgSell?id=' + params)
}
// 采购订单审批操作
export const approvalpurchaseOrderApi = params => {
  return axios.post('/new/purchase/order/approve', params)
}

// 销售方录入实时库存
export const confirmEnterApi = params => {
  return axios.post('/new/purchase/order/saveWarehouseNumber', params)
}

// 相关订单
export const relateOrderApi = params => {
  return axios.get('/new/purchase/plan/rolist?id=' + params.id + '&roleType=' + params.roleType)
}

// 采购计划模块--------------------------------
// 采购计划列表
export const purchasePlanListApi = params => {
  return axios.get('/new/purchase/plan/list?page=' + params.page + '&size=' + params.size + '&code=' + params.code + '&productName=' + params.productName + '&createTimeBegin=' + params.createTimeBegin + '&createTimeEnd=' + params.createTimeEnd + '&approveStatus=' + params.approveStatus + '&proposerId=' + params.proposerId + '&ifAwait=' + params.ifAwait + '&buyTimeBegin=' + params.buyTimeBegin + '&buyTimeEnd=' + params.buyTimeEnd)
}
// 采购计划单删除
export const delPurchasePlanApi = params => {
  return axios.get('/new/purchase/plan/delete?id=' + params)
}
// 申请人列表
export const getUserManageApi = params => {
  return axios.get('/sys/user-info/list?companyId=' + params.companyId + '&name=' + params.name + '&page=' + params.page + '&size=' + params.size + '&state=' + params.state + '&isActivate=' + params.isActivate + '&deptId=' + params.deptId)
}
// 采购计划保存
export const addPurchasePlanApi = params => {
  return axios.post('/new/purchase/plan/saveMsg', params)
}
// 采购计划变更
export const changePurchasePlanApi = params => {
  return axios.post('/new/purchase/plan/saveChangeMsg', params)
}
// 采购计划单提交
export const submitPurchasePlanApi = params => {
  return axios.get('/new/purchase/plan/submit?id=' + params)
}
// 采购计划详情
export const purchasePlanDetailApi = params => {
  return axios.get('/new/purchase/plan/info?id=' + params)
}
// 采购计划结束审批流
export const purchasePlanEndApi = params => {
  return axios.post('/new/purchase/plan/finish?id=' + params)
}
// 流程审批数据
export const getApprovalProcessApi = params => {
  return axios.post('/sys/sell/audit-detail?id=' + params.id + '&type=' + params.type)
}
// 采购计划审批操作
export const approvalpurchasePlanApi = params => {
  return axios.post('/new/purchase/plan/approve', params)
}
// 获取流程动态和数据日志(采购计划专用)
export const getAllChangeMsgApi = params => {
  return axios.get('/new/purchase/plan/getAllChangeMsg?id=' + params)
}
// 采购计划物料列表
export const getPlanOrderListApi = params => {
  return axios.get('new/purchase/plan/lineList?page=' + params.page + '&size=' + params.size + '&productName=' + params.productName + '&selectAll=' + params.selectAll + '&planIds=' + params.planIds + '&approveStatus=' + params.approveStatus)
}
// 销售--------------------------------

// 添加打印模板
export const addPrintTemplateApi = params => {
  return axios.post('/sys/print/template/add', params)
}

// 修改打印模板
export const editPrintTemplateApi = params => {
  return axios.post('/sys/print/template/update', params)
}

// 发货单管理
export const deliverGoods = params => {
  return axios.get('/new/delivery/list?code=' + params.code + '&companyName=' + params.companyName + '&state=' + params.state + '&createTimeBegin=' + params.createTimeBegin + '&createTimeEnd=' + params.createTimeEnd + '&type=' + params.type + '&page=' + params.page + '&size=' + params.size)
}

// 发货单删除
export const deliverGoodsDelApi = params => {
  return axios.get('/new/delivery/delete?id=' + params)
}
// 发货单详情
// /new/delivery/info
export const deliverGoodsDetailApi = params => {
  return axios.get('/new/delivery/info?id=' + params.id)
}
// 发货单保存
export const deliverGoodsAffirmApi = params => {
  return axios.post('/new/delivery/saveMsg', params)
}
// 发货单提交
export const ConfirmDeliveryApi = params => {
  return axios.get('/new/delivery/affirm?id=' + params)
}
// 新增发货单客户名称获取
export const deliverGoodSellerApi = params => {
  return axios.get('/new/seller/list?search=' + params.search + '&roleType=' + params.roleType + '&status=3' + '&page=' + params.page + '&size=' + params.size)
}
//  新增发货单列表
// /new/purchase/order/lineListSell
export const addSellerListApi = params => {
  return axios.get('/new/purchase/order/lineListSell?page=' + params.page + '&size=' + params.size + '&companyId=' + params.companyId + '&codeAndProductName=' + params.codeAndProductName + '&state=' + params.state + '&arriveTimeBegin=' + params.arriveTimeBegin + '&arriveTimeEnd=' + params.arriveTimeEnd + '&replenishment=' + params.replenishment)
}
// 发货单导出
export const deliverGoodExportApi = params => {
  return axios.get('/new/delivery/export?code=' + params.code + '&orderCode=' + params.orderCode + '&companyName=' + params.companyName + '&state=' + params.state + '&takeState=' + params.takeState + '&createTimeBegin=' + params.createTimeBegin + '&createTimeEnd=' + params.createTimeEnd + '&type=' + params.type,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '发货单管理.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 财务----------
// 扣款模块----------------------------------------(采购端)

// 产品管理导出
export const cpglExportApi = params => {
  return axios.get('/sys/product/export?describes=' + params.describes + '&num=' + params.num + '&specs	=' + params.specs	 + '&typeName=' + params.typeName + '&status=' + params.status + '&groupId=' + params.groupId + '&typeId=' + params.typeId + '&warehouseId=' + params.warehouseId + '&name=' + params.name,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '产品管理.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 采购计划单导出

export const cgjhExportApi = params => {
  return axios.get('/new/purchase/plan/export?code=' + params.code + '&productName=' + params.productName + '&approveTimeBegin	=' + params.approveTimeBegin	 + '&approveTimeEnd=' + params.approveTimeEnd + '&approveStatus=' + params.approveStatus + '&proposerId=' + params.proposerId + '&ifAwait=' + params.ifAwait,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '采购计划单.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 采购单导出
export const cgdExportApi = params => {
  return axios.get('/new/purchase/order/export?code=' + params.code + '&productName=' + params.productName + '&approveTimeBegin	=' + params.approveTimeBegin	 + '&approveTimeEnd=' + params.approveTimeEnd + '&approveStatus=' + params.approveStatus + '&state=' + params.state + '&sellId=' + params.sellId + '&ifAwait=' + params.ifAwait,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '采购单.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 采购退货单
export const cgthExportApi = params => {
  return axios.get('/new/purchase/return/export?code=' + params.code + '&productName=' + params.productName + '&approveTimeBegin	=' + params.approveTimeBegin	 + '&approveTimeEnd=' + params.approveTimeEnd + '&type=' + params.type + '&sellId=' + params.sellId,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '采购退货单.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 扣款管理
export const kkExportApi = params => {
  return axios.get('/new/finance/export?name=' + params.name + '&sellerName=' + params.sellerName + '&startTime	=' + params.startTime	 + '&endTime=' + params.endTime + '&status=' + params.status + '&auditStatus=' + params.auditStatus,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '扣款管理.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 预付款
export const yfkExportApi = params => {
  return axios.get('/new/finance/advanceExport?orderNum=' + params.orderNum + '&number=' + params.number + '&sellerName	=' + params.sellerName	 + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&payStartTime=' + params.payStartTime + '&payEndTime=' + params.payEndTime + '&status=' + params.status,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '预付款管理.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 对账单采购导出
export const dzdCExportApi = params => {
  return axios.get('/new/financedz/export?number=' + params.number + '&sellerName=' + params.sellerName + '&startTime	=' + '&endTime=' + params.endTime + '&status=' + params.status,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '对账单.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 付款单导出
export const fkdExportApi = params => {
  return axios.get('/new/financedz/paymentExport?number=' + params.number + '&sellerName=' + params.sellerName + '&startTime	=' + '&endTime=' + params.endTime + '&status=' + params.status,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '付款单.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 供应
// 收款单导出
export const skExportApi = params => {
  return axios.get('/new/supplyFinance/Sk/export?number=' + params.number + '&cgCompanyName=' + params.cgCompanyName + '&createTimeStart=' + params.createTimeStart + '&createTimeEnd=' + params.createTimeEnd + '&status=' + params.status + '&auditStatus=' + params.auditStatus,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '收款单管理.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 对账单导出
export const dzExportApi = params => {
  return axios.get('/new/supplyFinance/Dzd/export?number=' + params.number + '&cgCompanyName=' + params.cgCompanyName + '&createTimeStart=' + params.createTimeStart + '&createTimeEnd=' + params.createTimeEnd + '&status=' + params.status + '&auditStatus=' + params.auditStatus,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '对账单管理.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 销售金额导出
export const jeExportApi = params => {
  return axios.get('/new/statement/export?companyId=' + params.companyId + '&page=' + params.page + '&size=' + params.size + '&code=' + params.code + '&name=' + params.name,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '销售金额.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 销售汇总导出
export const hzExportApi = params => {
  return axios.get('/new/statement/sellCollectExport??companyName=' + params.companyName + '&page=' + params.page + '&size=' + params.size + '&productName=' + params.productName,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '销售汇总.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 销售明细导出
export const mxExportApi = params => {
  return axios.get('/new/purchase/order/lineListExportSell?page=' + params.page + '&size=' + params.size + '&state=2' + '&companyId=' + params.companyId + '&codeAndProductName=' + params.codeAndProductName + '&arriveTimeBegin=' + params.arriveTimeBegin + '&arriveTimeEnd=' + params.arriveTimeEnd + '&replenishment=' + params.replenishment + '&code=' + params.code + '&productName=' + params.productName + '&companyName=' + params.companyName,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '销售明细.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 扣款导出
export const GyKkExportApi = params => {
  return axios.get('/new/supplyFinance/Kk/export?page=' + params.page + '&size=' + params.size + '&status=' + params.status + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&cgCompanyName=' + params.cgCompanyName + '&name=' + params.name + '&auditStatus=' + params.auditStatus,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '扣款单.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}

// 预收款导出
export const GyYskExportApi = params => {
  return axios.get('/new/supplyFinance/Ysk/export?current=' + params.current + '&size=' + params.size + '&orderNum=' + params.orderNum + '&cgCompanyName=' + params.cgCompanyName + '&createTimeStart=' + params.createTimeStart + '&createTimeEnd=' + params.createTimeEnd + '&auditStatus=' + params.auditStatus + '&payTimeStart=' + params.payTimeStart + '&payTimeEnd=' + params.payTimeEnd,
    { responseType: 'arraybuffer' }).then(res => {
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let objectUrl = URL.createObjectURL(blob) // 创建URL
    link.href = objectUrl
    link.download = '预收款.xlsx' // 自定义文件名
    link.click() // 下载文件
    URL.revokeObjectURL(objectUrl) // 释放内存
  })
}
// 质量--------------------------------------------------
// 扣款模块----------
// 扣款列表
export const chargeBackListApi = params => {
  return axios.get('/new/finance/deductionList?page=' + params.page + '&size=' + params.size + '&status=' + params.status + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&sellerName=' + params.sellerName + '&sellerId=' + params.sellerId + '&name=' + params.name + '&type=' + params.type + '&auditStatus=' + params.auditStatus)
}
// 新增扣款单
export const addChargeBackApi = params => {
  return axios.post('/new/finance/addDeduction', params)
}
// 修改扣款单
export const editChargeBackApi = params => {
  return axios.post('/new/finance/editDeduction', params)
}
// 扣款单详情
export const chargeBackDetailApi = params => {
  return axios.get('/new/finance/deductionDetail?id=' + params)
}
// 删除扣款单
export const delChargeBackApi = params => {
  return axios.post('/new/finance/editKKStatus', params)
}
// 获取扣款单所剩金额
export const getBackPriceApi = params => {
  return axios.get('/new/finance/getAdvanceBalance?id=' + params.id + '&orderId=' + params.orderId + '&lineId=' + params.lineId)
}

// 扣款模块----------------------------------------(供应端)
// 扣款列表
export const sellChargeBackListApi = params => {
  return axios.get('/new/supplyFinance/Kk/page?page=' + params.page + '&size=' + params.size + '&status=' + params.status + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&cgCompanyName=' + params.cgCompanyName + '&name=' + params.name + '&auditStatus=' + params.auditStatus)
}
// 扣款单详情
export const sellChargeBackDetailApi = params => {
  return axios.get('/new/supplyFinance/Kk/info?id=' + params)
}
// 删除扣款单
export const sellDelChargeBackApi = params => {
  return axios.post('/new/supplyFinance/Kk/del/' + params)
}
// 取消扣款单
export const sellCancelChargeBackApi = params => {
  return axios.post('/new/supplyFinance/Kk/editQx/' + params)
}
// 撤销扣款单
export const sellReturnChargeBackApi = params => {
  return axios.post('/new/supplyFinance/Kk/editCh/' + params)
}
// 确认扣款单
export const sellConfirmChargeBackApi = params => {
  return axios.post('/new/supplyFinance/Kk/editQr/' + params)
}

// 预付款模块------------(采购端)
// 预付款列表
export const prePaymentListApi = params => {
  return axios.get('/new/finance/advanceList?page=' + params.page + '&size=' + params.size + '&orderNum=' + params.orderNum + '&status=' + params.status + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&sellerName=' + params.sellerName + '&payStartTime=' + params.payStartTime + '&payEndTime=' + params.payEndTime)
}
// 新增预付款单
export const addPrePaymentApi = params => {
  return axios.post('/new/finance/addAdvance', params)
}
// 修改预付款单
export const editPrePaymentApi = params => {
  return axios.post('/new/finance/editAdvance', params)
}
// 预付款单详情
export const prePaymentDetailApi = params => {
  return axios.get('/new/finance/advanceDetail?id=' + params)
}
// 删除预付款单
export const delPrePaymentApi = params => {
  return axios.post('/new/finance/editYFKStatus', params)
}
// 取消预付款单
export const canclePrePaymentApi = params => {
  return axios.post('/new/finance/editYfkAuditStatus', params)
}
// 获取付款单所剩金额
export const getMorePriceApi = params => {
  return axios.get('/new/finance/getAdvanceBalance?id=' + params.id + '&orderId=' + params.orderId)
}

// 预收款模块--------------(供应端)
// 预收款列表
export const sellPrePaymentListApi = params => {
  return axios.get('/new/supplyFinance/Ysk/page?page=' + params.page + '&size=' + params.size + '&orderNum=' + params.orderNum + '&cgCompanyName=' + params.cgCompanyName + '&createTimeStart=' + params.createTimeStart + '&createTimeEnd=' + params.createTimeEnd + '&auditStatus=' + params.auditStatus + '&payTimeStart=' + params.payTimeStart + '&payTimeEnd=' + params.payTimeEnd + '&status=' + params.status)
}
// 预收款单详情
export const sellPrePaymentDetailApi = params => {
  return axios.get('/new/supplyFinance/Ysk/info?id=' + params)
}
// 删除预收款单
export const sellDelPrePaymentApi = params => {
  return axios.post('/new/supplyFinance/Ysk/del/' + params)
}
// 取消预收款单
export const sellCanclePrePaymentApi = params => {
  return axios.post('/new/supplyFinance/Ysk/editQx/' + params)
}
// 撤销预收款单
export const sellReturnPrePaymentApi = params => {
  return axios.post('/new/supplyFinance/Ysk/editCh/' + params)
}
// 确认预收款单
export const sellConfirmPrePaymentApi = params => {
  return axios.post('/new/supplyFinance/Ysk/editQr/' + params)
}

// 采购退料模块------------(采购端)
// 采购退料列表
export const purchaseReturnListApi = params => {
  return axios.get('/new/purchase/return/list?page=' + params.page + '&size=' + params.size + '&code=' + params.code + '&createTimeBegin=' + params.createTimeBegin + '&createTimeEnd=' + params.createTimeEnd + '&productName=' + params.productName + '&approveStatus=' + params.approveStatus + '&sellId=' + params.sellId + '&type=' + params.type + '&state=' + params.state + '&purchaseCode=' + params.purchaseCode)
}
// 新增采购退料
export const addPurchaseReturnApi = params => {
  return axios.post('/new/purchase/return/saveMsg', params)
}
// 删除采购退料
export const delPurchaseReturnApi = params => {
  return axios.post('/new/purchase/return/delete?id=' + params.id + '&type=' + params.type)
}
// 修改采购退料
export const editPurchaseReturnApi = params => {
  return axios.post('/new/purchase/return/saveMsg', params)
}
// 采购退料单详情
export const purchaseReturnDetailApi = params => {
  return axios.get('/new/purchase/return/info?id=' + params)
}
// 修改退货单状态
export const editPurchaseReturnStateApi = params => {
  return axios.get('/new/purchase/return/changeState?id=' + params.id + '&type=' + params.type)
}

// 对账单模块------------(采购端)
// 对账单列表
export const balanceAccountListApi = params => {
  return axios.get('/new/financedz/queryPage?page=' + params.page + '&size=' + params.size + '&number=' + params.number + '&sellerName=' + params.sellerName + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&status=' + params.status)
}
// 新增对账单
export const addBalanceAccountApi = params => {
  return axios.post('/new/financedz/addBill', params)
}
// 删除对账单
export const delBalanceAccountApi = params => {
  return axios.post('/new/financedz/editStatus', params)
}
// 取消确认对账单
export const cancleBalanceAccountApi = params => {
  return axios.post('/new/financedz/delBill?id=' + params)
}
// 获取对账单明细
export const balanceAccountSonListApi = params => {
  return axios.get('/new/financedz/orderRecord?id=' + params.id + '&sellerId=' + params.sellerId + '&startTime=' + params.startTime + '&endTime=' + params.endTime)
}
// 修改对账单
export const editBalanceAccountApi = params => {
  return axios.post('/new/financedz/editBill', params)
}
// 对账单详情
export const balanceAccountDetailApi = params => {
  return axios.get('/new/financedz/detail?id=' + params)
}
// 撤回对账单
export const returnBalanceAccountApi = params => {
  return axios.post('/new/financedz/delBill?id=' + params)
}

// 转付款单
export const createPayApi = params => {
  return axios.post('/new/financedz/createPay', params)
}
// 对账单模块------------(供应端)
// 对账单列表
export const sellbalanceAccountListApi = params => {
  return axios.get('/new/supplyFinance/Dzd/page?current	=' + params.current	 + '&size=' + params.size + '&number=' + params.number + '&cgCompanyName=' + params.cgCompanyName + '&createTimeStart=' + params.createTimeStart + '&createTimeEnd=' + params.createTimeEnd + '&status=' + params.status + '&auditStatus=' + params.auditStatus)
}
// 新增对账单
export const selladdBalanceAccountApi = params => {
  return axios.post('/new/financedz/addBill', params)
}
// 确认对账单
export const selldelBalanceAccountApi = params => {
  return axios.post('/new/supplyFinance/Dzd/editQr/' + params.id)
}
// 获取对账单明细
export const sellbalanceAccountSonListApi = params => {
  return axios.get('/new/financedz/orderRecord?id=' + params.id + '&sellerId=' + params.sellerId + '&startTime=' + params.startTime + '&endTime=' + params.endTime)
}
// 对账单撤销
export const selleditBalanceAccountApi = params => {
  return axios.post('/new/financedz/delBill?id=' + params.id)
}
// 对账单详情
export const sellbalanceAccountDetailApi = params => {
  return axios.get('/new/supplyFinance/Dzd/info?id=' + params)
}
// 对账单撤回
export const sellcreatePayApi = params => {
  return axios.post('/new/supplyFinance/Dzd/editCh/' + params.id)
}
// 对账单删除
//
export const sellcreatePayDeleteApi = params => {
  return axios.post('/new/supplyFinance/Dzd/del/' + params.id)
}
// 收款单模块-----------------
// 收款单删除
export const receiptDeleteApi = params => {
  return axios.post('/new/supplyFinance/Sk/del/' + params.id)
}
// 收款单撤回
export const receiptCancelApi = params => {
  return axios.post('/new/supplyFinance/Sk/editCh/' + params.id)
}
// 收款单取消
export const receiptRevocationApi = params => {
  return axios.post('/new/supplyFinance/Sk/editQx/' + params.id)
}
//  收款单管理
export const CollectionManagementListApi = params => {
  return axios.get('/new/supplyFinance/Sk/page?page=' + params.page + '&size=' + params.size + '&number=' + params.number + '&cgCompanyName=' + params.cgCompanyName + '&createTimeStart=' + params.createTimeStart + '&createTimeEnd=' + params.createTimeEnd + '&createTimeEnd=' + params.createTimeEnd + '&auditStatus=' + params.auditStatus + '&status=' + params.status)
}
// 收款单导出

export const CollectionManagementDeriveApi = params => {
  return axios.get('/new/supplyFinance/Sk/page?number=' + params.number + '&cgCompanyName=' + params.cgCompanyName + '&createTimeStart=' + params.createTimeStart + '&createTimeEnd=' + params.createTimeEnd + '&createTimeEnd=' + params.createTimeEnd + '&auditStatus=' + params.auditStatus)
}
// 收款单详情
export const CollectionManagementDetailApi = params => {
  return axios.get('/new/supplyFinance/Sk/info?id=' + params)
}
// 收款单确认
export const CollectionManagementComfireApi = params => {
  return axios.post('/new/supplyFinance/Sk/editQr/' + params.id)
}

// 收款单保存上传发票
export const CollectionUpdateFileApi = params => {
  return axios.post('/new/supplyFinance/Sk/updateFile', params)
}

// 付款单模块-----------------
// 付款单列表
export const paymentListApi = params => {
  return axios.get('/new/financedz/paymentList?page=' + params.page + '&size=' + params.size + '&number=' + params.number + '&sellerName=' + params.sellerName + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&status=' + params.status)
}
// 撤回付款单
export const delPaymentApi = params => {
  return axios.post('/new/financedz/delPayment?id=' + params)
}
// 确认付款单
export const editPaymentApi = params => {
  return axios.post('/new/financedz/editPayment', params)
}
// 付款单详情
export const paymentDetailApi = params => {
  return axios.get('/new/financedz/paymentDetail?id=' + params)
}
// 付款单明细
export const paymentDetailListApi = params => {
  return axios.get('/new/financedz/getRecordOrder?id=' + params)
}
// 其他扣款单列表
export const paymentDtnListApi = params => {
  return axios.get('/new/financedz/dtnList?id=' + params)
}

// 采购组-----------------
// 获取采购组列表
export const getpurchaseListApi = params => {
  return axios.get('/sys/purchase/list?name=' + params.name + '&page=' + params.page + '&size=' + params.size + '&state=' + params.state)
}

// 修改采购组
export const editpurchaseApi = params => {
  return axios.post('/sys/purchase/update', params)
}

// 新增采购组
export const addpurchaseApi = params => {
  return axios.post('/sys/purchase/save', params)
}

// 删除采购组
export const delpurchaseApi = params => {
  return axios.post('/sys/purchase/delete?id=' + params)
}

// 获取采购组中用户列表
export const getpurchaseUserApi = params => {
  return axios.get('/sys/purchase/user-list?purchaseId=' + params.purchaseId + '&companyId=' + params.companyId)
}

// 将用户添加进采购组
export const addpurchaseUserApi = params => {
  return axios.post('/sys/purchase/update-user', params)
}

// 6.1.用户列表(待确认订单催单)
export const supplierUserListApi = params => {
  return axios.get('/sys/user-info/user-list?companyId=' + params.companyId + '&name=' + params.name + '&isActivate=' + params.isActivate)
}

// 角色管理-----------------------
// 获取角色列表
export const getRoleListApi = params => {
  return axios.get('/sys/role/list?search=' + params.search + '&page=' + params.page + '&size=' + params.size)
}

// 删除角色
export const delRoleApi = params => {
  return axios.post('/sys/role/delete', params)
}

// 获取角色详情
export const getRoleInfoApi = params => {
  return axios.get('/sys/role/info?id=' + params)
}

// 修改角色详情
export const editRoleInfoApi = params => {
  return axios.post('/sys/role/update', params)
}

// 新增角色
export const addRoleInfoApi = params => {
  return axios.post('/sys/role/save', params)
}

// 系统参数配置----------------------------
// 获取系统参数列表
export const getSystemDisposeApi = params => {
  return axios.post('/sys/syscode/list', params)
}
// 保存系统参数值
export const keepDetailsDataApi = params => {
  return axios.post('/sys/syscode/codevalue/save', params)
}
// 删除系统参数值
export const delDetailsDataApi = params => {
  return axios.post('/sys/syscode/codevalue/delete', params)
}
// 系统参数参数类别列表
export const otherSystemListApi = params => {
  return axios.get('/sys/code/other/list?type=' + params.type + '&roleId=' + params.roleId)
}

// 保存系统参数类别
export const keepOtherSystemApi = params => {
  return axios.post('/sys/code/other/insert', params)
}

// 其他系统参数启用停用(仓储启用停用)
export const otherSystemUseApi = params => {
  return axios.get('/sys/code/other/setEnabled?id=' + params.id + '&enabled=' + params.enabled + '&type=' + params.type)
}
// 其他系统参数,根据type获取详情
export const getOtherSystemTypeApi = params => {
  return axios.get('/sys/code/other/getByType?type=' + params)
}

// 用户管理
// 根据id获取角色列表(用户管理新增修改页面)
export const idGetRoleListApi = params => {
  return axios.get('/sys/role/all-list?companyId=' + params)
}

// 删除用户
export const delUserApi = params => {
  return axios.post('/sys/user-info/deleteByIds?ids=' + params)
}

// 根据infoId获取用户详情
export const userIdGetUserInfoApi = params => {
  return axios.post('/sys/user-info/detail?infoId=' + params)
}
// 添加新用户(子账号)
export const addChildUserApi = params => {
  return axios.post('/sys/user-info/save', params)
}
// 修改新用户(子账号)
export const editChildUserApi = params => {
  return axios.post('/sys/user-info/update', params)
}

// 数据转移(离职交接)
export const departTransferApi = params => {
  return axios.post('/sys/user-info/departDTS', params)
}

// 部门列表
export const departmentListApi = params => {
  return axios.get('/new/dept/list?search=' + params)
}

// 新增或修改部门
export const addDepartmentApi = params => {
  return axios.post('/new/dept/save', params)
}

// 删除部门
export const delDepartmentApi = params => {
  return axios.post('/new/dept/delete?id=' + params)
}

// 发送邀请短信
export const sendShortApi = params => {
  return axios.get('/sys/common/send-invite-sms?mobile=' + params.mobile + '&company=' + params.company + '&name=' + params.name + '&jobName=' + params.jobNum + '&infoId=' + params.infoId)
}

// 物理管理
// 0.1获取默认物理管理编码
export const getMatterCodeApi = params => {
  return axios.get('/sys/product/getDefaultCode')
}

// 0.4获取默认用户编码
export const getUserCodeApi = params => {
  return axios.get('/sys/user-info/getDefaultCode')
}

// 系统
// ★流程管理
// 获取审批列表
export const approvalManageApi = params => {
  return axios.get('/sys/approve/list?name=' + params.name + '&status=' + params.status)
}
// 修改审批状态
export const editApprovalStatusApi = params => {
  return axios.post('/sys/approve/update', params)
}

// 获取审批节点列表
export const approvalNodeApi = params => {
  return axios.get('/sys/approve/node-list?approveId=' + params)
}

// 修改审批节点
export const editNodeApi = params => {
  return axios.post('/sys/approve/update-node', params)
}

// 添加审批节点
export const addNodeApi = params => {
  return axios.post('/sys/approve/save-node', params)
}

// 删除审批节点
export const delNodeApi = params => {
  return axios.post('/sys/approve/delete-node?id=' + params)
}

// 企业资料
// 母公司搜索列表
export const relationSearchApi = params => {
  return axios.get('/sys/relation/search?companyName=' + params)
}
// 修改母公司
export const saveRationApi = params => {
  return axios.post('/sys/relation/save?mid=' + params)
}
// 物料对照关系列表
export const matterRelationListApi = params => {
  return axios.get('/sys/product/comparison/list?purchaserId=' + params.purchaserId + '&purchaserName=' + params.purchaserName + '&purchaserProductCode=' + params.purchaserProductCode + '&purchaserProductName=' + params.purchaserProductName + '&purchaserProductSpecs=' + params.purchaserProductSpecs + '&sellerProductCode=' + params.sellerProductCode + '&sellerProductName=' + params.sellerProductName + '&sellerProductSpecs=' + params.sellerProductSpecs + '&enabled=' + params.enabled + '&page=' + params.page + '&size=' + params.size)
}

// 解除关联
export const deleteRationApi = params => {
  return axios.post('/sys/relation/delete?id=' + params)
}

// 确认关联
export const updateRelationApi = params => {
  return axios.post('/sys/relation/update?id=' + params)
}

// 获取企业规模表
export const insizeListApi = params => {
  return axios.get('/sys/common/scale-list')
}

// 获取企业信息
export const getEnterInfoApi = params => {
  return axios.post('/sys/user/company/info', params)
}

// 修改企业信息
export const editEnterInfoApi = params => {
  return axios.post('/sys/user/company/updateInfo', params)
}

// 修改开票信息
export const editInvoiceInfoApi = params => {
  return axios.post('/sys/user/company/saveInvoice', params)
}

// 删除收货地址
export const delAddressApi = params => {
  return axios.post('/sys/user/company/removeDeliveryAddress', params)
}

// 新增收货地址
export const addAddressApi = params => {
  return axios.post('/sys/user/company/saveDeliveryAddress', params)
}

// 保存开票信息
export const keepDrawerInfoApi = params => {
  return axios.post('/sys/user/company/saveInvoice', params)
}

// 企业注销
export const companyCancelApi = params => {
  return axios.post('/sys/user/cancel?companyId=' + params)
}

// 仓储
// 仓库管理
// 1.基础资料 仓库资料

// 新增仓库
export const addStoreApi = params => {
  return axios.post('/sys/warehouse/basics/save', params)
}
// 0.3获取默认仓库资料编码
export const getStoreHouseCodeApi = params => {
  return axios.get('/sys/warehouse/basics/getDefaultCode')
}
// 根据用户获取已选择仓库
export const getChooseWarehouseApi = params => {
  return axios.get('/sys/warehouse/user/getWarehouseByUser?infoId=' + params)
}
// 选择仓库后进行保存
export const choosewarehouseKeepApi = params => {
  return axios.post('/sys/warehouse/user/saveWarehouseByUser', params)
}
// 1.1获取所有仓库列表
export const storeHouseListAllApi = params => {
  return axios.get('/sys/warehouse/basics/listAll?code=' + params.code + '&name=' + params.name + '&enabled=' + params.enabled + '&page=' + params.page + '&size=' + params.size)
}
// 1.2根据仓库获取已选择的用户
export const getChooseUserApi = params => {
  return axios.get('/sys/warehouse/user/getUserByWarehouse?warehouseId=' + params)
}
// 1.3选择用户后进行保存
export const chooseUserKeepApi = params => {
  return axios.post('/sys/warehouse/user/saveUserByWarehouse', params)
}

// 仓库启用停用
export const storeHouseUseApi = params => {
  return axios.get('/sys/warehouse/basics/setEnabled?id=' + params.id + '&enabled=' + params.enabled)
}
// 仓库设为默认
export const setDefaultStoreHouseApi = params => {
  return axios.get('/sys/warehouse/basics/setDefault?id=' + params)
}
// 删除仓库
export const delStoreHouseApi = params => {
  return axios.get('/sys/warehouse/basics/delete?ids=' + params)
}

// 初始化库存
export const initializeStoreHouseApi = params => {
  return axios.post('/sys/warehouse/basics/initialize?warehouseId=' + params.warehouseId, params.list)
}
// 仓库物料列表(库存台账, 库存盘点详情共用)
export const storeHouseMatterListApi = params => {
  return axios.get('/sys/warehouse/basics/getProductByWarehouseId?warehouseId=' + params.warehouseId + '&productCode=' + params.productCode + '&productName=' + params.productName + '&productSpecs=' + params.productSpecs + '&page=' + params.page + '&size=' + params.size + '&businessTypes=' + params.businessTypes + '&productNameOrCode=' + params.productNameOrCode)
}

// 出库单列表
export const outManageListApi = params => {
  return axios.get('/new/warehouse/outbound/list?outboundTimeBegin=' + params.outboundTimeBegin + '&outboundTimeEnd=' + params.outboundTimeEnd + '&productName=' + params.productName + '&sellName=' + params.sellName + '&warehouseId=' + params.warehouseId + '&type=' + params.type + '&code=' + params.code + '&page=' + params.page + '&size=' + params.size)
}

// 新增出库单
export const addOutboundApi = params => {
  return axios.post('/new/warehouse/outbound/save', params)
}

// 出库单详情
export const outDetailApi = params => {
  return axios.get('/sys/warehouse/outbound/info?id=' + params)
}

// 库存管理列表
export const warehouseListApi = params => {
  return axios.get('/new/warehouse/basics/list?warehouseId=' + params.warehouseName + '&productName=' + params.productName + '&page=' + params.page + '&size=' + params.size)
}

// 设置
// 配置模块获取列表
export const moduleApi = params => {
  return axios.get('/new/parameter/list')
}
// 配置模块变更
export const moduleUpdateApi = params => {
  return axios.post('/new/parameter/save', params)
}
// 收货校验单获取列表
export const ReceiptCheckListApi = params => {
  return axios.get('/new/delivery/list?code=' + params.code + '&companyName=' + params.companyName + '&orderCode=' + params.orderCode + '&state=1' + '&createTimeBegin=' + params.createTimeBegin + '&createTimeEnd=' + params.createTimeEnd + '&takeState=' + params.takeState + '&type=' + params.type)
}
// 收货校验单确认收货
export const confirmReceiptApi = params => {
  return axios.post('/new/delivery/saveMsgTake', params)
}
// 收货单详情
export const confirmReceiptDetailApi = params => {
  return axios.get('/new/delivery/info?id=' + params.id)
}
// 新增补货单详情
// /new/delivery/info
export const replenishmentDetailApi = params => {
  return axios.get('/new/delivery/info?id=' + params.id)
}
// 补货单保存
export const replenishmentAffirmApi = params => {
  return axios.post('/new/delivery/saveMsgReplenishment', params)
}
// 补货单删除
export const replenishmentDeleteApi = params => {
  return axios.get('/new/delivery/deleteReplenishment?id=' + params.id)
}
// 补货单提交
export const replenishmentOkApi = params => {
  return axios.get('/new/delivery/affirmReplenishment?id=' + params)
}

// 补货单确认收货
export const replenishmentConfirmReceiptApi = params => {
  return axios.post('/new/delivery/saveMsgTakeReplenishment', params)
}
// 销售明细列表
export const salesDetailListApi = params => {
  return axios.get('/new/purchase/order/lineListSell?page=' + params.page + '&size=' + params.size + '&state=2' + '&companyId=' + params.companyId + '&codeAndProductName=' + params.codeAndProductName + '&arriveTimeBegin=' + params.arriveTimeBegin + '&arriveTimeEnd=' + params.arriveTimeEnd + '&replenishment=' + params.replenishment + '&code=' + params.code + '&productName=' + params.productName + '&companyName=' + params.companyName)
}
// 销售金额汇总
export const salesAmountListApi = params => {
  return axios.get('/new/statement/statFinance?companyId=' + params.companyId + '&page=' + params.page + '&size=' + params.size + '&code=' + params.code + '&name=' + params.name)
}
// /new/statement/sellCollect
// 销售汇总
export const salesSummaryListApi = params => {
  return axios.get('/new/statement/sellCollect?companyName=' + params.companyName + '&page=' + params.page + '&size=' + params.size + '&productName=' + params.productName)
}
// 交付地址维护模块
// 交付地址列表
// /new/newJiaoFu/list
export const siteListApi = params => {
  return axios.get('/new/newJiaoFu/list')
}
// 交付地址删除
export const siteDeleteApi = params => {
  return axios.post('/new/newJiaoFu/del/' + params)
}
// 交付地址添加
export const siteAddApi = params => {
  return axios.post('/new/newJiaoFu/add', params)
}
// 交付地址编辑
export const siteUpdateApi = params => {
  return axios.post('/new/newJiaoFu/edit', params)
}
// 设为默认地址
export const siteMarkApi = params => {
  return axios.post('/new/newJiaoFu/editMr/' + params)
}
// 交付地址详情
export const siteDetailApi = params => {
  return axios.get('/new/newJiaoFu/info?id=' + params)
}
// 添加打印模版
export const printAddApi = params => {
  return axios.post('/sys/print/template/add', params)
}
// 模板详情
export const printDetailApi = params => {
  return axios.get('/sys/print/template/detail?id=' + params)
}
// 模板列表
export const printListApi = params => {
  return axios.get('/sys/print/template/list?type=' + params)
}
// 修改打印模板
export const printUpdateApi = params => {
  return axios.post('/sys/print/template/update', params)
}
// 模板删除
export const printDelApi = params => {
  return axios.post('/sys/print/template/delete?id=' + params)
}
// 线下采购单分页
export const offinePuichaseApi = params => {
  return axios.get('/new/order/offline/page?orderNo=' + params.orderNo + '&productName=' + params.productName + '&startTime=' + params.startTime + '&endTime=' + params.endTime	+ '&status=' + params.status + '&page=' + params.page)
}
// 新增线下采购
export const addOffinePuiuchaseApi = params => {
  return axios.post('/new/order/offline/add', params)
}
// 详情
export const offinePuichaseDetailApi = params => {
  return axios.get('/new/order/offline/info?id=' + params)
}
export const offinePuichaseDelApi = params => {
  return axios.post('/new/order/offline/del/' + params.id)
}
// 线下供应商列表 0-0000000000000000000000000000000000000000000000
// 线下采购商分页
export const offineSellerApi = params => {
  return axios.get('/new/seller/offline/page?companyName=' + params.companyName + '&companyType=' + params.companyType)
}
// 新增
export const addOffineSellerApi = params => {
  return axios.post('/new/seller/offline/save', params)
}
// /new/seller/offline/del/ 删除
export const delOffineSellerApi = params => {
  return axios.post('/new/seller/offline/del/' + params)
}
export const offineSellerDetailApi = params => {
  return axios.get('/new/seller/offline/info?id=' + params)
}
// 价格协议
export const offineAgreementApi = params => {
  return axios.get('/new/newdeal/page?name=' + params.name)
}
// 新增
export const addOffineAgreementApi = params => {
  return axios.post('/new/newdeal/add', params)
}
// 删除
export const delOffineAgreementApi = params => {
  return axios.post('/new/newdeal/del/' + params)
}
export const offineAgreementDetailApi = params => {
  return axios.get('/new/newdeal/info?id=' + params)
}
// 供应商名称列表
export const offineSupplyDetailApi = params => {
  return axios.get('/new/newdeal/findAllGys')
}
// 线上供应商
export const offineSupplyOlineApi = params => {
  return axios.get('/new/newdeal/userList?sellerId=' + params)
}

// 询价竞价模块
// 新增询价竞价
export const addAskPriceApi = params => {
  return axios.post('/new/enquiry/saveMsg', params)
}
// 发布询价竞价
export const submitAskPriceApi = params => {
  return axios.get('/new/enquiry/submit?id=' + params)
}
// 询价列表
export const askPriceListApi = params => {
  return axios.get('/new/enquiry/list?state=' + params.state + '&codeAndName=' + params.codeAndName + '&page=' + params.page + '&size=' + params.size + '&type=' + params.type)
}

// 结束询价
export const endAskPriceApi = params => {
  return axios.get('/new/enquiry/finish?id=' + params)
}

// 报价列表(确认报价用的)
export const quoteListApi = params => {
  return axios.get('/new/enquiry/getOffer?round=' + params.round + '&enquiryId=' + params.enquiryId + '&state=' + params.state)
}

// 报价列表(审核报价用的)
export const quoteList2Api = params => {
  return axios.get('/new/enquiry/getOffer?round=' + params.round + '&enquiryId=' + params.enquiryId + '&offerIds=' + params.offerIds)
}

// 确认报价
export const confirmQuoteApi = params => {
  return axios.get('/new/enquiry/affirm?ids=' + params)
}

// 审核询价
export const approvalAskPriceApi = params => {
  return axios.get('/new/enquiry/approve?id=' + params)
}

// 取消确认询价
export const cancelAskPriceApi = params => {
  return axios.get('/new/enquiry/cancelAffirm?id=' + params)
}

// 开始询价
export const beginAskPriceApi = params => {
  return axios.get('/new/enquiry/begin?id=' + params)
}

// 删除询价
export const delAskPriceApi = params => {
  return axios.get('/new/enquiry/delete?id=' + params)
}

// 询价详情
export const askPricePurchaseDetailApi = params => {
  return axios.get('/new/enquiry/info?id=' + params)
}

// 竞价增加轮次
export const addRoundApi = params => {
  return axios.post('/new/enquiry/addRound', params)
}

// 供应端询价竞价分页
export const sellAskPriceListApi = params => {
  return axios.get('/new/enquiry/gys/page?page=' + params.page + '&size=' + params.size + '&type=' + params.type + '&name=' + params.name + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&state=' + params.state)
}
// 供应端询价详情
export const askPriceSellDetailApi = params => {
  return axios.get('/new/enquiry/gys/info?id=' + params)
}
// 供应端取消报价
export const cancelQuoteApi = params => {
  return axios.post('/new/enquiry/gys/qx/' + params)
}
// 供应端提交报价
export const submitSellQuoteApi = params => {
  return axios.post('/new/enquiry/gys/addData', params)
}
// 采购库存管理详情接口 /new/warehouse/basics/cgsInfo
export const purchaseRepertoryDetail = params => {
  return axios.get('/new/warehouse/basics/cgsInfo?id=' + params.id + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&orderNo=' + params.orderNo + '&type=' + params.type + '&status=' + params.status)
}
export const purchaseRepertoryOutDetail = params => {
  return axios.get('/new/warehouse/basics/gysInfo?id=' + params.id + '&startTime=' + params.startTime + '&endTime=' + params.endTime + '&orderNo=' + params.orderNo + '&type=' + params.type)
}
// 扫描执照信息

export const ocrDeatailApi = params => {
  return axios.get('/sys/common/ocrBusinessLicense?url=' + params)
}
// 提交审核信息

export const submitApproveAPi = params => {
  return axios.post('/new/userCompany/submit', params)
}
// 出库单采购出库请求接口
export const purchaseOutApi = params => {
  return axios.get('/new/purchase/order/lineListSellCk?page=' + params.page + '&size=' + params.size + '&companyId=' + params.companyId + '&codeAndProductNameAndProductCode=' + params.codeAndProductNameAndProductCode + '&arriveTimeBegin=' + params.arriveTimeBegin + '&arriveTimeEnd=' + params.arriveTimeEnd + '&replenishment=' + params.replenishment + '&code=' + params.code + '&productName=' + params.productName + '&companyName=' + params.companyName)
}
export const purchasePushApi = params => {
  return axios.get('/new/purchase/order/lineListSellRk?page=' + params.page + '&size=' + params.size + '&state=10,11,3' + '&companyId=' + params.companyId + '&codeAndProductName=' + params.codeAndProductName + '&arriveTimeBegin=' + params.arriveTimeBegin + '&arriveTimeEnd=' + params.arriveTimeEnd + '&replenishment=' + params.replenishment + '&code=' + params.code + '&productName=' + params.productName + '&companyName=' + params.companyName)
}
// ----------------------------------------end
