// 新增协议
<template>
  <div class="addPurchasePlan">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Priceagreement' }">价格协议</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">{{this.addData.id==='' || this.addData.id===undefined?'新增价格协议':'修改价格协议'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addPurchasePlanContent">
      <el-form ref="ruleForm" :model="addData" label-width="117px" :rules="rules">
        <nav>
          <h5>{{this.addData.id==='' || this.addData.id===undefined?'新增价格协议':'修改价格协议'}}</h5>
          <div class="navBox">
            <el-form-item label="协议名称:" prop="name">
                    <el-input class="sectionInp" v-model="addData.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="类型:" prop="dealStatus">
              <el-input class="sectionInp" disabled value="固定价格协议"></el-input>

            </el-form-item>
            <el-form-item label="供应商名称:" prop="sellerId">
              <el-select v-model="addData.sellerId" placeholder="供应商名称" clearable size="mini" @change="sellerFn($event)">
                <el-option v-for="(item, index) in sellList" :key="index" :label="item.type===0?`${item.name+'(线上)'}`:`${item.name+'(线下)'}`" :value="item.id" :value-key="index"  ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系人:" prop="linkUserId">
              <!-- @change="clearValue()" -->
            <div v-if="userList.length===0">
              <el-input  v-model="addData.linkUser" placeholder="请输入内容" clearable ></el-input>
            </div>
            <div v-else>
              <el-select v-model="addData.linkUserId" placeholder="联系人名称" clearable size="mini">
                <el-option v-for="(item, index) in userList" :key="index" :label="item.name+ '（'+item.phone + '）'" :value="item.id" :value-key="index"  ></el-option>
              </el-select>
            </div>
            </el-form-item>
            <el-form-item label="协议有效期:" prop="startTime">
              <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="协议有效期"
          end-placeholder="协议有效期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
            </el-form-item>
          </div>
        </nav>
        <main>
          <h5>产品配置明细</h5>
          <el-button class="lightDeepStyle addBtn" @click="openAddDialog()"><i class="el-icon-plus"></i>添加产品</el-button>
          <!-- v-if="addData.dealLineEntityList.length !== 0" -->
          <el-table ref="tableHeight" row-key="id" :height="tableHeight" :data="addData.dealLineEntityList" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="productName" label="产品信息" min-width="100px" align="center">
          <template slot-scope="scope" >
            <span  v-if="addData.id===''|| addData.id===undefined">{{scope.row.code + '-' + scope.row.productName + '-' + (scope.row.parentTypeName === null ? '暂无' : scope.row.parentTypeName) + '-' + (scope.row.productNewColour === null ? '暂无' : scope.row.productNewColour) + '-' + (scope.row.unit === null ? '暂无' : scope.row.unit)}}</span>
            <!-- <span v-if="addData.id===''|| addData.id===undefined">{{ scope.row.code+'-'+scope.row.productName+'-'+scope.row.+'-'+scope.row.+'-'+scope.row.}}</span> -->
            <span v-else>{{ scope.row.productNo+'-'+scope.row.productName+'-'+scope.row.productSpec+'-'+scope.row.productColor+'-'+scope.row.productUnit}}</span>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="price" label="协议单价" min-width="100px" align="center">
          <template slot-scope="scope">
            <div>
                <!-- <el-input v-model="scope.row.price" placeholder="请输入内容" clearable ></el-input> -->
                <el-input type="num" @input="formatNum2(scope.row.price, scope.row.productId, '')" oninput="if(value.length > 10) value=value.slice(0, 10)" v-model="scope.row.price" placeholder="输入数值"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
                  <el-button @click="delMatter(scope.row.id)"><i class="el-icon-delete"></i><span>删除</span></el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
          <!-- v-else -->
        </main>
      </el-form>
      <!-- 单弹框 -->
      <el-dialog class="chooseOrderDialog" title="选择产品" :visible.sync="chooseOrderDialog" :close-on-click-modal="false" v-dialogDrag>
        <div>
          <el-input class="sectionInp" v-model="matterManageData.codeAndName" placeholder="产品编号/产品名称搜索" @input="getMatterManageList()" prefix-icon="el-icon-search"></el-input>
        </div>
        <el-table ref="matterSearchRef" :data="matterManageList" style="width: 100%" height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
          <el-table-column type="selection" width="45px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="code" label="产品编号" min-width="110px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="120px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.specs === '' || scope.row.specs === null ? '暂未填写' : scope.row.specs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="newColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.newColour === '' || scope.row.newColour === null ? '暂未填写' : scope.row.newColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productType === 0 ? '成品' : (scope.row.productType === 1 ? '半成品' : (scope.row.productType === 2 ? '物料' : '服务'))}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="unit" label="单位" min-width="130px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.unit === null ? '-' : scope.row.unit}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button class="defaultStyle cancleBtn" @click="chooseOrderDialog = false">取 消</el-button>
          <el-button class="lightDeepStyle" @click="chooseMatter()">确 认</el-button>
        </div>
      </el-dialog>
    </div>
    <footer>
      <el-button class="lightDeepStyle" @click="addPurchasePlan()">保存</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { addOffineAgreementApi, matterListApi, offineAgreementDetailApi, offineSupplyDetailApi, offineSellerDetailApi, offineSupplyOlineApi } from '@/api'
export default {
  data() {
    return {
      chooseTime: '',
      // 日期选择器限制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now()
        }
      },
      userList: [],
      // 控制弹框
      chooseOrderDialog: false,
      // 新增参数
      addData: {
        id: '',
        name: '',
        type: '',
        proposerId: JSON.parse(window.sessionStorage.userInfo).userId,
        startTime: '',
        endTime: '',
        sellerId: '',
        dealStatus: '',
        linkUserId: '',
        linkUser: '',
        dealLineEntityList: []
      },
      // 申请人列表传参
      userManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 999,
        // 搜索内容
        name: '',
        companyId: window.$cookies.get('companyId'),
        state: '',
        isActivate: ''
      },
      // 申请人列表
      userManageList: [],
      // 选中的项
      chooseList: [],
      // 产品列表参数
      matterManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        status: '1',
        typeId: '',
        name: '',
        codeAndName: '',
        productType: ''
      },
      // 产品列表
      matterManageList: [],
      // 选中的物料
      matterList: [],
      // 校验
      rules: {
        linkUserId: [
          { required: true, message: '联系人ID不能为空!', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '协议名称不能为空!', trigger: 'blur' }
        ],
        sellerId: [
          { required: true, message: '供应商不能为空!', trigger: 'blur' }
        ],
        createTime: [
          { required: true, message: '申请日期不能为空!', trigger: 'blur' }
        ],
        dealStatus: [
          { required: true, message: '供应商类型不能为空!', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '协议时间不能为空!', trigger: 'blur' }
        ]
      },
      // 供应商列表
      sellList: [],
      tableHeight: ''
    }
  },
  watch: {
    addData: {
      handler(newData) {
        // Update chooseTime whenever data.startTime or data.endTime changes
        this.chooseTime = [newData.startTime, newData.endTime]
      },
      deep: true // To watch for nested properties inside data
    }
  },
  created() {
    if (this.$route.query === {}) {
      this.addData.id = ''
    } else {
      this.addData.id = this.$route.query.result
    }
    this.getUserManage()
    this.sellerList()
  },
  mounted() {
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  activated () {
  },
  methods: {
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.addData.startTime = ''
        this.addData.endTime = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.addData.startTime = getYear + '-' + nowMonth + '-' + getDate + ' ' + '00:00:00'
          } else {
            this.addData.endTime = getYear + '-' + nowMonth + '-' + getDate + ' ' + '00:00:00'
          }
        })
      }
    },
    async sellerFn(value) {
      let types = ''
      this.sellList.forEach(item => {
        if (item.id === value) {
          types = item.type
        }
      })
      if (types === 0) {
        // 线上联系人
        const { data: res } = await offineSupplyOlineApi(value)
        this.addData.linkUserId = ''
        this.addData.dealStatus = 0
        this.userList = res.data
      } else {
        // 线下联系人
        const { data: res } = await offineSellerDetailApi(value)
        this.userList = []
        this.addData.linkUser = res.data.linkUser
        this.addData.dealStatus = 1
        this.addData.linkUserId = res.data.id
      }
    },
    // 详情列表
    async getUserManage() {
      const { data: res } = await offineAgreementDetailApi(this.addData.id)
      if (res.data.dealStatus === 0) {
        this.addData = res.data
        const { data: res2 } = await offineSupplyOlineApi(res.data.sellerId)
        this.userList = res2.data
      } else {
        this.addData = res.data
        this.addData.linkUser = res.data.linkUserName
      }
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await offineSupplyDetailApi()
      this.sellList = res.data
    },
    // 打开添加弹框
    openAddDialog() {
      this.getMatterManageList()
      this.chooseOrderDialog = true
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    },
    // 获取产品列表
    async getMatterManageList() {
      const { data: res } = await matterListApi(this.matterManageData)
      if (res.code !== 0) return this.$message.error(res.msg)
      let newMatterManageList = res.data.page.list
      // // 去除列表重复项
      if (this.addData.dealLineEntityList.legnth !== 0) {
        this.addData.dealLineEntityList.forEach(item => {
          newMatterManageList.forEach((val, index) => {
            if (item.productId === val.id) {
              newMatterManageList.splice(index, 1)
            }
          })
        })
      }
      this.matterManageList = newMatterManageList
    },
    formatNum2(val, id) {
      let temp = val.toString()
      temp = temp.replace(/。/g, '.')
      temp = temp.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, '') // 验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, '') // 只保留第一个, 清除多余的
      temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // eslint-disable-next-line no-useless-escape
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
      console.log(id)// 只能输入两个小数
      this.addData.dealLineEntityList.forEach((item, index) => {
        if (item.productId === id) {
          this.addData.dealLineEntityList[index].price = temp
        }
      })
    },
    // 物料选择
    chooseMatter() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      this.matterList.forEach((item, index) => {
        let newData = {
          code: item.code,
          productId: item.id,
          productName: item.name,
          productSpecs: item.specs,
          productNewColour: item.newColour,
          unit: item.unit,
          parentTypeName: item.parentTypeName,
          price: '',
          arriveTime: '',
          sellId: ''
        }
        this.addData.dealLineEntityList.push(newData)
      })
      this.chooseOrderDialog = false
    },
    // 删除产品
    delMatter(ind) {
      if (ind === 'all') {
        if (this.chooseList.length === 0) return this.$message.error('请先勾选需要删除的产品')
        this.$confirm('是否删除勾选产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          console.log(this.chooseList, '123456')
          this.chooseList.forEach(i => {
            this.addData.dealLineEntityList.forEach((val, index) => {
              if (i === index) {
                this.addData.dealLineEntityList.splice(index, 1)
              }
            })
          })
          // 清空选中项
          this.chooseList = []
        })
      } else {
        this.$confirm('是否确认删除当前产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.addData.dealLineEntityList.splice(ind, 1)
        })
      }
    },
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 新增价格协议
    addPurchasePlan() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.addData.id === '' || undefined) {
            if (this.addData.dealLineEntityList.length === 0) return this.$message.error('清单明细不能为空!')
            this.addData.startTime = this.addData.startTime + ' ' + '00:00:00'
            this.addData.endTime = this.addData.endTime + ' ' + '23:59:59'
            const { data: res } = await addOffineAgreementApi(this.addData)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('新增价格协议成功!')
            setTimeout(() => {
              this.$router.back()
            }, 300)
          } else {
            if (this.addData.dealLineEntityList.length === 0) return this.$message.error('清单明细不能为空!')
            const { data: res } = await addOffineAgreementApi(this.addData)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('修改价格协议成功!')
            setTimeout(() => {
              this.$router.back()
            }, 300)
          }
        } else {
          return false
        }
      })
    },
    // -----------------------------------------------
    // 清空列表数据
    clearValue() {
      if (this.addData.dealLineEntityList.length !== 0) {
        this.$confirm('变更供应商将清空已添加的计划明细?', '变更确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          // 清空选中项
          this.addData.dealLineEntityList = []
        })
      }
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    }
  }
}
</script>
<style scoped lang='less'>
.addPurchasePlan {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addPurchasePlanContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 180px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 2px;
      padding: 20px;
      height: 166px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .navBox {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 33.3%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 117px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
          }
        }
      }
    }
    main {
      position: relative;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              color: rgba(0, 0, 0, 0.60);
            }
            >p {
              height: 24px;
              width: 100%;
              font-size: 14px;
              >span {
                margin-left: 30%;
                display: inline-block;
                width: 220px;
                color: rgba(0, 0, 0, 0.60);
              }
              i {
                float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 33.3%;
              .el-form-item__content {
                width: calc(100% - 117px);
                .el-input {
                  width: 100%;
                }
                .el-select {
                  width: 100%;
                  /deep/ .el-input {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
