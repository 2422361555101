// 价格协议详情
<template>
  <div class="entryDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Priceagreement' }">价格协议</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="entryDetailContent">
      <header>
        <div>
          <h5 >价格协议名称: {{ entryDetail.name }}</h5>
          <!-- <h5 v-else>价格协议单号: {{entryDetail.code}}<i class="otherBox">自选价格协议</i></h5> -->
          <p>创建人: <span>{{entryDetail.createByUser}}</span>| 创建时间:  <span>{{entryDetail.createTime}}</span></p>
        </div>
        <div>
          <p>类型: <span>固定价格协议</span>| 协议有效期:  <span>{{entryDetail.startTime.substring(0, 10)}}- {{ entryDetail.endTime.substring(0, 10) }}</span>| 联系人:  <span>{{entryDetail.linkUserName}}</span>| 联系电话:  <span>{{entryDetail.linkPhone}}</span>| 供应商名称:  <span>{{entryDetail.companyName}}</span></p>
        </div>
      </header>
      <main>
        <h6>产品配置明细</h6>
        <el-table ref="tableHeight" :data="entryDetail.dealLineEntityList" style="width: 100%;">
        <el-table-column key="1"  show-overflow-tooltip prop="productNo" label="产品编号" min-width="80px" align="center"></el-table-column>
        <el-table-column key="2" show-overflow-tooltip prop="productName" label="产品名称" min-width="130px" align="center"></el-table-column>
        <el-table-column key="3" show-overflow-tooltip prop="productSpec" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productSpec === '' || scope.row.productSpec === null ? '暂未填写' : scope.row.productSpec}}</span>
          </template>
        </el-table-column>
        <el-table-column key="4" show-overflow-tooltip prop="productColor" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productColor === '' || scope.row.productColor === null ? '暂未填写' : scope.row.productColor}}</span>
          </template>
        </el-table-column>
        <el-table-column key="6" show-overflow-tooltip prop="productUnit" label="单位" min-width="130px" align="center"></el-table-column>
        <el-table-column key="7" show-overflow-tooltip prop="price" label="协议单价" min-width="130px" align="center"></el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      </main>
    </div>
  </div>
</template>
<script>
import { offineAgreementDetailApi } from '@/api'
export default {
  data() {
    return {
      id: '',
      // 获取采购单价格协议详情
      entryDetail: {
        warehouseWarrantLineVoList: []
      },
      // 判断是采购价格协议 1还是自选价格协议 2
      entryType: ''
    }
  },
  created() {
    this.id = this.$route.query.result
    this.getEntryDetail()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 获取价格协议单详情
    async getEntryDetail() {
      const { data: res } = await offineAgreementDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.entryDetail = res.data
    },
    routerJump(val) {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.entryDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .entryDetailContent {
    margin-top: 40px;
    header {
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          i {
            margin-left: 20px;
            display: inline-block;
            width: 52px;
            height: 22px;
            background: rgba(70,147,235,0.05);
            border-radius: 4px;
            border: 1px solid rgba(70,147,235,0.2);
            font-size: 12px;
            font-weight: 400;
            color: rgba(70, 147, 235, 1);
            text-align: center;
            line-height: 22px;
          }
          .otherBox {
            background: rgba(59,212,144,0.05);
            border: 1px solid rgba(59,212,144,0.2);
            color: rgba(59, 212, 144, 1)
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    main {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
  }
}
</style>
