import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import VueCookies from "vue-cookies";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/global.css";
import "./api/index";
import "./assets/fonts/iconfont.css";
// 导入富文本
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 导入时间格式
import moment from "moment";
// 导入滑块组件
import SlideVerify from "vue-monoplasty-slide-verify";
// 导入el-dialog拖拽功能
import "./directives.js";
// 防多次点击，重复提交
import preventReClick from "./preventReClick.js";
// 兼容360兼容模式
// import 'babel-polyfill'
// 导入思源黑体字体
import "./assets/font/font.css";
// 导入打印转换
import htmlToPdf from "./utils/pdf";
import { im } from "mathjs/lib/entry/pureFunctionsAny.generated";
// 添加自定义滚动事件
Vue.directive('tableScroll', {
  bind (el, binding) {
    // el-table 内容滚动区域
      const bodyWrap = el.querySelector('.el-table__body-wrapper')
      bodyWrap.addEventListener('scroll', function () {
          let sign = 0
          // 滚动到底部
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
          if (scrollDistance <= sign) {
              binding.value()
          }
      })
  }
})
// 导入vuex
Vue.use(htmlToPdf);
Vue.use(preventReClick);
Vue.use(SlideVerify);
Vue.prototype.$moment = moment;
Vue.use(VueCookies);
Vue.use(VueQuillEditor);
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
