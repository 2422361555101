// 仓储管理 仓库资料 初始化仓库
<template>
  <div class="initializeStoreHouse">
    <!-- 仓库列表 -->
    <main>
      <nav>
        <el-button class="lightDeepStyle" @click="initializeStoreHouse()"><i class="iconfont icon-chushihuakucun"></i>初始化库存</el-button>
        <!-- http://api.z-srm.com/ -->
        <!-- http://apisrm.bmxgj.cn/ -->
        <el-upload
          class="purchaseUpload"
          ref="purchaseUpload"
          :action="interAddrss + 'sys/warehouse/basics/import-product'"
          multiple
          :limit="1"
          :headers="uploadHeaders"
          :show-file-list="true"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          accept=".xlsx"
          :on-exceed="handleExceed">
          <el-button class="defaultStyle"><i class="iconfont icon-daoru"></i>导 入</el-button>
        </el-upload>
        <el-button class="defaultStyle" @click="initializeStoreHouseExport()"><i class="iconfont icon-upload2"></i>导 出</el-button>
        <el-button class="defaultStyle" @click="$router.push('/storeHouseData')"><i class="iconfont icon-fanhui"></i>返 回</el-button>
      </nav>
      <el-table :height="tableHeight" :data="matterManageList" style="width: 100%">
        <el-table-column show-overflow-tooltip prop="code" label="产品编码" min-width="80px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="specs" label="规格/型号" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="unit" label="单位" min-width="120px" align="center"></el-table-column>
        <el-table-column label="初始数量" width="180px"  align="center">
          <template slot-scope="scope">
            <div @click="clickNum(scope.$index, scope.row)">
              <span v-if="controlInitialId !== scope.$index" ><a>{{parseFloat(scope.row.initialNum)}}</a></span>
              <el-input @focus="getInputFocus($event)" v-else v-model="controlInitialNum" ref="editNumRef" size="mini" @blur="editIntializeNum(scope.$indx, scope.row, $event.target.value)" @keyup.native.enter="$event.target.blur" oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"></el-input>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </main>
    <!-- 批量导入弹出框 -->
    <el-dialog title="导入数据" :visible.sync="dialogUpload" class="upload_box" @close="closeDialog" :close-on-click-modal="false" v-dialogDrag>
      <el-table :data="uploadData" border>
        <el-table-column  property="message" label="校验是否存在问题" width="200" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.message !== ''">{{scope.row.message}}</span>
              <span v-else style="color: green">单行校验通过</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip property="num" label="物料编号" min-width="140px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip property="describes" label="物料描述" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip property="specs" label="规格/型号" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip property="unit" min-width="120px" label="单位" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip property="initialNum" min-width="160px" label="初始数量" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="initialStoreHouseUploadDefine()">确认导入</el-button>
        <el-button @click="cancelUpload()">取消导入</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { matterListApi, initializeStoreHouseApi, initializeStoreHouseExportApi } from '@/api'
export default {
  name: 'initializeStoreHouse',
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem('interAddrss'),
      // 初始化仓库列表
      matterManageList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 7,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      storeHouseListData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 6,
        status: "1",
        typeId: "",
        name: "",
        productType: "",
        codeAndName: ""
      },
      companyId: window.$cookies.get('companyId'),
      // 仓库列表传过来的参数
      storeHouseInfo: JSON.parse(this.$route.query.result),
      // 控制是否弹出初始数量输入框
      controlInitialNum: '0',
      // 控制是否弹出初始Id
      controlInitialId: '',
      // 初始化库存仓库参数
      initializeStoreHouseData: {
        warehouseId: JSON.parse(this.$route.query.result).id,
        productId: '',
        number: ''
      },
      // 上传时的请求头
      uploadHeaders: {
        // token: window.sessionStorage.getItem('token')
        token: window.$cookies.get('token')
      },
      // 控制批量导入弹出框
      dialogUpload: false,
      // 展示导入内容数据
      uploadData: [],
      // 物料分类搜索
      search: '',
      // 判断校验是否正确
      judge: false
    }
  },
  created() {
    this.getMatterManageList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - 180
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 180
      })()
    }
  },
  methods: {
    // 输入框获得焦点时全选
    getInputFocus(event) {
      event.currentTarget.select()
    },
    // 点击可修改数量触发事件
    clickNum(index, result) {
      this.controlInitialId = index
      this.controlInitialNum = result.initialNum
      this.$nextTick(function () {
        this.$refs.editNumRef.focus()
      })
    },
    // 获取物料管理列表
    async getMatterManageList() {
      const { data: res } = await matterListApi(this.storeHouseListData)
      // 获取表单数据
      this.matterManageList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
      this.matterManageList.forEach(item => {
        item.initialNum = 0
      })
    },
    // 修改初始参数
    editIntializeNum(index, result, e) {
      this.controlInitialNum = e
      this.matterManageList.forEach(item => {
        if (item.code === result.code) {
          if (this.controlInitialNum === '') {
            this.controlInitialNum = 0
          }
          item.initialNum = this.controlInitialNum
          console.log(item.num, result.num)
          console.log(item.initialNum, this.controlInitialNum)
        }
      })
      this.controlInitialId = ''
    },
    // 初始化库存
    initializeStoreHouse() {
      this.$confirm('确定初始化' + this.storeHouseInfo.name, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        let newInitializeStoreHouseData = []
        this.matterManageList.forEach(item => {
          if (item.initialNum !== 0) {
            newInitializeStoreHouseData.push({ productId: item.id, number: item.initialNum, warehouseId: this.initializeStoreHouseData.warehouseId })
          }
        })
        const { data: res } = await initializeStoreHouseApi({ list: newInitializeStoreHouseData, warehouseId: this.initializeStoreHouseData.warehouseId })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: this.storeHouseInfo.name + '初始化成功!' })
        this.$router.push('/storeHouseData')
      }).catch(() => {
      })
    },
    // 初始化库存导出
    async initializeStoreHouseExport() {
      const { data: res } = await initializeStoreHouseExportApi(this.matterManageList)
      if (res.code !== 0) return this.$message.error(res.msg)
    },
    // 批量导入成功触发
    uploadSuccess(res, file) {
      this.judge = res.data.success
      this.dialogUpload = true
      this.uploadData = res.data.data
      this.$refs.purchaseUpload.clearFiles()
    },
    // 批量导入失败触发
    uploadError(err) {
      this.$message.error(err)
    },
    // 导入文件格式校验
    handleExceed() {
      this.$message.error('只能导入单个文件!')
    },
    // 取消导入
    cancelUpload() {
      this.dialogUpload = false
    },
    closeDialog() {
      this.$refs.purchaseUpload.clearFiles()
    },
    // 导入保存
    initialStoreHouseUploadDefine () {
      if (this.judge === false) return this.$message.error('单行校验不通过')
      this.dialogUpload = false
      this.matterManageList = this.uploadData
      this.$message.success({ duration: 1000, message: '导入成功' })
    }
  }
}
</script>
<style scoped lang='less'>
.initializeStoreHouse {
  padding: 20px;
  main {
    nav {
      float: right;
      position: relative;
      box-sizing: border-box;
      margin-bottom: 10px;
      padding: 0 3px;
      height: 36px;
      background: #fff;
      overflow: hidden;
      .el-button {
        margin: 5px 5px;
        padding: 0 10px;
        height: 26px;
        text-align: center;
        line-height: 0;
        i {
          margin: 0 7px 0 0;
          font-size: 14px;
        }
      }
      .query {
        position: absolute;
        right: 18px;
      }
      .query2 {
        position: absolute;
        right: 100px;
      }
      div {
        position: relative;
        display: inline-block;
        height: 34px;
        span {
          display: inline-block;
          margin-left: 3px;
          margin-right: 3px;
          width: 85px;
          height: 34px;
          line-height: 34px;
          text-align: center;
        }
        .el-input {
          display: inline-block;
          height: 34px;
          width: 149px;
        }
      }
    }
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    background: #fff;
    border-radius: 3px;
    box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    .el-table {
      margin-top: 0;
      span {
        display: inline-block;
        margin: 0 5px;
        width: 150px;
        cursor: pointer;
        a {
          margin: 0 3px;
          color: #123E83;
        }
      }
    }
    .el-pagination {
      float: right;
      margin: 10px;
    }
    article {
      margin-top: 70px;
      width: 100%;
      p {
        margin-bottom: -1px;
        padding: 6px 20px;
        border-bottom: 1px solid #EBEEF5;
        font-size: 16px;
      }
    }
  }
  /deep/ .upload_box .el-dialog {
    width: 1100px;
    height: 600px;
    .el-dialog__body {
      height: 460px;
    }
    .el-dialog__footer .el-button {
      text-align: center;
      width: 100px;
    }
  }
}
</style>
