// 供应商管理(待我确认列表)
<template>
  <div class="sellerManage">
    <nav>
      <h5>
        <span @click="checkTab(1)">供应商管理</span>
        <span @click="checkTab(2)" class="choooseTab">待确认供应商({{total}})</span>
      </h5>
      <div>
        <el-button class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>邀请供应商</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model="sellerManageData.search" placeholder="供应商名称" @input="getsellerManageList()" prefix-icon="el-icon-search"></el-input>
      <el-cascader ref="catalogue" :options="industryList" :props="defaultData" clearable v-model="sellerManageData.industry" @change="industryChange" size="mini" filterable placeholder="行业名称"></el-cascader>
    </section>
    <main>
      <el-table ref="tableHeight" row-key="id" :height="tableHeight" :data="sellerManageList" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="name" label="供应商名称" min-width="200px" align="center">
          <template slot-scope="scope">
            <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="industryName" label="行业名称" min-width="200px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.industryName == null ? '暂无' : scope.row.industryName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="申请时间" min-width="120px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div class="controlBox">
              <p @click="chooseCommand('1', scope.row)"><i class="iconfont icon-shoudongwancheng"></i><span>同意</span></p>
              <p @click="chooseCommand('2', scope.row)"><i class="el-icon-delete"></i><span>删除</span></p>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="sellerManageData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import { waitMySellerListApi, delSellerApi, industryLevelListApi, editSellerStatusApi } from '@/api'
export default {
  name: 'sellerManage',
  data() {
    return {      
      //所属行业
      industryList: [],    
      // 级联选择器分类
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'sysIndustryEntityList',
        expandTrigger: 'hover',
        checkStrictly: true
      },              
      // 列表动态高度
      tableHeight: '',
      // 供应商列表
      sellerManageList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: '',
      // 传参参数
      sellerManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        search: '',
        roleType: 1,
        industry: ''
      },
      chooseTime: ''
    }
  },
  created() {
  },
  mounted() {
    this.getsellerManageList()
    this.getIndustryList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 选择所属行业
    industryChange(e) {
      this.$refs.catalogue.dropDownVisible = false
      this.getsellerManageList()
    },
    // 获取行业表信息
    async getIndustryList() {
      const { data: res } = await industryLevelListApi('')
      if (res.code !== 0) return this.$message.error("获取行业类型失败!")
      this.industryList = res.data.list
    },
    // 切换标签页
    checkTab(val) {
      if (val === 1) {
        this.$router.replace('./supplierManage')
      }
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.editSellerStatus(result.id)
      } else {
        this.delSeller(result.id)
      }
    },
    // 同意成为供应商
    editSellerStatus(id) {
      this.$confirm("是否确认同意成为供应商", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await editSellerStatusApi({ id: id, roleType: 1, status: 3 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('加入成功!')
        this.getsellerManageList()
      })
    },
    // 供应商删除
    delSeller(id) {
      this.$confirm("是否确认删除该供应商", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await delSellerApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getsellerManageList()
      })
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: "/beforeAddSupplier" })
      } else if (val === 2) {
        this.$router.push({ path: "/supplierDetail", query: { result: JSON.stringify(result) }})
      } else {
        this.$router.push({ path: "/editSupplier", query: { result: JSON.stringify(result) }})
      }
    },
    // 获取供应商列表(待我确认)
    async getsellerManageList(reset) {
      if (reset !== 'reset') {
        this.sellerManageData.page = 1
      }
      const { data: res } = await waitMySellerListApi(this.sellerManageData)
      // 获取表单数据
      this.sellerManageList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.sellerManageData.size = val
      this.sellerManageData.page = 1
      this.getsellerManageList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.sellerManageData.page = val
      this.currentPage = val
      this.getsellerManageList('reset')
    }
  }
}
</script>
<style scoped lang='less'>
.sellerManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
      span {
        margin-right: 20px;
        padding: 5px 0;
        display: inline-block;
        cursor: pointer;
      }
      .choooseTab {
        border-bottom: 3px solid rgba(18, 62, 131, 1);
      }
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
    .controlBox {
      display: flex;
      justify-content: space-around;
      min-width: 170px;
      p {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.80);
        i {
          margin-right: 3px;
          font-size: 14px;
        }
      }
    }
    .sellTypeBox1, .sellTypeBox2, .sellTypeBox3, .sellTypeBox4 {
      display: inline-block;
      width: 80px;
      height: 22px;
      border-radius: 4px;
      background-color: rgba(70, 147, 235, 0.05);
      border: 1px solid rgba(70, 147, 235, 0.2);
      color: rgba(70, 147, 235, 1);
    }
    .sellTypeBox2 {
      background-color: rgba(59, 212, 144, 0.05);
      border: 1px solid rgba(59, 212, 144, 0.2);
      color: rgba(59, 212, 144, 1);
    }
    .sellTypeBox3 {
      background-color: rgba(239, 188, 96, 0.05);
      border: 1px solid rgba(239, 188, 96, 0.2);
      color: rgba(239, 188, 96, 1);
    }
    .sellTypeBox4 {
      background-color: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.20);
      color: rgba(0, 0, 0, 0.40);
    }
  }
}
</style>
