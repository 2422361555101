// 供应商详情
<template>
  <div class="supplierDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/supplierManage' }">供应商管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">供应商详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
      <el-button v-if="supplierDetail.status === 3" class="lightDeepStyle" @click="routerJump(2)"><i class="el-icon-edit-outline"></i>编辑</el-button>
    </div>
    <div class="supplierDetailContent">
      <header>
        <div>
          <h5>{{supplierDetail.name}}
            <i v-if="supplierDetail.type === 1" class="sellTypeBox1">优选供应商</i>
            <i v-else-if="supplierDetail.type === 2" class="sellTypeBox2">合格供应商</i>
            <i v-else-if="supplierDetail.type === 3" class="sellTypeBox3">待淘汰供应商</i>
            <i v-else class="sellTypeBox4">退出供应商</i>
          </h5>
        </div>
        <ul>
          <li class="textHidden">所属行业：<span>{{supplierDetail.industryName === '' ? '暂未填写' : supplierDetail.industryName}}</span></li>
          <li class="textHidden">供应商类型：<span>{{supplierDetail.type === 1 ? '优选供应商' : supplierDetail.type === 2 ? '合格供应商' : supplierDetail.type === 3 ? '待淘汰供应商' : '退出供应商'}}</span></li>
          <li class="textHidden">公司人数：<span>{{supplierDetail.staffNumber === '' || supplierDetail.staffNumber === null ? '暂未填写' : supplierDetail.staffNumber}}</span></li>
          <li class="textHidden">主要采购产品：<span>{{supplierDetail.product === '' ? '暂未填写' : supplierDetail.product}}</span></li>
          <li class="textHidden">税种：<span>{{supplierDetail.taxType === '' ? '暂未填写' : (supplierDetail.taxType === 1 ? '增值税普通发票' : '增值税专用发票')}}</span></li>
          <li class="textHidden">税率: <span>{{supplierDetail.taxRate === '' ? '暂未填写' : supplierDetail.taxRate + '%'}}</span></li>
          <li class="textHidden">年收入：<span>{{supplierDetail.turnover === 0 ? '暂未填写' : supplierDetail.turnover + '万元'}}</span></li>
          <li class="textHidden">开户行：<span>{{supplierDetail.bankName === '' ? '暂未填写' : supplierDetail.bankName}}</span></li>
          <li class="textHidden">开户账号：<span>{{supplierDetail.bank === '' ? '暂未填写' : supplierDetail.bank}}</span></li>
          <li class="textHidden">公司地址：<span>{{supplierDetail.provinceName === '' ? '暂未填写' : supplierDetail.provinceName + supplierDetail.cityName + supplierDetail.areaName}}</span></li>
          <li class="textHidden">详细地址：<span>{{supplierDetail.address === '' ? '暂未填写' : supplierDetail.address}}</span></li>
        </ul>
      </header>
      <footer>
        <h6>
          <span @click="choooseTab = 1" :class="choooseTab === 1 ? 'choooseTab' : ''">工商信息</span>
          <span @click="choooseTab = 2" :class="choooseTab === 2 ? 'choooseTab' : ''">企业联系人</span>
          <el-button v-if="choooseTab === 2" style="float: right;" class="lightDeepStyle" @click="openDialog()"><i class="el-icon-plus"></i>新增联系人</el-button>
        </h6>
        <ul v-if="choooseTab === 1">
          <li><p class="textHidden">统一信用代码: </p><span class="textHidden">{{industryInfo.regNumber === null ||  industryInfo.regNumber === undefined || industryInfo.regNumber === '' ? '暂无' : industryInfo.regNumber}}</span></li>
          <li><p class="textHidden">法定代表人: </p><span class="textHidden">{{industryInfo.legalPerson === null ||  industryInfo.legalPerson === undefined || industryInfo.legalPerson === '' ? '暂无' : industryInfo.legalPerson}}</span></li>
          <li><p class="textHidden">成立日期: </p><span class="textHidden">{{industryInfo.startDate === null ||  industryInfo.startDate === undefined || industryInfo.startDate === '' ? '暂无' : industryInfo.startDate}}</span></li>
          <li><p class="textHidden">经营状态: </p><span class="textHidden">{{industryInfo.manage === null ||  industryInfo.manage === undefined || industryInfo.manage === '' ? '暂无' : industryInfo.manage === 0 ? '正常' : industryInfo.manage === 1 ? '注销' : industryInfo.manage === 0 ? '停业' : '解散'}}</span></li>
          <li><p class="textHidden">注册资本: </p><span class="textHidden">{{industryInfo.capital === null ||  industryInfo.capital === undefined || industryInfo.capital === '' ? '暂无' : industryInfo.capital}}</span></li>
          <li><p class="textHidden">实缴资本: </p><span class="textHidden">{{industryInfo.recCap === null ||  industryInfo.recCap === undefined || industryInfo.recCap === '' ? '暂无' : industryInfo.recCap}}</span></li>
          <li><p class="textHidden">企业类型: </p><span class="textHidden">{{industryInfo.econKind === null ||  industryInfo.econKind === undefined || industryInfo.econKind === '' ? '暂无' : industryInfo.econKind}}</span></li>
          <li><p class="textHidden">所属行业: </p><span class="textHidden">{{industryInfo.industryName === null ||  industryInfo.industryName === undefined || industryInfo.industryName === '' ? '暂无' : ((industryInfo.firstIndustryName === null || industryInfo.firstIndustryName === undefined) ? industryInfo.industryName: industryInfo.firstIndustryName + '/' + industryInfo.industryName)}}</span></li>
          <li><p class="textHidden">纳税人识别号: </p><span class="textHidden">{{industryInfo.regNumber === null ||  industryInfo.regNumber === undefined || industryInfo.regNumber === '' ? '暂无' : industryInfo.regNumber}}</span></li>
          <li><p class="textHidden">组织机构代码: </p><span class="textHidden">{{industryInfo.orgNo === null ||  industryInfo.orgNo === undefined || industryInfo.orgNo === '' ? '暂无' : industryInfo.orgNo}}</span></li>
          <li><p class="textHidden">工商注册号: </p><span class="textHidden">{{industryInfo.businessNo === null ||  industryInfo.businessNo === undefined || industryInfo.businessNo === '' ? '暂无' : industryInfo.businessNo}}</span></li>
          <li><p class="textHidden">纳税人资质: </p><span class="textHidden">-</span></li>
          <li><p class="textHidden">营业期限: </p><span class="textHidden">{{industryInfo.termStart === null ||  industryInfo.termStart === undefined || industryInfo.termStart === '' ? '暂无' : industryInfo.termStart + '-' + industryInfo.termEnd}}</span></li>
            <li><p class="textHidden">行政区划: </p><span class="textHidden">{{industryInfo.cityName === null ||  industryInfo.cityName === undefined || industryInfo.cityName === '' ? '暂无' : industryInfo.cityName}}</span></li>
            <li><p class="textHidden">登记机关: </p><span class="textHidden">{{industryInfo.belongOrg === null ||  industryInfo.belongOrg === undefined || industryInfo.belongOrg === '' ? '暂无' : industryInfo.belongOrg}}</span></li>
            <li><p class="textHidden">参保人员: </p><span class="textHidden">{{industryInfo.insuredNumber === null ||  industryInfo.insuredNumber === undefined || industryInfo.insuredNumber === '' ? '暂无' : industryInfo.insuredNumber}}</span></li>
            <li style="width: 66.6%"><p class="textHidden">注册地址: </p><span class="textHidden">{{industryInfo.regAddress === null ||  industryInfo.regAddress === undefined || industryInfo.regAddress === '' ? '暂无' : industryInfo.regAddress}}</span></li>
            <li style="width: 100%"><p class="textHidden">经营范围: </p><span>{{industryInfo.businessScope === null ||  industryInfo.businessScope === undefined || industryInfo.businessScope === '' ? '暂无' : industryInfo.businessScope}}</span></li>
        </ul>
        <el-table v-if="choooseTab === 2" ref="tableHeight" max-height="241" :data="supplierDetail.contactList" row-key="index" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineList'}" :show-overflow-tooltip="true">
          <el-table-column show-overflow-tooltip prop="name" label="姓名" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="phone" label="联系电话" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.phone === null ? '暂未填写' : scope.row.phone}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="email" label="邮箱" min-wid th="80px"  align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.email === null ? '暂未填写' : scope.row.email}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="job" label="职位" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.job === null ? '暂未填写' : scope.row.job}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="联系地址" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.provinceName + scope.row.cityName + scope.row.areaName + scope.row.address}}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </footer>
      <!-- 新增联系人弹出框 -->
      <template>
        <div>
          <el-dialog class="changeDialog" title="新增联系人"  :visible.sync="dialogVisible" :close-on-click-modal="false" v-dialogDrag>
            <section>
              <!-- 新增分类表单 -->
              <el-form :model="contactData" :rules="rules" ref="ruleForm">
                <label>
                  <p><i style="color: red;">*</i>联系人:</p>
                  <el-form-item prop="name">
                    <el-input v-model="contactData.name" clearable placeholder="请输入联系人"></el-input>
                  </el-form-item>
                </label>
                <label>
                  <p><i style="color: red;">*</i>联系电话:</p>
                  <el-form-item prop="phone">
                    <el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="contactData.phone" placeholder="请输入联系电话"></el-input>
                  </el-form-item>
                </label>
                <label>
                  <p>邮箱:</p>
                  <el-form-item prop="email">
                    <el-input v-model="contactData.email" placeholder="请输入联系电话"></el-input>
                  </el-form-item>
                </label>
                <label>
                  <p>职位:</p>
                  <el-form-item prop="job">
                    <el-input v-model="contactData.job" placeholder="请输入职位名称"></el-input>
                  </el-form-item>
                </label>
                <label>
                  <p>地址:</p>
                  <el-form-item>
                    <el-cascader ref="cascaderRef" :options="provinceList" :props="defaultData" clearable v-model="contactData.provice" @change="handleChange($event)" size="mini"></el-cascader>
                  </el-form-item>
                </label>
                <label>
                  <p>详细地址:</p>
                  <el-form-item prop="address">
                    <el-input v-model="contactData.address" placeholder="请输入详细地址"></el-input>
                  </el-form-item>
                </label>
              </el-form>
            </section>
            <span slot="footer" class="dialog-footer">
              <el-button class="defaultStyle" @click="dialogVisible = false">取消</el-button>
              <el-button class="lightDeepStyle" @click="addContact()">确定</el-button>
            </span>
          </el-dialog>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { sellerDetailApi, delPrePaymentApi, getProvinceAllApi, editSellerApi, industryInfoApi } from '@/api'
export default {
  data() {
    return {
      // 工商信息
      industryInfo: '',
      // 默认tab
      choooseTab: 1,
      id: '',
      // 获取供应商详情
      supplierDetail: '',
      // 控制弹出框
      dialogVisible: false,
      // 联系人参数
      contactData: {
        name: "", //联系人
        phone: "", //联系电话
        email: "", //邮箱
        job: "", //职位
        address: "", //地址
        province: "",
        city: "",
        area: "",
        provinceName: "",
        cityName: "",
        areaName: ""
      },
      // 存储全部省市区列表
      provinceList: [],
      // 级联选择器分类
      defaultData: {
        value: 'code',
        label: 'name',
        children: 'newChildList',
        expandTrigger: 'hover',
      },
       // 校验
      rules: {
        name: [
          { required: true, message: '联系人不能为空!', trigger: 'blur' },
          { max: 20, message: '最多可输入20个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '联系电话不能为空!', trigger: 'blur' },
          { max: 15, message: '最多可输入15个字符', trigger: 'blur' }
        ],
        email: [
          { max: 50, message: '最多可输入50个字符', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              if (value !== '') {
                if (/^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(value) == false) {
                  callback(new Error("邮箱格式不正确!"));
                } else {
                  //校验通过
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: "blur"
          }
        ],
        job: [
          { max: 20, message: '最多可输入20个字符', trigger: 'blur' }
        ],
        address: [
          { max: 50, message: '最多可输入50个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.id = JSON.parse(this.$route.query.result)
    this.getSellerDetail()
    this.getProvince()
  },
  methods: {
    // 查询工商信息
    async getIndustryInfo() {
      const { data: res } = await industryInfoApi(this.supplierDetail.companyName)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.industryInfo  = res.data.detail
    },
    // 修改供应商
    addContact() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          this.supplierDetail.contactList.push(this.contactData)
          const { data: res } = await editSellerApi(this.supplierDetail)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '添加成功!' })
          this.dialogVisible = false
          this.getSellerDetail()
        } else {
          return false
        }
      })
    },
    // 打开弹框
    openDialog() {
      this.contactData = {
        name: "", //联系人
        phone: "", //联系电话
        email: "", //邮箱
        job: "", //职位
        address: "", //地址
        province: "",
        city: "",
        area: "",
        provinceName: "",
        cityName: "",
        areaName: ""
      }
      this.dialogVisible = true
    },
    // 级联选择器改变触发
    handleChange(e) {
      let addressName = this.$refs.cascaderRef.getCheckedNodes()[0].pathLabels
      this.contactData.province = e[0]
      this.contactData.city = e[1]
      this.contactData.area = e[2]
      this.contactData.provinceName = addressName[0]
      this.contactData.cityName = addressName[1]
      this.contactData.areaName = addressName[2]
    },
    // 获取省市区信息
    async getProvince() {
      const { data: res } = await getProvinceAllApi()
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.provinceList = res.data.list
      this.provinceList.forEach((item, index) => {
        if (item.childList.length !== 0) {
          item.newChildList = item.childList
          item.newChildList.forEach((items, index) => {
            if (items.childList.length !== 0) {
              items.newChildList = items.childList
            }
          })
        }
      })
    },
    // 获取供应商详情
    async getSellerDetail() {
      const { data: res } = await sellerDetailApi(this.id)
      if (res.code !== 0) return this.$message.error()
      this.supplierDetail = res.data.detail
      this.getIndustryInfo()
    },
    // 删除
    delBalanceAccount() {
      this.$confirm("是否确认撤回该供应商，将会释放相应数据？", "撤回重做", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await delBalanceAccountApi({ id: this.id, status: 3 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('撤回成功!')
        this.$router.back()
      })
    },
    routerJump(val) {
      if (val === 1) {
        this.$router.back()
      } else {
        this.$router.push({ path: "/editSupplier", query: { result: JSON.stringify(this.id) }})
      }
    }
  }
};
</script>
<style lang="less" scoped>
.supplierDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .supplierDetailContent {
    margin-top: 40px;
    header {
      margin: 20px 20px 0;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          .sellTypeBox1, .sellTypeBox2, .sellTypeBox3, .sellTypeBox4 {
            margin-left: 20px;
            display: inline-block;
            width: 80px;
            height: 22px;
            border-radius: 4px;
            background-color: rgba(70, 147, 235, 0.05);
            border: 1px solid rgba(70, 147, 235, 0.2);
            color: rgba(70, 147, 235, 1);
            font-size: 12px;
            text-align: center;
            line-height: 22px;
          }
          .sellTypeBox2 {
            background-color: rgba(59, 212, 144, 0.05);
            border: 1px solid rgba(59, 212, 144, 0.2);
            color: rgba(59, 212, 144, 1);
          }
          .sellTypeBox3 {
            background-color: rgba(239, 188, 96, 0.05);
            border: 1px solid rgba(239, 188, 96, 0.2);
            color: rgba(239, 188, 96, 1);
          }
          .sellTypeBox4 {
            background-color: rgba(0, 0, 0, 0.05);
            border: 1px solid rgba(0, 0, 0, 0.20);
            color: rgba(0, 0, 0, 0.40);
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        li {
          margin-top: 20px;
          width: 33.3%;
          color: rgba(0, 0, 0, 0.40);
          font-size: 14px;
          span {
            color: rgba(0, 0, 0, 0.80);
          }
        }
        li:nth-child(10) {
          width: 100%;
        }
      }
    }
    footer {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0 0 30px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        span {
          margin-right: 20px;
          padding: 5px 0;
          display: inline-block;
          cursor: pointer;
        }
        .choooseTab {
          border-bottom: 3px solid rgba(18, 62, 131, 1);
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        li {
          display: flex;
          width: 33.33%;
          min-height: 40px;
          color: rgba(0, 0, 0, 0.80);
          p {
            width: 120px;
            text-align: right;
          }
          span {
            padding: 0 10px;
            width: calc(100% - 120px);
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .changeDialog {
    /deep/ .el-dialog {
      height: 645px;
      width: 424px;
      .el-dialog__body {
        height: 525px;
        .el-input {
          width: 100%;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
}
</style>