// 打印模板(模型页面)
<template>
    <div class="printPreview">
      <!-- <div v-for="(item, index) in totalNum" v-bind:key="index" @click="getIndex(index)"> -->
        <article style="margin:0 auto;">
          <h1>
            <span>客户名称</span><br>
            <span style="font-size: 18px;">发货单</span>
          </h1>

          <ul>
            <li><span>客户公司: </span>{{invoiceDetailList.companyName}}</li>
            <li><span>制单人: </span>{{invoiceDetailList.userName}}</li>
            <li><span>制单时间: </span>{{invoiceDetailList.createTime}}</li>
            <li><span>发货单号: </span>{{invoiceDetailList.code}}</li>
          </ul>
          <p style="padding-left: 33px;">发货明细</p>
          <el-table :data="invoiceDetailList.lineEntityList" style="width: 100%">
            <el-table-column  type="index" label="订单行号" align="center" width="100px"></el-table-column>
            <el-table-column show-overflow-tooltip label="产品名称" min-width="120px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productName === null ? '暂未关联' : scope.row.productName}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="unitName" label="单位" min-width="140px"  align="center">
              <template slot-scope="scope">
                <span>{{scope.row.unitName === null || scope.row.unitName==='' ? '暂未关联' : scope.row.unitName}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="120px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productSpecs === '' || scope.row.productSpecs===null ? '暂未关联' : scope.row.productSpecs}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="发货数量" min-width="140px"  align="center">
              <template slot-scope="scope">
                <span>{{scope.row.number === null || scope.row.number==='' ? '暂未关联' : scope.row.number}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="residueNumber" label="剩余待发货数量" align="center" min-width="90px">
            </el-table-column>
          </el-table>
          <p class="last"><span>送货方式: </span>{{invoiceDetailList.deliveryType===0?'自提':'供方送货'}}<span>打印日期: </span>{{currentDate}} <span>收货地址：{{ invoiceDetailList.deliveryAddressName }}</span></p>
        </article>
      <!-- </div> -->
    </div>
</template>
<script>
import { deliverGoodsDetailApi } from '@/api'
export default {
  name: 'printDemo',
  data() {
    return {
      invoiceId: window.sessionStorage.getItem('invoiceId'),
      // 发货单数据
      invoiceDetail: {
        id: '',
        buyId: '',
        startTime: '',
        endTime: '',
        remark: '',
        transport: '',
        logistics: '',
        transportNum: '',
        productList: [],
        address: ''
      },
      // 发货单列表数据
      invoiceDetailList: [],
      // 当前发货单详情传参数据
      invoiceDetailListData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 1000,
        invoiceId: this.$route.query.id
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 7,
      // 总页数
      totalNum: 10,
      // 总条数
      total: 0,
      currentDate: ''
    }
  },
  created() {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    this.currentDate = `${year}-${month}-${day}`
    this.sellerList()
    // this.getInvoiceDetailList()
  },
  methods: {
    async sellerList() {
      const { data: res } = await deliverGoodsDetailApi({ id: this.$route.query.id })
      this.invoiceDetailList = res.data.data
      // this.list = res.data.data.lineEntityList
    }
  }
}
</script>
<style scoped lang='less'>
@page{
  size: auto A4 landscape;
  margin: 3mm;
}
.printPreview {
  article {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 0 3px;
    width: 1200px;
    min-height: 660px;
    background: #fff;
    border-radius: 3px;
    box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    >.el-button {
      margin: 9px 10px;
      padding: 0 10px;
      height: 32px;
      text-align: center;
      background-color: rgba(56,58,66,.8);
      line-height: 0;
      color: #fff;
      i {
        margin: 0 7px 0 0;
      }
    }
    h1 {
      margin: 70px 0 77px;
      width: 100%;
      height: 44px;
      font-size: 28px;
      text-align: center;
    }
    ul {
      display: flex;
      width: 100%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 10px;
      li {
      display: inline-block;
      margin-bottom: 10px;
      // width: 50%;
      span {
        margin: 0 20px;
        display: inline-block;
        width: 100px;
        text-align: center;
      }
    }
    }

    .el-table {
      padding: 10px;
      padding-top: 0;
      margin-top: 0;
    }
    /deep/ .el-table td,/deep/ .el-table th {
      padding: 5px 0!important;
    }
    .el-table::before {
      height: 0;
    }
    .last {
      position: absolute;
      bottom: 10px;
      padding: 10px;
      span {
        margin: 0 10px;
        display: inline-block;
        width: 100px;
        text-align: center;
      }
    }
  }
}
</style>
