import { render, staticRenderFns } from "./editPayment.vue?vue&type=template&id=700bffb0&scoped=true&"
import script from "./editPayment.vue?vue&type=script&lang=js&"
export * from "./editPayment.vue?vue&type=script&lang=js&"
import style0 from "./editPayment.vue?vue&type=style&index=0&id=700bffb0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700bffb0",
  null
  
)

export default component.exports