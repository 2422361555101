<template>
  <div class="indexContent">
    <div class="indexHeader" v-if="roleType == 1">
      <div class="header" @click="routerPush(0, 0, 329)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>采购订单</p>
            <h1>{{indexTitleData[3].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle1.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <p @click="routerPush(0, 1, 329)">今日+{{indexTitleData[3].dayCount}}</p><span @click="routerPush(0, 2, 329)">未完结: {{indexTitleData[3].unCount}}</span>
        </div>
      </div>
      <div class="header" @click="routerPush(1, 0, 328)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>计划采购</p>
            <h1>{{indexTitleData[2].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle2.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <p @click="routerPush(1, 1, 328)">今日+{{indexTitleData[2].dayCount}}</p><span @click="routerPush(1, 2, 328)">计划待采购: {{indexTitleData[2].unCount}}</span>
        </div>
      </div>
      <div class="header" @click="routerPush(2, 0, 366)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>询价采购</p>
            <h1>{{indexTitleData[0].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle3.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <p @click="routerPush(2, 1, 366)">今日+{{indexTitleData[0].dayCount}}</p><span @click="routerPush(2, 2, 366)">待采购: {{indexTitleData[0].unCount}}</span>
        </div>
      </div>
      <div class="header" @click="routerPush(3, 0, 367)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>竞价采购</p>
            <h1>{{indexTitleData[1].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle4.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <p @click="routerPush(3, 1, 367)">今日+{{indexTitleData[1].dayCount}}</p><span @click="routerPush(3, 2, 367)">待采购: {{indexTitleData[1].unCount}}</span>
        </div>
      </div>
      <div class="header" @click="routerPush(4, 0, 378)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>线下采购单</p>
            <h1>{{indexTitleData[4].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle5.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <p @click="routerPush(4, 1, 378)">今日+{{indexTitleData[4].dayCount}}</p>
        </div>
      </div>
    </div>
    <div class="indexHeader" v-if="roleType == 2">
      <div class="header" @click="routerPush(5, 0, 342)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>销售单</p>
            <h1>{{indexTitleData[2].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle1.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <span @click="routerPush(5, 1, 342)">进行中: {{indexTitleData[2].unCount}}</span>
        </div>
      </div>
      <div class="header" @click="routerPush(6, 0, 344)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>发货单</p>
            <h1>{{indexTitleData[3].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle2.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <span @click="routerPush(6, 1, 344)">待发货: {{indexTitleData[3].unCount}}</span>
        </div>
      </div>
      <div class="header" @click="routerPush(7, 0, 368)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>询价报价</p>
            <h1>{{indexTitleData[0].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle3.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <span @click="routerPush(7, 1, 368)">报价已通过: {{indexTitleData[0].unCount}}</span>
        </div>
      </div>
      <div class="header" @click="routerPush(8, 0, 369)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>竞价报价</p>
            <h1>{{indexTitleData[1].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle4.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <span @click="routerPush(8, 1, 369)">报价已通过: {{indexTitleData[1].unCount}}</span>
        </div>
      </div>
      <div class="header" @click="routerPush(9, 0, 385)">
        <div class="headerBox">
          <div class="headerLeft">
            <p>线下销售单</p>
            <h1>{{indexTitleData[4].allCount}}</h1>
          </div>
          <div>
            <img src="../assets/img/indexTitle5.png" alt />
          </div>
        </div>
        <div class="headerCount">
          <p @click="routerPush(9, 1, 385)">今日+{{indexTitleData[4].dayCount}}</p>
        </div>
      </div>
    </div>
    <section>
      <div class="myBox" v-if="roleType == 1">
        <div class="myBoxHeader">
          <h5>我的待办</h5>
          <span @click="switchMyBox(0)" :class="indexMyData.type === 0 ? 'chooseMy' : ''">全部({{indexMyDetail.allCount}})</span>
          <span @click="switchMyBox(1)" :class="indexMyData.type === 1 ? 'chooseMy' : ''">计划采购({{indexMyDetail.planCount}})</span>
          <span @click="switchMyBox(2)" :class="indexMyData.type === 2 ? 'chooseMy' : ''">采购({{indexMyDetail.orderCount}})</span>
          <span @click="switchMyBox(3)" :class="indexMyData.type === 3 ? 'chooseMy' : ''">待收货({{indexMyDetail.deliveryCount}})</span>
        </div>
        <div v-if="indexMyList.length !== 0" @scroll="handleScroll4" ref="myBoxRef" class="myBoxContent">
          <div v-for="item in indexMyList" :key='item.id'>
            <div @click="routerGoin(1, item.id)" v-if="item.type == 1" class="planBox">
              <div class="myHeader">
                <p>审核流程(采购计划单)</p><span>{{item.createTime}}</span>
              </div>
              <div class="myContent">
                <p>{{item.code}}<i>{{item.orderType}}</i></p>
                <p><span>供应商 : </span>{{item.companyName === null  ? '暂无'  :  item.companyName}}</p>
              </div>
              <div class="myFooter">
                <span>创建人 : {{item.createBy}}</span>
                <span>创建日期 : {{(item.createTime).substring(0, 10)}}</span>
              </div>
            </div>
            <div @click="routerGoin(2, item.id)" v-if="item.type == 2" class="orderBox">
              <div class="myHeader">
                <p>审核流程(采购单)</p><span>{{item.createTime}}</span>
              </div>
              <div class="myContent">
                <p>{{item.code}}<i>{{item.orderType}}</i></p>
                <p><span>供应商 : </span>{{item.companyName === null  ? '暂无'  :  item.companyName}}</p>
              </div>
              <div class="myFooter">
                <span>创建人 : {{item.createBy}}</span>
                <span>创建日期 : {{(item.createTime).substring(0, 10)}}</span>
              </div>
            </div>
            <div @click="routerGoin(3, item.id)" v-if="item.type == 3" class="receiptBox">
              <div class="myHeader">
                <p>待收货(收货单)</p><span>{{item.createTime}}</span>
              </div>
              <div class="myContent">
                <p>{{item.code}}<i>{{item.deliveryType}}</i></p>
              </div>
              <div class="myFooter">
                <span>供应商 : {{item.sellName === null  ? '暂无'  :  item.sellName}}</span>
              </div>
            </div>
          </div>
        </div>
        <div slot="empty" class="emptyBg" v-else>
          <img src="../assets/img/notData.png" style="width:15%; margin-top: 0;">
          <span style="display: block; margin-top: -1%; color: #ccc;">暂无数据</span>
        </div>
      </div>
      <div class="myBox" v-if="roleType == 2">
        <div class="myBoxHeader">
          <h5>我的待办</h5>
          <span @click="switchMyBox(0)" :class="indexMyData.type === 0 ? 'chooseMy' : ''">全部({{indexMyDetail.allCount}})</span>
          <span @click="switchMyBox(1)" :class="indexMyData.type === 1 ? 'chooseMy' : ''">销售单({{indexMyDetail.sellCount}})</span>
          <span @click="switchMyBox(2)" :class="indexMyData.type === 2 ? 'chooseMy' : ''">扣款({{indexMyDetail.kouCount}})</span>
          <span @click="switchMyBox(3)" :class="indexMyData.type === 3 ? 'chooseMy' : ''">收款({{indexMyDetail.shouCount}})</span>
          <span @click="switchMyBox(4)" :class="indexMyData.type === 4 ? 'chooseMy' : ''">对账({{indexMyDetail.duiCount}})</span>
          <span @click="switchMyBox(5)" :class="indexMyData.type === 5 ? 'chooseMy' : ''">预收款({{indexMyDetail.yskCount}})</span>
        </div>
        <div v-if="indexMyList.length !== 0" @scroll="handleScroll4" ref="myBoxRef" class="myBoxContent">
          <div v-for="item in indexMyList" :key='item.id'>
            <!-- @click="routerGoin(1, item.id)" -->
            <div class="mySellBox">
              <div class="myHeader">
                <p>{{item.type == 1 ? '销售单' : (item.type == 2 ? '扣款单' : (item.type == 3 ? '收款单' : (item.type == 4 ? '对账单' : '预收款单')))}}</p>
                <el-popover
                  placement="bottom"
                  width="155"
                  trigger="hover">
                  <div class="mySellerBtn">
                    <el-button @click="myWaitConfirm(true, item.type, item.id)" class="lightDeepStyle"><i style="font-size: 14px; margin-right: 5px;" class="iconfont icon-shoudongwancheng"></i>确认</el-button>
                    <el-button @click="myWaitConfirm(false, item.type, item.id)" class="defaultStyle"><i style="font-size: 14px; margin-right: 5px;" class="iconfont icon-quxiaoxinzeng"></i>取消</el-button>
                  </div>
                  <span slot="reference">
                    <i style="transform: rotate(90deg)" class="el-icon-more"></i>
                  </span>
                </el-popover>
              </div>
              <div class="myContent">
                <p>{{item.code}}</p>
              </div>
              <div class="myFooter">
                <span class="textHidden">{{item.companyName}}</span>
                <span>{{(item.createTime).substring(0, 10)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div slot="empty" class="emptyBg" v-else>
          <img src="../assets/img/notData.png" style="width:15%; margin-top: 0;">
          <span style="display: block; margin-top: -1%; color: #ccc;">暂无数据</span>
        </div>
      </div>
      <div class="orderMap">
        <div class="orderMapHeader">
          <h5>账单分析</h5>
        </div>
        <div class="orderMapContent">
          <div id="main"></div>
        </div>
        <div v-if="roleType == 1" class="orderMapFooter">
          <div class="mapFooterBox">
            <p>未付账</p>
            <span style="color: rgba(237, 86, 71, 1);">¥{{(this.indexDetail.zdDetail.noPayAmount).toFixed(2)}}</span>
          </div>
          <div class="mapFooterBox">
            <p>未对账</p>
            <span style="color: rgba(67, 136, 244, 1);">¥{{(this.indexDetail.zdDetail.unreconciled).toFixed(2)}}</span>
          </div>
          <div style="border-right: 0" class="mapFooterBox">
            <p>待付款</p>
            <span style="color: rgba(242, 166, 29, 1);">¥{{(this.indexDetail.zdDetail.payingAmount).toFixed(2)}}</span>
          </div>
        </div>
        <div v-if="roleType == 2" class="orderMapFooter">
          <div class="mapFooterBox">
            <p>未收款</p>
            <span style="color: rgba(237, 86, 71, 1);">¥{{(this.indexDetail.zdDetail.noPayAmount).toFixed(2)}}</span>
          </div>
          <div class="mapFooterBox">
            <p>未对账</p>
            <span style="color: rgba(67, 136, 244, 1);">¥{{(this.indexDetail.zdDetail.unreconciled).toFixed(2)}}</span>
          </div>
          <div style="border-right: 0" class="mapFooterBox">
            <p>待收款</p>
            <span style="color: rgba(242, 166, 29, 1);">¥{{(this.indexDetail.zdDetail.payingAmount).toFixed(2)}}</span>
          </div>
        </div>
      </div>
    </section>
    <article>
      <div class="unEndOrder" v-if="roleType == 1">
        <h5>未完结采购</h5>
        <el-table v-table-scroll="handleScroll3" ref="tableHeight3" row-key="id" :height="240" :data="purchaseOrderList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="code" label="采购单编码" min-width="140px" align="center">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(3, scope.row.id)">{{scope.row.code}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="sellName" label="供应商" min-width="120px"  align="center">
            <template slot-scope="scope">
              <span>{{scope.row.sellName}}</span>
            </template>
          </el-table-column>
          <el-table-column key="3" show-overflow-tooltip prop="deliveryType" label="发货进度" min-width="160px"  align="center" fixed="right">
            <template slot-scope="scope">
              <el-progress :width="24" :show-text="false" type="circle" :percentage="(scope.row.deliveryNumber / scope.row.number * 100)"></el-progress>
              <span style="margin: 0 10px;">{{(scope.row.deliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
              <span>{{scope.row.deliveryNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column key="4" show-overflow-tooltip prop="deliveryType" label="收货进度" min-width="160px"  align="center" fixed="right">
            <template slot-scope="scope">
              <el-progress color="#20D4CA" :width="24" :show-text="false" type="circle" :percentage="(scope.row.takeDeliveryNumber / scope.row.number * 100)"></el-progress>
              <span style="margin: 0 10px;">{{(scope.row.takeDeliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
              <span>{{scope.row.takeDeliveryNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column key="5" show-overflow-tooltip prop="state" label="状态" min-width="80px"  align="center" fixed="right">
            <template slot-scope="scope">
              <div>
                <!-- 0待发货 8部分发货 9待收货 10部分收货 -->
                <span class="stateBox3" v-if="scope.row.state === 0">采购中</span>
                <span class="stateBox3" v-if="scope.row.state === 8">采购中</span>
                <span class="stateBox3" v-if="scope.row.state === 9">采购中</span>
                <span class="stateBox3" v-if="scope.row.state === 10">采购中</span>
                <span class="stateBox1" v-if="scope.row.state === 7">待确认</span>
                <span class="stateBox2" v-if="scope.row.state === 1">已完结</span>
                <span class="stateBox2" v-if="scope.row.state === 2">已对账</span>
                <span class="stateBox3" v-if="scope.row.state === 3">已完结</span>
                <span class="stateBox3" v-if="scope.row.state === 11">已完结</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="7" show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
            <template slot-scope="scope">
              <span @click="routerJump(3, scope.row.id)" class="controlBox"><i class="el-icon-warning-outline"></i>查看</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="unEndOrder" v-if="roleType == 2">
        <h5>未完成销售</h5>
        <el-table v-table-scroll="handleScroll3" ref="tableHeight3" row-key="id" :height="240" :data="purchaseOrderList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="code" label="销售单编码" min-width="140px" align="center">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(3, scope.row.id)">{{scope.row.code}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="companyName" label="客户" min-width="120px"  align="center">
            <template slot-scope="scope">
              <span>{{scope.row.companyName}}</span>
            </template>
          </el-table-column>
          <el-table-column key="3" show-overflow-tooltip prop="deliveryType" label="发货进度" min-width="160px"  align="center" fixed="right">
            <template slot-scope="scope">
              <el-progress :width="24" :show-text="false" type="circle" :percentage="(scope.row.deliveryNumber / scope.row.number * 100)"></el-progress>
              <span style="margin: 0 10px;">{{(scope.row.deliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
              <span>{{scope.row.deliveryNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column key="4" show-overflow-tooltip prop="deliveryType" label="收货进度" min-width="160px"  align="center" fixed="right">
            <template slot-scope="scope">
              <el-progress color="#20D4CA" :width="24" :show-text="false" type="circle" :percentage="(scope.row.takeDeliveryNumber / scope.row.number * 100)"></el-progress>
              <span style="margin: 0 10px;">{{(scope.row.takeDeliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
              <span>{{scope.row.takeDeliveryNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column key="7" show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
            <template slot-scope="scope">
              <span @click="routerJump(3, scope.row.id)" class="controlBox"><i class="el-icon-warning-outline"></i>查看</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="sellerBox">
        <div class="sellerBoxHeader">
          <h5>{{roleType == 1  ? '供应商名单' : '客户名单'}}</h5>
          <el-pagination
            style="margin: 10px 0"
            layout="prev, pager, next"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            @size-change="handlesizeChange"
            :page-sizes="[50, 100, 500]"
            :page-size="purchaseOrderData.size"
            :total="total">
          </el-pagination>
        </div>
        <!-- list -->
        <div v-if="sellerList.length !== 0" class="sellerBoxContent">
          <div @click="indexSellerData(v.companyId)" class="itmes" v-for="(v, i) in sellerList" :key="i">
            <div>
              <p :class="{one:i==0,tow:i==1,three:i==2,all:i>2}">{{ i+1 }}</p>
              <p class="textHidden" style="cursor: pointer;">{{ v.companyName }}</p>
            </div>
            <p>¥{{ v.amount }}</p>
          </div>
        </div>
        <div v-else style="margin: 100px 140px; font-size: 14px; color: #ccc;">暂无数据</div>
      </div>
    </article>
    <footer>
      <main>
        <div class="unEndAskPrice" v-if="roleType == 1">
          <h5>未完结询价</h5>
          <el-table v-table-scroll="handleScroll" ref="tableHeight" :height="240" :data="askPriceList" style="width: 100%;">
            <el-table-column show-overflow-tooltip prop="name" label="询价标题" min-width="120px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column key="3" show-overflow-tooltip type="createTime" label="报价日期" width="200px"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.beginTime && scope.row.endTime">{{(scope.row.beginTime).substring(0, 10) + ' - ' + (scope.row.endTime).substring(0, 10)}}</span>
              </template>
            </el-table-column>
            <el-table-column key="5" show-overflow-tooltip prop="offerNumber" label="报价次数" min-width="80px"  align="center"></el-table-column>
            <el-table-column key="1" show-overflow-tooltip label="报价状态" min-width="100px"  align="center">
              <template slot-scope="scope">
                <span class="stateBox3" v-if="scope.row.offerState == 0">待开始</span>
                <span class="stateBox3" v-else-if="scope.row.offerState == 1">报价中</span>
                <span class="stateBox2" v-else-if="scope.row.offerState == 2">已确认</span>
                <span class="stateBox2" v-else-if="scope.row.offerState == 3">已审核</span>
                <span class="stateBox1" v-else-if="scope.row.offerState == 4">未确认</span>
                <span class="stateBox1" v-else-if="scope.row.offerState == 5">已过期</span>
              </template>
            </el-table-column>
            <el-table-column key="4" show-overflow-tooltip prop="countDown" label="倒计时" min-width="80px"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.countDown !== null && scope.row.countDown !== ''" class="stateBox4">{{scope.row.countDown}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
              <template slot-scope="scope">
                <span @click="routerJump(1, scope.row.id)" class="controlBox"><i class="el-icon-warning-outline"></i>查看</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="unEndAskPrice" v-if="roleType == 2">
          <h5>进行中询价</h5>
          <el-table v-table-scroll="handleScroll" ref="tableHeight" :height="240" :data="sellAskPriceList" style="width: 100%;">
            <el-table-column show-overflow-tooltip prop="name" label="询价标题" min-width="120px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column key="3" show-overflow-tooltip type="createTime" label="报价日期" width="200px"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.beginTime && scope.row.endTime">{{(scope.row.beginTime).substring(0, 10) + ' - ' + (scope.row.endTime).substring(0, 10)}}</span>
              </template>
            </el-table-column>
            <el-table-column key="1" show-overflow-tooltip label="报价状态" min-width="100px"  align="center">
              <template slot-scope="scope">
                <span class="stateBox3" v-if="scope.row.ofState == 1">待开始</span>
                <span class="stateBox3" v-else-if="scope.row.ofState == 2">待报价</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 3">已报价</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 4">报价已通过</span>
                <span class="stateBox1" v-else-if="scope.row.ofState == 5">报价未通过</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 6">已转单</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 7">已取消</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 8">已过期</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 9">已结束</span>
              </template>
            </el-table-column>
            <el-table-column key="4" show-overflow-tooltip prop="countDown" label="倒计时" min-width="80px"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.countDown !== null && scope.row.countDown !== ''" class="stateBox4">{{scope.row.countDown}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.ofState == 2" @click="routerJump(1, scope.row.id, 1)" class="controlBox"><i class="el-icon-warning-outline"></i>报价</span>
                <span v-else @click="routerJump(1, scope.row.id)" class="controlBox"><i class="el-icon-warning-outline"></i>查看</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="unEndCompetePrice" v-if="roleType == 1">
          <h5>未完结竞价</h5>
          <el-table v-table-scroll="handleScroll2" ref="tableHeight2" :height="240" :data="competePriceList" style="width: 100%;">
            <el-table-column show-overflow-tooltip prop="name" label="竞价标题" min-width="140px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column key="3" show-overflow-tooltip type="createTime" label="报价日期" width="200px"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.beginTime && scope.row.endTime">{{(scope.row.beginTime).substring(0, 10) + ' - ' + (scope.row.endTime).substring(0, 10)}}</span>
              </template>
            </el-table-column>
            <el-table-column key="5" show-overflow-tooltip prop="offerNumber" label="报价次数" min-width="80px"  align="center"></el-table-column>
            <el-table-column key="9" show-overflow-tooltip label="当前/总数" min-width="100px"  align="center">
              <template slot-scope="scope">
                <div>
                  <p><span style="color: rgba(18, 62, 131, 1)">第{{scope.row.nowRound}}轮</span><span style="color: rgba(0, 0, 0, 0.60)">/总{{scope.row.numberRound}}轮</span></p>
                </div>
              </template>
            </el-table-column>
            <el-table-column key="1" show-overflow-tooltip label="报价状态" min-width="100px"  align="center">
              <template slot-scope="scope">
                <span class="stateBox3" v-if="scope.row.offerState == 0">待开始</span>
                <span class="stateBox3" v-else-if="scope.row.offerState == 1">报价中</span>
                <span class="stateBox2" v-else-if="scope.row.offerState == 2">已确认</span>
                <span class="stateBox2" v-else-if="scope.row.offerState == 3">已审核</span>
                <span class="stateBox1" v-else-if="scope.row.offerState == 4">未确认</span>
                <span class="stateBox1" v-else-if="scope.row.offerState == 5">已过期</span>
              </template>
            </el-table-column>
            <el-table-column key="4" show-overflow-tooltip prop="countDown" label="倒计时" min-width="80px"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.countDown !== null && scope.row.countDown !== ''" class="stateBox4">{{scope.row.countDown}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
              <template slot-scope="scope">
                <span @click="routerJump(2, scope.row.id)" class="controlBox"><i class="el-icon-warning-outline"></i>查看</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="unEndCompetePrice" v-if="roleType == 2">
          <h5>进行中竞价</h5>
          <el-table v-table-scroll="handleScroll2" ref="tableHeight2" :height="240" :data="sellCompetePriceList" style="width: 100%;">
            <el-table-column show-overflow-tooltip prop="name" label="竞价标题" min-width="140px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column key="3" show-overflow-tooltip type="createTime" label="报价日期" width="200px"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.beginTime && scope.row.endTime">{{(scope.row.beginTime).substring(0, 10) + ' - ' + (scope.row.endTime).substring(0, 10)}}</span>
              </template>
            </el-table-column>
            <el-table-column key="9" show-overflow-tooltip label="进度" min-width="100px"  align="center">
              <template slot-scope="scope">
                <div>
                  <p><span style="color: rgba(18, 62, 131, 1)">第{{scope.row.nowRound}}轮</span><span style="color: rgba(0, 0, 0, 0.60)">/总{{scope.row.numberRound}}轮</span></p>
                </div>
              </template>
            </el-table-column>
            <el-table-column key="1" show-overflow-tooltip label="报价状态" min-width="100px"  align="center">
              <template slot-scope="scope">
                <span class="stateBox3" v-if="scope.row.ofState == 1">待开始</span>
                <span class="stateBox3" v-else-if="scope.row.ofState == 2">待报价</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 3">已报价</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 4">报价已通过</span>
                <span class="stateBox1" v-else-if="scope.row.ofState == 5">报价未通过</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 6">已转单</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 7">已取消</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 8">已过期</span>
                <span class="stateBox2" v-else-if="scope.row.ofState == 9">已结束</span>
              </template>
            </el-table-column>
            <el-table-column key="4" show-overflow-tooltip prop="countDown" label="倒计时" min-width="80px"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.countDown !== null && scope.row.countDown !== ''" class="stateBox4">{{scope.row.countDown}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
              <template slot-scope="scope">
                <span v-if="scope.row.ofState == 2" @click="routerJump(2, scope.row.id, 1)" class="controlBox"><i class="el-icon-warning-outline"></i>报价</span>
                <span v-else @click="routerJump(2, scope.row.id)" class="controlBox"><i class="el-icon-warning-outline"></i>查看</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </main>
      <aside>
        <div class="asideHeader" v-if="roleType == 1">
          <h5>产品采购分析</h5>
          <div class="showTitle">
            <p><i></i>采购数</p>
            <p><i></i>已收货</p>
            <p><i></i>退货数</p>
          </div>
          <div class="pickerBox">
            <el-date-picker
              v-model="chooseTime"
              @change="getTimes"
              type="daterange"
              range-separator=""
              size="mini"
              start-placeholder="日期区间"
              end-placeholder="日期区间"
              :default-time="['00:00:00', '23:59:59']"
              format="MM-DD"
            ></el-date-picker>
          </div>
        </div>
        <div class="asideHeader" v-if="roleType == 2">
          <h5>销售金额分析</h5>
          <div class="pickerBox">
            <el-date-picker size="mini" @change="getTimess" v-model="chooseTime" type="year" placeholder="年份选择" value-format="yyyy" :clearable="false"></el-date-picker>
          </div>
        </div>
        <div class="asideContent">
          <div v-if="indexDetail.order && indexDetail.order.length == 0 && roleType == 1" slot="empty" class="emptyBg">
            <span style="display: block; margin-top: 50%; color: #ccc;">暂无数据</span>
          </div>
          <div v-else id="main2"></div>
        </div>
      </aside>
    </footer>
  </div>
</template>
<script>
// echarts图
import * as echarts from "echarts";
import { indexMsgApi, indexSellerListApi, indexSellerDataApi, askPriceListApi, purchaseOrderListApi, indexTitleApi, sellAskPriceListApi, indexMyApi } from "@/api";
// 待办操作导入接口
import { editPurchaseOrderStateApi, sellConfirmChargeBackApi, sellCancelChargeBackApi, CollectionManagementComfireApi, receiptRevocationApi, selldelBalanceAccountApi, cancleBalanceAccountApi, sellConfirmPrePaymentApi } from "@/api"
function findMenu(menuArray, targetMenuId) {
    for (const item of menuArray) {
        if (item.menuId == targetMenuId) {
            return true;
        } else if (item.list && item.list.length > 0) {
            if (findMenu(item.list, targetMenuId)) {
                return true;
            }
        }
    }
    return false;
}
export default {
  props: ['updateMessage'],
  data() {
    return {
      indexMyPage: '',
      indexMyList: [],
      // 首页待办
      indexMyDetail: '',
      indexMyData: {
        page: 1,
        size: 6,
        type: 0
      },
      // 首页标题数据
      indexTitleData: '',
      // --------------------------------图表数据
      indexMsgData:{
        startTime: '',
        endTime: ''
      },
      // 首页图表数据
      indexDetail: '',
      chooseTime: [],
      // ------------------------------------------竞价报价数据
      sellCompetePricePage: '',
      // 列表
      sellCompetePriceList: [],
      // 传参参数
      sellCompetePriceData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        type: 2,
        name: '',
        startTime: '',
        endTime: '',
        state: '1,2,3,4'
      },
      // ------------------------------------------询价报价数据
      sellAskPricePage: '',
      // 列表
      sellAskPriceList: [],
      // 传参参数
      sellAskPriceData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        type: 1,
        name: '',
        startTime: '',
        endTime: '',
        state: '1,2,3,4'
      },
      // ------------------------------------------竞价采购数据
      // 竞价当前页
      competePricePage: '',
      // 询价列表
      competePriceList: [],
      // 传参参数
      competePriceData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 5,
        state: '1,2',
        codeAndName: '',
        type: 2
      },
      //-------------------------------------------询价采购数据
      // 询价当前页
      askPricePage: '',
      // 询价列表
      askPriceList: [],
      // 传参参数
      askPriceData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 5,
        state: '1,2',
        codeAndName: '',
        type: 1
      },
      // ------------------------------------------采购订单数据 销售订单数据
      orderPage: '',
      purchaseOrderList: [],
      // 传参参数
      purchaseOrderData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        productName: '',
        approveTimeBegin: '',
        approveTimeEnd: '',
        approveStatus: 2,
        state: '0,8,9,10',
        stateType: '',
        sellId: '',
        ifAwait: '',
        companyType: 1
      },
      // ----------------------------------
      // 供应商列表
      sellerList: [],
      sellerData: {
        page: 1,
        size: 6
      },
      // 默认显示页码
      currentPage: 1,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      orderList: {
        matterName: [],
        returnNumber: [],
        takeDeliveryNumber: [],
        orderNumber: []
      },
      priceList: {
        months: [],
        price: []
      },
      // 公司id,
      companyId: window.$cookies.get("companyId"),
      // ------------------------------------------------------啊啊啊阿巴巴
      roleType: '',
      // eharts数据
      myChart: {},
      myChart2: {},
      // 供应商名单
      list: [
        { id: 0, title: 1, name: "供应商1", amount: "11,211,790,25" },
        { id: 1, title: 2, name: "供应商2", amount: "11,211,790,25" },
        { id: 2, title: 3, name: "供应商3", amount: "11,211,790,25" },
        { id: 3, title: 4, name: "供应商4", amount: "11,211,790,25" },
        { id: 4, title: 5, name: "供应商5", amount: "11,211,790,25" },
        { id: 5, title: 6, name: "供应商6", amount: "11,211,790,25" },
        { id: 6, title: 7, name: "供应商7", amount: "11,211,790,25" },
        { id: 7, title: 8, name: "供应商8", amount: "11,211,790,25" },
        { id: 7, title: 8, name: "供应商8", amount: "11,211,790,25" },
        { id: 9, title: 10, name: "供应商10", amount: "11,211,790,25" }
      ],
      // 未读消息
      tableList: []
    };
  },
  created() {
  },
  mounted() {
    this.roleType = window.sessionStorage.getItem('roleType')
    this.getIndexMsg().then(() => {
      // eharts图标
      this.showCharts();
      this.barCharts();
    })
    this.indexTitle()
    this.getIndexMy()
    // 共用部分
    this.getIndexSellerList()
    if (this.roleType == 1) {
      // 采购部分
      this.getAskPriceList()
      this.getCompetePriceList()
      this.getpurchaseOrderList('', 1)
    } else {
      // 供应部分
      this.getSellAskPriceList()
      this.getSellCompetePriceList()
      this.getpurchaseOrderList('', 2)
    }
  },
  methods: {
    // 点击切换时间表(采购)
    getTimes(result) {
      if (result === null) {
        this.indexMsgData.startTime = ''
        this.indexMsgData.endTime = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.indexMsgData.startTime = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.indexMsgData.endTime = getYear + '-' + nowMonth + '-' + getDate + ' 23:59:59'
          }
        })
      }
      this.getIndexMsg().then(() => {
        // eharts图标
        this.barCharts();
      })
    },
    // 点击切换时间表(供应)
    getTimess(result) {
        console.log(result)
      this.indexMsgData.startTime = result + '-01-01 00:00:00'
      this.indexMsgData.endTime = result + '-12-31 23:59:59'
      this.getIndexMsg().then(() => {
        // eharts图标
        this.barCharts();
      })
    },
    // 待办操作
    myWaitConfirm(confirm, type, id, state) {
      if (confirm === true) {
        // 确认操作
        if (type == 1) {
          // 销售单
          this.$confirm("是否确认该销售单", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消"
          }).then(async () => {
            const { data: res } = await editPurchaseOrderStateApi({ id: id, state: 0, stateType: 0 })
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('确认成功!')
            this.getIndexMy()
          })
        }
        if (type == 2) {
          // 扣款单
          this.$confirm('是否确认该扣款单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(async() => {
            const { data: res } = await sellConfirmChargeBackApi(id)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '确认成功!' })
            this.getIndexMy()
          })
        }
        if (type == 3) {
          // 收款单
          this.$confirm('是否确认该收款单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(async() => {
            const { data: res } = await CollectionManagementComfireApi({ id: id })
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '确认成功!' })
            this.getIndexMy()
          })
        }
        if (type == 4) {
          // 对账单
          this.$confirm('是否确认该对账单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(async() => {
            const { data: res } = await selldelBalanceAccountApi({ id: id })
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '确认成功!' })
            this.getIndexMy()
          })
        }
        if (type == 5) {
          // 预收款单
          this.$confirm('是否确认该预收款单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(async() => {
            const { data: res } = await sellConfirmPrePaymentApi(id)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '确认成功!' })
            this.getIndexMy()
          })
        }
      } else {
        // 取消操作
        if (type ==  1) {
          // 销售单
          this.$confirm("是否取消确认该销售单", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消"
          }).then(async () => {
            const { data: res } = await editPurchaseOrderStateApi({ id: id, state: 6, stateType: 0 })
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('取消成功!')
            this.getIndexMy()
          })
        }
        if (type == 2) {
          // 扣款单
          this.$confirm('是否取消该扣款单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(async() => {
            const { data: res } = await sellCancelChargeBackApi(id)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '取消成功!' })
            this.getIndexMy()
          })
        }
        if (type == 3) {
          // 收款单
          this.$confirm('是否取消该收款单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(async() => {
            const { data: res } = await receiptRevocationApi({ id: id })
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '取消成功!' })
            this.getIndexMy()
          })
        }
        if (type == 4) {
          // 对账单
          console.log(id, 6666)
          this.$confirm('是否取消该对账单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(async() => {
            const { data: res } = await cancleBalanceAccountApi(id)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '取消成功!' })
            this.getIndexMy()
          })
        }
        if (type == 5) {
          // 预收款单
          this.$confirm('是否取消该预收款单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(async() => {
            const { data: res } = await sellCanclePrePaymentApi(id)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '取消成功!' })
            this.getIndexMy()
          })
        }
      }
    },
    // 待办跳转
    routerGoin(val, result) {
      if (val === 1) {
        this.$router.push({ path: '/purchasePlanDetail', query: { result: JSON.stringify(result) } })
      } else if (val === 2) {
        this.$router.push({ path: '/purchaseOrderDetail', query: { result: JSON.stringify(result) } })
      } else {
        this.$router.push({ path: '/receipCheckDetail', query: { id: JSON.stringify(result) } })
      }
    },
    // 我的待办滚动
    handleScroll4() {
      const tableBody = this.$refs.myBoxRef
      const scrollTop = tableBody.scrollTop;
      const scrollHeight = tableBody.scrollHeight;
      const clientHeight = tableBody.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (this.indexMyData.page < this.indexMyPage) {
          this.indexMyData.page = this.indexMyData.page + 1
          this.getIndexMy(true)
        }
      }
    },
    // 获取工作台我的待办
    async getIndexMy(pushOrder) {
      const { data: res } = await indexMyApi(this.indexMyData)
      if (res.code !== 0) return this.message.error(res.msg)
      this.indexMyDetail = res.data
      // 展会页面总页数
      this.indexMyPage = res.data.page.totalPage
      // 获取表单数据
      if (pushOrder === true) {
        res.data.page.list.forEach(item => {
          this.indexMyList.push(item)
        })
      } else {
        this.indexMyList = res.data.page.list
      }
      console.log(this.indexMyList.length)
    },
    // 切换待办
    switchMyBox(type) {
      this.indexMyData.type = type
      this.indexMyData.page = 1
      this.indexMyList = []
      this.getIndexMy()
    },
    // 获取工作台头部数据
    async indexTitle() {
      const { data: res } = await indexTitleApi()
      if (res.code !== 0) return this.message.error(res.msg)
      this.indexTitleData = res.data.list
    },
    // 路由跳转
    routerJump(val, result, quote) {
      if (val === 1) {
        if (this.roleType == 1) {
          this.$router.push({ path: '/askPricePurchaseDetail', query: { result: JSON.stringify(result) } })
        } else {
          if (quote === 1) {
            this.$router.push({ path: '/askPriceSellQuote', query: { result: JSON.stringify(result) } })
          } else {
            this.$router.push({ path: '/askPriceSellDetail', query: { result: JSON.stringify(result) } })
          }
        }
      } else if (val === 2) {
        if (this.roleType == 1) {
          this.$router.push({ path: '/competePricePurchaseDetail', query: { result: JSON.stringify(result) } })
        } else {
          if (quote === 1) {
            this.$router.push({ path: '/competePriceSellQuote', query: { result: JSON.stringify(result) } })
          } else {
            this.$router.push({ path: '/competePriceSellDetail', query: { result: JSON.stringify(result) } })
          }
        }
      } else if (val === 3) {
        if (this.roleType == 1) {
          this.$router.push({ path: '/purchaseOrderDetail', query: { result: JSON.stringify(result) } })
        } else {
          this.$router.push({ path: "/sellerOrderDetail", query: { result: JSON.stringify(result) }})
        }
      }
    },
    // 获取竞价报价列表
    async getSellCompetePriceList(pushOrder) {
      const { data: res } = await sellAskPriceListApi(this.sellCompetePriceData)
      // 展会页面总页数
      this.sellCompetePriceData = res.data.totalPage
      // 获取表单数据
      if (pushOrder === true) {
        res.data.list.forEach(item => {
          this.sellCompetePriceList.push(item)
        })
      } else {
        this.sellCompetePriceList = res.data.list
      }
    },
    // 获取询价报价列表
    async getSellAskPriceList(pushOrder) {
      const { data: res } = await sellAskPriceListApi(this.sellAskPriceData)
      // 展会页面总页数
      this.sellAskPricePage = res.data.totalPage
      // 获取表单数据
      if (pushOrder === true) {
        res.data.list.forEach(item => {
          this.sellAskPriceList.push(item)
        })
      } else {
        this.sellAskPriceList = res.data.list
      }
    },
    // -----------------------------------------------------------------------------------------
    // 获取采购单列表
    async getpurchaseOrderList(pushOrder, companyType) {
      if (companyType === 1) {
        this.purchaseOrderData.companyType = 1
      } else {
        this.purchaseOrderData.companyType = 2
      }
      const { data: res } = await purchaseOrderListApi(this.purchaseOrderData)
      // 页面总页数
      this.orderPage = res.data.data.totalPage
      // 获取表单数据
      if (pushOrder === true) {
        res.data.data.list.forEach(item => {
          this.purchaseOrderList.push(item)
        })
      } else {
        this.purchaseOrderList = res.data.data.list
      }
    },
    // 采购单滚动
    handleScroll3() {
      const tableBody = this.$refs.tableHeight3.$el.querySelector('.el-table__body-wrapper');
      const scrollTop = tableBody.scrollTop;
      const scrollHeight = tableBody.scrollHeight;
      const clientHeight = tableBody.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (this.purchaseOrderData.page < this.orderPage) {
          this.purchaseOrderData.page = this.purchaseOrderData.page + 1
          this.getpurchaseOrderList(true)
        }
      }
    },
    // 获取竞价列表
    async getCompetePriceList(pushOrder) {
      const { data: res } = await askPriceListApi(this.competePriceData)
      // 展会页面总页数
      this.competePricePage = res.data.data.totalPage
      // 获取表单数据
      if (pushOrder === true) {
          res.data.data.list.forEach(item => {
            this.competePriceList.push(item)
          })
      } else {
        this.competePriceList = res.data.data.list
      }
    },
    // 竞价滚动
    handleScroll2() {
      const tableBody = this.$refs.tableHeight2.$el.querySelector('.el-table__body-wrapper');
      const scrollTop = tableBody.scrollTop;
      const scrollHeight = tableBody.scrollHeight;
      const clientHeight = tableBody.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (this.roleType == 1) {
          if (this.competePriceData.page < this.competePricePage) {
            this.competePriceData.page = this.competePriceData.page + 1
            this.getCompetePriceList(true)
          }
        } else {
          if (this.sellCompetePriceData.page < this.sellCompetePriceData) {
            this.sellCompetePriceData.page = this.sellCompetePriceData.page + 1
            this.getSellCompetePriceList(true)
          }
        }
      }
    },
    // 询价滚动
    handleScroll() {
      const tableBody = this.$refs.tableHeight.$el.querySelector('.el-table__body-wrapper');
      const scrollTop = tableBody.scrollTop;
      const scrollHeight = tableBody.scrollHeight;
      const clientHeight = tableBody.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        // 滚动到底部，继续加载数据
        if (this.roleType == 1) {
          if (this.askPriceData.page < this.askPricePage) {
            this.askPriceData.page = this.askPriceData.page + 1
            this.getAskPriceList(true)
          }
        } else {
          if (this.sellAskPriceData.page < this.sellAskPricePage) {
            this.sellAskPriceData.page = this.sellAskPriceData.page + 1
            this.getSellAskPriceList(true)
          }
        }
      }
    },
    // 获取询价列表
    async getAskPriceList(pushOrder) {
      const { data: res } = await askPriceListApi(this.askPriceData)
      // 展会页面总页数
      this.askPricePage = res.data.data.totalPage
      // 获取表单数据
      if (pushOrder === true) {
        res.data.data.list.forEach(item => {
          this.askPriceList.push(item)
        })
      } else {
        this.askPriceList = res.data.data.list
      }
    },
    // 供应商列表
    async getIndexSellerList() {
      const { data: res } = await indexSellerListApi(this.sellerData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.sellerList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.sellerData.size = val
      this.sellerData.page = 1
      this.getIndexSellerList()
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.sellerData.page = val
      this.currentPage = val
      this.getIndexSellerList()
    },
    // 根据供应商获取数据
    async indexSellerData(sellerId) {
      const { data: res } = await indexSellerDataApi(sellerId)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.indexDetail.zdDetail.payingAmount = (res.data.detail.payingAmount == null ? 0 : res.data.detail.payingAmount)
      this.indexDetail.zdDetail.unreconciled = (res.data.detail.unreconciled == null ? 0 : res.data.detail.unreconciled)
      this.indexDetail.zdDetail.noPayAmount = (res.data.detail.noPayAmount == null ? 0 : res.data.detail.noPayAmount)
      this.showCharts()
    },
    // -----------------------------------------------------------
    routerPush(val, result, menuId) {
      const menuIdList = JSON.parse(window.sessionStorage.getItem('menuIdList'))
      const menuLimits = findMenu(menuIdList, menuId)
      if (menuLimits === false) return this.$message.error('暂无权限跳转至该模块!')
      if (val === 0) {
        window.sessionStorage.setItem('btnLimit', ["查看","新增采购单","编辑采购单","完结采购单","删除采购单","提交采购单","导出"])
        if (result === 0) {
          this.$router.push({ path: 'purchaseOrder' })
        } else if (result === 1) {
          this.$router.push({ path: 'purchaseOrder', query: { indexData: 'today' } })
        } else {
          this.$router.push({ path: 'purchaseOrder', query: { indexState: '0,8,9,10' } })
        }
      } else if (val === 1) {
        window.sessionStorage.setItem('btnLimit', ["查看","新增计划单","编辑计划单","删除计划单","转订单","导出"])
        if (result === 0) {
          this.$router.push({ path: 'purchasePlanManage' })
        } else if (result === 1) {
          this.$router.push({ path: 'purchasePlanManage', query: { indexData: 'today' } })
        } else {
          this.$router.push({ path: 'purchasePlanManage', query: { ifAwait: 1, approveStatus: 2, proposerId: JSON.parse(window.sessionStorage.getItem('userInfo')).userId } })
        }
      } else if (val === 2) {
        window.sessionStorage.setItem('btnLimit', ["查看订单","查看","发布询价","修改询价","删除询价","开始询价","结束询价","确认报价","审核报价","取消确认报价","转订单"])
        if (result === 0) {
          this.$router.push({ path: 'askPricePurchase' })
        } else if (result === 1) {
          this.$router.push({ path: 'askPricePurchase', query: { indexData: 'today' } })
        } else {
          this.$router.push({ path: 'askPricePurchase', query: { indexState: '3' } })
        }
      } else if (val === 3) {
        window.sessionStorage.setItem('btnLimit', ["查看订单","查看","发布竞价","修改竞价","删除竞价","开始竞价","结束竞价","确认报价","审核报价","取消确认报价","转订单"])
        if (result === 0) {
          this.$router.push({ path: 'competePricePurchase' })
        } else if (result === 1) {
          this.$router.push({ path: 'competePricePurchase', query: { indexData: 'today' } })
        } else {
          this.$router.push({ path: 'competePricePurchase', query: { indexState: '3' } })
        }
      } else if (val === 4) {
        window.sessionStorage.setItem('btnLimit', ["查看","新增","编辑","删除"])
        if (result === 0) {
          this.$router.push({ path: 'offinePurchasing' })
        } else if (result === 1) {
          this.$router.push({ path: 'offinePurchasing', query: { indexData: 'today' } })
        }
      } else if (val === 5) {
        window.sessionStorage.setItem('btnLimit', ["确认取消","删除","撤回","录入实时库存"])
        if (result === 0) {
          this.$router.push({ path: 'sellerOrder' })
        } else if (result === 1) {
          this.$router.push({ path: 'sellerOrder', query: { indexState: '8,9,10' } })
        }
      } else if (val === 6) {
        window.sessionStorage.setItem('btnLimit', ["新增发货单","新增补货单","打印","编辑发货单","删除发货单","发货","导出"])
        if (result === 0) {
          this.$router.push({ path: 'dispatchList' })
        } else if (result === 1) {
          this.$router.push({ path: 'dispatchList', query: { indexState: '0' } })
        }
      } else if (val === 7) {
        window.sessionStorage.setItem('btnLimit', ["报价","取消报价"])
        if (result === 0) {
          this.$router.push({ path: 'askPriceSell' })
        } else if (result === 1) {
          this.$router.push({ path: 'askPriceSell', query: { indexState: '4' } })
        }
      } else if (val === 8) {
        window.sessionStorage.setItem('btnLimit', ["报价","取消报价"])
        if (result === 0) {
          this.$router.push({ path: 'competePriceSell' })
        } else if (result === 1) {
          this.$router.push({ path: 'competePriceSell', query: { indexState: '4' } })
        }
      } else if (val === 9) {
        window.sessionStorage.setItem('btnLimit', ["新增线下销售单","编辑","删除"])
        if (result === 0) {
          this.$router.push({ path: 'OnlinesellerOrder' })
        } else if (result === 1) {
          this.$router.push({ path: 'OnlinesellerOrder', query: { indexData: 'today' } })
        }
      }
    },
    // 获取首页
    async getIndexMsg() {
      this.orderList = {
        matterName: [],
        returnNumber: [],
        takeDeliveryNumber: [],
        orderNumber: []
      }
      this.priceList = {
        months: [],
        price: []
      }
      const { data: res } = await indexMsgApi(this.indexMsgData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.indexDetail = res.data.data
      if (this.indexDetail.zdDetail.payingAmount === null) {
        this.indexDetail.zdDetail.payingAmount = 0
      }
      if (this.indexDetail.zdDetail.unreconciled === null) {
        this.indexDetail.zdDetail.unreconciled = 0
      }
      if (this.indexDetail.zdDetail.noPayAmount === null) {
        this.indexDetail.zdDetail.noPayAmount = 0
      }
      // 采购端的
      if (this.indexDetail.order && this.indexDetail.order.length !== 0) {
        this.indexDetail.order = this.indexDetail.order.reverse()
        this.indexDetail.order.forEach((item, index) => {
          this.orderList.matterName.push(item.productName)
          this.orderList.returnNumber.push(item.returnNumber)
          this.orderList.takeDeliveryNumber.push(item.takeDeliveryNumber)
          this.orderList.orderNumber.push(item.orderNumber)
        })
      }
      // 供应端的
      if (this.indexDetail.priceByMonth && this.indexDetail.priceByMonth.length !== 0) {
        const monthNames = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
        // 创建一个包含12个月份的数组
        const allMonths = monthNames.map(month => month)
        // 将接口返回的数据中的月份存储到一个 Set 中
        const returnedMonths = new Set(this.indexDetail.priceByMonth.map(item => monthNames[Number(item.months.substring(4)) - 1]))
        // 填充未返回的月份，并将价格设置为0
        const filledData = allMonths.map(month => ({
          month,
          price: returnedMonths.has(month) ? this.indexDetail.priceByMonth.find(item => monthNames[Number(item.months.substring(4)) - 1] === month).price : 0
        }))
        // 将数据分离成月份和价格的两个数组
        this.priceList.months = filledData.map(item => item.month).reverse()
        this.priceList.price = filledData.map(item => item.price).reverse()
      } else if (this.indexDetail.priceByMonth && this.indexDetail.priceByMonth.length == 0) {
        // 如果接口返回为空，将月份设置为默认的12个月，价格都为0
        const monthNames = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
        this.priceList.months = monthNames.reverse()
        this.priceList.price = new Array(12).fill(0).reverse()
      }
    },
    // 环形charts图标
    showCharts() {
      this.myChart = echarts.init(document.getElementById("main"));
      // 绘制图表
      if (this.roleType == 1) {
        this.myChart.setOption({
          title: {
            left: "center"
          },
          tooltip: {
            trigger: "item"
          },
          legend: {
            orient: 'vertical',
            right: '0',
            bottom: '15',
            itemWidth: 14,
            data: [
              {
                name: "待付款",
                textStyle: {
                  color: '#F2A61D'      // 图例文字颜色
                }
              },
              {
                name: "未对账",
                textStyle: {
                  color: '#4388F4'      // 图例文字颜色
                }
              },
              {
                name: "未付款",
                textStyle: {
                  color: '#ED5647'      // 图例文字颜色
                }
              }
            ]
          },
          series: [
            {
              type: "pie",
              minAngle: 2,
              radius: ["60%", "85%"],
              labelLine: {
                normal: {
                  length: 0, // 设置标签的视觉引导线长度
                  length2: 0,
                  lineStyle: {
                    width: 0
                  }
                }
              },
              data: [
                { value: this.indexDetail.zdDetail.payingAmount, name: "", itemStyle: { color: '#F2A61D' }, label: { color: '#F2A61D' } },
                { value: this.indexDetail.zdDetail.unreconciled, name: "", itemStyle: { color: '#4388F4' }, label: { color: '#4388F4' } },
                { value: this.indexDetail.zdDetail.noPayAmount, name: "", itemStyle: { color: '#ED5647' }, label: { color: '#ED5647' } }
              ],
              itemStyle: {
                borderWidth: 5,
                borderColor: "#fff"
              }
            }
          ],
          graphic: [
            {
              type: "text",
              id: "text1",
              left: "center",
              top: "40%",
              style: {
                text: '￥' + ((this.indexDetail.zdDetail.noPayAmount * 1000 + this.indexDetail.zdDetail.unreconciled * 1000 + this.indexDetail.zdDetail.payingAmount * 1000) / 1000),
                fill: "rgba(0,0,0,0.8)",
                fontSize: 22
              }
            },
            {
              type: "text", // 类型，可以是文字、图片或其它类型
              id: "text2",
              left: "center",
              top: "52%",
              style: {
                text: "总金额",
                fill: "rgba(0,0,0,0.4)", // 文字的颜色
                fontSize: 16
              }
            }
          ]
        });
      } else if (this.roleType == 2) {
        this.myChart.setOption({
          title: {
            left: "center"
          },
          tooltip: {
            trigger: "item"
          },
          legend: {
            orient: 'vertical',
            right: '0',
            bottom: '15',
            itemWidth: 14,
            data: [
              {
                name: "待收款",
                textStyle: {
                  color: '#F2A61D'      // 图例文字颜色
                }
              },
              {
                name: "未对账",
                textStyle: {
                  color: '#4388F4'      // 图例文字颜色
                }
              },
              {
                name: "未收款",
                textStyle: {
                  color: '#ED5647'      // 图例文字颜色
                }
              }
            ]
          },
          series: [
            {
              type: "pie",
              minAngle: 2,
              radius: ["60%", "85%"],
              labelLine: {
                normal: {
                  length: 0, // 设置标签的视觉引导线长度
                  length2: 0,
                  lineStyle: {
                    width: 0
                  }
                }
              },
              data: [
                { value: this.indexDetail.zdDetail.payingAmount, name: "", itemStyle: { color: '#F2A61D' }, label: { color: '#F2A61D' } },
                { value: this.indexDetail.zdDetail.unreconciled, name: "", itemStyle: { color: '#4388F4' }, label: { color: '#4388F4' } },
                { value: this.indexDetail.zdDetail.noPayAmount, name: "", itemStyle: { color: '#ED5647' }, label: { color: '#ED5647' } }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              },
              itemStyle: {
                borderWidth: 5,
                borderColor: "#fff"
              }
            }
          ],
          graphic: [
            {
              type: "text",
              id: "text1",
              left: "center",
              top: "40%",
              style: {
                text: '￥' + ((this.indexDetail.zdDetail.noPayAmount * 1000 + this.indexDetail.zdDetail.unreconciled * 1000 + this.indexDetail.zdDetail.payingAmount * 1000) / 1000),
                fill: "rgba(0,0,0,0.8)",
                fontSize: 22
              }
            },
            {
              type: "text", // 类型，可以是文字、图片或其它类型
              id: "text2",
              left: "center",
              top: "52%",
              style: {
                text: "总金额",
                fill: "rgba(0,0,0,0.4)", // 文字的颜色
                fontSize: 16
              }
            }
          ]
        });
      }
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    // 柱形charts图像
    barCharts() {
      if (document.getElementById("main2") !== null) {
        this.myChart2 = echarts.init(document.getElementById("main2"))
      }
      // 绘制图表
      if (this.roleType == 1) {
        this.myChart2.setOption({
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          legend: {
            show: false,
            itemWidth: 14,
            right: '0'
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "1%",
            top: "5%",
            containLabel: true
          },
          xAxis: {
            position: "top",
            type: "value",
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              formatter: function (params) {
                var provideNumber = 4; // 每行显示的字数
                if (params && params.toString().length > provideNumber) {
                  return params.toString().substring(0, 3)
                }
                return params.toString().substring(0, 3)
              },
              margin: 0 // 调整标签和轴线的距离
            }
          },
          yAxis: {
            position: 'top',
            type: "category",
            // show: false,
            axisLine: {
              show: false
            },
            inverse: false,
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: '{value}',
              margin: 270, // 调整标签和轴线的距离
              align: 'right', // 标签左对齐
              inside: true, // 标签在刻度内部
              fontSize: 14,
              fontWeight: '500',
              color: 'rgba(0, 0, 0, 1)'
            },
            data: this.orderList.matterName
          },
          series: [
            {
              name: "采购数",
              type: "bar",
              barGap: "80%",
              barCategoryGap: "50%",
              data: this.orderList.orderNumber,
              itemStyle: {
                color: '#ED5647'
              }
            },
            {
              name: "已收货",
              type: "bar",
              barGap: "80%",
              barCategoryGap: "50%",
              data: this.orderList.takeDeliveryNumber,
              itemStyle: {
                color: '#4388F4'
              }
            },
            {
              name: "退货数",
              type: "bar",
              barGap: "80%",
              barCategoryGap: "50%",
              data: this.orderList.returnNumber,
              itemStyle: {
                color: '#F2A61D'
              }
            }
          ]
        });
      } else if (this.roleType == 2) {
        this.myChart2.setOption({
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          legend: {
            itemWidth: 14,
            right: '0'
          },
          grid: {
            left: "0%",
            right: "5%",
            bottom: "3%",
            top: "5%",
            height: "auto",
            containLabel: true
          },
          xAxis: {
            position: "top",
            type: "value",
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              // formatter: '{value}',
              margin: 0, // 调整标签和轴线的距离
              interval: 800, // 不隐藏标签（缩写也可以）
              formatter: function (params) {
                var provideNumber = 4; // 每行显示的字数
                if (params && params.toString().length > provideNumber) {
                  return params.toString().substring(0, 3)
                }
                return params.toString().substring(0, 3)
              }
            },
          },
          yAxis: {
            position: 'top',
            type: "category",
            axisLine: {
              show: false,
            },
            inverse: false,
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: '{value}',
              margin: 40, // 调整标签和轴线的距离
              align: 'left', // 标签左对齐
              inside: false, // 标签在刻度内部
              fontSize: 12,
              color: '#000',
              fontWeight: '500'
            },
            data: this.priceList.months
          },
          series: [
            {
              name: "",
              type: "bar",
              barGap: "80%",
              barCategoryGap: "50%",
              data: this.priceList.price,
              itemStyle: {
                color: '#4388F4',
                barBorderRadius: 10,
              }
            }
          ]
        });
      }
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  }
};
</script>
<style lang="less" scoped>
.indexContent {
  width: 97%;
  margin: 0 auto;
  .indexHeader {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 140px;
    .header {
      padding: 0 20px;
      border-radius: 10px;
      height: 140px;
      width: calc(20% - 60px);
      background: white;
      cursor: pointer;
      .headerBox {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        height: 94px;
        .headerLeft {
          p {
            width: 70px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.4);
            line-height: 20px;
          }
          h1 {
            width: 36px;
            height: 36px;
            font-size: 32px;
            font-family: ArialMT;
            color: rgba(0, 0, 0, 0.8);
            line-height: 37px;
          }
        }
        img {
          width: 40px;
        }
      }
      .headerCount {
        display: flex;
        justify-content: space-between;
        height: 40px;
        width: 100%;
        p {
          padding: 6px 8px;
          box-sizing: border-box;
          height: 32px;        
          background-color: #F8F9FA;
          border-radius: 16px;
          font-size: 13px;
          color: #123E83;
          line-height: 20px;
          font-weight: bold;
        }
        span {
          display: inline-block;
          padding: 6px;
          color: rgba(0, 0, 0, 0.40);
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .myBox {
      padding: 20px;
      width: calc(100% - 340px);
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;
      .myBoxHeader {
        display: flex;
        height: 24px;
        width: 100%;
        h5 {
          margin: 0;
          font-size: 18px;
        }
        span {
          margin-left: 20px;
          padding: 2px 10px;
          display: inline-block;
          background-color: rgba(248, 249, 250, 1);
          border-radius: 12px;
          border: 1px solid rgba(248, 249, 250, 1);
          cursor: pointer;
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.40);
          line-height: 18px;
        }
        .chooseMy {
          background-color: #fff;
          border: 1px solid rgba(18, 62, 131, 1);
          font-size: 12px;
          font-weight: bold;
          color: rgba(18, 62, 131, 1);
          line-height: 16px;
        }
      }
      .myBoxContent {
        margin: 20px 0 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 235px;
        overflow-y: auto;
        .planBox, .orderBox, .receiptBox, .mySellBox {
          margin-right: 20px;
          margin-bottom: 20px;
          padding: 20px;
          height: 172px;
          width: 320px;
          background: rgba(0, 0, 0, 0.03);
          box-sizing: border-box;
          border-radius: 10px;
          // box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
          cursor: pointer;
          .myHeader {
            display: flex;
            justify-content: space-between;
            height: 30px;
            width: 100%;
            border-bottom: 1px solid #fff;
            p {
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              font-weight: bold;
            }
            span {
              color: rgba(0, 0, 0, 0.60);
              font-size: 14px;
              font-weight: bold;
            }
            .mySellerBtn {
              display: flex;
              justify-content: space-between;
            }
          }
          .myContent {
            height: 70px;
            width: 100%;
            border-bottom: 1px solid #fff;
            overflow: hidden;
            p {
              margin: 10px 0;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              font-weight: bold;
              i {
                margin-left: 10px;
                padding: 4px 8px;
                background-color: rgba(70, 147, 235, 0.10);
                border-radius: 4px;
                font-size: 12px;
                color: rgba(70, 147, 235, 1);
                font-weight: bold;
              }
              span {
                margin-right: 5px;
                color: rgba(0, 0, 0, 0.40);
              }
            }
          }
          .myFooter {
            display: flex;
            justify-content: space-between;
            height: 30px;
            width: 100%;
            span {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.40);
              font-size: 14px;
              font-weight: bold;
            }
          }
        }
        .mySellBox {
          height: 142px;
          .myContent {
            height: 40px;
          }
          .myFooter {
            span:nth-child(1) {
              width: 70%;
            }
          }
        }
        .orderBox:hover, .planBox:hover, .receiptBox:hover, .mySellBox:hover {
          background-color: #fff;
          box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
        }
      }
    }
    .orderMap {
      width: 320px;
      height: 320px;
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;
      .orderMapHeader {
        margin: 20px 20px 0;
        h5 {
          margin: 0;
          font-size: 18px;
        }
      }
      .orderMapContent {
        height: 216px;
        #main {
          width: 100%;
          height: 216px;
          box-sizing: border-box;
        }
      }
      .orderMapFooter {
        display: flex;
        height: 60px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        .mapFooterBox {
          margin: 10px 0;
          height: 40px;
          border-right: 1px solid rgba(0, 0, 0, 0.05);
          flex: 1;
          p {
            text-align: center;
            color: rgba(0, 0, 0, 0.40);
            font-size: 12px;
            font-weight: bold;
          }
          span {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
  }
  article {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 320px;
    .unEndOrder {
      padding: 20px;
      width: calc(100% - 340px);
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;
      h5 {
        margin: 0;
        font-size: 18px;
      }
      /deep/ .el-table .cell.el-tooltip {
        display: flex;
        justify-content: center;
        .table-fixed {
          /deep/.el-table__fixed-right {
              height: 100% !important
          }
          /deep/.el-table__fixed {
              height:100% !important
          }
        }
      }
      .stateBox1, .stateBox2, .stateBox3 {
        display: block;
        width: 64px;
        height: 22px;
        background: rgba(239, 188, 96, 0.05);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(239, 188, 96, 0.2);
        font-size: 12px;
        color: rgba(239, 188, 96, 1);
      }
      .stateBox2 {
        background-color: rgba(70, 147, 235, 0.05);
        border-color: rgba(70, 147, 235, 0.20);
        color: rgba(70, 147, 235, 1);
      }
      .stateBox3 {
        background: rgba(59,212,144,0.05);
        border: 1px solid rgba(59,212,144,0.2);
        color: rgba(59, 212, 144, 1);
      }
      .controlBox {
        display: block;
        width: 64px;
        height: 22px;
        border-radius: 4px 4px 4px 4px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        cursor: pointer;
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: bold;
        i {
          margin: 0 5px 0 0;
        }
      }
    }
    .sellerBox {
      width: 320px;
      height: 320px;
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;
      .sellerBoxHeader {
        margin: 20px 20px 0;
        display: flex;
        justify-content: space-between;
        h5 {
          margin: 0;
          font-size: 18px;
        }
        .el-pagination {
          margin: -5px 0 0!important;
        }
      }
      .sellerBoxContent {
        height: 276px;
        width: 100%;
        overflow-y: auto;
        .itmes {
          padding: 20px 20px 0;
          display: flex;
          width: 100%;
          box-sizing: border-box;
          justify-content: space-between;
          >div {
            display: flex;
            width: 80%;
            overflow: hidden;
            p:nth-child(1) {
              margin-top: 2px;
              margin-right: 10px;
              width: 16px;
              height: 16px;
              border-radius: 4px;
              text-align: center;
              line-height: 16px;
              color: white;
              font-size: 12px;
            }
            p:nth-child(2) {
              width: calc(100% - 30px);
            }
          }
          p {
            line-height: 22px;
            font-weight: 500;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }
  footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 692px;
    main {
      width: calc(100% - 340px);
      .unEndAskPrice, .unEndCompetePrice {
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 10px;
        background-color: #fff;
        box-sizing: border-box;
        h5 {
          margin: 0;
          font-size: 18px;
        }
        /deep/ .el-table .cell.el-tooltip {
          display: flex;
          justify-content: center;
          .table-fixed {
            /deep/.el-table__fixed-right {
                height: 100% !important
            }
            /deep/.el-table__fixed {
                height:100% !important
            }
          }
        }
        .stateBox1, .stateBox2, .stateBox3, .stateBox4 {
          display: block;
          width: 64px;
          height: 22px;
          background: rgba(239, 188, 96, 0.05);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(239, 188, 96, 0.2);
          font-size: 12px;
          color: rgba(239, 188, 96, 1);
        }
        .stateBox2 {
          background-color: rgba(70, 147, 235, 0.05);
          border-color: rgba(70, 147, 235, 0.20);
          color: rgba(70, 147, 235, 1);
        }
        .stateBox3 {
          background: rgba(59,212,144,0.05);
          border: 1px solid rgba(59,212,144,0.2);
          color: rgba(59, 212, 144, 1);
        }
        .stateBox4 {
          padding: 0 10px;
          width: auto;
          background-color: rgba(70, 147, 235, 1);
          border-color: rgba(70, 147, 235, 1);
          color: rgba(255, 255, 255, 1);
        }
        .controlBox {
          display: block;
          width: 64px;
          height: 22px;
          border-radius: 4px 4px 4px 4px;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.15);
          cursor: pointer;
          color: rgba(0, 0, 0, 0.80);
          font-size: 12px;
          font-weight: bold;
          i {
            margin: 0 5px 0 0;
          }
        }
      }
    }
    aside {
      width: 320px;
      height: 672px;
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;
      .asideHeader {
        position: relative;
        margin: 20px 20px 0;
        h5 {
          margin: 0;
          font-size: 18px;
        }
        .showTitle {
          margin-top: 20px;
          display: flex;
          p {
            margin-right: 10px;
            font-size: 12px;
            color: rgba(237, 86, 71, 1);
            i {
              margin-right: 3px;
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: rgba(237, 86, 71, 1);
              vertical-align: text-top;
            }
          }
          p:nth-child(2) {
            color: rgba(67, 136, 244, 1);
            i {
              background-color: rgba(67, 136, 244, 1);
            }
          }
          p:nth-child(3) {
            color: rgba(245, 180, 63, 1);
            i {
              background-color: rgba(245, 180, 63, 1);
            }
          }
        }
        .pickerBox {
          position: absolute;
          top: 0;
          right: 0;
          width: 160px;
          .el-input {
            width: 160px;
            font-size: 12px;
            /deep/ .el-input__icon {
              line-height: 20px;
            }
          }
          
          .el-input__inner {
            width: 160px;
            font-size: 12px;
          }
          /deep/ .el-date-editor .el-range__icon {
            line-height: 18px;
          }
        }
      }
      .asideContent {
        padding: 0 10px;
        height: 614px;
        overflow: hidden;
        #main2 {
          width: 100%;
          height: 580px;
        }
      }
    }
  }
  .one {
    background: #fbba04;
  }
  .tow {
    background: #b1becc;
  }
  .three {
    background: #ce9d82;
  }
  .all {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>
