// 审批流程配置
<template>
    <div class="approvalManageDispose">
      <header>
        <h4>流程预览</h4>
        <p>流程名称:<span>{{name}}</span></p>
        <div class="schedule">
          <el-steps :active="-999" align-center>
            <!-- <el-step title="操作人" description="新建" class="black_step"></el-step> -->
            <el-step title="操作人" description="发布" class="black_step"></el-step>
            <el-step v-for="item in tableData" :title="item.name ? item.name: '无'" :description="item.type === 1 ? '上级岗位': item.type === 2 ? '指定岗位': '指定人'" v-bind:key="item.id"></el-step>
            <el-step title="完成" description="" class="black_step"></el-step>
          </el-steps>
        </div>
      </header>
      <section>
        <el-button @click="addDialog = true">添加审批节点</el-button>
        <el-button v-if="status === '0'" class="useBtn" @click="editApprovalStatus()">启用</el-button>
        <el-button v-else class="stopBtn" @click="editApprovalStatus()">挂起</el-button>
        <el-table :data="tableData" stripe style="width: 100%" border>
          <el-table-column show-overflow-tooltip label="节点" type="index" width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="" label="类型" min-width="20%" align="center">
            <template slot-scope="scope">
              <!-- <span v-if="scope.row.type === 1">上级岗位</span> -->
              <span v-if="scope.row.type === 2">指定岗位</span>
              <span v-if="scope.row.type === 3">指定人</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="审批用户" min-width="30%" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="" label="设置" min-width="20%" align="center">其中一人</el-table-column>
          <el-table-column prop="" label="操作" min-width="20%" align="center">
            <template slot-scope="scope">
              <el-button class="disposeBtn" size="mini" @click="openEditNode(scope.$index, scope.row)">编辑</el-button>
              <el-button class="delBtn" size="mini" @click="delNode(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 编辑节点弹出框 -->
      <template>
        <el-dialog title="修改审批节点" :visible.sync="dialogVisible" min-width="40%" :close-on-click-modal="false" v-dialogDrag>
          <div><p>当前节点 : </p><span>{{node}}</span></div>
          <div>
            <p>选择类型 : </p>
            <br>
            <!-- <el-radio v-model="type" :label="1" @click.native="uuid = ''">上级岗位</el-radio> -->
            <el-radio v-model="type" :label="2" @click.native="uuid = ''">指定岗位</el-radio>
            <el-radio v-model="type" :label="3" @click.native="uuid = ''">指定人</el-radio>
          </div>
          <!-- 岗位角色列表 -->
          <div v-if="type === 2" style="margin-top: 30px;">
            <p>审批岗位 : </p>
            <el-select v-model="uuid" placeholder="请选择" size="mini" filterable                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   >
              <el-option v-for="item in editData.options" :key="item.id" :label="item.roleName" :value="item.roleId"></el-option>
            </el-select>
          </div>
          <!-- 指定人用户列表 -->
          <div v-if="type === 3" style="margin-top: 30px;">
            <p>审批用户 : </p>
            <el-select v-model="uuid" placeholder="请选择" size="mini" filterable>
              <el-option v-for="items in editPerson.userOptions" :key="items.id" :label="items.name" :value="items.userId"></el-option>
            </el-select>
          </div>
          <!-- <div>
            <p>设置 : </p>
            <span>其中一人</span>
          </div> -->
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="editNode(uuid)">修 改</el-button>
          </span>
        </el-dialog>
      </template>
      <!-- 添加节点弹出框 -->
      <template>
        <el-dialog title="添加审批节点" :visible.sync="addDialog" min-width="40%" :close-on-click-modal="false" v-dialogDrag>
          <div>
            <p>选择类型 : </p>
            <br>
            <!-- <el-radio v-model="type" :label="1">上级岗位</el-radio> -->
            <el-radio v-model="type" :label="2">指定岗位</el-radio>
            <el-radio v-model="type" :label="3">指定人</el-radio>
          </div>
          <!-- 岗位角色列表 -->
          <div v-if="type === 2" style="margin-top: 30px;">
            <p>审批岗位 : </p>
            <el-select v-model="uuid" placeholder="请选择" size="mini">
              <el-option v-for="item in editData.options" :key="item.id" :label="item.roleName" :value="item.roleId"></el-option>
            </el-select>
          </div>
          <!-- 指定人用户列表 -->
          <div v-if="type === 3" style="margin-top: 30px;">
            <p>审批用户 : </p>
            <el-select v-model="uuid" placeholder="请选择" size="mini" v-show="choiceSign === false">
              <el-option v-for="items in editPerson.userOptions" :key="items.id" :label="items.name" :value="items.userId"></el-option>
            </el-select>
            <!-- <el-select v-model="signId" multiple placeholder="请选择多个" size="mini" v-show="choiceSign === true" style="width: 320px;">
              <el-option v-for="items in editPerson.userOptions" :key="items.id" :label="items.name" :value="items.userId"></el-option>
            </el-select> -->
          </div>
          <!-- <div style="margin-top: 10px;">
            <p>设置 : </p>
            <el-radio v-model="choiceSign" :label="false">其中一人</el-radio>
            <el-radio v-model="choiceSign" :label="true" v-if="type === 3">会签</el-radio>
          </div> -->
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialog = false">取 消</el-button>
            <el-button type="primary" @click="addNode()">添 加</el-button>
          </span>
        </el-dialog>
      </template>
    </div>
</template>
<script>
import { approvalNodeApi, editNodeApi, getRoleListApi, supplierUserListApi, addNodeApi, delNodeApi, editApprovalStatusApi } from '@/api'
export default {
  // name: 'approvalManageDispose',
  data() {
    return {
      companyId: window.$cookies.get('companyId'),
      tableData: [],
      name: this.$route.query.name,
      // 审批流id
      approveId: this.$route.query.approveId,
      // 控制修改节点弹出框显示隐藏
      dialogVisible: false,
      // 控制添加节点弹出框显示隐藏
      addDialog: false,
      // 选中的类别
      type: null,
      // 是否选择会签
      choiceSign: false,
      // 岗位角色数据
      editData: {
        // 指定下拉框数据
        options: []
      },
      // 当前节点
      node: null,
      // 当前节点id
      nodeId: '',
      // 当前选中岗位或指定人id
      uuid: '',
      // 会签选中人id
      signId: [],
      editPerson: {
        userOptions: []
      },
      // 当前状态
      status: this.$route.query.status
    }
  },
  created() {
    this.approvalNode()
    this.getRoleList()
    this.getUserManage()
  },
  watch: {
    'dialogVisible': function(newVal) {
      if (newVal === false) {
        this.uuid = null
      }
    },
    'addDialog': function(newVal) {
      if (newVal === false) {
        this.uuid = null
      }
    }
  },
  methods: {
    // 获取审批列表
    async approvalNode() {
      const { data: res } = await approvalNodeApi(this.approveId)
      if (res.code !== 0) return this.$message.error('获取审批列表失败!')
      this.tableData = res.data.list
      console.log(this.tableData, '2123')
    },
    // 打开编辑弹框
    openEditNode(index, result) {
      this.uuid = result.uuid
      console.log(this.uuid, '????')
      this.node = index + 1
      this.nodeId = result.id
      this.type = result.type
      this.dialogVisible = true
    },
    // 获取角色列表数据
    async getRoleList() {
      const { data: res } = await getRoleListApi({ page: 1, size: 999, search: '' })
      this.editData.options = res.data.page.list
    },
    // 获取用户列表数据
    async getUserManage() {
      const { data: res } = await supplierUserListApi({ companyId: this.companyId, name: '', isActivate: 1 })
      this.editPerson.userOptions = res.data.userList
    },
    // 修改审批节点
    async editNode() {
      if (this.type === null) return this.$message.error('请先选择类型!')
      if ((this.uuid === null || this.uuid === '') && this.type !== 1) return this.$message.error('未选择审批岗位或审批人!')
      const { data: res } = await editNodeApi({ type: this.type, uuid: this.uuid, id: this.nodeId })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.approvalNode()
      this.dialogVisible = false
      this.$message.success({ duration: 1000, message: '修改成功!' })
    },
    // 添加审批节点
    async addNode() {
      if (this.type === null) return this.$message.error('请先选择类型!')
      if ((this.uuid === null || this.uuid === '') && this.type !== 1) return this.$message.error('未选择审批岗位或审批人!')
      const { data: res } = await addNodeApi({ type: this.type, uuid: this.uuid, approveId: this.approveId })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.approvalNode()
      this.addDialog = false
      this.$message.success({ duration: 1000, message: '添加节点成功!' })
    },
    // 删除审批节点
    delNode(id) {
      this.$confirm('确定删除该节点吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await delNodeApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.approvalNode()
        this.$message.success({ duration: 1000, message: '删除节点成功!' })
      }).catch(() => {
      })
    },
    // 修改状态
    editApprovalStatus() {
      let newStatus = ''
      if (this.status === '1') {
        if (this.name === '采购方合同审批流程' || this.name === '销售方合同审批流程') {
          return this.$message.error('该审批流不支持挂起')
        }
        newStatus = 0
      } else if (this.status === '0') {
        newStatus = 1
      }
      this.$confirm('是否' + (this.status === '0' ? '启用' : '挂起') + '当前审批流', '提示', {
        confirmButtonText: this.status === '0' ? '启用' : '挂起',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await editApprovalStatusApi({ id: this.approveId, status: newStatus })
        if (res.code !== 0) return this.$message.errror(res.msg)
        if (this.status === '1') {
          this.$message.success({ duration: 1000, message: '挂起成功!' })
          this.status = '0'
        } else {
          this.$message.success({ duration: 1000, message: '启用成功!' })
          this.status = '1'
        }
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped lang='less'>
.approvalManageDispose {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
}
.approvalManageDispose header {
  margin: 20px;
  margin-bottom: 20px;
  padding: 35px;
  height: 185px;
  background:rgba(255,255,255,1);
  border-radius: 8px;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  h4 {
    width: 64px;
    height: 24px;
    color: rgb(80,80,80);
    font-size: 16px;
    font-weight: bold;
  }
  p {
    margin: 8px 0;
    font-size: 14px;
    font-weight: bold;
    color: rgb(80,80,80);
    span{
      margin-left: 10px;
    }
  }
  .schedule {
    display: inline-block;
    margin-left: 5%;
    height: 124px;
    width: 90%;
    background-color: #F3F4FD;
    overflow: hidden;
    .el-steps {
      margin-top: 30px;
      /deep/ .el-step__icon.is-text {
        background-color: #123E83;
        border: 0;
      }
      /deep/ .el-step__icon-inner {
        color: #fff;
      }
      .black_step /deep/ .el-step__icon-inner {
        color: #123E83;
      }
      /deep/ .el-step.is-center .el-step__line {
        background-color: #123E83;
      }
      /deep/ .el-step__title.is-wait {
        color: #000;
        font-weight: 500;
        font-size: 16px;
      }
      /deep/ .el-step__description.is-wait {
        color: #123E83!important;
      }
    }
  }
}
.approvalManageDispose section {
  position: relative;
  margin: 20px;
  padding: 20px 35px 35px 35px;
  background:rgba(255,255,255,1);
  border-radius: 8px;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}
section>.el-button {
  background-color: rgba(18, 62, 131, 1);
  color: #fff;
  border: 0;
}
section>.el-button:hover {
  background-color: rgba(18, 62, 131, .8);
  color: #fff;
}
section {
  .useBtn {
    position: absolute;
    right: 35px;
    width: 80px;
    background-color: rgb(60,201,172);
  }
  .useBtn:hover {
    background-color: rgb(60,201,172,.7);
  }
  .stopBtn {
    position: absolute;
    right: 35px;
    width: 80px;
    background-color: rgb(232,101,75);
  }
  .stopBtn:hover {
    background-color: rgba(232,101,75,.7);
  }
}
section .el-table {
  .el-button {
    color: #fff;
    border: 0;
  }
  .disposeBtn {
    background-color: rgba(18, 62, 131, 1);
  }
  .disposeBtn:hover {
    background-color: rgba(18, 62, 131, .8);
    color: #fff;
  }
  .delBtn {
    background-color: rgba(232,101,75);
  }
  .delBtn:hover {
    background-color: rgba(232,101,75,.9);
    color: #fff;
  }
}
.approvalManageDispose .el-dialog div {
  // height: 60px;
  p{
    display: inline-block;
    width: 80px;
    text-align: left;
  }
  span {
    margin-left: 10px;
  }
  .el-radio {
    margin-left: 10px;
  }
  .el-select {
    margin-left: 10px;
  }
}
// 会签输入框样式
/deep/ .el-tag.el-tag--info {
  margin-top: 4px;
}
/deep/ .el-select .el-tag__close.el-icon-close {
  top: -2px;
}
/deep/ .el-select .el-tag__close.el-icon-close::before {
  margin: 0;
}
</style>
