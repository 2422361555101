// 销售单列表
<template>
  <div class="purchaseOrder">
    <nav>
      <h5>销售单</h5>
      <div>
        <!-- <el-button class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增销售单</el-button> -->
        <el-button class="lightDeepStyle" @click="purchaseOrderExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="purchaseOrderData.code" placeholder="销售单编号搜索" @input="getpurchaseOrderList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="purchaseOrderData.productName" placeholder="产品名称搜索" @input="getpurchaseOrderList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="下单日期区间"
          end-placeholder="下单日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select v-if="active === 1" @change="getpurchaseOrderList()" v-model="purchaseOrderData.state" placeholder="发货状态" size="mini" filterable>
        <!-- 前端自己编撰一个值 12 将state改成11 stateType 0自动 1手动 -->
        <el-option label="待发货" value="0"></el-option>
        <el-option label="进行中" value="8,9,10"></el-option>
        <el-option label="订单完成" value="11"></el-option>
        <el-option label="手动完结" value="12"></el-option>
        <el-option label="已对账" value="2"></el-option>
        <el-option label="全部发货状态" value="0,1,2,3,8,9,10,11"></el-option>
      </el-select>
    </section>
    <article>
      <div @click="changeActive(1)" style="border-top-left-radius: 4px; border-bottom-left-radius: 4px;" :class="active === 1 ? 'changeBox highlight' : 'changeBox'">已确认</div>
      <div @click="changeActive(2)" :class="active === 2 ? 'changeBox highlight' : 'changeBox'">待确认</div>
      <div @click="changeActive(3)" style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;" :class="active === 3 ? 'changeBox highlight' : 'changeBox'">作废</div>
    </article>
    <main>
      <el-table ref="tableHeight" row-key="index" :height="tableHeight" :data="purchaseOrderList" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineEntityList'}" :show-overflow-tooltip="true">
        <el-table-column show-overflow-tooltip prop="code" label="销售单编码" min-width="140px" align="center" fixed="left">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.code}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="companyName" label="客户名称" min-width="140px" align="center" fixed="left">
          <template slot-scope="scope">
            <span>{{scope.row.companyName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="140px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="number" label="订单数量" min-width="100px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="sellWarehouseNumber" label="实时库存数" min-width="100px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.sellWarehouseNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.price}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="price" label="订单金额" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">{{(scope.row.totalPrice).toFixed(2)}}</span>
            <span v-else>{{(scope.row.price * scope.row.number).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="arriveTime" label="要求交货时间" min-width="100px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.arriveTime">{{(scope.row.arriveTime).substring(0, 10)}}</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="deliveryType" label="送货方式" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.type === 0">自提</span>
              <span v-if="scope.row.type === 1">供方送货</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="下单时间" min-width="120px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.createTime">{{(scope.row.createTime).substring(0, 16)}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="proposerName" label="签订人" min-width="120px"  align="center"></el-table-column>
        <el-table-column key="3" show-overflow-tooltip prop="deliveryType" label="发货进度" min-width="160px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-progress :width="24" :show-text="false" type="circle" :percentage="scope.row.deliveryNumber / scope.row.number * 100"></el-progress>
            <span style="margin: 0 10px;">{{(scope.row.deliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
            <span>{{scope.row.deliveryNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column key="4" show-overflow-tooltip prop="deliveryType" label="收货进度" min-width="160px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-progress color="#20D4CA" :width="24" :show-text="false" type="circle" :percentage="scope.row.takeDeliveryNumber / scope.row.number * 100"></el-progress>
            <span style="margin: 0 10px;">{{(scope.row.takeDeliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
            <span>{{scope.row.takeDeliveryNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="active === 1" key="1" show-overflow-tooltip prop="state" label="发货状态" min-width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <div v-if="scope.row.lineEntityList">
              <span class="stateBox3" v-if="scope.row.state == 0">待发货</span>
              <span class="stateBox1" v-else-if="scope.row.state == 8">进行中</span>
              <span class="stateBox1" v-else-if="scope.row.state == 9">进行中</span>
              <span class="stateBox1" v-else-if="scope.row.state == 10">进行中</span>
              <span class="stateBox2" v-else-if="scope.row.state == 11 && scope.row.stateType == 0">订单完成</span>
              <span class="stateBox2" v-else-if="scope.row.state == 11 && scope.row.stateType == 1">手动完结</span>
              <span class="stateBox2" v-else-if="scope.row.state == 2">已对账</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="180px"  align="center" fixed="right">
          <template slot-scope="scope">
            <div class="controlBox" v-if="scope.row.lineEntityList">
              <p v-if="scope.row.state == 0 && btnLimit.includes('撤回')" @click="chooseCommand('1', scope.row)"><i class="iconfont icon-fanhui-baise"></i><span>撤回</span></p>
              <p v-if="scope.row.state == 8" @click="chooseCommand('2', scope.row)"><i class="iconfont icon-wanjie"></i><span>完结</span></p>
              <p v-if="scope.row.state == 7 && btnLimit.includes('确认取消')" @click="chooseCommand('3', scope.row)"><i class="iconfont icon-shoudongwancheng"></i><span>确认</span></p>
              <p v-if="scope.row.state == 7 && btnLimit.includes('确认取消')" @click="chooseCommand('4', scope.row)"><i class="iconfont icon-quxiaoxinzeng"></i><span>取消</span></p>
              <p v-if="scope.row.state == 6 && btnLimit.includes('删除')" @click="chooseCommand('5', scope.row)"><i class="el-icon-delete"></i><span>删除</span></p>
              <p v-if="(scope.row.state == 0 || scope.row.state == 1 || scope.row.state == 8) && btnLimit.includes('录入实时库存')" @click="chooseCommand('6', scope.row)"><i class="el-icon-edit-outline"></i><span>录入实时库存</span></p>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="purchaseOrderData.size"
        :total="total">
      </el-pagination>
    </main>
    <!-- 录入实时库存弹出框 -->
    <template class="enterRealStockBox">
      <div>
        <el-dialog title="录入实时库存" :visible.sync="enterRealStockDialog" :close-on-click-modal="false" class="enterRealStockStyle" v-dialogDrag>
          <el-form :model="ruleForm" ref="ruleForm">
            <el-form-item v-for="(item, index) in  enterRealStockData" :key="index">
              <div>
                <span class="textHidden">{{item.productName}}</span><el-input v-model="item.sellWarehouseNumber" placeholder="输入库存数" clearable autocomplete="off"></el-input>
              </div>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="enterRealStockDialog = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="confirmEnter()">确认</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
  </div>
</template>
<script>
import { purchaseOrderListApi, delSellerOrderApi, purchaseOrderEndApi, submitPurchaseOrderApi, editPurchaseOrderStateApi, confirmEnterApi } from '@/api'
export default {
  name: 'purchaseOrder',
  data() {
    return {
      ruleForm: '',
      // 录入实时库存弹框
      enterRealStockDialog: false,
      enterRealStockData: [],
      // 选中的标签
      active: 1,
      // 列表动态高度
      tableHeight: '',
      // 销售单列表
      purchaseOrderList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      purchaseOrderData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        productName: '',
        approveTimeBegin: '',
        approveTimeEnd: '',
        approveStatus: 2,
        state: '0,1,2,3,8,9,10,11',
        stateType: '',
        sellId: '',
        ifAwait: '',
        companyType: 2
      },
      chooseTime: '',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    if (this.$route.query.ifAwait !== undefined) {
      this.purchaseOrderData.ifAwait = this.$route.query.ifAwait
    } else {
      this.purchaseOrderData.ifAwait = ''
    }
    if (this.$route.query.indexState && this.$route.query.indexState == '8,9,10') {
      this.purchaseOrderData.state = '8,9,10'
    } else {
      this.purchaseOrderData.state = '0,1,2,3,8,9,10,11'
    }
    this.getpurchaseOrderList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 确认录入
    async confirmEnter() {
      const { data: res } = await confirmEnterApi(this.enterRealStockData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.enterRealStockDialog = false
      this.$message.success('录入成功!')
      this.getpurchaseOrderList()
    },
    // 修改订单状态
    async editPurchaseOrderState(id, state) {
      let stateType = (state === 11 ? 1 : 0)
      const { data: res } = await editPurchaseOrderStateApi({ id: id, state: state, stateType: stateType })
      if (res.code !== 0) return this.$message.error(res.msg)
      if (state === 0) {
        this.$message.success('确认成功!')
      } else if (state === 6) {
        this.$message.success('取消成功!')
      } else if (state === 7) {
        this.$message.success('撤回成功!')
      } else if (state === 6) {
        this.$message.success('完结成功!')
      }
      this.getpurchaseOrderList()
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        // 确认=0 完结=9 撤回=7 取消=6
        this.$confirm("是否撤回该销售单?撤回后销售单状态变为【未确认】状态.", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(async () => {
          this.editPurchaseOrderState(result.id, 7)
        })
      } else if (command === '2') {
        this.$confirm("还有部分产品未发货,是否直接完结该销售订单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(async () => {
          this.editPurchaseOrderState(result.id, 11)
        })
      } else if (command === '3') {
        this.$confirm("是否确认该销售单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(async () => {
          this.editPurchaseOrderState(result.id, 0)
        })
      } else if (command === '4') {
        this.$confirm("是否取消确认该销售单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(async () => {
          this.editPurchaseOrderState(result.id, 6)
        })
      } else if (command === '5') {
        this.delSellerOrder(result.id)
      } else if (command === '6') {
        // 获取当前列表下的子列表数
        this.enterRealStockData = []
        result.lineEntityList.forEach((item, index) => {
          this.enterRealStockData.push(item)
        })
        this.enterRealStockDialog = true
        this.getpurchaseOrderList()
      }
    },
    // 销售方删除采购订单
    delSellerOrder(id) {
      this.$confirm("是否确认删除该销售单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(async () => {
        const { data: res } = await delSellerOrderApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getpurchaseOrderList()
      })
    },
    // 提交计划单
    async submitPurchaseOrder(id) {
      const { data: res } = await submitPurchaseOrderApi({ id: id, enquiryId: '' })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('提交成功!')
      this.getpurchaseOrderList()
    },
    // 切换标签页
    changeActive(val) {
      // state全部状态 0待发货 1已入库 2已对账 3部分入库   8 部分发货 9待收货 10部分收货 11已收货
      this.active = val
      if (val === 1) {
        // 已确认
        this.purchaseOrderData.state = '0,1,2,3,8,9,10,11'
      } else if (val === 2) {
        // 待确认
        this.purchaseOrderData.state = '7'
      } else if (val === 3) {
        // 作废
        this.purchaseOrderData.state = '6'
      }
      this.getpurchaseOrderList()
      // 解决样式错乱的问题
      this.$nextTick(() => {
        this.$refs.tableHeight.doLayout()
      })
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.purchaseOrderData.createTimeBegin = ''
        this.purchaseOrderData.createTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value);
          let getYear = timers.getFullYear();
          let nowMonth = timers.getMonth() + 1;
          let getDate = timers.getDate();
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.purchaseOrderData.createTimeBegin = getYear + '-' + nowMonth + '-' + getDate
          } else {
            this.purchaseOrderData.createTimeEnd = getYear + '-' + nowMonth + '-' + getDate
          }
        });
      }
      this.getpurchaseOrderList()
    },
    // 获取销售单列表
    async getpurchaseOrderList(reset) {
      if (reset !== 'reset') {
        this.purchaseOrderData.page = 1
      }
      let newPurchaseOrderData = JSON.parse(JSON.stringify(this.purchaseOrderData))
      if (this.purchaseOrderData.state == '11') {
        // 判断是不是订单完成
        newPurchaseOrderData.stateType = 0
      }
      if (this.purchaseOrderData.state == '12') {
        // 判断是不是手动完结
        newPurchaseOrderData.stateType = 1
        newPurchaseOrderData.state = 11
      }
      const { data: res } = await purchaseOrderListApi(newPurchaseOrderData)
      // 获取表单数据
      let newPurchaseOrderList = res.data.data.list
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
      // 给数据添加唯一值index,用于展开
      if (newPurchaseOrderList.length !== 0) {
        newPurchaseOrderList.forEach((item, index) => {
          item.index = index + ''
          if (item.lineEntityList.length !== 0) {
            item.lineEntityList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
      this.purchaseOrderList = newPurchaseOrderList
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.purchaseOrderData.size = val
      this.purchaseOrderData.page = 1
      this.getpurchaseOrderList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.purchaseOrderData.page = val
      this.currentPage = val
      this.getpurchaseOrderList('reset')
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: "/addPurchaseOrder" })
      } else if (val === 2) {
        this.$router.push({ path: "/sellerOrderDetail", query: { result: JSON.stringify(result) }})
      } else {
        this.$router.push({ path: "/editPurchaseOrder", query: { result: JSON.stringify(result) }})
      }
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseOrder {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  article {
    margin: 20px 20px 10px;
    display: flex;
    .changeBox {
      margin-left: -1px;
      height: 32px;
      width: 68px;
      border: 1px solid #CACACA;
      cursor: pointer;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.60);
    }
    .highlight {
      background-color: rgba(18, 62, 131, 1);
      border-color: rgba(18, 62, 131, 1);
      color: #fff;
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
    .controlBox {
      display: flex;
      justify-content: space-around;
      min-width: 170px;
      p {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.80);
        i {
          margin-right: 3px;
          font-size: 14px;
        }
      }
    }
    .stateBox1, .stateBox2, .stateBox3 {
      display: block;
      width: 64px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
  }
  // 录入实时库存弹框
  .enterRealStockStyle {
    margin-top: 15vh;
    /deep/.el-dialog {
      height: 319px;
      width: 424px;
      .el-dialog__header {
        padding: 20px 20px 0px;
        .el-dialog__title {
          font-size: 16px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.88);
        }
        .el-dialog__headerbtn {
          top: 20px;
          .el-icon-close:before {
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
      .el-dialog__body {
        height: 200px;
        overflow-y: auto;
        .el-form-item {
          margin: 0;
          .el-form-item__content {
            margin: 20px 0 0;
            >div {
              display: flex;
              justify-content: space-between;
              span {
                height: 32px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.80);
                text-align: left;
              }
              .el-input {
                width: 270px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
