// 新增线下供应商
<template>
  <div class="addOffineSupplier">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/offineSupplier' }">线下供应商</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">线下供应商详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addOffineSupplierContent">
      <el-form ref="ruleForm" :model="addData" label-width="117px" :rules="rules">
        <nav>
          <h5>线下供应商详情</h5>
          <div class="navBox">
            <el-row >
                <el-col :span="24">
                    <el-form-item label="线下供应商:" prop="name">
                        <el-input disabled v-model="addData.name" placeholder="请输入线下供应商全称"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="联系人:" prop="linkUser">
                        <el-input disabled v-model="addData.linkUser" placeholder="请输入联系人"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系电话:" prop="linkPhone">
                        <el-input disabled v-model="addData.linkPhone" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="所属行业:" prop="industry">
                    <el-select disabled v-model="addData.industry" placeholder="请选择所属行业" clearable>
                        <el-option  v-for="(item, index) in optionsStry" :key="item.value" :label="item.label" :value="item.value" :value-key="index"></el-option>
                    </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="供应商类型:" prop="type">
                    <el-select disabled v-model="addData.type" placeholder="请选择供应商类型" clearable>
                        <el-option v-for="(item, index) in optionsType" :key="index" :label="item.label" :value="item.value" :value-key="index"></el-option>
                    </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="公司人数:" prop="peopleNum">
                        <el-input disabled v-model="addData.peopleNum" placeholder="请输入公司人数"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="主要供应商产品:" prop="product">
                        <el-input disabled v-model="addData.product" placeholder="请输入主要供应商产品"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="税种:" prop="taxType">
                        <el-input disabled v-model="addData.taxType" placeholder="请输入税种"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="税率" prop="taxRate">
                    <el-select disabled v-model="addData.taxRate" placeholder="请选择税率" clearable>
                        <el-option v-for="(item, index) in optionsRate" :key="index" :label="`${item.value}%`" :value="item.value" :value-key="index"></el-option>
                    </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="年收入（万元）:" prop="turnover">
                        <el-input  disabled v-model="addData.turnover" placeholder="请输入年收入"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="开户行:" prop="bankName">
                        <el-input disabled v-model="addData.bankName" placeholder="请输入开户行"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="开户账号:" prop="bankNo">
                        <el-input disabled v-model="addData.bankNo" placeholder="请输入开户账号"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="公司地址:" prop="address">
                        <el-cascader disabled v-model="addData.address" :options="cityData" :props="defaultData" @change="handleChange" placeholder="请选择省市区"></el-cascader>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="详细地址:" prop="addressInfo">
                        <el-input disabled v-model="addData.addressInfo" placeholder="请输入详细地址"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
          </div>
        </nav>
      </el-form>
    </div>
  </div>
</template>
<script>
import { offineSellerDetailApi, getUserManageApi, sellerListApi, getProvinceAllApi, siteListApi, addOffineSellerApi } from '@/api'
export default {
  data() {
    return {
      // 新增参数
      addData: {
        id: '',
        name: '',
        companyType: 0,
        linkUser: '',
        linkPhone: '',
        industry: '',
        taxRate: '',
        type: '',
        peopleNum: '',
        product: '',
        taxType: '',
        turnover: '',
        bankName: '',
        bankNo: '',
        address: '',
        addressInfo: ''
      },
      // 行业下拉数据
      optionsStry: [{
        value: '0',
        label: '汽车制造业'
      }, {
        value: '1',
        label: ' 3C产业'
      }, {
        value: '2',
        label: '橡胶及塑料工业'
      }, {
        value: '3',
        label: '食品饮料行业'
      }, {
        value: '4',
        label: '铸造行业'
      }, {
        value: '5',
        label: '化工行业'
      }, {
        value: '6',
        label: '冶金行业'
      }],
      // 供应商类型下拉数据
      optionsType: [{
        value: '1',
        label: ' 优选供应商'
      }, {
        value: '2',
        label: '合格供应商'
      }, {
        value: '3',
        label: '待淘汰供应商'
      }, {
        value: '4',
        label: '退出供应商'
      }],
      // 税率下拉数据
      optionsRate: [{
        value: 0
      }, {
        value: 3
      }, {
        value: 6
      }, {
        value: 9
      }, {
        value: 13
      }, {
        value: 16
      }],
      // 省市区下拉数据
      cityData: [],
      defaultData: {
        value: 'name',
        label: 'name',
        children: 'newChildList',
        expandTrigger: 'hover'
      },
      // 校验
      rules: {
        name: [
          { required: true, message: '供应商名称不能为空!', trigger: 'blur' }
        ],
        linkUser: [
          { required: true, message: '联系人不能为空!', trigger: 'blur' }
        ],
        linkPhone: [
          { required: true, message: '联系电话不能为空!', trigger: 'blur' }
        ]
      },
      // 供应商列表
      sellList: [],
      // 判断是不是转订单过来的
      showPlan: false
    }
  },
  created() {
    if (this.$route.query === {}) {
      this.addData.id = ''
    } else {
      this.addData.id = this.$route.query.result
    }
    this.getProvince()
    this.getDetailList()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 获取收货地址
    async getModuleList() {
      const { data: res } = await siteListApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.addressList = res.data
      // 获取默认地址
      this.addressList.forEach((item, index) => {
        if (item.def === 1) {
          this.addData.deliveryAddress = item.id
        }
      })
    },
    // 申请人列表
    async getUserManage() {
      const { data: res } = await getUserManageApi(this.userManageData)
      this.userManageList = res.data.page.list
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await sellerListApi({ search: '', page: 1, size: 9999, roleType: 1, status: 3 })
      this.sellList = res.data.page.list
    },
    // 打开添加弹框
    openAddDialog(val) {
      if (val === 1) {
        this.getMatterManageList()
        this.chooseOrderDialog = true
      } else {
        this.getPlanOrderList()
        this.choosePlanDialog = true
      }
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    },
    // 获取省市区信息
    async getProvince() {
      const { data: res } = await getProvinceAllApi()
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.cityData = res.data.list
      this.cityData.forEach((item, index) => {
        if (item.childList.length !== 0) {
          item.newChildList = item.childList
          item.newChildList.forEach((items, index) => {
            if (items.childList.length !== 0) {
              items.newChildList = items.childList
            }
          })
        }
      })
    },
    handleChange(value) {
      this.addData.address = value.join(',')
    },
    // 获取修改数据
    async getDetailList() {
      const { data: res } = await offineSellerDetailApi(this.addData.id)
      //   console.log(res)
      this.addData = res.data
      this.addData.address = res.data.address.split(',')
    },
    // 添加供应商
    async submitCompany() {
      console.log(this.addData.id)
      if (this.addData.id === '' || this.addData.id === undefined) {
        const { data: res } = await addOffineSellerApi(this.addData)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '添加成功!' })
        setTimeout(() => {
          this.$router.push('/offineSupplier')
        }, 300)
      } else {
        if (Array.isArray(this.addData.address)) {
          this.addData.address = this.addData.address.join(',')
          const { data: res } = await addOffineSellerApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '修改成功!' })
          setTimeout(() => {
            this.$router.push('/offineSupplier')
          }, 300)
        } else {
          const { data: res } = await addOffineSellerApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '修改成功!' })
          setTimeout(() => {
            this.$router.push('/offineSupplier')
          }, 300)
        }
      }
    },
    // 返回
    routerJump() {
      this.$router.back()
    }
    // -----------------------------------------------
  }
}
</script>
<style scoped lang='less'>
.addOffineSupplier {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addOffineSupplierContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 190px);
    border-radius: 10px;
    // overflow-y: auto;
    nav {
      margin-bottom: 2px;
      padding: 20px;
    //   height: 268px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .el-form-item .el-input {
            width: 100%;
        }

    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
}
</style>
