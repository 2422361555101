// 岗位权限
<template>
  <div class="matterManage">
    <nav>
      <h5>岗位权限</h5>
      <div>
        <el-button v-if="btnLimit.includes('新增岗位')" class="lightDeepStyle" @click="routerPush('')"><i class="el-icon-plus"></i>新增岗位权限</el-button>
        <!-- <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button> -->
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model="roleManageData.search" placeholder="请输入岗位权限名称" @input="getRoleList" prefix-icon="el-icon-search"></el-input>
    </section>
    <main>
      <el-table ref="tableHeight" :height="tableHeight" :data="roleData" style="width: 100%;">
        <el-table-column show-overflow-tooltip roleManage_content prop="numnberStr" label="岗位编码" min-width="160px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip roleManage_content prop="roleName" label="岗位名称" min-width="140px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip roleManage_content prop="parentName" label="上级岗位" min-width="140px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip roleManage_content prop="userCount" label="关联成员数" min-width="240px" align="left"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productUnit" label="状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <el-switch
                v-model="scope.row.state"
                :active-value="1"
                :inactive-value="0"
                active-color="rgba(18, 62, 131, 1)"
                inactive-color="#eee"
                @change="editRole(scope.row)">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" width="170px"  align="center">
          <template slot-scope="scope">
            <span class="addBtn" v-if="scope.row.parentId == 0" @click="openDialog(scope.row.roleId, scope.row.numnberStr)"><i style="margin-right: 3px;" class="el-icon-copy-document"></i>复制</span>
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item v-if="scope.row.roleName !== '管理员' && btnLimit.includes('编辑权限')" command="1">
                  <i class="el-icon-edit-outline"></i><span>权限编辑</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.roleName !== '管理员' && btnLimit.includes('复制岗位')" command="2">
                  <i class="el-icon-edit-outline"></i><span>复制</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.isOpen !== 1 && scope.row.roleName !== '管理员' && btnLimit.includes('删除岗位')" command="3">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="roleManageData.size"
        :total="total">
      </el-pagination>
    </main>
    <template>
      <el-dialog title="复制" :visible.sync="dialogVisible" :close-on-click-modal="false" v-dialogDrag>
        <div>
          <span>岗位权限编码: </span><el-input v-model="copyNum" disabled :placeholder="copyNum"></el-input>
        </div>
        <div>
          <span>岗位权限名称: </span><el-input v-model="copyName" placeholder="请输入岗位权限名称"></el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="copyUser()">确 定</el-button>
        </div>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import { getRoleListApi, delRoleApi, editRoleInfoApi, getRoleInfoApi, addRoleInfoApi } from '@/api'
export default {
  name: "matterManage",
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      isFirstEnter: false,
      // 获取当前浏览器的宽度
      screenWidth: '',
      roleData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 展示参数
      roleManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 50,
        // 合同名称
        search: ''
      },
      // 存储总的岗位列表数据
      postListData: [],
      // 复制弹出框
      dialogVisible: false,
      // 复制时的岗位权限ID
      copyId: '',
      // 复制时的岗位权限名称
      copyName: '',
      copyNum: '',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
    this.getRoleList()
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152;
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152;
      })()
    }
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routerPush(result.roleId)
      } else if (command === '2') {
        this.openDialog(result.roleId, result.numnberStr)
      } else if (command === '3') {
        this.delDefine(result.roleId)
      }
    },
    routerPush(roleId) {
      this.$router.push({ path: 'roleManageEdit', query: { roleId: roleId } })
    },
    // 获取角色列表
    async getRoleList(reset) {
      if (reset !== 'reset') {
        this.roleManageData.page = 1
      }
      const { data: res } = await getRoleListApi(this.roleManageData)
      // 获取表单数据
      this.roleData = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.roleManageData.size = val
      this.roleManageData.page = 1
      this.getRoleList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.roleManageData.page = val
      this.currentPage = val
      this.getRoleList('reset')
    },
    // 删除确认框
    delDefine(id) {
      this.$confirm('是否确认删除该岗位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await delRoleApi([id])
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getRoleList()
      }).catch(() => {
      })
    },
    // 更改角色状态
    async editRole(result) {
      const { data: res } = await editRoleInfoApi({ menuIdList: result.menuIdList, roleId: result.roleId, remark: result.remark, dept: result.dept, parentId: result.parentId, roleName: result.roleName, state: result.state })
      console.log(res, '玛卡巴卡', result)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (result.state === 1) {
        this.$message.success({ duration: 1000, message: '启用成功' })
      } else {
        this.$message.success({ duration: 1000, message: '停用成功' })
      }
    },
    openDialog(roleId, roleNum) {
      this.copyName = ''
      this.copyId = roleId
      this.copyNum = roleNum
      this.dialogVisible = true
    },
    // 复制用户
    async copyUser() {
      const { data: res } = await getRoleInfoApi(this.copyId)
      console.log(res, '666666666')
      let newUser = {
        menuIdList: res.data.role.menuIdList,
        remark: res.data.role.remark,
        roleName: this.copyName,
        // 上级岗位id
        parentId: res.data.role.parentId,
        roleType: window.sessionStorage.getItem('roleType'),
        // 存储权限菜单
        newMenuList: res.data.role.newMenuList,
        // 菜单
        menuDeptList: res.data.role.menuDeptList
      }
      this.addCopyUser(newUser)
    },
    // 新增复制用户
    async addCopyUser(newUser) {
      const { data: res } = await addRoleInfoApi(newUser)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '复制成功!' })
      this.dialogVisible = false
      this.getRoleList()
    }
  }
}
</script>
<style scoped lang='less'>
.matterManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .el-select {
      margin: 0 20px 0 0;
    }
    .el-cascader {
      margin: 0 20px 0 0;
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    .addBtn {
      position: absolute;
      left: 0;
      cursor: pointer;
    }
    .el-table .cell.el-tooltip {
      img {
        width: 60px;
        height: 60px;
        border-radius: 4px;
      }
    }
  }
}
</style>


