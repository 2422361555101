// 自选产品入库
<template>
  <div class="purchaseEntry">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/entryManage' }">入库单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">自选产品入库</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="purchaseEntryContent">
      <el-form ref="ruleForm" :model="addData" label-width="102px" :rules="rules">
        <nav>
          <h5>自选产品入库</h5>
            <div class="navBox">
              <el-form-item label="入库仓库:" prop="warehouseId">
                <el-select v-model="addData.warehouseId" placeholder="请选择入库仓库" size="mini" @change="chooseStoreHouse()">
                  <el-option v-for="item in storeHouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="入库时间:" prop="warrantTime">
                <el-date-picker v-model="addData.warrantTime" type="datetime" placeholder="请选择入库时间" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
              </el-form-item>
              <el-form-item label="入库形式:" prop="type">
                <el-select v-model="addData.type" placeholder="请选择入库形式" clearable size="mini">
                  <el-option label="采购入库" :value="0"></el-option>
                  <el-option label="手动入库" :value="1"></el-option>
                  <el-option label="盘盈入库" :value="2"></el-option>
                  <el-option label="转仓入库" :value="3"></el-option>
                </el-select>
              </el-form-item>
          </div>
        </nav>
        <main>
          <h5>入库单明细</h5>
          <el-button class="lightDeepStyle addBtn" @click="openAddDialog()"><i class="el-icon-plus"></i>新增产品</el-button>
          <el-button class="defaultStyle delBtn" @click="delMatter('all')">删除</el-button>
          <ul v-if="addData.warehouseWarrantLineVoList.length !== 0">
            <el-checkbox-group v-model="chooseList">
              <li v-for="(item, index) in addData.warehouseWarrantLineVoList" :key="index">
                <div class="liUp">
                  <p>
                    <el-checkbox :label="item.productId"><span>{{item.productName + '-' + (item.unit === null ? '暂无' : item.unit) + '-' + (item.productSpecs === null ? '暂无' : item.productSpecs) + '-' + (item.productNewColour === null ? '暂无' : item.productNewColour)}}</span></el-checkbox>
                    <i @click="delMatter(index)" class="el-icon-delete"></i>
                  </p>
                </div>
                <div class="liDown">
                  <el-form-item label="入库数量:" :prop="'warehouseWarrantLineVoList.' + index + '.quantity'" :rules="rules.quantity">
                    <el-input type="number" @input="formatNum(item.quantity, index)" oninput="if(value.length > 10) value=value.slice(0, 10)" v-model="item.quantity" placeholder="输入数值"></el-input>
                  </el-form-item>
                  <div class="showNumBox">入库后数量: {{addData.warehouseId === '' ? '自动读取' : item.quantityEnd + (item.quantity - 0)}}</div>
                </div>
              </li>
            </el-checkbox-group>
          </ul>
          <div v-else class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:30%; margin-top: -2%;">
            <span style="display: block; margin-top: -5%">暂无数据</span>
          </div>
        </main>
      </el-form>
      <!-- 采购单弹框 -->
      <el-dialog class="chooseOrderDialog" title="选择产品" :visible.sync="chooseOrderDialog" :close-on-click-modal="false" v-dialogDrag>
        <div>
          <el-input class="sectionInp" v-model="matterManageData.name" placeholder="产品编号/产品名称搜索" @input="getMatterManageList()" prefix-icon="el-icon-search"></el-input>
        </div>
        <el-table ref="matterSearchRef" :data="matterManageList" style="width: 100%" height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
          <el-table-column type="selection" width="45px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="code" label="产品编号" min-width="110px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="120px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.specs === '' || scope.row.specs === null ? '暂未填写' : scope.row.specs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="newColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.newColour === '' || scope.row.newColour === null ? '暂未填写' : scope.row.newColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productType === 0 ? '成品' : (scope.row.productType === 1 ? '半成品' : (scope.row.productType === 2 ? '物料' : '服务'))}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="unit" label="单位" min-width="80px" align="center"></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button class="defaultStyle cancleBtn" @click="chooseOrderDialog = false">取 消</el-button>
          <el-button class="lightDeepStyle" @click="chooseMatter()">确 认</el-button>
        </div>
      </el-dialog>
    </div>
    <footer>
      <el-button class="lightDeepStyle" @click="addEntry()">提交</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { addEntryApi, storeHouseListApi, matterListApi, houseMatterListApi } from '@/api'
export default {
  data() {
    return {
      // 控制弹框
      chooseOrderDialog: false,
      // 采购单传参
      orderData: '',
      addData: {
        warehouseId: '',
        type: '',
        sellId: '',
        warrantTime: '',
        warehouseWarrantLineVoList: []
      },
      // 选中的项
      chooseList: [],
      // 获取仓库列表参数
      storeHouseListData: {
        page: 1,
        // 当前页条数
        size: 9999,
        code: '',
        name: '',
        enabled: 1
      },
      // 仓库列表
      storeHouseList: [],
      // 传参参数
      matterManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        status: "1",
        typeId: "",
        name: "",
        codeAndName: "",
        productType: ""
      },
      // 产品列表
      matterManageList: [],
      // 选中的物料
      matterList: [],
      // 选中仓库的物料列表
      chooseHouseMatterList: [],
      // 校验
      rules: {
        warehouseId: [
          { required: true, message: '入库仓库不能为空!', trigger: 'blur' },
        ],
        warrantTime: [
          { required: true, message: '入库时间不能为空!', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '入库形式不能为空!', trigger: 'blur' }
        ],
        quantity: [
          { required: true, message: '入库数量不能为空!', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getStoreHouseList()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 新增入库单
    addEntry() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          // const { data: res } = await addMatterApi(this.matterData)
          // if (res.code !== 0) return this.$message.error(res.msg)
          // this.$message.success({ duration: 1000, message: '保存成功!' })
          const { data: res } = await addEntryApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('新增入库单成功!')
          setTimeout(() => {
            this.$router.back()
          }, 300)
        } else {
          return false
        }
      })
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.addData.warehouseWarrantLineVoList[index].quantity = temp;
    },
    // 选择仓库
    chooseStoreHouse() {
      // 每当切换仓库时重新计算入库后数量
      this.houseMatterList()
    },
    // 获取仓库物料列表
    async houseMatterList() {
      const { data: res } = await houseMatterListApi({ warehouseId: this.addData.warehouseId, warehouseName: '', productName: '', page: 1, size: 9999 })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.chooseHouseMatterList = res.data.page.list
      // 为列表添加对应的仓库库存数量
      if (this.addData.warehouseWarrantLineVoList.length === 0) return false
      this.addData.warehouseWarrantLineVoList.forEach((item, index) => {
        item.quantityEnd = 0
        this.chooseHouseMatterList.forEach((val, index) => {
          if (item.productId === val.productId) {
            // 存储入库前仓库数量
            item.quantityEnd = val.number
          }
        })
      })
    },
    // 删除产品
    delMatter(index) {
      if (index === 'all') {
        if (this.chooseList.length === 0) return this.$message.error('请先勾选需要删除的产品')
        this.$confirm('是否删除勾选产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.chooseList.forEach(porductId => {
            this.addData.warehouseWarrantLineVoList.forEach((val, index) => {
              if (porductId === val.productId) {
                this.addData.warehouseWarrantLineVoList.splice(index, 1)
              }
            })
          })
          // 清空选中项
          this.chooseList = []
        })
      } else {
        this.$confirm('是否确认删除当前产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.addData.warehouseWarrantLineVoList.splice(index, 1)
        })
      }
    },
    // 物料选择
    chooseMatter() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      this.matterList.forEach((item, index) => {
        let newData = {
          productId: item.id,
          productName: item.name,
          productNewColour: item.newColour,
          productSpecs: item.specs,
          productName: item.name,
          unit: item.unit,
          quantity: '',
          quantityEnd: 0
        }
        this.addData.warehouseWarrantLineVoList.push(newData)
      })
      // 选择后重新计算入库后数量
      this.houseMatterList()
      this.chooseOrderDialog = false
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    },
    // 获取产品列表
    async getMatterManageList() {
      const { data: res } = await matterListApi(this.matterManageData);
      if (res.code !== 0) return this.$message.error(res.msg)
      let newMatterManageList = res.data.page.list
      // 去除列表重复项
      if (this.addData.warehouseWarrantLineVoList.legnth !== 0) {
        this.addData.warehouseWarrantLineVoList.forEach(item => {
          newMatterManageList.forEach((val, index) => {
            if (item.productId === val.id) {
              newMatterManageList.splice(index, 1)
            }
          })
        })
      }
      this.matterManageList = newMatterManageList
    },
    // 获取仓库列表
    async getStoreHouseList() {
      const { data: res } = await storeHouseListApi(this.storeHouseListData)
      this.storeHouseList = res.data.page.list
    },
    // 打开添加弹框
    openAddDialog() {
      this.chooseOrderDialog = true
      this.getMatterManageList()
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseEntry {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .purchaseEntryContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 180px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 2px;
      padding: 20px;
      height: 166px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .navBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 50%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 102px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
          }
        }
        /deep/.el-form-item:nth-child(1) {
          width: 100%;
        }
      }
    }
    main {
      position: relative;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              color: rgba(0, 0, 0, 0.60);
            }
            >p {
              height: 24px;
              width: 100%;
              font-size: 14px;
              span {
                display: inline-block;
                width: 220px;
                color: rgba(0, 0, 0, 0.60);
              }
              i {
                float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 100%;
              .el-form-item__content {
                width: 100%;
                width: calc(100% - 102px);
                .el-input {
                  width: 100%;
                }
              }
            }
            .showNumBox {
              position: absolute;
              right: 10px;
              height: 32px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 12px;
              line-height: 32px;
              font-weight: 400;
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
