// 部门管理
<template>
  <div class="classManage">
    <nav>
      <h5><span @click="routerJump()">成员管理</span><span>部门管理</span></h5>
      <div>
        <el-button class="lightDeepStyle" @click="openDialog()"><i class="el-icon-plus"></i>添加部门</el-button>
      </div>
    </nav>
    <h5>{{companyName + ': ' + num + '人'}}</h5>
    <div class="mainBox">
      <main>
        <el-table ref="tableHeight" :height="tableHeight" :data="departList" style="width: 100%;" row-key="id" class="data-table" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :show-overflow-tooltip="true" :tree-props="{children:'childrenList'}">
          <el-table-column show-overflow-tooltip prop="" label="部门名称" min-width="120px">
            <template slot-scope="scope">
              <a @click="openDialog(scope.row)" style="color: rgba(18, 62, 131, 1);">{{scope.row.name}}</a>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="nickName" label="部门负责人" min-width="120px">
            <template slot-scope="scope">
              <span>{{scope.row.nickName === null ? '暂无' : scope.row.nickName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="mobile" label="手机号" min-width="120px">
            <template slot-scope="scope">
              <span>{{scope.row.mobile === null ? '暂无' : scope.row.mobile}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="userCount" label="人数" min-width="120px"></el-table-column>
          <el-table-column show-overflow-tooltip label="操作" width="300px" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.noShow != true" class="addBtn" @click="addChildrenClass(scope.row.id, 1)"><i style="margin-right: 8px;" class="el-icon-plus"></i>新增下级部门</span>
              <span class="addBtn" @click="addChildrenClass(scope.row.parentId, 2)"><i style="margin-right: 8px;" class="el-icon-plus"></i>新增平级部门</span>
              <el-dropdown @command="chooseCommand($event, scope.row)">
                <span>
                  <i style="transform: rotate(90deg)" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="dropdownUl">
                  <el-dropdown-item command="1">
                    <i class="el-icon-edit-outline"></i><span>编辑</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="2">
                    <i class="el-icon-delete"></i><span>删除</span>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item v-if="scope.row.isActivate === 1" command="4">
                    <i class="el-icon-delete"></i><span>离职交接</span>
                  </el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
      </main>
    </div>
    <template>
      <div>
        <el-dialog class="changeDialog" :title="departData.id === '' ? '添加部门' : '修改部门'" :visible.sync="editDialog" :close-on-click-modal="false" v-dialogDrag>
          <!-- 添加部门表单 -->
          <el-form :model="departData" :rules="rules" ref="ruleForm">
            <el-form-item label="部门名称:" prop="name">
              <el-input placeholder="输入部门名称" v-model="departData.name"></el-input>
            </el-form-item>
            <el-form-item label="上级部门:" prop="parentId">
              <el-cascader @change="handleExportCatalogChange" ref="catalogue" :options="departList" :props="defaultData" clearable v-model="departData.parentId" placeholder="请选择部门(没有则不填)"></el-cascader>
            </el-form-item>
            <el-form-item v-if="departData.id && departData.id !== ''" label="部门负责人:" prop="principalUserId">
              <el-select v-model="departData.principalUserId" placeholder="请选择部门负责人" size="mini">
                <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="editDialog = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="addDepartment()">保存</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
  </div>
</template>
<script>
import { departmentListApi, getUserManageApi, addDepartmentApi, delDepartmentApi } from '@/api'
export default {
  name: 'classManage',
  data() {
    return {
      companyName: JSON.parse(window.sessionStorage.getItem('userInfo')).company,
      companyId: JSON.parse(window.sessionStorage.getItem('userInfo')).companyId,
      // 部门列表
      departList: [],
      departData: {
        id: '',
        name: '', // 姓名
        parentId: '', // 上级部门Id
        principalUserId: '', // 负责人Id 修改时传
      },
      // 控制弹出框
      editDialog: false,
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'childrenList2',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false,
        checkStrictly: true
      },
      // 成员列表
      userList: [],
      // 传参参数
      userData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 999,
        // 搜索内容
        name: '',
        deptId: '',
        companyId: window.$cookies.get('companyId'),
        state: '',
        isActivate: ''
      },
      // 总人数
      num: 0,
      // 列表动态高度
      tableHeight: '',
      // 校验
      rules: {
        name: [
          { required: true, message: '部门名称不能为空!', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
  },
  mounted() {
    this.getDepartmentList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 102
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 102
      })()
    }
  },
  methods: {
    // 打开部门弹框
    openDialog(item) {
      // 清空默认值
      this.departData = {
        id: '',
        name: '', // 姓名
        parentId: '', // 上级部门Id
        principalUserId: '', // 负责人Id 修改时传
      }
      if (item) {
        this.departData = {
          id: item.id,
          name: item.name, // 姓名
          parentId: item.parentId, // 上级部门Id
          principalUserId: item.principalUserId, // 负责人Id 修改时传
        }
        if (this.departData.principalUserId == 0) {
          this.departData.principalUserId = ''
        }
        this.userData.deptId = this.departData.id
        this.getUserList()
      }
      this.editDialog = true
    },
    // 关闭多级联动框
    handleExportCatalogChange(val) {
      this.$refs.catalogue.dropDownVisible = false
    },
    // 获取部门列表
    async getDepartmentList() {
      const { data: res } = await departmentListApi('')
      if (res.code !== 0) return this.$message.error(res.msg)
      this.departList = res.data
      this.num = 0
      // 处理子部门
      this.departList.forEach((item, index) => {
        // 获取总人数
        this.num = this.num + item.userCount
        if (item.childList && item.childList.length !== 0) {
          item.childrenList = item.childList
          item.childrenList2 = item.childList
          item.childrenList.forEach((items, index) => {
            if (items.childList && items.childList.length !== 0) {
              items.childrenList = item.childList
              items.childrenList.forEach((itemss, index) => {
                if (itemss.childList && itemss.childList.length !== 0) {
                  itemss.childrenList = itemss.childList
                  itemss.childrenList.forEach((val, k) => {
                    val.noShow = true
                  })
                }
              })
            }
          })
        }
      })
      console.log(this.departList, '999999')
    },
    // 获取用户列表(成员列表)
    async getUserList() {
      const { data: res } = await getUserManageApi(this.userData)
      // 获取表单数据
      this.userList = res.data.page.list
    },
    // 新增修改部门
    async addDepartment() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const { data: res } = await addDepartmentApi(this.departData)
          if (res.code !== 0) return this.$message.error(res.msg)
          if (this.departData.id === '') {
            this.$message.success('新增成功!')
          } else {
            this.$message.success('修改成功!')
          }
          this.editDialog = false
          this.getDepartmentList()
        } else {
          return false
        }
      })
    },
    // 新增下级平级部门
    addChildrenClass(id, val) {
      // 清除默认值
      this.departData = {
        id: '',
        name: '', // 姓名
        parentId: '', // 上级部门Id
        principalUserId: '', // 负责人Id 修改时传
      }
      this.departData.parentId = id
      this.editDialog = true
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.openDialog(result)
      } else if (command === '2') {
        this.delDepartment(result)
      }
    },
    // 路由跳转
    routerJump() {
      this.$router.push('./memberAndClass')
    },
    // 删除部门
    async delDepartment(value) {
      if (value.userCount == 0) {
        this.$confirm('部门里成员为<i style="margin-left: 3px; color: rgba(18, 62, 131, 1)">0</i>，可以删除。', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
          dangerouslyUseHTMLString: true
        }).then(async() => {
          const { data: res } = await delDepartmentApi(value.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '删除部门成功!' })
          this.getDepartmentList()
        })
      } else {
        this.$confirm('部门还有<i style="margin-left: 3px; color: rgba(18, 62, 131, 1)">' + value.userCount +'</i>个成员，无法进行删除操作<br>需要部门成员为<i style="margin-left: 3px; color: rgba(131, 18, 18, 1)">0</i>才能删除部门!', '删除提示', {
          confirmButtonText: '关闭',
          type: 'warning',
          closeOnClickModal: false,
          showCancelButton: false,
          dangerouslyUseHTMLString: true
        })
      }
    }
  }
}
</script>
<style scoped lang='less'>
.classManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
      span {
        padding: 5px 0;
        font-weight: 400;
        cursor: pointer;
      }
      span:nth-child(2) {
        margin-left: 20px;
        border-bottom: 3px solid rgba(18, 62, 131, 1);
        font-weight: bold;
      }
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  >h5 {
    margin: 0 20px 20px;
    font-size: 16px;
    font-weight: bold;
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 186px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 186px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  .mainBox {
    padding: 0 20px;
    main {
      width: 100%;
      /deep/ .el-table .cell.el-tooltip {
        display: flex;
        justify-content: flex-start;
      }
      .data-table {
        // 固定表格高度
        /deep/td {
          padding-left: 20px!important;
          height: 48px;
          padding: 0;
        }
        /deep/th {
          padding-left: 20px!important;
        }
      }
      .addBtn {
        width: 125px;
        cursor: pointer;
      }
      .el-table .el-button {
        padding: 0;
        width: 52px;
        height: 22px;
        background: rgba(239, 188, 96, 0.05);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(239, 188, 96, 0.20);
        font-size: 12px;
        line-height: 0;
        color: rgba(239, 188, 96, 1);
        cursor: default;
        text-align: center;
      }
      .el-table .joined {
        background: rgba(70,147,235,0.05);
        border: 1px solid rgba(70,147,235,0.2);
        color: rgba(70, 147, 235, 1);
      }
      .el-table .joining {
        background: rgba(70,147,235,0.05);
        border: 1px solid rgba(70,147,235,0.2);
        color: rgba(70, 147, 235, 1);
      }
    }
  }
  .changeDialog {
    /deep/ .el-dialog {
      height: 408px;
      width: 424px;
      .el-dialog__body {
        height: 288px;
        .el-input {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
  /deep/.data-table-header {
    height: 60px !important;
  }
  /*1.取消原本展开的旋转动效*/
  /deep/.el-table__expand-icon {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  /*2.展开按钮未点击的样式是加号带边框*/
  /deep/.el-table__expand-icon .el-icon-arrow-right:before {
    content: "\e6d9";
    border: 1px solid #ccc;
    padding: 1px;
  }
  /*2.按钮已点击展开之后的样式是减号带边框*/
  /deep/.el-table__expand-icon--expanded .el-icon-arrow-right:before {
    content: "\e6d8";
    -left: 20px;
  }
}
</style>
