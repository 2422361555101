// 竞价详情
<template>
  <div class="askPricePurchaseDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/competePriceSell' }">竞价报价</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump()"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="askPricePurchaseDetailContent">
      <header>
        <div>
          <h5>{{askPricePurchaseDetail.name + ' (' + askPricePurchaseDetail.code + ')'}}</h5>
          <p v-if="askPricePurchaseDetail.createTime">发布日期: <span>{{(askPricePurchaseDetail.createTime).substring(0, 16)}}({{askPricePurchaseDetail.createName === null ? '暂无' : askPricePurchaseDetail.createName}})</span>
            | 竞价状态:
            <span style="color: rgba(59, 212, 144, 1)" v-if="askPricePurchaseDetail.state === 0">草稿</span>
            <span style="color: rgba(59, 212, 144, 1)" v-else-if="askPricePurchaseDetail.state === 1">待开始</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="askPricePurchaseDetail.state === 2">进行中</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.state === 3">已结束</span>
            | 报价状态: 
            <span style="color: rgba(0, 0, 0, 0.60)" v-if="askPricePurchaseDetail.ofState == 1">待开始</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="askPricePurchaseDetail.ofState == 2">待报价</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.ofState == 3">已报价</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.ofState == 4">报价已通过</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="askPricePurchaseDetail.ofState == 5">报价未通过</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.ofState == 6">已转单</span>
            <span style="color: rgba(0, 0, 0, 0.60)" v-else-if="askPricePurchaseDetail.ofState == 7">已取消</span>
            <span style="color: rgba(0, 0, 0, 0.60)" v-else-if="askPricePurchaseDetail.ofState == 8">已过期</span>
            <span style="color: rgba(0, 0, 0, 0.60)" v-else-if="askPricePurchaseDetail.ofState == 9">已结束</span>
          </p>
        </div>
        <div class="timeBox">
          <div>
            <span>倒计时</span>
            <p>{{askPricePurchaseDetail.countDown === null ? '未开始' : askPricePurchaseDetail.countDown}}</p>
          </div>
          <i></i>
          <div>
            <span>总{{askPricePurchaseDetail.numberRound}}轮</span>
            <p>第{{askPricePurchaseDetail.nowRound}}轮</p>
          </div>
        </div>
      </header>
      <main>
        <h6>供应商要求: 
          <span class="stateBox2">{{askPricePurchaseDetail.invoiceType === 1 ? '不需要发票' : (askPricePurchaseDetail.invoiceType === 2 ? '增值税普通发票' : '增值税专用发票')}}</span>
          <span class="stateBox2">{{askPricePurchaseDetail.provinceName == '' ? '暂无' : (askPricePurchaseDetail.provinceName == null ? '' : askPricePurchaseDetail.provinceName) + (askPricePurchaseDetail.cityName == null ? '' : askPricePurchaseDetail.cityName) + (askPricePurchaseDetail.areaName == null ? '' : askPricePurchaseDetail.areaName)}}</span>
          <span class="stateBox2">{{askPricePurchaseDetail.payType === 1 ? '现金' : '转账'}}</span>
        </h6>
      </main>
      <main>
        <p>联系方式: <span v-if="askPricePurchaseDetail.peoplePhone">{{askPricePurchaseDetail.peoplePhone}}({{askPricePurchaseDetail.peopleName}})</span>
          | 报价日期: <span v-if="askPricePurchaseDetail.beginTime && askPricePurchaseDetail.endTime">{{(askPricePurchaseDetail.beginTime).substring(0, 10) + ' - ' + (askPricePurchaseDetail.endTime).substring(0, 10)}}</span><span v-else>暂无</span>
          | 报价方式: <span>{{askPricePurchaseDetail.offerTypeName}}</span>
          | 报价要求: <span>{{askPricePurchaseDetail.offerAskName}}</span>
          | 收货地址: <span>{{askPricePurchaseDetail.deliveryAddressName}}</span>
        </p>
      </main>
      <main>
        <p>补充说明: <span>{{askPricePurchaseDetail.remark === '' ? '暂无' : askPricePurchaseDetail.remark}}</span></p>
      </main>
      <main style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
        <p>附件: 
          <span style="margin-right: 20px;">{{askPricePurchaseDetail.filesEntityList.length}}</span>
          <el-button @click="openFileDialog(askPricePurchaseDetail.filesEntityList)" class="defaultStyle">查看</el-button>
        </p>
      </main>
      <footer>
        <h6 style="font-size: 14px;">
          <p>产品信息({{askPricePurchaseDetail.enquiryLineEntityList.length}})</p>
        </h6>
        <p v-if="askPricePurchaseDetail.offerType == 2">
          <span>总金额(不含税): {{askPricePurchaseDetail.offerEntity[roundNum][0].money}}</span>
          <span v-if="askPricePurchaseDetail.offerAsk === 1">税率: {{askPricePurchaseDetail.offerEntity[roundNum][0].taxRate}}%</span>
          <span v-if="askPricePurchaseDetail.offerAsk === 1">总金额(含税): {{askPricePurchaseDetail.offerEntity[roundNum][0].moneyTax}}</span>
          <span>说明: {{askPricePurchaseDetail.offerEntity[roundNum][0].remark === null ? '暂无' : askPricePurchaseDetail.offerEntity[roundNum][0].remark}}</span>
        </p>
        <div class="roundTab" :style="'width: ' + (askPricePurchaseDetail.offerEntity.length) * 100 + 'px'">
          <div class="roundBox" @click="roundNum = index" :class="roundNum === index ? 'highlight' : ''" :style="index === askPricePurchaseDetail.offerEntity -  1 ? 'border-right: 0;' : ''" v-for="(item, index) in askPricePurchaseDetail.offerEntity" :key="index">
            第{{index + 1}}轮
          </div>
        </div>
        <el-table ref="tableHeight" v-if="askPricePurchaseDetail.offerEntity[roundNum][0].offerLineEntityList.length !== 0" :data="askPricePurchaseDetail.offerEntity[roundNum][0].offerLineEntityList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="productCode" label="产品编码" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productCode === '' || scope.row.productCode === null ? '暂无' : scope.row.productCode}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="number" label="采购数量" min-width="80px"  align="center">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.number}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productUnitName" label="单位" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productUnitName === '' || scope.row.productUnitName === null ? '暂无' : scope.row.productUnitName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="要求到货时间" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.arriveTime">{{(scope.row.arriveTime).substring(0, 16)}}</span>
                <span v-else>-</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="产品描述" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.productDescribes === null ? '暂无' : scope.row.productDescribes}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType == 1 && askPricePurchaseDetail.offerAsk == 1" key="1" show-overflow-tooltip prop="priceTax" label="单价(含税)" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.priceTax === null || scope.row.priceTax === '' ? '-' : scope.row.priceTax}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType == 1 && askPricePurchaseDetail.offerAsk == 1" key="2" show-overflow-tooltip prop="moneyTax" label="合价(含税)" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.moneyTax === null || scope.row.moneyTax === '' ? '-' : scope.row.moneyTax}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType == 1" key="3" show-overflow-tooltip prop="price" label="单价(不含税)" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.price === null || scope.row.price === '' ? '-' : scope.row.price}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType == 1" key="4" show-overflow-tooltip prop="money" label="合价(不含税)" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.money === null || scope.row.money === '' ? '-' : scope.row.money}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span @click="openFileDialog(scope.row.filesEntityList)" style="cursor: pointer;"><i style="margin-right: 3px;" class="el-icon-search"></i>查看附件</span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
        <el-table ref="tableHeight" v-else :data="askPricePurchaseDetail.enquiryLineEntityList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="productCode" label="产品编码" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productCode === '' || scope.row.productCode === null ? '暂无' : scope.row.productCode}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="number" label="采购数量" min-width="80px"  align="center">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.number}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productUnitName" label="单位" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productUnitName === '' || scope.row.productUnitName === null ? '暂无' : scope.row.productUnitName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="要求到货时间" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.arriveTime">{{(scope.row.arriveTime).substring(0, 16)}}</span>
                <span v-else>-</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="产品描述" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.productDescribes === null ? '暂无' : scope.row.productDescribes}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span @click="openFileDialog(scope.row.filesEntityList)" style="cursor: pointer;"><i style="margin-right: 3px;" class="el-icon-search"></i>查看附件</span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
      </footer>
    </div>
    <!-- 查看附件 -->
    <el-dialog class="uploadDialog" title="查看附件" :visible.sync="uploadDialog" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <ul>
          <li style="margin-top: 10px; cursor: pointer;" v-for="(item, index) in fileList" :key="index">
            <a :href="item.url">{{item.fileName}}<span style="float:right;">附件下载</span></a>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="uploadDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { askPriceSellDetailApi, endAskPriceApi, quoteListApi, approvalAskPriceApi, cancelAskPriceApi, beginAskPriceApi, delAskPriceApi } from '@/api'
export default {
  data() {
    return {
      // 选中的轮次 - 1
      roundNum: 0,
      // 附件列表
      fileList: [],
      // 附件弹框
      uploadDialog: false,
      // 报价记录选择的公司
      chooseCompany: 0,
      id: '',
      // 获取竞价单详情
      askPricePurchaseDetail: '',
    }
  },
  created() {
    this.id = JSON.parse(this.$route.query.result)
    this.getAskPricePurchaseDetail()
  },
  methods: {
    // 打开附件弹框
    openFileDialog(file) {
      this.fileList = file
      this.uploadDialog = true
    },
    // 获取竞价详情
    async getAskPricePurchaseDetail() {
      const { data: res } = await askPriceSellDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.askPricePurchaseDetail = res.data.data
      this.roundNum = this.askPricePurchaseDetail.nowRound - 1
    },
    routerJump() {
      this.$router.back()
    }
  }
};
</script>
<style lang="less" scoped>
.askPricePurchaseDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .askPricePurchaseDetailContent {
    margin-top: 40px;
    .stateBox1, .stateBox2, .stateBox3, .stateBox4 {
      display: inline-block;
      padding: 0 10px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      line-height: 22px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
    .stateBox4 {
      cursor: pointer;
      background-color: rgba(70, 147, 235, 1);
      border-color: rgba(70, 147, 235, 1);
      color: rgba(255, 255, 255, 1);
    }
    header {
      position: relative;
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          i {
            margin-left: 20px;
            display: inline-block;
            width: 52px;
            height: 22px;
            background: rgba(70,147,235,0.05);
            border-radius: 4px;
            border: 1px solid rgba(70,147,235,0.2);
            font-size: 12px;
            font-weight: 400;
            color: rgba(70, 147, 235, 1);
            text-align: center;
            line-height: 22px;
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
      .timeBox {
        position: absolute;
        top: 10px;
        right: 20px;
        display: flex;
        width: 260px;
        height: 70px;
        background-color: rgba(18, 62, 131, 0.05);
        border: 1px solid rgba(18, 62, 131, 0.20);
        border-radius: 10px;
        text-align: center;
        >div {
          width: 129px;
          height: 70px;
          span {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.60);
            font-weight: bold;
          }
          p {
            margin-top: 0;
            font-size: 24px;
            font-weight: bold;
            color: rgba(18, 62, 131, 1);
          }
        }
        i {
          margin-top: 10px;
          width: 2px;
          height: 50px;
          background-color: rgba(18, 62, 131, 1);
        }
      }
    }
    main {
      margin: 2px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        >span {
          margin: 0 10px;
        }
      }
      p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
    }
    footer {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        p {
          margin-right: 20px;
          padding-bottom: 5px;
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 25px;
        }
        .highlight {
          border-bottom: 3px solid rgba(18, 62, 131, 1);
        }
      }
      >p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        span {
          margin-right: 15px;
        }
      }
      .roundTab {
        margin: 20px 0;
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: hidden;
        .roundBox {
          margin-right: -1px;
          width: 100px;
          height: 32px;
          border-right: 1px solid rgba(0, 0, 0, 0.25);
          cursor: pointer;
          color: rgba(0, 0, 0, 0.60);
          line-height: 32px;
          text-align: center;
        }
        .highlight {
          background-color: rgba(18, 62, 131, 1);
          color: rgba(255, 255, 255, 1);
        }
      }
      .priceRatioBox {
        ul {
          margin: 20px 0 0;
          display: flex;
          height: 124px;
          li {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            box-sizing: border-box;
          }
          li:nth-child(1) {
            position: relative;
            width: 480px;
            border: 1px solid rgba(0, 0, 0, 0.06);
            span:nth-child(1) {
              position: absolute;
              left: 20px;
              bottom: 20px;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.80);
              font-weight: bold;
            }
            span:nth-child(3) {
              position: absolute;
              top: 20px;
              right: 20px;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.80);
              font-weight: bold;
            }
            i {
              position: absolute;
              top: 62px;
              left: -8px;
              width: 103%;
              height: 1px;
              background-color: rgba(0, 0, 0, 0.15);
              transform: rotate(13.5deg)
            }
          }
          li:nth-child(2), li:nth-child(4), li:nth-child(6), li:nth-child(8), li:nth-child(10) {
            padding: 20px;
            width: 480px;
            background-color: rgba(18, 62, 131, 0.05);
            border: 1px solid rgba(18, 62, 131, 0.05);
            h6 {
              font-size: 18px;
            }
            p:nth-child(2) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                margin-right: 60px;
              }
            }
            p:nth-child(3) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                color: rgba(0, 0, 0, 0.40);
              }
            }
          }
          li:nth-child(3), li:nth-child(5), li:nth-child(7), li:nth-child(9), li:nth-child(11) {
            padding: 20px;
            width: 480px;
            background-color: rgba(0, 0, 0, 0.02);
            border: 1px solid rgba(0, 0, 0, 0.02);
            h6 {
              font-size: 18px;
            }
            p:nth-child(2) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                margin-right: 60px;
              }
            }
            p:nth-child(3) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                color: rgba(0, 0, 0, 0.40);
              }
            }
          }
        }
        .tableBox {
          display: flex;
          justify-content: flex-start;
          /deep/ .el-table:nth-child(2), /deep/ .el-table:nth-child(4), /deep/ .el-table:nth-child(6), /deep/ .el-table:nth-child(8), /deep/ .el-table:nth-child(10) {
            tr {
              background-color: rgba(18, 62, 131, 0.05);
            }
          }
          /deep/ .el-table:nth-child(3), /deep/ .el-table:nth-child(5), /deep/ .el-table:nth-child(7), /deep/ .el-table:nth-child(9), /deep/ .el-table:nth-child(11) {
            tr {
              background-color: rgba(18, 62, 131, 0.02);
            }
          }
        }
      }
      .recordBox {
        display: flex;
        justify-content: space-between;
        ul {
          margin: 20px 20px 0 0;
          width: 440px;
          li {
            margin-bottom: 10px;
            padding: 20px;
            width: 100%;
            background-color: rgba(18, 62, 131, 0.05);
            box-sizing: border-box;
            border-radius: 10px;
            cursor: pointer;
            h6 {
              font-size: 18px;
            }
            p:nth-child(2) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                margin-right: 60px;
              }
            }
            p:nth-child(3) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                color: rgba(0, 0, 0, 0.40);
              }
            }
          }
          .highlight {
            background-color: rgba(18, 62, 131, 1);
            h6 {
              color: #fff!important;
            }
            p {
              color: #fff!important;
              span {
                color: #fff!important;
              }
            }
          }
        }
      }
    }
  }
}
</style>