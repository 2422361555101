// 新增线下采购单
<template>
  <div class="addPurchaseOrder">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/offinePurchasing' }">线下采购单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">新增线下采购单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addPurchaseOrderContent">
      <el-form ref="ruleForm" :model="addData" label-width="117px" :rules="rules">
        <nav>
          <h5>新增线下采购单</h5>
          <!-- <h6>线下采购单编号: 系统自动生成</h6> -->
          <div class="navBox">
            <el-form-item label="线下采购单编号:" prop="code">
                <el-input v-model="addData.code" placeholder="自动生成（可输入）"></el-input>
            </el-form-item>
            <el-form-item label="线下供应商:" prop="sellerId">
              <el-select v-model="addData.sellerId" placeholder="请选择供应商" clearable size="mini">
                <el-option v-for="(item, index) in sellList" :key="index" :label="item.name" :value="item.id" :value-key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单类型" prop="orderType">
              <el-select v-model="addData.orderType" placeholder="请选择订单类型">
                <el-option label="原材料采购" :value="0"></el-option>
                <el-option label="外协生产订单" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签订人" prop="signer">
              <el-select v-model="addData.signer" placeholder="请选择签订人" clearable>
                <el-option v-for="(item, index) in userManageList" :key="index" :label="item.name" :value="item.id" :value-key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="送货方式" prop="deliveryType">
              <el-select v-model="addData.deliveryType" placeholder="请选择送货方式">
                <el-option label="自提" :value="0"></el-option>
                <el-option label="供方送货" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收货地址" prop="address">
              <el-select v-model="addData.address" placeholder="请选择收货地址">
                <el-option v-for="(item, index) in addressList" :key="index" :label="item.province + item.city + item.district + item.area" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </nav>
        <main>
          <h5>订单明细</h5>
          <el-button v-if="showPlan === false" class="lightDeepStyle addBtn" @click="openAddDialog(1)"><i class="el-icon-plus"></i>添加产品</el-button>
          <el-button v-if="showPlan === true" class="lightDeepStyle addBtn" @click="openAddDialog(2)"><i class="el-icon-plus"></i>添加采购计划</el-button>
          <el-button class="defaultStyle delBtn" @click="delMatter('all')">删除</el-button>
          <ul v-if="addData.allProductLine.length !== 0">
            <el-checkbox-group v-model="chooseList">
              <li :style="index + 1 === addData.allProductLine.length ? 'border-bottom: 0' : ''" v-for="(item, index) in addData.allProductLine" :key="index">
                <div class="liUp">
                  <p>
                    <el-checkbox :label="item.productId"><span class="textHidden">{{item.productNo + '-' + item.productName + '-' + (item.productUnit === null ? '暂无' : item.productUnit) + '-' + (item.productSpec === null ? '暂无' : item.productSpec) + '-' + (item.productNewColour === null ? '暂无' : item.productNewColour)}}</span><span v-if="showPlan === true">需求数量: {{item.needNumber}}</span><span v-if="showPlan === true">剩余数量: {{item.planResidueNumber}}</span></el-checkbox>
                    <i @click="delMatter(index)" class="el-icon-delete"></i>
                  </p>
                </div>
                <div class="liDown">
                  <el-form-item label="本次订购数量:" :prop="'allProductLine.' + index + '.num'" :rules="rules.num">
                    <el-input type="num" @input="formatNum(item.num, index, item.planResidueNumber)" oninput="if(value.length > 10) value=value.slice(0, 10)" v-model="item.num" placeholder="输入数值"></el-input>
                  </el-form-item>
                  <el-form-item label="单价:" :prop="'allProductLine.' + index + '.price'" :rules="rules.price">
                    <el-input type="num" @input="formatNum2(item.price, index, '')" oninput="if(value.length > 10) value=value.slice(0, 10)" v-model="item.price" placeholder="输入数值"></el-input>
                    <i v-if="item.num === '' || item.price === ''">单品总价: 自动读取</i>
                    <i class="textHidden" v-else >单品总价: <span> {{((item.num * 100) * (item.price * 100) / 10000).toFixed(2)}}</span> </i>
                  </el-form-item>
                  <el-form-item label="要求到货时间:">
                    <el-date-picker :picker-options="pickerOptions" v-model="item.arrivalTime" type="date" placeholder="到货日期选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
                  </el-form-item>
                </div>
              </li>
            </el-checkbox-group>
          </ul>
          <div v-else class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:27%; margin-top: -2%;">
            <span style="display: block; margin-top: -5%">暂无数据</span>
          </div>
        </main>
      </el-form>
      <!-- 采购计划单弹框 -->
      <el-dialog class="choosePlanDialog" title="选择产品" :visible.sync="choosePlanDialog" :close-on-click-modal="false" v-dialogDrag>
        <div>
          <el-input class="sectionInp" v-model="matterManageData.selectAll" placeholder="计划单编号/产品编号/产品名称搜索" @input="getPlanOrderList()" prefix-icon="el-icon-search"></el-input>
        </div>
        <el-table ref="matterSearchRef" :data="matterManageList" style="width: 100%" height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
          <el-table-column type="selection" width="45px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="planCode" label="计划单编号" min-width="110px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productCode" label="产品编号" min-width="110px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="130px" align="center"></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button class="defaultStyle cancleBtn" @click="choosePlanDialog = false">取 消</el-button>
          <el-button class="lightDeepStyle" @click="choosePlanMatter()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 线下采购单弹框 -->
      <el-dialog class="chooseOrderDialog" title="选择产品" :visible.sync="chooseOrderDialog" :close-on-click-modal="false" v-dialogDrag>
        <div>
          <el-input class="sectionInp" v-model="matterData.codeAndName" placeholder="产品编号/产品名称搜索" @input="getMatterManageList()" prefix-icon="el-icon-search"></el-input>
        </div>
        <el-table ref="matterSearchRef" :data="matterManageList" style="width: 100%" max-height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
          <el-table-column type="selection" width="45px" align="center" fixed="left"></el-table-column>
          <el-table-column show-overflow-tooltip prop="code" label="产品编号" min-width="110px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="120px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.specs === '' || scope.row.specs === null ? '暂未填写' : scope.row.specs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="newColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.newColour === '' || scope.row.newColour === null ? '暂未填写' : scope.row.newColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productType === 0 ? '成品' : (scope.row.productType === 1 ? '半成品' : (scope.row.productType === 2 ? '物料' : '服务'))}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="unit" label="单位" min-width="130px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.unit === null ? '暂未填写' : scope.row.unit}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button class="defaultStyle cancleBtn" @click="chooseOrderDialog = false">取 消</el-button>
          <el-button class="lightDeepStyle" @click="chooseMatter()">确 认</el-button>
        </div>
      </el-dialog>
    </div>
    <footer>
      <el-button class="lightDeepStyle" @click="addPurchaseOrder()">提交</el-button>
      <!-- <el-button class="lightDeepStyle" @click="addPurchaseOrder(1)">提交</el-button> -->
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { addOffinePuiuchaseApi, submitPurchaseOrderApi, getPlanOrderListApi, getUserManageApi, offineSellerApi, matterListApi, siteListApi } from '@/api'
export default {
  data() {
    return {
      // 收货地址
      addressList: [],
      // 日期选择器限制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 8.64e7
        }
      },
      // 控制弹框
      choosePlanDialog: false,
      chooseOrderDialog: false,
      // 新增参数
      addData: {
        code: '',
        sellerId: '',
        orderType: '',
        signer: '',
        deliveryType: '',
        address: '',
        orderStatus: 0,
        allProductLine: []
      },
      // 签订人列表传参
      userManageData: {

        // 当前页
        page: 1,
        // 当前页条数
        size: 999,
        // 搜索内容
        name: '',
        companyId: window.$cookies.get('companyId'),
        state: '1',
        isActivate: '1',
        deptId: ''
      },
      // 签订人列表
      userManageList: [],
      // 选中的项
      chooseList: [],
      // 计划单对应物料传参
      matterManageData: {
        page: 1,
        size: 100,
        productName: '',
        selectAll: '',
        planIds: '',
        approveStatus: 2
      },
      // 传参参数
      matterData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        status: '1',
        typeId: '',
        name: '',
        codeAndName: '',
        productType: ''
      },
      // 产品列表
      matterManageList: [],
      /// ----------------------------------------------
      // 选中的物料
      matterList: [],
      // 校验
      rules: {
        price: [
          { required: true, message: '单价不能为空!', trigger: 'blur' }
        ],
        num: [
          { required: true, message: '本次订购数不能为空!', trigger: 'blur' }
        ],
        sellerId: [
          { required: true, message: '供应商不能为空!', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '订单类型不能为空!', trigger: 'blur' }
        ],
        proposerId: [
          { required: true, message: '签订人不能为空!', trigger: 'blur' }
        ],
        deliveryType: [
          { required: true, message: '送货方式不能为空!', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '收货地址不能为空!', trigger: 'blur' }
        ]
      },
      // 供应商列表
      sellList: [],
      // 判断是不是转订单过来的
      showPlan: false
    }
  },
  created() {
    this.addData.signer = JSON.parse(sessionStorage.getItem('userInfo')).infoId
    if (this.$route.query.result !== undefined) {
      this.matterList = JSON.parse(this.$route.query.result)
      this.choosePlanMatter()
      this.showPlan = true
    } else {
      this.showPlan = false
    }
    this.sellerList()
    this.getModuleList()
  },
  mounted() {
    this.getUserManage()
  },
  activated () {
  },
  methods: {
    // 获取收货地址
    async getModuleList() {
      const { data: res } = await siteListApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.addressList = res.data
      // 获取默认地址
      this.addressList.forEach((item, index) => {
        if (item.def === 1) {
          this.addData.address = item.id
        }
      })
    },
    // 申请人列表
    async getUserManage() {
      const { data: res } = await getUserManageApi(this.userManageData)
      this.userManageList = res.data.page.list
    },
    // 线下供应商列表
    async sellerList() {
      const { data: res } = await offineSellerApi({ companyName: '', companyType: 0 })
      this.sellList = res.data.list
    },
    // 打开添加弹框
    openAddDialog(val) {
      if (val === 1) {
        this.getMatterManageList()
        this.chooseOrderDialog = true
      } else {
        this.getPlanOrderList()
        this.choosePlanDialog = true
      }
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    },
    // 获取产品列表
    async getMatterManageList() {
      // debugger
      const { data: res } = await matterListApi(this.matterData)
      if (res.code !== 0) return this.$message.error(res.msg)
      let newMatterManageList = res.data.page.list
      // 去除列表重复项
      // let newData = JSON.parse(JSON.stringify(this.addData.allProductLine))
      // if (newData.legnth !== 0) {
      //   newMatterManageList.forEach((item, index) => {
      //     newData.forEach((val) => {
      //       newMatterManageList.splice(index, 1)
      //       if (item.id === val.id) {
      //       }
      //     })
      //   })
      // }
      this.matterManageList = newMatterManageList
    },
    // 获取计划单对应的物料列表
    async getPlanOrderList() {
      const { data: res } = await getPlanOrderListApi(this.matterManageData)
      if (res.code !== 0) return this.$message.error(res.msg)
      let newMatterManageList = res.data.data.list
      // 去除列表重复项
      if (this.addData.allProductLine.legnth !== 0) {
        this.addData.allProductLine.forEach(item => {
          newMatterManageList.forEach((val, index) => {
            if (item.planLineId === val.id) {
              newMatterManageList.splice(index, 1)
            }
          })
        })
      }
      this.matterManageList = newMatterManageList
    },
    // 计划选择
    choosePlanMatter() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      this.matterList.forEach((item, index) => {
        let newData = {
          planCode: item.planCode,
          productId: item.id,
          productNo: item.productCode,
          productName: item.productName,
          productSpecs: item.productSpecs,
          productNewColour: item.productNewColour,
          unitName: item.unitName,
          number: '',
          needNumber: item.number,
          arriveTime: item.arriveTime, // 要求交货时间
          price: '',
          planResidueNumber: item.residueNumber, // 计划剩余数量
          residueNumber: '' // 剩余数量
        }
        this.addData.allProductLine.push(newData)
      })
      this.choosePlanDialog = false
    },
    // 产品选择
    chooseMatter() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      this.matterList.forEach((item, index) => {
        let newData = {
          productId: item.id,
          productNo: item.code,
          productName: item.name,
          productSpec: item.specs,
          productNewColour: item.newColour,
          productUnit: item.unit,
          num: '',
          allPrice: '',
          arrivalTime: '', // 要求交货时间
          price: '',
          residueNumber: '', // 剩余数量
          planResidueNumber: item.residueNumber, // 计划剩余数量
          planLineId: ''
        }
        this.addData.allProductLine.push(newData)
      })
      this.chooseOrderDialog = false
    },
    // 删除产品
    delMatter(index) {
      if (index === 'all') {
        if (this.chooseList.length === 0) return this.$message.error('请先勾选需要删除的产品')
        this.$confirm('是否删除勾选产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.chooseList.forEach(porductId => {
            this.addData.allProductLine.forEach((val, index) => {
              if (porductId === val.productId) {
                this.addData.allProductLine.splice(index, 1)
              }
            })
          })
          // 清空选中项
          this.chooseList = []
        })
      } else {
        this.$confirm('是否确认删除当前产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.addData.allProductLine.splice(index, 1)
        })
      }
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index, max) {
      let temp = val.toString()
      temp = temp.replace(/。/g, '.')
      temp = temp.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, '') // 验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, '') // 只保留第一个, 清除多余的
      temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // eslint-disable-next-line no-useless-escape
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      console.log(temp)// 只能输入两个小数
      // 判断计划数不得大于最大可计划数
      if (this.showPlan === true && temp > max) {
        temp = max
      }
      this.addData.allProductLine[index].num = temp
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum2(val, index) {
      let temp = val.toString()
      temp = temp.replace(/。/g, '.')
      temp = temp.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, '') // 验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, '') // 只保留第一个, 清除多余的
      temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // eslint-disable-next-line no-useless-escape
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
      console.log(temp)// 只能输入两个小数
      this.addData.allProductLine[index].price = temp
    },
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 新增线下采购单
    addPurchaseOrder(val) {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.addData.allProductLine.length === 0) return this.$message.error('订单明细不能为空!')
          const { data: res } = await addOffinePuiuchaseApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          // this.$message.success('新增并提交线下采购单成功!')
          // 判断是否直接提交
          if (val === 1) {
            this.submitPurchaseOrder(res.data.data.id)
          } else {
            this.$message.success('新增线下采购单成功!')
            setTimeout(() => {
              this.$router.replace({ path: '/offinePurchasing' })
            }, 300)
          }
        } else {
          return false
        }
      })
    },
    // 提交计划单
    async submitPurchaseOrder(id) {
      const { data: res } = await submitPurchaseOrderApi({ id: id, enquiryId: '' })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('新增并提交线下采购单成功!')
      setTimeout(() => {
        this.$router.replace({ path: '/purchaseOrderDetail', query: { result: JSON.stringify(id) } })
      }, 300)
    },
    // -----------------------------------------------
    // 清空列表数据
    clearValue() {
      if (this.addData.allProductLine.length !== 0) {
        this.$confirm('变更供应商将清空已添加的计划明细?', '变更确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          // 清空选中项
          this.addData.allProductLine = []
        })
      }
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    }
  }
}
</script>
<style scoped lang='less'>
.addPurchaseOrder {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addPurchaseOrderContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 190px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 2px;
      padding: 20px;
      height: 268px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      h6 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .navBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 33.3%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 102px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
          }
        }
        /deep/.el-form-item:nth-child(1) {
        //   width: 100%;
        }
        /deep/.el-form-item:nth-child(5) {
        //   width: 100%;
        }
      }
    }
    main {
      position: relative;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              width: 100%;
              color: rgba(0, 0, 0, 0.60);
              span:nth-child(1) {
                display: inline-block;
                width: 400px;
              }
              span:nth-child(2) {
                display: inline-block;
                width: 120px;
              }
              span:nth-child(3) {
                display: inline-block;
                width: 120px;
              }
            }
            >p {
              height: 24px;
              width: 100%;
              font-size: 14px;
              >span {
                margin-left: 30%;
                display: inline-block;
                width: 220px;
                color: rgba(0, 0, 0, 0.60);
              }
              i {
                float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 33.3%;
              .el-form-item__content {
                position: relative;
                width: calc(100% - 117px);
                .el-input {
                  width: 100%;
                }
                .el-select {
                  width: 100%;
                  /deep/ .el-input {
                    width: 100%;
                  }
                }
                >i {
                  position: absolute;
                  top: 0;
                  right: 10px;
                  width: 140px;
                  text-align: right;
                }
              }
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .choosePlanDialog, /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
