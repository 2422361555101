// 竞价报价
<template>
  <div class="askPricePurchaseDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/competePriceSell' }">竞价报价</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">报价</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump()"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="askPricePurchaseDetailContent">
      <header>
        <div>
          <h5>{{askPricePurchaseDetail.name + ' (' + askPricePurchaseDetail.code + ')'}}</h5>
          <p v-if="askPricePurchaseDetail.createTime">发布日期: <span>{{(askPricePurchaseDetail.createTime).substring(0, 16)}}({{askPricePurchaseDetail.createName === null ? '暂无' : askPricePurchaseDetail.createName}})</span>
            | 竞价状态:
            <span style="color: rgba(59, 212, 144, 1)" v-if="askPricePurchaseDetail.state === 0">草稿</span>
            <span style="color: rgba(59, 212, 144, 1)" v-else-if="askPricePurchaseDetail.state === 1">待开始</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="askPricePurchaseDetail.state === 2">进行中</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.state === 3">已结束</span>
            | 报价状态: 
            <span style="color: rgba(0, 0, 0, 0.60)" v-if="askPricePurchaseDetail.ofState == 1">待开始</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="askPricePurchaseDetail.ofState == 2">待报价</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.ofState == 3">已报价</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.ofState == 4">报价已通过</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="askPricePurchaseDetail.ofState == 5">报价未通过</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.ofState == 6">已转单</span>
            <span style="color: rgba(0, 0, 0, 0.60)" v-else-if="askPricePurchaseDetail.ofState == 7">已取消</span>
            <span style="color: rgba(0, 0, 0, 0.60)" v-else-if="askPricePurchaseDetail.ofState == 8">已过期</span>
            <span style="color: rgba(0, 0, 0, 0.60)" v-else-if="askPricePurchaseDetail.ofState == 9">已结束</span>
          </p>
        </div>
        <div class="timeBox">
          <div>
            <span>倒计时</span>
            <p>{{askPricePurchaseDetail.countDown === null ? '未开始' : askPricePurchaseDetail.countDown}}</p>
          </div>
          <i></i>
          <div>
            <span>总{{askPricePurchaseDetail.numberRound}}轮</span>
            <p>第{{askPricePurchaseDetail.nowRound}}轮</p>
          </div>
        </div>
      </header>
      <main>
        <h6>供应商要求: 
          <span class="stateBox2">{{askPricePurchaseDetail.invoiceType === 1 ? '不需要发票' : (askPricePurchaseDetail.invoiceType === 2 ? '增值税普通发票' : '增值税专用发票')}}</span>
          <span class="stateBox2">{{askPricePurchaseDetail.provinceName == '' ? '暂无' : (askPricePurchaseDetail.provinceName == null ? '' : askPricePurchaseDetail.provinceName) + (askPricePurchaseDetail.cityName == null ? '' : askPricePurchaseDetail.cityName) + (askPricePurchaseDetail.areaName == null ? '' : askPricePurchaseDetail.areaName)}}</span>
          <span class="stateBox2">{{askPricePurchaseDetail.payType === 1 ? '现金' : '转账'}}</span>
        </h6>
      </main>
      <main>
        <p>联系方式: <span v-if="askPricePurchaseDetail.peoplePhone">{{askPricePurchaseDetail.peoplePhone}}({{askPricePurchaseDetail.peopleName}})</span>
          | 报价日期: <span v-if="askPricePurchaseDetail.beginTime && askPricePurchaseDetail.endTime">{{(askPricePurchaseDetail.beginTime).substring(0, 10) + ' - ' + (askPricePurchaseDetail.endTime).substring(0, 10)}}</span><span v-else>暂无</span>
          | 报价方式: <span>{{askPricePurchaseDetail.offerTypeName}}</span>
          | 报价要求: <span>{{askPricePurchaseDetail.offerAskName}}</span>
          | 收货地址: <span>{{askPricePurchaseDetail.deliveryAddressName}}</span>
        </p>
      </main>
      <main>
        <p>补充说明: <span>{{askPricePurchaseDetail.remark === '' ? '暂无' : askPricePurchaseDetail.remark}}</span></p>
      </main>
      <main style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
        <p>附件: 
          <span style="margin-right: 20px;">{{askPricePurchaseDetail.filesEntityList.length}}</span>
          <el-button @click="openFileDialog(askPricePurchaseDetail.filesEntityList)" class="defaultStyle">查看</el-button>
        </p>
      </main>
      <footer>
        <h6 style="font-size: 14px;">
          <p>产品信息({{askPricePurchaseDetail.enquiryLineEntityList.length}})</p>
        </h6>
        <div class="roundTab" :style="'width: ' + (askPricePurchaseDetail.offerEntity.length) * 100 + 'px'">
          <div class="roundBox" @click="roundNum = index" :class="roundNum === index ? 'highlight' : ''" :style="index === askPricePurchaseDetail.offerEntity -  1 ? 'border-right: 0;' : ''" v-for="(item, index) in askPricePurchaseDetail.offerEntity" :key="index">
            第{{index + 1}}轮
          </div>
        </div>
        <div class="allPriceBox" v-if="askPricePurchaseDetail.offerType === 2">
          <p v-if="askPricePurchaseDetail.offerAsk == 1">
            <span><i>*</i>总金额(含税):</span>
            <el-input v-if="quoteData.taxRate === '' && askPricePurchaseDetail.offerAsk == 1" disabled type="number" v-model="quoteData.moneyTax" @input="formatNum(quoteData.moneyTax, '', 5)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请先选择税率"></el-input>
            <el-input v-else type="number" v-model="quoteData.moneyTax" @input="formatNum(quoteData.moneyTax, '', 5)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请输入"></el-input>
          </p>
          <p v-if="askPricePurchaseDetail.offerAsk == 1">
            <span>税率: {{quoteData.taxRate}}%</span>
            <!-- <el-select placeholder="请选择税率" @change="updataTable('all')" v-model="quoteData.taxRate">
              <el-option label="0%" :value="0"></el-option>
              <el-option label="3%" :value="3"></el-option>
              <el-option label="9%" :value="9"></el-option>
              <el-option label="13%" :value="13"></el-option>
              <el-option label="16%" :value="16"></el-option>
            </el-select> -->
          </p>
          <p>
            <span><i>*</i>总金额(不含税):</span>
            <el-input v-if="quoteData.taxRate === '' && askPricePurchaseDetail.offerAsk == 1" disabled type="number" v-model="quoteData.money" @input="formatNum(quoteData.money, '', 6)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请先选择税率"></el-input>
            <el-input v-else type="number" v-model="quoteData.money" @input="formatNum(quoteData.money, '', 6)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请输入"></el-input>
          </p>
          <p>
            <span>说明:</span>
            <el-input style="width: 200px" v-model="quoteData.remark" oninput="if(value.length > 50) value=value.slice(0, 50)" placeholder="请输入"></el-input>
          </p>
        </div>
        <el-table ref="tableHeight" v-if="askPricePurchaseDetail.offerEntity[roundNum][0].offerLineEntityList.length !== 0" :data="askPricePurchaseDetail.offerEntity[roundNum][0].offerLineEntityList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="productCode" label="产品编码" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productCode === '' || scope.row.productCode === null ? '暂无' : scope.row.productCode}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="number" label="采购数量" min-width="80px"  align="center">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.number}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productUnitName" label="单位" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productUnitName === '' || scope.row.productUnitName === null ? '暂无' : scope.row.productUnitName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="要求到货时间" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.arriveTime">{{(scope.row.arriveTime).substring(0, 16)}}</span>
                <span v-else>-</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="产品描述" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.productDescribes === null ? '暂无' : scope.row.productDescribes}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType == 1 && askPricePurchaseDetail.offerAsk == 1" key="1" show-overflow-tooltip prop="priceTax" label="单价(含税)" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.priceTax === null || scope.row.priceTax === '' ? '-' : scope.row.priceTax}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType == 1 && askPricePurchaseDetail.offerAsk == 1" key="2" show-overflow-tooltip prop="moneyTax" label="合价(含税)" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.moneyTax === null || scope.row.moneyTax === '' ? '-' : scope.row.moneyTax}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType == 1" key="3" show-overflow-tooltip prop="price" label="单价(不含税)" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.price === null || scope.row.price === '' ? '-' : scope.row.price}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType == 1" key="4" show-overflow-tooltip prop="money" label="合价(不含税)" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.money === null || scope.row.money === '' ? '-' : scope.row.money}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span @click="openFileDialog(scope.row.filesEntityList)" style="cursor: pointer;"><i style="margin-right: 3px;" class="el-icon-search"></i>查看附件</span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
        <el-table v-else :key="isUpdate" ref="tableHeight" :data="askPricePurchaseDetail.enquiryLineEntityList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="productCode" label="产品编码" min-width="100px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productCode === '' || scope.row.productCode === null ? '暂无' : scope.row.productCode}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="100px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="number" label="采购数量" min-width="80px"  align="center">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.number}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productUnitName" label="单位" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productUnitName === '' || scope.row.productUnitName === null ? '暂无' : scope.row.productUnitName}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType === 1 && askPricePurchaseDetail.offerAsk == 1" key="1" min-width="120px" align="center">
            <template slot="header">
                <span><i style="color: red;">*</i>单价(含税)</span>
              </template>
              <template slot-scope="scope">
                <el-input v-if="scope.row.taxRate === ''" disabled type="number" v-model="scope.row.priceTax" @input="formatNum(scope.row.priceTax, scope.$index, 1)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请先选择税率"></el-input>
                <el-input :ref="'tabInp1' + scope.$index" v-else type="number" v-model="scope.row.priceTax" @input="formatNum(scope.row.priceTax, scope.$index, 1)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请输入"></el-input>
              </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType === 1 && askPricePurchaseDetail.offerAsk == 1" key="2" min-width="120px" align="center">
            <template slot="header">
              <span><i style="color: red;">*</i>合价(含税)</span>
            </template>
            <template slot-scope="scope">
              <el-input v-if="scope.row.taxRate === ''" disabled type="number" v-model="scope.row.moneyTax" @input="formatNum(scope.row.moneyTax, scope.$index, 2)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请先选择税率"></el-input>
              <el-input :ref="'tabInp2' + scope.$index" v-else type="number" v-model="scope.row.moneyTax" @input="formatNum(scope.row.moneyTax, scope.$index, 2)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType === 1 && askPricePurchaseDetail.offerAsk == 1" key="3" prop="taxRate" label="税率" min-width="120px" align="center">
            <template slot="header">
              <span>税率</span>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.taxRate}}%</span>
              <!-- <el-select placeholder="请选择税率" @change="updataTable(scope.$index)" v-model="scope.row.taxRate">
                <el-option label="0%" :value="0"></el-option>
                <el-option label="3%" :value="3"></el-option>
                <el-option label="9%" :value="9"></el-option>
                <el-option label="13%" :value="13"></el-option>
                <el-option label="16%" :value="16"></el-option>
              </el-select> -->
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType === 1" key="4" min-width="120px" align="center">
            <template slot="header">
              <span><i style="color: red;">*</i>单价(不含税)</span>
            </template>
            <template slot-scope="scope">
              <el-input v-if="scope.row.taxRate === '' && askPricePurchaseDetail.offerAsk == 1" disabled type="price" v-model="scope.row.price" @input="formatNum(scope.row.price, scope.$index, 3)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请先选择税率"></el-input>
              <el-input :ref="'tabInp3' + scope.$index" v-else type="price" v-model="scope.row.price" @input="formatNum(scope.row.price, scope.$index, 3)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType === 1" key="5" min-width="120px" align="center">
            <template slot="header">
                <span><i style="color: red;">*</i>合价(不含税)</span>
              </template>
              <template slot-scope="scope">
                <el-input v-if="scope.row.taxRate === '' && askPricePurchaseDetail.offerAsk == 1" disabled type="number" v-model="scope.row.money" @input="formatNum(scope.row.money, scope.$index, 4)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请先选择税率"></el-input>
                <el-input :ref="'tabInp4' + scope.$index" v-else type="number" v-model="scope.row.money" @input="formatNum(scope.row.money, scope.$index, 4)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请输入"></el-input>
              </template>
          </el-table-column>
          <el-table-column v-if="askPricePurchaseDetail.offerType === 1" key="6" show-overflow-tooltip label="说明" min-width="180px" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.remark2" oninput="if(value.length > 50) value=value.slice(0, 50)" placeholder="请输入"></el-input>
              </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="要求到货日期" min-width="120px" align="center">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.arriveTime">{{(scope.row.arriveTime).substring(0, 10)}}</span>
                <span v-else>-</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="产品描述" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.productDescribes === null ? '暂无' : scope.row.productDescribes}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span @click="openFileDialog(scope.row.filesEntityList)" style="cursor: pointer;"><i style="margin-right: 3px;" class="el-icon-search"></i>查看附件</span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
      </footer>
      <div class="footerBox">
        <el-button class="lightDeepStyle" @click="submitSellQuote()">提交报价</el-button>
        <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
      </div>
    </div>
    <!-- 查看附件 -->
    <el-dialog class="uploadDialog" title="查看附件" :visible.sync="uploadDialog" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <ul>
          <li style="margin-top: 10px; cursor: pointer;" v-for="(item, index) in fileList" :key="index">
            <a :href="item.url">{{item.fileName}}<span style="float:right;">附件下载</span></a>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="uploadDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { askPriceSellDetailApi, endAskPriceApi, quoteListApi, approvalAskPriceApi, cancelAskPriceApi, beginAskPriceApi, delAskPriceApi, submitSellQuoteApi } from '@/api'
export default {
  data() {
    return {
      // 选中的轮次 - 1
      roundNum: 0,
      // 刷新表格
      isUpdate: true,
      // 附件列表
      fileList: [],
      // 附件弹框
      uploadDialog: false,
      // 报价记录选择的公司
      chooseCompany: 0,
      id: '',
      // 获取竞价单详情
      askPricePurchaseDetail: '',
      quoteData: {
        id: '',
        taxRate: '',
        moneyTax: '',
        money: '',
        remark: '',
        infoDtoList: []
      }
    }
  },
  created() {
    this.id = JSON.parse(this.$route.query.result)
    this.quoteData.id = JSON.parse(this.$route.query.result)
    this.getAskPricePurchaseDetail()
  },
  methods: {
    // 更新税率
    updataTable(index) {
      if (index === 'all') {
        if (this.quoteData.moneyTax !== '' && this.quoteData.taxRate !== '') {
          this.quoteData.money = (this.quoteData.moneyTax / (1 + 0.01 * this.quoteData.taxRate)).toFixed(2)
        }
      } else {
        if (this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax !== '' && this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate !== '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].price = (this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax / (1 + 0.01 * this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate)).toFixed(2)
        }
        if (this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax !== '' && this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate !== '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].money = (this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax / (1 + 0.01 * this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate)).toFixed(2)
        }
        this.isUpdate = !this.isUpdate
      }
    },
    async submitSellQuote() {
      // 判断是单品报价还是整单报价
      // 判断是否含税
      if (this.askPricePurchaseDetail.offerType === 1 && this.askPricePurchaseDetail.offerAsk == 1) {
        this.quoteData.infoDtoList = []
        this.askPricePurchaseDetail.enquiryLineEntityList.some((item, index) => {
          if (item.priceTax === '') return this.$message.error('单价(含税)不能为空!')
          if (item.price === '') return this.$message.error('单价(不含税)不能为空!')
          if (item.moneyTax === '') return this.$message.error('合价(含税)不能为空!')
          if (item.money === '') return this.$message.error('合价(不含税)不能为空!')
          this.quoteData.infoDtoList.push({ productId: item.productId, enquiryLineId: item.id, priceTax: item.priceTax, price: item.price, moneyTax: item.moneyTax, money: item.money, taxRate: item.taxRate, remark: item.remark2 })
        })
      } else if (this.askPricePurchaseDetail.offerType === 1 && this.askPricePurchaseDetail.offerAsk == 2) {
        this.quoteData.infoDtoList = []
        this.askPricePurchaseDetail.enquiryLineEntityList.some((item, index) => {
          if (item.price === '') return this.$message.error('单价(不含税)不能为空!')
          if (item.money === '') return this.$message.error('合价(不含税)不能为空!')
          this.quoteData.infoDtoList.push({ productId: item.productId, enquiryLineId: item.id, priceTax: item.priceTax, price: item.price, moneyTax: item.moneyTax, money: item.money, taxRate: item.taxRate, remark: item.remark2 })
        })
      } else {
        if (this.askPricePurchaseDetail.offerAsk == 1) {
          if (this.quoteData.moneyTax === '') return this.$message.error('总金额(含税)不能为空!')
        }
        if (this.quoteData.money === '') return this.$message.error('总金额(不含税)不能为空!')
        this.quoteData.infoDtoList = []
        this.askPricePurchaseDetail.enquiryLineEntityList.some((item, index) => {
          this.quoteData.infoDtoList.push({ productId: item.productId, enquiryLineId: item.id, priceTax: '', price: '', moneyTax: '', money: '', taxRate: '', remark: '' })
        })
      }
      const { data: res } = await submitSellQuoteApi(this.quoteData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('提交报价成功!')
      this.$router.replace({ path: '/competePriceSellDetail', query: { result: JSON.stringify(this.id) } })
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index, sub) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (sub === 1) {
        this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax = temp;
        // 根据含税计算不含税
        if (temp !== '' && this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate !== '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].price = (this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax / (1 + 0.01 * this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate)).toFixed(2)
          this.askPricePurchaseDetail.enquiryLineEntityList[index].money = (this.askPricePurchaseDetail.enquiryLineEntityList[index].price * this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
          this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax = (this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax * this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
        } else if (temp === '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].price = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].money = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax = ''
        }
        this.isUpdate = !this.isUpdate
        this.$nextTick(() => {
          this.$refs['tabInp1' + index].focus()
        })
      } else if (sub === 2) {
        this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax = temp;
        // 根据含税计算不含税
        if (temp !== '' && this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate !== '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].money = (this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax / (1 + 0.01 * this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate)).toFixed(2)
          this.askPricePurchaseDetail.enquiryLineEntityList[index].price = (this.askPricePurchaseDetail.enquiryLineEntityList[index].money / this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
          this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax = (this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax / this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
        } else if (temp === '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].price = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].money = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax = ''
        }
        this.isUpdate = !this.isUpdate
        this.$nextTick(() => {
          this.$refs['tabInp2' + index].focus()
        })
      } else if (sub === 3) {
        this.askPricePurchaseDetail.enquiryLineEntityList[index].price = temp;
        // 根据不含税计算含税
        if (temp !== '' && this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate !== '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax = (this.askPricePurchaseDetail.enquiryLineEntityList[index].price * (1 + 0.01 * this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate)).toFixed(2)
          this.askPricePurchaseDetail.enquiryLineEntityList[index].money = (this.askPricePurchaseDetail.enquiryLineEntityList[index].price * this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
          this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax = (this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax * this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
        } else if (temp !== '' && this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate === '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].money = (this.askPricePurchaseDetail.enquiryLineEntityList[index].price * this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
        } else if (temp === '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].price = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].money = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax = ''
        }
        this.isUpdate = !this.isUpdate
        this.$nextTick(() => {
          this.$refs['tabInp3' + index].focus()
        })
      } else if (sub === 4) {
        this.askPricePurchaseDetail.enquiryLineEntityList[index].money = temp;
        // 根据不含税计算含税
        if (temp !== '' && this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate !== '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax = (this.askPricePurchaseDetail.enquiryLineEntityList[index].money * (1 + 0.01 * this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate)).toFixed(2)
          this.askPricePurchaseDetail.enquiryLineEntityList[index].price = (this.askPricePurchaseDetail.enquiryLineEntityList[index].money / this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
          this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax = (this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax / this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
        } else if (temp !== '' && this.askPricePurchaseDetail.enquiryLineEntityList[index].taxRate === '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].price = (this.askPricePurchaseDetail.enquiryLineEntityList[index].money / this.askPricePurchaseDetail.enquiryLineEntityList[index].number).toFixed(2)
        } else if (temp === '') {
          this.askPricePurchaseDetail.enquiryLineEntityList[index].price = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].priceTax = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].money = ''
          this.askPricePurchaseDetail.enquiryLineEntityList[index].moneyTax = ''
        }
        this.isUpdate = !this.isUpdate
        this.$nextTick(() => {
          this.$refs['tabInp4' + index].focus()
        })
      } else if (sub === 5) {
        // 根据含税计算不含税
        this.quoteData.moneyTax = temp;
        if (temp !== '' && this.quoteData.taxRate !== '') {
          this.quoteData.money = (this.quoteData.moneyTax / (1 + 0.01 * this.quoteData.taxRate)).toFixed(2)
        } else if (temp === '') {
          this.quoteData.money = ''
        }
      } else if (sub === 6) {
        // 根据不含税计算含税
        this.quoteData.money = temp;
        if (temp !== '' && this.quoteData.taxRate !== '') {
          this.quoteData.moneyTax = (this.quoteData.money * (1 + 0.01 * this.quoteData.taxRate)).toFixed(2)
        } else if (temp === '') {
          this.quoteData.moneyTax = ''
        }
      }
    },
    // 打开附件弹框
    openFileDialog(file) {
      this.fileList = file
      this.uploadDialog = true
    },
    // 获取竞价详情
    async getAskPricePurchaseDetail() {
      const { data: res } = await askPriceSellDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.askPricePurchaseDetail = res.data.data
      this.askPricePurchaseDetail.enquiryLineEntityList.forEach(item => {
        item.priceTax = ''
        item.price = ''
        item.moneyTax = ''
        item.money = ''
        item.taxRate = this.askPricePurchaseDetail.taxRate
      })
      // 获取默认税率
      this.quoteData.taxRate = this.askPricePurchaseDetail.taxRate
      this.roundNum = this.askPricePurchaseDetail.nowRound - 1
    },
    routerJump() {
      this.$router.back()
    }
  }
};
</script>
<style lang="less" scoped>
.askPricePurchaseDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .askPricePurchaseDetailContent {
    margin-top: 40px;
    .stateBox1, .stateBox2, .stateBox3, .stateBox4 {
      display: inline-block;
      padding: 0 10px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      line-height: 22px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
    .stateBox4 {
      cursor: pointer;
      background-color: rgba(70, 147, 235, 1);
      border-color: rgba(70, 147, 235, 1);
      color: rgba(255, 255, 255, 1);
    }
    header {
      position: relative;
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          i {
            margin-left: 20px;
            display: inline-block;
            width: 52px;
            height: 22px;
            background: rgba(70,147,235,0.05);
            border-radius: 4px;
            border: 1px solid rgba(70,147,235,0.2);
            font-size: 12px;
            font-weight: 400;
            color: rgba(70, 147, 235, 1);
            text-align: center;
            line-height: 22px;
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
      .timeBox {
        position: absolute;
        top: 10px;
        right: 20px;
        display: flex;
        width: 260px;
        height: 70px;
        background-color: rgba(18, 62, 131, 0.05);
        border: 1px solid rgba(18, 62, 131, 0.20);
        border-radius: 10px;
        text-align: center;
        >div {
          width: 129px;
          height: 70px;
          span {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.60);
            font-weight: bold;
          }
          p {
            margin-top: 0;
            font-size: 24px;
            font-weight: bold;
            color: rgba(18, 62, 131, 1);
          }
        }
        i {
          margin-top: 10px;
          width: 2px;
          height: 50px;
          background-color: rgba(18, 62, 131, 1);
        }
      }
    }
    main {
      margin: 2px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        >span {
          margin: 0 10px;
        }
      }
      p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
    }
    footer {
      margin: 20px 20px 80px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        p {
          margin-right: 20px;
          padding-bottom: 5px;
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 25px;
        }
        .highlight {
          border-bottom: 3px solid rgba(18, 62, 131, 1);
        }
      }
      .roundTab {
        margin: 20px 0;
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: hidden;
        .roundBox {
          margin-right: -1px;
          width: 100px;
          height: 32px;
          border-right: 1px solid rgba(0, 0, 0, 0.25);
          cursor: pointer;
          color: rgba(0, 0, 0, 0.60);
          line-height: 32px;
          text-align: center;
        }
        .highlight {
          background-color: rgba(18, 62, 131, 1);
          color: rgba(255, 255, 255, 1);
        }
      }
      .allPriceBox {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        p {
          span {
            margin-right: 20px;
            display: inline-block;
            height: 32px;
            line-height: 32px;
            vertical-align: middle;
            i {
              color:  red;
            }
          }
          .el-input {
            margin-right: 20px;
            width: 140px;
          }
          .el-select {
            margin-right: 20px;
            width: 100px;
          }
        }
      }
    }
    .footerBox {
      position: fixed;
      bottom: 0;
      padding: 12px 20px;
      height: 56px;
      width: 100%;
      background-color: #fff;
      box-sizing: border-box;
      box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
      z-index: 1;
    }
  }
}
</style>