// 询价采购列表
<template>
  <div class="purchaseOrder">
    <nav>
      <h5>询价报价</h5>
      <div>
        <!-- <el-button class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>导出</el-button> -->
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="askPriceData.name" placeholder="标题搜索" @input="getAskPriceList()" prefix-icon="el-icon-search"></el-input>
      <!-- 发布日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="发布日期区间"
          end-placeholder="发布日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select @change="getAskPriceList()" v-model="askPriceData.state" placeholder="报价状态" size="mini" filterable clearable>
        <el-option label="待开始" :value="1"></el-option>
        <el-option label="待报价" :value="2"></el-option>
        <el-option label="已报价" :value="3"></el-option>
        <el-option label="报价已通过" :value="4"></el-option>
        <el-option label="报价未通过" :value="5"></el-option>
        <el-option label="已转单" :value="6"></el-option>
        <el-option label="已取消" :value="7"></el-option>
        <el-option label="已过期" :value="8"></el-option>
        <el-option label="已结束" :value="9"></el-option>
      </el-select>
    </section>
    <main>
      <div class="mainContent" v-if="askPriceList.length !== 0">
        <div v-for="(item, index) in askPriceList" :key="index" class="mainBox">
          <div class="mainHeader">
            <p><i class="el-icon-timer"></i>{{item.countDown}}</p>
            <span style="color: rgba(0, 0, 0, 0.60)" v-if="item.ofState == 1">待开始</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="item.ofState == 2">待报价</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="item.ofState == 3">已报价</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="item.ofState == 4">报价已通过</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="item.ofState == 5">报价未通过</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="item.ofState == 6">已转单</span>
            <span style="color: rgba(0, 0, 0, 0.60)" v-else-if="item.ofState == 7">已取消</span>
            <span style="color: rgba(0, 0, 0, 0.60)" v-else-if="item.ofState == 8">已过期</span>
            <span style="color: rgba(0, 0, 0, 0.60)" v-else-if="item.ofState == 9">已结束</span>
          </div>
          <div class="mainBody">
            <h6 class="textHidden">{{item.name}}</h6>
            <p>{{item.code}}</p>
            <span>补充说明: {{item.remark}}</span>
          </div>
          <div class="mainInfo">
            <p v-if="item.beginTime"><i class="el-icon-time"></i>{{(item.beginTime).substring(0, 16)}}</p>
            <p class="textHidden"><i class="el-icon-office-building"></i>{{item.companyName}}</p>
          </div>
          <div class="mainFooter">
            <p class="defaultStyle" @click="routerJump(1, item.id)">详情</p>
            <p v-if="item.ofState == 2" class="lightDeepStyle" @click="routerJump(2, item.id)">报价</p>
            <p v-if="item.ofState == 3 && item.state !== 9 && item.state !== 8 && item.state !== 7" class="lightStyle" @click="cancelQuote(item.id)">取消报价</p>
          </div>
        </div>
      </div>
      <div v-else class="mainContent">
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -4%; color: #ccc;">暂无数据</span>
        </div>
      </div>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="askPriceData.size"
        :total="total">
      </el-pagination>
    </main>
    <!-- 确认报价弹框 -->
    <el-dialog class="controlQuoteDialog" title="确认报价" :visible.sync="controlQuote" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <ul v-if="quoteList.length !== 0">
          <li v-for="(item, index) in quoteList" :key="index">123456</li>
        </ul>
        <div v-else slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="chooseOrderDialog = false">取 消</el-button>
        <el-button class="lightDeepStyle" @click="chooseMatter()">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { sellAskPriceListApi, cancelQuoteApi } from '@/api'
export default {
  name: 'purchaseOrder',
  data() {
    return {
      chooseTime: '',
      // ---------------------------------------------
      // 报价列表
      quoteList: [],
      // 控制确认报价弹框
      controlQuote: false,
      // 保存勾选的row的值
      allRowId: [],
      // 选中的标签
      active: 1,
      // 采购单列表
      askPriceList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      askPriceData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 6,
        type: 1,
        name: '',
        startTime: '',
        endTime: '',
        state: ''
      },
      showTotal: {
        total1: 0,
        total2: 0,
        total3: 0
      }
    }
  },
  created() {
  },
  mounted() {
    if (this.$route.query.indexState && this.$route.query.indexState == '4') {
      this.askPriceData.state = 4
    }
    this.getAskPriceList()
  },
  methods: {
    // 点击切换时间表
    getTimess(result) {
      if (result === null) {
        this.askPriceData.startTime = ''
        this.askPriceData.endTime = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.askPriceData.startTime = getYear + '-' + nowMonth + '-' + getDate + ' ' + '00:00:00'
          } else {
            this.askPriceData.endTime = getYear + '-' + nowMonth + '-' + getDate + ' ' + '00:00:00'
          }
        })
      }
      this.getAskPriceList()
    },
    // 获取询价列表
    async getAskPriceList(reset) {
      if (reset !== 'reset') {
        this.askPriceData.page = 1
      }
      let newPurchaseOrderData = JSON.parse(JSON.stringify(this.askPriceData))
      const { data: res } = await sellAskPriceListApi(newPurchaseOrderData)
      // 获取表单数据
      this.askPriceList = res.data.list
      // 展示当前页面
      this.currentPage = res.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.totalPage
      // 展示总条数
      this.total = res.data.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.askPriceData.size = val
      this.askPriceData.page = 1
      this.getAskPriceList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.askPriceData.page = val
      this.currentPage = val
      this.getAskPriceList('reset')
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: '/askPriceSellDetail', query: { result: JSON.stringify(result) } })
      } else if (val === 2) {
        this.$router.push({ path: '/askPriceSellQuote', query: { result: JSON.stringify(result) } })
      }
    },
    // 取消确认询价
    async cancelQuote(id) {
      this.$confirm('取消报价后,无法再次进行报价', '取消报价确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await cancelQuoteApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('取消报价成功!')
        this.getAskPriceList()
      })
      
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseOrder {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 20px 20px 0;
    .mainContent {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100vh - 275px);
      overflow-y: auto;
      .mainBox {
        margin-bottom: 20px;
        margin: 0 10px;
        padding: 20px 0 0;
        width: calc(33.33% - 20px);
        height: 250px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        box-sizing: border-box;
        .mainHeader {
          margin: 0 20px;
          display: flex;
          justify-content: space-between;
          p {
            color: rgba(18, 62, 131, 1);
            font-size: 16px;
            font-weight: bold;
            i {
              margin-right: 5px;
              color: rgba(18, 62, 131, 1);
              font-size: 16px;
            }
          }
          span {
            font-size: 14px;
            font-weight: 500;
          }
        }
        .mainBody {
          padding: 0 20px;
          height: 87px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          h6 {
            margin: 20px 0 4px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.80);
          }
          p {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.80);
          }
          span {
            margin-top: 10px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.40);
          }
        }
        .mainInfo {
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          height: 38px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          line-height: 38px;
          p {
            font-size: 14px;
            i {
              margin-right: 5px;
            }
          }
        }
        .mainFooter {
          padding: 10px 10px;
          display: flex;
          justify-content: space-between;
          height: 60px;
          box-sizing: border-box;
          p {
            margin: 0 10px;
            width: 100%;
            border-radius: 4px;
            cursor: pointer;
            line-height: 40px;
            text-align: center;
          }
        }
      }
    }
  }
  /deep/ .controlQuoteDialog {
    .el-dialog {
      width: 516px;
      height: 550px;
      border-radius: 10px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 10px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        padding-bottom: 0;
        height: 440px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
