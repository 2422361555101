// 询价详情
<template>
  <div class="askPricePurchaseDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/askPricePurchase' }">询价采购</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button v-if="askPricePurchaseDetail.state === 2 && (askPricePurchaseDetail.offerState === 1 || askPricePurchaseDetail.offerState === 2 || askPricePurchaseDetail.offerState === 4)" class="lightDeepStyle" @click="chooseCommand('2')"><i class="el-icon-edit-outline"></i>结束询价</el-button>
      <el-button v-if="askPricePurchaseDetail.state === 2 && (askPricePurchaseDetail.offerState === 1 || askPricePurchaseDetail.offerState === 4)" class="lightDeepStyle" @click="chooseCommand('3')"><i class="el-icon-edit-outline"></i>确认报价</el-button>
      <el-button v-if="askPricePurchaseDetail.state === 2 && askPricePurchaseDetail.offerState === 2" class="lightDeepStyle" @click="chooseCommand('4')"><i class="el-icon-edit-outline"></i>审核报价</el-button>
      <el-button v-if="askPricePurchaseDetail.state === 2 && askPricePurchaseDetail.offerState === 2" class="lightDeepStyle" @click="chooseCommand('5')"><i class="el-icon-edit-outline"></i>取消确认报价</el-button>
      <el-button v-if="askPricePurchaseDetail.state === 1" class="lightDeepStyle" @click="chooseCommand('6')"><i class="el-icon-edit-outline"></i>开始询价</el-button>
      <el-button v-if="askPricePurchaseDetail.state === 1 || askPricePurchaseDetail.state === 0" class="lightDeepStyle" @click="chooseCommand('7')"><i class="el-icon-edit-outline"></i>修改询价</el-button>
      <el-button v-if="askPricePurchaseDetail.state === 1 || askPricePurchaseDetail.state === 0" class="lightDeepStyle" @click="chooseCommand('8')"><i class="el-icon-edit-outline"></i>删除询价</el-button>
      <el-button class="lightDeepStyle" @click="routerJump(1, id)"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="askPricePurchaseDetailContent">
      <header>
        <div>
          <h5>{{askPricePurchaseDetail.name + ' (' + askPricePurchaseDetail.code + ')'}}</h5>
          <p v-if="askPricePurchaseDetail.createTime">发布日期: <span>{{(askPricePurchaseDetail.createTime).substring(0, 16)}}({{askPricePurchaseDetail.createName === null ? '暂无' : askPricePurchaseDetail.createName}})</span>
            | 询价状态:
            <span style="color: rgba(59, 212, 144, 1)" v-if="askPricePurchaseDetail.state === 0">草稿</span>
            <span style="color: rgba(59, 212, 144, 1)" v-else-if="askPricePurchaseDetail.state === 1">待开始</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="askPricePurchaseDetail.state === 2">进行中</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.state === 3">已结束</span>
            | 报价状态: 
            <span style="color: rgba(59, 212, 144, 1)" v-if="askPricePurchaseDetail.offerState === 0">待开始</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="askPricePurchaseDetail.offerState === 1">报价中</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.offerState === 2">已确认</span>
            <span style="color: rgba(70, 147, 235, 1)" v-else-if="askPricePurchaseDetail.offerState === 3">已审核</span>
            <span style="color: rgba(239, 188, 96, 1)" v-else-if="askPricePurchaseDetail.offerState === 4">未确认</span>
          </p>
        </div>
        <div class="timeBox">
          <span>倒计时</span>
          <p>{{askPricePurchaseDetail.countDown === null ? '未开始' : askPricePurchaseDetail.countDown}}</p>
        </div>
      </header>
      <main>
        <h6>供应商要求: 
          <span class="stateBox2">{{askPricePurchaseDetail.invoiceType === 1 ? '不需要发票' : (askPricePurchaseDetail.invoiceType === 2 ? '增值税普通发票' : '增值税专用发票')}}</span>
          <span class="stateBox2">{{askPricePurchaseDetail.provinceName == '' ? '暂无' : (askPricePurchaseDetail.provinceName == null ? '' : askPricePurchaseDetail.provinceName) + (askPricePurchaseDetail.cityName == null ? '' : askPricePurchaseDetail.cityName) + (askPricePurchaseDetail.areaName == null ? '' : askPricePurchaseDetail.areaName)}}</span>
          <span class="stateBox2">{{askPricePurchaseDetail.payType === 1 ? '现金' : '转账'}}</span>
        </h6>
      </main>
      <main>
        <p>联系方式: <span v-if="askPricePurchaseDetail.peoplePhone">{{askPricePurchaseDetail.peoplePhone}}({{askPricePurchaseDetail.peopleName}})</span>
          | 报价日期: <span v-if="askPricePurchaseDetail.beginTime && askPricePurchaseDetail.endTime">{{(askPricePurchaseDetail.beginTime).substring(0, 10) + ' - ' + (askPricePurchaseDetail.endTime).substring(0, 10)}}</span><span v-else>暂无</span>
          | 报价方式: <span>{{askPricePurchaseDetail.offerTypeName}}</span>
          | 报价要求: <span>{{askPricePurchaseDetail.offerAskName}}</span>
          | 收货地址: <span>{{askPricePurchaseDetail.deliveryAddressName}}</span>
        </p>
      </main>
      <main>
        <p>补充说明: <span>{{askPricePurchaseDetail.remark === '' ? '暂无' : askPricePurchaseDetail.remark}}</span></p>
      </main>
      <main style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
        <p>附件: 
          <span style="margin-right: 20px;">{{askPricePurchaseDetail.filesEntityList.length}}</span>
          <el-button @click="openFileDialog(askPricePurchaseDetail.filesEntityList)" class="defaultStyle">查看</el-button>
        </p>
      </main>
      <footer>
        <h6 style="font-size: 14px;">
          <p @click="active = 1" :class="active === 1 ? 'highlight' : ''">产品信息({{askPricePurchaseDetail.enquiryLineEntityList.length}})</p>
          <p v-if="askPricePurchaseDetail.state !== 0 && askPricePurchaseDetail.offerEntity[0].length !== 0" @click="active = 2" :class="active === 2 ? 'highlight' : ''">比价</p>
          <p v-if="askPricePurchaseDetail.state !== 0 && askPricePurchaseDetail.offerEntity[0].length !== 0" @click="active = 3" :class="active === 3 ? 'highlight' : ''">报价记录({{askPricePurchaseDetail.offerEntity[0].length}})</p>
        </h6>
        <el-table v-if="active === 1" ref="tableHeight" :data="askPricePurchaseDetail.enquiryLineEntityList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="productCode" label="产品编码" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productCode === '' || scope.row.productCode === null ? '暂无' : scope.row.productCode}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="number" label="采购数量" min-width="80px"  align="center">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.number}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productUnitName" label="单位" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productUnitName === '' || scope.row.productUnitName === null ? '暂无' : scope.row.productUnitName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="要求到货时间" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.arriveTime">{{(scope.row.arriveTime).substring(0, 16)}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="产品描述" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.remark === '' || scope.row.remark === null ? '-' : scope.row.remark}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <span @click="openFileDialog(scope.row.filesEntityList)" style="cursor: pointer;"><i style="margin-right: 3px;" class="el-icon-search"></i>查看附件</span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
        <div class="priceRatioBox" v-if="active === 2">
          <ul>
            <li>
              <span>产品信息</span>
              <i></i>
              <span>企业信息</span>
            </li>
            <li v-for="(item, index) in askPricePurchaseDetail.offerEntity[0]" :key="index">
              <h6>{{item.sellName}}({{item.state === 4 ? '已通过' : item.state === 7 ? '已取消' : '已报价'}})</h6>
              <p><span>合计金额不含税: {{item.money === null ? '无' : item.money}}</span><span v-if="askPricePurchaseDetail.offerAsk === 1">合计金额含税: {{item.moneyTax === null ? '无' : item.moneyTax}}</span></p>
              <p v-if="item.createTime"><span>报价时间: </span>{{(item.createTime).substring(0, 16)}}<span v-if="askPricePurchaseDetail.offerType == 2"> | 报价说明: </span>{{askPricePurchaseDetail.offerType == 2 ? (item.remark === null || item.remark === '' ? '无' : item.remark) : ''}}</p>
            </li>
          </ul>
          <div class="tableBox" :style="'width:' + (askPricePurchaseDetail.offerEntity[0].length * 480 + 480) + 'px'">
            <el-table ref="tableHeight" :data="askPricePurchaseDetail.offerEntity[0][0].offerLineEntityList">
              <el-table-column show-overflow-tooltip prop="productCode" label="产品编码" width="120px" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.productCode === '' || scope.row.productCode === null ? '暂无' : scope.row.productCode}}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="productName" label="产品名称" width="120px" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.productName === '' || scope.row.productName === null ? '暂无' : scope.row.productName}}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="number" label="采购数量" width="120px" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.number === '' || scope.row.number === null ? '暂未填写' : scope.row.number}}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="productUnitName" label="单位" width="120px" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.productUnitName === '' || scope.row.productUnitName === null ? '暂无' : scope.row.productUnitName}}</span>
                </template>
              </el-table-column>
              <div slot="empty" class="emptyBg">
                <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
                <span style="display: block; margin-top: -14%">暂无数据</span>
              </div>
            </el-table>
            <!-- 循环列表 -->
            <el-table v-for="(item, i) in askPricePurchaseDetail.offerEntity[0]" :key="i" ref="tableHeight" :data="item.offerLineEntityList">
              <el-table-column show-overflow-tooltip prop="price" label="单价(不含税)" :width="askPricePurchaseDetail.offerAsk === 1 ? '120px' : '240px'" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.price === '' || scope.row.price === null ? '暂未填写' : scope.row.price}}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="money" label="合价(不含税)" :width="askPricePurchaseDetail.offerAsk === 1 ? '120px' : '240px'"  align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.money === '' || scope.row.money === null ? '暂未填写' : scope.row.money}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="askPricePurchaseDetail.offerAsk === 1" show-overflow-tooltip prop="priceTax" label="单价(含税)" width="120px" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.priceTax === '' || scope.row.priceTax === null ? '暂未填写' : scope.row.priceTax}}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="askPricePurchaseDetail.offerAsk === 1" show-overflow-tooltip prop="moneyTax" label="合价(含税)" width="120px"  align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.moneyTax === '' || scope.row.moneyTax === null ? '暂未填写' : scope.row.moneyTax}}</span>
                  </div>
                </template>
              </el-table-column>
              <div slot="empty" class="emptyBg">
                <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
                <span style="display: block; margin-top: -14%">暂无数据</span>
              </div>
            </el-table>
          </div>
        </div>
        <div class="recordBox" v-if="active === 3">
          <ul v-if="askPricePurchaseDetail.offerEntity[0].length !== 0">
            <li @click="changeChoose(index)" :class="chooseCompany === index ? 'highlight' : ''" v-for="(item, index) in askPricePurchaseDetail.offerEntity[0]" :key="index">
              <h6>{{item.sellName}}({{item.state === 4 ? '已通过' : item.state === 7 ? '已取消' : '已报价'}})</h6>
              <p><span>合计金额不含税: {{item.money === null ? '无' : item.money}}</span><span v-if="askPricePurchaseDetail.offerAsk === 1">合计金额含税: {{item.moneyTax === null ? '无' : item.moneyTax}}</span></p>
              <p class="textHidden" v-if="item.createTime"><span>报价时间: </span>{{(item.createTime).substring(0, 16)}}<span v-if="askPricePurchaseDetail.offerType == 2"> | 报价说明: </span>{{askPricePurchaseDetail.offerType == 2 ? (item.remark === null || item.remark === '' ? '无' : item.remark) : ''}}</p>
            </li>
          </ul>
          <el-table ref="tabelHeight" :data="askPricePurchaseDetail.offerEntity[0][chooseCompany].offerLineEntityList">
            <el-table-column show-overflow-tooltip prop="productCode" label="产品编码" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productCode === '' || scope.row.productCode === null ? '暂无' : scope.row.productCode}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="number" label="采购数量" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.number === '' || scope.row.number === null ? '暂未填写' : scope.row.number}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productUnitName" label="单位" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productUnitName === '' || scope.row.productUnitName === null ? '暂无' : scope.row.productUnitName}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="price" label="单价(不含税)" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.price === '' || scope.row.price === null ? '暂未填写' : scope.row.price}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="money" label="合价(不含税)" min-width="80px"  align="center">
              <template slot-scope="scope">
                <div>
                  <span>{{scope.row.money === '' || scope.row.money === null ? '暂未填写' : scope.row.money}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="askPricePurchaseDetail.offerAsk === 1" show-overflow-tooltip prop="priceTax" label="单价(含税)" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.priceTax === '' || scope.row.priceTax === null ? '暂未填写' : scope.row.priceTax}}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="askPricePurchaseDetail.offerAsk === 1" show-overflow-tooltip prop="moneyTax" label="合价(含税)" min-width="80px"  align="center">
              <template slot-scope="scope">
                <div>
                  <span>{{scope.row.moneyTax === '' || scope.row.moneyTax === null ? '暂未填写' : scope.row.moneyTax}}</span>
                </div>
              </template>
            </el-table-column>
            <div slot="empty" class="emptyBg">
              <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
              <span style="display: block; margin-top: -14%">暂无数据</span>
            </div>
          </el-table>
        </div>
      </footer>
    </div>
    <!-- 查看附件 -->
    <el-dialog class="uploadDialog" title="查看附件" :visible.sync="uploadDialog" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <ul>
          <li style="margin-top: 10px; cursor: pointer;" v-for="(item, index) in fileList" :key="index">
            <a :href="item.url">{{item.fileName}}<span style="float:right;">附件下载</span></a>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="uploadDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 确认报价弹框 -->
    <el-dialog class="controlQuoteDialog" title="确认报价" :visible.sync="controlQuote" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <ul v-if="quoteList.length !== 0">
          <el-radio-group v-model="chooseQuoteCompany">
            <li @click="chooseQuoteCompany = item.id" :class="chooseQuoteCompany === item.id ? 'highlight' : ''"  v-for="(item, index) in quoteList" :key="index">
              <h6>{{item.sellName}}({{item.state === 4 ? '已通过' : item.state === 7 ? '已取消' : '已报价'}})</h6>
              <p><span>合计金额不含税: {{item.money === null ? '无' : item.money}}</span><span v-if="askPricePurchaseDetail.offerAsk === 1">合计金额含税: {{item.moneyTax === null ? '无' : item.moneyTax}}</span></p>
              <p><span>报价时间: </span>{{(item.createTime).substring(0, 16)}}<span v-if="item.offerType == 2"> | 报价说明: </span>{{item.offerType == 1 ?  '' : (item.remark === null ? '无' : item.remark)}}</p>
              <el-radio :label="item.id"></el-radio>
            </li>
          </el-radio-group>
        </ul>
        <div v-else slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 10%;">
          <span style="display: block; margin-top: 0%; color: #ccc">暂无数据</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="controlQuote = false">取 消</el-button>
        <el-button v-if="quoteList.length !== 0" class="lightDeepStyle" @click="confirmQuote()">确 认</el-button>
      </div>
    </el-dialog>
    <!-- 审核报价弹框 -->
    <el-dialog class="controlApprovalDialog" title="审核报价" :visible.sync="controlApproval" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <ul>
          <li>
            <h6>{{approvalQuoteData.sellName}}</h6>
            <p><span>合计金额不含税: {{approvalQuoteData.money === null ? '无' : approvalQuoteData.money}}</span><span v-if="approvalQuoteData.offerAsk === 1">合计金额含税: {{approvalQuoteData.moneyTax === null ? '无' : approvalQuoteData.moneyTax}}</span></p>
            <p><span v-if="approvalQuoteData.createTime">报价时间: </span>{{(approvalQuoteData.createTime).substring(0, 16)}}<span v-if="approvalQuoteData.offerType == 2"> | 报价说明: </span>{{approvalQuoteData.offerType == 1 ?  '' : (approvalQuoteData.remark === null ? '无' : approvalQuoteData.remark)}}</p>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="cancelAskPrice(id, false)">不通过</el-button>
        <el-button class="lightDeepStyle" @click="approvalAskPrice(id)">通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { askPricePurchaseDetailApi, endAskPriceApi, quoteListApi, quoteList2Api, approvalAskPriceApi, cancelAskPriceApi, beginAskPriceApi, delAskPriceApi, confirmQuoteApi } from '@/api'
export default {
  data() {
    return {
      // 审核报价
      approvalQuoteData: {
        sellName: '',
        money: '',
        moneyTax: '',
        createTime: '',
        remark: ''
      },
      // 控制审核报价弹框
      controlApproval: false,
      // 附件列表
      fileList: [],
      // 附件弹框
      uploadDialog: false,
      // 选择的标签
      active: 1,
      chooseCompany: 0,
      // 报价记录选择的公司
      chooseQuoteCompany: '',
      // 报价列表
      quoteList: [],
      // 控制确认报价弹框
      controlQuote: false,
      id: '',
      // 获取询价单详情
      askPricePurchaseDetail: '',
    }
  },
  created() {
    this.id = JSON.parse(this.$route.query.result)
    this.getAskPricePurchaseDetail()
  },
  methods: {
    // 切换公司
    changeChoose(index) {
      this.chooseCompany = index
      this.$refs['tabelHeight'].doLayout()
    },
    // 报价列表(确认报价用)
    async getQuoteList(id, state) {
      const { data: res } = await quoteListApi({ round: 1, enquiryId: id, state: state })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.quoteList = res.data.data
    },
    // 报价列表(审核报价用)
    async getQuoteList2(id, offerIds) {
      const { data: res } = await quoteList2Api({ round: 1, enquiryId: id, offerIds: offerIds })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.quoteList = res.data.data
      this.approvalQuoteData = this.quoteList[0]
      this.chooseApprovalId = id
    },
    // 确认报价
    async confirmQuote() {
      const { data: res } = await confirmQuoteApi(this.chooseQuoteCompany)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('确认报价成功!')
      this.controlQuote = false
      this.getAskPricePurchaseDetail()
    },
    // 删除询价
    async delAskPrice(id) {
      const { data: res } = await delAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('删除成功!')
      this.$router.back()
    },
    // 开始询价
    async beginAskPrice(id) {
      const { data: res } = await beginAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('开始询价成功!')
      this.getAskPricePurchaseDetail()
    },
    // 取消确认询价
    async cancelAskPrice(id, status) {
      const { data: res } = await cancelAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (status === false) {
        this.$message.success('不通过审核成功!')
        this.controlApproval = false
      } else {
        this.$message.success('取消确认成功!')
      }
      this.getAskPricePurchaseDetail()
    },
    // 审核询价
    async approvalAskPrice(id) {
      const { data: res } = await approvalAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('审核成功!')
      this.getAskPricePurchaseDetail()
      this.controlApproval = false
    },
    // 结束询价
    async endAskPrice(id) {
      const { data: res } = await endAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('结束成功!')
      this.getAskPricePurchaseDetail()
    },
    // 选择下拉
    chooseCommand(command) {
      // <!-- 结束询价 进行中>报价中&已确认&未确认 -->
      // <!-- 确认报价 进行中>报价中&未确认 -->
      // <!-- 审核报价 进行中>已确认 -->
      // <!-- 取消确认报价 进行中>已确认 -->
      // <!-- 删除询价 开始询价 修改询价 待开始 -->
      if (command === '2') {
        // 结束询价
        this.$confirm('是否确认结束询价?结束后无法进行报价确认等操作<p>报价: <span style="color: rgba(70, 147, 235, 1)">已确认</span>/<span style="color: rgba(239, 188, 96, 1)">未确认</span></p>', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true
        }).then(async () => {
          this.endAskPrice(this.id)
        })
      } else if (command === '3') {
        // 确认报价
        this.getQuoteList(this.id, 3)
        this.controlQuote = true
      } else if (command === '4') {
        // 审核报价
        this.getQuoteList2(this.id, this.askPricePurchaseDetail.offerIds)
        this.controlApproval = true
      } else if (command === '5') {
        // 取消确认寻找
        this.cancelAskPrice(this.id)
      } else if (command === '6') {
        // 开始询价
        this.beginAskPrice(this.id)
      } else if (command === '7') {
        // 修改询价
        this.routerJump(3, this.id)
      } else if (command === '8') {
        // 删除询价
        this.$confirm('是否确认删除该询价', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(async () => {
          this.delAskPrice(this.id)
        })
      }
    },
    // 打开附件弹框
    openFileDialog(file) {
      this.fileList = file
      this.uploadDialog = true
    },
    // 获取询价详情
    async getAskPricePurchaseDetail() {
      const { data: res } = await askPricePurchaseDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.askPricePurchaseDetail = res.data.data
    },
    routerJump(val, result) {
      if (val === 1) {
        this.$router.back()
      } else if (val === 2) {
        this.$router.push({ path: '/askPricePurchaseDetail', query: { result: JSON.stringify(result) } })
      } else if (val === 3) {
        this.$router.push({ path: '/editAskPrice', query: { result: JSON.stringify(result) } })
      } else if (val === 4) {
        this.$router.push({ path: '/askPriceDraft' })
      }
    }
  }
};
</script>
<style lang="less" scoped>
.askPricePurchaseDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .askPricePurchaseDetailContent {
    margin-top: 40px;
    .stateBox1, .stateBox2, .stateBox3, .stateBox4 {
      display: inline-block;
      padding: 0 10px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      line-height: 22px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
    .stateBox4 {
      cursor: pointer;
      background-color: rgba(70, 147, 235, 1);
      border-color: rgba(70, 147, 235, 1);
      color: rgba(255, 255, 255, 1);
    }
    header {
      position: relative;
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          i {
            margin-left: 20px;
            display: inline-block;
            width: 52px;
            height: 22px;
            background: rgba(70,147,235,0.05);
            border-radius: 4px;
            border: 1px solid rgba(70,147,235,0.2);
            font-size: 12px;
            font-weight: 400;
            color: rgba(70, 147, 235, 1);
            text-align: center;
            line-height: 22px;
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
      .timeBox {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 130px;
        height: 70px;
        background-color: rgba(18, 62, 131, 0.05);
        border: 1px solid rgba(18, 62, 131, 0.20);
        border-radius: 10px;
        text-align: center;
        span {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.60);
          font-weight: bold;
        }
        p {
          margin-top: 0;
          font-size: 24px;
          font-weight: bold;
          color: rgba(18, 62, 131, 1);
        }
      }
    }
    main {
      margin: 2px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        >span {
          margin: 0 10px;
        }
      }
      p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
    }
    footer {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        p {
          margin-right: 20px;
          padding-bottom: 5px;
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 25px;
        }
        .highlight {
          border-bottom: 3px solid rgba(18, 62, 131, 1);
        }
      }
      .priceRatioBox {
        overflow-x: auto;
        ul {
          margin: 20px 0 0;
          display: flex;
          justify-content: flex-start;
          height: 124px;
          li {
            width: 480px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            box-sizing: border-box;
            flex-shrink:0;
          }
          li:nth-child(1) {
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.06);
            span:nth-child(1) {
              position: absolute;
              left: 20px;
              bottom: 20px;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.80);
              font-weight: bold;
            }
            span:nth-child(3) {
              position: absolute;
              top: 20px;
              right: 20px;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.80);
              font-weight: bold;
            }
            i {
              position: absolute;
              top: 62px;
              left: -8px;
              width: 103%;
              height: 1px;
              background-color: rgba(0, 0, 0, 0.15);
              transform: rotate(13.5deg)
            }
          }
          li:nth-child(2), li:nth-child(4), li:nth-child(6), li:nth-child(8), li:nth-child(10) {
            padding: 20px;
            background-color: rgba(18, 62, 131, 0.05);
            border: 1px solid rgba(18, 62, 131, 0.05);
            h6 {
              font-size: 18px;
            }
            p:nth-child(2) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                margin-right: 60px;
              }
            }
            p:nth-child(3) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                color: rgba(0, 0, 0, 0.40);
              }
            }
          }
          li:nth-child(3), li:nth-child(5), li:nth-child(7), li:nth-child(9), li:nth-child(11) {
            padding: 20px;
            background-color: rgba(0, 0, 0, 0.02);
            border: 1px solid rgba(0, 0, 0, 0.02);
            h6 {
              font-size: 18px;
            }
            p:nth-child(2) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                margin-right: 60px;
              }
            }
            p:nth-child(3) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                color: rgba(0, 0, 0, 0.40);
              }
            }
          }
        }
        .tableBox {
          display: flex;
          justify-content: flex-start;
          /deep/ .el-table:nth-child(2), /deep/ .el-table:nth-child(4), /deep/ .el-table:nth-child(6), /deep/ .el-table:nth-child(8), /deep/ .el-table:nth-child(10) {
            tr {
              background-color: rgba(18, 62, 131, 0.05);
            }
          }
          /deep/ .el-table:nth-child(3), /deep/ .el-table:nth-child(5), /deep/ .el-table:nth-child(7), /deep/ .el-table:nth-child(9), /deep/ .el-table:nth-child(11) {
            tr {
              background-color: rgba(18, 62, 131, 0.02);
            }
          }
        }
      }
      .recordBox {
        display: flex;
        justify-content: space-between;
        ul {
          margin: 20px 20px 0 0;
          width: 440px;
          li {
            margin-bottom: 10px;
            padding: 20px;
            width: 100%;
            background-color: rgba(18, 62, 131, 0.05);
            box-sizing: border-box;
            border-radius: 10px;
            cursor: pointer;
            h6 {
              font-size: 18px;
            }
            p:nth-child(2) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                margin-right: 60px;
              }
            }
            p:nth-child(3) {
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                color: rgba(0, 0, 0, 0.40);
              }
            }
          }
          .highlight {
            background-color: rgba(18, 62, 131, 1);
            h6 {
              color: #fff!important;
            }
            p {
              color: #fff!important;
              span {
                color: #fff!important;
              }
            }
          }
        }
      }
    }
  }
  /deep/ .controlQuoteDialog {
    .el-dialog {
      width: 480px;
      height: 550px;
      border-radius: 10px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 10px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        padding-bottom: 0;
        height: 440px!important;
        width: 100%;
        ul {
          overflow-y: auto;
          li {
            position: relative;
            margin-bottom: 10px;
            padding: 10px 20px;
            width: 100%;
            height: 124px;
            background-color: rgba(0, 0, 0, 0.02);
            border: 2px solid rgba(0, 0, 0, 0.02);
            box-sizing: border-box;
            border-radius: 10px;
            cursor: pointer;
            h6 {
              margin: 10px 0 15px;
              font-size: 18px;
              color: rgba(0, 0, 0, 0.80);
            }
            p:nth-child(2) {
              margin-bottom: 15px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                margin-right: 60px;
              }
            }
            p:nth-child(3) {
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                color: rgba(0, 0, 0, 0.40);
              }
            }
            .el-radio {
              position: absolute;
              top: 54px;
              right: 20px;
              .el-radio__label {
                display: none;
              }
            }
          }
        }
        .highlight {
          border: 2px solid rgba(18, 62, 131, 1);
          background-color: #fff;
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
  /deep/ .controlApprovalDialog {
    .el-dialog {
      width: 515px;
      height: 270px;
      border-radius: 10px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 10px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        padding-bottom: 0;
        height: 160px!important;
        width: 100%;
        ul {
          overflow-y: auto;
          li {
            margin-bottom: 10px;
            width: 100%;
            height: 124px;
            box-sizing: border-box;
            border-radius: 10px;
            cursor: pointer;
            h6 {
              margin: 0;
              font-size: 18px;
              color: rgba(0, 0, 0, 0.80);
            }
            p:nth-child(2) {
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                margin-right: 60px;
              }
            }
            p:nth-child(3) {
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                color: rgba(0, 0, 0, 0.40);
              }
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>