// 用户管理管理
<template>
  <div class="sellerManage">
    <nav>
      <h5>用户管理</h5>
      <div>
        <el-button class="lightDeepStyle" @click="openDialog()"><i class="el-icon-plus"></i>新增用户</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model="roleManageData.name" placeholder="用户姓名搜索" @input="getUserList()" prefix-icon="el-icon-search"></el-input>
    </section>
    <main>
      <el-table ref="tableHeight" row-key="id" :height="tableHeight" :data="roleData" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="jobNum" label="工号" min-width="105px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="" label="姓名" min-width="120px" align="center">
          <template slot-scope="scope">
            <a @click="openDialog(scope.row.infoId)" style="color: rgba(18, 62, 131, 1);">{{scope.row.name}}</a>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="dept" label="部门" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="" label="岗位权限" min-width="140px" align="center">
          <template slot-scope="scope">
            <a @click="scope.row.roleName === '管理员' ? '' : routerPush('roleManageEdit', { roleId: scope.row.roleId })" style="color: rgba(18, 62, 131, 1)">{{scope.row.roleName}}</a>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="username" label="账号" min-width="140px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="mobile" label="手机号" min-width="140px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="isSuper" label="用户类型" min-width="100px" align="center">
          <template slot-scope="scope">
            <div class="status_height">
              <p v-if="scope.row.isSuper === 1 && createUserId === scope.row.userId">创建人</p>
              <p v-else-if="scope.row.isSuper === 1">管理员</p>
              <p v-else-if="scope.row.isSuper === 0">用户</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="" label="状态" min-width="80px" align="center">
          <template slot-scope="scope">
            <div class="status_height">
              <p v-if="scope.row.state === '1'" style="color: rgba(59, 212, 144, 1);">在职</p>
              <p v-if="scope.row.state === '0'" style="color: rgba(0, 0, 0, 0.40);">离职</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="mobile" label="邀请状态" min-width="80px" align="center">
          <template slot-scope="scope">
            <div>
              <el-button class="joining" v-if="scope.row.isActivate === 2">邀请中</el-button>
              <el-button class="joined" v-if="scope.row.isActivate === 1">已加入</el-button>
              <el-button class="send" v-if="scope.row.isActivate === 0">未邀请</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item v-if="scope.row.isActivate === 0 && scope.row.state === '1'" command="1">
                  <i class="el-icon-edit-outline"></i><span>发送邀请</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.isActivate === 2" command="2">
                  <i class="el-icon-edit-outline"></i><span>再次发送</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.isActivate === 0" command="3">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
                <!-- <el-dropdown-item v-if="scope.row.isActivate === 1" command="4">
                  <i class="el-icon-delete"></i><span>离职交接</span>
                </el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="roleManageData.size"
        :total="total">
      </el-pagination>
    </main>
    <template>
      <div>
        <el-dialog class="changeDialog" :title="infoId === '' ? '新增用户' : '修改用户'" :visible.sync="editDialog" :close-on-click-modal="false" v-dialogDrag>
          <!-- 新增用户表单 -->
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <label>
              <p><i style="color: red;">*</i>姓名:</p>
              <el-form-item prop="name">
                <el-input placeholder="输入姓名" v-model="ruleForm.name"></el-input>
              </el-form-item>
            </label>
            <label>
              <p><i style="color: red;">*</i>工号:</p>
              <el-form-item prop="jobNum">
                <el-input placeholder="输入工号" v-model="ruleForm.jobNum"></el-input>
              </el-form-item>
            </label>
            <label>
              <p><i style="color: red;">*</i>手机号:</p>
              <el-form-item prop="mobile">
                <el-input placeholder="输入手机" v-model="ruleForm.mobile" oninput="value=value.replace(/[^\d]/g,'')" maxLength='11'></el-input>
              </el-form-item>
            </label>
            <label>
              <p><i style="color: red;">*</i>岗位权限:</p>
              <el-form-item prop="mobile">
                <el-select v-model="powerId" placeholder="请选择" size="mini" @visible-change="getRoleList()">
                  <el-option v-for="item in position" :key="item.roleId" :label="item.roleName" :value="item.roleId" @click.native="changeStatus(item)"></el-option>
                </el-select>
              </el-form-item>
            </label>
            <label>
              <p>管理员:</p>
              <el-form-item prop="isSuper">
                <el-radio-group v-model="ruleForm.isSuper">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </label>
            <label>
              <p>状态:</p>
              <el-form-item prop="isSuper">
                <el-radio-group v-model="ruleForm.state">
                  <el-radio :label="1">在职</el-radio>
                  <el-radio :label="0">离职</el-radio>
                </el-radio-group>
              </el-form-item>
            </label>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="editDialog = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="addChildUser()">保存</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
  </div>
</template>
<script>
import { getUserManageApi, delUserApi, sendShortApi, getUserCodeApi, idGetRoleListApi, addChildUserApi, editChildUserApi, userIdGetUserInfoApi } from '@/api'
export default {
  name: 'sellerManage',
  data() {
    return {
      // 获取创建人id
      createUserId: JSON.parse(window.sessionStorage.getItem('userInfo')).createUserId,
      // 列表动态高度
      tableHeight: '',
      isFirstEnter: false,
      // 获取当前浏览器的宽度
      screenWidth: '',
      roleData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      roleManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 50,
        // 搜索内容
        name: '',
        companyId: window.$cookies.get('companyId'),
        state: '',
        isActivate: ''
      },
      chooseTime: '',
      ruleForm: {
        deptId: '', // 部门id
        jobNum: '', // 工号
        name: '', // 姓名
        mobile: '', // 手机号
        isSuper: 1, // 是否为管理员,
        state: 1, // 是否在职,
        companyId: JSON.parse(window.$cookies.get('companyId')), // 当前公司id,
        companyRoleList: [] // 选取的岗位权限
      },
      // 控制弹出框
      editDialog: false,
      // 存储岗位权限
      position: [],
      // 当前选中的岗位权限id
      powerId: null,
      // 当前选中的岗位
      power: '',
      infoId: '',
      // 校验
      rules: {
        jobNum: [
          { required: true, message: '工号不能为空!', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '姓名不能为空!', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空!', trigger: 'blur' },
          { max: 16, message: '最多可输入16个字符', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              console.log(value)
              if (value !== '') {
                if (/^(13[0-9]|14[0-9]|15[0-9]|16[6]|18[0-9]|19[6,9]|17[0-9])\d{8}$/i.test(value) === false) {
                  callback(new Error('手机号格式不正确!'))
                } else {
                  // 校验通过
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
  },
  mounted() {
    this.getUserList()
    this.getRoleList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.sendShort(result)
      } else if (command === '2') {
        this.sendShort(result)
      } else if (command === '3') {
        this.delUser(result.id)
      }
    },
    // 切换岗位权限
    changeStatus(item) {
      this.ruleForm.companyRoleList = []
      this.power = item
    },
    // 根据用户id获取用户详情
    async getUserInfo() {
      const { data: res } = await userIdGetUserInfoApi(this.infoId)
      if (res.code !== 0) return this.$message.error('获取用户详情失败!')
      this.ruleForm = res.data.detail
      this.ruleForm.state = Number(this.ruleForm.state)
      this.powerId = res.data.detail.companyRoleList[0].roleId
    },
    // 添加(修改)用户
    async addChildUser() {
      if (this.power === '' && this.infoId === '') return this.$message.error('请选择岗位权限!')
      if (this.infoId === '') {
        this.ruleForm.companyRoleList = []
        this.ruleForm.companyRoleList.push(this.power)
        const { data: res } = await addChildUserApi(this.ruleForm)
        if (res.code !== 0) return this.$message.error(res.msg)
      } else {
        if (this.power !== '') {
          this.ruleForm.companyRoleList = []
          this.ruleForm.companyRoleList.push(this.power)
        }
        const { data: res } = await editChildUserApi(this.ruleForm)
        if (res.code !== 0) return this.$message.error(res.msg)
      }
      if (this.infoId === '') {
        this.$message.success({ duration: 1000, message: '添加用户成功!' })
      } else {
        this.$message.success({ duration: 1000, message: '修改用户成功!' })
      }
      this.getUserList()
      this.editDialog = false
    },
    // 根据id获取角色列表
    async getRoleList() {
      const { data: res } = await idGetRoleListApi(this.ruleForm.companyId)
      if (res.code !== 0) return this.$message.error('获取角色列表失败!')
      this.position = res.data.list
    },
    async getUserCode() {
      const { data: res } = await getUserCodeApi()
      if (this.infoId === undefined) {
        this.ruleForm.jobNum = res.data.data
      }
    },
    // 打开弹框
    openDialog(infoId) {
      if (infoId) {
        this.infoId = infoId
        this.getUserInfo()
      } else {
        this.infoId = ''
        this.powerId = ''
        this.power = ''
        this.ruleForm = {
          jobNum: '', // 工号
          name: '', // 姓名
          mobile: '', // 手机号
          isSuper: 0, // 是否为管理员,
          state: 1, // 是否在职,
          companyId: JSON.parse(window.$cookies.get('companyId')), // 当前公司id,
          companyRoleList: [] // 选取的岗位权限
        }
        this.getUserCode()
      }
      this.editDialog = true
    },
    routerPush(url, query) {
      this.$router.push({ path: url, query: query })
    },
    // 获取用户管理列表
    async getUserList(reset) {
      if (reset !== 'reset') {
        this.roleManageData.page = 1
      }
      const { data: res } = await getUserManageApi(this.roleManageData)
      // 获取表单数据
      this.roleData = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.roleManageData.size = val
      this.roleManageData.page = 1
      this.getUserList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.roleManageData.page = val
      this.currentPage = val
      this.getUserList('reset')
    },
    // 发送短信
    async sendShort(result) {
      this.$confirm('确定发送邀请吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await sendShortApi(result)
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '发送成功!'
          })
          this.getUserList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '取消发送'
        })
      })
    },
    // 删除用户
    async delUser(id) {
      this.$confirm('确定删除当前用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await delUserApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '删除用户成功!' })
        this.getUserList()
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped lang='less'>
.sellerManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
    .el-table .el-button {
      padding: 0;
      width: 52px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.20);
      font-size: 12px;
      line-height: 0;
      color: rgba(239, 188, 96, 1);
      cursor: default;
      text-align: center;
    }
    .el-table .joined {
      background: rgba(70,147,235,0.05);
      border: 1px solid rgba(70,147,235,0.2);
      color: rgba(70, 147, 235, 1);
    }
    .el-table .joining {
      background: rgba(70,147,235,0.05);
      border: 1px solid rgba(70,147,235,0.2);
      color: rgba(70, 147, 235, 1);
    }
  }
  .changeDialog {
    /deep/ .el-dialog {
      height: 638px;
      width: 424px;
      .el-dialog__body {
        height: 518px;
        .el-input {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
}
</style>
