// 采购退料
<template>
  <div class="purchaseReturn">
    <nav>
      <h5>采购退货单</h5>
      <div>
        <el-button v-if="btnLimit.includes('新增退货单')" class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增退货单</el-button>
        <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="purchaseReturnData.code" placeholder="采购退货单编号搜索" @input="getPurchaseReturnList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="purchaseReturnData.productName" placeholder="产品名称搜索" @input="getPurchaseReturnList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="创建日期区间"
          end-placeholder="创建日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
    </section>
    <main>
      <el-table ref="tableHeight" row-key="index" :height="tableHeight" :data="purchaseReturnList" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineEntityList'}" :show-overflow-tooltip="true">
        <el-table-column show-overflow-tooltip prop="code" label="退货单编码" min-width="100px" align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(3, scope.row.id)">{{scope.row.code}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="sellName" label="供应商" min-width="100px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="orderCode" label="采购单编号" min-width="100px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.orderCode === '' || scope.row.orderCode === null ? '暂未填写' : scope.row.orderCode}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip type="index" label="需求行号" width="80px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.unitName === '' || scope.row.unitName === null ? '暂未填写' : scope.row.unitName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unitName" label="状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">{{scope.row.state === 1 ? '待确认' : scope.row.state === 2 ? '已确认' : "作废"}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown v-if="scope.row.lineEntityList" @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="1" v-if="(scope.row.orderState !== 2 && scope.row.state !== 3) && btnLimit.includes('编辑退货单')">
                  <i class="el-icon-edit-outline"></i><span>修改</span>
                </el-dropdown-item>
                <el-dropdown-item command="2" v-if="scope.row.orderState !== 2 && btnLimit.includes('删除退货单')">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="purchaseReturnData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import { purchaseReturnListApi, delPurchaseReturnApi, cgthExportApi } from '@/api'
export default {
  name: 'purchaseReturn',
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      // 采购退料列表
      purchaseReturnList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      purchaseReturnData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        purchaseCode: '',
        productName: '',
        createTimeBegin: '',
        createTimeEnd: '',
        approveStatus: '',
        sellId: '',
        state: '',
        type: 1
      },
      chooseTime: '',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getPurchaseReturnList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routerJump(3, result.id)
      } else {
        this.delPurchaseReturn(result.id)
      }
    },
    // 供应商删除
    delPurchaseReturn(id) {
      this.$confirm("是否确认删除该退货单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(async () => {
          const { data: res } = await delPurchaseReturnApi({ id: id, type: 1 })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('删除成功!')
          this.getPurchaseReturnList()
        })
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: "/addPurchaseReturn" })
      } else if (val === 2) {
        this.$router.push({ path: "/chooseEntry" })
      } else {
        this.$router.push({ path: "/editPurchaseReturn", query: { result: JSON.stringify(result) }})
      }
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.purchaseReturnData.createTimeBegin = ''
        this.purchaseReturnData.createTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value);
          let getYear = timers.getFullYear();
          let nowMonth = timers.getMonth() + 1;
          let getDate = timers.getDate();
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.purchaseReturnData.createTimeBegin = getYear + '-' + nowMonth + '-' + getDate
          } else {
            this.purchaseReturnData.createTimeEnd = getYear + '-' + nowMonth + '-' + getDate
          }
        });
      }
      this.getPurchaseReturnList()
    },
    // 获取采购退料列表
    async getPurchaseReturnList(reset) {
      if (reset !== 'reset') {
        this.purchaseReturnData.page = 1
      }
      const { data: res } = await purchaseReturnListApi(this.purchaseReturnData)
      // 获取表单数据
      this.purchaseReturnList = res.data.data.list
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
      // 给列表添加唯一值
      this.purchaseReturnList.forEach((item, index) => {
        item.index = index + 1
        item.lineEntityList.forEach((val, i) => {
          val.index= (index + 1 + '' + i) - 0
        })
      })
      console.log(this.purchaseReturnList)
    },
    // -----------------------------------------------------------------
    
    // 触发每页显示条数
    handlesizeChange(val) {
      this.purchaseReturnData.size = val
      this.purchaseReturnData.page = 1
      this.getPurchaseReturnList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.purchaseReturnData.page = val
      this.currentPage = val
      this.getPurchaseReturnList('reset')
    },
    async purchaseReturnExport() {
      const { data: res } = await cgthExportApi(this.purchaseReturnData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getPurchaseReturnList()
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseReturn {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
