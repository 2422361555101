// 收款单详情
<template>
  <div class="balanceAccountDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/CollectionManagement' }">收款单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
      <!-- <el-button v-if="balanceAccountDetail.orderDetail.status === 0" class="lightDeepStyle" @click="routerJump(2)"><i class="el-icon-edit-outline"></i>编辑</el-button> -->
      <!-- <el-button v-if="balanceAccountDetail.orderDetail.status === 0" class="dangerStyle" @click="delBalanceAccount(balanceAccountDetail.orderDetail.id)"><i class="el-icon-delete"></i>撤回重做</el-button> -->
    </div>
    <div class="balanceAccountDetailContent">
      <header>
        <div>
          <h5>收款单编号: {{balanceAccountDetail.skInfo.number + ' (' + balanceAccountDetail.skInfo.cgCompanyName + ')'}}
          </h5>
          <p>创建时间: <span>{{balanceAccountDetail.skInfo.createTime + '(' + balanceAccountDetail.detail.sellerName + ')'}}</span></p>
        </div>
      </header>
      <main style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
        <p>总金额(含税):</p><el-input v-model="balanceAccountDetail.skInfo.amountTax" disabled></el-input>
        <p style="margin-left: 10px;">总金额(不含税):</p><el-input v-model="balanceAccountDetail.skInfo.amount" disabled></el-input>
      </main>
      <footer>
        <h6>收款单明细</h6>
          <el-table ref="tableHeight" max-height="241" :data="detailList" row-key="index" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineList'}" :show-overflow-tooltip="true">
            <el-table-column show-overflow-tooltip prop="orderNum" label="采购单号" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.lineList">-</span>
                <span v-else>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.lineList">-</span>
                <span v-else>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.lineList">-</span>
                <span v-else>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="warrantCount" label="数量" min-wid th="80px"  align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="returnCount" label="退货数量" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="80px" align="center">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.price">{{scope.row.price}}</span>
                  <span v-else>-</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="totalAmount" label="产品总价" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="yfkAmount" label="预付款金额" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="kkAmount" label="扣款金额" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="resAmount" label="应付金额(不含税)" min-width="80px" align="center"></el-table-column>
          </el-table>
      </footer>
      <footer>
        <h6>其他扣款单</h6>
        <el-table ref="tableHeight" max-height="245" :data="balanceAccountDetail.dtnList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="number" label="扣款单号" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="扣款名称" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="remark" label="扣款理由" min-width="280px"  align="left">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.remark}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="price" label="扣款金额" min-width="80px" align="center"></el-table-column>
        </el-table>
      </footer>
    </div>
  </div>
</template>
<script>
import { CollectionManagementDetailApi, paymentDetailApi, delBalanceAccountApi, paymentDetailListApi } from '@/api'
export default {
  data() {
    return {
      id: '',
      // 获取收款单详情
      balanceAccountDetail: '',
      // 收款单详情
      paymentDetail: '',
      // 明细
      detailList: []
    }
  },
  created() {
    this.id = JSON.parse(this.$route.query.id)
    this.DetailList()
    this.getBalanceAccountDetail().then(() => {
      if (this.balanceAccountDetail.orderDetail.status === 2) {
        this.getPaymentDetail(this.balanceAccountDetail.pid)
      }
    })
  },
  methods: {
    // 删除
    delBalanceAccount() {
      this.$confirm('是否确认撤回该收款单，将会释放相应数据？', '撤回重做', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await delBalanceAccountApi({ id: this.id, status: 4 })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('撤回成功!')
          this.$router.back()
        })
    },
    // 收款单明细
    async DetailList () {
      const { data: res } = await paymentDetailListApi(this.id)
      let newBalanceAccountDetail = res.data.list
      //   给数据添加唯一值index,用于展开
      if (newBalanceAccountDetail.length !== 0) {
        newBalanceAccountDetail.forEach((item, index) => {
          item.index = index + ''
          if (item.lineList.length !== 0) {
            item.lineList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
      this.detailList = newBalanceAccountDetail
    },
    // 获取收款单详情
    async getBalanceAccountDetail() {
      const { data: res } = await CollectionManagementDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      //   let newBalanceAccountDetail = res.data
      // 给数据添加唯一值index,用于展开
      //   if (newBalanceAccountDetail.orderList.length !== 0) {
      //     newBalanceAccountDetail.orderList.forEach((item, index) => {
      //       item.index = index + ''
      //       if (item.lineList.length !== 0) {
      //         item.lineList.forEach((val, i) => {
      //           val.index = index + '' + i
      //         })
      //       }
      //     })
      //   }
      this.balanceAccountDetail = res.data
      console.log(this.balanceAccountDetail.skInfo)
    },
    // 获取收款单详情
    async getPaymentDetail(id) {
      const { data: res } = await paymentDetailApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.paymentDetail = res.data.detail
    },
    routerJump(val) {
      if (val === 1) {
        this.$router.back()
      } else {
        this.$router.push({ path: '/editBalanceAccount', query: { result: JSON.stringify(this.id) } })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.balanceAccountDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .balanceAccountDetailContent {
    margin-top: 40px;
    header {
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          .statusBox1, .statusBox2, .statusBox3{
            margin-left: 20px;
            padding: 5px 8px;
            width: 52px;
            height: 22px;
            border-radius: 4px;
            background: rgba(70,147,235,0.05);
            border: 1px solid rgba(70,147,235,0.2);
            font-size: 12px;
            color: rgba(70, 147, 235, 1);
          }
          .statusBox2 {
            border: 1px solid rgba(242,90,90,0.2);
            background: rgba(242,90,90,0.05);
            color: rgba(242, 90, 90, 1);
          }
          .statusBox3 {
            border: 1px solid rgba(59, 212, 144, 0.20);
            background: rgba(59, 212, 144, 0.05);
            color: rgba(59, 212, 144, 1);
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    section {
      margin: 2px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
    main {
      margin: 2px 20px;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 14px;
        width: 100px;
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input {
        width: calc(50% - 110px);
        .el-input__inner {
          background-color: rgba(0, 0, 0, 0.05);
          border: 1px solid transparent;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.80);
          cursor: default;
        }
      }
    }
    footer {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        span {
          float: right;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
  }
}
</style>
