// 付款单详情
<template>
  <div class="paymentDetail">
    <div class="paymentDetailContent">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/balanceAccount' }">付款单</el-breadcrumb-item>
        <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">确认付款单</el-breadcrumb-item>
      </el-breadcrumb>
      <header>
        <div>
          <h5 style="margin-bottom: 20px;">确认付款单</h5>
          <h5>付款单编号: {{paymentDetail.number + ' (' + paymentDetail.sellerName + ')'}}</h5>
          <p>创建时间: <span>{{paymentDetail.createTime + '(' + paymentDetail.nickName + ')'}}</span></p>
        </div>
      </header>
      <main style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
        <p>总金额(含税):</p><el-input v-model="paymentDetail.amountTax" disabled></el-input>
        <p style="margin-left: 10px;">总金额(不含税):</p><el-input v-model="paymentDetail.amount" disabled></el-input>
      </main>
      <section>
        <h6>付款单明细</h6>
          <el-table ref="tableHeight" max-height="241" :data="paymentDetailList" row-key="index" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineList'}" :show-overflow-tooltip="true">
            <el-table-column show-overflow-tooltip prop="orderNum" label="采购单号" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.lineList">-</span>
                <span v-else>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.lineList">-</span>
                <span v-else>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.lineList">-</span>
                <span v-else>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="warrantCount" label="入库数量" min-wid th="80px"  align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="returnCount" label="退货数量" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="resAmount" label="产品总价" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="yfkAmount" label="预付款金额" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="totalAmount" label="应付金额" min-width="80px" align="center"></el-table-column>
          </el-table>
      </section>
      <section>
        <h6>其他扣款单</h6>
        <el-table ref="tableHeight" max-height="245" :data="paymentDtnList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="number" label="扣款单号" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="扣款名称" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="remark" label="扣款理由" min-width="280px"  align="left">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.remark}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="price" label="扣款金额" min-width="80px" align="center"></el-table-column>
        </el-table>
      </section>
    </div>
    <el-form ref="ruleForm" :model="prePaymentData" label-width="102px" :rules="rules">
      <footer>
        <div class="footerBox">
          <el-form-item label="付款方式:" prop="type">
            <el-select v-model="prePaymentData.type" placeholder="请选择付款方式" clearable size="mini">
              <el-option label="现金" :value="1"></el-option>
              <el-option label="转账" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="付款日期:" prop="payTime">
            <el-date-picker v-model="prePaymentData.payTime" type="datetime" placeholder="请选择付款时间" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
          </el-form-item>
          <el-form-item label="银行名称:" prop="bank">
            <el-input type="text" maxlength="30" v-model="prePaymentData.bank" placeholder="请输入银行名称"></el-input>
          </el-form-item>
          <el-form-item label="银行账号:" prop="bankNum">
            <el-input type="number" oninput="if(value.length > 20) value=value.slice(0, 20)" v-model="prePaymentData.bankNum" placeholder="请输入银行账号"></el-input>
          </el-form-item>
        </div>
        <el-button class="lightDeepStyle" @click="editPayment()">提交</el-button>
        <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
      </footer>
    </el-form>
  </div>
</template>
<script>
import { delPrePaymentApi, paymentDetailApi, paymentDetailListApi, paymentDtnListApi, editPaymentApi } from '@/api'
export default {
  data() {
    return {
      id: '',
      // 付款单详情
      paymentDetail: '',
      // 付款单明细
      paymentDetailList: [],
      // 其他扣款明细
      paymentDtnList: [],
      // 确认付款单传参
      prePaymentData: {
        id: '',
        type: '',
        payTime: '',
        bank: '',
        bankNum: ''
      },
      // 校验
      rules: {
        payTime: [
          { required: true, message: '请选择付款日期!', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择付款方式!', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.id = JSON.parse(this.$route.query.result)
    this.prePaymentData.id = JSON.parse(this.$route.query.result)
    this.getPaymentDetail()
    this.getPaymentDetailList()
    this.getPaymentDtnList()
  },
  methods: {
    // 确认付款单
    editPayment() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const { data: res } = await editPaymentApi(this.prePaymentData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('付款成功!')
          this.$router.back()
        } else {
          return false
        }
      })
    },
    // 删除
    delPayment() {
      this.$confirm("是否确认撤回该付款单，重新生成对账单？", "撤回重选", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await delPaymentApi(this.id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('撤回成功!')
        this.$router.back()
      })
    },
    // 获取付款单详情
    async getPaymentDetail() {
      const { data: res } = await paymentDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.paymentDetail =  res.data.detail
    },
    // 获取付款单明细
    async getPaymentDetailList() {
      const { data: res } = await paymentDetailListApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      // 付款单明细
      let newPaymentDetail = res.data.list
      // 给数据添加唯一值index,用于展开
      if (newPaymentDetail.length !== 0) {
        newPaymentDetail.forEach((item, index) => {
          item.index = index + ''
          if (item.lineList.length !== 0) {
            item.lineList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
      this.paymentDetailList = newPaymentDetail
    },
    // 其他扣款单列表
    async getPaymentDtnList() {
      const { data: res } = await paymentDtnListApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.paymentDtnList = res.data.list
    },
    routerJump() {
      this.$router.back()
    }
  }
};
</script>
<style lang="less" scoped>
.paymentDetail {
  .paymentDetailContent {
    height: calc(100vh - 250px);
    overflow-y: auto;
    .nowPage {
      /deep/ .el-breadcrumb__inner {
        color: rgba(18, 62, 131, 1);
      }
    }
    header {
      margin: 0 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          .statusBox1, .statusBox2{
            padding: 5px 8px;
            width: 52px;
            height: 22px;
            border-radius: 4px;        
            background: rgba(239, 188, 96, 0.05);
            border: 1px solid rgba(239, 188, 96, 0.2);
            font-size: 12px;
            color: rgba(239, 188, 96, 1);
          }
          .statusBox2 {
            background: rgba(70,147,235,0.05);
            border: 1px solid rgba(70,147,235,0.2);
            color: rgba(70, 147, 235, 1);
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    section {
      margin: 2px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
    main {
      margin: 2px 20px;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 14px;
        width: 100px;
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input {
        width: calc(50% - 110px);
        .el-input__inner {
          background-color: rgba(0, 0, 0, 0.05);
          border: 1px solid transparent;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.80);
          cursor: default;
        }
      }
    }
    section {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        span {
          float: right;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 180px;
    width: calc(100% - 150px);
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
    z-index: 1;
    .footerBox {
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      /deep/.el-form-item {
        position: relative;
        margin: 10px 0;
        width: 50%;
        .priceBox {
          position: absolute;
          right: 10px;
        }
        .el-form-item__error {
          padding: 5px 0 0 0;
        }
        .el-form-item__content {
          width: calc(100% - 112px);
          .el-input {
            width: 100%;
          }
          .el-select {
            width: 100%;
          }
          .el-cascader {
            width: 100%;
          }
        }
      }
      /deep/.el-form-item:nth-child(5) {
        width: 66.6%;
      }
    }
  }
}
</style>