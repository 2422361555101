// 付款单列表
<template>
  <div class="paymentManage">
    <nav>
      <h5>付款单</h5>
      <div>
        <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="paymentData.number" placeholder="请输入付款单编号" @input="getPaymentList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="paymentData.sellerName" placeholder="请输入供应商名称" @input="getPaymentList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="创建日期区间"
          end-placeholder="创建日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select v-model="paymentData.status" placeholder="请选择付款状态" clearable size="mini" @change="getPaymentList()">
        <el-option label="待付款" :value="0"></el-option>
        <el-option label="已付款" :value="1"></el-option>
      </el-select>
    </section>
    <main>
      <el-table ref="tableHeight" :height="tableHeight" :data="paymentManageList" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="number" label="付款单编号" min-width="100px" align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.number}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="sellerName" label="供应商" min-width="130px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="amount" label="总金额(不含税)" min-width="100px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="amountTax" label="总金额(含税)" min-width="100px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="120px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="auditStatus" label="状态" min-width="120px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.auditStatus === 0 ? '待确认' : (scope.row.auditStatus === 1 ? '已确认' : '已取消')}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="status" label="付款状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.status === 0" class="statusBox1">待付款</span>
              <span v-else class="statusBox2">已付款</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="fileStatus" label="发票上传" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.fileStatus == 0" style="color: rgba(242, 90, 90, 1)">未上传</span>
              <span v-else-if="scope.row.fileStatus == 1" style="color: rgba(70, 147, 235, 1)">已上传</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="1" v-if="scope.row.status === 0 && btnLimit.includes('付款')">
                  <i class="el-icon-eleme"></i><span>付款</span>
                </el-dropdown-item>
                <el-dropdown-item command="2" v-if="scope.row.status !== 0 && scope.row.auditStatus === 0 && btnLimit.includes('撤回重选')">
                  <i class="el-icon-refresh-left"></i><span>撤回</span>
                </el-dropdown-item>
                <el-dropdown-item command="3">
                  <i class="el-icon-warning-outline"></i><span>查看</span>
                </el-dropdown-item>
                <el-dropdown-item command="4" v-if="scope.row.fileStatus == 1">
                  <i class="el-icon-warning-outline"></i><span>查看发票</span>
                </el-dropdown-item>
                <el-dropdown-item command="5" v-if="scope.row.status !== 0 && scope.row.auditStatus === 2 && btnLimit.includes('重新付款')">
                  <i class="el-icon-eleme"></i><span>重新付款</span>
                </el-dropdown-item>
                <el-dropdown-item command="6" v-if="scope.row.status === 0 && btnLimit.includes('删除')">
                  <i class="el-icon-eleme"></i><span>删除</span>
                </el-dropdown-item>
                <el-dropdown-item command="7" v-if="scope.row.status !== 0 && scope.row.auditStatus === 2 && btnLimit.includes('删除')">
                  <i class="el-icon-eleme"></i><span>删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="paymentData.size"
        :total="total">
      </el-pagination>
    </main>
    <!-- 查看附件 -->
    <el-dialog class="uploadDialog" title="查看附件" :visible.sync="uploadDialog" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <ul>
          <li style="position:relative; margin-top: 10px; width: 350px; cursor: pointer;" v-for="(item, index) in fileList" :key="index">
            <a class="textHidden" style="display: inline-block; width: 280px;" :href="item">{{item}}
              <span style="position: absolute; top: 0; right: 0;">附件下载</span>
            </a>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="uploadDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Utils from '../../util.js'
import { paymentListApi, delPaymentApi, fkdExportApi } from '@/api'
export default {
  name: 'paymentManage',
  data() {
    return {
      // 附件列表
      fileList: [],
      // 附件弹框
      uploadDialog: false,
      // 列表动态高度
      tableHeight: '',
      // 采购退料列表
      paymentManageList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      paymentData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        number: '',
        sellerName: '',
        startTime: '',
        endTime: '',
        status: ''
      },
      chooseTime: '',
      // 选择的付款单
      chooseBalance: [],
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
    this.getPaymentList()
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getPaymentList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routerJump(3, result.id)
      } else if (command === '2') {
        this.delPayment(result.id, '撤回')
      } else if (command === '3') {
        this.routerJump(2, result.id)
      } else if (command === '4') {
        // 查看附件
        this.fileList = (result.fileUrl).split(',')
        this.uploadDialog = true
      } else if (command === '5') {
        this.routerJump(3, result.id)
      } else {
        this.delPayment(result.id, '删除')
      }
    },
    // 删除付款单
    delPayment(id, val) {
      let inner = val
      this.$confirm("是否确认" + inner + "该付款单？", "撤回重选", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await delPaymentApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        if (inner == '撤回') {
          this.$message.success('撤回成功!')
        } else {
          this.$message.success('删除成功!')
        }
        this.getPaymentList()
      })
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 2) {
        this.$router.push({ path: "/paymentDetail", query: { result: JSON.stringify(result) } })
      } else {
        this.$router.push({ path: "/editPayment", query: { result: JSON.stringify(result) }})
      }
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.paymentData.startTime = ''
        this.paymentData.endTime = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value);
          let getYear = timers.getFullYear();
          let nowMonth = timers.getMonth() + 1;
          let getDate = timers.getDate();
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.paymentData.startTime = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.paymentData.endTime = getYear + '-' + nowMonth + '-' + getDate + ' 23:59:59'
          }
        });
      }
      this.getPaymentList()
    },
    // 获取付款单列表
    async getPaymentList(reset) {
      if (reset !== 'reset') {
        this.paymentData.page = 1
      }
      const { data: res } = await paymentListApi(this.paymentData)
      // 获取表单数据
      this.paymentManageList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.paymentData.size = val
      this.paymentData.page = 1
      this.getPaymentList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.paymentData.page = val
      this.currentPage = val
      this.getPaymentList('reset')
    },
    async purchaseReturnExport() {
      const { data: res } = await fkdExportApi(this.paymentData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getPaymentList()
    }
  }
}
</script>
<style scoped lang='less'>
.paymentManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      .statusBox1, .statusBox2{
        padding: 5px 8px;
        width: 52px;
        height: 22px;
        border-radius: 4px;        
        background: rgba(239, 188, 96, 0.05);
        border: 1px solid rgba(239, 188, 96, 0.2);
        font-size: 12px;
        color: rgba(239, 188, 96, 1);
      }
      .statusBox2 {
        background: rgba(70,147,235,0.05);
        border: 1px solid rgba(70,147,235,0.2);
        color: rgba(70, 147, 235, 1);
      }
    }
  }
  /deep/ .uploadDialog {
    .el-dialog {
      width: 400px;
      height: 450px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        padding-bottom: 0;
        height: 340px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
