<template>
  <!-- 登录页面 -->
  <div class="login_container">
    <!-- 左侧盒子 -->
    <div class="login_left">
      <!-- 左侧图片 -->
      <div class="login_img">
        <img src="../assets/img/loginBanner.png"/>
        <img class="imgbox" src="../assets/img/Group 12.png">
      </div>
      <!-- 左侧文字 -->
      <div class="login_footer">
        <span>
          <a href="javascript:;">网站首页</a> |
          <a href="javascript:;">帮助中心</a> |
          <a href="javascript:;">联系我们</a> |
          <a href="javascript:;">招聘信息</a> |
          <a href="javascript:;">客户服务</a> |
          <a href="javascript:;">隐私政策</a> |
          <a href="javascript:;">广告服务</a> |
          <a href="javascript:;">网站地图</a> |
          <a href="javascript:;">意见反馈</a>
        </span>
        <!-- 供应链管理平台 版权所有©️2019  -->
        <p class="footer_p">
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;vertical-align: bottom"
          >
            <p
              style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;"
            >浙公网安备 浙ICP备20018181号-1</p>
          </a>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33021202001647"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;vertical-align: bottom"
          >
            <img src="../assets/img/recordIcon.png" style="float:left;" width="16px;" />
            <p
              style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;"
            >浙公网安备 33021202001647号</p>
          </a>
        </p>
      </div>
    </div>
    <!-- 右侧盒子 -->
    <div class="login_rigth">
      <div v-show="flag == 3" class="loginChangeBox" @click="changeRole()">
        <span>采购端</span>
        <span>供应端</span>
        <p :class="chooseRole === true ? '' : 'chooseBox'">{{chooseRole === true ? '采购端' : '供应端'}}</p>
      </div>
      <div v-show="flag != 3" style="border: 0;" class="loginChangeBox"></div>
      <div class="title">
        <p>欢迎您登录</p>
        <p>
          <img src="@/assets/img/位图.png" alt />
        </p>
        <p>炙讯云-SRM</p>
      </div>
      <!-- 登录模块 -->
      <div class="login_box" v-show="flag == 0">
        <div class="login_tag">
          <p @click="flag=0" :class="[flag==0?'on':'active']">账号登录</p>
          <p @click="flag=1" :class="[flag==1?'on':'active']">短信登录</p>
        </div>
        <el-form :model="ruleForm1" :rules="rules" ref="ruleForm1">
          <label>
            <p>手机号</p>
            <el-form-item prop="mobile">
              <el-input
                v-model.trim="ruleForm1.mobile"
                placeholder="输入手机号"
                type="text"
                prefix-icon="el-icon-mobile-phone"
                clearable
              ></el-input>
            </el-form-item>
          </label>
          <label>
            <p>密码</p>
            <el-form-item prop="password">
              <el-input
                v-model.trim="ruleForm1.password"
                placeholder="输入密码"
                type="password"
                prefix-icon="el-icon-unlock"
                clearable
              ></el-input>
            </el-form-item>
          </label>
          <div class="checkBox_box">
            <el-form-item>
              <!-- <el-checkbox v-model.trim="ruleForm1.choose">记住密码</el-checkbox> -->
              <el-checkbox v-model.trim="ruleForm1.auto">自动登录</el-checkbox>
            </el-form-item>
            <p style="cursor: pointer" @click="flag=2">忘记密码?</p>
          </div>
          <el-form-item>
            <el-button class="loginBtn" @click="submitCheck()">登 录</el-button>
          </el-form-item>
          <div class="form_layout">
            <!-- <el-link @click="routerJump('/register')" type="primary" :underline="false">注册新用户</el-link> -->
            <!-- <el-link @click="routerJump('/resetPassword')" type="primary" :underline="false">忘记密码?</el-link> -->
            <el-link @click="flag=3" type="primary" class="enrollbtn">没有账号?注册新用户</el-link>
          </div>
        </el-form>
      </div>
      <!-- 短信登录模块 -->
      <div class="enroll_box" v-show="flag == 1">
        <div class="login_tag">
          <p @click="flag=0" :class="[flag==0?'on':'active']">账号登录</p>
          <p @click="flag=1" :class="[flag==1?'on':'active']">短信登录</p>
        </div>
        <el-form :model="ruleForm2" :rules="rules" ref="ruleForm2">
          <!-- 手机号 -->
          <label>
            <p>手机号</p>
            <el-form-item prop="mobile">
              <el-input
                v-model.trim="ruleForm2.mobile"
                placeholder="输入手机号"
                prefix-icon="el-icon-mobile-phone"
                clearable
              ></el-input>
            </el-form-item>
          </label>
          <!-- 验证码 -->
          <label>
            <p>验证码</p>
            <el-form-item prop="code" class="el_form_item_shortMsg">
              <el-input
                v-model.trim="ruleForm2.code"
                name="shortMsg"
                placeholder="输入验证码"
                prefix-icon="iconfont icon-duanxinyanzhengma"
              ></el-input>
              <el-button
                class="loginBtn lightDeepStyle"
                @click="sendVerificationdx('ruleForm2')"
                ref="sendVeridx"
                :disabled="controlBtn"
              >发送验证码</el-button>
            </el-form-item>
          </label>
          <div class="checkBox_box">
            <el-form-item>
              <!-- <el-checkbox v-model.trim="ruleForm1.choose">记住密码</el-checkbox> -->
              <el-checkbox v-model="ruleForm1.auto">自动登录</el-checkbox>
            </el-form-item>
            <p style="cursor: pointer" @click="flag=2">忘记密码?</p>
          </div>
          <el-form-item>
            <el-button class="loginBtn" @click="submitForm2('ruleForm2')">登 录</el-button>
          </el-form-item>
        </el-form>
        <div class="form_layout">
          <!-- <el-link @click="routerJump('/register')" type="primary" :underline="false">注册新用户</el-link> -->
          <!-- <el-link @click="routerJump('/resetPassword')" type="primary" :underline="false">忘记密码?</el-link> -->
          <el-link @click="flag=3" type="primary" class="enrollbtn">没有账号?注册新用户</el-link>
        </div>
      </div>
      <!-- 忘记密码模块 -->
      <div class="Forgotpwd" v-show="flag == 2">
        <h2>忘记密码</h2>
        <div class="stepBox">
          <p style="color: rgba(0, 0, 0, 0.80);"><i style="color: #fff; background-color: rgba(18, 62, 131, 1);">1</i>登录账号</p><span>一</span><p><i>2</i>设置新密码</p><span>一</span><p><i>3</i>重置成功</p>
        </div>
        <!-- 1.验证身份表单 -->
        <el-form :model="ruleForm3" :rules="rules" ref="ruleForm3">
          <label>
            <p>手机号</p>
            <el-form-item prop="mobile">
              <el-input
                v-model.trim="ruleForm3.mobile"
                placeholder="请输入手机号码"
                type="text"
                prefix-icon="el-icon-mobile-phone"
                clearable
              ></el-input>
            </el-form-item>
          </label>
          <label>
            <p>验证码</p>
            <el-form-item prop="code" class="el_form_item_shortMsg">
              <el-input
                v-model.trim="ruleForm3.code"
                name="shortMsg"
                placeholder="请输入短信验证码"
                prefix-icon="iconfont icon-duanxinyanzhengma"
                clearable
              ></el-input>
              <el-button
                class="loginBtn"
                @click="sendVerification3('ruleForm3')"
                ref="sendVeri3"
                :disabled="controlBtn3"
              >发送验证码</el-button>
            </el-form-item>
          </label>
          <el-form-item>
            <el-button style="margin-top: 10px;" class="lightDeepStyles" @click="submitForm3('ruleForm3')">下一步</el-button>
            <el-button style="margin-top: 20px;" class="lightDeepStyleqx" @click="flag=0">去登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 注册模块 -->
      <div class="Newusers" v-show="flag == 3">
        <!-- <h2>注册新用户</h2> -->
        <!-- 注册表单 -->
        <el-form :model="ruleForm5" :rules="rules" ref="ruleForm5">
          <!-- 为了解决浏览器保存代码在注册页面自动填充(添加了两个隐藏的输入框) -->
          <div class="hidden-input">
            <input type="text" class="form-control" />
          </div>
          <div class="hidden-input">
            <input type="password" class="form-control" />
          </div>
          <!-- <label v-if="chooseRole === true">
            <p>公司名称</p>
            <el-form-item prop="company">
              <el-input
                v-model.trim="ruleForm5.company"
                placeholder="请输入公司名称"
                type="text"
                prefix-icon="iconfont icon-yonghuming"
                autocomplete="off"
                clearable
              ></el-input>
            </el-form-item>
          </label>
          <label v-if="chooseRole === true">
            <p>姓名</p>
            <el-form-item v-if="againStatus !== '1'" prop="contact">
              <el-input
                v-model.trim="ruleForm5.contact"
                placeholder="请输入联系人"
                type="text"
                prefix-icon="iconfont icon-shouji"
                clearable
              ></el-input>
            </el-form-item>
          </label> -->
          <label>
            <p>手机号</p>
            <el-form-item v-if="againStatus !== '1'" prop="mobile">
              <el-input
                v-model.trim="ruleForm5.mobile"
                placeholder="请输入手机号码"
                type="text"
                prefix-icon="iconfont icon-shouji"
                clearable
              ></el-input>
            </el-form-item>
          </label>
          <label>
            <p>验证码</p>
            <el-form-item v-if="againStatus !== '1'" prop="code" class="el_form_item_shortMsg">
              <el-input
                v-model.trim="ruleForm5.code"
                name="shortMsg"
                placeholder="请输入短信验证码"
                prefix-icon="iconfont icon-duanxinyanzhengma"
                clearable
              ></el-input>
              <el-button
                class="loginBtn lightDeepStyle"
                @click="NewsendVerification('ruleForm5')"
                ref="sendVeri"
                :disabled="controlBtn"
              >发送验证码</el-button>
            </el-form-item>
          </label>
          <el-form-item class="el-form-reg">
            <el-button v-if="chooseRole === true" class="lightDeepStyle" @click="NewsubmitForm('ruleForm5')" style="font-size: 15px; font-weight: 500;">注册成为采购商</el-button>
            <el-button v-if="chooseRole === false" class="lightDeepStyle" @click="NewsubmitForm('ruleForm5')" style="font-size: 15px; font-weight: 500;">注册成为供应商</el-button>
          </el-form-item>
          <el-button style="height: 40px;" class="lightDeepStyleqx" @click="flag=0">去登录</el-button>
        </el-form>
      </div>
      <!-- 设置新密码 -->
      <div class="Forgotpwd" v-show="flag == 4">
        <h2>忘记密码</h2>
        <div class="stepBox">
          <p style="color: rgba(0, 0, 0, 0.80);"><i style="color: #fff; background-color: rgba(18, 62, 131, 1);">1</i>登录账号</p>
          <span style="color: rgba(18, 62, 131, 1);">一</span>
          <p style="color: rgba(0, 0, 0, 0.80);"><i style="color: #fff; background-color: rgba(18, 62, 131, 1);">2</i>设置新密码</p>
          <span>一</span>
          <p><i>3</i>重置成功</p>
        </div>
        <!-- 2.设置新密码 -->
        <el-form :model="ruleForm4" :rules="rules" ref="ruleForm4">
          <label>
            <p>新密码</p>
            <el-form-item prop="password">
              <el-input
                v-model.trim="ruleForm4.password"
                placeholder="设置六至二十位登录密码"
                type="password"
                prefix-icon="iconfont icon-iconmm"
                clearable
              ></el-input>
            </el-form-item>
          </label>
          <label>
            <p>确定新密码</p>
            <el-form-item prop="checkPassword">
              <el-input
                v-model.trim="ruleForm4.checkPassword"
                placeholder="请再次输入登录密码"
                type="password"
                prefix-icon="iconfont icon-iconmm"
                clearable
              ></el-input>
            </el-form-item>
          </label>
          <el-form-item>
            <el-button class="lightDeepStyle" @click="submitForm4('ruleForm4')">确定</el-button>
            <el-button style="margin-top: 20px;" class="lightDeepStyleqx" @click="flag=0">去登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 密码设置成功 -->
      <div class="Forgotpwd" v-show="flag==5">
        <h2>忘记密码</h2>
        <div class="stepBox">
          <p style="color: rgba(0, 0, 0, 0.80);"><i style="color: #fff; background-color: rgba(18, 62, 131, 1);">1</i>登录账号</p>
          <span style="color: rgba(18, 62, 131, 1);">一</span>
          <p style="color: rgba(0, 0, 0, 0.80);"><i style="color: #fff; background-color: rgba(18, 62, 131, 1);">2</i>设置新密码</p>
          <span style="color: rgba(18, 62, 131, 1);">一</span>
          <p style="color: rgba(0, 0, 0, 0.80);"><i style="color: #fff; background-color: rgba(18, 62, 131, 1);">3</i>重置成功</p>
        </div>
        <div class="border_box">
          <img src="../assets/img/success.png" />
          <p>重置成功</p>
          <p class="time">倒计时 {{count}}s 跳转到登陆界面</p>
          <el-button class="lightDeepStyleqdl" @click="flag=0">去登录</el-button>
        </div>
      </div>
      <!-- 注册成功 -->
      <div class="Newusers" v-show="flag==6">
        <h2 class="h2s">注册新用户</h2>
        <div class="border_box">
          <img src="../assets/img/success.png" alt />
          <p>提交信息成功</p>
          <p class="time">工作人员将于1-3个工作日与您联系，请保持电话的</p>
          <p class="time2">畅通，谢谢</p>
          <el-button class="lightDeepStyleqdl" @click="flag=0">返回</el-button>
        </div>
      </div>
      <!-- 选择公司 -->
      <div class="chooseCompany" v-show="flag==7">
        <h2 class="h2s">选择公司</h2>
        <ul v-if="moreCompanyList.length > 0">
          <li v-for="(item, index) in moreCompanyList" :key="index" @click="chooseCompanyId = item.companyId" :class="chooseCompanyId === item.companyId ? 'chooseLi' : ''">{{item.companyName}}</li>
        </ul>
        <ul v-else>
          <div class="emptyBg">
            <img src="../assets/img/notData.png" style="width:50%; margin-top: 0;">
            <span style="display: block; margin-top: -5%; color: #ccc">暂无数据</span>
          </div>
        </ul>
        <div @click="updateDefaultCompany()" class="loginBox">登入</div>
        <div @click="flag = 0" class="cancelBox">返回</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Base64 } from 'js-base64'
import { loginApi, sendVerificationApi, phoneLoginApi, checkShortMsgApi, changePasswordApi, userRegisterApi, moreCompanyListApi, updateDefaultCompanyApi } from '@/api'
import Utils from '@/utils'
// import waveBtn from '../../src/assets/effect/waveBtn.js'
export default {
  data() {
    // 部分校验(密码校验)
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm4.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      // 选中的公司
      chooseCompanyId: '',
      // 多公司列表
      moreCompanyList: [],
      // 选择端(true采购端 false供应端)
      chooseRole: true,
      // 注册成功返回的企业号信息
      companyNum: '',
      // 注册成功返回的手机号信息
      username: '',
      // 判断是不是被驳回后再次注册
      againStatus: this.$route.query.again,
      // 倒计时
      count: '',
      // 忘记密码进度条一
      active: 0,
      // 忘记密码进度条二
      active2: 1,
      // 忘记密码进度条三
      active3: 2,
      // 显示隐藏
      flag: 0,
      // 动态获取底部盒子高度
      actionHeight: 220,
      // 存储当前域名
      host: '',
      // 存储当前公司名称
      company: '',
      companyH: '',
      companyF: '',
      // 存储当前公司背景图
      companyBgm: '',
      // 控制是否展示新公司信息
      controlInfo: false,
      controlBtn3: false,
      companyStyle: {
        backgroundImage: this.controlInfo
          ? 'url(' + this.companyBgm + ')'
          : 'url(' + require('../assets/img/bgc4.jpg') + ')'
        // backgroundSize: 'cover'
      },
      // 密码登录数据
      ruleForm1: {
        mobile: '',
        password: '',
        // 记住登录勾选状态
        choose: false,
        // 自动登录状态
        auto: false,
        buyId: null
      },
      // 验证码登录数据
      ruleForm2: {
        mobile: '',
        code: '',
        buyId: null
      },
      // 忘记密码一
      ruleForm3: {
        mobile: '',
        code: ''
      },
      // 忘记密码二
      ruleForm4: {
        mobile: '',
        code: '',
        password: ''
      },
      // 注册数据
      ruleForm5: {
        company: '',
        contact: '',
        mobile: '',
        code: ''
      },
      // 登录框校验
      rules: {
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[34578]\d{9}$/, message: '请输入正确格式的手机号码' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/,
            message: '密码长度应为6~16个字符且包含字母和数字'
          }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{4}$/, message: '验证码格式不正确' }
        ],
        mobilephone: [
          { required: false, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[34578]\d{9}$/, message: '请输入正确格式的手机号码' }
        ],
        phone: [
          { required: false, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[34578]\d{9}$/, message: '请输入正确格式的手机号码' }
        ],
        checkPassword: [{ validator: validatePass2, trigger: 'blur' }],
        company: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
          { min: 2, max: 100, message: '不得小于两个字符', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          { pattern: /^[a-zA-Z\u4e00-\u9fa5]+$/, message: '只能包含字母和中文' }
        ],
        username: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[34578]\d{9}$/, message: '请输入正确格式的手机号码' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{4}$/, message: '验证码格式不正确' }
        ]
      },
      // 控制验证码按钮的禁用状态
      controlBtn: false,
      msg: '',
      text: '向右拖动滑块完成拼图',
      // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      accuracy: 4,
      // 判断有无错误提示
      errorMsg: false,
      // 将登录接口返回的登录信息先存起来
      loginInfo: ''
    }
  },
  created() {
    this.host = window.location.host
    // this.host = 'zl.bmxgj.cn'
    this.actionHeight = window.innerHeight - 800
    if (this.$cookies.isKey('mobile')) {
      this.ruleForm1.mobile = this.$cookies.get('mobile')
    }
    if (this.$cookies.isKey('password')) {
      this.ruleForm1.password = Utils.decrypt(
        this.$cookies.get('password'),
        'hAw6eqnFLKxpsDv3'
      )
    }
    if (this.$cookies.isKey('choose')) {
      this.ruleForm1.choose = true
    }
    // this.useWaveBtnJS()
  },
  mounted() {
    // 自动登录调用功能
    this.autoSubmitForm()
    // 阻止刷新重置验证码
    this.judgeCode()
    // 绑定回车实现登录
    window.addEventListener('keydown', this.keyDown)
  },
  methods: {
    // 切换端
    changeRole() {
      this.chooseRole = !this.chooseRole
      this.chooseCompanyId = ''
      window.sessionStorage.setItem('roleType', this.chooseRole === true ? '1' : '2')
      this.getMoreCompanyList()
    },
    // 确认选择公司
    async updateDefaultCompany() {
      if (this.chooseCompanyId === '') return this.$message.error('请选择需要登入的公司')
      const { data: res } = await updateDefaultCompanyApi(this.chooseCompanyId)
      if (res.code !== 0) return this.$message.error('切换失败!')
      // 重新存储公司
      let newIndex = ''
      this.moreCompanyList.forEach((item,index) => {
        if (item.companyId === this.chooseCompanyId) {
          window.sessionStorage.setItem('roleType', item.roleType)
          newIndex = index
        }
      })
      if (this.moreCompanyList[newIndex].expStatus === 0) {
        this.$message.error('所选企业会员已到期，暂时无法使用，如有疑问请联系平台管理人员。')
        return false
      }
      window.$cookies.set('companyId', this.chooseCompanyId)
      this.$router.push('/index')
    },
    // 获取多公司列表
    // this.chooseRole === true ? '1' : '2'
    async getMoreCompanyList() {
      const { data: res } = await moreCompanyListApi({ type: 1, roleType: '' })
      this.moreCompanyList = res.data.list
    },
    // 使用水波纹js
    // useWaveBtnJS() {
    //   waveBtn()
    // },
    onSuccess(times) {
      this.text = '登录成功, 耗时' + (times / 1000).toFixed(1) + 's'
      this.$refs.slideblock.reset()
      this.$refs.slideVerify.style.display = 'none'
      this.submitForm('ruleForm1')
    },
    onFail() {
      console.log('验证不通过')
      this.text = '验证失败,请再试一次'
    },
    onRefresh() {
      console.log('点击了刷新小图标')
      this.text = ''
    },
    onFulfilled() {
      console.log('刷新成功啦！')
    },
    onAgain() {
      console.log('检测到非人为操作的哦！')
      this.text = '请再试一次'
      // 刷新
      this.$refs.slideblock.reset()
    },
    // 跳转至登录页面
    routerJump(path) {
      this.$router.push(path)
    },
    format(percentage) {
      return percentage === 100 ? '满' : `${percentage}%`
    },
    // 登录前校验拼图
    submitCheck() {
      if (this.errorMsg === true) {
        this.$refs.slideVerify.style.display = 'block'
      } else {
        this.submitForm('ruleForm1')
      }
    },
    // 登录校验及调用密码登录接口
    submitForm(formName, controlJump) {
      this.$refs[formName].validate(async valid => {
        if (!valid) return false
        let encryPassword =
          'hAw6eqnFLKxpsDv3' + Base64.encode(this.ruleForm1.password)
        const { data: res } = await loginApi({
          username: this.ruleForm1.mobile,
          password: encryPassword,
          buyId: this.ruleForm1.buyId
        })
        if (res.code == 504) {
          this.$message.error(res.msg)
        }
        if (res.data.companyId === 0) return this.$message.error('该账户已被禁用,请联系管理员!')
        if (res.code === -1) return this.$message.error(res.msg)
        if (this.ruleForm1.auto === true) {
          this.ruleForm1.choose = true
        }
        if (this.ruleForm1.choose === true) {
          // 设置cookies保存时间
          var date = new Date()
          date.setDate(date.getDate() + 15)
          // 对cookies中的密码进行crypt-js加密
          let psd = Utils.encrypt(this.ruleForm1.password, 'hAw6eqnFLKxpsDv3')
          this.$cookies.set('mobile', this.ruleForm1.mobile, date)
          this.$cookies.set('password', psd, date)
          this.$cookies.set('choose', this.ruleForm1.choose, date)
          this.$cookies.set('auto', this.ruleForm1.auto, date)
        } else {
          this.$cookies.remove('mobile')
          this.$cookies.remove('password')
          this.$cookies.remove('choose')
          this.$cookies.remove('auto')
        }
        // 存储企业号
        window.sessionStorage.setItem('companyNum', res.data.companyNum)
        window.sessionStorage.setItem('workStatus', res.data.workStatus)
        // 设置token并将token有效期设置为7天
        this.$cookies.set('token', res.data.token, { expires: 7 })
        // 存储当前选中的是采购端还是供应端
        window.sessionStorage.setItem('roleType', this.chooseRole === true ? '1' : '2')
        if (controlJump === true) {
          window.$cookies.set("companyId", res.data.companyId)
          // 如果是供应端就直接强制进入工作台去完善企业信息
          this.$router.push({ path: '/home', query: { username: this.ruleForm5.mobile, code: this.ruleForm5.code } })
        } else {
          // 再跳转之前先选择企业
          this.getMoreCompanyList().then(async() => {
            // 如果只有一家企业
            if (this.moreCompanyList.length === 1) {
              this.chooseCompanyId = this.moreCompanyList[0].companyId
              // 存储企业id
              window.$cookies.set('companyId', JSON.stringify(this.chooseCompanyId))
              window.sessionStorage.setItem('roleType', this.moreCompanyList[0].roleType)
              if (this.moreCompanyList[0].expStatus === 0) {
                this.$message.error('所选企业会员已到期，暂时无法使用，如有疑问请联系平台管理人员。')
                return false
              }
              this.updateDefaultCompany()
            // } else if (this.moreCompanyList.length === 0) {
              // // 重新获取另一端的企业
              // const { data: vals } = await moreCompanyListApi({ type: 1, roleType: this.chooseRole === true ? '2' : '1' })
              // let newMoreCompanyList = vals.data.list
              // // 判断另一端企业是否唯一
              // if (newMoreCompanyList.length === 1) {
              //   this.chooseCompanyId = newMoreCompanyList[0].companyId
              //   // 存储企业id
              //   window.$cookies.set('companyId', JSON.stringify(this.chooseCompanyId))
              //   window.sessionStorage.setItem('userInfo', JSON.stringify(vals.data.list))
              //   this.updateDefaultCompany()
              //   window.sessionStorage.setItem('roleType', this.chooseRole === true ? '1' : '2')
              // } else {
              //   this.flag = 7
              // }
            } else {
              this.flag = 7
            }
          })
          // this.$router.push("/home");
          // if (res.data.status === 0 && res.data.companyId === 0) {
          //   // 设置token并将token有效期设置为7天
          //   this.$cookies.set("token", res.data.token, { expires: 7 });
          //   window.sessionStorage.setItem("quitValue", 1);
          //   this.$router.push("/home");
          //   return false;
          // }
        }
      })
    },
    // 登录校验及调用手机登录接口
    submitForm2(formName) {
      this.$refs[formName].validate(async valid => {
        if (!valid) return false
        const { data: res } = await phoneLoginApi({
          username: this.ruleForm2.mobile,
          code: this.ruleForm2.code,
          buyId: this.ruleForm2.buyId
        })
        if (res.code !== 0) return this.$message.error(res.msg)
        if (res.data.companyId === 0) return this.$message.error('该账户已被禁用,请联系管理员!')
        this.loginInfo = res.data
        // 存储企业id
        window.$cookies.set('companyId', JSON.stringify(res.data.companyId))
        // 存储企业号
        window.sessionStorage.setItem('companyNum', res.data.companyNum)
        window.sessionStorage.setItem('workStatus', res.data.workStatus)
        this.$cookies.set('token', res.data.token, { expires: 7 })
        // 存储当前选中的是采购端还是供应端
        window.sessionStorage.setItem('roleType', this.chooseRole === true ? 1 : 2)
        // 再跳转之前先选择企业
        this.getMoreCompanyList().then(async() => {
          // 如果只有一家企业
          if (this.moreCompanyList.length === 1) {
            this.chooseCompanyId = this.moreCompanyList[0].companyId
            this.updateDefaultCompany()
          } else if (this.moreCompanyList.length === 0) {
            // 重新获取另一端的企业
            const { data: vals } = await moreCompanyListApi({ type: 1, roleType: this.chooseRole === true ? '2' : '1' })
            let newMoreCompanyList = vals.data.list
            // 判断另一端企业是否唯一
            if (newMoreCompanyList.length === 1) {
              this.chooseCompanyId = newMoreCompanyList[0].companyId
              this.updateDefaultCompany()
              window.sessionStorage.setItem('roleType', this.chooseRole === true ? '1' : '2')
            } else {
              this.flag = 7
            }
          } else {
            this.flag = 7
          }
        })
        // this.$router.push("/home");
      })
    },
    sendVerificationdx(formName) {
      // 校验手机号格式是否正确
      this.$refs[formName].validateField('mobile', async mobileError => {
        if (!mobileError) {
          const { data: res } = await sendVerificationApi({
            type: 6,
            mobile: this.ruleForm2.mobile
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '验证码发送成功,有效时间为30分钟!' })
          // 获取验证码按钮元素(实现验证码倒计时功能)
          var sendBtndx = this.$refs.sendVeridx.$el
          let num = 60
          let that = this
          let timer = setInterval(function() {
            this.$cookies.set('num', num--)
            that.controlBtn = true
            sendBtndx.innerHTML = num + '秒后重置'
            sendBtndx.style.color = '#fff'
            if (num <= 0) {
              that.controlBtn = false
              sendBtndx.style.color = '#fff'
              sendBtndx.innerHTML = '发送验证码'
              clearInterval(timer)
            }
          }, 1000)
        } else {
          this.$message.error('请填入正确的手机号')
          return false
        }
      })
    },
    // 自动登录
    async autoSubmitForm() {
      if (this.$cookies.get('auto') === 'true') {
        this.submitForm('ruleForm1')
        // const { data: res } = await loginApi({ username: this.ruleForm1.mobile, password: this.ruleForm1.password })
        // if (res.code !== 0) return this.$message.error('登录请求超时')
        // window.sessionStorage.setItem('token', res.data.token)
        // this.$router.push('/home')
      }
    },
    // 检验当前是否是可获取验证码状态
    judgeCode() {
      // 获取验证码按钮元素(实现验证码倒计时功能)
      if (this.$cookies.get('num')) {
        var sendBtn = this.$refs.sendVeri.$el
        let that = this
        let num = that.$cookies.get('num')
        let timers = setInterval(function() {
          that.$cookies.set('num', num--)
          that.controlBtn = true
          sendBtn.innerHTML = num + '秒后重置'
          sendBtn.style.color = '#ccc'
          if (num <= 0) {
            that.controlBtn = false
            sendBtn.style.color = '#7C7C7E'
            sendBtn.innerHTML = '发送验证码'
            clearInterval(timers)
          }
        }, 1000)
      }
    },
    // 判断按下回车键实现登录
    keyDown(e) {
      if (e.keyCode === 13) {
        this.submitCheck()
        this.submitForm2('ruleForm2')
      }
    },
    // 发送验证码
    sendVerification3(formName) {
      if (this.ruleForm3.mobile === '') { return this.$message.error('请先输入手机号码!') }
      // 校验手机号格式是否正确
      this.$refs[formName].validateField('mobile', async mobileError => {
        if (!mobileError) {
          const { data: res } = await sendVerificationApi({
            type: 2,
            mobile: this.ruleForm3.mobile
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '验证码发送成功,有效时间为30分钟!' })
          // 获取验证码按钮元素(实现验证码倒计时功能)
          var sendBtn3 = this.$refs.sendVeri3.$el
          let resNum = 60
          let that = this
          let timer = setInterval(function() {
            this.$cookies.set('resNum', resNum--)
            that.controlBtn3 = true
            sendBtn3.innerHTML = resNum + '秒后重置'
            sendBtn3.style.color = '#ccc'
            if (resNum <= 0) {
              that.controlBtn3 = false
              sendBtn3.style.color = '#ffa600'
              sendBtn3.innerHTML = '发送验证码'
              clearInterval(timer)
            }
          }, 1000)
        } else {
          this.$message.error('请填入正确的手机号')
          return false
        }
      })
    },
    // 前往第二部分(设置新密码)
    submitForm3(formName) {
      this.$refs[formName].validate(async valid => {
        if (!valid) return false
        const { data: res } = await checkShortMsgApi(this.ruleForm3)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.ruleForm4.mobile = this.ruleForm3.mobile
        this.ruleForm4.code = this.ruleForm3.code
        this.flag = 4
      })
    },
    // 前往第三部分(密码修改成功)
    submitForm4(formName) {
      this.$refs[formName].validate(async valid => {
        if (!valid) return false
        let editRuleForm = {
          password: 'hAw6eqnFLKxpsDv3' + Base64.encode(this.ruleForm4.password),
          mobile: this.ruleForm4.mobile,
          code: this.ruleForm4.code
        }
        const { data: res } = await changePasswordApi(editRuleForm)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.flag = 5
        // 密码修改成功触发倒计时
        if (this.flag == 5) {
          var s = 5
          var timecount = setInterval(() => {
            s--
            this.count = s
            if (s <= 0) {
              clearInterval(timecount)
              this.flag = 0
            }
          }, 1000)
        }
      })
    },
    // 注册验证码
    NewsendVerification(formName) {
      // 校验手机号格式是否正确
      this.$refs[formName].validateField('mobile', async mobileError => {
        if (!mobileError) {
          const { data: res } = await sendVerificationApi({
            type: 10,
            mobile: this.ruleForm5.mobile
          })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '验证码发送成功,有效时间为30分钟!' })
          // 获取验证码按钮元素(实现验证码倒计时功能)
          var sendBtn = this.$refs.sendVeri.$el
          let num = 60
          let that = this
          let timer = setInterval(function() {
            this.$cookies.set('num', num--)
            that.controlBtn = true
            sendBtn.innerHTML = num + '秒后重置'
            sendBtn.style.color = '#ccc'
            if (num <= 0) {
              that.controlBtn = false
              sendBtn.style.color = '#ffa600'
              sendBtn.innerHTML = '发送验证码'
              clearInterval(timer)
            }
          }, 1000)
        } else {
          return false
        }
      })
    },
    // 登录校验及调用登录接口
    async NewsubmitForm(formName) {
      // 如果是采购端就继续走注册
      // if (this.chooseRole === true) {
      //   this.$refs[formName].validate(async valid => {
      //     if (!valid) return false
      //     let newRuleForm = {
      //       name: this.ruleForm5.company,
      //       phone: this.ruleForm5.mobile,
      //       content: this.ruleForm5.mobile,
      //       code: this.ruleForm5.code
      //     }
      //     const { data: res } = await userRegisterApi(newRuleForm)
      //     if (res.code !== 0) return this.$message.error({ duration: 1000, message: res.msg })
      //     this.$message.success({ duration: 1000, message: "注册成功!" })
      //     this.ruleForm5 = {
      //       company: "",
      //       contact: "",
      //       mobile: "",
      //       code: ""
      //     }
      //     this.flag = 6
      //   })
      // } else {
        // 先判断有没有填手机号和验证码
        if (this.ruleForm5.mobile === '') return this.$message.error('请输入手机号!')
        if (this.ruleForm5.code === '') return this.$message.error('请输入验证码!')
        // 判断验证码是否正确
        const { data: res } = await checkShortMsgApi(this.ruleForm5)
        if (res.code !== 0) return this.$message.error(res.msg)
        window.$cookies.set('newToken', 'default')
        // 先去登录默认账号
        this.ruleForm1.mobile = '13077889999'
        this.ruleForm1.password = 'q123456'
        this.submitForm("ruleForm1", true)
      // }
    }
  }
}
</script>
<style lang="less" scoped>
.login_container {
  width: 100%;
  height: 100%;
  display: flex;
  // 左侧盒子
  .login_left {
    width: calc(100% - 380px);
    height: 100%;
    display: flex;
    flex-direction: column;
    .login_img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      img {
        width: 1000px;
        height: 700px;
      }
      .imgbox {
        position: fixed;
        top: 160px;
        left: 10%;
        width: 20%;
        height: auto;
      }
    }
    .login_footer {
      position: fixed;
      bottom: 20px;
      width: calc(100% - 380px);
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      span {
        display: block;
        width: 100%;
        text-align: center;
        a {
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .footer_p {
        text-align: center;
        p {
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
  // 右侧盒子
  .login_rigth {
    width: 380px;
    height: 100%;
    background-color: #fff;
    .loginChangeBox {
      position: relative;
      margin: 15vh 90px 0 90px;
      width: 200px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      cursor: pointer;
      line-height: 40px;
      p {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        background-color: #fff;
        border-radius: 40px;
        background-color: rgba(18, 62, 131, 1);
        color: #fff;
        text-align: center;
        transition: all .3s ease-out;
      }
      .chooseBox {
        left: 50%;
        background-color: rgba(18, 62, 131, 1);
        color: #fff;
      }
      span {
        display: inline-block;
        width: 100px;
        color: rgba(0, 0, 0, 0.60);
        text-align: center;
      }
    }
    .title {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      p:nth-child(1) {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
      }
      p:nth-child(2) {
        img {
          margin: 0 10px;
        }
      }
      p:nth-child(3) {
        width: 82px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
      }
    }

    .login_box {
      margin: 0 40px;
      label {
        p {
          width: 24px;
          height: 24px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          line-height: 24px;
          margin-bottom: 10px;
        }
      }
    }
    .enroll_box {
      margin: 0 40px;
      label {
        p {
          width: 24px;
          height: 24px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          line-height: 24px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .checkBox_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    p {
      width: 60px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #123e83;
      line-height: 17px;
      margin-top: -17px;
    }
    /deep/ .el-input--prefix .el-input__suffix {
      right: 10px;
    }
  }
  .loginBtn {
    width: 100%;
    color: white;
    font-weight: 500;
    height: 40px;
    background: #123e83;
    border-radius: 4px;
  }
  .loginBtn:hover {
    background: #123e83;
    color: #fff;
  }
  .login_tag {
    display: flex;
    margin-top: 40px;
    margin-bottom: 20px;
    p:nth-child(1) {
      width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 25px;
      margin-right: 40px;
    }
    p:nth-child(2) {
      width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 25px;
    }
  }
  .enrollbtn {
    margin-top: -20px;
    width: 100%;
  }
  .el_form_item_shortMsg .el-input {
    width: 65%;
  }
  .el_form_item_shortMsg .el-button {
    position: absolute;
    padding: 12px 0;
    right: 0;
    width: 30%;
    text-align: center;
  }
  .Forgotpwd {
    margin: 0 40px;
    h2 {
      width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #123e83;
      line-height: 25px;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    .stepBox {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 22px;
      cursor: default;
      p {
        margin-top: 3px;
        display: flex;
        align-items: center;
        height: 16px;
        font-size: 12.5px;
        color: rgba(0, 0, 0, 0.20);
        i {
          margin-right: 3px;
          display: inline-block;
          width: 16px;
          height: 16px;
          background-color: rgba(0, 0, 0, 0.20);
          border-radius: 50%;
          color: #fff;
          text-align: center;
          line-height: 16px;
          font-size: 12px;
        }
      }
      span {
        color: rgba(0, 0, 0, 0.20);
      }
    }
    label {
      p {
        width: 24px;
        height: 24px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 24px;
        margin-bottom: 10px;
      }
    }
  }
  .chooseCompany {
    margin: 0 40px;
    h2 {
      width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #123e83;
      line-height: 25px;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    >ul {
      padding: 10px;
      width: 300px;
      height: 209px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      box-sizing: border-box;
      overflow-y: auto;
      li {
        padding: 0 20px;
        // height: 48px;
        background-color: #fff;
        border-radius: 8px;
        cursor: pointer;
        line-height: 48px;
        color: rgba(0, 0, 0, 1);
        font-size: 18px;
      }
      li:hover {
        background-color: rgba(18, 62, 131, 0.05);
      }
      .chooseLi {
        background-color: rgba(18, 62, 131, 0.05);
      }
    }
    .loginBox, .cancelBox {
      margin-top: 20px;
      width: 300px;
      height: 40px;
      background-color: rgba(18, 62, 131, 1);
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      line-height: 40px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }
    .cancelBox {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.60);
    }
    .loginBox:hover {
      background-color: rgba(18, 62, 131, .8);
    }
    .cancelBox:hover {
      border: 1px solid rgba(18, 62, 131, 1);
    }
  }
  .lightDeepStyle {
    width: 100px;
    height: 40px;
    background: #123e83;
    border-radius: 4px;
    color: #fff!important;
  }
  .lightDeepStyles {
    width: 100%;
    height: 40px;
    background: #123e83;
    border-radius: 4px;
    color: white;
  }
  .lightDeepStyleqx {
    width: 100%;
    margin: 0;
    background: white;
    color: #000;
  }
  .lightDeepStyle {
    width: 100%;
    height: 40px;
    background: #123e83;
    border-radius: 4px;
    color: white;
  }
  .border_box {
    width: 100%;
    height: 80px;
    margin: 0 auto;
    margin-top: 40px;
    >img {
      margin-left: 50%;
      transform: translate(-50%);
    }
    p {
      height: 32px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      line-height: 32px;
      margin-top: 14px;
      text-align: center;
    }
    .time {
      width: 300px;
      height: 32px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      line-height: 32px;
      text-align: center;
    }
    .time2 {
      margin-top: -10px;
      margin-left: 10px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .lightDeepStyleqdl {
    width: 300px;
    background: white;
    color: #000;
    margin-top: 20px;
  }
  .Newusers {
    margin: 0 40px;
    h2 {
      margin-top: 20px;
      // margin-left: 40px;
      margin-bottom: 20px;
      width: 90px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #123e83;
      line-height: 25px;
    }
    label {
      p {
        width: 24px;
        height: 24px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 24px;
        // margin-bottom: 10px;
      }
    }
  }
  .hidden-input {
    position: relative;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .hidden-input .form-control {
    position: absolute;
    left: -1000px;
  }
}
.on {
  color: #123e83;
  font-weight: 500;
  cursor: pointer;
}
.active {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  cursor: pointer;
}
</style>
