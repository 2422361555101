// 竞价采购列表
<template>
  <div class="purchaseOrder">
    <nav>
      <h5>竞价采购</h5>
      <div>
        <el-button class="lightStyle" @click="routerJump(4, '')"><i class="el-icon-edit-outline"></i>草稿箱</el-button>
        <el-button class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>发布竞价</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="askPriceData.codeAndName" placeholder="标题/竞价单号搜索" @input="getAskPriceList()" prefix-icon="el-icon-search"></el-input>
    </section>
    <article>
      <div @click="changeActive(1)" style="border-top-left-radius: 4px; border-bottom-left-radius: 4px;" :class="active === 1 ? 'changeBox highlight' : 'changeBox'">进行中 ({{showTotal.total1}})</div>
      <div @click="changeActive(2)" :class="active === 2 ? 'changeBox highlight' : 'changeBox'">待开始 ({{showTotal.total2}})</div>
      <div @click="changeActive(3)" style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;" :class="active === 3 ? 'changeBox highlight' : 'changeBox'">已结束 ({{showTotal.total3}})</div>
    </article>
    <main>
      <el-table @select="handleSelectionChange" @select-all="handleSelectionChange" ref="tableHeight" row-key="index" :height="tableHeight" :data="askPriceList" style="width: 100%;">
        <el-table-column type="selection" width="45" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="code" label="竞价采购单编号" min-width="100px" align="center" fixed="left">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.code}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="name" label="标题" min-width="120px" align="center" fixed="left"></el-table-column>
        <el-table-column v-if="active !== 2" key="4" show-overflow-tooltip type="createTime" label="报价日期" width="200px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.beginTime && scope.row.endTime">{{(scope.row.beginTime).substring(0, 10) + ' - ' + (scope.row.endTime).substring(0, 10)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="active !== 2" key="5" show-overflow-tooltip prop="countDown" label="倒计时" min-width="80px"  align="center"></el-table-column>
        <el-table-column v-if="active === 1" key="3" show-overflow-tooltip label="当前/总数" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <p><span style="color: rgba(18, 62, 131, 1)">第{{scope.row.nowRound}}轮</span><span style="color: rgba(0, 0, 0, 0.60)">/总{{scope.row.numberRound}}轮</span></p>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="active !== 2" key="6" show-overflow-tooltip prop="offerNumber" label="报价次数" min-width="80px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="remark" label="补充说明" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" :content="scope.row.remark" placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column v-if="active !== 2" key="7" show-overflow-tooltip type="createTime" label="发布日期" width="200px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.createTime">{{(scope.row.createTime).substring(0, 10)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="active === 1 || active === 3" key="1" show-overflow-tooltip label="报价状态" min-width="100px"  align="center">
          <template slot-scope="scope">
            <span class="stateBox3" v-if="scope.row.offerState == 0">待开始</span>
            <span class="stateBox3" v-else-if="scope.row.offerState == 1">报价中</span>
            <span class="stateBox2" v-else-if="scope.row.offerState == 2">已确认</span>
            <span class="stateBox2" v-else-if="scope.row.offerState == 3">已审核</span>
            <span class="stateBox1" v-else-if="scope.row.offerState == 4">未确认</span>
            <span class="stateBox1" v-else-if="scope.row.offerState == 5">已过期</span>
          </template>
        </el-table-column>
        <el-table-column v-if="active === 3" key="2" show-overflow-tooltip label="转订单" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span v-if="(scope.row.orderId === '' || scope.row.orderId === 0 || scope.row.orderId === null) && scope.row.offerState == 3" @click="routerJump(5, scope.row.id)" class="stateBox4">转订单</span>
            <span v-else-if="scope.row.offerState == 3" @click="routerJump(6, scope.row.orderId)" class="stateBox4">查看订单</span>
            <span>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <!-- 查看详情 不限制 -->
                <!-- 结束竞价 进行中>报价中&已确认&未确认 -->
                <!-- 确认报价 进行中>报价中&未确认 -->
                <!-- 审核报价 进行中>已确认 -->
                <!-- 取消确认报价 进行中>已确认 -->
                <!-- 删除竞价 开始竞价 修改竞价 待开始 -->
                <el-dropdown-item command="1">
                  <i class="el-icon-edit-outline"></i><span>查看详情</span>
                </el-dropdown-item>
                <el-dropdown-item command="2" v-if="active === 1 && (scope.row.offerState === 1 || scope.row.offerState === 2 || scope.row.offerState === 4)">
                  <i class="el-icon-edit-outline"></i><span>结束竞价</span>
                </el-dropdown-item>
                <el-dropdown-item command="3" v-if="active === 1 && (scope.row.offerState === 1 || scope.row.offerState === 4)">
                  <i class="el-icon-switch-button"></i><span>确认报价</span>
                </el-dropdown-item>
                <el-dropdown-item command="4" v-if="active === 1 && scope.row.offerState === 2">
                  <i class="el-icon-delete"></i><span>审核报价</span>
                </el-dropdown-item>
                <el-dropdown-item command="5" v-if="active === 1 && scope.row.offerState === 2">
                  <i class="el-icon-switch-button"></i><span>取消确认报价</span>
                </el-dropdown-item>
                <el-dropdown-item command="6" v-if="active === 2">
                  <i class="el-icon-switch-button"></i><span>开始竞价</span>
                </el-dropdown-item>
                <el-dropdown-item command="7" v-if="active === 2">
                  <i class="el-icon-switch-button"></i><span>修改竞价</span>
                </el-dropdown-item>
                <el-dropdown-item command="8" v-if="active === 2">
                  <i class="el-icon-switch-button"></i><span>删除竞价</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="askPriceData.size"
        :total="total">
      </el-pagination>
    </main>
    <!-- 确认报价弹框 -->
    <el-dialog class="controlQuoteDialog" title="确认报价" :visible.sync="controlQuote" :close-on-click-modal="false" v-dialogDrag>
      <div class="dialogContent">
        <ul v-if="quoteList.length !== 0">
          <el-checkbox-group v-model="chooseQuoteCompany">
            <li :class="chooseQuoteCompany.indexOf(item.id) !== -1 ? 'highlight' : ''" @click.stop="chooseQuote(item.id)" v-for="(item, index) in quoteList" :key="index">
              <h6>{{item.sellName}}({{item.state === 4 ? '已通过' : item.state === 7 ? '已取消' : '已报价'}})</h6>
              <p><span>合计金额不含税: {{item.money === null ? '无' : item.money}}</span><span v-if="item.offerAsk === 1">合计金额含税: {{item.moneyTax === null ? '无' : item.moneyTax}}</span></p>
              <p><span>报价时间: </span>{{(item.createTime).substring(0, 16)}}<span v-if="item.offerType == 2"> | 报价说明: </span>{{item.offerType == 1 ?  '' : (item.remark === null ? '无' : item.remark)}}</p>
              <el-checkbox @change="chooseQuote2($event, item.id)" :label="item.id"></el-checkbox>
            </li>
          </el-checkbox-group>
        </ul>
        <div v-else slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 10%;">
          <span style="display: block; margin-top: 0%; color: #ccc">暂无数据</span>
        </div>
      </div>
      <div class="dialogBox" v-if="askPricePurchaseDetail.nowRound !== askPricePurchaseDetail.numberRound">
        <p>
          <span><i style="color: rgba(242, 90, 90, 1)">*</i>第{{askPricePurchaseDetail.nowRound + 1}}轮竞价开始日期:</span>
          <el-date-picker @change="countData(1)" :picker-options="pickerOptions" v-model="addRoundData.beginTime" type="datetime" placeholder="请选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
        </p>
        <p>
          <span><i style="color: rgba(242, 90, 90, 1)">*</i>第{{askPricePurchaseDetail.nowRound + 1}}轮报价截止日期:</span>
          <el-date-picker @change="countData(2)" :picker-options="pickerOptions" v-model="addRoundData.endTime" type="datetime" placeholder="请选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
        </p>
        <p>
          <span>第{{askPricePurchaseDetail.nowRound + 1}}轮竞价周期</span>
          <span v-if="addRoundData.beginTime == '' || addRoundData.endTime == ''">选择开始和截止日期后自动读取</span>
          <span v-else>{{addRoundData.days}}天 {{addRoundData.hours}}时 {{addRoundData.minutes}}分</span>
        </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="controlQuote = false">取 消</el-button>
        <el-button v-if="quoteList.length !== 0" class="lightDeepStyle" @click="confirmQuote()">确 认</el-button>
      </div>
    </el-dialog>
    <!-- 审核报价弹框 -->
    <el-dialog class="controlApprovalDialog" title="审核报价" :visible.sync="controlApproval" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <ul>
          <li v-for="(item, index) in approvalQuoteData" :key="index">
            <h6>{{item.sellName}}({{item.state === 4 ? '已通过' : item.state === 7 ? '已取消' : '已报价'}})</h6>
            <p><span>合计金额不含税: {{item.money === null ? '无' : item.money}}</span><span v-if="item.offerAsk === 1">合计金额含税: {{item.moneyTax === null ? '无' : item.moneyTax}}</span></p>
            <p><span v-if="item.createTime">报价时间: </span>{{(item.createTime).substring(0, 16)}}<span v-if="item.offerType == 2"> | 报价说明: </span>{{item.offerType == 1 ?  '' : (item.remark === null ? '无' : item.remark)}}</p>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="cancelAskPrice(chooseApprovalId, false)">不通过</el-button>
        <el-button class="lightDeepStyle" @click="approvalAskPrice(chooseApprovalId)">通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { askPriceListApi, endAskPriceApi, quoteListApi, quoteList2Api, approvalAskPriceApi, cancelAskPriceApi, beginAskPriceApi, delAskPriceApi, confirmQuoteApi, askPricePurchaseDetailApi, addRoundApi } from '@/api'
export default {
  name: 'purchaseOrder',
  data() {
    return {
      // 增加轮次参数
      addRoundData: {
        beginTime: '',
        endTime: '',
        days: '',
        hours: '',
        round: '',
        enquiryId: ''
      },
      // 选择的竞价详情
      askPricePurchaseDetail: '',
      // 审核报价
      approvalQuoteData: [],
      chooseApprovalId: '',
      // 报价记录选择的公司
      chooseCompany: 0,
      chooseQuoteCompany: [],
      // 报价列表
      quoteList: [],
      // 控制确认报价弹框
      controlQuote: false,
      // 控制审核报价弹框
      controlApproval: false,
      // 保存勾选的row的值
      allRowId: [],
      // 选中的标签
      active: 1,
      // 列表动态高度
      tableHeight: '',
      // 采购单列表
      askPriceList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      askPriceData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        state: 2,
        codeAndName: '',
        type: 2
      },
      showTotal: {
        total1: 0,
        total2: 0,
        total3: 0
      },
      // 日期选择器限制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 8.64e7
        }
      }
    }
  },
  created() {
  },
  mounted() {
    if (this.$route.query.indexState !== undefined) {
      this.active = 3
      this.askPriceData.state = 3
    }
    this.getAskPriceListNum()
    this.getAskPriceList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 选择报价弹框
    chooseQuote(id) {
      if (this.chooseQuoteCompany.length > 0) {
        let splice = true
        this.chooseQuoteCompany.forEach((item, index) => {
          if (item === id) {
            this.chooseQuoteCompany.splice(index, 1)
            splice = false
          }
        })
        if (splice === true) {
          this.chooseQuoteCompany.push(id)
        }
      } else {
        this.chooseQuoteCompany.push(id)
      }
    },
    // 选择报价按钮
    chooseQuote2(e, id) {
      if (e === true) {
        this.chooseQuoteCompany.forEach((item, index) => {
          if (item === id) {
            this.chooseQuoteCompany.splice(index, 1)
          }
        })
      } else {
        this.chooseQuoteCompany.push(id)
      }
    },
    // 计算结束日期
    countData(key) {
      if (this.addRoundData.beginTime !== '' && this.addRoundData.endTime !== '' && this.addRoundData.beginTime > this.addRoundData.endTime) {
        if (key === 1) {
          this.addRoundData.beginTime = ''
          return this.$message.error('竞价开始日期不得大于报价截止日期')
        } else {
          this.addRoundData.endTime = ''
          return this.$message.error('报价截止日期不得小于询价开始日期')
        }
      }
      if (this.addRoundData.beginTime != '' && this.addRoundData.endTime != '') {
        // 结束时间
        let endTime = (Date.parse(new Date(this.addRoundData.endTime)))/1000
        // 开始时间
        let beginTime = (Date.parse(new Date(this.addRoundData.beginTime)))/1000
        //时间差
        let dateDiffer = (endTime - beginTime) * 1000
        //计算出相差天数
        this.addRoundData.days = Math.floor(dateDiffer / (24 * 3600 * 1000))
        console.log(this.addRoundData.days, '666666666')
        //计算出小时数
        let leave1 = dateDiffer % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        this.addRoundData.hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        this.addRoundData.minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        this.addRoundData.seconds = Math.round(leave3 / 1000);
      }
    },
    // 获取竞价详情
    async getAskPricePurchaseDetail(id) {
      const { data: res } = await askPricePurchaseDetailApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.askPricePurchaseDetail = res.data.data
      this.addRoundData.round = this.askPricePurchaseDetail.nowRound + 1
      console.log(this.addRoundData.round, '12345699999')
    },
    // 确认报价
    async confirmQuote() {
      // const { data: res } = await confirmQuoteApi(this.chooseQuoteCompany)
      // if (res.code !== 0) return this.$message.error(res.msg)
      // this.$message.success('确认报价成功!')
      // this.controlQuote = false
      // this.getAskPriceList()
      // this.getAskPriceListNum()
      // 判断如果不是最后一轮
      if (this.chooseQuoteCompany.length === 0) return this.$message.error('请选择确认报价的公司')
      if (this.askPricePurchaseDetail.nowRound != this.askPricePurchaseDetail.numberRound) {
        // 判断轮次时间
        if (this.addRoundData.beginTime === '') return this.$message.error('竞价开始日期不能为空!')
        if (this.addRoundData.endTime === '') return this.$message.error('报价截止日期不能为空!')
      } else {
        // 如果是最后一轮只能选择一个公司
        if (this.chooseQuoteCompany.length !== 1) {
          this.$message.error('最后一轮竞价只能选择一家公司!')
        }
      }
      let newCooseQuoteCompany = this.chooseQuoteCompany.join(',')
      const { data: res } = await confirmQuoteApi(newCooseQuoteCompany)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('确认报价成功!')
      if (this.askPricePurchaseDetail.nowRound != this.askPricePurchaseDetail.numberRound) {
        // 报价成功后增加轮次
        const { data: ress } = await addRoundApi(this.addRoundData)
        if (ress.code !== 0) return this.$message.error(ress.msg)
      }
      this.controlQuote = false
      this.getAskPriceList()
      this.getAskPriceListNum()
    },
    // 删除竞价
    async delAskPrice(id) {
      const { data: res } = await delAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('删除成功!')
      this.getAskPriceList()
      this.getAskPriceListNum()
    },
    // 开始竞价
    async beginAskPrice(id) {
      const { data: res } = await beginAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('开始竞价成功!')
      setTimeout(() => {
        this.getAskPriceList()
        this.getAskPriceListNum()
      }, 200)
    },
    // 取消确认竞价
    async cancelAskPrice(id, status) {
      const { data: res } = await cancelAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (status === false) {
        this.$message.success('不通过审核成功!')
        this.controlApproval = false
      } else {
        this.$message.success('取消确认成功!')
      }
      this.getAskPriceList()
      this.getAskPriceListNum()
    },
    // 审核竞价
    async approvalAskPrice(id) {
      const { data: res } = await approvalAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('审核成功!')
      this.controlApproval = false
      this.getAskPriceList()
      this.getAskPriceListNum()
    },
    // 报价列表(确认报价用)
    async getQuoteList(id, state) {
      const { data: res } = await quoteListApi({ round: this.askPricePurchaseDetail.nowRound, enquiryId: id, state: state })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.quoteList = res.data.data
    },
    // 报价列表(审核报价用)
    async getQuoteList2(id, offerIds, nowRound) {
      const { data: res } = await quoteList2Api({ round: nowRound, enquiryId: id, offerIds: offerIds })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.quoteList = res.data.data
      this.approvalQuoteData = this.quoteList
      this.chooseApprovalId = id
    },
    // 结束竞价
    async endAskPrice(id) {
      const { data: res } = await endAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('结束成功!')
      this.getAskPriceList()
      this.getAskPriceListNum()
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: '/addCompetePrice' })
      } else if (val === 2) {
        this.$router.push({ path: '/competePricePurchaseDetail', query: { result: JSON.stringify(result) } })
      } else if (val === 3) {
        this.$router.push({ path: '/editCompetePrice', query: { result: JSON.stringify(result) } })
      } else if (val === 4) {
        this.$router.push({ path: '/competePriceDraft' })
      } else if (val === 5) {
        this.$router.push({ path: '../switchPurchaseOrder', query: { askPriceId: result, source: 3 } })
      } else if (val === 6) {
        this.$router.push({ path: '/purchaseOrderDetail', query: { result: result } })
      }
    },
    // 选择下拉
    chooseCommand(command, result) {
      // <!-- 查看详情 不限制 -->
      // <!-- 结束竞价 进行中>报价中&已确认&未确认 -->
      // <!-- 确认报价 进行中>报价中&未确认 -->
      // <!-- 审核报价 进行中>已确认 -->
      // <!-- 取消确认报价 进行中>已确认 -->
      // <!-- 删除竞价 开始竞价 修改竞价 待开始 -->
      if (command === '1') {
        // 查看详情
        this.routerJump(2, result.id)
      } else if (command === '2') {
        // 结束竞价
        this.$confirm('是否确认结束竞价?结束后无法进行报价确认等操作<p>报价: <span style="color: rgba(70, 147, 235, 1)">已确认</span>/<span style="color: rgba(239, 188, 96, 1)">未确认</span></p>', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true
        }).then(async () => {
          this.endAskPrice(result.id)
        })
      } else if (command === '3') {
        // 确认报价
        this.getAskPricePurchaseDetail(result.id).then(() => {
          this.getQuoteList(result.id, 3)
        })
        this.addRoundData.enquiryId = result.id
        this.controlQuote = true
      } else if (command === '4') {
        // 审核报价
        this.getQuoteList2(result.id, result.offerIds, result.nowRound)
        this.controlApproval = true
      } else if (command === '5') {
        // 取消确认寻找
        this.cancelAskPrice(result.id)
      } else if (command === '6') {
        // 开始竞价
        this.beginAskPrice(result.id)
      } else if (command === '7') {
        // 修改竞价
        this.routerJump(3, result.id)
      } else if (command === '8') {
        // 删除竞价
        this.$confirm('是否确认删除该竞价', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(async () => {
          this.delAskPrice(result.id)
        })
      }
    },
    // 获取默认条数
    async getAskPriceListNum() {
      let newPurchaseOrderData = JSON.parse(JSON.stringify(this.askPriceData))
      newPurchaseOrderData.state = 2
      const { data: res } = await askPriceListApi(newPurchaseOrderData)
      // 展示总条数
      this.showTotal.total1 = res.data.data.totalCount
      newPurchaseOrderData.state = 1
      const { data: ress } = await askPriceListApi(newPurchaseOrderData)
      this.showTotal.total2 = ress.data.data.totalCount
      newPurchaseOrderData.state = 3
      const { data: resss } = await askPriceListApi(newPurchaseOrderData)
      this.showTotal.total3 = resss.data.data.totalCount
    },
    // 手动勾选的参数
    handleSelectionChange(selection, row) {
      let arr = []
      selection.forEach(item => {
        arr.push(item.id)
      })
      this.allRowId = arr
    },
    // 全选
    allChoose(selection) {
      let arr = []
      selection.forEach(item => {
        arr.push(item.id)
      })
      this.allRowId = arr
    },
    // 获取竞价列表
    async getAskPriceList(reset) {
      if (reset !== 'reset') {
        this.askPriceData.page = 1
      }
      let newPurchaseOrderData = JSON.parse(JSON.stringify(this.askPriceData))
      const { data: res } = await askPriceListApi(newPurchaseOrderData)
      // 获取表单数据
      this.askPriceList = res.data.data.list
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.askPriceData.size = val
      this.askPriceData.page = 1
      this.getAskPriceList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.askPriceData.page = val
      this.currentPage = val
      this.getAskPriceList('reset')
    },
    // 切换标签页
    changeActive(val) {
      this.active = val
      if (val === 1) {
        this.askPriceData.state = 2
      } else if (val === 2) {
        // 待提交的状态
        this.askPriceData.state = 1
      } else if (val === 3) {
        this.askPriceData.state = 3
      }
      this.getAskPriceList()
      // 解决样式错乱的问题
      this.$nextTick(() => {
        this.$refs.tableHeight.doLayout()
      })
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseOrder {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  article {
    margin: 20px 20px 10px;
    display: flex;
    .changeBox {
      margin-left: -1px;
      height: 32px;
      width: 88px;
      border: 1px solid #CACACA;
      cursor: pointer;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.60);
    }
    .highlight {
      background-color: rgba(18, 62, 131, 1);
      border-color: rgba(18, 62, 131, 1);
      color: #fff;
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      .table-fixed {
        /deep/.el-table__fixed-right {
            height: 100% !important
        }
        /deep/.el-table__fixed {
            height:100% !important
        }
      }
    }
    .stateBox1, .stateBox2, .stateBox3, .stateBox4 {
      display: block;
      width: 64px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
    .stateBox4 {
      cursor: pointer;
      background-color: rgba(70, 147, 235, 1);
      border-color: rgba(70, 147, 235, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  /deep/ .controlQuoteDialog {
    .el-dialog {
      width: 480px;
      height: 660px;
      border-radius: 10px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 10px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        padding-bottom: 0;
        height: 540px!important;
        width: 100%;
        .dialogContent {
          height: 402px;
          overflow-y: auto;
          ul {
            li {
              position: relative;
              margin-bottom: 10px;
              padding: 10px 20px;
              width: 100%;
              height: 124px;
              background-color: rgba(0, 0, 0, 0.02);
              border: 2px solid rgba(0, 0, 0, 0.02);
              box-sizing: border-box;
              border-radius: 10px;
              cursor: pointer;
              h6 {
                margin: 0;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.80);
              }
              p:nth-child(2) {
                color: rgba(0, 0, 0, 0.80);
                font-size: 14px;
                span {
                  margin-right: 44px;
                }
              }
              p:nth-child(3) {
                color: rgba(0, 0, 0, 0.80);
                font-size: 14px;
                span {
                  color: rgba(0, 0, 0, 0.40);
                }
              }
              .el-checkbox {
                position: absolute;
                top: 45px;
                right: 20px;
                .el-checkbox__label {
                  display: none;
                }
              }
            }
            .highlight {
              border: 2px solid rgba(18, 62, 131, 1);
              background-color: #fff;
            }
          }
        }
        .dialogBox {
          height: 108px;
          width: 100%;
          P {
            display: flex;
            justify-content: space-between;
            width: 100%;
            span {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.80);
            }
            .el-date-editor {
              width: calc(100% - 140px);
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
  /deep/ .controlApprovalDialog {
    .el-dialog {
      width: 515px;
      height: 470px;
      border-radius: 10px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 10px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        padding-bottom: 0;
        height: 360px!important;
        overflow-y: auto;
        width: 100%;
        ul {
          li {
            margin-bottom: 10px;
            padding: 10px 20px;
            width: 100%;
            height: 124px;
            box-sizing: border-box;
            border-radius: 10px;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.02);
            h6 {
              margin: 0;
              font-size: 18px;
              color: rgba(0, 0, 0, 0.80);
            }
            p:nth-child(2) {
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                margin-right: 64px;
              }
            }
            p:nth-child(3) {
              color: rgba(0, 0, 0, 0.80);
              font-size: 14px;
              span {
                color: rgba(0, 0, 0, 0.40);
              }
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
