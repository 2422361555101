// 采购组管理
<template>
  <div class="purchaseManage_content">
    <!-- 岗位权限 -->
    <main>
      <p>采购组管理</p>
      <el-input placeholder="请输入" clearable v-model="purchaseManageData.name" @keyup.enter.native="getpurchaseList()" size="mini"></el-input>
      <el-button class="searchBtn" type="info" @click="getpurchaseList()">查询</el-button>
      <el-button type="info" @click="dialogVisible = true" size="mini">+ 新增</el-button>
      <el-table ref="tableHeight" :max-height="tableHeight" min-height="30px" :data="purchaseData" stripe style="width: 100%">
        <el-table-column show-overflow-tooltip prop="id" label="编号" min-width="20%" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="name" label="采购组名称" min-width="30%" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="userCount" label="组员数" min-width="20%" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="state" label="状态" min-width="30%" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.state" :active-value="1" :inactive-value="0" active-color="rgba(27, 199, 181, 1)" inactive-color="#eee" @change="editpurchase(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="260px" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="openManage(scope.row.id)">管理</el-button>
            <el-button size="mini" @click="openEdit(scope.row)">修改</el-button>
            <el-button size="mini" @click="delpurchase(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页功能 -->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="purchaseManageData.size"
        :total="total">
      </el-pagination>
    </main>
    <!-- 新建采购组弹框 -->
    <el-dialog title="新增采购组" :visible.sync="dialogVisible" class="newDialog" :close-on-click-modal="false" v-dialogDrag>
      采购组名称: <el-input autocomplete="off" v-model="newName" clearable></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addpurchase()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改采购组弹框 -->
    <el-dialog title="修改采购组" :visible.sync="controlEdit" class="newDialog" :close-on-click-modal="false" v-dialogDrag>
      采购组名称: <el-input autocomplete="off" v-model="newName" clearable></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="controlEdit = false">取 消</el-button>
        <el-button type="primary" @click="editpurchaseName()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 管理采购组弹框 -->
    <el-dialog title="采购组管理" :visible.sync="controlManage" class="manageDialog" :close-on-click-modal="false" v-dialogDrag>
      <!-- 添加用户进采购组 -->
      <div class="addUser">
        <span>添加用户: </span>
        <el-select v-model="checkUser" placeholder="请选择" size="mini" clearable>
          <el-option
            v-for="item in optionsValue"
            :key="item.infoId"
            :label="item.name"
            :value="item.infoId">
          </el-option>
          <el-option label="" value="" class="addSelectBtn" @click.native="selectJump('userManage')"><i class="el-icon-plus"></i>添加用户</el-option>
        </el-select>
        <el-button size="mini" @click="addpurchaseUser()">添加</el-button>
      </div>
      <el-table :data="tableData">
        <el-table-column  prop="jobNum" label="工号" width="150" align="center"></el-table-column>
        <el-table-column  prop="name" label="姓名" width="150" align="center"></el-table-column>
        <el-table-column  prop="" label="操作" align="center">
          <template slot-scope="scope">
            <el-button class="delBtn" size="mini" @click="delpurchaseUser(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { getpurchaseListApi, editpurchaseApi, addpurchaseApi, delpurchaseApi, getpurchaseUserApi, addpurchaseUserApi, userIdGetUserInfoApi } from '@/api'
export default {
  name: 'purchaseManage',
  data() {
    return {
      // 列表动态高度
      tableHeight: '',
      // 获取当前浏览器的宽度
      screenWidth: '',
      purchaseData: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 展示参数
      purchaseManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 50,
        // 合同名称
        name: '',
        state: ''
      },
      // 控制新增采购组的弹框
      dialogVisible: false,
      // 新增采购组名称
      newName: '',
      // 控制管理采购组的弹框
      controlManage: false,
      // 控制修改采购组的弹框
      controlEdit: false,
      companyId: window.$cookies.get('companyId'),
      // 采购组管理列表数据
      tableData: [],
      // 未加入采购组的用户列表
      options: [],
      // 管理采购组的id
      purchaseId: '',
      // 选中的采购组信息
      purchaseInfo: [],
      // 选中的用户
      checkUser: null,
      // 过滤后的采购组
      optionsValue: []
    }
  },
  created() {
    this.getpurchaseList()
    this.getpurchaseUserAll()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 180
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 180
      })()
    }
  },
  methods: {
    selectJump(url) {
      this.controlManage = false
      this.controlEdit = false
      this.$router.push(url, 'params')
    },
    // 获取采购组列表
    async getpurchaseList() {
      const { data: res } = await getpurchaseListApi(this.purchaseManageData)
      // 获取表单数据
      this.purchaseData = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.purchaseManageData.size = val
      this.purchaseManageData.page = 1
      this.getpurchaseList()
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.purchaseManageData.page = val
      this.currentPage = val
      this.getpurchaseList()
    },
    // 修改采购组
    async editpurchase(data) {
      const { data: res } = await editpurchaseApi(data)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (data.state === 0) {
        this.$message({
          message: data.name + ' 已关闭 !',
          type: 'warning'
        })
      } else {
        this.$message({
          message: data.name + ' 已开启 !',
          type: 'success'
        })
      }
    },
    // 新增采购组
    async addpurchase() {
      const { data: res } = await addpurchaseApi({ name: this.newName })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '新增采购组成功' })
      this.dialogVisible = false
      this.newName = ''
      this.getpurchaseList()
    },
    // 删除采购组
    delpurchase(id) {
      this.$confirm('此操作将删除该采购组', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await delpurchaseApi(id)
        if (res.code !== 0) return this.$message.error('采购组中存在用户，无法删除!')
        this.getpurchaseList()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
      })
    },
    // 打开管理采购组弹框
    openManage(id) {
      this.controlManage = true
      this.purchaseId = id
      this.getpurchaseUser(id)
    },
    // 打开修改采购组弹框
    openEdit(result) {
      this.controlEdit = true
      this.purchaseId = result.id
      this.newName = result.name
      this.purchaseInfo = result
    },
    // 修改采购组名称
    async editpurchaseName() {
      this.purchaseInfo.name = this.newName
      const { data: res } = await editpurchaseApi(this.purchaseInfo)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.newName = ''
      this.$message.success({ duration: 1000, message: '修改成功!' })
      this.controlEdit = false
      this.getpurchaseList()
    },
    // 获取采购组中所有用户
    async getpurchaseUserAll() {
      const { data: res } = await getpurchaseUserApi({ companyId: this.companyId, purchaseId: '' })
      if (res.code !== 0) return this.$message.error('获取采购组用户失败')
      this.options = res.data.list
      this.optionsValue = res.data.list
    },
    // 获取采购组中用户详情
    async getpurchaseUser(id) {
      const { data: res } = await getpurchaseUserApi({ companyId: this.companyId, purchaseId: id })
      if (res.code !== 0) return this.$message.error('获取采购组用户失败')
      this.tableData = res.data.list
      this.optionsValue = []
      this.options.forEach(item => {
        this.optionsValue.push(item)
      })
      console.log(this.optionsValue, '过滤前下拉值')
      // 过滤物料
      function arrayDiff(a, b) {
        for (var i = 0; i < b.length; i++) {
          for (var j = 0; j < a.length; j++) {
            if (a[j].infoId === b[i].infoId) {
              a.splice(j, 1)
              j = j - 1
            }
          }
        }
        return a
      }
      if (this.tableData.length !== 0) {
        arrayDiff(this.optionsValue, this.tableData)
      }
      this.checkUser = ''
    },
    // 将用户添加入采购组
    async addpurchaseUser() {
      // 先去获取当前用户的采购组信息0
      // 根据用户id获取用户详情
      const { data: result } = await userIdGetUserInfoApi(this.checkUser)
      console.log(result)
      // 如果在其他采购组中存在该用户
      if (result.data.detail.purchaseId !== 0) {
        this.$confirm('该用户已在' + result.data.detail.purchaseName + '，是否确认更换采购组？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(async() => {
          const { data: res } = await addpurchaseUserApi({ infoId: this.checkUser, purchaseId: this.purchaseId })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '更换成功!' })
          this.getpurchaseUser(this.purchaseId)
          this.getpurchaseList()
        }).catch(() => {
        })
      } else {
        const { data: res } = await addpurchaseUserApi({ infoId: this.checkUser, purchaseId: this.purchaseId })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '添加成功!' })
        this.getpurchaseUser(this.purchaseId)
        this.getpurchaseList()
      }
    },
    // 将用户从采购组中删除
    async delpurchaseUser(result) {
      const { data: res } = await addpurchaseUserApi({ infoId: result.infoId, purchaseId: 0 })
      if (res.code !== 0) return this.$message.error('删除用户失败')
      this.$message.success({ duration: 1000, message: '删除成功!' })
      this.getpurchaseUser(this.purchaseId)
      this.getpurchaseList()
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseManage_content nav {
  width: 100%;
  height: 150px;
  background: #fff;
  border-radius: 3px;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.purchaseManage_content main>.el-button {
  position: absolute;
  top: 35px;
  right: 10px;
  margin-left: 20px;
  width: 90px;
  height: 28px;
  line-height: 0;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  background-color: #49505A;
}
.purchaseManage_content  main>.el-button:hover {
  background-color: rgba(73,80,90, .7)
}
.purchaseManage_content main .searchBtn {
  width: 70px;
  right: 110px;
  background-color: #333;
  border: 1px solid #000;
}
.purchaseManage_content main .searchBtn:hover {
  background-color: rgba(51,51,51, .8);
}

.purchaseManage_content p {
  display: inline-block;
  margin: 20px;
}
.purchaseManage_content main>.el-input {
  position: absolute;
  top: 35px;
  right: 192px;
  height: 32px;
  width: 260px;
}
.purchaseManage_content main {
  position: relative;
  padding: 10px;
  background: #fff;
  border-radius: 3px;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.purchaseManage_content .el-table {
  margin: 10px 0 45px;
  border: 1px solid #ccc;
}
.purchaseManage_content .el-table .el-button:nth-child(1) {
  border: 0;
  background-color: #FEB300;
  color: #fff;
}
.purchaseManage_content .el-table .el-button:nth-child(2) {
  border: 0;
  background-color: yellowgreen;
  color: #fff;
}
.purchaseManage_content .el-table .el-button:nth-child(3) {
  border: 0;
  background-color: #E8654B;
  color: #fff;
}
.purchaseManage_content .el-table .el-button:hover {
  background-color: #ec823a;
}
.el-pagination {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
.purchaseManage_content .newDialog .el-input {
  margin: 0 30px;
  width: 300px;
}
.purchaseManage_content .manageDialog .el-table {
  margin-bottom: 0;
  width: 100%;
}
.purchaseManage_content .manageDialog .addUser {
  height: 60px;
}
.purchaseManage_content .manageDialog span {
  margin: 0 10px 0 125px;
}
.purchaseManage_content .manageDialog .el-button {
  margin-left: 20px;
  display: inline-block;
  background-color: #2A313B;
  color: #fff;
  border: 0;
}
.purchaseManage_content .manageDialog .el-button:hover {
  color: #FEB300;
}
.purchaseManage_content .manageDialog .delBtn {
  background-color: #E8654B !important;
}
.purchaseManage_content .manageDialog .delBtn:hover {
  background-color: #E8654B;
  background-color: rgba(232, 101, 75, .7);
}
</style>
