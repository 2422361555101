// 新增出库单
<template>
  <div class="purchaseEntry">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/outManage' }">出库单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">新增出库单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="purchaseEntryContent">
      <el-form ref="ruleForm" :model="addData" label-width="102px" :rules="rules">
        <nav>
          <h5>新增出库单</h5>
            <div class="navBox">
              <el-form-item label="出库仓库:" prop="warehouseId">
                <el-select v-model="addData.warehouseId" placeholder="请选择出库仓库" size="mini" @change="chooseStoreHouse()">
                  <el-option v-for="item in storeHouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="出库时间:" prop="outboundTime">
                <el-date-picker v-model="addData.outboundTime" type="datetime" placeholder="请选择出库时间" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
              </el-form-item>
              <el-form-item label="出库形式:" prop="type">
                <el-select v-model="addData.type" placeholder="请选择出库形式" clearable size="mini">
                  <el-option label="手动出库" :value="1"></el-option>
                  <el-option label="转仓出库" :value="2"></el-option>
                  <el-option label="盘亏出库" :value="3"></el-option>
                  <el-option label="线下销售出库" :value="4"></el-option>
                  <el-option label="销售出库" :value="5"></el-option>
                </el-select>
              </el-form-item>
          </div>
        </nav>
        <main>
          <h5>出库单明细</h5>
          <el-button v-if="addData.type===0" class="lightDeepStyle addBtn" @click="openAddDialog()"><i class="el-icon-plus"></i>添加发货单</el-button>
          <el-button v-else class="lightDeepStyle addBtn" @click="openAddDialog()"><i class="el-icon-plus"></i>添加产品</el-button>
          <el-button class="defaultStyle delBtn" @click="delMatter('all')">删除</el-button>
          <ul v-if="addData.warehouseOutboundLineVoList.length !== 0">
            <el-checkbox-group v-model="chooseList">
              <li v-for="(item, index) in addData.warehouseOutboundLineVoList" :key="index">
                <div v-if="addData.type===0" class="liUp">
                  <p>
                    <el-checkbox :label="item.productId"><span>{{item.code + '-' + item.productName + '-' + (item.unit === null ? '暂无' : item.unit) + '-' + (item.productSpecs === null ? '暂无' : item.productSpecs) + '-' + (item.productNewColour === null ? '暂无' : item.productNewColour)}}</span></el-checkbox>
                    <span>销售数量: {{item.takeDeliveryNumber}} 可出数量: {{item.takeDeliveryNumber - item.warrantNumber}}</span>
                    <i @click="delMatter(index)" class="el-icon-delete"></i>
                  </p>
                </div>
                <div  v-else class="liUp">
                  <p>
                    <el-checkbox :label="item.productId"><span>{{item.productName + '-' + (item.productUnit === null ? '暂无' : item.productUnit) + '-' + (item.productSpecs === null ? '暂无' : item.productSpecs) + '-' + (item.productNewColour === null ? '暂无' : item.productNewColour)}}</span><span>库存 : {{item.number}}</span></el-checkbox>
                    <i @click="delMatter(index)" class="el-icon-delete"></i>
                  </p>
                </div>
                <div class="liDown">
                  <el-form-item label="出库数量:" :prop="'warehouseOutboundLineVoList.' + index + '.quantity'" :rules="rules.quantity">
                    <el-input type="number" @input="formatNum(item.quantity, index, item.number)" oninput="if(value.length > 10) value=value.slice(0, 10)" v-model="item.quantity" placeholder="输入数值"></el-input>
                  </el-form-item>
                  <div class="showNumBox">出库后数量: {{item.quantity === '' ? '自动计算' : item.number - item.quantity}}</div>
                </div>
              </li>
            </el-checkbox-group>
          </ul>
          <div v-else class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:30%; margin-top: -2%;">
            <span style="display: block; margin-top: -5%">暂无数据</span>
          </div>
        </main>
      </el-form>
      <!-- 采购单弹框 -->
      <el-dialog class="chooseOrderDialog" :title="addData.type===0?'添加发货单':'添加产品'" :visible.sync="chooseOrderDialog" :close-on-click-modal="false" v-dialogDrag>
        <div>
          <el-input v-if="addData.type===0" class="sectionInp" v-model="balanceAccountData.codeAndProductNameAndProductCode" placeholder="产品编号/产品名称搜索" @input="getMatterManageList()" prefix-icon="el-icon-search"></el-input>
          <el-input v-else class="sectionInp" v-model="storeHouseData.productNameOrCode" placeholder="产品编号/产品名称搜索" @input="getMatterManageList()" prefix-icon="el-icon-search"></el-input>
        </div>
        <el-table v-if="addData.type!==0" ref="matterSearchRef" :data="matterManageList" style="width: 100%" height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
          <el-table-column type="selection" width="45px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productCode" label="产品编号" min-width="110px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productCode === null || scope.row.productCode === '' ? '暂无' : scope.row.productCode}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productName === null || scope.row.productName === '' ? '暂无' : scope.row.productName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productUnit" label="单位" min-width="130px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productUnit === null || scope.row.productUnit === '' ? '暂无' : scope.row.productUnit}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productUnit" label="库存数量" min-width="130px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.number}}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-else ref="matterSearchRef" :data="matterManageList2" style="width: 100%" height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
          <el-table-column type="selection" width="45px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="code" label="发货编号" min-width="110px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productCode" label="产品编号" min-width="110px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productName === '' || scope.row.productName === null ? '暂未填写' : scope.row.productName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="90px" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.unitName === null ? '暂无' : scope.row.unitName}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="deliveryNumber" label="本次订单可出" min-width="110px" align="center">
            <!-- <template slot-scope="scope">
              <span>{{scope.row.takeDeliveryNumber - scope.row.warrantNumber}}</span>
            </template> -->
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button class="defaultStyle cancleBtn" @click="chooseOrderDialog = false">取 消</el-button>
          <el-button class="lightDeepStyle" @click="chooseMatter()">确 认</el-button>
        </div>
      </el-dialog>
    </div>
    <footer>
      <el-button class="lightDeepStyle" @click="addOutbound()">提交</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { addOutboundApi, storeHouseListAllApi, storeHouseMatterListApi, purchaseOutApi } from '@/api'
export default {
  data() {
    return {
      // 控制弹框
      chooseOrderDialog: false,
      addData: {
        warehouseId: '',
        type: '',
        sellId: '',
        outboundTime: '',
        warehouseOutboundLineVoList: []
      },
      // 选中的项
      chooseList: [],
      // 获取仓库列表参数
      storeHouseListData: {
        page: 1,
        // 当前页条数
        size: 9999,
        code: '',
        name: '',
        enabled: 1
      },
      // 仓库列表
      storeHouseList: [],
      balanceAccountData: {
        codeAndProductNameAndProductCode: '',
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        companyId: '',
        state: '',
        arriveTimeBegin: '',
        arriveTimeEnd: '',
        replenishment: '',
        code: '',
        companyName: '',
        productName: ''
      },
      // 传参参数
      storeHouseData: {
        page: 1,
        // 当前页条数
        size: 99999,
        status: '',
        productCode: '',
        productName: '',
        productNameOrCode: '',
        productSpecs: '',
        warehouseId: '',
        businessTypes: ''
      },
      // 产品列表
      matterManageList: [],
      matterManageList2: [],
      // 选中的物料
      matterList: [],
      // 选中仓库的物料列表
      chooseHouseMatterList: [],
      // 校验
      rules: {
        warehouseId: [
          { required: true, message: '出库仓库不能为空!', trigger: 'blur' }
        ],
        outboundTime: [
          { required: true, message: '出库时间不能为空!', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '出库形式不能为空!', trigger: 'blur' }
        ],
        quantity: [
          { required: true, message: '出库数量不能为空!', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getStoreHouseList()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 切换仓库清空列表
    // typeFn(val) {
    //   if (this.addData.warehouseOutboundLineVoList.length !== 0) {
    //     this.$confirm('变更供应商将清空已添加的入库单明细?', '变更确认', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning',
    //       closeOnClickModal: false
    //     }).then(() => {
    //       // 清空选中项
    //       this.addData.warehouseOutboundLineVoList = []
    //     })
    //   }
    // },
    // 获取仓库列表
    async getStoreHouseList() {
      const { data: res } = await storeHouseListAllApi(this.storeHouseListData)
      this.storeHouseList = res.data.page.list
    },
    // 获取产品列表(仓库专用)
    async getMatterManageList() {
      if (this.addData.type === 0) {
        const { data: res } = await purchaseOutApi(this.balanceAccountData)
        if (res.code !== 0) return this.$message.error(res.msg)
        let newMatterManageList = []
        let newArray = res.data.data.list
        // 过滤掉库存数为0的产品
        newArray.forEach(item => {
          if (item.deliveryNumber !== 0) {
            newMatterManageList.push(item)
          }
        })
        // 去除列表重复项
        if (this.addData.warehouseOutboundLineVoList.legnth !== 0) {
          this.addData.warehouseOutboundLineVoList.forEach(item => {
            newMatterManageList.forEach((val, index) => {
              if (item.productId === val.id) {
                newMatterManageList.splice(index, 1)
              }
            })
          })
        }
        this.matterManageList2 = newMatterManageList
      } else {
        const { data: res } = await storeHouseMatterListApi(this.storeHouseData)
        if (res.code !== 0) return this.$message.error(res.msg)
        let newMatterManageList = []
        let newArray = res.data.page.list
        // 过滤掉库存数为0的产品
        newArray.forEach(item => {
          if (item.number !== 0) {
            newMatterManageList.push(item)
          }
        })
        // 去除列表重复项
        if (this.addData.warehouseOutboundLineVoList.legnth !== 0) {
          this.addData.warehouseOutboundLineVoList.forEach(item => {
            newMatterManageList.forEach((val, index) => {
              if (item.productId === val.id) {
                newMatterManageList.splice(index, 1)
              }
            })
          })
        }
        // debugger
        this.matterManageList = newMatterManageList
      }
    },
    // 打开添加弹框
    openAddDialog() {
      // 判断是否选择仓库
      if (this.addData.warehouseId === '') return this.$message.error('请先选择出库仓库!')
      this.storeHouseData.warehouseId = this.addData.warehouseId
      this.chooseOrderDialog = true
      this.getMatterManageList()
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    },
    // 物料选择
    chooseMatter() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      if (this.addData.type === 0) {
        console.log(this.matterList)
        this.matterList.forEach((item, index) => {
          let newData = {
            code: item.code,
            id: item.id,
            productId: item.productId,
            productName: item.productName,
            productNewColour: item.productNewColour,
            productSpecs: item.productSpecs,
            product: item.productName,
            unit: item.unitName,
            // 入库数量
            quantity: '',
            // 仓库数量
            quantityEnd: 0,
            orderLineId: item.id,
            // 采购数量
            number: item.number,
            // 可入数量
            residueNumber: item.residueNumber,
            takeDeliveryNumber: item.takeDeliveryNumber,
            warrantNumber: item.warrantNumber
          }
          this.addData.warehouseOutboundLineVoList.push(newData)
        })
      } else {
        this.matterList.forEach((item, index) => {
          let newData = {
            productId: item.productId,
            productCode: item.productCode,
            productName: item.productName,
            productSpecs: item.productSpecs,
            productNewColour: item.productNewColour,
            productUnit: item.productUnit,
            // 库存数量
            number: item.number,
            // 出库数量
            quantity: '',
            // 出库后剩余库存数
            quantityEnd: ''
          }
          this.addData.warehouseOutboundLineVoList.push(newData)
        })
      }
      this.chooseOrderDialog = false
    },
    // 删除产品
    delMatter(index) {
      if (index === 'all') {
        if (this.chooseList.length === 0) return this.$message.error('请先勾选需要删除的产品')
        this.$confirm('是否删除勾选产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.chooseList.forEach(porductId => {
            this.addData.warehouseOutboundLineVoList.forEach((val, index) => {
              if (porductId === val.productId) {
                this.addData.warehouseOutboundLineVoList.splice(index, 1)
              }
            })
          })
          // 清空选中项
          this.chooseList = []
        })
      } else {
        this.$confirm('是否确认删除当前产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.addData.warehouseOutboundLineVoList.splice(index, 1)
        })
      }
    },
    // 选择仓库
    chooseStoreHouse() {
      // 判断是不是第一次选择仓库,不是的话清空出库明细
      if (this.addData.warehouseOutboundLineVoList.length !== 0) {
        this.$confirm('切换仓库将会清空明细,是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.addData.warehouseOutboundLineVoList = []
        })
      }
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index, max) {
      let temp = val.toString()
      temp = temp.replace(/。/g, '.')
      temp = temp.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, '') // 验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, '') // 只保留第一个, 清除多余的
      temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // eslint-disable-next-line no-useless-escape
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
      // 判断出库数不得大于库存数
      if (temp > max) {
        temp = max
      }
      this.addData.warehouseOutboundLineVoList[index].quantity = temp
    },
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 新增出库单
    addOutbound() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          this.addData.warehouseOutboundLineVoList.forEach((item, index) => {
            item.quantityEnd = item.number - item.quantity
          })
          const { data: res } = await addOutboundApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('新增出库单成功!')
          setTimeout(() => {
            this.$router.back()
          }, 300)
        } else {
          return false
        }
      })
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseEntry {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .purchaseEntryContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 180px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 2px;
      padding: 20px;
      height: 166px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .navBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 50%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 102px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
          }
        }
        /deep/.el-form-item:nth-child(1) {
          width: 100%;
        }
      }
    }
    main {
      position: relative;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              color: rgba(0, 0, 0, 0.60);
            }
            >p {
              height: 24px;
              width: 100%;
              font-size: 14px;
              span {
                display: inline-block;
                width: 400px;
                color: rgba(0, 0, 0, 0.60);
              }
              i {
                float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 100%;
              .el-form-item__content {
                width: 100%;
                width: calc(100% - 102px);
                .el-input {
                  width: 100%;
                }
              }
            }
            .showNumBox {
              position: absolute;
              right: 10px;
              height: 32px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 12px;
              line-height: 32px;
              font-weight: 400;
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
