// 我的待办
<template>
  <div class='myWait'>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/index' }">工作台</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">我的待办</el-breadcrumb-item>
    </el-breadcrumb>
    <main>
      <h5>我的待办 ({{this.total + this.total2}})</h5>
      <h6>采购计划单 ({{this.total}})</h6>
      <div class="mainContent">
        <el-pagination
          class="planPage"
          style="margin: 10px 0"
          layout="prev, pager, next"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          @size-change="handlesizeChange"
          :page-sizes="[50, 100, 500]"
          :page-size="planData.size"
          :total="total">
        </el-pagination>
        <div class="mainBox" v-for="(item, index) in planList" :key="index" @click="routerJump(1, item.id)">
          <p><span style="color: rgba(0, 0, 0, 0.80); font-weight: bold;">审核流程</span><span v-if="item.createTime">{{(item.createTime).substring(0, 16)}}</span></p>
          <p style="justify-content: flex-start;"><span style="color: rgba(0, 0, 0, 0.80); font-weight: bold;">{{item.code}}</span><i>{{item.type === 0 ? '生产采购' : (item.type === 1 ? '委外采购' : '备货采购')}}</i></p>
          <p style="boder-bottom: 0;"><span>申请人 : {{item.proposerName}}</span><span v-if="item.approveTime">申请日期 : {{(item.approveTime).substring(0, 16)}}</span></p>
        </div>
      </div>
      <h6>采购单 ({{this.total2}})</h6>
      <div class="mainContent">
        <el-pagination
          class="orderPage"
          style="margin: 10px 0"
          layout="prev, pager, next"
          :current-page="currentPage2"
          @current-change="handleCurrentChange2"
          @size-change="handlesizeChange2"
          :page-sizes="[50, 100, 500]"
          :page-size="orderData.size"
          :total="total2">
        </el-pagination>
        <div class="mainBox2" v-for="(item, index) in orderList" :key="index" @click="routerJump(2, item.id)">
          <p><span style="color: rgba(0, 0, 0, 0.80); font-weight: bold;">审核流程</span><span v-if="item.approveTime">{{(item.approveTime).substring(0, 16)}}</span></p>
          <p style="justify-content: flex-start;"><span style="color: rgba(0, 0, 0, 0.80); font-weight: bold;">{{item.code}}</span><i>{{item.type === 0 ? '原材料采购' : '外协生产订单'}}</i><i>{{item.deliveryType === 0 ? '自提' : '供方送货'}}</i></p>
          <p><span>供应商: <span style="color: rgba(0, 0, 0, 0.80)">{{item.sellName === null ? '暂无' : item.sellName}}</span></span></p>
          <p style="boder-bottom: 0;"><span>创建人 : {{item.proposerName}}</span><span v-if="item.createTime">创建日期 : {{(item.createTime).substring(0, 16)}}</span></p>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { myWaitOrderListApi, myWaitPlanListApi } from "@/api"
export default {
  data() {
    return {
      planData: {
        page: 1,
        size: 3
      },
      // 默认显示页码
      currentPage: 1,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      planList: [],
      orderData: {
        page: 1,
        size: 3
      },
      // 默认显示页码
      currentPage2: 1,
      // 总页数
      totalNum2: 0,
      // 总条数
      total2: 0,
      orderList: []
    }
  },
  created() {
    this.myWaitOrderList()
    this.myWaitPlanList()
  },
  methods: {
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: "/purchasePlanDetail", query: { result: JSON.stringify(result) }})
      } else {
        this.$router.push({ path: '/purchaseOrderDetail', query: { result: JSON.stringify(result) } })
      }
    },
    // 我的待办订单列表
    async myWaitOrderList() {
      const { data: res } = await myWaitOrderListApi(this.orderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.orderList = res.data.page.list
      // 展示当前页面
      this.currentPage2 = res.data.page.currPage
      // 展会页面总页数
      this.totalNum2 = res.data.page.totalPage
      // 展示总条数
      this.total2 = res.data.page.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.planData.size = val
      this.planData.page = 1
      this.myWaitPlanList()
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.planData.page = val
      this.currentPage = val
      this.myWaitPlanList()
    },
    // 触发每页显示条数
    handlesizeChange2(val) {
      this.orderData.size = val
      this.orderData.page = 1
      this.myWaitOrderList()
    },
    // 当前页数据动态改变
    handleCurrentChange2(val) {
      this.orderData.page = val
      this.currentPage2 = val
      this.myWaitOrderList()
    },
    // 我的待办计划列表
    async myWaitPlanList() {
      const { data: res } = await myWaitPlanListApi(this.planData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.planList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    }
  }
}
</script>
<style scoped lang='less'>
.myWait {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  main {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 140px);
    background-color: #fff;
    border-radius: 10px;
    overflow-y: auto;
    h5 {
      margin: 20px;
      height: 22px;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    h6 {
      margin: 20px;
      height: 22px;
      font-size: 14px;
      font-weight: bold;
      color: rgba(18, 62, 131, 1);
      line-height: 19px;
    }
    .mainContent {
      position: relative;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      .mainBox {
        width: calc(33% - 13.3px);
        height: 122px;
        border-radius: 16px;
        background-color: rgba(0, 0, 0, 0.03);
        cursor: pointer;
        p {
          margin: 0 20px;
          display: flex;
          justify-content: space-between;
          height: 40px;
          border-bottom: 1px solid #fff;
          line-height: 40.5px;
          color: rgba(0, 0, 0, 0.60);
          i {
            margin: 10px 0 0 10px;
            padding: 0 8px;
            height: 20px;
            background-color: rgba(70, 147, 235, 0.10);
            border-radius: 4px;
            line-height: 20px;
            font-size: 12px;
            color: rgba(70, 147, 235, 1);
          }
        }
      }
      .mainBox2 {
        width: calc(33% - 13.3px);
        height: 162px;
        border-radius: 16px;
        background-color: rgba(0, 0, 0, 0.03);
        cursor: pointer;
        p {
          margin: 0 20px;
          display: flex;
          justify-content: space-between;
          height: 40px;
          border-bottom: 1px solid #fff;
          line-height: 40.5px;
          color: rgba(0, 0, 0, 0.60);
          i {
            margin: 10px 0 0 10px;
            padding: 0 8px;
            height: 20px;
            background-color: rgba(70, 147, 235, 0.10);
            border-radius: 4px;
            line-height: 20px;
            font-size: 12px;
            color: rgba(70, 147, 235, 1);
          }
        }
      }
      .planPage {
        position: absolute;
        top: -50px;
        right: 20px;
        float: right;
      }
      .orderPage {
        position: absolute;
        top: -50px;
        right: 20px;
        float: right;
      }
      .mainBox:hover, .mainBox2:hover {
        background-color: #fff;
        box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.1);
      }
    }
  }
}
</style>
