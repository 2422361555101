// 成员与部门
<template>
  <div class="sellerManage">
    <nav>
      <h5><span>成员管理</span><span @click="routerJump()">部门管理</span></h5>
      <div>
        
        <!-- v-if="btnLimit.includes('新增用户')" -->
        <el-button class="lightDeepStyle" @click="openMemberDialog()"><i class="el-icon-plus"></i>添加成员</el-button>
        <!-- <el-button class="lightDeepStyle" @click="openDialog2()"><i class="el-icon-edit-outline"></i>变更部门</el-button> -->
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model="userManageData.name" placeholder="部门/成员搜索" @input="getUserList2('search')" prefix-icon="el-icon-search"></el-input>
    </section>
    <div class="mainBox">
      <article>
        <div :class="chooseClassId === -1  ? 'highH artHeader' : 'artHeader'"><i class="el-icon-document"></i><span @click="chooseClass(-1)" class="textHidden">{{companyName}}</span></div>
        <div class="artContent">
          <!-- 多级部门 -->
          <div class="artBox" v-for="(item, index) in departList" :key="index">
            <p @click="chooseClass(item.id)"  :class="chooseClassId === item.id  ? 'highP' : ''">
              <i @click.stop="openNewArt(item.id, 1)" v-if="item.childList && item.childList.length !== 0" :class="openClassId === item.id ? 'el-icon-minus' : 'el-icon-plus'"></i>
              {{item.name}}
              <el-dropdown @command="chooseCommand($event, item)">
                <span>
                  <i style="transform: rotate(90deg)" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="dropdownUl">
                  <el-dropdown-item command="1">
                    <i class="el-icon-edit-outline"></i><span>编辑</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="2">
                    <i class="el-icon-delete"></i><span>删除</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </p>
            <div class="artChildBox" v-if="item.childList && item.childList.length !== 0 && openClassId === item.id" v-for="(items, index) in item.childList" :key="index">
              <p @click="chooseClass(items.id)"  :class="chooseClassId === items.id  ? 'highP' : ''">
                <i @click="openNewArt(items.id, 2)" v-if="items.childList && items.childList.length !== 0" :class="openClassId2 === items.id ? 'el-icon-minus' : 'el-icon-plus'"></i>
                {{items.name}}
                <el-dropdown @command="chooseCommand($event, items)">
                  <span>
                    <i style="transform: rotate(90deg)" class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="dropdownUl">
                    <el-dropdown-item command="1">
                      <i class="el-icon-edit-outline"></i><span>编辑</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="2">
                      <i class="el-icon-delete"></i><span>删除</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </p>
              <div class="artChildBox2" v-if="items.childList && items.childList.length !== 0 && openClassId2 === items.id" v-for="(itemss, index) in items.childList" :key="index">
                <p @click="chooseClass(itemss.id)"  :class="chooseClassId === itemss.id  ? 'highP' : ''">
                  <i v-if="itemss.childList && itemss.childList.length !== 0" class="el-icon-plus"></i>
                  {{itemss.name}}
                  <el-dropdown @command="chooseCommand($event, itemss)">
                    <span>
                      <i style="transform: rotate(90deg)" class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="dropdownUl">
                      <el-dropdown-item command="1">
                        <i class="el-icon-edit-outline"></i><span>编辑</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="2">
                        <i class="el-icon-delete"></i><span>删除</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="artFooter">
          <el-button class="lightDeepStyle" @click="openDialog()"><i class="el-icon-plus"></i>添加部门</el-button>
        </div>
      </article>
      <main>
        <el-table ref="tableHeight" row-key="id" :height="tableHeight" :data="userManageList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="" label="姓名" min-width="120px" align="center">
            <template slot-scope="scope">
              <a>{{scope.row.name}}</a>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="dept" label="部门" min-width="120px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.dept === null ? '暂无' : scope.row.dept}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="" label="岗位" min-width="140px" align="center">
            <template slot-scope="scope">
              <a @click="scope.row.roleName === '管理员' ? '' : routerPush('roleManageEdit', { roleId: scope.row.roleId })" style="color: rgba(18, 62, 131, 1)">{{scope.row.roleName}}</a>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="mobile" label="手机号" min-width="140px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip label="最后登录时间" min-width="140px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.lastTime === null ? '-' : scope.row.lastTime}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="邀请状态" min-width="80px" align="center">
            <template slot-scope="scope">
              <div>
                <el-button class="joining" v-if="scope.row.isActivate === 2">邀请中</el-button>
                <el-button class="joined" v-if="scope.row.isActivate === 1">已加入</el-button>
                <el-button class="send" v-if="scope.row.isActivate === 0">未邀请</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
            <template slot-scope="scope">
              <el-dropdown @command="chooseCommand2($event, scope.row)">
                <span>
                  <i style="transform: rotate(90deg)" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="dropdownUl">
                  <el-dropdown-item v-if="btnLimit.includes('编辑成员')" command="5">
                    <i class="el-icon-delete"></i><span>编辑</span>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isActivate === 0 && scope.row.state === '1' && btnLimit.includes('发送邀请')" command="1">
                    <i class="el-icon-edit-outline"></i><span>发送邀请</span>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isActivate === 2" command="2">
                    <i class="el-icon-edit-outline"></i><span>再次发送</span>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isActivate === 0 && btnLimit.includes('删除成员')" command="3">
                    <i class="el-icon-delete"></i><span>删除</span>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isActivate === 1" command="4">
                    <i class="el-icon-delete"></i><span>离职交接</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
        <el-pagination
          style="margin: 10px 0"
          layout="prev, pager, next"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          @size-change="handlesizeChange"
          :page-sizes="[50, 100, 500]"
          :page-size="userManageData.size"
          :total="total">
        </el-pagination>
      </main>
    </div>
    <!-- 添加部门弹框 -->
    <template>
      <div>
        <el-dialog class="changeDialog" :title="departData.id === '' ? '添加部门' : '修改部门'" :visible.sync="editDialog" :close-on-click-modal="false" v-dialogDrag>
          <el-form :model="departData" :rules="rules" ref="departForm">
            <el-form-item label="部门名称:" prop="name">
              <el-input placeholder="输入部门名称" v-model="departData.name"></el-input>
            </el-form-item>
            <el-form-item label="上级部门:" prop="parentId">
              <el-cascader @change="handleExportCatalogChange" ref="catalogue" :options="departList" :props="defaultData" clearable v-model="departData.parentId" placeholder="请选择部门(没有则不填)"></el-cascader>
            </el-form-item>
            <el-form-item v-if="departData.id && departData.id !== ''" label="部门负责人:" prop="principalUserId">
              <el-select v-model="departData.principalUserId" placeholder="请选择部门负责人" size="mini">
                <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="editDialog = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="addDepartment()">保存</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <!-- 添加成员 -->
    <template>
      <div>
        <el-dialog class="changeDialog2" :title="infoId === '' ? '添加成员' : '修改成员'" :visible.sync="editMemberDialog" :close-on-click-modal="false" v-dialogDrag>
          <el-form :model="ruleForm" :rules="rules2" ref="ruleForm">
            <el-form-item label="姓名:" prop="name">
              <el-input placeholder="输入姓名" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="工号:" prop="jobNum">
              <el-input placeholder="输入工号" v-model="ruleForm.jobNum"></el-input>
            </el-form-item>
            <el-form-item label="手机号:" prop="mobile">
              <el-input v-if="infoId === ''" placeholder="输入手机" v-model="ruleForm.mobile" oninput="value=value.replace(/[^\d]/g,'')" maxLength='11'></el-input>
              <el-input v-else disabled placeholder="输入手机" v-model="ruleForm.mobile" oninput="value=value.replace(/[^\d]/g,'')" maxLength='11'></el-input>
            </el-form-item>
            <el-form-item label="部门:" prop="deptId">
              <el-cascader @change="handleExportCatalogChange" ref="catalogue" :options="departList" :props="defaultData2" clearable v-model="ruleForm.deptId" placeholder="请选择部门"></el-cascader>
            </el-form-item>
            <el-form-item label="岗位权限:" prop="powerId">
              <el-select v-model="ruleForm.powerId" placeholder="请选择" size="mini" @visible-change="getRoleList()">
                <el-option v-for="item in position" :key="item.roleId" :label="item.roleName" :value="item.roleId" @click.native="changeStatus(item)"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="editMemberDialog = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="addChildUser()">保存</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <!-- 离职交接 -->
    <template>
      <div>
        <el-dialog class="classDialog" title="离职交接" :visible.sync="classDialog" :close-on-click-modal="false" v-dialogDrag>
          <div class="dialogContent">
            <div class="depart">
              <p><span>离职人员: {{departTransferData.oldProposerDetail.name}}</span></p>
              <div class="departBox">
                <p>工号: {{departTransferData.oldProposerDetail.jobNum}}</p>
                <p>手机号: {{departTransferData.oldProposerDetail.mobile}}</p>
                <p>部门: {{departTransferData.oldProposerDetail.dept}}</p>
                <p>岗位权限: {{departTransferData.oldProposerDetail.roleName}}</p>
              </div>
            </div>
            <div class="depart">
              <p>
                <span><i style="color: rgba(255, 0, 0, 1)">*</i>交接人员: </span>
                <el-select @change="chooseTransfer" v-model="departTransferData.proposerId" placeholder="请选择" size="mini">
                  <el-option v-for="item in allUserList" :key="item.id" :label="item.name" :value="item.userId"></el-option>
                </el-select>
              </p>
              <div class="departBox">
                <p>工号: {{departTransferData.proposerDetail.jobNum === '' ? '选择后读取' : departTransferData.proposerDetail.jobNum}}</p>
                <p>手机号: {{departTransferData.proposerDetail.mobile === '' ? '选择后读取' : departTransferData.proposerDetail.mobile}}</p>
                <p>部门: {{departTransferData.proposerDetail.dept === '' ? '选择后读取' : departTransferData.proposerDetail.dept}}</p>
                <p>岗位权限: {{departTransferData.proposerDetail.roleName === '' ? '选择后读取' : departTransferData.proposerDetail.roleName}}</p>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="classDialog = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="getDepartTransfer()">确认</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
  </div>
</template>
<script>
import { departmentListApi, userIdGetUserInfoApi, editChildUserApi, addChildUserApi, sendShortApi, delUserApi, delDepartmentApi, addDepartmentApi, getUserManageApi, idGetRoleListApi, getUserCodeApi, departTransferApi } from '@/api'
export default {
  name: 'sellerManage',
  data() {
    return {
      companyName: JSON.parse(window.sessionStorage.getItem('userInfo')).company,
      // 部门列表
      departList: [],
      // 展开的一级部门id
      openClassId: '',
      // 展开的二级部门id
      openClassId2: '',
      chooseClassId: -1,
      departData: {
        id: '',
        name: '', // 姓名
        parentId: '', // 上级部门Id
        principalUserId: '', // 负责人Id 修改时传
      },
      // 控制弹出框
      editDialog: false,
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'childrenList2',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false,
        checkStrictly: true
      },
      // 所有成员
      allUserList: [],
      // 部门对应成员列表
      userList: [],
      // 传参参数
      userData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 999,
        // 搜索内容
        name: '',
        deptId: '',
        companyId: window.$cookies.get('companyId'),
        state: '',
        isActivate: ''
      },
      // 校验
      rules: {
        name: [
          { required: true, message: '部门名称不能为空!', trigger: 'blur' }
        ]
      },
      // 成员弹框
      editMemberDialog: false,
      // 校验
      rules2: {
        jobNum: [
          { required: true, message: '工号不能为空!', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '姓名不能为空!', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空!', trigger: 'blur' },
          { max: 16, message: '最多可输入16个字符', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              console.log(value)
              if (value !== '') {
                if (/^(13[0-9]|14[0-9]|15[0-9]|16[6]|18[0-9]|19[6,9]|17[0-9])\d{8}$/i.test(value) === false) {
                  callback(new Error('手机号格式不正确!'))
                } else {
                  // 校验通过
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        // deptId: [
        //   { required: true, message: '部门不能为空!', trigger: 'blur' }
        // ],
        powerId: [
          { required: true, message: '岗位不能为空!', trigger: 'blur' }
        ]
      },
      ruleForm: {
        deptId: '', // 部门id
        jobNum: '', // 工号
        name: '', // 姓名
        mobile: '', // 手机号
        isSuper: 1, // 是否为管理员,
        state: 1, // 是否在职,
        // 当前选中的岗位权限id
        powerId: null,
        companyId: JSON.parse(window.$cookies.get('companyId')), // 当前公司id,
        companyRoleList: [] // 选取的岗位权限
      },
      defaultData2: {
        value: 'id',
        label: 'name',
        children: 'childrenList',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false,
        checkStrictly: true
      },
      // 存储岗位权限
      position: [],
      // 当前选中的岗位
      power: '',
      // 选中的成员id
      infoId: '',
      // 传参参数
      userManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        // 搜索内容
        name: '',
        companyId: window.$cookies.get('companyId'),
        state: 1,
        isActivate: ''
      },
      userManageList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 控制部门弹框
      classDialog: false,
      // 离职转移数据参数
      departTransferData: {
        oldProposerId: '',
        oldProposerDetail: '',
        proposerId: '',
        proposerDetail: {
          jobNum: '',
          mobile: '',
          dept: '',
          roleName: ''
        }
      },
      tableHeight: '',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getDepartmentList('')
    this.getUserList()
    this.chooseClass(-1)
    this.getRoleList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 选择离职交接对象
    chooseTransfer(val) {
      this.allUserList.forEach(item => {
        if (item.id === val) {
          this.departTransferData.proposerDetail = item
        }
      })
    },
    // 离职交接
    async getDepartTransfer() {
      if (this.proposerId === '') return this.$message.error('交接人员不能为空!')
      const { data: res } = await departTransferApi(this.departTransferData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('交接成功!')
      this.classDialog = false
      this.getUserList2()
    },
    // 选择成员
    chooseClass(id) {
      this.chooseClassId = id
      this.userManageData.deptId = this.chooseClassId
      this.userManageData.name = ''
      // 获取成员列表
      this.getUserList2()
    },
    openNewArt(id, val) {
      if (val === 1) {
        // 点击第一级
        if (this.openClassId === id) {
          this.openClassId = ''
        } else {
          this.openClassId = id
        }
      } else if (val === 2) {
        // 点击第二级
        if (this.openClassId2 === id) {
          this.openClassId2 = ''
        } else {
          this.openClassId2 = id
        }
      }
    },
    // 获取部门列表
    async getDepartmentList(search) {
      const { data: res } = await departmentListApi(search)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.departList = res.data
      // 处理子部门
      this.departList.forEach((item, index) => {
        if (item.childList && item.childList.length !== 0) {
          item.childrenList = item.childList
          item.childrenList2 = item.childList
          item.childrenList.forEach((items, index) => {
            if (items.childList && items.childList.length !== 0) {
              items.childrenList = item.childList
              items.childrenList.forEach((itemss, index) => {
                if (itemss.childList && itemss.childList.length !== 0) {
                  itemss.childrenList = itemss.childList
                }
              })
            }
          })
        }
      })
    },
    // 关闭多级联动框
    handleExportCatalogChange(val) {
      this.$refs.catalogue.dropDownVisible = false
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.openDialog(result)
      } else if (command === '2') {
        this.delDepartment(result)
      }
    },
    // 打开部门弹框
    openDialog(item) {
      // 清空默认值
      this.departData = {
        id: '',
        name: '', // 姓名
        parentId: '', // 上级部门Id
        principalUserId: '', // 负责人Id 修改时传
      }
      if (item) {
        this.departData = {
          id: item.id,
          name: item.name, // 姓名
          parentId: item.parentId, // 上级部门Id
          principalUserId: item.principalUserId, // 负责人Id 修改时传
        }
        if (this.departData.principalUserId == 0) {
          this.departData.principalUserId = ''
        }
        if (this.departData.id !== -1) {
          this.userData.deptId = this.departData.id
        } else {
          this.userData.deptId = 0
        }
        this.getUserList()
      }
      this.editDialog = true
    },
    // 删除部门
    async delDepartment(value) {
      if (value.userCount == 0) {
        this.$confirm('部门里成员为<i style="margin-left: 3px; color: rgba(18, 62, 131, 1)">0</i>，可以删除。', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
          dangerouslyUseHTMLString: true
        }).then(async() => {
          const { data: res } = await delDepartmentApi(value.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '删除部门成功!' })
          this.getDepartmentList('')
        })
      } else {
        this.$confirm('部门还有<i style="margin-left: 3px; color: rgba(18, 62, 131, 1)">' + value.userCount +'</i>个成员，无法进行删除操作<br>需要部门成员为<i style="margin-left: 3px; color: rgba(131, 18, 18, 1)">0</i>才能删除部门!', '删除提示', {
          confirmButtonText: '关闭',
          type: 'warning',
          closeOnClickModal: false,
          showCancelButton: false,
          dangerouslyUseHTMLString: true
        })
      }
    },
    // 获取用户列表(成员列表)
    async getUserList(val) {
      if (val === 'all') {
        this.userData = {
          // 当前页
          page: 1,
          // 当前页条数
          size: 999,
          // 搜索内容
          name: '',
          deptId: '',
          companyId: window.$cookies.get('companyId'),
          state: 1,
          isActivate: 1
        }
        const { data: res } = await getUserManageApi(this.userData)
        // 获取表单数据
        this.allUserList = res.data.page.list
      } else {
        const { data: res } = await getUserManageApi(this.userData)
        // 获取表单数据
        this.userList = res.data.page.list
      }
    },
    // 新增修改部门
    async addDepartment() {
      this.$refs['departForm'].validate(async valid => {
        if (valid) {
          const { data: res } = await addDepartmentApi(this.departData)
          if (res.code !== 0) return this.$message.error(res.msg)
          if (this.departData.id === '') {
            this.$message.success('新增成功!')
          } else {
            this.$message.success('修改成功!')
          }
          this.editDialog = false
          this.getDepartmentList('')
        } else {
          return false
        }
      })
    },
    // 路由跳转
    routerJump() {
      this.$router.push('./classManage')
    },
    // 打开成员弹框
    openMemberDialog(infoId) {
      if (infoId) {
        this.infoId = infoId
        this.getUserInfo()
      } else {
        this.infoId = ''
        this.power = ''
        this.ruleForm = {
          deptId: this.userManageData.deptId === -1 ? 0 : this.userManageData.deptId, // 部门id
          jobNum: '', // 工号
          name: '', // 姓名
          mobile: '', // 手机号
          isSuper: 0, // 是否为管理员,
          state: 1, // 是否在职,
          powerId: '',
          companyId: JSON.parse(window.$cookies.get('companyId')), // 当前公司id,
          companyRoleList: [] // 选取的岗位权限
        }
        this.getUserCode()
      }
      this.editMemberDialog = true
    },
    // 添加(修改)成员
    addChildUser() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.infoId === '') {
            // 新增
            this.ruleForm.companyRoleList = []
            this.ruleForm.companyRoleList.push(this.power)
            const { data: res } = await addChildUserApi(this.ruleForm)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '添加成员成功!' })
          } else {
            // 修改
            if (this.power !== '') {
              this.ruleForm.companyRoleList = []
              this.ruleForm.companyRoleList.push(this.power)
            }
            const { data: res } = await editChildUserApi(this.ruleForm)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success({ duration: 1000, message: '修改成员成功!' })
          }
          this.getUserList2()
          this.editMemberDialog = false
        } else {
          return false
        }
      })
    },
    // 获取岗位列表
    async getRoleList() {
      const { data: res } = await idGetRoleListApi(this.ruleForm.companyId)
      this.position = res.data.list
    },
    async getUserCode() {
      const { data: res } = await getUserCodeApi()
      if (this.infoId === '') {
        this.ruleForm.jobNum = res.data.data
      }
    },
    // 切换岗位权限
    changeStatus(item) {
      this.ruleForm.companyRoleList = []
      this.power = item
    },
    // 获取成员列表
    async getUserList2(reset) {
      if (reset !== 'reset') {
        this.userManageData.page = 1
      }
      if (reset === 'search') {
        this.getDepartmentList(this.userManageData.name)
        if (name === '') {
          this.userManageData.deptId = ''
          this.chooseClassId = ''
        } else {
          this.userManageData.deptId = -1
        }
      }
      const { data: res } = await getUserManageApi(this.userManageData)
      // 获取表单数据
      this.userManageList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.userManageData.size = val
      this.userManageData.page = 1
      this.getUserList2('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.userManageData.page = val
      this.currentPage = val
      this.getUserList2('reset')
    },
    routerPush(url, query) {
      this.$router.push({ path: url, query: query })
    },
    // 选择下拉
    chooseCommand2(command, result) {
      if (command === '1') {
        this.sendShort(result)
      } else if (command === '2') {
        this.sendShort(result)
      } else if (command === '3') {
        this.delUser(result.id)
      } else if (command === '4') {
        // 离职交接
        this.departTransferData.oldProposerId = result.userId
        this.departTransferData.oldProposerDetail = result
        this.openChangeClassDialog(result.id)
        this.getUserList('all')
      } else if (command === '5') {
        // 编辑成员
        this.openMemberDialog(result.id)
      }
    },
    // 发送短信
    async sendShort(result) {
      this.$confirm('确定发送邀请吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await sendShortApi(result)
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '发送成功!'
          })
          this.getUserList2()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '取消发送'
        })
      })
    },
    // 删除用户
    async delUser(id) {
      this.$confirm('确定删除当前成员吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await delUserApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '删除成员成功!' })
        this.getUserList2()
      }).catch(() => {
      })
    },
    // 根据用户id获取用户详情
    async getUserInfo() {
      const { data: res } = await userIdGetUserInfoApi(this.infoId)
      if (res.code !== 0) return this.$message.error('获取用户详情失败!')
      this.ruleForm = res.data.detail
      this.ruleForm.state = Number(this.ruleForm.state)
      this.ruleForm.powerId = res.data.detail.companyRoleList[0].roleId
    },
    // 打开部门弹框
    openChangeClassDialog(id) {
      this.classDialog = true
    },
  }
}
</script>
<style scoped lang='less'>
.sellerManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
      span {
        padding: 5px 0;
        font-weight: 400;
        cursor: pointer;
      }
      span:nth-child(1) {
        margin-right: 20px;
        border-bottom: 3px solid rgba(18, 62, 131, 1);
        font-weight: bold;
      }
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 186px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 186px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  .mainBox {
    display: flex;
    article {
      margin: 20px;
      padding: 20px 10px;
      width: 240px;
      background-color: rgba(248, 249, 250, 1);
      border-radius: 10px;
      box-sizing: border-box;
      .artHeader {
        height: 40px;
        width: 220px;
        line-height: 40px;
        font-size: 16px;
        i {
          margin: 0 10px;
          color: rgba(0, 0, 0, 0.60);
        }
        span {
          display: inline-block;
          width: 180px;
          vertical-align: middle;
          color: rgba(0, 0, 0, 0.70);
          font-weight: bold;
          cursor: pointer;
        }
      }
      .highH {
        background-color: #123E83;
        border-radius: 4px;
        i {
          color: #fff;
        }
        span {
          color: #fff;
        }
      }
      .artContent {
        height: calc(100% - 93px);
        .artBox, .artChildBox, .artChildBox2 {
          margin-top: 10px;
          width: 220px;
          background-color: rgba(248, 249, 250, 1);
          box-sizing: border-box;
          cursor: pointer;
          p {
            padding-left: 10px;
            height: 40px;
            box-sizing: border-box;
            border-radius: 4px;
            line-height: 40px;
            color: rgba(0, 0, 0, 0.60);
            font-size: 14px;
            font-weight: 500;
            i {
              margin-right: 10px;
              padding: 2px;
              border: 1px solid rgba(0, 0, 0, 0.06);
              color: rgba(0, 0, 0, 0.60);
              font-size: 12px;
            }
            .el-dropdown {
              float: right;
            }
          }
          .highP {
            background-color: #123E83!important;
            border-radius: 4px;
            color: #fff;
            i {
              color: #fff;
              border-color: #fff;
            }
          }
        }
        .artChildBox {
          width: 210px;
          p {
            width: 220px;
            padding-left: 20px;
          }
        }
        .artChildBox2 {
          width: 200px;
          p {
            width: 220px;
            padding-left: 30px;
          }
        }
        .artBox:hover {
          p {
            background-color: rgb(102, 123, 158);
            color: #fff;
            i {
              color: #fff;
              border-color: #fff;
            }
          }
          .artChildBox {
            p {
              background-color: rgb(102, 123, 158);
            }
            .artChildBox2 {
              p {
                background-color: rgb(102, 123, 158);
              }
            }
          }
        }
      }
      .artFooter {
        padding: 20px 10px;
        height: 53px;
        box-sizing: border-box;
        border-top: 1px solid #EDEEEF;
        .el-button {
          width: 200px;
        }
      }
    }
    main {
      width: calc(100% - 300px);
      /deep/ .el-table .cell.el-tooltip {
        display: flex;
        justify-content: center;
      }
      .el-table .el-button {
        padding: 0;
        width: 52px;
        height: 22px;
        background: rgba(239, 188, 96, 0.05);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(239, 188, 96, 0.20);
        font-size: 12px;
        line-height: 0;
        color: rgba(239, 188, 96, 1);
        cursor: default;
        text-align: center;
      }
      .el-table .joined {
        background: rgba(70,147,235,0.05);
        border: 1px solid rgba(70,147,235,0.2);
        color: rgba(70, 147, 235, 1);
      }
      .el-table .joining {
        background: rgba(70,147,235,0.05);
        border: 1px solid rgba(70,147,235,0.2);
        color: rgba(70, 147, 235, 1);
      }
    }
  }
  .changeDialog {
    /deep/ .el-dialog {
      height: 408px;
      width: 424px;
      .el-dialog__body {
        height: 288px;
        .el-input {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
  .changeDialog2 {
    /deep/ .el-dialog {
      height: 548px;
      width: 424px;
      .el-dialog__body {
        height: 428px;
        .el-input {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
  .classDialog {
    /deep/ .el-dialog {
      height: 368px;
      width: 704px;
      .el-dialog__body {
        height: 250px;
        .dialogContent {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 208px;
          .depart {
            width: calc(50% - 20px);
            >p {
              margin-bottom: 20px;
              height: 32px;
              line-height: 32px;
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.80);
              >span {
                margin-right: 10px;
              }
              .el-select {
                width: 230px;
              }
            }
            .departBox {
              padding: 8px 10px;
              height: 112px;
              background: rgba(0,0,0,0.05);
              border-radius: 4px;
              border: 1px solid rgba(0,0,0,0.05);
              p {
                color: #000;
                font-size: 14px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
