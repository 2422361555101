// 产品分类
<template>
  <div class="matterCategory">
    <nav>
      <h5>产品分类</h5>
      <div>
        <el-button v-if="btnLimit.includes('新增产品分类')" class="lightDeepStyle" @click="addCategory()"><i class="el-icon-plus"></i>新增分类</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" placeholder="产品分类搜索" v-model.trim="search" prefix-icon="el-icon-search" @input="changeColor"></el-input>
    </section>
    <!-- 卡片视图区 -->
    <div class="matterCategoryContent">
      <el-table ref="cimsDictTable" :data="matterData" style="width: 100%" row-key="id" size="mini" class="data-table" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :show-overflow-tooltip="true" :tree-props="{children:'childList'}">
        <el-table-column label="产品分类" min-width="400px">
          <template slot-scope="scope">
            <span class="showName">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column  align="center" label="操作" width="170px">
          <template slot-scope="scope">
            <span class="addBtn" v-if="scope.row.parentId == 0 && btnLimit.includes('新增产品分类')" @click="addChildrenCategory(scope.row.id)"><i style="margin-right: 3px;" class="el-icon-plus"></i>新增</span>
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="btnLimit.includes('编辑产品分类')" command="1">
                  <i class="el-icon-edit-outline"></i><span>修改</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="btnLimit.includes('删除产品分类')" command="2">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加商品分类弹出框 -->
    <template>
      <div>
        <el-dialog class="changeDialog" title="新增分类"  :visible.sync="dialogVisible" :close-on-click-modal="false" v-dialogDrag>
          <section>
            <!-- 新增分类表单 -->
            <el-form :model="branchForm" :rules="rules" ref="ruleForm">
              <label>
                <p><i style="color: red;">*</i>产品分类:</p>
                <el-form-item prop="title" class="shopFullName">
                  <el-input v-model.trim="branchForm.name" clearable autocomplete="off" placeholder="请输入产品分类名称"></el-input>
                </el-form-item>
              </label>
              <label>
                <p>上级分类:</p>
                <el-form-item>
                  <el-cascader
                    class="cascader_input"
                    @change="handlerValue"
                    ref="refHandle"
                    :options="matterDataBox"
                    :props="defaultData"
                    clearable
                    v-model="branchForm.parentId"
                    placeholder="请选择上级分类(没有则不选)"
                    filterable
                  ></el-cascader>
                </el-form-item>
              </label>
            </el-form>
          </section>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="dialogVisible = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="addNewCategory()">确定</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <!-- 修改商品分类弹出框 -->
    <template>
      <div>
        <el-dialog class="changeDialog" title="修改分类" :visible.sync="editDialog" :close-on-click-modal="false" v-dialogDrag>
          <section>
            <!-- 修改分类表单 -->
            <el-form :model="branchInfoForm" :rules="rules" ref="ruleForm">
              <label>
                <p><i style="color: red;">*</i>产品分类:</p>
                <el-form-item prop="title" class="shopFullName">
                  <el-input v-model.trim="branchInfoForm.name" clearable autocomplete="off" placeholder="请输入产品分类名称"></el-input>
                </el-form-item>
              </label>
              <label>
                <p>上级分类:</p>
                <el-form-item>
                  <el-cascader
                    class="cascader_input"
                    @change="handlerValue"
                    ref="refHandle"
                    :options="matterDataBox"
                    :props="defaultData"
                    clearable
                    v-model="branchInfoForm.parentId"
                    placeholder="请选择上级分类(没有则不选)"
                    filterable
                  ></el-cascader>
                </el-form-item>
              </label>
            </el-form>
          </section>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="editDialog = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="agreeEditCategory()">修改</el-button>
          </span>
        </el-dialog>
        <!-- 批量导入弹出框 -->
        <!-- <el-dialog
          class="dialogUpload"
          title="导入数据"
          :visible.sync="dialogUpload"
          :close-on-click-modal="false"
          v-dialogDrag
        >
          <el-table :data="uploadData" border max-height="420">
            <el-table-column show-overflow-tooltip property label="校验是否存在问题" width="140">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.errorMessage !== ''">{{scope.row.errorMessage}}</span>
                  <span v-else style="color: green">单行校验通过</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              property="name"
              label="分类名称"
              min-width="10%"
              align="center"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              property="num"
              label="分类编码"
              min-width="10%"
              align="center"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              property="parentName"
              label="上级名称"
              min-width="8%"
              align="center"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              property="parentNum"
              label="上级编码"
              min-width="8%"
              align="center"
            ></el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="matterCategoryUploadKeep()">确定导入</el-button>
            <el-button @click="cancelUpload()">取消导入</el-button>
          </div>
        </el-dialog> -->
      </div>
    </template>
  </div>
</template>
<script>
import {
  getMatterCategoryCodeApi,
  matterClassApi,
  addCategoryApi,
  delCategoryApi,
  editCategoryApi,
  matterCategoryUploadKeepApi
} from "@/api";
export default {
  name: "matterCategory",
  data() {
    return {
      isshow: true,
      tableData: [], // 这个是真正的展示树状列表
      dialogFlag: false,
      // 列表动态高度
      tableHeight: "",
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem("interAddrss"),
      // 搜索值
      search: "",
      // 物料分类数据
      matterData: [],
      // 物料分类弹框专用
      matterDataBox: [],
      // 控制商品分类弹出框
      dialogVisible: false,
      // 控制修改分类弹出框
      editDialog: false,
      // 添加分支表单
      branchForm: {
        parentId: 0,
        name: "",
        num: ""
      },
      // 修改分支表单
      branchInfoForm: {
        parentId: 0,
        name: "",
        num: ""
      },
      // 级联选择器分类
      defaultData: {
        value: "id",
        label: "name",
        children: "newChildList",
        expandTrigger: "hover",
        // 控制级联选择器只选则单个值
        emitPath: false,
        // 不关联父子节点
        checkStrictly: true
      },
      // 添加修改分类框校验
      rules: {},
      // 上传时的请求头
      uploadHeaders: {
        // token: window.sessionStorage.getItem('token')
        token: window.$cookies.get("token")
      },
      // 控制批量导入弹出框
      dialogUpload: false,
      // 展示导入内容数据
      uploadData: [],
      // 控制强制刷新dom
      refreshDom: false,
      // 按钮权限列表
      btnLimit: []
    };
  },
  created() {
    this.getMatterCategoryList();
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    // 动态获取表格高度
    const that = this;
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 300;
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight =  window.innerHeight - that.$refs.multipleTable.$el.offsetTop - 300;
      })();
    };
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.editCategory(result)
      } else {
        this.delCategory(result)
      }
    },
    // 实现级联选择器选中文字关闭级联选择框
    handlerValue() {
      if (this.$refs.refHandle) {
        this.$refs.refHandle.dropDownVisible = false;
      }
    },
    handlerValue2() {
      if (this.$refs.refHandle2) {
        this.$refs.refHandle2.dropDownVisible = false;
      }
    },
    // 获取物料分类数据
    async getMatterCategoryList() {
      const { data: res } = await matterClassApi(this.search);
      let newMatterData = [];
      this.matterData = res.data.typeList;
      // 物料分类专用
      this.matterDataBox = res.data.typeList;
      this.matterDataBox.forEach((item, index) => {
        if (item.childList.length !== 0) {
          item.newChildList = item.childList
          item.childList.forEach((item2, index) => {
            if (item2.childList.length !== 0) {
              item2.newChildList = item2.childList
              item2.childList.forEach((item2, index) => {
                if (item3.childList.length !== 0) {
                  item3.newChildList = item3.childList
                }
              })
            }
          })
        }
      })
    },
    // 搜索改变颜色
    changeColor() {
      // 刷新dom
      this.refreshDom = true;
      this.$nextTick(() => {
        this.refreshDom = false;
        setTimeout(() => {
          let items = document.getElementsByClassName("showName");
          let searchitem = this.search;
          for (var i = 0; i < items.length; i++) {
            var result = items[i].innerText;
            result = result.replace(
              new RegExp(searchitem, "g"),
              '<a style="background-color: #FF9632;">' + searchitem + "</a>"
            );
            // 控制如果不是操作列就不更新dom(防止按钮功能失效)( % 4是因为操作列在第四列)
            if ((i + 1) % 5 !== 0) {
              items[i].innerHTML = result;
            }
          }
        });
      });
    },
    // 打开新增物料弹框
    async addCategory() {
      // 自动获取默认物料编码
      const { data: res } = await getMatterCategoryCodeApi();
      this.branchForm.num = res.data.data;
      this.dialogVisible = true;
    },
    // 添加新的商品分类
    async addNewCategory() {
      if (this.branchForm.name === "") return this.$message.error("分类名称不能为空!");
      if (this.branchForm.parentId === null) {
        this.branchForm.parentId = 0
      }
      const { data: res } = await addCategoryApi(this.branchForm);
      if (res.code !== 0) return this.$message.error(res.msg);
      this.$message({
        type: "success",
        duration: 1000,
        message: "添加成功!"
      });
      this.branchForm = {
        parentId: 0,
        name: "",
        num: ""
      };
      this.dialogVisible = false;
      this.getMatterCategoryList();
    },
    // 删除分类弹框
    delCategory(item) {
      if (item.childList.length != []) {
        this.$confirm(
          "当前分类下存在二级分类，请先删除二级分类。",
          "删除确认",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        );
      } else {
        this.$confirm("确定删除该分类吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false
        }).then(async () => {
          const { data: res } = await delCategoryApi(item.id);
          if (res.code !== 0) return this.$message.error(res.msg);
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.branchInfoForm = {
            parentId: 0,
            name: "",
            num: "",
            remark: ""
          };
          this.getMatterCategoryList();
        });
      }
    },
    // 修改分类弹框数据渲染
    editCategory(res) {
      this.branchInfoForm = JSON.parse(JSON.stringify(res))
      this.editDialog = true;
    },
    // 实现修改分类
    async agreeEditCategory() {
      const { data: res } = await editCategoryApi(this.branchInfoForm);
      if (res.code !== 0) return this.$message.error(res.msg);
      this.$message({
        type: "success",
        duration: 1000,
        message: "修改分类成功!"
      });
      this.editDialog = false;
      this.getMatterCategoryList();
    },
    // 新增子分类
    addChildrenCategory(id) {
      this.branchForm.parentId = id;
      this.dialogVisible = true;
    },
    // 批量导入成功触发
    uploadSuccess(res, file) {
      this.dialogUpload = true;
      this.uploadData = res.data.list;
      this.$refs.purchaseUpload.clearFiles();
    },
    // 批量导入失败触发
    uploadError(err) {
      this.$message.error(err);
    },
    // 导入文件格式校验
    handleExceed() {
      this.$message.error("只能导入单个文件!");
    },
    // 取消导入
    cancelUpload() {
      this.$refs.purchaseUpload.clearFiles();
      this.dialogUpload = false;
    },
    // 导入保存
    async matterCategoryUploadKeep() {
      if (this.uploadData.length === 0)
        return this.$message.error("请添加数据后再保存!");
      let controlImp = true;
      this.uploadData.forEach(item => {
        if (item.success === false) {
          controlImp = false;
          return false;
        }
      });
      if (controlImp === false)
        return this.$message.error("校验未通过,请修正后重试!");
      const { data: res } = await matterCategoryUploadKeepApi(this.uploadData);
      if (res.code !== 0) return this.$message.error(res.msg);
      this.$message.success({ duration: 1000, message: "导入保存成功!" });
      this.dialogUpload = false;
      this.getMatterCategoryList();
    },
    getNewData() {
      //   this.$set(this.sourceData[0], "childNode", []);
      this.initData();
    },
    updateTableTree(parentId, nodes) {
      // 更新需要先更新上级节点
      this.$set(
        this.$refs.cimsDictTable.store.states.lazyTreeNodeMap,
        parentId,
        nodes
      );
    },
    refTable() {
      let _this = this;
      function dg(data) {
        for (let i in data) {
          if (data[i].childNode) {
            _this.updateTableTree(data[i].id, data[i].childNode);
            dg(data[i].childNode);
          }
        }
      }
      dg(this.tableData);
    },
    proTableData(data) {
      let _this = this;
      // 处理数据
      function dg(data) {
        for (let i in data) {
          _this.$set(data[i], "edit", false);
          if (data[i].childNode) {
            // 重要:树状节点数据刷新
            _this.updateTableTree(data[i].id, data[i].childNode);
            dg(data[i].childNode);
          }
        }
      }
      dg(data);
      // 给数据判断添加父子节点标识
      function setFarSon(data) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          if (element.parentId == undefined || element.parentId == null) {
            element["colorType"] = "1";
            setFarSon(element.childNode);
          } else {
            if (element.childList == [] || element.childList == []) {
              element["colorType"] = "2";
              setFarSon(element.childNode);
            } else {
              element["colorType"] = "2";
            }
          }
        }
      }
      setFarSon(data);
    },
    initData() {
      // 数据加载   模仿数据请求
      let res = JSON.parse(JSON.stringify(this.sourceData));
      // 数据处理 添加编辑标识
      this.proTableData(res);
      this.tableData = res;
    }
  }
};
</script>
<style lang="less" scoped>
.matterCategory {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  >section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
  }
  .matterCategoryContent {
    width: calc(100vw - 260px);
    height: calc(100vh - 260px);
    margin-bottom: 20px;
    padding: 0 20px;
    overflow-y: auto;
    .addBtn {
      position: absolute;
      left: 0;
      cursor: pointer;
    }
    .data-table {
      // 固定表格高度
      /deep/td {
        padding-left: 20px!important;
        height: 48px;
        padding: 0;
      }
      /deep/th {
        padding-left: 20px!important;
      }
    }
  }
  /deep/.el-icon-more::before {
    display: block;
    font-size: 16px;
    transform: rotate(90deg) !important;
  }
}
/deep/.data-table-header {
  height: 60px !important;
}
/*1.取消原本展开的旋转动效*/
/deep/.el-table__expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
/*2.展开按钮未点击的样式是加号带边框*/
/deep/.el-table__expand-icon .el-icon-arrow-right:before {
  content: "\e6d9";
  border: 1px solid #ccc;
  padding: 1px;
}
/*2.按钮已点击展开之后的样式是减号带边框*/
/deep/.el-table__expand-icon--expanded .el-icon-arrow-right:before {
  content: "\e6d8";
}
/deep/.el-dialog {
  height: 350px;
}
.dialog-footer {
  .el-button:nth-child(2) {
    background: #123e83;
    border-color: #123e83;
  }
}
.changeDialog {
  /deep/ .el-dialog {
    height: 308px;
    width: 424px;
    .el-dialog__body {
      height: 188px;
      .el-input {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
  }
}
</style>
