// 收款单列表
<template>
  <div class="balanceAccount">
    <nav>
      <h5>收款单</h5>
      <div>
        <el-button class="searchStyle" @click="purchaseReturnExport()"><i class="iconfont icon-upload1"></i>导 出</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="balanceAccountData.number" placeholder="请输入收款单编号" @input="getBalanceAccountList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="balanceAccountData.cgCompanyName" placeholder="请输入客户名称名称" @input="getBalanceAccountList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="创建日期区间"
          end-placeholder="创建日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select v-model="balanceAccountData.auditStatus" placeholder="请选择确认状态" clearable size="mini" @change="getBalanceAccountList()">
        <el-option label="已确认" :value="1"></el-option>
        <el-option label="未确认" :value="0"></el-option>
        <el-option label="已取消" :value="2"></el-option>
      </el-select>
    </section>
    <main>
      <el-table @select="handleSelectionChange" @select-all="handleSelectionChange" ref="tableHeight" :height="tableHeight" :data="balanceAccountList" style="width: 100%;">
        <el-table-column type="selection" width="45" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="number" label="收款单编号" min-width="100px" align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.number}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="cgCompanyName" label="客户名称" min-width="130px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="amountTax" label="总金额(含税)" min-width="100px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="amount" label="总金额(不含税)" min-width="100px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="120px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="auditStatus" label="确认状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.auditStatus === 1" >已确认</span>
              <span v-else-if="scope.row.auditStatus === 0" >未确认</span>
              <span v-else-if="scope.row.auditStatus === 2" >已取消</span>
              <span v-else-if="scope.row.auditStatus === null">暂无</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column show-overflow-tooltip prop="status" label="收款状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.status === 1" class="statusBox1">已收款</span>
              <span v-else-if="scope.row.status === 0" class="statusBox2">待收款</span>
              <span v-else class="statusBox3">-</span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column show-overflow-tooltip prop="fileStatus" label="发票上传" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.fileStatus == 0" style="color: rgba(242, 90, 90, 1)">未上传</span>
              <span v-else-if="scope.row.fileStatus == 1" style="color: rgba(70, 147, 235, 1)">已上传</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="7">
                  <i class="el-icon-warning-outline"></i><span>查看</span>
                </el-dropdown-item>
                <el-dropdown-item command="1" v-if="(scope.row.auditStatus ===0 || scope.row.auditStatus===null) && btnLimit.includes('确认取消')">
                  <i class="el-icon-edit-outline"></i><span>确认</span>
                </el-dropdown-item>
                <el-dropdown-item command="2" v-if="(scope.row.auditStatus ===0 || scope.row.auditStatus===null) && btnLimit.includes('确认取消')">
                  <i class="el-icon-refresh-left"></i><span>取消</span>
                </el-dropdown-item>
                <el-dropdown-item command="3"  v-if="scope.row.auditStatus ===1 && btnLimit.includes('撤回')">
                  <i class="el-icon-warning-outline"></i><span>撤回</span>
                </el-dropdown-item>
                <el-dropdown-item command="4" v-if="scope.row.auditStatus ===2 && btnLimit.includes('删除')">
                  <i class="el-icon-warning-outline"></i><span>删除</span>
                </el-dropdown-item>
                <el-dropdown-item command="5" v-if="scope.row.fileStatus == 1">
                  <i class="el-icon-warning-outline"></i><span>查看发票</span>
                </el-dropdown-item>
                <el-dropdown-item command="6" v-if="scope.row.fileStatus == 0 && scope.row.auditStatus !== 2">
                  <i class="el-icon-warning-outline"></i><span>上传发票</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="balanceAccountData.size"
        :total="total">
      </el-pagination>
    </main>
    <!-- 上传附件 -->
    <el-dialog class="uploadDialog" title="上传附件" :visible.sync="uploadDialog" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <el-upload
          class="upload-demo"
          drag
          :action="interAddrss + 'sys/common/upload'"
          :data="{uploadType: 'NEW_ENQUIRY'}"
          :headers="headers"
          :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :file-list="fileList"
          :on-preview="clickFile"
          :limit="10">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="uploadDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import Utils from '../../util.js'
import { CollectionManagementListApi, receiptCancelApi, CollectionManagementComfireApi, receiptDeleteApi, receiptRevocationApi, skExportApi, CollectionUpdateFileApi } from '@/api'
export default {
  name: 'balanceAccount',
  data() {
    return {
      // 选择的收款单id
      chooseCollectionId: '',
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem('interAddrss'),
      headers: {
        'token': window.$cookies.get('token')
      },
      // 附件列表
      fileList: [],
      // 上传附件
      uploadDialog: false,
      // 列表动态高度
      tableHeight: '',
      // 收款单列表
      balanceAccountList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      balanceAccountData: {
        status: '',
        // 当前页
        current: 1,
        // 当前页条数
        size: 10,
        number: '',
        cgCompanyName: '',
        createTimeStart: '',
        createTimeEnd: '',
        auditStatus: ''
      },
      chooseTime: '',
      // 选择的收款单
      chooseBalance: [],
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getBalanceAccountList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 点击文件
    clickFile(e) {
      console.log(e, '999999')
      window.open(e.name)
    },
    // 移除上传文件
    async handleRemove(file, fileList) {
      console.log(file, fileList, '69849846')
      file.name
      this.fileList.forEach((item, index) => {
        if (item.name === file.name) {
          this.fileList.splice(index, 1)
          return false
        }
      })
      let newFileList = []
      this.fileList.forEach((item, index) => {
        newFileList.push(item.url)
      })
      // 收货单保存上传的发票
      const { data: res } = await CollectionUpdateFileApi({ id: this.chooseCollectionId, url: newFileList.join(',') })
      if (res.code !== 0) return this.$message.error(res.msg)        
      this.$message.success('移除成功!')
      this.getBalanceAccountList()
    },
    // 上传成功回调
    async handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        this.fileList.push({ name: file.response.data.url, url: file.response.data.url })
        let newFileList = []
        this.fileList.forEach((item, index) => {
          newFileList.push(item.url)
        })
        // 收货单保存上传的发票
        const { data: res } = await CollectionUpdateFileApi({ id: this.chooseCollectionId, url: newFileList.join(',') })
        if (res.code !== 0) return this.$message.error(res.msg)        
        this.$message.success('上传成功!')
        this.getBalanceAccountList()
      } else {
        this.$message.error('上传失败')
      }
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过5MB!')
      }
      return isLt2M
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.createPay(result.id)
      } else if (command === '2') {
        this.delBalanceAccount(result.id)
      } else if (command === '3') {
        this.BalanceAccountWithdraw(result.id)
      } else if (command === '4') {
        this.BalanceAccountdelete(result.id)
      } else if (command === '5') {
        // 查看附件
        this.uploadDialog = true
        this.chooseCollectionId = result.id
        this.fileList = []
        let newFileList = (result.fileUrl).split(',')
        if (newFileList.length > 0) {
          newFileList.forEach((item, index) => {
            this.fileList.push({ name: item, url: item })
          })
        }
      } else if (command === '6') {
        // 上传附件
        this.uploadDialog = true
        this.chooseCollectionId = result.id
        this.fileList = []
        let newFileList = (result.fileUrl).split(',')
        if (newFileList.length > 0) {
          newFileList.forEach((item, index) => {
            this.fileList.push({ name: item, url: item })
          })
        }
      } else if (command === '7') {
        this.routerJump(2, result.id)
      }
    },
    // 确认
    async createPay(id) {
      // if (this.chooseBalance.length === 0) return this.$message.error('请先勾选收款单!')
      const { data: res } = await CollectionManagementComfireApi({ id: id })
      if (res.code !== 0) return this.$message.error('只能勾选未付款的收款单且供应商必须唯一!')
      this.$message.success('操作成功!')
      this.getBalanceAccountList()
    },
    // 手动勾选的参数(收款单专用)
    handleSelectionChange(selection) {
      let arr = []
      selection.forEach(item => {
        arr.push({ id: item.id, sellerId: item.sellerId })
      })
      this.chooseBalance = arr
    },
    // 取消收款单
    delBalanceAccount(id) {
      this.$confirm('是否取消收款单', '取消', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await receiptRevocationApi({ id: id })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('取消成功!')
          this.getBalanceAccountList()
        })
    },
    // 删除收款单
    BalanceAccountdelete(id) {
      this.$confirm('是否删除该收款单？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await receiptDeleteApi({ id: id })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('删除成功!')
          this.getBalanceAccountList()
        })
    },
    // 撤回收款单
    BalanceAccountWithdraw(id) {
      this.$confirm('是否撤回收款单？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await receiptCancelApi({ id: id })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('撤回成功!')
          this.getBalanceAccountList()
        })
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: '/addBalanceAccount' })
      } else if (val === 2) {
        this.$router.push({ path: '/CollectionManagementDetail', query: { id: JSON.stringify(result) } })
      }
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.balanceAccountData.createTimeStart = ''
        this.balanceAccountData.createTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.balanceAccountData.createTimeStart = getYear + '-' + nowMonth + '-' + getDate
            this.balanceAccountData.createTimeEnd = getYear + '-' + nowMonth + '-' + getDate
          }
        })
      }
      this.getBalanceAccountList()
    },
    // 获取收款单列表
    async getBalanceAccountList(reset) {
      if (reset !== 'reset') {
        this.balanceAccountData.page = 1
      }
      const { data: res } = await CollectionManagementListApi(this.balanceAccountData)
      // 获取表单数据
      this.balanceAccountList = res.data.list
      // 展示当前页面
      this.currentPage = res.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.totalPage
      // 展示总条数
      this.total = res.data.totalCount
    },
    // -----------------------------------------------------------------

    // 触发每页显示条数
    handlesizeChange(val) {
      this.balanceAccountData.size = val
      this.balanceAccountData.page = 1
      this.getBalanceAccountList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.balanceAccountData.page = val
      this.currentPage = val
      this.getBalanceAccountList('reset')
    },
    async purchaseReturnExport() {
      const { data: res } = await skExportApi(this.balanceAccountData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getPurchaseReturnList()
    }
  }
}
</script>
<style scoped lang='less'>
.balanceAccount {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      .statusBox1, .statusBox2, .statusBox3{
        padding: 5px 8px;
        width: 52px;
        height: 22px;
        border-radius: 4px;
        background: rgba(70,147,235,0.05);
        border: 1px solid rgba(70,147,235,0.2);
        font-size: 12px;
        color: rgba(70, 147, 235, 1);
      }
      .statusBox2 {
        border: 1px solid rgba(59, 212, 144, 0.20);
        background: rgba(59, 212, 144, 0.05);
        color: rgba(59, 212, 144, 1);
      }
      .statusBox3 {
        background: rgba(242,90,90,0.05);
        border: 1px solid rgba(242,90,90,0.2);
        color: rgba(242, 90, 90, 1);
      }
    }
  }
  /deep/ .uploadDialog {
    .el-dialog {
      width: 400px;
      height: 450px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        padding-bottom: 0;
        height: 340px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
