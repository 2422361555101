// 新增发货单
<template>
  <div class="addDispatchList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dispatchList' }">发货单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">添加发货明细</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addPurchasePlanContent">
      <el-form ref="ruleForm" :model="addData" label-width="117px" :rules="rules">
        <nav>
          <h5>新增发货单</h5>
          <div class="navBox">
            <el-form-item label="客户名称:" prop="companyId">
              <el-select  @change='companyFn()' v-model="addData.companyId" placeholder="客户名称选择" clearable size="mini">
                <el-option
                    v-for="item in userManageList"
                    :key="item.companyId"
                    :label="item.companyName"
                    :value="item.companyId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发货日期:" prop="deliveryTime">
              <el-date-picker :picker-options="pickerOptions" v-model="addData.deliveryTime" type="date" placeholder="申请日期选择" value-format="yyyy-MM-dd" :clearable="false"></el-date-picker>
            </el-form-item>
          </div>
        </nav>
        <main>
          <h5>订单明细</h5>
          <el-tooltip class="item" effect="dark" content="请选择客户" placement="top">
            <el-button  class="lightDeepStyle addBtn" @click="openAddDialog()"><i class="el-icon-plus"></i>添加发货明细</el-button>
          </el-tooltip>
          <el-button class="defaultStyle delBtn" @click="delMatter('all')">删除</el-button>
          <ul v-if="addData.lineEntityList.length !== 0">
            <el-checkbox-group v-model="chooseList">
              <li v-for="(item, index) in addData.lineEntityList" :key="index">
                <div class="liUp">
                  <p>
                    <el-checkbox :label="index"><span>{{item.code + '-' + item.productName + '-' + (item.unitName === null ? '暂无' : item.unitName) + '-' + (item.productSpecs === "" ? '暂无' : item.productSpecs)+ (item.productNewColour === "" ? '暂无' : item.productNewColour)+'-'+ (item.productTypeName === "" ? '暂无' : item.productTypeName) }}</span></el-checkbox>
                  </p>
                  <p>
                    <span>订单数量：{{ item.residueNumber }}</span>
                    <span>已发/未发货：{{ item.deliveryNumber }}/{{ (item.residueNumber-item.deliveryNumber-item.residueLockDelivery).toFixed(2) }}</span>
                    <span>退货：{{ item.returnNumber }}</span>
                    <span>送货方式：{{ item.deliveryType===1?'供方送货':'自提' }}</span>
                    <span>要求交货时间：{{item.arriveTime==='' || item.arriveTime===null?'暂无' : item.arriveTime.substring(0, 10) }}</span>
                    <i @click="delMatter(index)" class="el-icon-delete"></i>
                  </p>
                </div>
                <div class="liDown">
                  <el-form-item label="发货数量:" :prop="'lineEntityList.' + index + '.number'" :rules="rules.number">
                    <el-input type="number" @input="formatNum(item.number, index, item.residueNumber-item.deliveryNumber-item.residueLockDelivery)" oninput="if(value.length > 10) value=value.slice(0, 10)" v-model="item.number" placeholder="输入数值"></el-input>
                  </el-form-item>
                </div>
              </li>
            </el-checkbox-group>
          </ul>
          <div v-else class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:30%; margin-top: -2%;">
            <span style="display: block; margin-top: -5%">暂无数据</span>
          </div>
        </main>
      </el-form>
      <!-- 采购单弹框 -->
      <el-dialog class="chooseOrderDialog" title="添加明细" :visible.sync="chooseOrderDialog" :close-on-click-modal="false" v-dialogDrag>
        <div>
          <el-input class="sectionInp" v-model="addData.codeAndProductName" placeholder="产品编号/产品名称搜索" @input="getMatterManageList()" prefix-icon="el-icon-search"></el-input>
        </div>
        <el-table ref="matterSearchRef" :data="matterManageList" style="width: 100%" height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
        <el-table-column type="selection" width="45px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="code" label="销售单编号" min-width="110px" align="center">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productCode" label="产品编号" min-width="110px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
              <template slot-scope="scope">
                  <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
              </template>
            </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
                <span>{{scope.row.productNewColour === "" || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productTypeName" label="类型" min-width="80px" align="center">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="130px" align="center">
            <template slot-scope="scope">
                <span>{{scope.row.unitName === null ? '-' : scope.row.unitName}}</span>
            </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="arriveTime" label="要求交货时间" min-width="120px" align="center">
          <template slot-scope="scope">
                <span>{{scope.row.arriveTime === null ? '-' : scope.row.arriveTime.substring(0,10)}}</span>
            </template>
        </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button class="defaultStyle cancleBtn" @click="chooseOrderDialog = false">取 消</el-button>
          <el-button class="lightDeepStyle" @click="chooseMatter()">确 认</el-button>
        </div>
      </el-dialog>
    </div>
    <footer>
      <el-button class="lightDeepStyle" @click="addDispatchList(1)">提交并发货</el-button>
      <el-button class="lightDeepStyle" @click="addDispatchList()">提交</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import { deliverGoodsAffirmApi, ConfirmDeliveryApi, addSellerListApi, deliverGoodSellerApi, sellerListApi } from '@/api'
export default {
  data() {
    return {
      // 日期选择器限制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now()
        }
      },
      // 控制弹框
      chooseOrderDialog: false,
      // 新增参数
      addData: {
        page: 1,
        // 当前页条数
        size: 999,
        id: '',
        code: '',
        companyId: '',
        proposerId: JSON.parse(window.sessionStorage.userInfo).userId,
        deliveryTime: '',
        approveStatus: '',
        lineEntityList: [],
        // companyId: '',
        codeAndProductName: '',
        state: '',
        arriveTimeBegin: '',
        arriveTimeEnd: '',
        replenishment: '',
        residueNumber: ''
      },
      // 申请人列表传参
      userManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 999,
        // 搜索内容
        search: '',
        roleType: 2,
        status: ''

      },
      // 申请人列表
      userManageList: [],
      // 选中的项
      chooseList: [],
      // 产品列表参数
      matterManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        code: '',
        companyId: '',
        codeAndProductName: '',
        state: '',
        arriveTimeBegin: '',
        arriveTimeEnd: ''
      },
      // 产品列表
      matterManageList: [],
      // 选中的物料
      matterList: [],
      // 校验
      rules: {
        companyId: [
          { required: true, message: '客户名称不能为空!', trigger: 'blur' }
        ],
        proposerId: [
          { required: true, message: '申请人不能为空!', trigger: 'blur' }
        ],
        deliveryTime: [
          { required: true, message: '发货日期不能为空!', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '需求量不能为空!', trigger: 'blur' }
        ],
        arriveTime: [
          { required: true, message: '期望发货日期不能为空!', trigger: 'blur' }
        ]
      },
      // 供应商列表
      sellList: []
    }
  },
  created() {
    // this.addData.lineEntityList = []
    this.addData.state = [parseInt(this.$route.query.id), parseInt(this.$route.query.ids)]
    this.getUserManage()
    this.sellerList()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    companyFn() {
      if (this.addData.lineEntityList.length !== 0) {
        this.$confirm('变更供应商将清空已添加的发货明细?', '变更确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          // 清空选中项
          this.addData.lineEntityList = []
        })
      }
    },
    // 申请人列表
    async getUserManage() {
      const { data: res } = await deliverGoodSellerApi(this.userManageData)
      this.userManageList = res.data.page.list
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await sellerListApi({ search: '', page: 1, size: 9999, roleType: 2, status: '' })
      this.sellList = res.data.page.list
    },
    // 打开添加弹框
    openAddDialog() {
      this.getMatterManageList()
      this.chooseOrderDialog = true
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    },
    // 获取产品列表
    async getMatterManageList() {
      const { data: res } = await addSellerListApi(this.addData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.matterManageList = res.data.data.list
      // // 去除列表重复项
      if (this.matterManageList.legnth !== 0) {
        this.matterManageList.forEach((item, index) => {
          if (item.deliveryNumber === item.residueNumber) {
            this.matterManageList.splice(index, 1)
          }
        })
        // console.log(this.matterManageList)
        this.addData.lineEntityList.forEach(item => {
          this.matterManageList.forEach((val, index) => {
            if (item.orderLineId === val.id) {
              this.matterManageList.splice(index, 1)
            }
          })
        })
      }
    },
    // 物料选择
    chooseMatter: debounce(function() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      this.matterList.forEach((item, index) => {
        let newData = {
          indexs: index,
          code: item.code,
          orderId: item.orderId,
          orderLineId: item.id,
          productName: item.productName,
          productNewColour: item.productNewColour,
          unitName: item.unitName,
          productSpecs: item.productSpecs,
          residueLockDelivery: item.residueLockDelivery,
          number: '',
          sellId: '',
          // 剩余发货数
          residueNumber: item.residueNumber,
          // 已发货数量
          deliveryNumber: item.deliveryNumber,
          // 退货数量
          returnNumber: item.returnNumber,
          arriveTime: item.arriveTime,
          productTypeName: item.productTypeName,
          residueNumberHistory: null,
          // 不合格数量
          disqualificationNumber: item.disqualificationNumber
        }
        this.addData.lineEntityList.push(newData)
      })
      this.chooseOrderDialog = false
    }, 200),
    // 删除产品
    delMatter(ind) {
      if (ind === 'all') {
        if (this.chooseList.length === 0) return this.$message.error('请先勾选需要删除的产品')
        this.$confirm('是否删除勾选产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          console.log(this.addData.lineEntityList)
          this.chooseList.forEach((item, i) => {
            this.addData.lineEntityList.forEach((val, index) => {
              if (i === val.indexs) {
                this.addData.lineEntityList.splice(index, 1)
              }
            })
          })
          // 清空选中项
          this.chooseList = []
        })
      } else {
        this.$confirm('是否确认删除当前产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.addData.lineEntityList.splice(ind, 1)
        })
      }
    },
    formatNum(val, index, max) {
      let temp = val.toString()
      temp = temp.replace(/。/g, '.')
      temp = temp.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, '') // 验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, '') // 只保留第一个, 清除多余的
      temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // eslint-disable-next-line no-useless-escape
      temp = temp.replace(/^(\-)*(\d+)\.(\d{1,2}).*$/, '$1$2.$3') // 只能输入四个小数
      // 判断计划数不得大于最大可计划数
      if (temp > max) {
        temp = max
      }
      this.addData.lineEntityList[index].number = temp
    },
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 新增计划单
    addDispatchList: debounce(function(val) {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          // eslint-disable-next-line eqeqeq
          if (this.addData.lineEntityList.length == 0) return this.$message.error('清单明细不能为空!')
          this.addData.deliveryTime = this.addData.deliveryTime + ' ' + new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0') + ':' + new Date().getSeconds().toString().padStart(2, '0')
          this.addData.state = ''
          this.addData.lineEntityList.forEach((item, index) => {
            item.residueNumberHistory = item.residueNumber - parseInt(item.number) - item.residueLockDelivery
          })
          const { data: res } = await deliverGoodsAffirmApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          // 判断是否直接提交
          if (val === 1) {
            this.submitPurchasePlan(res.data.data)
          } else {
            this.$message.success('新增发货单成功!')
            setTimeout(() => {
              this.$router.back()
            }, 300)
          }
        } else {
          return false
        }
      })
    }, 200),
    // 提交计划单
    // 提交并发货单
    submitPurchasePlan: debounce(function(data) {
      try {
        data.forEach(async (item, index) => {
          await ConfirmDeliveryApi(item.id)
        })
        this.$message.success('新增并提交发货单成功!')
        setTimeout(() => {
          this.$router.back()
        }, 300)
      } catch {
        this.$message.error('保存提交失败!')
      }
    }, 200),

    // -----------------------------------------------
    // 清空列表数据
    clearValue() {
      if (this.addData.lineEntityList.length !== 0) {
        this.$confirm('变更供应商将清空已添加的计划明细?', '变更确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          // 清空选中项
          this.addData.lineEntityList = []
        })
      }
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      // console.log(selection)
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    }
  }
}

</script>
<style scoped lang='less'>
.addDispatchList {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addPurchasePlanContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 180px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 2px;
      padding: 20px;
      height: 166px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .navBox {
        // display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
        //   width: 33.3%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 117px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
          }
        }
      }
    }
    main {
      position: relative;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              color: rgba(0, 0, 0, 0.60);
            }
            >p {
              height: 24px;
              // width: 100%;
              font-size: 14px;
              >span {
                // margin-left: 30%;
                display: inline-block;
                // width: 220px;
                color: rgba(0, 0, 0, 0.60);
              }

            }
            p:nth-child(2){
              float: right;
              span{
                display: inline-block;
                margin-right: 15px;
              }
              i {
                // float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 100%;
              .el-form-item__content {
                width: calc(100% - 117px);
                .el-input {
                  width: 100%;
                }
                .el-select {
                  width: 100%;
                  /deep/ .el-input {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
