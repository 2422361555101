// 线下销售单列表
<template>
  <div class="purchaseOrder">
    <nav>
      <h5>线下销售单</h5>
      <div>
        <el-button class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增线下销售单</el-button>
        <!-- <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button> -->
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="purchaseOrderData.orderNo" placeholder="线下销售单编号搜索" @input="getpurchaseOrderList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="purchaseOrderData.productName" placeholder="产品名称搜索" @input="getpurchaseOrderList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="创建时间区间"
          end-placeholder="创建时间区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <!-- <el-select v-if="active === 1" @change="getpurchaseOrderList()" v-model="purchaseOrderData.state" placeholder="订单状态筛选" size="mini" filterable clearable>
        <el-option label="待确认" :value="7"></el-option>
        <el-option label="待发货" :value="0"></el-option>
        <el-option label="部分发货" :value="8"></el-option>
        <el-option label="待收货" :value="9"></el-option>
        <el-option label="部分收货" :value="10"></el-option>
        <el-option label="已完结" :value="'11,3,1'"></el-option>
        <el-option label="已对账" :value="2"></el-option>
      </el-select> -->
      <el-select v-if="active === 3" @change="getpurchaseOrderList()" v-model="purchaseOrderData.approveStatus" placeholder="审核状态筛选" size="mini" filterable>
        <el-option label="进行中" :value="1"></el-option>
        <el-option label="驳回" :value="3"></el-option>
      </el-select>
    </section>
    <!-- <article>
      <div @click="changeActive(1)" style="border-top-left-radius: 4px; border-bottom-left-radius: 4px;" :class="active === 1 ? 'changeBox highlight' : 'changeBox'">审核完成</div>
      <div @click="changeActive(2)" :class="active === 2 ? 'changeBox highlight' : 'changeBox'">待提交</div>
      <div @click="changeActive(3)" :class="active === 3 ? 'changeBox highlight' : 'changeBox'">审核中</div>
      <div @click="changeActive(4)" style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;" :class="active === 4 ? 'changeBox highlight' : 'changeBox'">作废</div>
    </article> -->
    <main>
      <el-table ref="tableHeight" row-key="index" :height="tableHeight" :data="purchaseOrderList" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'allProductLine'}" :show-overflow-tooltip="true">
        <el-table-column show-overflow-tooltip prop="code" label="线下销售单编码" min-width="140px" align="center" fixed="left">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.code}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="orderType" label="订单类型" min-width="120px" align="center" fixed="left">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.orderType === 0">原材料采购</span>
              <span v-if="scope.row.orderType === 1">外协生产订单</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="sellerName" label="线下供应商名称" min-width="140px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip type="index" label="需求行号" width="80px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="100px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.allProductLine">-</span>
            <span v-else>{{scope.row.price}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="num" label="本次订购数量" min-width="140px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.allProductLine">-</span>
            <span v-else>{{scope.row.num}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="deliveryType" label="送货方式" min-width="100px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.allProductLine">{{scope.row.deliveryType === 0 ? '自提' : '供方送货'}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createByName" label="创建人" min-width="120px"  align="center">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="100px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.createTime">{{(scope.row.createTime).substring(0, 16)}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-dropdown v-if="scope.row.allProductLine" @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <!-- 审批状态 0 制单 1进行中 2 审批完成 3驳回 4 手动结束 -->
                <el-dropdown-item command="1">
                  <i class="el-icon-edit-outline"></i><span>编辑</span>
                </el-dropdown-item>
                <el-dropdown-item command="3">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="purchaseOrderData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import { offinePuichaseApi, offinePuichaseDelApi, purchaseOrderEndApi, overPurchaseOrderApi, submitPurchaseOrderApi, cgdExportApi } from '@/api'
export default {
  name: 'purchaseOrder',
  data() {
    return {
      // 选中的标签
      active: 1,
      // 列表动态高度
      tableHeight: '',
      // 线下销售单列表
      purchaseOrderList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      purchaseOrderData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        orderNo: '',
        productName: '',
        startTime: '',
        endTime: '',
        status: 1
      },
      chooseTime: ''
    }
  },
  created() {
  },
  mounted() {
    if (this.$route.query.ifAwait !== undefined) {
      this.purchaseOrderData.ifAwait = this.$route.query.ifAwait
    } else {
      this.purchaseOrderData.ifAwait = ''
    }
    if (this.$route.query.state !== undefined) {
      this.purchaseOrderData.state = this.$route.query.state - 0
    }
    if (this.$route.query.indexData && this.$route.query.indexData == 'today') {
      this.purchaseOrderData.startTime = new Date().toISOString().split('T')[0] + ' 00:00:00'
      this.purchaseOrderData.endTime = new Date().toISOString().split('T')[0] + ' 23:59:59'
      this.chooseTime = [new Date().toISOString().split('T')[0] + ' 00:00:00', new Date().toISOString().split('T')[0] + ' 23:59:59']
    }
    this.getpurchaseOrderList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 完结
    overPurchaseOrder(id) {
      this.$confirm('是否确认完结该线下销售单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await overPurchaseOrderApi({ id: id, state: 11, stateType: 1 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('完结成功!')
        this.getpurchaseOrderList()
      })
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routerJump(3, result.id)
      } else if (command === '2') {
        this.routerJump(3, result.id)
      } else if (command === '3') {
        this.delPurchaseOrder(result.id)
      } else if (command === '4') {
        this.endPurchaseOrder(result.id)
      } else if (command === '5') {
        this.overPurchaseOrder(result.id)
      } else {
        this.$confirm('是否确认提交该线下销售单', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(async () => {
          this.submitPurchaseOrder(result.id)
        })
      }
    },
    // 提交计划单
    async submitPurchaseOrder(id) {
      const { data: res } = await submitPurchaseOrderApi({ id: id, enquiryId: '' })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('提交成功!')
      this.getpurchaseOrderList()
    },
    // 切换标签页
    changeActive(val) {
      this.active = val
      if (val === 1) {
        // 审核完成的状态(在审核完成时搜索状态改state  0待发货 1已入库 2已对账 3部分入库 7待确认 8 部分发货 9待收货 10部分收货 11已收货)
        this.purchaseOrderData.state = ''
        this.purchaseOrderData.approveStatus = 2
      } else if (val === 2) {
        // 待提交的状态
        this.purchaseOrderData.state = 4
        this.purchaseOrderData.approveStatus = ''
      } else if (val === 3) {
        // 审核中的状态(在审核中时搜索状态改approveStatus 1进行中 3驳回)
        this.purchaseOrderData.state = 5
        this.purchaseOrderData.approveStatus = ''
      } else if (val === 4) {
        // 作废的状态
        this.purchaseOrderData.state = 6
        this.purchaseOrderData.approveStatus = ''
      }
      this.getpurchaseOrderList()
      // 解决样式错乱的问题
      this.$nextTick(() => {
        this.$refs.tableHeight.doLayout()
      })
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.purchaseOrderData.startTime = ''
        this.purchaseOrderData.endTime = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.purchaseOrderData.startTime = getYear + '-' + nowMonth + '-' + getDate
          } else {
            this.purchaseOrderData.endTime = getYear + '-' + nowMonth + '-' + getDate
          }
        })
      }
      this.getpurchaseOrderList()
    },
    // 获取线下销售单列表
    async getpurchaseOrderList(reset) {
      if (reset !== 'reset') {
        this.purchaseOrderData.page = 1
      }
      let newPurchaseOrderData = JSON.parse(JSON.stringify(this.purchaseOrderData))
      if (newPurchaseOrderData.state === '') {
        newPurchaseOrderData.state = '7,0,8,9,10,11,3,1,2'
      }
      const { data: res } = await offinePuichaseApi(newPurchaseOrderData)
      let newPurchaseOrderList = res.data.list
      // 展示当前页面
      this.currentPage = res.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.totalPage
      // 展示总条数
      this.total = res.data.totalCount
      // 给数据添加唯一值index,用于展开
      if (newPurchaseOrderList.length !== 0) {
        newPurchaseOrderList.forEach((item, index) => {
          item.index = index + ''
          if (item.allProductLine.length !== 0) {
            item.allProductLine.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
      this.purchaseOrderList = newPurchaseOrderList
      console.log(this.purchaseOrderList)
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.purchaseOrderData.size = val
      this.purchaseOrderData.page = 1
      this.getpurchaseOrderList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      console.log(val)
      this.purchaseOrderData.page = val
      this.currentPage = val
      this.getpurchaseOrderList('reset')
    },
    // -----------------------------------------------------------------
    // 删除
    delPurchaseOrder(id) {
      this.$confirm('是否确认删除该线下销售单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await offinePuichaseDelApi({ id: id })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getpurchaseOrderList()
      })
    },
    // 结束
    endPurchaseOrder(id) {
      this.$confirm('是否手动结束该线下销售单审批流', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await purchaseOrderEndApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('结束成功!')
        this.getpurchaseOrderList()
      })
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: '/onlineSellerAdd' })
      } else if (val === 2) {
        this.$router.push({ path: '/onlineSellerDetail', query: { result: JSON.stringify(result) } })
      } else {
        this.$router.push({ path: '/onlineSellerUpdate', query: { result: JSON.stringify(result) } })
      }
    },
    async purchaseReturnExport() {
      const { data: res } = await cgdExportApi(this.purchaseOrderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getpurchaseOrderList()
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseOrder {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  article {
    margin: 20px 20px 10px;
    display: flex;
    .changeBox {
      margin-left: -1px;
      height: 32px;
      width: 68px;
      border: 1px solid #CACACA;
      cursor: pointer;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.60);
    }
    .highlight {
      background-color: rgba(18, 62, 131, 1);
      border-color: rgba(18, 62, 131, 1);
      color: #fff;
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      .table-fixed {
        /deep/.el-table__fixed-right {
            height: 100% !important
        }
        /deep/.el-table__fixed {
            height:100% !important
        }
      }
    }
    .stateBox1, .stateBox2, .stateBox3 {
      display: block;
      width: 64px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
  }
}
</style>
