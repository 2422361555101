// 采购计划
<template>
  <div class="purchasePlan">
    <nav>
      <h5>采购计划单</h5>
      <div>
        <el-button v-if="btnLimit.includes('新增计划单')" class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增计划单</el-button>
        <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button>
        <el-button v-if="btnLimit.includes('转订单')" class="lightDeepStyle" @click="openTurnOrder()"><i class="iconfont icon-change"></i>转订单</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model="purchasePlanData.code" placeholder="采购计划单号搜索" @input="getpurchasePlanList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model="purchasePlanData.productName" placeholder="产品名称搜索" @input="getpurchasePlanList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="开始采购日期区间"
          end-placeholder="开始采购日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select @change="getpurchasePlanList()" v-model="purchasePlanData.approveStatus" placeholder="审核状态筛选" size="mini" filterable clearable>
        <el-option label="制单" :value="0"></el-option>
        <el-option label="待审核" :value="1"></el-option>
        <el-option label="进行中" :value="5"></el-option>
        <el-option label="审批完成" :value="2"></el-option>
        <el-option label="驳回" :value="3"></el-option>
        <el-option label="手动结束" :value="4"></el-option>
      </el-select>
    </section>
    <main>
      <el-table ref="tableHeight" row-key="index" :height="tableHeight" :data="purchasePlanList" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineEntityList'}" :show-overflow-tooltip="true">
        <el-table-column type="test" align="center" width="55" fixed="left">
          <template slot="header" slot-scope="scope">
            <el-checkbox v-model="chooseAllList" @change="handleChooseAllList"></el-checkbox>
          </template>
          <template slot-scope="scope">
            <el-checkbox v-if="typeof(scope.row.choose) === 'boolean'" v-model="scope.row.choose" @change="handleChoosePartList"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="code" label="计划单编码" min-width="120px" align="center" fixed="left">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.code}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="typeName" label="采购类型" min-width="80px" align="center" fixed="left"></el-table-column>
        <!-- <el-table-column show-overflow-tooltip type="index" label="需求行号" width="80px"  align="center"></el-table-column> -->
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="100px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.unitName">{{(scope.row.unitName).substring(0, 16)}}</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="number" label="需求数量" min-width="80px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="arriveTime" label="期望到货日期" min-width="100px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.arriveTime">{{(scope.row.arriveTime).substring(0, 10)}}</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="sellType" label="供应商类型" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.arriveTime"><i :class="scope.row.sellType === 1 ? 'sellTypeBox1' : (scope.row.sellType === 2 ? 'sellTypeBox2' : (scope.row.sellType === 3 ? 'sellTypeBox3' : (scope.row.sellType === 4 ? 'sellTypeBox4' : '')))">{{scope.row.sellTypeName}}</i></span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="sellName" label="期望供应商" min-width="140px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.sellName">{{(scope.row.sellName).substring(0, 16)}}</span>
            <span>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="residueNumber" label="剩余需求数量" min-width="100px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="申请日期" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.createTime">{{(scope.row.createTime).substring(0, 10)}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="buyTime" label="开始采购日期" min-width="110px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.buyTime">{{(scope.row.buyTime).substring(0, 10)}}</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <!-- 采购数量 (需求数量 - 剩余需求数量) / 需求数量 -->
        <el-table-column show-overflow-tooltip label="采购进度" min-width="160px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-progress color="#20D4CA" :width="24" :show-text="false" type="circle" :percentage="(scope.row.number - scope.row.residueNumber) / scope.row.number * 100"></el-progress>
            <span style="margin: 0 10px;">{{((scope.row.number - scope.row.residueNumber) / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
            <span>{{scope.row.number - scope.row.residueNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="proposerName" label="申请人" min-width="120px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="state" label="审核状态" min-width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <div>
              <span class="stateBox1" v-if="scope.row.approveStatus === 0">{{scope.row.approveStatusName}}</span>
              <span class="stateBox1" v-if="scope.row.approveStatus === 1">{{scope.row.approveStatusName}}</span>
              <span class="stateBox1" v-if="scope.row.approveStatus === 5">{{scope.row.approveStatusName}}</span>
              <span class="stateBox3" v-if="scope.row.approveStatus === 2">{{scope.row.approveStatusName}}</span>
              <span class="stateBox2" v-if="scope.row.approveStatus === 3">{{scope.row.approveStatusName}}</span>
              <span class="stateBox3" v-if="scope.row.approveStatus === 4">{{scope.row.approveStatusName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-dropdown v-if="scope.row.lineEntityList" @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <!-- 审批状态 0 制单 1待审核 2 审批完成 3驳回 4 手动结束 5进行中 -->
                <el-dropdown-item command="1" v-if="(scope.row.approveStatus === 0 || scope.row.approveStatus === 1 || scope.row.approveStatus === 2) && btnLimit.includes('编辑计划单')">
                  <i class="el-icon-edit-outline"></i><span>编辑</span>
                </el-dropdown-item>
                <el-dropdown-item command="2" v-if="scope.row.approveStatus === 3">
                  <i class="el-icon-edit-outline"></i><span>变更</span>
                </el-dropdown-item>
                <el-dropdown-item command="3" v-if="scope.row.approveStatus !== 1 && scope.row.approveStatus !== 5 && btnLimit.includes('删除计划单')">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
                <el-dropdown-item command="4" v-if="scope.row.approveStatus === 1 || scope.row.approveStatus === 5">
                  <i class="el-icon-switch-button"></i><span>结束</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="purchasePlanData.size"
        :total="total">
      </el-pagination>
    </main>
    <!-- 转订单弹框 -->
    <el-dialog class="chooseOrderDialog" title="选择计划单中的产品" :visible.sync="turnOrderDialog" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <el-input class="sectionInp" v-model="matterData.selectAll" placeholder="计划单编号/产品编号/产品名称搜索" @input="getPlanOrderList()" prefix-icon="el-icon-search"></el-input>
      </div>
      <el-table ref="matterSearchRef" :data="matterList" style="width: 100%" max-height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
        <el-table-column type="selection" width="45px" align="center" fixed="left"></el-table-column>
        <el-table-column show-overflow-tooltip prop="planCode" label="计划单编号" min-width="110px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productCode" label="产品编号" min-width="110px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="130px" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="turnOrderDialog = false">取 消</el-button>
        <el-button class="lightDeepStyle" @click="confirmChoose()">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { purchasePlanListApi, delPurchasePlanApi, purchasePlanEndApi, getPlanOrderListApi, cgjhExportApi } from '@/api'
export default {
  name: 'purchasePlan',
  data() {
    return {
      // 选中的物料列表
      chooseMatterList: [],
      // 计划单对应的物料列表
      matterList: [],
      // 计划单对应物料传参
      matterData: {
        page: 1,
        size: 100,
        productName: '',
        selectAll: '',
        planIds: '',
        approveStatus: ''
      },
      // 打开转订单弹框
      turnOrderDialog: false,
      // 列表动态高度
      tableHeight: '',
      // 采购计划列表
      purchasePlanList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      purchasePlanData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        productName: '',
        createTimeBegin: '',
        createTimeEnd: '',
        buyTimeBegin: '',
        buyTimeEnd: '',
        approveStatus: '',
        proposerId: '',
        ifAwait: ''
      },
      chooseTime: '',
      // 控制是否全部勾选
      chooseAllList: false,
      exoprtData: {
        code: '',
        productName: '',
        approveTimeBegin: '',
        approveTimeEnd: '',
        approveStatus: '',
        proposerId: '',
        ifAwait: ''
      },
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    if (this.$route.query.ifAwait !== undefined) {
      this.purchasePlanData.ifAwait = this.$route.query.ifAwait
      this.purchasePlanData.approveStatus = this.$route.query.approveStatus - 0
      this.purchasePlanData.proposerId = this.$route.query.proposerId
    } else {
      this.purchasePlanData.ifAwait = ''
      this.purchasePlanData.approveStatus = ''
      this.purchasePlanData.proposerId = ''
    }
    if (this.$route.query.indexData && this.$route.query.indexData == 'today') {
      this.purchasePlanData.buyTimeBegin = new Date().toISOString().split('T')[0] + ' 00:00:00'
      this.purchasePlanData.buyTimeEnd = new Date().toISOString().split('T')[0] + ' 23:59:59'
      this.chooseTime = [new Date().toISOString().split('T')[0] + ' 00:00:00', new Date().toISOString().split('T')[0] + ' 23:59:59']
    }
    this.getpurchasePlanList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routerJump(3, result.id)
      } else if (command === '2') {
        this.routerJump(3, result.id)
      } else if (command === '3') {
        this.delPurchasePlan(result.id)
      } else {
        this.endPurchasePlan(result.id)
      }
    },
    // 确认产品选择
    confirmChoose() {
      if (this.chooseMatterList.length === 0) return this.$message.error('请选择需要转订单的产品')
      this.$router.push({ path: "/addPurchaseOrder", query: { result: JSON.stringify(this.chooseMatterList) }})
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.chooseMatterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.chooseMatterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.chooseMatterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.chooseMatterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.chooseMatterList.push(selection)
        }
      } else {
        this.chooseMatterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.chooseMatterList = selection
    },
    // 打开转订单弹框
    async openTurnOrder() {
      // 获取勾选的采购计划单
      let chooseArray = []
      let confirmApproveStatus = true
      this.purchasePlanList.forEach((item, index) => {
        if (item.choose === true && item.approveStatus === 2) {
          chooseArray.push(item.id)
        } else if (item.choose === true && item.approveStatus !== 2) {
          confirmApproveStatus = false
        }
      })
      if (confirmApproveStatus === false) return this.$message.error('只允许勾选审批完成的计划单!')
      if (chooseArray.length === 0) return this.$message.error('请勾选采购计划单!')
      this.matterData.planIds = chooseArray.join(',')
      this.getPlanOrderList()
      this.turnOrderDialog = true
    },
    // 获取计划单对应的物料列表
    async getPlanOrderList() {
      const { data: res } = await getPlanOrderListApi(this.matterData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.matterList = res.data.data.list
    },
    // 勾选采购计划单全选时触发
    handleChooseAllList(selection) {
      if (this.purchasePlanList.length !== 0) {
        this.purchasePlanList.forEach((item, index) => {
          item.choose = selection
        })
      }
    },
    // 勾选其中的一个对账单明细列表
    handleChoosePartList(selection) {
      if (selection === true) {
        // 这里可以将勾选的值存储起来(暂时没后续)
        let isLast = true
        // 判断当前勾选的是否是最后一个待勾选项
        this.purchasePlanList.forEach((item, index) => {
          if (item.choose === false) {
            isLast = false
          }
        })
        if (isLast === true) {
          // 让全选框勾选上
          this.chooseAllList = true
        }
      } else {
        this.chooseAllList = false
      }
    },
    // 删除
    delPurchasePlan(id) {
      this.$confirm("是否确认删除该计划单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(async () => {
          const { data: res } = await delPurchasePlanApi(id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('删除成功!')
          this.getpurchasePlanList()
        })
    },
    // 结束
    endPurchasePlan(id) {
      this.$confirm("是否手动结束该采购计划单审批流", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(async () => {
          const { data: res } = await purchasePlanEndApi(id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('结束成功!')
          this.getpurchasePlanList()
        })
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: "/addPurchasePlan" })
      } else if (val === 2) {
        this.$router.push({ path: "/purchasePlanDetail", query: { result: JSON.stringify(result) }})
      } else {
        this.$router.push({ path: "/editpurchasePlan", query: { result: JSON.stringify(result) }})
      }
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.purchasePlanData.buyTimeBegin = ''
        this.purchasePlanData.buyTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value);
          let getYear = timers.getFullYear();
          let nowMonth = timers.getMonth() + 1;
          let getDate = timers.getDate();
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.purchasePlanData.buyTimeBegin = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.purchasePlanData.buyTimeEnd = getYear + '-' + nowMonth + '-' + getDate + ' 23:59:59'
          }
        })
      }
      this.getpurchasePlanList()
    },
    // 获取采购计划列表
    async getpurchasePlanList(reset) {
      if (reset !== 'reset') {
        this.purchasePlanData.page = 1
      }
      const { data: res } = await purchasePlanListApi(this.purchasePlanData)
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
      // 获取表单数据
      let newPurchasePlanList = res.data.data.list
      // 给数据添加唯一值index,用于展开
      if (newPurchasePlanList.length !== 0) {
        newPurchasePlanList.forEach((item, index) => {
          item.choose = false
          item.index = index + ''
          if (item.lineEntityList.length !== 0) {
            item.lineEntityList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
      this.purchasePlanList = newPurchasePlanList
    },
    // -----------------------------------------------------------------
    
    // 触发每页显示条数
    handlesizeChange(val) {
      this.purchasePlanData.size = val
      this.purchasePlanData.page = 1
      this.getpurchasePlanList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.purchasePlanData.page = val
      this.currentPage = val
      this.getpurchasePlanList('reset')
    },
    // 导出
    async purchaseReturnExport() {
      const { data: res } = await cgjhExportApi(this.exoprtData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getpurchasePlanList()
    }
  }
}
</script>
<style scoped lang='less'>
.purchasePlan {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
    .stateBox1, .stateBox2, .stateBox3 {
      display: block;
      width: 64px;
      height: 22px;
      border-radius: 4px 4px 4px 4px;
      font-size: 12px;
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
      
    }
    .stateBox2 {
      background: rgba(239, 188, 96, 0.05);
      border: 1px solid rgba(239, 188, 96, 0.2);
      color: rgba(239, 188, 96, 1);
    }
    .stateBox3 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .sellTypeBox1, .sellTypeBox2, .sellTypeBox3, .sellTypeBox4 {
      display: inline-block;
      width: 80px;
      height: 22px;
      border-radius: 4px;
      background-color: rgba(70, 147, 235, 0.05);
      border: 1px solid rgba(70, 147, 235, 0.2);
      color: rgba(70, 147, 235, 1);
    }
    .sellTypeBox2 {
      background-color: rgba(59, 212, 144, 0.05);
      border: 1px solid rgba(59, 212, 144, 0.2);
      color: rgba(59, 212, 144, 1);
    }
    .sellTypeBox3 {
      background-color: rgba(239, 188, 96, 0.05);
      border: 1px solid rgba(239, 188, 96, 0.2);
      color: rgba(239, 188, 96, 1);
    }
    .sellTypeBox4 {
      background-color: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.20);
      color: rgba(0, 0, 0, 0.40);
    }
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
