// 打印模板
<template>
  <div class="printPreview">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dispatchList' }">发货单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">打印</el-breadcrumb-item>
    </el-breadcrumb>
    <nav>
      <el-button @click="goPrint" class="lightDeepStyle" size="mini" id="fullscreen_button"><i class="el-icon-printer"></i>打  印</el-button>
      <el-button class="defaultStyle" size="mini" @click="$router.push({ path: '/dispatchDetail', query: { invoiceId: invoiceId }})"><i class="el-icon-back"></i>返 回</el-button>
    </nav>
    <div class="contentBox">
      <!-- 左侧模板修改 -->
      <div class="printTemplate">
        <div class="header">
          <p>模板选择</p>
          <span class="printBtn" @click="keepTemp()"><i class="iconfont icon-preserve"></i>保存</span>
          <el-select @change="chooseTemplate()" class="chooseTemp" v-model="chooseTemp" placeholder="请选择模板" size="mini">
            <el-option v-for="(item, index) in printList" :key="index" :label="item.title" :value="item.id">
              <span>{{item.title}}<i @click.stop="delTemp(item)" style="margin-right: 10px; float: right; line-height: 30px; color: rgba(242, 90, 90, 1)" class="el-icon-delete"></i></span>
            </el-option>
          </el-select>
        </div>
        <!-- 模板名称 -->
        <div class="printStyle">
          <p>模板标题</p>
          <el-input size="mini" v-model="tempTitle" placeholder="请输入模板名称" maxlength="50" show-word-limit></el-input>
        </div>
        <!-- 模板样式 -->
        <div class="printStyle">
          <p>模板内容</p>
          <div class="controlStyle">
            <span>标题</span>
            <!-- 字体 -->
            <el-select v-model="printDataList.templateTitleSize" placeholder="" size="mini" @change="controlUpdatePrint()">
              <el-option label="14号" value="14"></el-option>
              <el-option label="16号" value="16"></el-option>
              <el-option label="18号" value="18"></el-option>
              <el-option label="20号" value="20"></el-option>
            </el-select>
            <!-- 加粗 -->
            <div v-if="printDataList.templateTitleBold === '1'" class="boldBox" @click="printTitleBold('title', '2')">B</div>
            <div v-else class="boldBox" style="color: rgba(0, 199, 199, 1);" @click="printTitleBold('title', '1')">B</div>
          </div>
          <el-input v-if="controlPrintStyle === true" v-model="printDataList.templateTitle" size="mini" placeholder="请输入标题" maxlength="20" show-word-limit></el-input>
          <div v-if="controlPrintStyle === true" class="controlStyle">
            <span>副标题</span>
            <!-- 字体 -->
            <el-select v-model="printDataList.templateSubtitleSize" placeholder="" size="mini" @change="controlUpdatePrint()">
              <el-option label="14号" value="14"></el-option>
              <el-option label="16号" value="16"></el-option>
              <el-option label="18号" value="18"></el-option>
              <el-option label="20号" value="20"></el-option>
            </el-select>
            <!-- 加粗 -->
            <div v-if="printDataList.templateSubtitleBold === '1'" class="boldBox" @click="printTitleBold('subTitle', '2')">B</div>
            <div v-else class="boldBox" style="color: rgba(0, 199, 199, 1);" @click="printTitleBold('subTitle', '1')">B</div>
          </div>
          <el-input v-if="controlPrintStyle === true" v-model="printDataList.templateSubtitle" size="mini" placeholder="请输入副标题" maxlength="20" show-word-limit></el-input>
          <div v-if="controlPrintStyle === true" class="controlStyle">
            <span>单据表头</span>
            <!-- 字体 -->
            <el-select v-model="printDataList.templateTableHeaderSize" placeholder="" size="mini" @change="controlUpdatePrint()">
              <el-option label="14号" value="14"></el-option>
              <el-option label="16号" value="16"></el-option>
              <el-option label="18号" value="18"></el-option>
              <el-option label="20号" value="20"></el-option>
            </el-select>
            <!-- 加粗 -->
            <div v-if="printDataList.templateTableHeaderBold === '1'" class="boldBox" @click="printTitleBold('headerBold', '2')">B</div>
            <div v-else class="boldBox" style="color: rgba(0, 199, 199, 1);" @click="printTitleBold('headerBold', '1')">B</div>
          </div>
          <div v-if="controlPrintStyle === true" class="controlStyle">
            <span>单据内容</span>
            <!-- 字体 -->
            <el-select v-model="printDataList.templateTableContentSize" placeholder="" size="mini" @change="controlUpdatePrint()">
              <el-option label="14号" value="14"></el-option>
              <el-option label="16号" value="16"></el-option>
              <el-option label="18号" value="18"></el-option>
              <el-option label="20号" value="20"></el-option>
            </el-select>
            <!-- 加粗 -->
            <div v-if="printDataList.templateTableContentBold === '1'" class="boldBox" @click="printTitleBold('contentBold', '2')">B</div>
            <div v-else class="boldBox" style="color: rgba(0, 199, 199, 1);" @click="printTitleBold('contentBold', '1')">B</div>
          </div>
        </div>
        <!-- 表格行设置 -->
        <div class="printStyle">
          <p>表格行设置</p>
          <div class="controlStyle">
            <span>表头行高</span>
            <el-input size="mini" placeholder="" v-model="printDataList.templateThLineHeight" @change="controlUpdatePrint()" oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"></el-input>
          </div>
          <div class="controlStyle">
            <span>表内行高</span>
            <el-input size="mini" placeholder="" v-model="printDataList.templateTdLineHeight" @change="controlUpdatePrint()" oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"></el-input>
          </div>
          <!-- <div class="controlStyle">
            <span>表尾行高</span>
            <el-input size="mini" placeholder=""></el-input>
          </div> -->
          <div class="controlStyle">
            <span>固定行数</span>
            <el-input size="mini" placeholder="" v-model="printDataList.templateFiexdRow" @change="controlUpdatePrint()" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
          </div>
        </div>
        <!-- 自定义单据头 -->
        <div class="printStyle">
          <p style="margin-bottom: 10px;">自定义单据头</p>
          <ul class="customDocumentHeader">
            <li @click="printDataList.customTableHeader1 = false" v-if="printDataList.customTableHeader1 === true">客户名称<i class="iconfont icon-right"></i></li>
            <li @click="printDataList.customTableHeader1 = true" v-else class="hiddenLi">客户名称</li>
            <li @click="printDataList.customTableHeader2 = false" v-if="printDataList.customTableHeader2 === true">制单人<i class="iconfont icon-right"></i></li>
            <li @click="printDataList.customTableHeader2 = true" v-else class="hiddenLi">制单人</li>
            <li @click="printDataList.customTableHeader3 = false" v-if="printDataList.customTableHeader3 === true">制单时间<i class="iconfont icon-right"></i></li>
            <li @click="printDataList.customTableHeader3 = true" v-else class="hiddenLi">制单时间</li>
            <li @click="printDataList.customTableHeader4 = false" v-if="printDataList.customTableHeader4 === true">发货单号<i class="iconfont icon-right"></i></li>
            <li @click="printDataList.customTableHeader4 = true" v-else class="hiddenLi">发货单号</li>
          </ul>
        </div>
        <!-- 自定义表格头 -->
        <div class="printStyle">
          <p style="margin-bottom: 10px;">自定义表格头</p>
          <ul class="customTableHeader">
            <li>
              <span>订单行号</span>
              <el-input size="mini" placeholder="列宽" v-model="printDataList.showTableWidth1" @change="controlUpdatePrint()" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
              <el-checkbox @change="updateDom()" v-model="printDataList.showTable1"></el-checkbox>
            </li>
            <li>
              <span>产品名称</span>
              <el-input size="mini" placeholder="列宽" v-model="printDataList.showTableWidth2" @change="controlUpdatePrint()" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
              <el-checkbox @change="updateDom()" v-model="printDataList.showTable2"></el-checkbox>
            </li>
            <li>
              <span>单位</span>
              <el-input size="mini" placeholder="列宽" v-model="printDataList.showTableWidth3" @change="controlUpdatePrint()" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
              <el-checkbox @change="updateDom()" v-model="printDataList.showTable3"></el-checkbox>
            </li>
            <li>
              <span>规格</span>
              <el-input size="mini" placeholder="列宽" v-model="printDataList.showTableWidth4" @change="controlUpdatePrint()" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
              <el-checkbox @change="updateDom()" v-model="printDataList.showTable4"></el-checkbox>
            </li>
            <li>
              <span>发货数量</span>
              <el-input size="mini" placeholder="列宽" v-model="printDataList.showTableWidth5" @change="controlUpdatePrint()" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
              <el-checkbox @change="updateDom()" v-model="printDataList.showTable5"></el-checkbox>
            </li>
            <li>
              <span>剩余待发货数量</span>
              <el-input size="mini" placeholder="列宽" v-model="printDataList.showTableWidth6" @change="controlUpdatePrint()" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
              <el-checkbox @change="updateDom()" v-model="printDataList.showTable6"></el-checkbox>
            </li>
          </ul>
        </div>
        <!-- 自定义单据尾 -->
        <div class="printStyle">
          <p style="margin-bottom: 10px;">自定义单据尾</p>
          <ul class="customDocumentHeader">
            <li @click="printDataList.customTableFooter1 = false" v-if="printDataList.customTableFooter1 === true">送货方式<i class="iconfont icon-right"></i></li>
            <li @click="printDataList.customTableFooter1 = true" v-else class="hiddenLi">送货方式</li>
            <li @click="printDataList.customTableFooter2 = false" v-if="printDataList.customTableFooter2 === true">打印时间<i class="iconfont icon-right"></i></li>
            <li @click="printDataList.customTableFooter2 = true" v-else class="hiddenLi">打印时间</li>
            <li @click="printDataList.customTableFooter3 = false" v-if="printDataList.customTableFooter3 === true">收货地址<i class="iconfont icon-right"></i></li>
            <li @click="printDataList.customTableFooter3 = true" v-else class="hiddenLi">收货地址</li>
          </ul>
        </div>
      </div>
      <!-- 模板内容 -->
      <div id="container_max">
        <div v-if="controlUpdateDom === true" class="printPreviews">
          <article ref="print" class="export" :style="'margin:0 auto; width:' + printAllContent.paperSizeWidth + 'px; height:' + printAllContent.paperSizeHeight + 'px; padding:' + printAllContent.topMargin + 'px ' + printAllContent.leftMargin + 'px;'">
            <h1 class="headerTitle" :style="'font-size:' + printAllContent.templateTitleSize + 'px; font-weight:' + printAllContent.templateTitleBold">{{printDataList.templateTitle}}</h1>
            <h1 class="headerTitle" :style="'font-size:' + printAllContent.templateSubtitleSize + 'px; font-weight:' + printAllContent.templateSubtitleBold">{{printDataList.templateSubtitle}}</h1>
            <ul>
              <li v-if="printDataList.customTableHeader1 === true"><span>客户名称: </span>{{invoiceDetail.companyName}}</li>
              <li v-if="printDataList.customTableHeader2 === true"><span>制单人: </span>{{invoiceDetail.userName}}</li>
              <li v-if="printDataList.customTableHeader3 === true"><span>制单时间: </span>{{(invoiceDetail.createTime).substring(0, 16)}}</li>
              <li v-if="printDataList.customTableHeader4 === true"><span>发货单号: </span>{{invoiceDetail.code}}</li>
            </ul>
            <el-table ref="table" :data="invoiceDetail.lineEntityList" style="width: 100%; min-height: 50px;" border :height="printAllContent.templateFiexdRow">
              <el-table-column key="1" v-if="printDataList.showTable1 === true" show-overflow-tooltip type="index" label="行号" :min-width="printDataList.showTableWidth1 + 'px'" align="center"></el-table-column>
              <el-table-column key="3" v-if="printDataList.showTable2 === true" prop="purchaseProductDescribes" label="产品名称" :min-width="printDataList.showTableWidth2 + 'px'"  align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.productName === null ? '暂无' : scope.row.productName}}</span>
                </template>
              </el-table-column>
              <el-table-column key="4" v-if="printDataList.showTable3 === true" show-overflow-tooltip prop="unitName" label="单位" align="center" :min-width="printDataList.showTableWidth3 + 'px'">
                <template slot-scope="scope">
                  <span>{{scope.row.unitName === null || scope.row.unitName === '' ? '暂无' : scope.row.unitName}}</span>
                </template>
              </el-table-column>
              <el-table-column key="5" v-if="printDataList.showTable4 === true" show-overflow-tooltip prop="productSpecs" label="规格" align="center" :min-width="printDataList.showTableWidth4 + 'px'">
                <template slot-scope="scope">
                  <span>{{scope.row.productSpecs === null || scope.row.productSpecs === '' ? '暂无' : scope.row.productSpecs}}</span>
                </template>
              </el-table-column>
              <el-table-column key="6" v-if="printDataList.showTable5 === true" show-overflow-tooltip prop="number" label="发货数量" align="center" :min-width="printDataList.showTableWidth5 + 'px'"></el-table-column>
              <el-table-column key="6" v-if="printDataList.showTable6 === true" show-overflow-tooltip prop="residueNumber" label="剩余待发货数量" align="center" :min-width="printDataList.showTableWidth6 + 'px'"></el-table-column>
            </el-table>
            <p>
              <span v-if="printDataList.customTableFooter1 === true" style="display: inline-block; margin-right: 40px;">送货方式: {{invoiceDetail.deliveryType}}</span>
              <span v-if="printDataList.customTableFooter2 === true" style="display: inline-block;">打印时间: {{(nowTime).substring(0, 16)}}</span>
              <span v-if="printDataList.customTableFooter3 === true">收货地址: {{invoiceDetail.deliveryAddressName === null || invoiceDetail.deliveryAddressName === '' ? '暂无' : invoiceDetail.deliveryAddressName}}</span>
            </p>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import printJS from 'print-js'
import html2Canvas from 'html2canvas'
// 为局部全屏引入
// import screenfull from 'screenfull'
import moment from 'moment'
import { deliverGoodsDetailApi, printAddApi, printListApi, printUpdateApi, printDetailApi, printDelApi } from '@/api'
export default {
  name: 'printPreview',
  data() {
    return {
      // 选择的模板
      chooseTemp: '',
      // 模板名称
      tempTitle: '',
      // 模板列表
      printList: [],
      // 控制更新模板页面
      controlUpdateDom: false,
      // 控制模板样式折叠
      controlPrintStyle: true,
      invoiceId: this.$route.query.id,
      // 发货单数据
      invoiceDetail: {
        id: '',
        buyId: '',
        startTime: '',
        endTime: '',
        remark: '',
        transport: '',
        logistics: '',
        transportNum: '',
        productList: [],
        address: ''
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 7,
      // 总页数
      totalNum: 10,
      // 总条数
      total: 0,
      // 当前时间
      nowTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      // 获取打印详情参数
      printData: [],
      // 打印设置默认内容(左侧模板输入的值)
      printDataList: {
        printPaper: '1',
        direction: '1',
        topMargin: '15',
        leftMargin: '15',
        printTime: '0',
        // 模板样式--------------------------------------------------1
        // 模板标题内容------------------------------1.1
        templateTitle: '宁波众力供应链有限公司',
        // 模板标题字号大小
        templateTitleSize: '18',
        // 模板标题加粗
        templateTitleBold: '1',
        // 模板副标题内容----------------------------1.2
        templateSubtitle: '发货单',
        // 模板副标题字号大小
        templateSubtitleSize: '16',
        // 模板副标题加粗
        templateSubtitleBold: '1',
        // 表格单据头字号大小-------------------------1.3
        templateTableHeaderSize: '14',
        // 表格单据头加粗
        templateTableHeaderBold: '1',
        // 表格单据内容字号大小-----------------------1.4
        templateTableContentSize: '14',
        // 表格单据内容加粗
        templateTableContentBold: '1',
        // 表格行设置------------------------------------------------2
        // 表头行高
        templateThLineHeight: '30',
        // 表内行高
        templateTdLineHeight: '30',
        // 固定行数
        templateFiexdRow: '10',
        // 自定义单据头-----------------------------------------------3
        customTableHeader1: true,
        customTableHeader2: true,
        customTableHeader3: true,
        customTableHeader4: true,
        // 自定义表格头-----------------------------------------------4
        showTable1: true,
        showTableWidth1: 120,
        showTable2: true,
        showTableWidth2: 200,
        showTable3: true,
        showTableWidth3: 80,
        showTable4: true,
        showTableWidth4: 80,
        showTable5: true,
        showTableWidth5: 100,
        showTable6: true,
        showTableWidth6: 140,
        // 自定义单据尾-----------------------------------------------5
        customTableFooter1: true,
        customTableFooter2: true,
        customTableFooter3: true,
        customTableFooter4: true,
        customTableFooter5: true,
        customTableFooter6: true
      },
      // 打印控制(实际存储的值)
      printAllContent: {
        // 纸张大小(宽度)
        paperSizeWidth: 796,
        // 纸张大小(高度)
        paperSizeHeight: 1000,
        // 上下边距
        topMargin: 15,
        // 左右边距
        leftMargin: 15,
        // 打印时间是否显示
        printTime: '0',
        // 打印设置内容-------------------
        // 模板标题字号大小
        templateTitleSize: '18',
        // 模板标题加粗
        templateTitleBold: '1',
        // 表格单据头字号大小
        templateTableHeaderSize: '14',
        // 表格单据头加粗
        templateTableHeaderBold: '1',
        // 表格单据内容字号大小
        templateTableContentSize: '14',
        // 表格单据内容加粗
        templateTableContentBold: '1',
        // 表头行高
        templateThLineHeight: '30',
        // 表内行高
        templateTdLineHeight: '30',
        // 固定行数
        templateFiexdRow: '10'
      }
    }
  },
  created() {
    this.getInvoiceDetail().then(() => {
      this.getPrintTemplateDetail('first')
    })
    this.getPrintList()
  },
  mounted() {
    const element = document.getElementById('container_max')
    document.getElementById('fullscreen_button').addEventListener('click', () => {
      if (screenfull.isEnabled) {
        screenfull.request(element)
      }
    })
  },
  methods: {
    // 删除模板
    delTemp(val) {
      this.$confirm('是否删除【' + val.title + '】?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await printDelApi(val.id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        // 如果删除的模板是当前模板就清空
        if (this.chooseTemp == val.id) {
          this.tempTitle = ''
          this.chooseTemp = ''
        }
        this.getPrintList()
      })
    },
    // 选择模板
    async chooseTemplate() {
      // 获取模板详情
      const { data: res } = await printDetailApi(this.chooseTemp)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.tempTitle = res.data.template.title
      let newArray = res.data.template.content
      this.printDataList = JSON.parse(newArray).printDataList
      this.printAllContent = JSON.parse(newArray).printAllContent
    },
    // 获取模板列表
    async getPrintList() {
      const { data: res } = await printListApi(5)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.printList = res.data.list
    },
    // 保存模板
    keepTemp() {
      if (this.chooseTemp !== '') {
        this.$confirm('是否新增模板?', '提示', {
          confirmButtonText: '新增',
          cancelButtonText: '修改',
          type: 'warning',
          closeOnClickModal: false
        }).then(async() => {
          this.addTempMoudle()
        }).catch(() => {
          this.editTempMoudle()
        })
      } else {
        this.addTempMoudle()
      }
    },
    // 新增打印模板
    async addTempMoudle() {
      if (this.tempTitle === '') return this.$message.error('模板名称不能为空!')
      const { data: res } = await printAddApi({ type: 5, remark: '', content: JSON.stringify({ printDataList: this.printDataList, printAllContent: this.printAllContent }), title: this.tempTitle })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('新增模板成功!')
      this.getPrintList()
    },
    // 修改打印模板
    async editTempMoudle() {
      if (this.tempTitle === '') return this.$message.error('模板名称不能为空!')
      const { data: res } = await printUpdateApi({ id: this.chooseTemp, type: 5, remark: '', content: JSON.stringify({ printDataList: this.printDataList, printAllContent: this.printAllContent }), title: this.tempTitle })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('修改模板成功!')
      this.getPrintList()
    },
    // 更新dom
    updateDom() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    goPrint() {
      this.isPrint = true
      html2Canvas(this.$refs.print, {
        allowTaint: true,
        taintTest: false,
        useCORS: true,
        dpi: window.devicePixelRatio * 4,
        scale: 4
      }).then((canvas) => {
        const url = canvas.toDataURL()
        printJS({
          printable: url, // 要打印的id
          type: 'image',
          style: '@page{size:auto;margin: 0cm 1cm 0cm 1cm;}'
        })
        this.isPrint = false
      })
    },
    // 点击加粗时触发
    printTitleBold(index, val) {
      if (index === 'title') {
        this.printDataList.templateTitleBold = val
      } else if (index === 'subTitle') {
        this.printDataList.templateSubtitleBold = val
      } else if (index === 'headerBold') {
        this.printDataList.templateTableHeaderBold = val
      } else if (index === 'contentBold') {
        this.printDataList.templateTableContentBold = val
      }
      this.controlUpdatePrint()
    },
    // 控制更新模板打印页面
    controlUpdatePrint() {
      // 表头行高默认值
      if ((this.printDataList.templateThLineHeight - 0) < 23) {
        this.printDataList.templateThLineHeight = '23'
      }
      // 表内行高默认值
      if ((this.printDataList.templateTdLineHeight - 0) < 23) {
        this.printDataList.templateTdLineHeight = '23'
      }
      // 固定行数默认值
      if (this.printDataList.templateFiexdRow === '') {
        this.printDataList.templateFiexdRow = (this.invoiceDetail.lineEntityList).length
      }
      this.getPrintTemplateDetail()
    },
    printDemo() {
      let that = this
      this.$nextTick(() => {
        that.$refs.easyPrint.print()
      })
    },
    // 获取发货单打印详情
    async getPrintTemplateDetail(value) {
      // 更新dom页面
      this.controlUpdateDom = false
      // setTimeout(() => {
      this.controlUpdateDom = true
      // })
      // // 控制只有第一次进页面才调此接口
      if (value === 'first') {
        // const { data: res } = await getPrintTemplateDetailApi(5)
        // if (res.code !== 0) return this.$message.error(res.msg)
        // this.printData = res.data.list
        // this.printData.forEach(item => {
        //   if (item.mkey === 'printPaper') this.printDataList.printPaper = item.mval
        //   if (item.mkey === 'direction') this.printDataList.direction = item.mval
        //   if (item.mkey === 'topMargin') this.printDataList.topMargin = item.mval
        //   if (item.mkey === 'leftMargin') this.printDataList.leftMargin = item.mval
        //   if (item.mkey === 'printTime') this.printDataList.printTime = item.mval
        // })
        // // 根据发货单打印详情调整页面默认显示
        // // 根据选择的纸张类型和纸张方向改变页面显示纸张大小(printPaper => '1' A4 , '2' A5, '3', 票据 direction => '1' 纵向, '2' 横向)
        // if (this.printDataList.printPaper === '1' && this.printDataList.direction === '1') {
        //   this.printAllContent.paperSizeWidth = 796
        //   this.printAllContent.paperSizeHeight = 1000
        // } else if (this.printDataList.printPaper === '2' && this.printDataList.direction === '1') {
        //   this.printAllContent.paperSizeWidth = 560
        //   this.printAllContent.paperSizeHeight = 794
        // } else if (this.printDataList.printPaper === '1' && this.printDataList.direction === '2') {
        //   this.printAllContent.paperSizeWidth = 1000
        //   this.printAllContent.paperSizeHeight = 796
        // } else if (this.printDataList.printPaper === '2' && this.printDataList.direction === '2') {
        //   this.printAllContent.paperSizeWidth = 794
        //   this.printAllContent.paperSizeHeight = 560
        // }
        // // 纸张上下边距
        // this.printAllContent.topMargin = this.printDataList.topMargin
        // // 纸张左右边距
        // this.printAllContent.leftMargin = this.printDataList.leftMargin
        // // 打印时间
        // this.printAllContent.printTime = this.printDataList.printTime
      }
      // 模板标题字号------------------------------1.1
      this.printAllContent.templateTitleSize = this.printDataList.templateTitleSize
      // 模板标题加粗
      if (this.printDataList.templateTitleBold === '1') {
        this.printAllContent.templateTitleBold = '400'
      } else {
        this.printAllContent.templateTitleBold = 'bold'
      }
      // 模板副标题字号------------------------------1.2
      this.printAllContent.templateSubtitleSize = this.printDataList.templateSubtitleSize
      // 模板副标题加粗
      if (this.printDataList.templateSubtitleBold === '1') {
        this.printAllContent.templateSubtitleBold = '400'
      } else {
        this.printAllContent.templateSubtitleBold = 'bold'
      }
      // 单据头字号----------------------------------1.3
      this.printAllContent.templateTableHeaderSize = this.printDataList.templateTableHeaderSize
      // 通过dom去获取所有th表头,添加字体class
      for (let i = 0; i < document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th').length - 1; i++) {
        if (this.printAllContent.templateTableHeaderSize === '14') {
          document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.add('thSize14')
          document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.remove('thSize16', 'thSize18', 'thSize20')
        } else if (this.printAllContent.templateTableHeaderSize === '16') {
          document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.add('thSize16')
          document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.remove('thSize14', 'thSize18', 'thSize20')
        } else if (this.printAllContent.templateTableHeaderSize === '18') {
          document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.add('thSize18')
          document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.remove('thSize14', 'thSize16', 'thSize20')
        } else if (this.printAllContent.templateTableHeaderSize === '20') {
          document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.add('thSize20')
          document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.remove('thSize14', 'thSize16', 'thSize18')
        }
      }
      // 单据头加粗
      this.printAllContent.templateTableHeaderBold = this.printDataList.templateTableHeaderBold
      // 通过dom去获取所有th表头,添加字体class
      for (let i = 0; i < document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th').length; i++) {
        if (document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild !== null) {
          if (this.printAllContent.templateTableHeaderBold === '2') {
            document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.add('headerBold')
          } else {
            document.getElementsByClassName('has-gutter')[0].firstChild.getElementsByTagName('th')[i].firstChild.classList.remove('headerBold')
          }
        }
      }
      // 单据内容字号----------------------------------1.4
      this.printAllContent.templateTableContentSize = this.printDataList.templateTableContentSize
      // 通过dom去获取所有td表中内容,添加字体class
      for (let i = 0; i < document.getElementsByTagName('td').length; i++) {
        if (this.printAllContent.templateTableContentSize === '14') {
          document.getElementsByTagName('td')[i].firstChild.classList.add('thSize14')
          document.getElementsByTagName('td')[i].firstChild.classList.remove('thSize16', 'thSize18', 'thSize20')
        } else if (this.printAllContent.templateTableContentSize === '16') {
          document.getElementsByTagName('td')[i].firstChild.classList.add('thSize16')
          document.getElementsByTagName('td')[i].firstChild.classList.remove('thSize14', 'thSize18', 'thSize20')
        } else if (this.printAllContent.templateTableContentSize === '18') {
          document.getElementsByTagName('td')[i].firstChild.classList.add('thSize18')
          document.getElementsByTagName('td')[i].firstChild.classList.remove('thSize14', 'thSize16', 'thSize20')
        } else if (this.printAllContent.templateTableContentSize === '20') {
          document.getElementsByTagName('td')[i].firstChild.classList.add('thSize20')
          document.getElementsByTagName('td')[i].firstChild.classList.remove('thSize14', 'thSize16', 'thSize18')
        }
      }
      // 单据内容加粗
      this.printAllContent.templateTableContentBold = this.printDataList.templateTableContentBold
      // 通过dom去获取所有td表中内容,添加字体class
      for (let i = 0; i < document.getElementsByTagName('td').length; i++) {
        if (this.printAllContent.templateTableContentBold === '2') {
          document.getElementsByTagName('td')[i].firstChild.classList.add('headerBold')
        } else {
          document.getElementsByTagName('td')[i].firstChild.classList.remove('headerBold')
        }
      }
      // 表格行设置--------------------------------2.0
      // 表头行高
      this.printAllContent.templateThLineHeight = this.printDataList.templateThLineHeight
      for (let i = 0; i < document.getElementsByTagName('th').length; i++) {
        document.getElementsByTagName('th')[i].style.height = this.printAllContent.templateThLineHeight + 'px'
      }
      // 表内行高
      this.printAllContent.templateTdLineHeight = this.printDataList.templateTdLineHeight
      for (let i = 0; i < document.getElementsByTagName('td').length; i++) {
        document.getElementsByTagName('td')[i].style.height = this.printAllContent.templateTdLineHeight + 'px'
      }
      // 固定行数(输入值乘以每行高度 + 表头高度 + 1px边框)
      this.printAllContent.templateFiexdRow = ((this.printDataList.templateFiexdRow - 0) * (this.printAllContent.templateTdLineHeight - 0)) + (this.printAllContent.templateThLineHeight - 0) + 3 + 'px'
    },
    // 获取发货单详情列表
    async getInvoiceDetail() {
      const { data: res } = await deliverGoodsDetailApi({ id: this.$route.query.id })
      this.invoiceDetail = res.data.data
      this.printDataList.templateTitle = this.invoiceDetail.companyName
      // 让固定行数默认为物料行数
      this.printDataList.templateFiexdRow = (this.invoiceDetail.lineEntityList).length
    }
  }
}
</script>
<style scoped lang='less'>
@page{
  size: auto A4 landscape;
  margin: 3mm;
}
.printPreview {
  nav {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .contentBox {
    margin: 40px 20px;
    display: flex;
    .printTemplate {
      margin-right: 50px;
      // max-height: 1207px;
      width: 300px;
      min-width: 285px;
      background-color: #fff;
      border-radius: 10px;
      .header {
        padding-top: 10px;
        padding-left: 20px;
        height: 61px;
        line-height: 30px;
        p {
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
        }
        .printBtn {
          float: right;
          margin-right: 20px;
          font-size: 13px;
          cursor: pointer;
          i {
            margin-right: 3px;
            font-size: 13px;
          }
        }
        .chooseTemp {
          width: calc(100% - 20px);
          /deep/ .el-input__inner {
            height: 28px;
          }
        }
      }
      // 模板名称 模板样式
      .printName, .printStyle {
        padding: 12px 20px 5px;
        color: rgba(67, 67, 67, 1);
        p {
          color: rgba(67, 67, 67, 1);
          font-weight: 300;
          font-size: 14px;
        }
        .el-input {
          margin-top: 10px;
          .el-input__inner {
            height: 38px;
            width: 135px;
            border-radius: 3px;
          }
          /deep/ .el-input__count .el-input__count-inner {
            color: rgba(159, 121, 12, 1);
          }
        }
      }
      // 模板样式
      .printStyle {
        p {
          font-size: 14px;
          color: rgba(43, 43, 43, 1);
          font-weight: bold;
        }
        .controlStyle {
          position: relative;
          margin-top: 18px;
          span {
            font-size: 14px;
            font-weight: 300;
          }
          .el-select {
            position: absolute;
            right: 30px;
            /deep/ .el-input__inner {
              padding-left: 10px;
              width: 64px;
              height: 25px;
              border: 0;
              background-color: rgba(250, 250, 250, 1);
              color: rgba(67, 67, 67, 1);
              font-size: 12px;
              line-height: 25px!important;
            }
            /deep/ .el-input--mini .el-input__suffix-inner {
              height: 25px;
              .el-input__icon {
                line-height: 40px;
              }
            }
          }
          .boldBox {
            position: absolute;
            right: 0;
            display: inline-block;
            width: 20px;
            height: 25px;
            background-color: rgba(250, 250, 250, 1);
            color: rgba(67, 67, 67, 1);
            text-align: center;
            cursor: pointer;
          }
          .el-input {
            margin-top: 0;
            position: absolute;
            right: 30px;
            width: 64px;
            height: 25px;
            /deep/ .el-input__inner {
              padding-left: 10px;
              width: 64px;
              height: 25px;
              background-color: rgba(250, 250, 250, 1);
              color: rgba(67, 67, 67, 1);
              border: 0;
            }
          }
        }
        .customDocumentHeader {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            margin: 5px 0;
            padding: 6px 14px;
            background-color: rgba(230, 248, 255, 1);
            border-radius: 14px;
            width: 100%;
            color: rgba(110, 143, 162, 1);
            font-size: 13px;
            cursor: pointer;
            i {
              margin-left: -3px;
              display: inline-block;
              transform: scale(.4)
            }
          }
          .hiddenLi {
            background-color: rgba(230, 248, 255, .4);
            color: #cccccc;
          }
        }
        .customTableHeader {
          li {
            display: flex;
            justify-content: space-between;
            align-items:center;
            flex-wrap: nowrap;
            margin-bottom: 14px;
            // background-color: yellowgreen;
            span {
              display: inline-block;
              width: 100px;
              font-size: 14px;
              font-weight: 300;
            }
            .el-input {
              margin-top: 0;
              padding: 0;
              width: 64px;
              height: 25px;
              /deep/ .el-input__inner {
                padding-left: 10px;
                width: 64px;
                height: 25px;
                background-color: rgba(250, 250, 250, 1);
                color: rgba(67, 67, 67, 1);
                border: 0;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
    #container_max {
      position: relative;
      box-sizing: border-box;
      margin-bottom: 16px;
      border-radius: 3px;
      width: 100%;
      .printPreviews {
        padding-top: 30px;
        padding-bottom: 30px;
        width: 100%;
        min-width: 900px;
        background: rgba(82,86,89);
        box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
        article {
          background: #fff;
          border-radius: 0;
          h1 {
            margin: 10px 0;
            width: 100%;
            height: 23px;
            line-height: 23px;
            font-size: 16px;
            text-align: center;
            font-weight: 400;
          }
          ul li {
            margin-right: 30px;
            margin-bottom: 10px;
            display: inline-block;
            text-align: left;
            span {
              margin-right: 10px;
              display: inline-block;
            }
          }
          .el-table {
            margin-top: 0;
          }
          /deep/ .el-table--scrollable-y .el-table__body-wrapper {
            overflow-y: hidden;
          }
          /deep/ .el-table--scrollable-x .el-table__body-wrapper {
            overflow-x: hidden;
          }
          /deep/ .gutter {
            display: none;
          }
          /deep/ .el-table td,/deep/ .el-table th {
            padding: 0!important;
            font-weight: 500;
            height: 10px;
          }
          /deep/ .el-table__header th>.cell {
            color: rgba(43, 43, 43, 1)!important;
          }
          // 专门用来控制表字体大小的class
          /deep/ .thSize14 {
            font-size: 14px;
            color: rgba(43, 43, 43, 1)!important;
          }
          /deep/ .thSize16 {
            font-size: 16px;
            color: rgba(43, 43, 43, 1)!important;
          }
          /deep/ .thSize18 {
            font-size: 18px;
            color: rgba(43, 43, 43, 1)!important;
          }
          /deep/ .thSize20 {
            font-size: 20px;
            color: rgba(43, 43, 43, 1)!important;
          }
          // 专门用来控制表字体加粗的class
          /deep/ .headerBold {
            font-weight: bold!important;
          }
          .el-table::before {
            margin-top: 0;
            height: 0;
          }
          p {
            padding: 0 0 10px;
            span {
              margin: 10px 10px 0 0;
              display: block;
            }
            i {
              position: absolute;
              right: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
