// 邀请供应商
<template>
  <div class="addChargeBack">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/supplierManage' }">供应商管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">邀请供应商</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addChargeBackContent">
      <nav>
        <h5>邀请供应商</h5>
        <el-input v-model="name" placeholder="请输入供应商全称"></el-input>
        <el-button class="lightDeepStyle searchBtn" @click="routerJump()">查询</el-button>
      </nav>
    </div>
  </div>
</template>
<script>
import { industryInfoApi } from '@/api'
export default {
  data() {
    return {
      name: ''
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    routerJump() {
      this.$router.push({ path: '/addSupplier', query: { name: JSON.stringify(this.name) } })
    }
  }
}
</script>
<style lang="less" scoped>
.addChargeBack {
  .addChargeBackContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 190px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      padding: 20px;
      height: 114px;
      width: 100%;
      background: #fff;
      box-sizing: border-box;
      h5 {
        margin: 0 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .el-input {
        width: calc(100% - 80px);
      }
      .searchBtn {
        margin-left: 12px;
        width: 68px;
      }
    }
  }
}
</style>