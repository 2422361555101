// 企业资料维护
<template>
    <div class="data_uphold">
      <div class="main">
        <el-tabs v-model="activeName">
          <el-tab-pane label="企业信息" name="first"  class="enterInfo">
            <!-- 卡片视图区 -->
            <div class="cardBox">
              <div class="tableBox1"><p>企业名称 : </p><span :title="ruleForm.name" class="textHidden">{{ruleForm.name}}</span><p>入驻时间 : </p><span>{{(ruleForm.createTime).substring(0, 10)}}</span><p></p>
                <div class="imgBox">
                  <el-image v-if="controlEditInfo === false" :src="ruleForm.logo">
                    <div slot="error" class="image-slot">
                      <span>暂无图片</span>
                    </div>
                  </el-image>
                  <!-- http://api.z-srm.com/ -->
                  <!-- http://apisrm.bmxgj.cn/ -->
                  <el-upload
                    v-else
                    class="avatar-uploader"
                    :action="interAddrss + 'sys/common/upload'"
                    :show-file-list="false"
                    :data="{uploadType: 'BUSINESS_LOGO'}"
                    :headers="headers"
                    :on-success="handleAvatarSuccess"
                    :on-remove="handleRemove">
                    <img v-if="ruleForm.logo" :src="ruleForm.logo" class="avatar">
                    <span v-if="ruleForm.logo" class="el-upload-action" @click.stop="handleRemove()">
                        <i class="el-icon-delete"></i>
                    </span>
                    <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
                  </el-upload>
                  <el-image :src="ruleForm.licensePic">
                    <div slot="error" class="image-slot">
                      <span>暂无图片</span>
                    </div>
                  </el-image>
                </div>
              </div>
              <div class="tableBox2" v-if="controlEditInfo === false"><p>统一信用代码 : </p><span>{{ruleForm.regNumber}}</span><p></p></div>
              <div class="tableBox2" v-else>
                <p>所属省市区 : </p>
                <el-select class="addressInp" v-model="ruleForm.provinceCode" placeholder="请选择省份" size="mini" @change="getCityList('1')">
                  <el-option :label="item.name" :value="item.code" v-for="(item, index) in provinceList" v-bind:key="index"></el-option>
                </el-select>
                <el-select class="addressInp" v-model="ruleForm.cityCode" placeholder="请选择城市" size="mini" @change="getAreaList('1')">
                  <el-option :label="item.name" :value="item.code" v-for="(item, index) in cityList" v-bind:key="index"></el-option>
                </el-select>
                <el-select class="addressInp" v-model="ruleForm.areaCode" placeholder="请选择地区" size="mini">
                  <el-option :label="item.name" :value="item.code" v-for="(item, index) in areaList" v-bind:key="index"></el-option>
                </el-select>
                <p></p>
              </div>
              <!-- ~~~ -->
              <div class="tableBox2" v-if="controlEditInfo === false">
                <p>企业地址 : </p>
                <span v-if="ruleForm.address !== ''">{{ruleForm.provinceName + ruleForm.cityName + ruleForm.areaName + ruleForm.address}}</span>
                <span v-else>暂无</span>
                <p></p>
              </div>
              <div class="tableBox1" v-else>
                <p>统一信用代码 : </p>
                <span>{{ruleForm.regNumber}}</span>
                <p>详细地址 : </p>
                <el-input type="text" v-model="ruleForm.address" size="mini" placeholder="请输入地址"></el-input>
                <p></p>
              </div>
              <!-- ~~~ -->
              <div class="tableBox1" v-if="controlEditInfo === false">
                <p>行业类型 : </p>
                <span class="textHidden">{{ruleForm.industryDescribe}}</span>
                <p>经营模式 : </p>
                <span>{{ruleForm.modesName}}</span>
                <p></p>
              </div>
              <div class="tableBox1" v-else>
                <p>行业类型 : </p>
                <el-autocomplete class="inline-input" v-model="ruleForm.industryDescribe" :fetch-suggestions="querySearch" placeholder="请输入" @select="handleSelect" size="mini" value-key="name"></el-autocomplete>
                <p>经营模式 : </p>
                <el-select v-model="ruleForm.modes" placeholder="请选择" size="mini">
                  <el-option :label="item.label" :value="item.value" v-for="(item, index) in modesList" v-bind:key="index"></el-option>
                </el-select>
                <p></p>
              </div>
              <!-- ~~~ -->
              <div class="tableBox1" v-if="controlEditInfo === false">
                <p>主营产品 : </p>
                <span class="textHidden" :title="ruleForm.mainProducts">{{ruleForm.mainProducts}}</span>
                <p>企业规模 : </p>
                <span>{{ruleForm.scaleDescribe}}</span>
                <p></p>
              </div>
              <div class="tableBox1" v-else>
                <p>主营产品 : </p>
                <el-input type="text" v-model="ruleForm.mainProducts" size="mini" placeholder="请输入"></el-input>
                <p>企业规模 : </p>
                <el-select v-model="ruleForm.scale" placeholder="请选择企业规模" size="mini">
                  <el-option :label="item.name" :value="item.id" v-for="(item, index) in insizeList" v-bind:key="index"></el-option>
                </el-select>
                <p></p>
              </div>
              <!-- ~~~ -->
              <div class="tableBox1" v-if="controlEditInfo === false">
                <p>注册资金 : </p>
                <span v-if="ruleForm.capital !== null">{{ruleForm.capital}}万</span>
                <span v-else>暂无</span>
                <p>年营业额 : </p>
                <span v-if="ruleForm.turnoverDescribe !== null">{{ruleForm.turnoverDescribe}}</span>
                <span v-else>暂无</span>
                <p></p>
              </div>
              <div class="tableBox1" v-else>
                <p>注册资金(万) : </p>
                <el-input type="number" :maxlength="8" v-model="ruleForm.capital" size="mini" placeholder="请输入"></el-input>
                <p>年营业额(万) : </p>
                <el-select v-model="ruleForm.turnover" placeholder="请选择" size="mini">
                  <el-option :label="item.valueDescribe" :value="item.value" v-for="(item, index) in yearTurnoverList" v-bind:key="index"></el-option>
                </el-select>
                <p></p>
              </div>
              <!-- ~~~ -->
              <div class="tableBox1" v-if="controlEditInfo === false">
                <p>联系人 : </p>
                <span class="textHidden">{{ruleForm.contact === '' || null ? '暂无' : ruleForm.contact}}</span>
                <p>联系电话 : </p>
                <span>{{ruleForm.tel === '' || null ? '暂无' : ruleForm.tel}}</span>
                <p></p>
              </div>
              <div class="tableBox1" v-else>
                <p>联系人 : </p>
                <el-input type="text" v-model="ruleForm.contact" size="mini" placeholder="请输入"></el-input>
                <p>联系电话 : </p>
                <el-input type="text" v-model="ruleForm.tel" size="mini" placeholder="请输入"></el-input>
                <p></p>
              </div>
              <!-- ~~~ -->
              <div class="tableBox3" style="height: 100px;" v-if="controlEditInfo === false">
                <p>企业简介 : </p>
                <span class="textHidden3" style="line-height: 33px;" v-if="ruleForm.companyProfile !== null">{{ruleForm.companyProfile}}</span>
                <span class="textHidden3" style="line-height: 33px;" v-else>暂无</span>
              </div>
              <div class="tableBox3" style="height: 100px;" v-else>
                <p>企业简介 : </p>
                <el-input style="height: 100px;" maxLength="200" type="textarea" v-model="ruleForm.companyProfile" size="mini" placeholder="请输入企业简介(不超过200字)"></el-input>
              </div>
              <!-- <div class="tableBox3"><p>经营属性 : </p><span><i v-for="(item, index) in ruleForm.businessAttributeString" :key="index">{{item}}</i></span></div> -->
            </div>
            <el-button class="keepBtn" type="primary" @click="controlEditInfo = true" size="mini" v-if="controlEditInfo === false">编 辑</el-button>
            <el-button class="keepBtn" type="primary" @click="editEnterInfo()" size="mini" v-else>保 存</el-button>
          </el-tab-pane>
          <el-tab-pane label="集团子公司" name="second" class="subCompany">
            <div>
              <p>所属母公司 : <span>{{ruleForm.mname === null ? '暂无' : ruleForm.mname}}</span><span v-if="ruleForm.mname !== null">状态 : {{ruleForm.relationStatus === 1 ? "已关联": "未关联"}}</span><el-button @click="saveRelation()">增加/切换母公司</el-button></p>
            </div>
            <el-table :data="relationList" stripe style="width: 100%" max-height="300">
              <el-table-column show-overflow-tooltip prop="companyName" label="子公司" min-width="40%" align="left"></el-table-column>
              <el-table-column show-overflow-tooltip label="状态" min-width="30%" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.status === 1">已关联</span>
                  <span v-else>未关联</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip label="操作" min-width="30%" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.status === 1" class="relButton" @click="delRelation(scope.row.id)"  >解除关联</span>
                  <span v-else class="relButton" @click="updateRelation(scope.row.id)" >确认关联</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="收货地址" name="third">
            <!-- `````````````````` -->
            <el-table :data="ruleForm.sysCompanyDeliveryAddressEntities" stripe style="width: 100%">
              <el-table-column  label="编码" min-width="10%" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.code !== controlEditAddress">{{scope.row.code}}</span>
                  <el-input v-if="scope.row.code === controlEditAddress" size="mini" class='editInput1' v-model="scope.row.code" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                </template>
              </el-table-column>
              <el-table-column  label="地点标签" min-width="12%" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.code !== controlEditAddress">{{scope.row.warehouseId}}</span>
                  <el-input v-if="scope.row.code === controlEditAddress" size="mini" class='editInput2' v-model="scope.row.warehouseId"></el-input>
                </template>
              </el-table-column>
              <!-- <el-table-column label="国家" align="center" min-width="10%" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.code !== controlEditAddress">{{scope.row.countryCode}}</span>
                  <el-input v-if="scope.row.code === controlEditAddress" size="mini" class='editInput3' v-model="scope.row.countryCode"></el-input>
                </template>
              </el-table-column> -->
              <el-table-column show-overflow-tooltip label="省市区" align="center" min-width="30%">
                <template slot-scope="scope">
                  <span v-if="scope.row.code !== controlEditAddress">{{scope.row.provinceName}}-{{scope.row.cityName}}-{{scope.row.areaName}}</span>
                  <div v-if="scope.row.code === controlEditAddress" class="editInput4">
                    <el-select v-model="scope.row.provinceCode" placeholder="请选择省份" size="mini" clearable @change="getEditCityList(scope.row, 1)">
                      <el-option :label="item.name" :value="item.code" v-for="(item, index) in editProvinceList" v-bind:key="index"></el-option>
                    </el-select>
                    <el-select v-model="scope.row.cityCode" placeholder="请选择城市" size="mini" clearable @change="getEditAreaList(scope.row, 1)">
                      <el-option :label="item.name" :value="item.code" v-for="(item, index) in editCityList" v-bind:key="index"></el-option>
                    </el-select>
                    <el-select v-model="scope.row.areaCode" placeholder="请选择地区" size="mini" clearable>
                      <el-option :label="item.name" :value="item.code" v-for="(item, index) in editAreaList" v-bind:key="index"></el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip label="详细地址" align="center" min-width="22%">
                <template slot-scope="scope">
                  <span v-if="scope.row.code !== controlEditAddress">{{scope.row.address}}</span>
                  <el-input v-if="scope.row.code === controlEditAddress" size="mini" class='editInput5' v-model="scope.row.address"></el-input>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip label="默认地址" align="center" min-width="6%">
                <template slot-scope="scope">
                  <!-- <el-switch
                    v-if="controlEditAddress === scope.row.code"
                    v-model="scope.row.isDefault"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#FEB300">
                  </el-switch>
                  <span v-else>{{scope.row.isDefault === 1 ? '默认': ''}}</span> -->
                  <el-radio :label="1" v-model="scope.row.isDefault" @change="keepAddress(scope.row, 1)"></el-radio>
                </template>
              </el-table-column>
              <el-table-column  label="操作" align="center" min-width="16%">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.code !== controlEditAddress" class="lightStyle" @click="openEditAddress(scope.row)">编辑</el-button>
                  <el-button v-if="scope.row.code === controlEditAddress" class="lightStyle" @click="keepAddress(scope.row)">保存</el-button>
                  <el-button v-if="scope.row.code === controlEditAddress" @click="controlEditAddress = -1">取消</el-button>
                  <el-button v-if="scope.row.code !== controlEditAddress" class="dangerStyle" @click="delAddress(scope.row.id)">删除</el-button>
                  <!-- <span v-if="scope.row.code !== controlEditAddress" style="cursor: pointer" @click="openEditAddress(scope.row)">编辑</span>
                  <span v-if="scope.row.code === controlEditAddress" style="cursor: pointer" @click="keepAddress(scope.row)">保存</span>
                  <span v-if="scope.row.code === controlEditAddress" style="cursor: pointer" @click="controlEditAddress = -1">取消</span>
                  <span v-if="scope.row.code !== controlEditAddress" style="cursor: pointer" @click="delAddress(scope.row.id)">删除</span> -->
                </template>
              </el-table-column>
            </el-table>
            <!-- `````````````````````` -->
            <!-- 点击添加后的输入框 -->
            <div class="inputAdress" v-if="controlAddAddress === true">
              <el-input size="mini" class='addInput1' v-model="newAddress.code" placeholder="编码"></el-input>
              <el-input size="mini" class='addInput2' v-model="newAddress.warehouseId" placeholder="地点标签"></el-input>
              <!-- <el-input size="mini" class='addInput3' v-model="newAddress.countryCode"></el-input> -->
              <div class="addInput4">
                <el-select v-model="newAddress.provinceCode" placeholder="省份" size="mini" clearable @change="getNewCityList()">
                  <el-option :label="item.name" :value="item.code" v-for="(item, index) in newProvinceList" v-bind:key="index"></el-option>
                </el-select>
                <el-select v-model="newAddress.cityCode" placeholder="城市" size="mini" clearable @change="getNewAreaList()">
                  <el-option :label="item.name" :value="item.code" v-for="(item, index) in newCityList" v-bind:key="index"></el-option>
                </el-select>
                <el-select v-model="newAddress.areaCode" placeholder="地区" size="mini" clearable>
                  <el-option :label="item.name" :value="item.code" v-for="(item, index) in newAreaList" v-bind:key="index"></el-option>
                </el-select>
              </div>
              <el-input size="mini" class='addInput5' v-model="newAddress.address" placeholder="请输入详细地址"></el-input>
              <p>
                <el-button class="addressKeepBtn" @click="addNewAddress()">保存</el-button>
                <el-button @click="controlAddAddress = false">取消</el-button>
              </p>
            </div>
            <!-- 添加新地址 -->
            <div class="addNewAddress" @click="controlNewAddress()" v-if="controlCancel === false && controlAddAddress === false">+ 添加新地址</div>
            <!-- `````````````````` -->
          </el-tab-pane>
          <el-tab-pane label="开票资料" name="fourth" class="billingInfo">
            <el-form :model="ruleForm.sysCompanyInvoiceEntity" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
              <el-form-item label="纳税人编号:" prop="taxpayerCode">
                <el-input v-model="ruleForm.sysCompanyInvoiceEntity.taxpayerCode" size="mini"></el-input>
              </el-form-item>
              <el-form-item label="开户行:" prop="bank">
                <el-input v-model="ruleForm.sysCompanyInvoiceEntity.bank" size="mini"></el-input>
              </el-form-item>
              <el-form-item label="账号:" prop="account">
                <el-input v-model="ruleForm.sysCompanyInvoiceEntity.account" size="mini"></el-input>
              </el-form-item>
              <el-form-item label="地址:" prop="address">
                <el-input v-model="ruleForm.sysCompanyInvoiceEntity.address" size="mini"></el-input>
              </el-form-item>
              <el-form-item class="keepFix" label="电话:" prop="tel">
                <el-input v-model="ruleForm.sysCompanyInvoiceEntity.tel" size="mini"></el-input>
                <el-button class="keepBtn" v-if="controlCancel === false" type="primary" @click="keepDrawerInfo(ruleForm.sysCompanyInvoiceEntity.id)" size="mini">保 存</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 修改母公司 -->
      <el-dialog title="修改母公司" :visible.sync="saveRelationDialog" v-dialogDrag>
        <el-form :model="form">
          <el-form-item label="母公司">
            <el-select v-model="mid"  placeholder="请选择母公司" filterable>
              <el-option v-for="item in motherCompanyList" :key="item.companyId" :label="item.companyName" :value="item.companyId" ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="deSelect()">取 消</el-button>
          <el-button type="primary" @click="remindDefine()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>
<script>
import { getProvinceApi, relationSearchApi, saveRationApi, relationListApi, updateRelationApi, deleteRationApi, industryListApi, insizeListApi, getEnterInfoApi, editEnterInfoApi, editInvoiceInfoApi, delAddressApi, addAddressApi, keepDrawerInfoApi, companyCancelApi, logoutApi, getSystemDisposeApi } from '@/api'
export default {
  name: 'dataUphold',
  data() {
    return {
      headers: {
        'token': window.$cookies.get('token')
      },
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem('interAddrss'),
      // 获取创建人id
      createUserId: JSON.parse(window.sessionStorage.getItem('userInfo')).createUserId,
      // 当前用户id
      userId: JSON.parse(window.sessionStorage.getItem('userInfo')).userId,
      form: '',
      activeName: 'first',
      // 企业信息表
      ruleForm: {
        sysCompanyInvoiceEntity: {},
        sysCompanyDeliveryAddressEntities: []
      },
      rules: {
        qwe: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      relationList: [],
      // 行业类型列表
      industryList: [],
      // 经营模式
      modesList: [
        { label: '生产型', value: 0 },
        { label: '贸易型', value: 1 },
        { label: '服务型', value: 2 },
        { label: '政府或其他机构', value: 3 }
      ],
      // 企业规模列表
      insizeList: [],
      // 省市区下拉框的pcode
      pcode: '000000',
      // 存储省列表
      provinceList: [],
      // 存储市列表
      cityList: [],
      // 存储区列表
      areaList: [],
      companyId: window.$cookies.get('companyId'),
      // 新增的收货地址
      newAddress: {
        id: '',
        companyId: window.$cookies.get('companyId'),
        code: null,
        warehouseId: null,
        countryCode: null,
        address: '',
        provinceCode: '',
        cityCode: '',
        areaCode: ''
      },
      // 母公司ID
      mid: '',
      // 控制修改母公司弹框
      saveRelationDialog: false,
      // 存储新增省列表
      newProvinceList: [],
      // 存储新增市列表
      newCityList: [],
      // 存储新增区列表
      newAreaList: [],
      // 控制添加收货地址
      controlAddAddress: false,
      // 控制编辑收货地址
      controlEditAddress: '',
      // 存储修改省列表
      editProvinceList: [],
      // 存储修改市列表
      editCityList: [],
      // 存储修改区列表
      editAreaList: [],
      // 判断是否显示已注销按钮
      controlCancel: false,
      // 系统参数数据(部分下拉选项数据)
      systemDisposeList: JSON.parse(window.sessionStorage.getItem('systemDispose')),
      // 年营业额
      yearTurnoverList: [],
      // 多公司列表
      moreCompanyList: [],
      // 母公司列表
      motherCompanyList: [],
      // 控制编辑
      controlEditInfo: false
    }
  },
  created() {
    this.getIndustryList()
    this.getEnterInfo()
    this.getProvince()
    this.getInsizeList()
    this.getRelationList()
    this.getMotherCompanyList('')
    // 年营业额
    this.getYearTurnoverList('YEAR_TURNOVER')
    if (this.$route.query.setTabs === 'third') {
      this.activeName = 'third'
    }
  },
  methods: {
    // 移除图片
    handleRemove() {
      this.ruleForm.logo = ''
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      this.ruleForm.logo = res.data.url
    },
    selectJump(url) {
      Utils.$emit(url, 'params')
    },
    // 输入框查询行业表信息功能
    querySearch(queryString, cb) {
      let industryList = this.industryList
      let results = queryString ? industryList.filter(this.createFilter(queryString)) : industryList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1)
      }
    },
    handleSelect(company) {
      this.ruleForm.industry = company.id
      this.ruleForm.industryDescribe = company.name
    },
    // 获取年营业额
    async getYearTurnoverList(code) {
      const { data: res } = await getSystemDisposeApi({ page: 1, size: 999 })
      this.systemDisposeList = res.data.page.list
      this.systemDisposeList.forEach(item => {
        if (item.code === code) {
          this.yearTurnoverList = item.sysCodeValueEntities
          this.yearTurnoverList.forEach(item => {
            item.value = item.value - 0
          })
        }
      })
    },
    // 获取子公司列表
    async getRelationList() {
      const { data: res } = await relationListApi()
      if (res.code !== 0) return this.$message.error('获取多公司失败!')
      this.relationList = res.data.list
      console.log(this.relationList)
    },
    // 获取行业表信息
    async getIndustryList() {
      const { data: res } = await industryListApi()
      if (res.code !== 0) return this.$message.error('获取行业类型失败!')
      this.industryList = res.data.list
      if (this.industryList.industry === 0) {
        this.industryList.industry = ''
      }
    },
    // 获取企业规模表信息
    async getInsizeList() {
      const { data: res } = await insizeListApi()
      if (res.code !== 0) return this.$message.error('获取企业规模类型失败!')
      this.insizeList = res.data.list
    },
    // 获取省份信息
    async getProvince() {
      const { data: res } = await getProvinceApi(this.pcode)
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.provinceList = res.data.list
      this.getCityList()
    },
    // 获取城市信息
    async getCityList(key) {
      if (key !== undefined) {
        this.ruleForm.cityCode = ''
        this.ruleForm.areaCode = ''
      }
      const { data: res } = await getProvinceApi(this.ruleForm.provinceCode)
      if (res.code !== 0) return this.$message.error('获取城市失败!')
      this.cityList = res.data.list
      this.getAreaList()
    },
    // 获取地区信息
    async getAreaList(key) {
      if (key !== undefined) {
        this.ruleForm.areaCode = ''
      }
      const { data: res } = await getProvinceApi(this.ruleForm.cityCode)
      if (res.code !== 0) return this.$message.error('获取地区失败!')
      this.areaList = res.data.list
    },
    // 获取企业信息
    async getEnterInfo() {
      const { data: res } = await getEnterInfoApi({ companyId: this.companyId })
      if (res.code !== 0) return this.$message.error('获取企业信息失败!')
      this.ruleForm = res.data.companyInfo
    },
    // 修改企业信息
    async editEnterInfo() {
      this.controlEditAddress = -1
      const { data: res } = await editEnterInfoApi(this.ruleForm)
      if (res.code !== 0) return this.$message.error('修改企业信息失败!')
      this.$message.success({ duration: 1000, message: '修改企业信息成功!' })
      this.getEnterInfo()
      this.controlEditInfo = false
    },
    // 修改开票信息
    async editInvoiceInfo() {
      const { data: res } = await editInvoiceInfoApi(this.ruleForm.sysCompanyInvoiceEntity)
      if (res.code !== 0) return this.$message.error('修改开票信息失败!')
      this.$message.success({ duration: 1000, message: '修改开票信息成功!' })
      this.getEnterInfo()
    },
    // 删除收货地址
    async delAddress(id) {
      const { data: res } = await delAddressApi({ id: id })
      if (res.code !== 0) return this.$message.error('删除收货地址失败!')
      this.$message.success({ duration: 1000, message: '删除收货地址成功!' })
      this.getEnterInfo()
    },
    // 获取新增省份信息
    async getNewProvince() {
      const { data: res } = await getProvinceApi(this.pcode)
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.newProvinceList = res.data.list
    },
    // 获取新增城市信息
    async getNewCityList() {
      this.newAddress.cityCode = ''
      this.newAddress.areaCode = ''
      console.log(this.newAddress.provinceCode, '??!!')
      const { data: res } = await getProvinceApi(this.newAddress.provinceCode)
      if (res.code !== 0) return this.$message.error('获取城市失败!')
      this.newCityList = res.data.list
    },
    // 获取新增地区信息
    async getNewAreaList() {
      this.newAddress.areaCode = ''
      console.log(this.newAddress.cityCode, '????')
      const { data: res } = await getProvinceApi(this.newAddress.cityCode)
      if (res.code !== 0) return this.$message.error('获取地区失败!')
      this.newAreaList = res.data.list
    },
    // 获取母公司列表
    async getMotherCompanyList(companyName) {
      const { data: res } = await relationSearchApi(companyName)
      this.motherCompanyList = res.data.list
    },
    // 新增收货地址输入框显示隐藏
    controlNewAddress() {
      this.newAddress = {
        id: '',
        companyId: window.$cookies.get('companyId'),
        code: '',
        warehouseId: '',
        countryCode: '',
        address: '',
        provinceCode: '',
        cityCode: '',
        areaCode: ''
      }
      this.getNewProvince()
      this.controlAddAddress = true
    },
    // 新增收货地址
    async addNewAddress() {
      if (this.newAddress.code === '') return this.$message.error('编码不能为空!')
      // if (this.newAddress.warehouseId === '') return this.$message.error('地点标签不能为空!')
      if (this.newAddress.provinceCode === '' || this.newAddress.cityCode === '' || this.newAddress.areaCode === '') return this.$message.error('省市区不能为空!')
      if (this.newAddress.address === '') return this.$message.error('详细地址不能为空!')
      const { data: res } = await addAddressApi(this.newAddress)
      if (res.code !== 0) return this.$message.error('新增收货地址失败!(编码不能重复)')
      this.$message.success({ duration: 1000, message: '新增收货地址成功!' })
      this.controlAddAddress = false
      this.getEnterInfo()
    },
    // 获取修改省份信息
    async getEditProvince(result) {
      const { data: res } = await getProvinceApi(this.pcode)
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.editProvinceList = res.data.list
      this.getEditCityList(result)
    },
    // 获取修改城市信息
    async getEditCityList(result, key) {
      if (key !== undefined) {
        result.cityCode = ''
        result.areaCode = ''
      }
      const { data: res } = await getProvinceApi(result.provinceCode)
      if (res.code !== 0) return this.$message.error('获取城市失败!')
      this.editCityList = res.data.list
      this.getEditAreaList(result)
    },
    // 获取修改地区信息
    async getEditAreaList(result, key) {
      if (key !== undefined) {
        result.areaCode = ''
      }
      const { data: res } = await getProvinceApi(result.cityCode)
      if (res.code !== 0) return this.$message.error('获取地区失败!')
      this.editAreaList = res.data.list
    },
    // 控制修改收货地址输入框显示隐藏
    openEditAddress(result) {
      this.controlEditAddress = result.code
      this.getEditProvince(result)
    },
    // 修改收货地址
    async keepAddress(result, val) {
      console.log(result)
      if (result.provinceCode === '' || result.cityCode === '' || result.areaCode === '') return this.$message.error('省市区不能为空!')
      if (result.address === '') return this.$message.error('详细地址不能为空!')
      const { data: res } = await addAddressApi(result)
      if (res.code !== 0) return this.$message.error('修改收货地址失败!')
      if (val === 1) {
        this.$message.success({ duration: 1000, message: '修改默认地址成功!' })
      } else {
        this.$message.success({ duration: 1000, message: '修改收货地址成功!' })
      }
      this.controlEditAddress = -1
      this.getEnterInfo()
    },
    // 保存开票信息
    async keepDrawerInfo(id) {
      const { data: res } = await keepDrawerInfoApi({
        id: id,
        taxpayerCode: this.ruleForm.sysCompanyInvoiceEntity.taxpayerCode,
        companyId: this.companyId,
        bank: this.ruleForm.sysCompanyInvoiceEntity.bank,
        account: this.ruleForm.sysCompanyInvoiceEntity.account,
        tel: this.ruleForm.sysCompanyInvoiceEntity.tel,
        address: this.ruleForm.sysCompanyInvoiceEntity.address
      })
      if (res.code !== 0) return this.$message.error('保存开票信息失败!')
      this.$message.success({ duration: 1000, message: '修改开票信息成功!' })
      this.getEnterInfo()
    },
    // 企业注销
    companyCancel() {
      this.$confirm('此操作将永久注销该企业, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await companyCancelApi(this.companyId)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.controlCancel = true
        this.$message.success({ duration: 1000, message: '注销成功!' })
        this.logout()
      }).catch(() => {
      })
    },
    // 确认关联
    updateRelation(id) {
      this.$confirm('确定要关联吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await updateRelationApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        console.log(res)
        this.$message.success({ duration: 1000, message: '关联成功!' })
        this.getRelationList()
      }).catch(() => {
      })
    },
    // 解除关联
    delRelation(id) {
      this.$confirm('确定要解除关联吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await deleteRationApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        console.log(res)
        this.$message.success({ duration: 1000, message: '解除成功!' })
        this.getRelationList()
      }).catch(() => {
      })
    },
    async saveRation() {
      const { data: res } = await saveRationApi(this.mid)
      if (res.code !== 0) return this.$message.error('修改母公司失败!')
      this.$message.success({ duration: 1000, message: '修改母公司成功!' })
      this.getEnterInfo()
    },
    // 修改母公司时触发
    saveRelation() {
      this.saveRelationDialog = true
    },
    // 取消修改
    deSelect() {
      this.mid = ''
      this.saveRelationDialog = false
    },
    // 用户确认
    remindDefine() {
      this.saveRation()
      this.mid = ''
      this.saveRelationDialog = false
    },

    // 退出登录(企业注销专用)
    async logout() {
      const { data: res } = await logoutApi()
      if (res.code !== 0) return this.$message.error('退出登录失败!')
      window.$cookies.remove('token')
      window.sessionStorage.removeItem('userInfo')
      window.$cookies.remove('buyId')
      window.$cookies.remove('companyId')
      window.sessionStorage.removeItem('companyNum')
      window.sessionStorage.removeItem('editableTabs')
      window.sessionStorage.removeItem('editableTabsValue')
      this.$cookies.remove('auto')
      this.$router.replace('/login')
    }
  }
}
</script>
<style scoped lang='less'>
.crumbs {
  margin: -15px -15px 16px;
  padding-left: 30px;
  height: 30px;
  width: 100%;
  background-color: #fff;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  .el-breadcrumb {
    height: 30px;
    line-height: 30px;
    font-size: 15px;
  }
}
.data_uphold header {
  display: flex;
  height: 238px;
  background-color: #fff;
  .num_info {
    width: 572px;
    h5 {
      margin: 14px 20px;
      font-size: 18px;
      font-weight: 400;
    }
    .info_l, .info_r {
      display: inline-block;
      margin-left: 54px;
      width: 150px;
      height: 150px;
      overflow: hidden;
    }
    p {
      margin: 5px 0 30px;
      font-size: 14px;
      font-weight: 300;
      span {
        margin-right: 45px;
        color: rgba(103, 102, 110, 1);
      }
    }
    .info_l {
      img {
        display: block;
        width: 150px;
      }
      button {
        margin-left: 15px;
        width: 130px;
      }
    }
    .info_r {
      padding-left: 10px;
      width: 250px;
      p span{
        display: inline-block;
        text-align: right;
        padding-right: 10px;
        width: 70px;
      }
    }
  }
}
.vline {
  margin-top: 87px;
  width: 1px;
  height: 100px;
  background-color: rgba(198, 198, 198, 1);
}
.company_home {
  flex: 1;
  margin-left: 70px;
  padding-top: 50px;
  p {
    margin: 5px 0 30px;
    font-size: 13px;
    span {
      margin-right: 45px;
      color: rgba(103, 102, 110, 1);
    }
  }
}
.license {
  flex: 1;
  position: relative;
  .cancel {
    position: absolute;
    top: 17px;
    right: 49px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    img {
      width: 36px;
    }
  }
}
// 主体部分
.main {
  margin: 10px;
  padding: 5px 8px;
  min-height: 520px;
  max-height: 625px;
  background-color: #fff;
  .enterInfo {
    position: relative;
    padding: 20px 0 100px;
    /deep/ .cardBox {
      margin-left: calc(50% - 500px);
      width: 100%;
      background:rgba(255,255,255,1);
      border-radius:3px;
      overflow: auto;
      .tableBox1, .tableBox2, .tableBox3 {
        display: flex;
        width: 1000px;
        height: 52px;
        border-bottom: 1px solid #ECECEC;
        line-height: 52px;
        color: #333333;
        p {
          padding: 0 20px;
          width: 20%;
          border-left: 1px solid #ECECEC;
          border-right: 1px solid #ECECEC;
          background-color: #F9F9F9;
          box-sizing: border-box;
          text-align: right;
        }
        >span {
          padding: 0 20px;
          width: 20%;
          box-sizing: border-box;
          text-align: left;
        }
        >.el-input, .el-autocomplete, .el-select, .el-textarea {
          width: 20%;
          box-sizing: border-box;
          border-bottom: 1px solid #00C7C7;
          .el-input__inner {
            padding: 0 20px;
            width: 100%;
            height: 48px;
            border: 0;
            color: #333333!important;
            font-size: 15px!important;
          }
        }
        .addressInp {
          border-right: 1px solid #ECECEC;
        }
      }
      .tableBox1:nth-child(1) {
        position: relative;
        border-top: 1px solid #ECECEC;
        .imgBox {
          position: absolute;
          top: 0;
          right: 1px;
          display: flex;
          flex-direction: column;
          width: 198px;
          height: 370px;
          background-color: #FFFFFF;
          .el-image:nth-child(1) {
            height: 158px;
            border-bottom: 1px solid #ECECEC;
            .image-slot {
              width: 100%;
              height: 100%;
              background-color: #F5F7FA;
              font-size: 16px;
              text-align: center;
              line-height: 160px;
              color: #C0C4CC;
            }
          }
          .el-image:nth-child(2) {
            flex: 1;
            .image-slot {
              width: 100%;
              height: 100%;
              background-color: #F5F7FA;
              font-size: 16px;
              text-align: center;
              line-height: 210px;
              color: #C0C4CC;
            }
          }
          .avatar-uploader{
            width: 198px;
            height: 158px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            background: url('../../assets/img/defaultLogo.png') no-repeat;
            background-size: 100% 100%;
            vertical-align: top;
          }
          .avatar-uploader-icon{
            font-size: 0;
            color: #fff;
            width: 198px;
            height: 158px;
            line-height: 158px;
            text-align: center;
          }
          .avatar-uploader-icon:hover{
            font-size: 28px;
            background-color: rgba(0, 0, 0, .3);
          }
          .avatar {
            position: relative;
            width: 198px;
            height: 158px;
            display: block;
          }
          .el-upload-action {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 198px;
            height: 158px;
            font-size: 0;
            color: #fff;
            text-align: center;
            line-height: 158px;
          }
          .el-upload-action:hover {
            font-size: 20px;
            background-color: #000;
            background-color: rgba(0, 0, 0, .3)
          }
        }
      }
      .tableBox2, .tableBox3 {
        p {
          width: 20%;
        }
        >span {
          width: 60%;
        }
      }
      .tableBox3 {
        span {
          width: 80%;
          border-right: 1px solid #ECECEC;
          i {
            margin-right: 11px;
            padding: 2px 7px;
            display: inline-block;
            height: 20px;
            background-color: #3AABC2;
            border-radius: 2px 2px 2px 2px;
            color: #FFFFFF;
            line-height: 20px;
          }
        }
        .el-input {
          width: 80%;
        }
        .el-textarea {
          width: 80%;
          .el-textarea__inner {
            padding: 16px 20px;
            height: 100px;
            border-left: 0;
            border-top: 0;
            font-size: 16px;
            color: #333333;
          }
        }
      }
    }
    >.keepBtn {
      position: absolute;
      top: 525px!important;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .el-form {
    margin-top: 20px;
  }
  .el-table::before {
    background-color: #fff;
  }
  /deep/ .el-tabs__content {
    overflow: visible;
  }
  /deep/ .el-tabs__item {
    padding: 0;
    width: 100px;
    text-align: center;
  }
  .el-tab-pane {
    margin-left: 20px;
    // span {
    //   display: inline-block;
    //   margin: 0 5%;
    // }
    // /deep/ .el-form-item__content {
    //   padding-left: 0;
    // }
    // /deep/ .el-input  {
    //   height: 38px;
    //   width: 424px;
    //   .el-input__inner {
    //     height: 38px;
    //     border: 1px solid #E6E6E6;
    //     ::placeholder {
    //       color: rgba(165, 165, 171, 1);
    //     }
    //   }
    // }
    .keepFix {
      position: relative;
      margin-bottom: 120px;
    }
    .keepBtn {
      position: absolute;
      top: 60px;
      right: 25px;
      width: 100px!important;
      height: 40px!important;
      font-size: 16px;
      border-radius: 6px;
      color: #fff;
    }
    .keepBtn:hover {
      background-color: rgba(0,199,199, .5);
      border: 1px solid rgba(127,227,227, 1);
    }
    .editInput1 {
      margin: 0 5%;
      width: 90%;
    }
    .editInput2, .editInput3, .editInput5 {
      width: 90%;
    }
    .editInput4 {
      display: inline-block;
      .el-select {
        margin: 0 1%;
        width: 31.3%;
        /deep/ .el-input {
          width: 100%;
        }
      }
    }
    .el-button {
      padding: 0;
      width: 60px;
      height: 26px;
      border-radius: 3px;
      line-height: 0;
    }
    .inputAdress {
      height: 48px;
      line-height: 48px;
      border-top: 1px solid rgba(235,238,245);
      border-bottom: 1px solid rgba(235,238,245);
      /deep/ .el-input__inner {
        height: 26px;
      }
      .addInput1 {
        margin: 0 1%;
        width: 8%;
      }
      .addInput2 {
        margin: 0 1%;
        width: 11.5%;
      }
      .addInput3 {
        margin: 0 1%;
        width: 8%;
      }
      .addInput4 {
        display: inline-block;
        width: 30%;
        .el-select {
          margin: 0 1%;
          width: 31.3%;
          /deep/ .el-input {
            width: 100%;
          }
        }
      }
      .addInput5 {
        margin: 0 1%;
        width: 28%;
      }
      p {
        display: inline-block;
        box-sizing: border-box;
        width: 16.4%;
        padding: 0 10px;
        text-align: center;
        span {
          display: inline-block;
          text-align: center;
          font-size: 13px;
          color: #FEB300;
          cursor: pointer;
        }
        .el-button {
          padding: 0;
          width: 60px;
          height: 26px;
          border-radius: 3px;
          line-height: 0;
          border: 1px solid rgba(196,198,206, 1);
          color: rgba(90, 98, 119, 1);
        }
        .el-button:hover {
          border: 1px solid rgba(196,198,206, .5);
          background-color: #fff;
          color: rgba(90, 98, 119, .5);
        }
        .addressKeepBtn {
          border: 1px solid rgba(130,227,227, 1);
          background-color: rgba(206, 244, 244, 1);
          color: rgba(0, 199, 199, 1);
        }
        .addressKeepBtn:hover {
          border: 1px solid rgba(130,227,227, .5);
          background-color: rgba(206, 244, 244, .5);
          color: rgba(0, 199, 199, .5);
        }
      }
    }
    .addNewAddress {
      height: 32px;
      background-color: rgba(206, 244, 244, 1);
      border-top: 1px solid#EBEEF5;
      line-height: 32px;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      color: rgba(21, 203, 203, 1);
      cursor: pointer;
    }
    .relButton {
      color: #54a5ff;
      cursor: pointer;
    }
  }
  .subCompany {
    padding-top: 20px;
    p {
      padding: 0 22px;
      height: 34px;
      background-color: rgba(248, 250, 252, 1);
      color: rgba(103, 102, 110, 1);
      line-height: 34px;
      font-size: 14px;
      span {
        margin: 0 30px 0 0;
        padding: 0 13px;
        color: #000;
      }
      .el-button {
        margin-left: 56px;
        height: 30px;
        width: 120px;
        background-color: rgba(0, 199, 199, 1);
        border: 1px solid rgba(248, 250, 252, 1);
        border-radius: 3px;
        color: #fff;
        font-size: 13px;
      }
    }
  }
}
.el-form-item {
  display: inline-block;
  margin-bottom: 20px;
}
/deep/ .el-form-item__error {
  top: 34px;
  left: -84px;
}
// 开票资料
/deep/ .billingInfo .el-form .el-form-item .el-input {
  width: 424px;
}
</style>
