// 收货单修改
<template>
  <div class="receiptCheck">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/receiptCheck' }">收货单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">编辑收货单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="purchaseEntryContent">
        <nav>
            <div class="navBox">
                <p>发货单编号：
                   <span>{{ sellList.code }}</span>
                </p>
                <p>供应商名称：
                   <span>{{ sellList.sellName }}</span>
                </p>
                <p>收货地址：
                    <span>{{ sellList.deliveryAddressName }}</span>
                </p>
                <el-form :model="updateData" :rules="rules" ref="ruleForm"  class="demo-ruleForm">
                  <el-form-item label="质检人：" prop="qualityTestingBy">
                    <el-select v-model="updateData.qualityTestingBy" filterable placeholder="请选择质检人">
                      <el-option v-for="item in testList"
                        :key="item.userId"
                        :label="item.name"
                        :value="item.userId"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="质检日期：" prop="qualityTestingTime">
              <el-date-picker v-model="updateData.qualityTestingTime" type="datetime" placeholder="申请日期选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
                  </el-form-item>
                  <el-form-item  v-if="states===1" label="收货仓库：" prop="warehouseId">
                    <el-select v-model="updateData.warehouseId" placeholder="请选择收货仓库">
                      <el-option v-for="item in storeHouseList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
               </el-form>
          </div>
        </nav>
        <main>
          <h5>质检明细</h5>
          <el-table v-if="list.length !== 0"
      :data="list"
      style="width: 100%">
      <el-table-column
      label="行号"
      type="index"
      width="50">
    </el-table-column>
      <el-table-column
        prop="productName"
        align="center"
        label="产品名称"
        width="180">
    </el-table-column>
    <el-table-column
    align="center"
    prop="unitName"
    label="单位">
</el-table-column>
<el-table-column
align="center"
prop="productSpecs"
label="规格">
</el-table-column>
<el-table-column
align="center"
prop="number"
label="发货数量">
</el-table-column>
<el-table-column
prop="takeDeliveryNumber"
align="center"
label="实际到货数量">
<template slot-scope="scope">
    <el-input type="text"  v-model="scope.row.takeDeliveryNumber"  @input="takeDeliveryNumberFn(scope.row.number-scope.row.disqualificationNumber,scope.row.takeDeliveryNumber,scope.row.id)" :pattern="pattern"></el-input>
</template>
</el-table-column>
<el-table-column
prop="disqualificationNumber"
align="center"
label="不合格数量">
<template slot-scope="scope">
    <el-input type="text" v-model="scope.row.disqualificationNumber" @input="missedDeliveryNumberFn(scope.row.number-scope.row.takeDeliveryNumber,scope.row.disqualificationNumber,scope.row.id)" :pattern="pattern"></el-input>
</template>
</el-table-column>
<el-table-column
prop="missedDeliveryNumber"
align="center"
label="缺货数量">
<template slot-scope="scope">
  <span>{{ (scope.row.number-scope.row.takeDeliveryNumber-scope.row.disqualificationNumber).toFixed(2) }}</span>
</template>
</el-table-column>
</el-table>
<div v-else class="emptyBg">
    <img src="../../assets/img/notData.png" style="width:30%; margin-top: -2%;">
    <span style="display: block; margin-top: -5%">暂无数据</span>
</div>
        </main>
        <footer>
            <el-button type="primary" @click="confirmReceipt">确认收货</el-button>
            <el-button  v-if="states===1" type="primary" @click="confirmStorage">确认收货并入库</el-button>
        </footer>
   </div>
</div>
</template>
<script>
import { addEntryApi, storeHouseListAllApi, purchaseMatterListApi, confirmReceiptDetailApi, getUserManageApi, confirmReceiptApi, moduleApi, replenishmentConfirmReceiptApi } from '@/api'
export default {
  name: 'receipCheckDetail',
  data() {
    return {
      defaultDate: '',
      // 收货单修改
      // 正则
      pattern: /^\d$/,
      // 质检人id
      // 收货单修改参数对象
      updateData: {
        qualityTestingTime: '',
        qualityTestingBy: '',
        warehouseId: ''
      },
      testData: {
        companyId: '',
        name: '',
        page: 1,
        size: 999,
        state: '',
        isActivate: '',
        deptId: ''
      },
      testList: [],
      // 控制弹框
      chooseOrderDialog: false,
      // 采购单传参
      orderData: '',
      addData: {
        warehouseId: '',
        type: '',
        sellId: '',
        warrantTime: '',
        warehouseWarrantLineVoList: []
      },
      // 供应商列表
      sellList: [],
      list: [],
      // 选中的项
      chooseList: [],
      // 获取仓库列表参数
      storeHouseListData: {
        page: 1,
        // 当前页条数
        size: 9999,
        code: '',
        name: '',
        enabled: '',
        businessTypes: ''
      },
      // 仓库列表
      storeHouseList: [],
      // 传参参数
      matterManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        sellId: '',
        codeAndProductName: '',
        codeAndProductNameAndProductCode: '',
        state: '0,3',
        kkstat: '',
        approveStatus: ''
      },
      // 默认质检人
      defaultName: null,
      // 产品列表
      matterManageList: [],
      // 选中的物料
      matterList: [],
      states: '',
      // 选中仓库的物料列表
      chooseHouseMatterList: [],
      rules: {
        qualityTestingBy: [
          { required: true, message: '请选择质检人', trigger: 'change' }
        ],
        qualityTestingTime: [
          { required: true, message: '请选择质检时间', trigger: 'change' }
        ],
        warehouseId: [
          { required: true, message: '请选择收货仓库', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    // this.getTimer()
    this.updateData.id = this.$route.query.id
    this.testData.companyId = this.$route.query.companyId
    this.entrepotList()
    this.qualityTestingList()
    this.sellerList()
    this.getModuleList()
  },
  mounted() {

    // if (this.testList.length > 0) {
    // }
    // console.log(this.testList)
  },
  activated () {
  },
  computed: {
  },
  methods: {
    // 默认当天日期
    // 获取当前年月日星期几几点几分几秒并打印
    getTimer() {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      const hours = String(currentDate.getHours()).padStart(2, '0')
      const minutes = String(currentDate.getMinutes()).padStart(2, '0')
      const seconds = String(currentDate.getSeconds()).padStart(2, '0')
      const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      return currentDateTime
    },
    async getModuleList() {
      const { data: res } = await moduleApi()
      if (res.code === 0) {
        this.states = res.data.data[1].state
      }
    },
    // 不合格
    missedDeliveryNumberFn(max, val, id) {
      let temp = val.toString()
      temp = temp.replace(/。/g, '.')
      temp = temp.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, '') // 验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, '') // 只保留第一个, 清除多余的
      temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // eslint-disable-next-line no-useless-escape
      temp = temp.replace(/^(\-)*(\d+)\.(\d{1,2}).*$/, '$1$2.$3')
      this.list.forEach((item, i) => {
        if (item.id === id) {
          if (temp > max) {
            this.list[i].disqualificationNumber = max
          } else if (val === '') {
            this.list[i].disqualificationNumber = 0
          } else {
            this.list[i].disqualificationNumber = temp
          }
        }
      })
    },
    // 发货数判断
    takeDeliveryNumberFn(max, val, id) {
      let temp = val.toString()
      temp = temp.replace(/。/g, '.')
      temp = temp.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, '') // 验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, '') // 只保留第一个, 清除多余的
      temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // eslint-disable-next-line no-useless-escape
      temp = temp.replace(/^(\-)*(\d+)\.(\d{1,2}).*$/, '$1$2.$3')
      this.list.forEach((item, i) => {
        if (item.id === id) {
          if (temp > max) {
            this.list[i].takeDeliveryNumber = max
          } else if (val === '') {
            this.list[i].takeDeliveryNumber = 0
          } else {
            this.list[i].takeDeliveryNumber = temp
          }
        }
      })
    },
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 确认收货
    confirmReceipt() {
      console.log(this.updateData)
      this.updateData.takeDeliveryType = 1
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // console.log(this.updateData)
          if (this.updateData.type === 1) {
            const { data: res } = await confirmReceiptApi(this.updateData)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('确认收货成功!')
            setTimeout(() => {
              this.$router.back()
            }, 300)
          } else {
            const { data: res } = await replenishmentConfirmReceiptApi(this.updateData)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('确认收货成功!')
            setTimeout(() => {
              this.$router.back()
            }, 300)
          }
        } else {
          console.log('error')
        }
      })
    },
    async confirmStorage() {
      this.updateData.takeDeliveryType = 2
      if (this.updateData.type === 1) {
        const { data: res } = await confirmReceiptApi(this.updateData)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('确认收货并入库成功!')
        setTimeout(() => {
          this.$router.back()
        }, 300)
      } else {
        const { data: res } = await replenishmentConfirmReceiptApi(this.updateData)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('确认收货并入库成功!')
        setTimeout(() => {
          this.$router.back()
        }, 300)
      }
    },
    // 获取收货仓库
    async entrepotList() {
      const { data: res } = await storeHouseListAllApi(this.storeHouseListData)
      this.storeHouseList = res.data.page.list
      // this.updateData.warehouseId = this.getDefaultDate()
      this.storeHouseList.forEach(item => {
        if (item.ifDefault === true) {
          this.updateData.warehouseId = item.id
        }
      })
    },
    // 获取质检人信息
    async qualityTestingList() {
      const { data: res } = await getUserManageApi(this.testData)
      this.testList = res.data.page.list
      this.defaultName = JSON.parse(sessionStorage.getItem('userInfo'))
      this.testList.forEach(item => {
        if (item.userId === this.defaultName.userId) {
          this.updateData.qualityTestingBy = item.userId
        }
      })
    },

    // ------------------------------------------------------------
    // 新增入库单
    addEntry() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const { data: res } = await addEntryApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('新增入库单成功!')
          setTimeout(() => {
            this.$router.back()
          }, 300)
        } else {
          return false
        }
      })
    },
    // 收货单修改列表
    async sellerList() {
      const { data: res } = await confirmReceiptDetailApi({ id: this.$route.query.id })
      console.log(res.data.data)
      this.sellList = res.data.data
      this.list = res.data.data.lineEntityList
      this.updateData = res.data.data
      // console.log(this.getTimer())
      this.updateData.qualityTestingTime = this.getTimer()
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    },
    // 获取采购单产品列表
    async getMatterManageList() {
      const { data: res } = await purchaseMatterListApi(this.matterManageData)
      if (res.code !== 0) return this.$message.error(res.msg)
      let newMatterManageList = res.data.data.list
      // 去除列表重复项
      if (this.addData.warehouseWarrantLineVoList.legnth !== 0) {
        this.addData.warehouseWarrantLineVoList.forEach(item => {
          newMatterManageList.forEach((val, index) => {
            if (item.productId === val.productId) {
              newMatterManageList.splice(index, 1)
            }
          })
        })
      }
      this.matterManageList = newMatterManageList
    },
    // 打开添加弹框
    openAddDialog() {
      // 先判断选了供应商
      if (this.addData.sellId === '') return this.$message.error('请先选择供应商!')
      this.matterManageData.sellId = this.addData.sellId
      this.getMatterManageList()
      this.chooseOrderDialog = true
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    }

  }
}

</script>
<style scoped lang='less'>
.receiptCheck {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .purchaseEntryContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 180px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 20px;
      padding: 20px;
    //   height: 92px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .navBox {
        // display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.467);
        p{
            width: 100%;
            display: flex;
            margin: 25px;
            span{
                color: rgba(0, 0, 0, 0.843);
            }
        }
       }
    }
    main {
      position: relative;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        height: calc(100vh - 470px);
        overflow-y: auto;
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              color: rgba(0, 0, 0, 0.60);
            }
            >p {
              position: relative;
              height: 24px;
              width: 100%;
              font-size: 14px;
              >span {
                position: absolute;
                left: 40%;
                display: inline-block;
                width: 220px;
                color: rgba(0, 0, 0, 0.60);
              }
              i {
                float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 100%;
              .el-form-item__content {
                width: 100%;
                width: calc(100% - 102px);
                .el-input {
                  width: 100%;
                }
              }
            }
            .showNumBox {
              position: absolute;
              right: 10px;
              height: 32px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 12px;
              line-height: 32px;
              font-weight: 400;
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
