//  订单通知
<template>
    <div class="manageCenter_content">
      <!-- 卡片视图区 -->
      <el-card>
        <!-- 状态选择区 -->
        <el-row style="height: 84px;">
          <p style="margin-bottom: 16px; font-size: 16px; font-weight: bold">订单通知</p>
          <el-menu :default-active="activeIndex" mode="horizontal">
            <el-menu-item index="1" @click="switchData('')">全部</el-menu-item>
            <el-menu-item index="2" @click="switchData(0)">平台通知</el-menu-item>
            <el-menu-item index="3" @click="switchData(1)">内部通知</el-menu-item>
            <el-menu-item index="4" @click="switchData(2)">外部通知</el-menu-item>
          </el-menu>
        </el-row>
        <!-- 内容搜索区 -->
        <div class="search">
          <el-input placeholder="请输入标题名称" v-model="manageListData.messageTitle" @input="getMessageList()"></el-input>
          <i @click="getMessageList()" class="iconfont icon-search"></i>
          <el-button @click="routerJump('manageManage')">通知管理</el-button>
        </div>
        <!-- 物料定义表格区 -->
        <el-row>
          <ul v-if="tableData.length !== 0" class="manageContent">
            <li v-for="(item, index) in tableData" :key="index" @click="examineManage(item)">
              <h5 v-if="item.messageStatus === 0" style="color: rgba(18, 62, 131, 1);">{{item.messageTitle}}<i>{{item.messageCategoryName}}</i></h5>
              <h5 v-else>{{item.messageTitle}}<i>{{item.messageCategoryName}}</i></h5>
              <span class="textHidden2">{{item.messageContent}}</span>
              <p><span>{{item.publishName}}</span><span>{{item.updateTime}}</span></p>
            </li>
          </ul>
          <div v-else class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: 0">暂无数据</span>
          </div>
          <!-- <el-table tooltip-effect="dark" ref="tableHeight" :height="tableHeight" :data="tableData" style="width: 100%" :default-sort = "{prop: 'date', order: 'descending'}" @row-click="examineManage">
              <el-table-column show-overflow-tooltip prop="messageCategoryName" label="类型" min-width="18%">
                <template slot-scope="scope">
                  <i v-if="scope.row.messageStatus === 0" style="color: rgba(0, 163, 255, 1);">{{scope.row.messageCategoryName}}</i>
                  <i v-else>{{scope.row.messageCategoryName}}</i>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip label="标题" min-width="60%">
                <template slot-scope="scope">
                  <i v-if="scope.row.messageStatus === 0" style="color: rgba(0, 163, 255, 1);"><i class="el-icon-paperclip"></i>{{scope.row.messageTitle}}</i>
                  <i v-else>{{scope.row.messageTitle}}</i>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="updateTime" label="发布人" min-width="22%" align="center">
                <template slot-scope="scope">
                  <i v-if="scope.row.messageStatus === 0" style="color: rgba(0, 163, 255, 1);">{{scope.row.publishName}}</i>
                  <i v-else>{{scope.row.publishName}}</i>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="updateTime" label="日期" min-width="22%">
                <template slot-scope="scope">
                  <i v-if="scope.row.messageStatus === 0" style="color: rgba(0, 163, 255, 1);">{{scope.row.updateTime}}</i>
                  <i v-else>{{scope.row.updateTime}}</i>
                </template>
              </el-table-column>
          </el-table> -->
          <el-checkbox style="margin-top: 5px;" v-model="isShowChoose" @change="getMessageList">显示已读</el-checkbox>
        </el-row>
        <el-row class="layout_row">
          <!-- 分页功能 -->
          <el-pagination
            layout="prev, pager, next"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            :page-size="manageListData.size"
            :total="total">
          </el-pagination>
        </el-row>
        <!-- 查看消息弹出框 -->
        <template>
          <el-dialog title="通知详情" :visible.sync="dialogVisible" :close-on-click-modal="false" class="examineDialog" v-dialogDrag>
            <h5>{{messageDetail.messageTitle}}</h5>
            <div class="ql-editor contentBox" v-html="messageDetail.messageContent"></div>
            <div class="enclosureBox" v-if="messageDetail.attachmentEntityList.length !== 0">
              <p>附件</p>
              <ul>
                <li v-for="(item, index) in messageDetail.attachmentEntityList" :key="index">
                  <div class="prefixBox">{{'.' + item.attachmentType}}</div>
                  <a :href="item.attachmentPath">{{item.attachmentName + '.' + item.attachmentType}}</a>
                  <i class="iconfont icon-download1"></i>
                </li>
              </ul>
            </div>
          </el-dialog>
        </template>
      </el-card>
    </div>
</template>
<script>
import { otherSystemListApi, getMessageListApi, examineManageApi } from '@/api'
export default {
  name: 'manageCenter',
  data() {
    return {
      // 获取分页数据
      getUserListData: [],
      // 获取自定义分类数据
      matterCustomData: [],
      // 获取表格数据
      tableData: [],
      // 当前激活状态栏
      activeIndex: '1',
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 10,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 展示参数
      manageListData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 15,
        messageTitle: '',
        messageCategory: '',
        messageStatus: '0',
        messageType: 1
      },
      // 存储当前消息详情
      messageDetail: {
        attachmentEntityList: []
      },
      // 获取当前浏览器的宽度
      screenWidth: '',
      // 控制消息查看弹出框
      dialogVisible: false,
      // 控制是否显示已读
      isShowChoose: true,
      // 控制是否显示消息管理按钮
      showMessageManage: true
    }
  },
  created() {
    this.getMessageList()
    this.otherSystemList()
  },
  mounted() {
  },
  methods: {
    // 路由跳转
    routerJump(url) {
      if (this.showMessageManage === false) return this.$message.error('您所属岗位没有权限进入消息管理页面!')
      this.$router.push(url)
    },
    // 获取消息展示数据
    async getMessageList(reset) {
      if (reset !== 'reset') {
        this.manageListData.page = 1
      }
      if (this.isShowChoose === false) {
        this.manageListData.messageStatus = '0'
      } else {
        this.manageListData.messageStatus = ''
      }
      const { data: res } = await getMessageListApi(this.manageListData)
      if (res.code !== 0) return this.$message.error('获取消息失败')
      // 获取表单数据
      this.tableData = res.data.page.list
      // 获取当前页面
      this.currentPage = res.data.page.currPage
      // 获取页面总页数
      this.totalNum = res.data.page.totalPage
      // 获取总条数
      this.total = res.data.page.totalCount
      this.tableData.forEach((item, index) => {
        item.messageContent = this.removeTags(item.messageContent)
      })
    },
    // 去掉富文本框中的标签
    removeTags(str) {
      if ((str===null) || (str==='')) {
        return false;
      } else {
        str = str.toString();
      }
      return str.replace(/(<([^>]+)>)/ig,"")
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.manageListData.size = val
      this.manageListData.page = 1
      this.getMessageList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.manageListData.page = val
      this.currentPage = val
      this.getMessageList('reset')
    },
    // 点击切换展示数据
    switchData(status) {
      this.manageListData.messageCategory = status
      this.getMessageList()
    },
    // 查看消息详情
    async examineManage(result) {
      const { data: res } = await examineManageApi(result.userMessageId)
      this.messageDetail = res.data.data
      this.dialogVisible = true
      this.getMessageList()
    },
    // 判断是否展示消息管理
    async otherSystemList() {
      const { data: res } = await otherSystemListApi({ type: '3', roleId: JSON.parse(window.sessionStorage.getItem('userInfo')).roleId })
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res.data.page.list[0].enabled)
      this.showMessageManage = res.data.page.list[0].enabled
    }
  }
}
</script>
<style scoped lang='less'>
.el-card {
  margin: 20px;
  height: calc(100vh - 105px);
  width: calc(100% - 40px);
  background:rgba(255,255,255,1);
  border-radius:8px;
  /deep/ .el-card__body {
    padding: 20px;
  }
  .el-menu--horizontal {
    height: 30px;
    width: 343px;
    background-color: rgba(18, 62, 131, .05);
    border-radius: 4px;
    border: 0;
    >.el-menu-item {
      padding: 0 20px;
      height: 30px;
      font-size: 13px;
      line-height: 30px;
      color: rgba(18, 62, 131, 1);
    }
    .el-menu-item:hover {
      color: rgba(18, 62, 131, 1);
      background-color: rgba(18, 62, 131, .2);
    }
    .el-menu-item.is-active {
      border-bottom: 0;
      color: #fff;
      background-color: rgba(18, 62, 131, 1);
      border-radius: 3px;
    }
    .el-menu-item.is-active:hover {
      background-color: rgba(18, 62, 131, .8);
    }
  }
  .search {
    position: absolute;
    top: 82px;
    right: 35px;
    height: 30px;
    width: 380px;
    .el-button {
      padding: 0;
      display: inline-block;
      width: 85px;
      height: 30px;
      line-height: 30px;
      background-color: rgba(18, 62, 131, 1);
      color: #fff;
      font-size: 13px;
    }
    .el-button:hover {
      background-color: rgba(18, 62, 131, .8);
    }
    .el-input {
      margin-right: 12px;
      width: 274px;
      /deep/ .el-input__inner {
        height: 30px;
      }
    }
    i {
      position: absolute;
      top: 5px;
      left: 248px;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
.layout_row {
  position: relative;
}
.el-pagination {
  position: absolute;
  top: -25px;
  right: 0;
}
.manageContent {
  width: 100%;
  height: calc(100vh - 245px);
  overflow-y: auto;
  >li {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    h5 {
      margin: 0 0 10px;
      font-size: 16px;
      i {
        margin-left: 10px;
        padding: 5px 8px;
        width: 64px;
        height: 22px;
        background: rgba(70,147,235,0.05);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(70,147,235,0.2);
        font-size: 12px;
        color: rgba(70, 147, 235, 1);
      }
    }
    >span {
      margin-bottom: 20px;
      display: block;
      width: 100%;
      overflow: hidden;
      font-size: 14px;
    }
    >p {
      margin-bottom: 0;
      span {
        margin-right: 20px;
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
      }
    }
  }
}
.emptyBg {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  img {
    height: auto;
  }
  span {
    margin-bottom: 60px;
    display: block;
    color: #ccc;
  }
}
.examineDialog {
  /deep/ .el-dialog {
    margin-top: 30px!important;
    height: 768px;
    width: 878px;
    .el-dialog__header {
      font-size: 20px;
      font-weight: 400;
    }
    .el-dialog__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 720px;
      h5 {
        margin: 0;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
      }
      span {
        font-size: 13px;
        font-weight: 400;
        color: rgba(96, 98, 102, 1);
      }
      .contentBox {
        height: 350px;
        width: 700px;
        p {
          width: 669px;
          white-space: pre-wrap;
          line-height: 25px;
        }
      }
      .enclosureBox {
        width: 685px;
        p {
          margin-bottom: 0;
          line-height: 20px;
        }
        li {
          position: relative;
          margin-top: 10px;
          height: 40px;
          width: 602px;
          border: 1px solid rgba(222, 226, 230, 1);
          border-radius: 3px;
          line-height: 38px;
          .prefixBox {
            margin: 4px 10px 4px 4px;
            display: inline-block;
            width: 48px;
            height: 30px;
            background-color: rgba(220, 222, 252, 1);
            border-radius: 3px;
            line-height: 24px;
            text-align: center;
            vertical-align: middle;
            color: rgba(114, 124, 245, 1);
            font-size: 16px;
            font-weight: 300;
          }
          a {
            position: absolute;
            left: 58px;
            display: inline-block;
            width: 544px;
            height: 40px;
            color: rgba(67, 67, 67, 1);
            cursor: pointer;
          }
          i {
            position: absolute;
            top: 1px;
            right: 20px;
            color: rgba(114, 124, 245, 1);
          }
        }
      }
    }
  }
}
</style>
