<!-- eslint-disable no-mixed-spaces-and-tabs -->
<!-- eslint-disable no-mixed-spaces-and-tabs -->
<!-- eslint-disable no-mixed-spaces-and-tabs -->
// 发货单详情
<template>
  <div class="purchaseEntry">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dispatchList' }">发货单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="purchaseEntryContent">
        <nav>
          <div>    <h5>
            发货单编号:{{ sellList.code }}
            ({{ sellList.companyName }})
            <el-button v-if="sellList.state===1" type="primary" size="mini">已发货</el-button>
            <el-button v-else type="warning" size="mini">待发货</el-button>
        </h5>
            <div class="navBox">
                <p>制单人：
                    <span>{{ sellList.userName }}</span>
                </p>
                <p>制单时间：
                    <span>{{ sellList.createTime }}</span>
                </p>
                <p>收货地址：
                    <span>{{ sellList.deliveryAddressName }}</span>
                </p>
                </div></div>
                <div>
                  <el-button type="primary" @click="$router.back()">返回</el-button>
                  <el-button type="primary" v-if="sellList.state===1" @click="print">打印</el-button>
                  <el-button type="primary" v-if="sellList.state!==1" @click="dispatchFn">发货</el-button>
                  <el-button type="primary" v-if="sellList.state === 0" @click="updateFn(sellList)">编辑</el-button>
                </div>
        </nav>
        <main>
          <h5>发货明细</h5>
          <div v-if="list.length !== 0">
            <el-table  show-summary :summary-method="getSummaries" :data="list"
      style="width: 100%">
      <el-table-column
      label="行号"
      type="index"
      width="50">
    </el-table-column>
            <el-table-column
            prop="productName"
            align="center"
            label="产品名称"
            width="180">
            </el-table-column>
            <el-table-column
            align="center"
            prop="unitName"
            label="单位">
            </el-table-column>
            <el-table-column
            align="center"
            prop="productSpecs"
            label="规格">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
                <span>{{scope.row.productNewColour === "" || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
            </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productTypeName" label="类型" min-width="80px" align="center">
        </el-table-column>
            <el-table-column
            prop="number"
            align="center"
            label="发货数量">
            </el-table-column>
            <el-table-column
            align="center"
            prop="residueNumberHistory"
            :label="typeName">
            <!-- <template slot-scope="scope">
                <span>{{scope.row.residueNumber-scope.row.number-scope.row.residueLockDelivery}}</span>
            </template> -->
            </el-table-column>
          </el-table>
          </div>
          <div v-else class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:30%; margin-top: -2%;">
          <span style="display: block; margin-top: -5%">暂无数据</span>
          </div>
          </main>
</div>
</div>
</template>
<script>
import { addEntryApi, houseMatterListApi, deliverGoodsDetailApi, ConfirmDeliveryApi, replenishmentOkApi } from '@/api'
export default {
  name: 'receipCheckDetail',
  data() {
    return {
      // 总计
      total: 0,
      // 控制弹框
      chooseOrderDialog: false,
      // 采购单传参
      orderData: '',
      addData: {
        warehouseId: '',
        type: '',
        sellId: '',
        warrantTime: '',
        warehouseWarrantLineVoList: []
      },
      // 供应商列表
      sellList: [],
      list: [],
      // 选中的项
      chooseList: [],
      // 获取仓库列表参数
      storeHouseListData: {
        page: 1,
        // 当前页条数
        size: 9999,
        code: this.$route.query.code,
        companyName: this.$route.query.companyName,
        state: this.$route.query.state
      },
      // 仓库列表
      storeHouseList: [],
      // 传参参数
      matterManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        sellId: '',
        codeAndProductName: '',
        codeAndProductNameAndProductCode: '',
        state: '0,3',
        kkstat: '',
        approveStatus: ''
      },
      // 产品列表
      matterManageList: [],
      // 选中的物料
      matterList: [],
      // 选中仓库的物料列表
      chooseHouseMatterList: [],
      hj: '合计'
    }
  },
  created() {
    this.sellerList()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 发货
    dispatchFn() {
      this.$confirm('是否确认发货', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          // console.log(result)
          if (this.sellList.type === 1) {
            const { data: res } = await ConfirmDeliveryApi(this.sellList.id)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('发货成功!')
            this.$router.back()
          } else {
            const { data: res } = await replenishmentOkApi(this.sellList.id)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('发货成功!')
            this.$router.back()
          }
        })
    },
    // 跳转到修改
    updateFn(res) {
      this.$router.push({ path: '/updateDispath', query: { id: JSON.stringify(res.id), companyID: JSON.stringify(res.companyId) } })
    },
    // 跳转到打印模版
    print () {
      this.$router.push({
        path: '/printPreview',
        query: {
          // url的参数, 类似get请求的传参
          id: this.$route.query.id
        }
      })
    },
    // 数量总计
    calculateTotal() {
      this.total = this.list.reduce((sum, product) => sum + product.deliveryNumber, 0)
    },
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 新增入库单
    addEntry() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const { data: res } = await addEntryApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('新增入库单成功!')
          setTimeout(() => {
            this.$router.back()
          }, 300)
        } else {
          return false
        }
      })
    },
    // 发货单详情
    async sellerList() {
      const { data: res } = await deliverGoodsDetailApi({ id: this.$route.query.id })
      this.sellList = res.data.data
      this.list = res.data.data.lineEntityList
      if (this.sellList.type === 1) {
        this.typeName = '剩余发货数量'
      } else {
        this.typeName = '剩余补货数量'
      }
      this.calculateTotal()
    },
    // 获取仓库物料列表
    async houseMatterList() {
      const { data: res } = await houseMatterListApi({ warehouseId: this.addData.warehouseId, warehouseName: '', productName: '', page: 1, size: 9999 })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.chooseHouseMatterList = res.data.page.list
      // 为列表添加对应的仓库库存数量
      if (this.addData.warehouseWarrantLineVoList.length === 0) return false
      this.addData.warehouseWarrantLineVoList.forEach((item, index) => {
        item.quantityEnd = 0
        this.chooseHouseMatterList.forEach((val, index) => {
          if (item.productId === val.productId) {
            // 存储入库前仓库数量
            item.quantityEnd = val.number
          }
        })
      })
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    },
    // 打开添加弹框
    openAddDialog() {
      // 先判断选了供应商
      if (this.addData.sellId === '') return this.$message.error('请先选择供应商!')
      this.matterManageData.sellId = this.addData.sellId
      this.getMatterManageList()
      this.chooseOrderDialog = true
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (column.property === 'number' || column.property === 'residueNumberHistory') {
          sums[index] = values.reduce((prev, curr) => {
            // // console.log(, 'aaaaa')
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + value
            } else {
              return prev
            }
          }, 0)
        }
      })
      return sums
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseEntry {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .purchaseEntryContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 180px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 20px;
      height: 92px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .navBox {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.467);
        p{
            margin: 0px .625rem 0 0;
            span{
                color: rgba(0, 0, 0, 0.843);
            }
        }
       }
    }
    main {
      position: relative;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .totalBox{
        display: flex;
        justify-content: space-between;
        height: 3.125rem;
        // line-height: 3.125rem;
        align-items: center;
      }
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        height: calc(100vh - 470px);
        overflow-y: auto;
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              color: rgba(0, 0, 0, 0.60);
            }
            >p {
              position: relative;
              height: 24px;
              width: 100%;
              font-size: 14px;
              >span {
                position: absolute;
                left: 40%;
                display: inline-block;
                width: 220px;
                color: rgba(0, 0, 0, 0.60);
              }
              i {
                float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 100%;
              .el-form-item__content {
                width: 100%;
                width: calc(100% - 102px);
                .el-input {
                  width: 100%;
                }
              }
            }
            .showNumBox {
              position: absolute;
              right: 10px;
              height: 32px;
              color: rgba(0, 0, 0, 0.80);
              font-size: 12px;
              line-height: 32px;
              font-weight: 400;
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
