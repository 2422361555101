// 新增产品
<template>
  <div class="addmatter">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/matterManage' }">产品管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">新增产品</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addmatterContent">
      <nav>
        <h5>新增产品</h5>
      </nav>
      <main>
        <el-form ref="ruleForm" :model="matterData" label-width="92px" :rules="rules">
          <el-form-item label="产品名称:" prop="name">
            <el-input v-model="matterData.name" placeholder="请输入产品名称"></el-input>
          </el-form-item>
          <el-form-item label="产品分类:" prop="typeId">
            <el-cascader ref="refHand" :options="matterClassList" :props="defaultData" clearable v-model="matterData.typeId" @change="handleChange" size="mini" filterable @visible-change="getMatterClass()" placeholder="请选择分类"></el-cascader>
          </el-form-item>
          <el-form-item label="产品类型:" prop="productType">
            <el-select v-model="matterData.productType" placeholder="请选择产品类型" clearable>
              <el-option label="成品" :value="0"></el-option>
              <el-option label="半成品" :value="1"></el-option>
              <el-option label="物料" :value="2"></el-option>
              <el-option label="服务" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="仓库名称:" prop="warehouseIds">
            <el-select v-model="matterData.warehouseIds" multiple filterable allow-create default-first-option placeholder="请选择仓库名称,可多选" clearable>
              <el-option
                v-for="item in storeHouseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="单位 : ">
            <el-select v-model="matterData.unitUserId" placeholder="请选择单位" size="mini" filterable clearable>
              <el-option :label="item.showName" :value="item.id" v-for="(item, index) in unitUserList" v-bind:key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="规格:" prop="specs">
            <el-input v-model="matterData.specs" placeholder="请输入规格"></el-input>
          </el-form-item>
          <el-form-item label="颜色:" prop="newColour">
            <el-input v-model="matterData.newColour" placeholder="请输入颜色"></el-input>
          </el-form-item>
          <el-form-item label="材料:" prop="newMaterials">
            <el-input v-model="matterData.newMaterials" placeholder="请输入材料"></el-input>
          </el-form-item>
          <el-form-item label="产品图片:" prop="dialogImageUrl">
            <!-- http://api.z-srm.com/ -->
            <!-- http://apisrm.bmxgj.cn/ -->
            <span>上传jpg，png，webp，JPEG类型的图片，单张5MB</span>
            <el-upload
                class="avatar-uploader"
                :action="interAddrss + 'sys/common/upload'"
                :show-file-list="false"
                :data="{uploadType: 'BUSINESS_PRODUCT'}"
                :headers="headers"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :on-remove="handleRemove">
                <img v-if="matterData.newPic" :src="matterData.newPic" class="avatar">
                <span v-if="matterData.newPic" class="el-upload-action" @click.stop="handleRemove()">
                    <i class="el-icon-delete"></i>
                </span>
                <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </main>
    </div>
    <footer>
      <el-button class="lightDeepStyle" @click="addMatter()">提交</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { unitUserListApi, addMatterApi, matterClassApi } from '@/api'
export default {
  data() {
    return {
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem('interAddrss'),
      headers: {
        'token': window.$cookies.get('token')
      },
      // 新增物料数据
      matterData: {
        code: '',
        name: '',
        typeId: '',
        productType: '',
        unitUserId: '',
        specs: '',
        newColour: '',
        newMaterials: '',
        newPic: ''
      },
      // 级联选择器分类
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'newChildList',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false,
        // 不关联父子节点
        checkStrictly: false
      },
      // 校验
      rules: {
        name: [
          { required: true, message: '产品名称不能为空!', trigger: 'blur' },
          { min: 1, max: 50, message: '最多可输入50个字符', trigger: 'blur' }
        ],
        typeId: [
          { required: true, message: '产品分类不能为空!', trigger: 'blur' }
        ],
        productType: [
          { required: true, message: '产品类型不能为空!', trigger: 'blur' }
        ]
      },
      // 仓库列表
      storeHouseList: [],
      // 单位列表
      unitUserList: [],
      // 物料分类列表
      matterClassList: []
    }
  },
  created() {
    this.getUnitUserList()
  },
  methods: {
    // 获取物料分类
    async getMatterClass() {
      const { data: res } = await matterClassApi('')
      this.matterClassList = res.data.typeList
      this.matterClassList.forEach((item, index) => {
        if (item.childList.length !== 0) {
          item.newChildList = item.childList
          item.childList.forEach((item2, index) => {
            if (item2.childList.length !== 0) {
              item2.newChildList = item2.childList
              item2.childList.forEach((item2, index) => {
                if (item3.childList.length !== 0) {
                  item3.newChildList = item3.childList
                }
              })
            }
          })
        }
      })
    },
    // 页面跳转
    routerJump () {
      this.$router.back()
    },
    // 级联选择器改变触发 (物料分类的)
    handleChange(e) {
      if (e === null) {
        this.matterData.typeId = 0
      }
      // 实现级联选择器选中文字关闭级联选择框
      if (this.$refs.refHandles) { this.$refs.refHandles.dropDownVisible = false }
      if (this.$refs.refHandle) { this.$refs.refHandle.dropDownVisible = false }
      if (this.$refs.refHand) { this.$refs.refHand.dropDownVisible = false }
    },
    // 新增物料
    addMatter() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const { data: res } = await addMatterApi(this.matterData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '保存成功!' })
          setTimeout(() => {
          this.$router.back()
        }, 300)
        } else {
          return false
        }
      })
    },
    // 移除图片
    handleRemove() {
      this.matterData.newPic = ''
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      this.matterData.newPic = res.data.url
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload(file) {
      const type = file.type === 'image/jpeg' || 'image/jpg' || 'image/webp' || 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!type) {
        this.$message.error('图片格式不正确!(只能包含jpg，png，webp，JPEG)')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过5MB!')
      }
      return type && isLt2M
    },
    // 获取单位列表
    async getUnitUserList() {
      const { data: res } = await unitUserListApi({ enabled: 1, name: '', code: '', page: 1, size: 9999 })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.unitUserList = res.data.page.list
    }
  }
};
</script>
<style lang="less" scoped>
.addmatter {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addmatterContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    background: #fff;
    border-radius: 10px;
    overflow-y: auto;
    nav {
      padding: 20px;
      display: flex;
      height: 32px;
      justify-content: space-between;
      align-items: center;
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
    }
    main {
      padding: 0 20px;
      height: calc(100vh - 250px);
      overflow-y: auto;
      .el-form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 33.33%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 92px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
            .el-cascader {
              width: 100%;
            }
          }
        }
        /deep/.el-form-item:nth-child(8) {
          width: 100%;
        }
      }
      .avatar-uploader{
        width: 120px;
        height: 120px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: url('../../assets/img/defaultPicture.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: top;
      }
      .avatar-uploader-icon{
        font-size: 0;
        color: #fff;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
      }
      .avatar-uploader-icon:hover{
        font-size: 28px;
        background-color: rgba(0, 0, 0, .3);
      }
      .avatar {
        position: relative;
        width: 120px;
        height: 120px;
        display: block;
      }
      .el-upload-action {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 120px;
        height: 120px;
        font-size: 0;
        color: #fff;
        text-align: center;
        line-height: 120px;
      }
      .el-upload-action:hover {
        font-size: 20px;
        background-color: #000;
        background-color: rgba(0, 0, 0, .3)
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
}
</style>