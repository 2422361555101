// 关联订单
<template>
  <div class='relateOrder'>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/sellerOrder' }">销售单</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/sellerOrderDetail', query: { result: this.id } }" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">相关订单</el-breadcrumb-item>
    </el-breadcrumb>
    <main>
      <div id="main"></div>
    </main>
  </div>
</template>
<script>
// echarts图
import * as echarts from "echarts";
import { relateOrderApi } from '@/api'
export default {
  data() {
    return {
      id: '',
      node1: '',
      node2: [],
      node3: '',
      node4: '',
      myChart: {},
      myChartData: [],
      myChartLink: []
    }
  },
  created() {
    this.id = this.$route.query.id
    this.node1 = '销售单:' +  this.$route.query.code 
  },
  mounted() {
    this.relateOrder().then(() => {
      this.barCharts()
    })
  },
  methods: {
    // 获取关联订单
    async relateOrder() {
      const { data: res } = await relateOrderApi({ id: this.id, roleType: 2 })
      if (res.code !== 0) return this.$message.error(res.msg)
      let newArray = res.data.list
      console.log(newArray, '6666666666666666666666666')
      newArray.forEach((item, index) => {
        if (item.type === 5 ) {
          this.node3 = item.name + ': ' + item.code
        } else if (item.type === 6) {
          this.node4 = item.name + ': ' + item.code
        } else {
          this.node2.push(item.name + ': ' + item.code)
        }
      })
      // 手动拼接data----------------------------------------
      // 一级
      this.myChartData.push({ name: this.node1, x: 200, y: 300 })
      // 二级
      if (this.node2.length !== 0) {
        this.node2.forEach((item, index) => {
          this.myChartData.push({ name: item, x: 550, y: 600/(this.node2.length - 1) * index })
        })
      }
      // 三级
      if (this.node3 !== '') {
        this.myChartData.push({ name: this.node3, x: 800, y: 300 })
      }
      // 四级
      if (this.node4 !== '') {
        this.myChartData.push({ name: this.node4, x: 1050, y: 300 })
      }
      // 手动拼接link(关系图)----------------------------
      if (this.node2.length !== 0) {
        this.node2.forEach((item, index) => {
          this.myChartLink.push({ source: this.node1, target: item })
          if (this.node3 !== '') {
            this.myChartLink.push({ source: item, target: this.node3 })
          }
        })
      }
      if (this.node4 !== '') {
        this.myChartLink.push({ source: this.node3, target: this.node4 })
      }
    },
    // 关系图charts图像
    barCharts() {
      this.myChart = echarts.init(document.getElementById("main"));
      this.myChart.setOption({
        title: {
          text: '相关订单'
        },
        tooltip: {},
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            layout: 'none',
            symbol: 'rect',
            symbolSize: [160, 40],
            roam: true,
            label: {
              show: true
            },
            edgeSymbol: ['circle', 'arrow'],
            edgeSymbolSize: [4, 10],
            edgeLabel: {
              fontSize: 20
            },
            data: this.myChartData,
            links: this.myChartLink,
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0
            }
          }
        ]
      })
    }
  }
}
</script>
<style scoped lang='less'>
.relateOrder {
  main {
    margin: 20px;
    padding: 20px;
    width: calc(100% - 40px);
    height: 83vh;
    background: white;
    box-sizing: border-box;
    border-radius: 10px;
    #main {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
