// 个人中心
<template>
    <div class="personalCenter">
      <article>
        <header>
          <div class="head_left">
            <p>个人信息</p>
            <div class="head_user">
              <!-- <img v-if="imageUrl && editIcon === true" :src="imageUrl" class="avatar"> -->
              <!-- http://api.z-srm.com/ -->
              <!-- http://apisrm.bmxgj.cn/ -->
              <el-upload
                class="avatar-uploader"
                :action="interAddrss + 'sys/common/upload'"
                :show-file-list="true"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :headers="headers"
                :data="{uploadType: 'DEFAULT'}"
                :on-remove="handleRemove">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <span class="el-upload-action">
                  <!-- <i class="el-icon-delete"></i> -->
                  <i class="el-icon-upload2 avatar-uploader-icon" stop></i>
                </span>
              </el-upload>
            </div>
          </div>
          <div class="info">
            <div class="editInfo" v-if="editIcon === true" @click="editIcon = false"><i class="iconfont icon-bianji"></i></div>
            <div class="editInfo" v-if="editIcon === false"  @click="editInfo()"><i class="iconfont icon-bianji"></i></div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="82px" class="demo-ruleForm">
              <el-form-item label="姓名:" prop="name">
                <span v-if="editIcon === true" >{{ruleForm.name}}</span>
                <el-input v-if="editIcon === false" size="mini" v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item label="手机:" prop="mobile">
                <span>{{ruleForm.mobile}}<a @click="dialogPass = true" style="color: #00E5E5;">修改</a></span>
              </el-form-item>
              <el-form-item label="性别:" prop="sex">
                <span v-if="editIcon === true" >{{ruleForm.sex === 1 ? '男': '女'}}</span>
                <el-select v-if="editIcon === false" v-model="ruleForm.sex" placeholder="请选择" size="mini">
                  <el-option label="男" :value="1"></el-option>
                  <el-option label="女" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="邮箱:" prop="email">
                <span>{{ruleForm.email}}<a @click="dialogVisible = true" style="color: #00E5E5;">修改</a></span>
              </el-form-item>
              <el-form-item label="生日:" prop="birthday">
                <span v-if="editIcon === true" >{{ruleForm.birthday === undefined || ruleForm.birthday === null ? '' : (ruleForm.birthday).substring(0, 10)}}</span>
                <div v-if="editIcon === false">
                  <el-date-picker
                    v-model="ruleForm.birthday"
                    type="date"
                    placeholder="请选择"
                    size="mini"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :clearable="false">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item label="微信:" prop="name">
                <a href="javascript:;" style="color: #00E5E5;">绑定</a>
              </el-form-item>
            </el-form>
          </div>
        </header>
        <section class="other">
          <p>其他操作</p>
          <el-button class="newEnter" @click="openNewCompany()"><i class="iconfont icon-gongshi"></i>注册新企业</el-button>
          <!-- <el-button><i class="setBtn iconfont icon-xiaoxi"></i>消息设置</el-button> -->
        </section>
        <section class="my_author">
          <p>有工作权限的公司</p>
          <el-table :data="moreCompanyList" stripe style="width: 100%">
            <el-table-column show-overflow-tooltip prop="companyName" label="企业名称" min-width="180px"></el-table-column>
            <el-table-column show-overflow-tooltip prop="dept" label="部门" min-width="140px"></el-table-column>
            <el-table-column show-overflow-tooltip prop="roleName" label="岗位权限" min-width="140px"></el-table-column>
            <el-table-column show-overflow-tooltip prop="jobNum" label="工号" min-width="160px"></el-table-column>
            <el-table-column show-overflow-tooltip prop="isSuper" label="用户类型" min-width="120px" :formatter="superFormat" ></el-table-column>
          </el-table>
        </section>
      </article>
      <!-- 修改手机弹出框 -->
      <template class="changepasswordBox">
        <div>
          <el-dialog
            title="修改手机号"
            :visible.sync="dialogPass"
            :close-on-click-modal="false"
            @close="closeDialog"
            v-dialogDrag>
              <!-- 修改密码表单 -->
              <!-- 旧手机验证 -->
              <el-form v-if="controlEditPass === false" :model="editOidMobile" :rules="rules" ref="ruleForm">
                  <el-form-item class="clear">
                    <p>原手机号: </p><i>{{ruleForm.mobile}}</i>
                  </el-form-item>
                  <el-form-item prop="code" class="el_form_item_shortMsg clear">
                    <p>验证码: </p><el-input v-model="editOidMobile.code" name="shortMsg" placeholder="请输入短信验证码" clearable style="width: 180px;"></el-input>
                    <el-button @click="sendVerification(4, ruleForm.mobile)" ref="sendVeri" class="sendVeri" :disabled="controlBtn">发送验证码</el-button>
                  </el-form-item>
              </el-form>
              <span v-if="controlEditPass === false" slot="footer" class="dialog-footer">
                <el-button class="lightDeepStyle" @click="checkOldMobile()">下一步</el-button>
              </span>
              <!-- 新手机验证 -->
              <el-form v-if="controlEditPass === true" :model="editPassword" :rules="rules" ref="ruleForm">
                  <el-form-item  class="clear">
                    <p>新手机号: </p><el-input v-model="editPassword.mobile" placeholder="请输入新手机号" type="email" clearable autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item prop="code" class="el_form_item_shortMsg clear">
                    <p>验证码: </p><el-input v-model="editPassword.code" name="shortMsg" placeholder="请输入短信验证码" clearable style="width: 180px;"></el-input>
                    <el-button @click="sendVerification(4, editPassword.mobile)" ref="sendVeri" class="sendVeri" :disabled="controlBtn">发送验证码</el-button>
                  </el-form-item>
              </el-form>
              <span v-if="controlEditPass === true" slot="footer" class="dialog-footer">
                <el-button @click="dialogPass = false" class="searchStyle">取消</el-button>
                <el-button class="lightDeepStyle" @click="changeMobile()">修改</el-button>
              </span>
          </el-dialog>
        </div>
      </template>
      <!-- 修改邮箱弹出框 -->
      <template class="changeEmailBox">
          <div>
            <el-dialog
              title="修改邮箱"
              :visible.sync="dialogVisible"
              :close-on-click-modal="false"
              @close="closeDialog"
              v-dialogDrag>
                <!-- 修改邮箱表单 -->
                <el-form :model="editEmail" :rules="rules" ref="ruleForm">
                    <el-form-item prop="email" style="clear">
                      <p>新邮箱: </p><el-input v-model="editEmail.email" placeholder="请输入" type="email" prefix-icon="el-icon-search" clearable autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item prop="code" class="el_form_item_shortMsg clear">
                      <p>验证码: </p><el-input v-model="editEmail.code" name="shortMsg" placeholder="请输入邮箱验证码" clearable style="width: 180px;"></el-input>
                      <el-button @click="sendEmainVerification('editEmail')" ref="sendVeri2" class="sendVeri" :disabled="controlBtn2">发送验证码</el-button>
                    </el-form-item>
                </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button class="searchStyle" @click="dialogVisible = false">取消</el-button>
                <el-button class="lightDeepStyle" @click="changeEmail">修改</el-button>
              </span>
            </el-dialog>
          </div>
      </template>
    </div>
</template>
<script>
import { getUserInfoApi, editUserInfoApi, moreCompanyListApi, sendEmainVerificationApi, editEmailApi, sendVerificationApi, checkOldMobileApi, changeMobileApi } from '@/api'
export default {
  name: 'personalCenter',
  data() {
    return {
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem('interAddrss'),
      ruleForm: {},
      moreCompanyList: [],
      rules: {
        abc: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      // 图片地址
      imageUrl: '',
      // 控制修改按钮显影
      editIcon: true,
      companyId: window.$cookies.get('companyId'),
      headers: {
        // 'token': window.sessionStorage.getItem('token')
        'token': window.$cookies.get('token')
      },
      // 控制验证码按钮的禁用状态
      controlBtn: false,
      // 控制验证码按钮的禁用状态(邮箱专用)
      controlBtn2: false,
      // 控制邮箱弹框显隐
      dialogVisible: false,
      // 控制密码弹框显隐
      dialogPass: false,
      editEmail: {
        email: '',
        // 邮箱验证码
        code: ''
      },
      editOidMobile: {
        mobile: '',
        code: ''
      },
      editPassword: {
        code: '',
        mobile: '',
        checkCode: null
      },
      // 控制修改密码步骤
      controlEditPass: false,
      timer: null
    }
  },
  created() {
    this.getUserInfo()
    this.getMoreCompanyList()
  },
  methods: {
    // 获取用户的个人信息
    async getUserInfo() {
      const { data: res } = await getUserInfoApi({ companyId: this.companyId, buyId: window.$cookies.get('buyId'), userId: '' })
      console.log(res)
      this.ruleForm = res.data.userInfo
      this.imageUrl = res.data.userInfo.portrait
    },
    // 获取多公司列表
    async getMoreCompanyList() {
      const { data: res } = await moreCompanyListApi(0)
      if (res.code !== 0) return this.$message.error('获取公司列表失败!')
      this.moreCompanyList = res.data.list
    },
    // 移除图片
    handleRemove() {
      this.imageUrl = ''
    },
    // 展示是否管理员
    superFormat(row, column) {
      if (row.isSuper === 1) {
        return '管理员'
      } else {
        return '员工'
      }
    },
    // 上传成功回调
    handleAvatarSuccess(res) {
      this.imageUrl = res.data.url
      this.editInfo()
      console.log(this.imageUrl)
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload(file) {
      const type = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/webp' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!type) {
        this.$message.error('图片格式不正确!(只能包含jpg，png，webp，JPEG)')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return type && isLt2M
    },
    // 打开home页面的弹框
    openNewCompany() {
      this.$router.push('openNewCompany')
    },
    // 修改个人信息
    async editInfo() {
      if (this.ruleForm.name === null || this.ruleForm.name === '') return this.$message.error('请填写姓名')
      const { data: res } = await editUserInfoApi({ name: this.ruleForm.name, sex: this.ruleForm.sex, birthday: this.ruleForm.birthday, companyId: this.companyId, portrait: this.imageUrl })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '修改成功' })
      this.editIcon = true
    },
    // 发送邮箱验证码
    async sendEmainVerification(formName) {
      if (this.editEmail.email === '') return this.$message.error('邮箱不能为空!')
      if (!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.editEmail.email)) {
        this.$message.error('邮箱格式校验失败,请更改后重试!')
        return false
      }
      const { data: res } = await sendEmainVerificationApi({ type: 1, email: this.editEmail.email })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '验证码发送成功!' })
      // 获取验证码按钮元素(实现验证码倒计时功能)
      var sendBtn2 = this.$refs.sendVeri2.$el
      let val = 30
      var that = this
      let timers = setInterval(function () {
        val--
        that.controlBtn2 = true
        sendBtn2.innerHTML = val + '秒后重新获取'
        sendBtn2.style.color = '#ccc'
        if (val === 0) {
          that.controlBtn2 = false
          sendBtn2.style.color = '#fff'
          sendBtn2.innerHTML = '发送验证码'
          clearInterval(timers)
        }
      }, 1000)
    },
    // 修改邮箱
    async changeEmail() {
      if (this.editEmail.email === '') return this.$message.error('邮箱不能为空!')
      if (this.editEmail.code === '') return this.$message.error('验证码不能为空!')
      if (!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.editEmail.email)) {
        this.$message.error('邮箱格式校验失败,请更改后重试!')
        return false
      }
      const { data: res } = await editEmailApi(this.editEmail)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '修改邮箱成功!' })
      this.controlBtn2 = false
      var sendBtn2 = this.$refs.sendVeri2.$el
      sendBtn2.style.color = '#fff'
      sendBtn2.innerHTML = '发送验证码'
      clearInterval(this.timers)
      this.dialogVisible = false
      this.getUserInfo()
    },
    // 发送手机验证码
    async sendVerification(type, mobile) {
      if (mobile === '') return this.$message.error('手机号不能为空!')
      if (!/^((1[3,5,8][0-9])|(14[5,7])|(17[0,6,7,8])|(19[7]))\d{8}$/.test(mobile)) {
        this.$message.error('手机号码格式不对,请更改后重试')
        return false
      }
      const { data: res } = await sendVerificationApi({ type: type, mobile: mobile })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '验证码发送成功!' })
      // 获取验证码按钮元素(实现验证码倒计时功能)
      var sendBtn = this.$refs.sendVeri.$el
      let num = 30
      var that = this
      this.timer = setInterval(function () {
        num--
        that.controlBtn = true
        sendBtn.innerHTML = num + '秒后重新获取'
        sendBtn.style.color = '#ccc'
        if (num === 0) {
          that.controlBtn = false
          sendBtn.style.color = '#fff'
          sendBtn.innerHTML = '发送验证码'
          clearInterval(that.timer)
        }
      }, 1000)
    },
    // 校验旧手机号(点击下一步)
    async checkOldMobile() {
      const { data: res } = await checkOldMobileApi({ code: this.editOidMobile.code, mobile: this.ruleForm.mobile })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.editPassword.checkCode = res.data.checkCode
      this.controlEditPass = true
      this.controlBtn = false
      var sendBtn = this.$refs.sendVeri.$el
      sendBtn.style.color = '#fff'
      sendBtn.innerHTML = '发送验证码'
      clearInterval(this.timer)
    },
    // 修改手机号
    async changeMobile() {
      if (this.editPassword.mobile === '') return this.$message.error('新手机号不能为空!')
      if (this.editPassword.code === '') return this.$message.error('验证码不能为空!')
      if (!/^((1[3,5,8][0-9])|(14[5,7])|(17[0,6,7,8])|(19[7]))\d{8}$/.test(this.editPassword.mobile)) {
        this.$message.error('手机号码格式不对,请更改后重试')
        return false
      }
      const { data: res } = await changeMobileApi(this.editPassword)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '修改手机号成功!' })
      this.controlEditPass = false
      this.controlBtn = false
      this.dialogPass = false
      var sendBtn = this.$refs.sendVeri.$el
      sendBtn.style.color = '#fff'
      sendBtn.innerHTML = '发送验证码'
      clearInterval(this.timer)
      this.getUserInfo()
    },
    // 关闭弹框
    closeDialog() {
      this.ruleForm.code = ''
      this.editOidMobile.code = ''
      this.editPassword.mobile = ''
      this.editPassword.code = ''
      this.controlEditPass = false
      this.editEmail.email = ''
      this.editEmail.code = ''
    }
  }
}
</script>
<style scoped lang='less'>
/deep/ .el-form .el-form-item .el-form-item__content {
  width: 425px;
}
.personalCenter .el-dialog {
  .el_form_item_shortMsg .el-button {
    position: absolute;
    padding: 12px 0;
    top: 0;
    right: 54px;
    width: 110px;
    text-align: center;
  }
  i {
    display: inline-block;
    margin-left: 10px;
    width: 200px;
  }
}
article {
  width:100%;
  height:100%;
  border-radius:3px;
}
header {
  display: flex;
  height: 238px;
  overflow: hidden;
  background-color: #fff;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}
.head_left {
  box-sizing: border-box;
  width: 238px;
  height: 238px;
  border-radius: 3px;
  p {
    margin: 16px 20px;
    font-size: 16px;
    font-weight: 300;
  }
  .head_user {
    margin: 26px 56px;
    width: 150px;
    height: 150px;
  }
}
// 用户头像
.avatar-uploader{
  margin-top: -4px;
  width: 150PX;
  height: 150px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: url('../../assets/img/defaultHead2.svg') no-repeat;
  background-size: 100% 100%;
}
.avatar-uploader-icon{
  font-size: 0;
  color: #fff;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar-uploader-icon:hover{
  font-size: 28px;
  background-color: rgba(0, 0, 0, .3);
}
.avatar {
  position: relative;
  width: 150px;
  height: 150px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 150px;
}
/deep/ .el-upload-list {
  display: none;
}
.el-upload-action:hover {
  font-size: 20px;
  background-color: rgba(0, 0, 0, .3)
}
.personalCenter i {
  margin-right: 6px;
}
.info {
  position: relative;
  box-sizing: border-box;
  flex: 1;
  margin-left: 20px;
  padding-top: 58px;
  border-radius: 3px;
  font-size: 14px;
}
.info .editInfo {
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  height: 36px;
  width: 36px;
  background-color: #00C7C7;
  border-radius: 50%;
  text-align: center;
}
.info .editInfo i {
  font-size: 38px;
  line-height: 36px;
  color: #DDF9F9;
}
/deep/ .info .el-input__inner {
  width: 150px;
}
/deep/ .info .el-input__suffix {
  right: -26px;
}
.other {
  margin: 20px 0;
  height: 143px;
  width: 100% ;
  background:rgba(255,255,255,1);
  border-radius: 3px;
  overflow: hidden;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  p {
    margin: 20px 0 14px 20px;
  }
}
.other .el-button {
  margin-left: 55px;
  padding: 11px 18px;
  height: 60px;
  width: 180px;
  border: 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  font-weight: 400;
  color: #727179;
  line-height: 21px;
  text-align: left;
  i {
    font-size: 38px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .setBtn {
    color: #0DC9C9;
  }
}
.other .el-button:hover {
  background-color: #25DCDC;
  color: #fff;
}
.other .el-button:hover .setBtn{
  color: #fff;
}
.other .newEnter:hover {
  background-color: #5A6277;
}
.my_author {
  padding: 11px 18px;
  height: 100%;
  background:rgba(255,255,255,1);
  border-radius: 3px;
  box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}
// 基本表单样式
.el-form {
  margin: 10px;
  height: 100px;
}
.el-form-item {
  float: left;
  margin-bottom: 5px;
  width: 40%;
  height: 40px;
}
.el-form-item__label {
  color: #000!important;
}
.el-form-item span a {
  margin-left: 15px;
  color: #409EFF;
}
.el-form-item a {
  color: #409EFF;
}
.clear {
  clear: both;
}
.sendVeri {
  background-color: #1BC7B5;
  border: 1px solid #1BC7B5;
  color: #fff;
}
.sendVeri:hover {
  background-color: #1BC7B5;
  border: 1px solid #1BC7B5;
  color: #fff;
}
.sendVeri:focus {
  background-color: #1BC7B5;
  border: 1px solid #1BC7B5;
  color: #fff;
}
</style>
