// 邀请客户
<template>
  <div class="addChargeBack">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/customManage' }">客户管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">邀请客户</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addChargeBackContent">
      <el-form ref="ruleForm" :model="addSupplierData" label-width="122px" :rules="rules">
        <nav>
          <h5>邀请客户</h5>
          <el-form-item label="客户全称:" prop="name">
            <el-input @input="judgeQuery = false" v-model="addSupplierData.name" placeholder="请输入客户全称"></el-input>
            <el-button class="lightDeepStyle searchBtn" @click="getIndustryInfo()">查询</el-button>
          </el-form-item>
        </nav>
        <article>
          <h5>工商信息</h5>
          <ul>
            <li><p class="textHidden">统一信用代码: </p><span class="textHidden">{{industryInfo.regNumber === null ||  industryInfo.regNumber === undefined || industryInfo.regNumber === '' ? '暂无' : industryInfo.regNumber}}</span></li>
            <li><p class="textHidden">法定代表人: </p><span class="textHidden">{{industryInfo.legalPerson === null ||  industryInfo.legalPerson === undefined || industryInfo.legalPerson === '' ? '暂无' : industryInfo.legalPerson}}</span></li>
            <li><p class="textHidden">成立日期: </p><span class="textHidden">{{industryInfo.startDate === null ||  industryInfo.startDate === undefined || industryInfo.startDate === '' ? '暂无' : industryInfo.startDate}}</span></li>
            <li><p class="textHidden">经营状态: </p><span class="textHidden">{{industryInfo.manage === null ||  industryInfo.manage === undefined || industryInfo.manage === '' ? '暂无' : industryInfo.manage === 0 ? '正常' : industryInfo.manage === 1 ? '注销' : industryInfo.manage === 0 ? '停业' : '解散'}}</span></li>
            <li><p class="textHidden">注册资本: </p><span class="textHidden">{{industryInfo.capital === null ||  industryInfo.capital === undefined || industryInfo.capital === '' ? '暂无' : industryInfo.capital}}</span></li>
            <li><p class="textHidden">实缴资本: </p><span class="textHidden">{{industryInfo.recCap === null ||  industryInfo.recCap === undefined || industryInfo.recCap === '' ? '暂无' : industryInfo.recCap}}</span></li>
            <li><p class="textHidden">企业类型: </p><span class="textHidden">{{industryInfo.econKind === null ||  industryInfo.econKind === undefined || industryInfo.econKind === '' ? '暂无' : industryInfo.econKind}}</span></li>
            <li><p class="textHidden">所属行业: </p><span class="textHidden">{{industryInfo.industryName === null ||  industryInfo.industryName === undefined || industryInfo.industryName === '' ? '暂无' : ((industryInfo.firstIndustryName === null || industryInfo.firstIndustryName === undefined) ? industryInfo.industryName: industryInfo.firstIndustryName + '/' + industryInfo.industryName) }}</span></li>
            <li><p class="textHidden">纳税人识别号: </p><span class="textHidden">{{industryInfo.regNumber === null ||  industryInfo.regNumber === undefined || industryInfo.regNumber === '' ? '暂无' : industryInfo.regNumber}}</span></li>
            <li><p class="textHidden">组织机构代码: </p><span class="textHidden">{{industryInfo.orgNo === null ||  industryInfo.orgNo === undefined || industryInfo.orgNo === '' ? '暂无' : industryInfo.orgNo}}</span></li>
            <li><p class="textHidden">工商注册号: </p><span class="textHidden">{{industryInfo.businessNo === null ||  industryInfo.businessNo === undefined || industryInfo.businessNo === '' ? '暂无' : industryInfo.businessNo}}</span></li>
            <li><p class="textHidden">纳税人资质: </p><span class="textHidden">-</span></li>
          </ul>
          <div v-if="showMore === false" @click="showMore = true" class="showMore"><i style="transform: rotate(90deg);" class="el-icon-d-arrow-right"></i>展开</div>
          <ul v-if="showMore === true">
            <li><p class="textHidden">营业期限: </p><span class="textHidden">{{industryInfo.termStart === null ||  industryInfo.termStart === undefined || industryInfo.termStart === '' ? '暂无' : industryInfo.termStart + '-' + industryInfo.termEnd}}</span></li>
            <li><p class="textHidden">行政区划: </p><span class="textHidden">{{industryInfo.cityName === null ||  industryInfo.cityName === undefined || industryInfo.cityName === '' ? '暂无' : industryInfo.cityName}}</span></li>
            <li><p class="textHidden">登记机关: </p><span class="textHidden">{{industryInfo.belongOrg === null ||  industryInfo.belongOrg === undefined || industryInfo.belongOrg === '' ? '暂无' : industryInfo.belongOrg}}</span></li>
            <li><p class="textHidden">参保人员: </p><span class="textHidden">{{industryInfo.insuredNumber === null ||  industryInfo.insuredNumber === undefined || industryInfo.insuredNumber === '' ? '暂无' : industryInfo.insuredNumber}}</span></li>
            <li style="width: 66.6%"><p class="textHidden">注册地址: </p><span class="textHidden">{{industryInfo.regAddress === null ||  industryInfo.regAddress === undefined || industryInfo.regAddress === '' ? '暂无' : industryInfo.regAddress}}</span></li>
            <li style="width: 100%"><p class="textHidden">经营范围: </p><span>{{industryInfo.businessScope === null ||  industryInfo.businessScope === undefined || industryInfo.businessScope === '' ? '暂无' : industryInfo.businessScope}}</span></li>
          </ul>
          <div v-if="showMore === true" @click="showMore = false" class="showMore"><i style="transform: rotate(-90deg);" class="el-icon-d-arrow-right"></i>隐藏</div>
        </article>
        <section>
          <h5>企业联系人</h5>
          <el-button class="lightDeepStyle addBtn" @click="addContact">+ 新增联系人</el-button>
          <el-table ref="tableHeight" row-key="id" :min-height="tableHeight" :data="addSupplierData.contactList" style="width: 100%;">
            <el-table-column prop="name" label="联系人" min-width="100px" align="center">
              <template slot="header">
                <div>
                  <span><i style="color: #F56C6C;">*</i>联系人</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-input maxLength="20" v-model="scope.row.name" placeholder="输入姓名"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="phone" label="联系电话" min-width="100px" align="center">
              <template slot="header">
                <div>
                  <span><i style="color: #F56C6C;">*</i>联系电话</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-input oninput="value=value.replace(/[^\d]/g,'')" maxLength='11' v-model="scope.row.phone" placeholder="输入联系电话"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="email" label="邮箱" min-width="100px" align="center">
              <template slot-scope="scope">
                <div>
                  <el-input oninput="value=value.replace(/[^a-zA-Z0-9_@.-]/g,'')"  maxLength='50' v-model="scope.row.email" placeholder="输入邮箱"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="job" label="职位" min-width="100px" align="center">
              <template slot-scope="scope">
                <div>
                  <el-input maxLength="20" v-model="scope.row.job" placeholder="输入职位名称"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="地址" min-width="100px" align="center">
              <template slot-scope="scope">
                <div>
                  <el-cascader :ref="'cascaderRef' + scope.$index" :options="provinceList" :props="defaultData2" clearable v-model="scope.row.provice" @change="handleChange2($event, scope.$index)" size="mini"></el-cascader>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="address" label="详细地址" min-width="100px" align="center">
              <template slot-scope="scope">
                <div>
                  <el-input maxLength="50" v-model="scope.row.address" placeholder="请输入详细地址"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="addSupplierData.contactList.length > 1" show-overflow-tooltip label="操作" min-width="40px" align="center">
              <template slot-scope="scope">
                <div>
                  <i @click="delContact(scope.$index)" style="cursor: pointer; font-weight: bold; font-size: 16px; color: #F25A5A" class="el-icon-delete"></i>
                </div>
              </template>
            </el-table-column>
            <div slot="empty" class="emptyBg">
              <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
              <span style="display: block; margin-top: -14%">暂无数据</span>
            </div>
          </el-table>
        </section>
        <main>
          <h5>企业基本信息</h5>
          <div class="mainContent">
            <el-form-item label="所属行业 : " prop="industry">
              <el-cascader ref="catalogue" :options="industryList" :props="defaultData" clearable v-model="addSupplierData.industry" @change="industryChange" size="mini" filterable placeholder="请选择"></el-cascader>
            </el-form-item>
            <el-form-item label="客户类型: ">
              <el-select v-model="addSupplierData.type" placeholder="请选择经营模式">
                <el-option label="优选客户" :value="1"></el-option>
                <el-option label="合格客户" :value="2"></el-option>
                <el-option label="待淘汰客户" :value="3"></el-option>
                <el-option label="退出客户" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司人数 : " prop="staffNumber">
              <el-input oninput="value=value.replace(/[^\d]/g,'')" maxLength='6' v-model="addSupplierData.staffNumber" placeholder="请输入公司人数"></el-input>
            </el-form-item>
            <el-form-item label="主要采购产品 :" prop="product">
              <el-input v-model="addSupplierData.product" placeholder="请输入主要采购产品"></el-input>
            </el-form-item>
            <el-form-item label="公司地址 : ">
              <el-cascader ref="refHand" :options="provinceList" :props="defaultData2" clearable v-model="chooseAddress" @change="handleChange" size="mini"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址 : ">
              <el-input maxLength='40' v-model="addSupplierData.address" placeholder="请输入详细地址"></el-input>
            </el-form-item>
            <el-form-item label="年收入（万元） : " prop="turnover">
              <el-input oninput="value=value.replace(/[^\d]/g,'')" maxLength='8' type="number" v-model="addSupplierData.turnover" placeholder="请输入年收入"></el-input>
            </el-form-item>
          </div>
        </main>
      </el-form>
    </div>
    <footer>
      <el-button :class="judgeQuery === false ? 'defaultBox lightDeepStyle' : 'lightDeepStyle'" @click="addChargeBack()">保存</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { addSellerApi, industryLevelListApi, getProvinceAllApi, industryInfoApi } from '@/api'
export default {
  data() {
    return {
      // 判断是否查询
      judgeQuery: false,
      // 显示更多
      showMore: false,
      // 工商信息
      industryInfo: '',
      // 列表动态高度
      tableHeight: '',
      // 传参参数
      addSupplierData: {
        // 1:当前用户是采购商 2:当前用户是供应商
        roleType: 2,
        name: "", //供应商全称
        smallName: "", //供应商简称
        industry: "", //行业类型
        type: "", //供应商类型
        staffNumber: "", //公司人数
        product: "", //主营产品
        turnover: "", //年收入
        address: "", //地址
        province: "",
        city: "",
        area: "",
        provinceName: "",
        cityName: "",
        areaName: "",
        contactList: [
          {
            name: "", //联系人
            phone: "", //联系电话
            email: "", //邮箱
            job: "", //职位
            address: "", //地址
            province: "",
            city: "",
            area: "",
            provinceName: "",
            cityName: "",
            areaName: "",
          }
        ],
        provinceCode: "", // 省编码
        cityCode: "", // 市编码
        areaCode: "" // 区编码
      },
      // 级联选择器分类
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'sysIndustryEntityList',
        expandTrigger: 'hover',
        checkStrictly: true
      },
      // 级联选择器分类(地区使用)
      defaultData2: {
        value: 'code',
        label: 'name',
        children: 'newChildList',
        expandTrigger: 'hover',
      },
      // 校验
      rules: {
        name: [
          { required: true, message: '客户全称不能为空!', trigger: 'blur' },
          { min: 4, message: '最少输入4个字符', trigger: 'blur' },
          { max: 40, message: '最多可输入40个字符', trigger: 'blur' }
        ],
        smallName: [
          { max: 15, message: '最多可输入15个字符', trigger: 'blur' }
        ],
        product: [
          { max: 20, message: '最多可输入20个字符', trigger: 'blur' }
        ],
        turnover: [
          { max: 8, message: '最多可输入8个字符', trigger: 'blur' }
        ]
      },
      //所属行业
      industryList: [],
      // 存储全部省市区列表
      provinceList: [],
      chooseAddress: ''
    }
  },
  created() {
    this.addSupplierData.name = JSON.parse(this.$route.query.name)
    this.getIndustryInfo()
    this.getIndustryList()
    this.getProvince()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 652
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 652
      })()
    }
  },
  methods: {
    // 查询工商信息
    async getIndustryInfo() {
      const { data: res } = await industryInfoApi(this.addSupplierData.name)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.judgeQuery = true
      if (res.data.detail !== null) {
        this.industryInfo  = res.data.detail
      }
    },
    // 选择所属行业
    industryChange(e) {
      this.$refs.catalogue.dropDownVisible = false
    },
    // 级联选择器改变触发
    handleChange(e) {
      let addressName = this.$refs.refHand.getCheckedNodes()[0].pathLabels
      this.addSupplierData.province = e[0]
      this.addSupplierData.city = e[1]
      this.addSupplierData.area = e[2]
      this.addSupplierData.provinceName = addressName[0]
      this.addSupplierData.cityName = addressName[1]
      this.addSupplierData.areaName = addressName[2]
    },
    // 级联选择器改变触发
    handleChange2(e, index) {
      let addressName = this.$refs[`cascaderRef${index}`].getCheckedNodes()[0].pathLabels
      this.addSupplierData.contactList[index].province = e[0]
      this.addSupplierData.contactList[index].city = e[1]
      this.addSupplierData.contactList[index].area = e[2]
      this.addSupplierData.contactList[index].provinceName = addressName[0]
      this.addSupplierData.contactList[index].cityName = addressName[1]
      this.addSupplierData.contactList[index].areaName = addressName[2]
    },
    // 获取省市区信息
    async getProvince() {
      const { data: res } = await getProvinceAllApi()
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.provinceList = res.data.list
      this.provinceList.forEach((item, index) => {
        if (item.childList.length !== 0) {
          item.newChildList = item.childList
          item.newChildList.forEach((items, index) => {
            if (items.childList.length !== 0) {
              items.newChildList = items.childList
            }
          })
        }
      })
    },
    // 删除联系人
    delContact(index) {
      this.addSupplierData.contactList.splice(index, 1)
    },
    // 新增联系人
    addContact() {
      this.addSupplierData.contactList.push(
        {
          name: "", //联系人
          phone: "", //联系电话
          email: "", //邮箱
          job: "", //职位
          address: "", //地址
          province: "",
          city: "",
          area: "",
          provinceName: "",
          cityName: "",
          areaName: "",
        }
      )
    },
    // 获取行业表信息
    async getIndustryList() {
      const { data: res } = await industryLevelListApi('')
      if (res.code !== 0) return this.$message.error("获取行业类型失败!")
      this.industryList = res.data.list
    },
    // 页面跳转
    routerJump() {
      this.$router.back()
    },
    // 判断数组重复
    hasDuplicate(arr) {
      let isDuplicate = false;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (i !== j && arr[i] === arr[j]) {
            isDuplicate = true;
            break;
          }
        }
        if (isDuplicate) {
          break;
        }
      }
      return isDuplicate;
    },
    // 新增供应商
    addChargeBack() {
      if (this.judgeQuery === false) return this.$message.error('请先查询待邀请客户对应工商信息后重试!')
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          let controlCheck = true
          let controlCheck2 = true
          this.addSupplierData.contactList.forEach((item, index) => {
            if (item.name === '') {
              controlCheck = false
            }
            if (item.phone === '') {
              controlCheck2 = false
            }
          })
          if (controlCheck === false) return this.$message.error('联系人姓名不能为空!')
          if (controlCheck2 === false) return this.$message.error('联系电话不能为空!')
          //
          let array = []
          this.addSupplierData.contactList.forEach((val, i) => {
            array.push(val.phone)
          })
          if (this.hasDuplicate(array)) return this.$message.error('联系电话重复!')
          let newSupplierData = this.addSupplierData
          if (newSupplierData.industry !== undefined) {
            newSupplierData.industry = (newSupplierData.industry[newSupplierData.industry.length - 1])
          }
          if (this.industryInfo.id === null || this.industryInfo.id === undefined) {
            newSupplierData.sellerId = 0
          } else {
            newSupplierData.sellerId = this.industryInfo.id
          }
          const { data: res } = await addSellerApi(newSupplierData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '保存成功!' })
          setTimeout(() => {
            this.$router.push('/customManage')
          }, 300)
        } else {
          return false
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
.addChargeBack {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addChargeBackContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 190px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 1px;
      padding: 20px;
      height: 114px;
      width: 100%;
      background: #fff;
      box-sizing: border-box;
      h5 {
        margin: 0 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .el-input {
        width: calc(100% - 80px);
      }
      .searchBtn {
        margin-left: 12px;
        width: 68px;
      }
    }
    article {
      margin-bottom: 1px;
      padding: 20px;
      width: 100%;
      background: #fff;
      box-sizing: border-box;
      h5 {
        margin: 0 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        li {
          display: flex;
          width: 33.33%;
          min-height: 40px;
          color: rgba(0, 0, 0, 0.80);
          p {
            width: 120px;
            text-align: right;
          }
          span {
            padding: 0 10px;
            width: calc(100% - 120px);
            box-sizing: border-box;
          }
        }
      }
      .showMore {
        border-top: 2px solid #F8F9FA;
        border-bottom: 2px solid #F8F9FA;
        width: 100%;
        cursor: pointer;
        text-align: center;
      }
    }
    section {
      margin-bottom: 1px;
      padding: 20px;
      width: 100%;
      background-color: #fff;
      box-sizing: border-box;
      position: relative;
      h5 {
        margin: 0 0 30px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      .el-cascader {
        width: 100%;
      }
    }
    main {
      padding: 20px;
      width: 100%;
      background-color: #fff;
      box-sizing: border-box;
      h5 {
        margin: 0 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .mainContent {
        display: flex;
        flex-wrap: wrap;
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 33.3%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 130px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
            .el-cascader {
              width: 100%;
            }
          }
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
    .defaultBox {
      background-color: rgba(18,62,131, .7);
    }
  }
}
</style>