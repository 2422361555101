// 采购订单详情
<template>
  <div class="purchaseOrderDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/offinePurchasing' }">线下采购单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <!-- 0 制单 1进行中 2 审批完成 3驳回 4 手动结束 -->
      <el-button class="lightDeepStyle" @click="routerJump(2)"><i class="el-icon-edit-outline"></i>编辑</el-button>
      <el-button class="dangerStyle" @click="delPurchaseOrder()"><i class="el-icon-delete"></i>删除</el-button>
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="purchaseOrderDetailContent">
      <header>
        <div>
          <h5>线下采购单编号: {{purchaseOrderDetail.code}}
          </h5>
          <p>签订人: <span>{{purchaseOrderDetail.signerName}}</span> | 签订日期:  <span v-if="purchaseOrderDetail.createTime">{{(purchaseOrderDetail.createTime).substring(0, 10)}}</span></p>
        </div>
      </header>
      <!-- 流程进度 -->
      <main>
        <h6>采购清单明细</h6>
        <el-table ref="tableHeight" :data="purchaseOrderDetail.allProductLine" style="width: 100%;">
          <el-table-column key="1" show-overflow-tooltip prop="productNo" label="产品编号" min-width="100px" align="center"></el-table-column>
          <el-table-column key="2" show-overflow-tooltip prop="productName" label="产品名称" min-width="100px" align="center"></el-table-column>
          <el-table-column key="12" show-overflow-tooltip prop="productSpec" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productSpec === '' || scope.row.productSpec === null ? '暂未填写' : scope.row.productSpec}}</span>
            </template>
          </el-table-column>
          <el-table-column key="13" show-overflow-tooltip prop="productColor" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productColor === '' || scope.row.productColor === null ? '暂未填写' : scope.row.productColor}}</span>
            </template>
          </el-table-column>
          <el-table-column key="3" show-overflow-tooltip prop="productUnit" label="单位  " min-width="100px" align="center"></el-table-column>
          <el-table-column key="7" show-overflow-tooltip prop="num" label="本次订购数量" min-width="100px" align="center"></el-table-column>
          <el-table-column key="9" show-overflow-tooltip prop="price" label="单价" min-width="100px" align="center"></el-table-column>
          <el-table-column key="10" show-overflow-tooltip label="单品总价" prop="allPrice" min-width="100px" align="center">
          </el-table-column>
          <el-table-column key="11" show-overflow-tooltip prop="arrivalTime" label="要求到货时间" min-width="100px" align="center">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.arrivalTime">{{(scope.row.arrivalTime).substring(0, 10)}}</span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
      </el-table>
      </main>
    </div>
  </div>
</template>
<script>
import { getApprovalProcessApi, approvalpurchaseOrderApi, offinePuichaseDetailApi, purchaseOrderEndApi, delPurchaseOrderApi } from '@/api'
export default {
  data() {
    return {
      // 抽屉切换
      drawerTab: true,
      // 抽屉开关
      drawer: false,
      // 抽屉数据
      drawerData: '',
      id: '',
      // 获取线下采购单详情
      purchaseOrderDetail: {
        allProductLine: []
      },
      // 流程进度参数
      approvalProcessList: [],
      // 审批流程id
      approvalId: '',
      // 审批流程备注
      approvalRemark: '',
      showPlan: false,
      allNum: 0,
      allPrice: 0
    }
  },
  created() {
    this.id = this.$route.query.result
    this.getPurchaseOrderDetail().then(() => {
      this.getAllChangeMsg()
    })
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 改变展示
    changeShow(index, val) {
      this.drawerData.changeMsg[index].showDetail = val
      this.$forceUpdate()
    },
    // 删除
    delPurchaseOrder() {
      this.$confirm('是否确认删除该线下采购单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await delPurchaseOrderApi(this.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('删除成功!')
          this.$router.replace('purchaseOrder')
        })
    },
    // 结束
    purchaseOrderEnd() {
      this.$confirm('是否手动结束该线下采购单审批流', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await purchaseOrderEndApi(this.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('结束成功!')
          this.getPurchaseOrderDetail().then(() => {
            this.getAllChangeMsg()
          })
        })
    },
    // 获取流程动态和数据日志
    async getAllChangeMsg() {
      const { data: res } = await offinePuichaseDetailApi(this.id)
      this.drawerData = res.data.data
      if (this.drawerData.changeMsg.length !== 0) {
        this.drawerData.changeMsg.forEach(item => {
          item.showDetail = false
          if (item.listProduct && item.listProduct.length > 0) {
            let newArray = []
            item.listProduct.forEach((val, i) => {
              // 我需要将自己整合一个数组 分为三种情况 新增 删除 修改(type 1新增 2删除 3修改)
              // 1.新增
              // 判断如果oldLine === null
              if (val.oldLine === null && val.newLine !== null) {
                val.newLine.type = 1
                newArray.push(val.newLine)
              }
              // 2.删除
              if (val.oldLine !== null && val.newLine === null) {
                val.oldLine.type = 2
                newArray.push(val.oldLine)
              }
              // 3.修改
              if (val.oldLine !== null && val.newLine !== null) {
                let object = {
                  id: val.newLine.id,
                  code: val.newLine.code,
                  companyId: val.newLine.companyId,
                  arriveTime: val.oldLine.arriveTime,
                  newArriveTime: val.newLine.arriveTime,
                  number: val.oldLine.number,
                  newNumber: val.newLine.number,
                  orderId: val.newLine.orderId,
                  planLineId: val.newLine.planLineId,
                  planNumber: val.newLine.planNumber,
                  planResidueNumber: val.newLine.planResidueNumber,
                  price: val.oldLine.price,
                  newPrice: val.newLine.price,
                  productId: val.newLine.productId,
                  productCode: val.newLine.productCode,
                  productName: val.newLine.productName,
                  residueNumber: val.newLine.residueNumber,
                  unitName: val.newLine.unitName,
                  warrantNumber: val.newLine.warrantNumber,
                  warrantQuantity: val.newLine.warrantQuantity,
                  type: 3
                }
                newArray.push(object)
              }
            })
            item.changeArray = newArray
          }
        })
      }
    },
    // 流程进度数据
    async approvalProcess() {
      const { data: res } = await getApprovalProcessApi({ id: this.id, type: 5 })
      this.approvalProcessList = res.data.list
      if (this.approvalProcessList.length !== 0) {
        this.approvalProcessList.forEach(item => {
          if (item.status === 1) {
            this.approvalId = item.id
          }
        })
      }
      this.approvalProcessList.reverse()
    },
    // 审批通过
    async approvalpass(value) {
      if (value === 2 && this.approvalRemark === '') {
        this.approvalRemark = '同意'
      }
      if (value === 3 && this.approvalRemark === '') return this.$message.error('请在审批意见中输入驳回理由!')
      const { data: res } = await approvalpurchaseOrderApi({ approveLogs: [{ id: this.approvalId, remark: this.approvalRemark }], id: [{ id: this.approvalId }], operation: value, remark: this.approvalRemark })
      if (res.code !== 0) return this.$message.error(res.msg)
      if (value === 2) {
        this.$message.success({ duration: 1000, message: '审批通过!' })
      } else {
        this.$message.success({ duration: 1000, message: '拒绝成功!' })
      }
      this.getPurchaseOrderDetail()
    },
    // 获取线下采购单详情
    async getPurchaseOrderDetail() {
      const { data: res } = await offinePuichaseDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      let newPurchaseOrderDetail = res.data
      if (newPurchaseOrderDetail.allProductLine[0].planLineId !== null) {
        this.showPlan = true
      } else {
        this.showPlan = false
      }
      this.purchaseOrderDetail = newPurchaseOrderDetail
      this.allNum = 0
      this.allPrice = 0
      this.purchaseOrderDetail.allProductLine.forEach(item => {
        this.allNum = this.allNum + item.number
        this.allPrice = this.allPrice + item.price * item.number
      })
      this.approvalProcess()
      console.log(this.purchaseOrderDetail, '999999')
    },
    routerJump(val) {
      if (val === 1) {
        this.$router.back()
      } else if (val === 2) {
        this.$router.push({ path: '/updateOffinePuichase', query: { result: JSON.stringify(this.id) } })
      } else {
        this.$router.push({ path: 'relateOrder2', query: { id: this.id, code: this.purchaseOrderDetail.code } })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.purchaseOrderDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .purchaseOrderDetailContent {
    margin-top: 40px;
    header {
      position: relative;
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          .stateBox1, .stateBox2, .stateBox3 {
            margin-left: 10px;
            display: inline-block;
            width: 64px;
            height: 22px;
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            background: rgba(59,212,144,0.05);
            border: 1px solid rgba(59,212,144,0.2);
            color: rgba(59, 212, 144, 1);
            text-align: center;
            line-height: 22px;
          }
          .stateBox2 {
            background: rgba(239, 188, 96, 0.05);
            border: 1px solid rgba(239, 188, 96, 0.2);
            color: rgba(239, 188, 96, 1);
          }
          .stateBox3 {
            background-color: rgba(70, 147, 235, 0.05);
            border-color: rgba(70, 147, 235, 0.20);
            color: rgba(70, 147, 235, 1);
          }
          >i {
            position: absolute;
            right: 20px;
            padding: 0 8px;
            display: inline-block;
            height: 22px;
            border-radius: 4px 4px 4px 4px;
            cursor: pointer;
            font-size: 12px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            color: rgba(0, 0, 0, 0.4);
            text-align: center;
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    aside {
      margin: 20px;
      padding: 20px 20px 1px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .el-button {
          padding: 0;
        }
      }
      ul {
        margin-top: 10px;
        width: 100%;
        li {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          height: 40px;
          width: 100%;
          line-height: 40px;
          .stepBox1, .stepBox2, .stepBox3 {
            position: relative;
            margin-top: 8px;
            margin-left: 8px;
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.10);
            line-height: 20px;
            color: rgba(0, 0, 0, 0.80);
            text-align: center;
            i {
              font-size: 12px;
              font-weight: bold;
            }
            .circle {
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #3BD490;
            }
            u {
              position: absolute;
              top: 32px;
              left: 12px;
              width: 2px;
              height: 17px;
              background-color: rgba(0, 0, 0, 0.15);
            }
          }
          .stepBox2 {
            background-color: rgba(59, 212, 144, 0.10);
          }
          .stepBox3 {
            margin-top: 4px;
            margin-left: 4px;
            width: 32px;
            height: 32px;
            background-color: rgba(18, 62, 131, 1);
            .circleBox {
              margin-top: 6.25px;
              display: inline-block;
              width: 17.5px;
              height: 17.5px;
              border-radius: 50%;
              border: 1px solid #fff;
              background-color: rgba(18, 62, 131, 1);
              line-height: 0;
              .circle {
                margin-top: 6.25px;
                width: 5px;
                height: 5px;
                border-radius: 0;
                background-color: #fff;
              }
            }
            u {
              top: 39px;
              left: 15px;
            }
          }
          .stepContent {
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            height: 40px;
            width: calc(100% - 44px);
            background: rgba(18,62,131,0.05);
            border-radius: 8px;
            box-sizing: border-box;
            font-size: 14px;
            p {
              width: 120px;
            }
            p:nth-child(4) {
              width: 35%;
              /deep/ .el-input {
                height: 40px;
                width: calc(100% - 80px);
                .el-input__inner {
                  height: 40px;
                  border: 0;
                  background-color: transparent;
                  font-size: 14px;
                }
                ::-webkit-input-placeholder {
                  color: rgba(18, 62, 131, 1);
                }
                ::-moz-input-placeholder {
                  color: rgba(18, 62, 131, 1);
                }
              }
            }
            p:nth-child(5) {
              float: right;
              width: 140px;
              text-align: right;
            }
            .stepBtn {
              padding: 0 16px;
            }
          }
        }
      }
    }
    main {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        span {
          float: right;
        }
        i {
          float: right;
          margin-top: -7px;
          margin-left: 10px;
          margin-bottom: 14px;
          width: 76px;
          height: 32px;
          background: #123E83;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          color: #fff;
          line-height: 32px;
          font-size: 14px;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
      .sellTypeBox1, .sellTypeBox2, .sellTypeBox3, .sellTypeBox4 {
        display: inline-block;
        width: 80px;
        height: 22px;
        border-radius: 4px;
        background-color: rgba(70, 147, 235, 0.05);
        border: 1px solid rgba(70, 147, 235, 0.2);
        color: rgba(70, 147, 235, 1);
      }
      .sellTypeBox2 {
        background-color: rgba(59, 212, 144, 0.05);
        border: 1px solid rgba(59, 212, 144, 0.2);
        color: rgba(59, 212, 144, 1);
      }
      .sellTypeBox3 {
        background-color: rgba(239, 188, 96, 0.05);
        border: 1px solid rgba(239, 188, 96, 0.2);
        color: rgba(239, 188, 96, 1);
      }
      .sellTypeBox4 {
        background-color: rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.20);
        color: rgba(0, 0, 0, 0.40);
      }
    }
  }
  .drawerBox {
    padding: 20px;
    width: 100%;
    height: 100vh;
    >p {
      height: 48px;
      width: 500px;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      span {
        margin: 4px;
        display: inline-block;
        height: 40px;
        width: 246px;
        border-radius: 8px;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        color: rgba(0, 0, 0, 0.60);
      }
      .drawerShow {
        background-color: rgba(18, 62, 131, 1);
        color: #fff;
      }
    }
    .drawerLeft {
      ul {
        margin-top: 10px;
        width: 100%;
        li {
          .drawerNav {
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            height: 40px;
            width: 100%;
            line-height: 40px;
            .stepBox1, .stepBox2, .stepBox3 {
              margin-top: 8px;
              margin-left: 8px;
              display: inline-block;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: rgba(0, 0, 0, 0.10);
              line-height: 20px;
              color: rgba(0, 0, 0, 0.80);
              text-align: center;
              i {
                font-size: 12px;
                font-weight: bold;
              }
              .circle {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #3BD490;
              }
            }
            .stepBox2 {
              background-color: rgba(59, 212, 144, 0.10);
            }
            .stepBox3 {
              margin-top: 4px;
              margin-left: 4px;
              width: 32px;
              height: 32px;
              background-color: rgba(18, 62, 131, 1);
              .circleBox {
                margin-top: 6.25px;
                display: inline-block;
                width: 17.5px;
                height: 17.5px;
                border-radius: 50%;
                border: 1px solid #fff;
                background-color: rgba(18, 62, 131, 1);
                line-height: 0;
                .circle {
                  margin-top: 6.25px;
                  width: 5px;
                  height: 5px;
                  border-radius: 0;
                  background-color: #fff;
                }
              }
            }
            .drawerHeader {
              display: flex;
              justify-content: space-between;
              width: calc(100% - 50px);
              color: rgba(0, 0, 0, 0.80);
            }
          }
          .drawerBody {
            margin-left: 20px;
            width: 100%;
            border-left: 1px solid rgba(0, 0, 0, 0.15);
            .drawerContent {
              margin-left: 24px;
              padding: 10px 10px 1px;
              width: calc(100% - 44px);
              background: rgba(18,62,131,0.05);
              border-radius: 8px;
              box-sizing: border-box;
              font-size: 14px;
              p {
                margin: 0 0 10px;
                display: flex;
                width: 100%;
                justify-content: space-between;
              }
            }
          }

        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
    .drawerRight {
      .drawerRightBox {
        >li {
          margin-top: 20px;
          width: 100%;
          background: rgba(18,62,131,0.05);
          border-radius: 8px;
          box-sizing: border-box;
          font-size: 14px;
          div {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            width: 100%;
          }
          div:nth-child(2) {
            border-top: 1px solid #fff;
          }
          .openBox {
            display: block;
            border: 1px solid rgba(18,62,131,0.05);
            background-color: #fff;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            li {
              display: flex;
              justify-content: space-between;
              height: 30px;
              color: rgba(0, 0, 0, 0.80);
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
