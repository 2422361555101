
<!-- // 发货单管理 -->
<template>
  <div v-show="addIsShow" class="dispatchList">
    <nav>
      <h5>发货单管理</h5>
      <div class="buttonBox">
        <el-button v-if="btnLimit.includes('新增发货单')" class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增发货单</el-button>
        <el-button v-if="btnLimit.includes('新增补货单')" class="lightDeepStyle" @click="routerJump(2, '')"><i class="el-icon-plus"></i>新增补货单</el-button>
        <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-upload1"></i>导 出</el-button>
        <!-- <el-button class="lightDeepStyle" @click="print"><i class="el-icon-plus"></i>打印配置</el-button> -->
        <!-- <el-button class="lightDeepStyle"  @click="print"><i class="el-icon-plus"></i>打印</el-button> -->
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="dispatchListData.code" placeholder="发货单编号搜索" @input="getdispatchListList()" prefix-icon="el-icon-search"></el-input>
      <el-input class="sectionInp" v-model.trim="dispatchListData.companyName" placeholder="客户名称搜索" @input="getdispatchListList()" prefix-icon="el-icon-search"></el-input>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="创建日期区间"
          end-placeholder="创建日期区间"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select @change="stateFn" v-model="dispatchListData.state"  clearable placeholder="请选择">
        <el-option value="0" label="待发货"></el-option>
        <el-option value="1" label="已发货"></el-option>
      </el-select>
    </section>
    <main>
      <!--  -->
      <el-table ref="tableHeight" row-key="index" :height="tableHeight" :data="dispatchListList" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineEntityList'}" :show-overflow-tooltip="true">
        <el-table-column show-overflow-tooltip prop="code" label="发货单编号" min-width="140px" align="center" fixed="left">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList && scope.row.type===1" @click="routerJump(6, scope.row.id)" style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.code}}</span>
            <span v-else-if="scope.row.lineEntityList && scope.row.type===2" @click="routerJump(6, scope.row.id)" style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.code}}(补)</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="120px" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productNewColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="120px" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productSpecs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productTypeName" label="类型" min-width="120px" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.productTypeName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="companyName" label="客户名称" min-width="120px" align="center" fixed="left">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">{{scope.row.companyName}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="orderCode" label="销售单编号" width="120px"  align="center">
          <template slot-scope="scope">
          <span v-if="scope.row.lineEntityList">{{scope.row.orderCode}}</span>
            <span v-else>-</span>
            </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="140px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.unitName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="number" label="发货数量" min-width="80px"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">-</span>
            <span v-else>{{scope.row.number}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="deliveryTime" label="要求交货时间" min-width="120px" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">{{scope.row.deliveryTime.substring(0,10)}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="制单时间" min-width="120px" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">{{scope.row.createTime}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="userName" label="制单人" min-width="120px" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.lineEntityList">{{scope.row.userName}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column  show-overflow-tooltip prop="state" label="发货状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.lineEntityList">
              <span class="stateBox1" v-if="scope.row.state === 0">待发货</span>
              <span class="stateBox3" v-if="scope.row.state === 1">已发货</span>
            </div>
            <div v-else><span >-</span></div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown v-if="scope.row.lineEntityList" @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu v-if="scope.row.state === 0" slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="3" v-if="btnLimit.includes('编辑发货单')">
                  <i class="el-icon-edit-outline"></i><span>修改</span>
                </el-dropdown-item>
                <el-dropdown-item command="4" v-if="btnLimit.includes('删除发货单')">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
                <el-dropdown-item command="5" v-if="btnLimit.includes('发货')">
                  <i class="el-icon-s-promotion"></i><span>发货</span>
                </el-dropdown-item>
              </el-dropdown-menu>
              <el-dropdown-menu v-if="scope.row.state === 1 && btnLimit.includes('打印')" slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="6">
                  <i class="el-icon-s-promotion"></i><span>打印</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <!--  -->
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="dispatchListData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import { deliverGoods, deliverGoodsDelApi, ConfirmDeliveryApi, deliverGoodExportApi, replenishmentOkApi } from '@/api'
// import Printer from 'printerjs'
export default {
  data() {
    return {
    // 新增模块显示隐藏
      addIsShow: true,
      addIsShow2: false,
      // 列表动态高度
      tableHeight: '',
      // 采购退料列表
      dispatchListList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      purchaseReturnData: {
        code: '',
        orderCode: '',
        companyName: '',
        createTimeBegin: '',
        createTimeEnd: '',
        takeState: '',
        state: 2,
        type: 1
      },
      // 传参参数
      dispatchListData: {
        takeState: '',
        orderCode: '',
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        companyName: '',
        createTimeBegin: '',
        createTimeEnd: '',
        state: '',
        type: 1
      },
      chooseTime: '',
      printTemplate: '', // 自定义打印模板
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    if (this.$route.query.indexState && this.$route.query.indexState == '0') {
      this.dispatchListData.state = '0'
    }
    this.getdispatchListList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
  // 打印功能
    print() {
      this.$router.push('/printDemo')
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routerJump(1, result.id)
      } else if (command === '2') {
        this.routerJump(2, result.id)
      } else if (command === '3') {
        this.routerJump(3, result)
      } else if (command === '4') {
        this.deldispatchList(result.id)
      } else if (command === '5') {
        this.deldispatchFN(result)
      } else if (command === '6') {
      // 打印
        this.routerJump(6, result.id)
      }
    },
    // 供应商删除
    deldispatchList(id) {
      this.$confirm('是否确认删除该退货单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await deliverGoodsDelApi(id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('删除成功!')
          this.getdispatchListList()
        })
    },
    // 发货
    deldispatchFN(result) {
      this.$confirm('是否确认发货', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          // console.log(result)
          if (result.type === 1) {
            const { data: res } = await ConfirmDeliveryApi(result.id)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('发货成功!')
            this.getdispatchListList()
          } else {
            const { data: res } = await replenishmentOkApi(result.id)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('发货成功!')
            this.getdispatchListList()
          }
        })
    },
    // 路由跳转
    routerJump(val, result) {
      console.log(result)
      if (val === 1) {
      // 新增发货
        this.$router.push({ path: '/addDispatchList', query: { id: JSON.stringify(0), ids: JSON.stringify(8) } })
      } else if (val === 2) {
        this.$router.push({ path: '/replenishment', query: { id: JSON.stringify(10) } })
      } else if (val === 3) {
        this.$router.push({ path: '/updateDispath', query: { id: JSON.stringify(result.id), companyID: JSON.stringify(result.companyId) } })
      } else if (val === 6) {
        // 打印
        this.$router.push({ path: '/dispatchDetail', query: { id: JSON.stringify(result) } })
      }
    },
    // 点击切换时间表
    getTimess(result) {
      if (result === null) {
        this.dispatchListData.createTimeBegin = ''
        this.dispatchListData.createTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.dispatchListData.createTimeBegin = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.dispatchListData.createTimeEnd = getYear + '-' + nowMonth + '-' + getDate + ' 23:59:59'
          }
        })
      }
      this.getdispatchListList()
    },
    // 获取发货单列表
    async getdispatchListList(reset) {
      if (reset !== 'reset') {
        this.dispatchListData.page = 1
      }
      const { data: res } = await deliverGoods(this.dispatchListData)
      // 获取表单数据
      this.dispatchListList = res.data.data.list
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
      // 给列表添加唯一值
      if (this.dispatchListList.length !== 0) {
        this.dispatchListList.forEach((item, index) => {
          item.index = index + ''
          if (item.lineEntityList.length !== 0) {
            item.lineEntityList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
    },
    // 发货状态搜索
    stateFn(e) {
      this.getdispatchListList()
    },
    // -----------------------------------------------------------------

    // 触发每页显示条数
    handlesizeChange(val) {
      this.dispatchListData.size = val
      this.dispatchListData.page = 1
      this.getdispatchListList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.dispatchListData.page = val
      this.currentPage = val
      this.getdispatchListList('reset')
    },
    // 导出
    // 采购退料导出
    async purchaseReturnExport() {
      const { data: res } = await deliverGoodExportApi(this.dispatchListData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getPurchaseReturnList()
    }
  }
}
</script>
<style scoped lang='less'>
.dispatchList {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    // height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 50px;
    }
    .buttonBox {
      display: flex;
    //   justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
