// 线下供应商管理
<template>
  <div class="sellerOffineManage">
    <nav>
      <h5>
        <span class="choooseTab">线下供应商</span>
      </h5>
      <div>
        <el-button class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增线下供应商</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model="sellerManageData.companyName" placeholder="线下供应商名称" @input="getsellerManageList()" prefix-icon="el-icon-search"></el-input>
    </section>
    <main>
      <el-table ref="tableHeight" row-key="id" :height="tableHeight" :data="sellerManageList" style="width: 100%;">
        <el-table-column show-overflow-tooltip prop="name" label="线下供应商名称" min-width="100px" align="center">
          <template slot-scope="scope">
            <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="type" label="" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.type==='1'||scope.row.type==='2'||scope.row.type==='3'"><i :class="scope.row.type === '1' ? 'sellTypeBox1' : (scope.row.type === '2' ? 'sellTypeBox2' : (scope.row.type === '3' ? 'sellTypeBox3' : (scope.row.type === '4' || scope.row.type===''? 'sellTypeBox4' : '')))">
                {{scope.row.type === '1' ? '优选供应商' : scope.row.type === '2' ? '合格供应商' : scope.row.type === '3' ? '待淘汰供应商' : '暂无'}}</i>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="industry" label="行业名称" min-width="100px" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.industry == null || scope.row.industry == ''">
              <span>暂无</span>
            </div>
            <div v-else>
                <span>{{ scope.row.industry==='0'?'汽车制造业' :scope.row.industry==='1'?'3C产业': scope.row.industry==='2' ?'橡胶及塑料工业': scope.row.industry==='3' ?'食品饮料行业': scope.row.industry==='4'?'铸造行业': scope.row.industry==='5'?'化工行业': '冶金行业'}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="linkUser" label="联系人" min-width="100px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.linkUser == null ? '暂无' : scope.row.linkUser}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="linkPhone" label="联系电话" min-width="120px"  align="center">
          <template slot-scope="scope">
            <span>{{scope.row.linkPhone == null ? '暂无' : scope.row.linkPhone}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="taxRate" label="税率" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.taxRate!==0">
              <span>{{scope.row.taxRate == null || scope.row.taxRate == '' ? '暂无' : scope.row.taxRate + '%'}}</span>
            </div>
            <div v-else>
                <span>0%</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="120px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="0">
                  <i class="iconfont icon-xiangqing1"></i><span>查看</span>
                </el-dropdown-item>
                <el-dropdown-item command="1">
                  <i class="el-icon-edit-outline" ></i><span>编辑</span>
                </el-dropdown-item>
                <el-dropdown-item command="2">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="sellerManageData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import { offineSellerApi, delOffineSellerApi, waitMySellerListApi } from '@/api'
export default {
  name: 'sellerOffineManage',
  data() {
    return {
      // 待确认条数
      waitTotal: '',
      // 列表动态高度
      tableHeight: '',
      // 线下供应商列表
      sellerManageList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      sellerManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 12,
        companyName: '',
        com: 1,
        companyType: 0
      },
      chooseTime: ''
    }
  },
  created() {
  },
  mounted() {
    this.getsellerManageList()
    this.getWaitMySellerList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 获取线下供应商列表(待我确认)
    async getWaitMySellerList(reset) {
      const { data: res } = await waitMySellerListApi({ page: 1, size: 10, search: '', roleType: 1, industry: '' })
      this.waitTotal = res.data.page.totalCount
    },
    // 切换标签页
    checkTab(val) {
      if (val === 2) {
        this.$router.replace('./waitMySupplierManage')
      }
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '0') {
        this.routerJump(2, result.id)
      } else if (command === '1') {
        this.routerJump(3, result.id)
      } else {
        this.delSeller(result.id)
      }
    },
    // 线下供应商删除
    delSeller(id) {
      this.$confirm('是否确认删除该线下供应商', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await delOffineSellerApi(id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('删除成功!')
          this.getsellerManageList()
        })
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: '/addOffineSupplier' })
      } else if (val === 2) {
        this.$router.push({ path: '/detailOffineSupplier', query: { result: JSON.stringify(result) } })
      } else {
        this.$router.push({ path: '/addOffineSupplier', query: { result: JSON.stringify(result) } })
      }
    },
    // 获取线下供应商列表
    async getsellerManageList(reset) {
      if (reset !== 'reset') {
        this.sellerManageData.page = 1
      }
      const { data: res } = await offineSellerApi(this.sellerManageData)
      // 获取表单数据
      this.sellerManageList = res.data.list
      // 展示当前页面
      this.currentPage = res.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.totalPage
      // 展示总条数
      this.total = res.data.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.sellerManageData.size = val
      this.sellerManageData.page = 1
      this.getsellerManageList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.sellerManageData.page = val
      this.currentPage = val
      this.getsellerManageList('reset')
    }
  }
}
</script>
<style scoped lang='less'>
.sellerOffineManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
      span {
        margin-right: 20px;
        padding: 5px 0;
        display: inline-block;
        cursor: pointer;
      }
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
    }
    .statusBox1, .statusBox2, .statusBox3 {
      padding: 3px 8px;
      background: rgba(242,90,90,0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(242,90,90,0.2);
      font-size: 14px;
      color: rgba(242, 90, 90, 1);
    }
    .statusBox2 {
      background: rgba(239,188,96,0.05);
      border: 1px solid rgba(239,188,96,0.2);
      color: rgba(239, 188, 96, 1);
    }
    .statusBox3 {
      background: rgba(70,147,235,0.05);
      border: 1px solid rgba(70,147,235,0.2);
      color: rgba(70, 147, 235, 1)
    }
    .sellTypeBox1, .sellTypeBox2, .sellTypeBox3, .sellTypeBox4 {
      display: inline-block;
      width: 80px;
      height: 22px;
      border-radius: 4px;
      background-color: rgba(70, 147, 235, 0.05);
      border: 1px solid rgba(70, 147, 235, 0.2);
      color: rgba(70, 147, 235, 1);
    }
    .sellTypeBox2 {
      background-color: rgba(59, 212, 144, 0.05);
      border: 1px solid rgba(59, 212, 144, 0.2);
      color: rgba(59, 212, 144, 1);
    }
    .sellTypeBox3 {
      background-color: rgba(239, 188, 96, 0.05);
      border: 1px solid rgba(239, 188, 96, 0.2);
      color: rgba(239, 188, 96, 1);
    }
    .sellTypeBox4 {
      background-color: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.20);
      color: rgba(0, 0, 0, 0.40);
    }
  }
}
</style>
