// 岗位权限
<template>
  <div class="roleManageEdit">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/roleManage' }">岗位权限</el-breadcrumb-item>
      <el-breadcrumb-item v-if="roleData.roleId === ''" class="nowPage" style="color: rgba(18, 62, 131, 1)">新增岗位</el-breadcrumb-item>
      <el-breadcrumb-item v-else class="nowPage" style="color: rgba(18, 62, 131, 1)">修改岗位</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="roleManageEditContent">
      <section>
        <div class="sectionHeader">
          <h5 v-if="roleData.roleId === ''">新增岗位</h5>
          <h5 v-else>修改岗位</h5>
          <ul>
            <li><span><i>*</i>岗位名称 : </span><el-input type="text" v-model="roleData.roleName" placeholder="请输入"></el-input></li>
            <li><span><i>*</i>岗位编码 : </span><p>{{roleData.roleId === '' ? '自动生成' : roleData.roleId}}</p></li>
            <li><span>上级岗位 : </span>
              <el-select v-model="roleData.parentId" clearable placeholder="请选择上级岗位">
                <el-option
                  v-for="item in superArr"
                  :key="item.roleId"
                  :label="item.roleName"
                  :value="item.roleId">
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
        <div class="sectionBody">
          <!-- <h5>岗位权限配置<span>{{roleData.roleName}}</span></h5>
          <el-table :data="otherList" style="width: 97%">
            <el-table-column show-overflow-tooltip prop="title" label="数据权限" min-width="60px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="content" label=""></el-table-column>
            <el-table-column label="是否开启" min-width="45px" align="center">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.enabled"
                  :active-value="true"
                  :inactive-value="false"
                  active-color="rgba(18, 62, 131, 1)"
                  inactive-color="#eee">
                </el-switch>
              </template>
            </el-table-column>
          </el-table> -->
        </div>
      </section>
      <main>
        <h5>菜单权限操作</h5>
        <div class="mainHeader">
          <span>一级菜单</span>
          <span>二级菜单</span>
          <span>三级菜单</span>
        </div>
        <div class="mainContent">
          <p>{{'采购端&供应端'}}</p>
          <li v-for="(item, index) in allMenuData3" :key="item.menuId">
            <div class="liLeft">
              <div class="leftBox">
                <el-checkbox v-model="item.checkAll" @change="handleCheckAllChange($event, '', index, 3)">{{item.name}}</el-checkbox>
              </div>
            </div>
            <div class="liCenter">
              <div v-for="(value, ind) in item.children" :key="ind" class="liBox">
                <el-checkbox v-model="value.checkAll" @change="handleCheckedTreeChange($event, item, value)" :label="value.menuId">{{value.name}}</el-checkbox>
                <el-select v-if="notMenu.includes(value.name)" class="dataLimit" v-model="value.type" clearable placeholder="数据权限">
                  <el-option label="全部" :value="1"></el-option>
                  <el-option label="本人" :value="2"></el-option>
                  <el-option label="本部门" :value="3"></el-option>
                  <el-option label="本部门及以下部门" :value="4"></el-option>
                  <el-option label="自定义" :value="5"></el-option>
                </el-select>
                <el-cascader v-if="value.type === 5" class="dataLimit2" ref="catalogue" :options="departList" :props="defaultData"  clearable v-model="value.dept" placeholder="部门"></el-cascader>
                <div class="liRight" v-if="value.children && value.children.length !== 0">
                  <div v-for="(val, i) in value.children" :key="i" class="liBox2">
                    <el-checkbox v-model="val.checkAll" @change="handleCheckedTreeChange2($event, item, value, val)" :label="val.menuId">{{val.name}}</el-checkbox>
                  </div>
                </div>
                <div v-else class="liRight">
                  <div class="liBox2"></div>
                </div>
              </div>
            </div>
          </li>
          <p>采购端</p>
          <li v-for="(item, index) in allMenuData1" :key="item.menuId">
            <div class="liLeft">
              <div class="leftBox">
                <el-checkbox v-model="item.checkAll" @change="handleCheckAllChange($event, '', index, 1)">{{item.name}}</el-checkbox>
              </div>
            </div>
            <div class="liCenter">
              <div v-for="(value, ind) in item.children" :key="ind" class="liBox">
                <el-checkbox  v-model="value.checkAll" @change="handleCheckedTreeChange($event, item, value)" :label="value.menuId">{{value.name}}</el-checkbox>
                <el-select v-if="notMenu.includes(value.name)" class="dataLimit" v-model="value.type" clearable placeholder="数据权限">
                  <el-option label="全部" :value="1"></el-option>
                  <el-option label="本人" :value="2"></el-option>
                  <el-option label="本部门" :value="3"></el-option>
                  <el-option label="本部门及以下部门" :value="4"></el-option>
                  <el-option label="自定义" :value="5"></el-option>
                </el-select>
                <el-cascader v-if="value.type === 5" class="dataLimit2" ref="catalogue" :options="departList" :props="defaultData"  clearable v-model="value.dept" placeholder="部门"></el-cascader>
                <div class="liRight" v-if="value.children && value.children.length !== 0">
                  <div v-for="(val, i) in value.children" :key="i" class="liBox2">
                    <el-checkbox v-model="val.checkAll" @change="handleCheckedTreeChange2($event, item, value, val)" :label="val.menuId">{{val.name}}</el-checkbox>
                  </div>
                </div>
                <div v-else class="liRight">
                  <div class="liBox2"></div>
                </div>
              </div>
            </div>
          </li>
          <p>供应端</p>
          <li v-for="(item, index) in allMenuData2" :key="item.menuId">
            <div class="liLeft">
              <div class="leftBox">
                <el-checkbox v-model="item.checkAll" @change="handleCheckAllChange($event, '', index, 2)">{{item.name}}</el-checkbox>
              </div>
            </div>
            <div class="liCenter">
              <div v-for="(value, ind) in item.children" :key="ind" class="liBox">
                <el-checkbox  v-model="value.checkAll" @change="handleCheckedTreeChange($event, item, value)" :label="value.menuId">{{value.name}}</el-checkbox>
                <el-select v-if="notMenu.includes(value.name)" class="dataLimit" v-model="value.type" clearable placeholder="数据权限">
                  <el-option label="全部" :value="1"></el-option>
                  <el-option label="本人" :value="2"></el-option>
                  <el-option label="本部门" :value="3"></el-option>
                  <el-option label="本部门及以下部门" :value="4"></el-option>
                  <el-option label="自定义" :value="5"></el-option>
                </el-select>
                <el-cascader v-if="value.type === 5" class="dataLimit2" ref="catalogue" :options="departList" :props="defaultData"  clearable v-model="value.dept" placeholder="部门"></el-cascader>
                <div class="liRight" v-if="value.children && value.children.length !== 0">
                  <div v-for="(val, i) in value.children" :key="i" class="liBox2">
                    <el-checkbox v-model="val.checkAll" @change="handleCheckedTreeChange2($event, item, value, val)" :label="val.menuId">{{val.name}}</el-checkbox>
                  </div>
                </div>
                <div v-else class="liRight">
                  <div class="liBox2"></div>
                </div>
              </div>
            </div>
          </li>
        </div>
      </main>
    </div>
    <footer>
      <el-button class="lightDeepStyle" @click="addRoleInfo()">保存</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { keepOtherSystemApi, otherSystemListApi, getRoleInfoApi, editRoleInfoApi, addRoleInfoApi, getAllMenuApi, getRoleListApi, departmentListApi } from '@/api'
function editData(array) {
  const updatedArray = array.map(item => {
    const updatedItem = { ...item }
    if (updatedItem.list && updatedItem.list.length > 0) {
      updatedItem.children = editData(updatedItem.list)
    }
    delete updatedItem.list
    return updatedItem
  })
  return updatedArray.sort((a, b) => a.orderNum - b.orderNum)
}
export default {
  data() {
    return {
      notMenu: ['采购计划单', '采购单', '线下采购单', '询价采购', '竞价采购', '采购退货单', '预付款管理', '扣款管理', '对账单', '付款单', '线下采购单', '线下销售单', '发货单管理', '库存'],
      // 部门列表
      departList: [],
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'childrenList',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false,
        checkStrictly: true
      },
      // 列表动态高度
      tableHeight: '',
      // 存储上级岗位的数组
      superArr: [],
      // 上级岗位列表传参
      roleManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        // 合同名称
        search: ''
      },
      // 数据权限列表
      otherList: [],
      // 所有菜单
      allMenuData1: [],
      allMenuData2: [],
      allMenuData3: [],
      // ---------------------------------------------
      roleData: {
        // 当前角色id
        roleId: this.$route.query.roleId,
        // 备注
        remark: '',
        // 上级岗位id
        parentId: null,
        // 角色名称
        roleName: '',
        roleType: window.sessionStorage.getItem('roleType'),
        // 权限菜单
        menuIdList: [],
        // 存储权限菜单
        newMenuList: '',
        // 菜单
        menuDeptList: []
      },
      // 初始开启的开关
      btnValueA: [],
      // 初始未开启的开关
      btnValueB: 0
    }
  },
  created() {
    this.getRoleList()
    this.getDepartmentList()
    this.getAllMenu().then(() => {
      if (this.roleData.roleId !== undefined && this.roleData.roleId !== '') {
        this.getRoleInfo()
        this.otherSystemList()
      } else {
        this.otherList = [{
          content: '有该权限的角色才能可以发布通知',
          enabled: false,
          title: '通知管理权限'
        }]
      }
    })
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 652
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 652
      })()
    }
  },
  methods: {
    // 获取部门列表
    async getDepartmentList() {
      const { data: res } = await departmentListApi('')
      if (res.code !== 0) return this.$message.error(res.msg)
      this.departList = res.data
      // 处理子部门
      this.departList.forEach((item, index) => {
        // 获取总人数
        if (item.childList && item.childList.length !== 0) {
          item.childrenList = item.childList
          item.childrenList.forEach((items, index) => {
            if (items.childList && items.childList.length !== 0) {
              items.childrenList = item.childList
              items.childrenList.forEach((itemss, index) => {
                if (itemss.childList && itemss.childList.length !== 0) {
                  itemss.childrenList = itemss.childList
                }
              })
            }
          })
        }
      })
    },
    routerJump() {
      this.$router.back()
    },
    // 获取角色详情
    async getRoleInfo() {
      const { data: res } = await getRoleInfoApi(this.roleData.roleId)
      this.roleData = res.data.role
      // 如果没有上级岗位就显示为空
      if (this.roleData.parentId === 0) {
        this.roleData.parentId = ''
      }
      this.roleData.newMenuList = JSON.parse(this.roleData.newMenuList)
      // 第一层加上勾选状态(采购端)
      this.allMenuData1.forEach((item, index) => {
        this.roleData.newMenuList[0].forEach((arrayId, key)=> {
          if (item.menuId === arrayId) {
            item.checkAll = true
          }
        })
        // 第二层加上勾选状态
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            this.roleData.newMenuList[1].forEach((arrayId, key)=> {
              if (value.menuId === arrayId) {
                value.checkAll = true
              }
            })
            // 第三层加上勾选状态
            if (value.children && value.children.length !== 0) {
              value.children.forEach((val, i) => {
                this.roleData.newMenuList[2].forEach((arrayId, key)=> {
                  if (val.menuId === arrayId) {
                    val.checkAll = true
                  }
                })
              })
            }
          })
        }
      })
      // 给第二层加上勾选状态(采购端)
      this.allMenuData1.forEach((item, index) => {
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            this.roleData.menuDeptList.forEach((val, i) => {
              if (val.menuId === value.menuId) {
                if (val.type === 0) {
                  value.type = ''
                } else {
                  value.type = val.type
                }
                value.dept = val.dept - 0
              }
            })
          })
        }
      })
      // 第一层加上勾选状态(供应端)
      this.allMenuData2.forEach((item, index) => {
        this.roleData.newMenuList[0].forEach((arrayId, key)=> {
          if (item.menuId === arrayId) {
            item.checkAll = true
          }
        })
        // 第二层加上勾选状态
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            this.roleData.newMenuList[1].forEach((arrayId, key)=> {
              if (value.menuId === arrayId) {
                value.checkAll = true
              }
            })
            // 第三层加上勾选状态
            if (value.children && value.children.length !== 0) {
              value.children.forEach((val, i) => {
                this.roleData.newMenuList[2].forEach((arrayId, key)=> {
                  if (val.menuId === arrayId) {
                    val.checkAll = true
                  }
                })
              })
            }
          })
        }
      })
      // 给第二层加上勾选状态(供应端)
      this.allMenuData2.forEach((item, index) => {
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            this.roleData.menuDeptList.forEach((val, i) => {
              if (val.menuId === value.menuId) {
                if (val.type === 0) {
                  value.type = ''
                } else {
                  value.type = val.type
                }
                value.dept = val.dept - 0
              }
            })
          })
        }
      })
      // 第一层加上勾选状态(公共端)
      this.allMenuData3.forEach((item, index) => {
        this.roleData.newMenuList[0].forEach((arrayId, key)=> {
          if (item.menuId === arrayId) {
            item.checkAll = true
          }
        })
        // 第二层加上勾选状态
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            this.roleData.newMenuList[1].forEach((arrayId, key)=> {
              if (value.menuId === arrayId) {
                value.checkAll = true
              }
            })
            // 第三层加上勾选状态
            if (value.children && value.children.length !== 0) {
              value.children.forEach((val, i) => {
                this.roleData.newMenuList[2].forEach((arrayId, key)=> {
                  if (val.menuId === arrayId) {
                    val.checkAll = true
                  }
                })
              })
            }
          })
        }
      })
      // 给第二层加上勾选状态(公共端)
      this.allMenuData3.forEach((item, index) => {
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            this.roleData.menuDeptList.forEach((val, i) => {
              if (val.menuId === value.menuId) {
                if (val.type === 0) {
                  value.type = ''
                } else {
                  value.type = val.type
                }
                value.dept = val.dept - 0
              }
            })
          })
        }
      })
    },
    // checkAll = true 勾选状态
    // 一级判断
    handleCheckAllChange(status, array, sup, keys) {
      let newAllMenu = ''
      if (keys == 1) {
        newAllMenu = this.allMenuData1
      } else if (keys == 2) {
        newAllMenu = this.allMenuData2
      } else {
        newAllMenu = this.allMenuData3
      }
      // 勾选一级菜单
      newAllMenu[sup].checkAll = status
      // 第二层加上勾选状态
      if (newAllMenu[sup].children && newAllMenu[sup].children.length !== 0) {
        newAllMenu[sup].children.forEach((value, ind) => {
          value.checkAll = status
          // 第三层加上勾选状态
          if (value.children && value.children.length !== 0) {
            value.children.forEach((val, i) => {
              val.checkAll = status
            })
          }
        })
      }
      this.$forceUpdate()
    },
    // 二级判断
    handleCheckedTreeChange(status, item, value) {
      // 如果是勾选状态则将一二三级都勾上
      if (status === true) {
        // 勾选一级菜单
        item.checkAll = true
        // 勾选二级菜单
        value.checkAll = true
        // 勾选三级菜单
        if (value.children && value.children.length !== 0) {
          value.children.forEach((array, key) => {
            array.checkAll = true
          })
        }
      } else {
      // 如果是取消状态则将二三级都取消, 一级判断同级是否含有勾选
        // 勾选二级菜单
        value.checkAll = false
        // 勾选三级菜单
        if (value.children && value.children.length !== 0) {
          value.children.forEach((array, key) => {
            array.checkAll = false
          })
        }
        // 判断同级是否含有勾选,决定一级状态
        let controlOneStatus = false
        item.children.forEach((array, key) => {
          if (array.checkAll === true) {
            controlOneStatus = true
          }
        })
        // 如果同级存在勾选,则不动,未存在勾选则取消勾选状态
        if (controlOneStatus === false) {
          item.checkAll = false
        }
      }
      this.$forceUpdate()
    },
    // 三级判断
    handleCheckedTreeChange2(status, item, value, val) {
      // 如果是勾选状态则将一二三级都勾上
      if (status === true) {
        // 勾选三级菜单
        val.checkAll = true
        value.checkAll = true
        item.checkAll = true
      } else {
        val.checkAll = false
      }
      this.$forceUpdate()
    },
    // 新增(修改)角色详情
    async addRoleInfo() {
      if (this.roleData.roleName === '') return this.$message.error('岗位名称不能为空!')
      // 赋自定义值
      let newDataOne = []
      let newDataTwo = []
      let newDataThree = []
      // 获取勾选的第一层(采购端)
      this.allMenuData1.forEach((item, index) => {
        if (item.checkAll === true) {
          newDataOne.push(item.menuId)
        }
        // 第二层加上勾选状态
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            if (value.checkAll === true) {
              newDataTwo.push(value.menuId)
            }
            // 第三层加上勾选状态
            if (value.children && value.children.length !== 0) {
              value.children.forEach((val, i) => {
                if (val.checkAll === true) {
                  newDataThree.push(val.menuId)
                }
              })
            }
          }) 
        }
      })
      // 获取勾选的第一层(供应端)
      this.allMenuData2.forEach((item, index) => {
        if (item.checkAll === true) {
          newDataOne.push(item.menuId)
        }
        // 第二层加上勾选状态
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            if (value.checkAll === true) {
              newDataTwo.push(value.menuId)
            }
            // 第三层加上勾选状态
            if (value.children && value.children.length !== 0) {
              value.children.forEach((val, i) => {
                if (val.checkAll === true) {
                  newDataThree.push(val.menuId)
                }
              })
            }
          }) 
        }
      })
      // 获取勾选的第一层(公共端)
      this.allMenuData3.forEach((item, index) => {
        if (item.checkAll === true) {
          newDataOne.push(item.menuId)
        }
        // 第二层加上勾选状态
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            if (value.checkAll === true) {
              newDataTwo.push(value.menuId)
            }
            // 第三层加上勾选状态
            if (value.children && value.children.length !== 0) {
              value.children.forEach((val, i) => {
                if (val.checkAll === true) {
                  newDataThree.push(val.menuId)
                }
              })
            }
          }) 
        }
      })
      this.roleData.newMenuList = JSON.stringify([newDataOne, newDataTwo, newDataThree])
      // 重置
      this.roleData.menuIdList = []
      this.roleData.menuDeptList = []
      let newArray = []
      // 获取一级菜单的id
      this.allMenuData1.forEach((item , index) => {
        if (item.checkAll === true) {
          newArray.push(item.menuId)
        }
        // 获取二级菜单的id
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value , ind) => {
            if (value.checkAll === true) {
              newArray.push(value.menuId)
              // 获取三级菜单的id
              if (value.children && value.children.length !== 0) {
                value.children.forEach((val , i) => {
                  if (val.checkAll === true) {
                    newArray.push(val.menuId)
                  }
                })
              }
            }
            // 获取数据权限数据
            if (value.type !== '') {
              this.roleData.menuDeptList.push({ menuId: value.menuId, type: value.type, dept: JSON.stringify(value.dept) })
            } else {
              this.roleData.menuDeptList.push({ menuId: value.menuId, type: 0, dept: JSON.stringify(value.dept) })
            }
          })
        }
      })
      // 获取一级菜单的id(供应端)
      this.allMenuData2.forEach((item , index) => {
        if (item.checkAll === true) {
          newArray.push(item.menuId)
        }
        // 获取二级菜单的id
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value , ind) => {
            if (value.checkAll === true) {
              newArray.push(value.menuId)
              // 获取三级菜单的id
              if (value.children && value.children.length !== 0) {
                value.children.forEach((val , i) => {
                  if (val.checkAll === true) {
                    newArray.push(val.menuId)
                  }
                })
              }
            }
            // 获取数据权限数据
            if (value.type !== '') {
              this.roleData.menuDeptList.push({ menuId: value.menuId, type: value.type, dept: JSON.stringify(value.dept) })
            } else {
              this.roleData.menuDeptList.push({ menuId: value.menuId, type: 0, dept: JSON.stringify(value.dept) })
            }
          })
        }
      })
      // 获取一级菜单的id(公共端)
      this.allMenuData3.forEach((item , index) => {
        if (item.checkAll === true) {
          newArray.push(item.menuId)
        }
        // 获取二级菜单的id
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value , ind) => {
            if (value.checkAll === true) {
              newArray.push(value.menuId)
              // 获取三级菜单的id
              if (value.children && value.children.length !== 0) {
                value.children.forEach((val , i) => {
                  if (val.checkAll === true) {
                    newArray.push(val.menuId)
                  }
                })
              }
            }
            // 获取数据权限数据
            if (value.type !== '') {
              this.roleData.menuDeptList.push({ menuId: value.menuId, type: value.type, dept: JSON.stringify(value.dept) })
            } else {
              this.roleData.menuDeptList.push({ menuId: value.menuId, type: 0, dept: JSON.stringify(value.dept) })
            }
          })
        }
      })
      // 将所有菜单全部存起来
      this.roleData.menuIdList = newArray
      // 判断是新增还是修改
      if (this.roleData.roleId === '') {
        const { data: res } = await addRoleInfoApi(this.roleData)
        if (res.code !== 0) return this.$message.error(res.msg)
        // 保存之后调此接口(用来保存数据权限设置0 禁用 1启用)
        const { data: val } = await keepOtherSystemApi({ enabled: this.otherList[0].enabled, roleId: res.data.data })
        if (val.code !== 0) return this.$message.error(val.msg)
        this.$message.success({ duration: 1000, message: '新增岗位成功!' })
      } else {
        const { data: res } = await editRoleInfoApi(this.roleData)
        if (res.code !== 0) return this.$message.error(res.msg)
        // 保存之后调此接口(用来保存数据权限设置0 禁用 1启用)
        const { data: val } = await keepOtherSystemApi(this.otherList[0])
        if (val.code !== 0) return this.$message.error(val.msg)
        this.$message.success({ duration: 1000, message: '修改岗位成功!' })
      }
      this.$router.back()
    },
    // 获取所有岗位权限
    async otherSystemList() {
      const { data: res } = await otherSystemListApi({ type: '3', roleId: this.$route.query.roleId })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.otherList = res.data.page.list
    },
    // 获取所有岗位信息
    async getRoleList() {
      const { data: res } = await getRoleListApi(this.roleManageData)
      // 获取表单数据
      res.data.page.list.forEach(item => {
        let roleObj = { roleId: item.roleId, roleName: item.roleName }
        this.superArr.push(roleObj)
      })
    },
    // 获取所有菜单
    async getAllMenu() {
      const { data: res } = await getAllMenuApi('')
      let newAllMenuData1 = res.data.buyList
      let newAllMenuData2 = res.data.sellList
      let newAllMenuData3 = res.data.commonList
      this.allMenuData1 = editData(newAllMenuData1[0].list)
      this.allMenuData2 = editData(newAllMenuData2[0].list)
      this.allMenuData3 = editData(newAllMenuData3[0].list)
      // 第一层加上勾选状态(采购端)
      this.allMenuData1.forEach((item, index) => {
        item.checkAll = false
        // 第二层加上勾选状态
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            value.checkAll = false
            // 加上数据权限
            value.type = ''
            // 加上自定义部门
            value.dept = ''
            // 第三层加上勾选状态
            if (value.children && value.children.length !== 0) {
              value.children.forEach((val, i) => {
                val.checkAll = false
              })
            }
          })
        }
      })
      // 第一层加上勾选状态(供应端)
      this.allMenuData2.forEach((item, index) => {
        item.checkAll = false
        // 第二层加上勾选状态
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            value.checkAll = false
            // 加上数据权限
            value.type = ''
            // 加上自定义部门
            value.dept = ''
            // 第三层加上勾选状态
            if (value.children && value.children.length !== 0) {
              value.children.forEach((val, i) => {
                val.checkAll = false
              })
            }
          })
        }
      })
      // 第一层加上勾选状态(公共端)
      this.allMenuData3.forEach((item, index) => {
        item.checkAll = false
        // 第二层加上勾选状态
        if (item.children && item.children.length !== 0) {
          item.children.forEach((value, ind) => {
            value.checkAll = false
            // 加上数据权限
            value.type = ''
            // 加上自定义部门
            value.dept = ''
            // 第三层加上勾选状态
            if (value.children && value.children.length !== 0) {
              value.children.forEach((val, i) => {
                val.checkAll = false
              })
            }
          })
        }
      })
      console.log(this.allMenuData2, '6DEFEIQ1')
    }
  }
};
</script>
<style lang="less" scoped>
.roleManageEdit {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .roleManageEditContent {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 190px);
    overflow-y: auto;
    section {
      width: 23%;
      min-width: 300px;
      height: 100%;
      background: #fff;
      border-radius: 10px;
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        span {
          float: right;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.60);
        }
      }
      .sectionHeader {
        padding: 20px;
        // border-bottom: 2px solid #F8F9FA;
        ul {
          width: 100%;
          li {
            margin-top: 20px;
            display: flex;
            width: 100%;
            span {
              width: 112px;
              line-height: 32px;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.80);
              i {
                margin-right: 3px;
                color: rgba(242, 90, 90, 1);
              }
            }
            .el-input {
              width: calc(100% - 112px);
            }
            p {
              padding-left: 10px;
              width: calc(100% - 112px);
              height: 32px;
              background-color: rgba(0, 0, 0, 0.05);
              border-radius: 4px;
              box-sizing: border-box;
              line-height: 32px;
              font-size: 14px;
            }
            .el-select {
              width: calc(100% - 112px);
            }
          }
        }
      }
      .sectionBody {
        padding: 20px;
        .el-table {
          height: calc(100vh - 550px);
          overflow-y: auto;
        }
      }
    }
    main {
      margin-left: 20px;
      padding: 20px;
      width: calc(77% - 20px);
      min-width: 1000px;
      background: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
      }
      .mainHeader {
        margin-top: 20px;
        width: 100%;
        height: 48px;
        background-color: rgba(247, 247, 247, 1);
        line-height: 48px;
        span {
          padding-left: 20px;
          display: inline-block;
          width: 200px;
          box-sizing: border-box;
        }
        span:nth-child(2) {
          width: calc(100% - 420px);
        }
        span:nth-child(3) {
          width: 200px;
        }
      }
      .mainContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 320px);
        overflow-y: auto;
        >P {
          padding: 10px 20px;
          font-size: 18px;
          font-weight: bold;
          background-color: #ccc;
        }
        li {
          .liLeft {
            position: relative;
            padding-left: 20px;
            display: inline-block;
            width: 200px;
            height: 100%;
            box-sizing: border-box;
            background-color: rgba(18, 62, 131, 0.05);
            border-bottom: 1px solid #ccc;
            vertical-align: top;
            .leftBox {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .liCenter {
            display: inline-block;
            box-sizing: border-box;
            width: calc(100% - 200px);
            height: 100%;
            background-color: rgba(18, 62, 131, 0.02);
            .liBox {
              position: relative;
              padding-left: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #ccc;
              line-height: 30px;
              .dataLimit {
                position: absolute;
                top: 50%;
                left: 25%;
                transform: translateY(-50%);
                width: 140px;
              }
              .dataLimit2 {
                position: absolute;
                top: 50%;
                left: calc(25% + 160px);
                transform: translateY(-50%);
                width: 140px;
                /deep/ .el-input {
                  .el-input__inner {
                    vertical-align: top;
                  }
                }
              }
              .liRight {
                width: 200px;
                background-color: #fff;
                .liBox2 {
                  padding: 3px 10px;
                  min-height: 30px;
                }
              }
            }
            
          }
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
}
</style>