// 竞价草稿箱
<template>
  <div class="purchaseOrder">
    <nav>
      <h5>竞价草稿箱</h5>
      <div>
        <el-button class="lightStyle" @click="routerJump(4, '')"><i class="el-icon-edit-outline"></i>竞价采购</el-button>
        <el-button class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>发布竞价</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="askPriceData.codeAndName" placeholder="标题/竞价单号搜索" @input="getAskPriceList()" prefix-icon="el-icon-search"></el-input>
    </section>
    <main>
      <el-table @select="handleSelectionChange" @select-all="handleSelectionChange" ref="tableHeight" row-key="index" :height="tableHeight" :data="askPriceList" style="width: 100%;">
        <el-table-column type="selection" width="45" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="code" label="竞价采购单编号" min-width="100px" align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.code}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="name" label="标题" min-width="320px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <!-- 查看详情 不限制 -->
                <!-- 结束竞价 进行中>报价中&已确认&未确认 -->
                <!-- 确认报价 进行中>报价中&未确认 -->
                <!-- 审核报价 进行中>已确认 -->
                <!-- 取消确认报价 进行中>已确认 -->
                <!-- 删除竞价 开始竞价 修改竞价 待开始 -->
                <!-- <el-dropdown-item command="1">
                  <i class="el-icon-edit-outline"></i><span>查看详情</span>
                </el-dropdown-item> -->
                <el-dropdown-item command="7">
                  <i class="el-icon-switch-button"></i><span>修改竞价</span>
                </el-dropdown-item>
                <el-dropdown-item command="8">
                  <i class="el-icon-switch-button"></i><span>删除竞价</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="askPriceData.size"
        :total="total">
      </el-pagination>
    </main>
    <!-- 确认报价弹框 -->
    <el-dialog class="controlQuoteDialog" title="确认报价" :visible.sync="controlQuote" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <ul v-if="quoteList.length !== 0">
          <li v-for="(item, index) in quoteList" :key="index">123456</li>
        </ul>
        <div v-else slot="empty" class="emptyBg">
          <img src="../../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="chooseOrderDialog = false">取 消</el-button>
        <el-button class="lightDeepStyle" @click="chooseMatter()">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { askPriceListApi, endAskPriceApi, quoteListApi, approvalAskPriceApi, cancelAskPriceApi, beginAskPriceApi, delAskPriceApi } from '@/api'
export default {
  name: 'purchaseOrder',
  data() {
    return {
      // 报价列表
      quoteList: [],
      // 控制确认报价弹框
      controlQuote: false,
      // 保存勾选的row的值
      allRowId: [],
      // 选中的标签
      active: 1,
      // 列表动态高度
      tableHeight: '',
      // 采购单列表
      askPriceList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      askPriceData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 12,
        state: 0,
        codeAndName: '',
        type: 2
      },
      showTotal: {
        total1: 0,
        total2: 0,
        total3: 0
      }
    }
  },
  created() {
  },
  mounted() {
    this.getAskPriceListNum()
    this.getAskPriceList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  methods: {
    // 删除竞价
    async delAskPrice(id) {
      const { data: res } = await delAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('删除成功!')
      this.getAskPriceList()
    },
    // 开始竞价
    async beginAskPrice(id) {
      const { data: res } = await beginAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('开始竞价成功!')
      this.getAskPriceList()
    },
    // 取消确认竞价
    async cancelAskPrice(id) {
      const { data: res } = await cancelAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('取消确认成功!')
      this.getAskPriceList()
    },
    // 审核竞价
    async approvalAskPrice(id) {
      const { data: res } = await approvalAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('审核成功!')
      this.getAskPriceList()
    },
    // 报价列表
    async quoteList(id) {
      const { data: res } = await quoteListApi({ round: 1, enquiryId: id })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.quoteList = res.data.data
    },
    // 结束竞价
    async endAskPrice(id) {
      const { data: res } = await endAskPriceApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('结束成功!')
      this.getAskPriceList()
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: '/addCompetePrice' })
      } else if (val === 2) {
        this.$router.push({ path: '/competePricePurchaseDetail', query: { result: JSON.stringify(result) } })
      } else if (val === 3) {
        this.$router.push({ path: '/editCompetePrice', query: { result: JSON.stringify(result) } })
      } else if (val === 4) {
        this.$router.push({ path: '/competePricePurchase' })
      }
    },
    // 选择下拉
    chooseCommand(command, result) {
      // <!-- 查看详情 不限制 -->
      // <!-- 结束竞价 进行中>报价中&已确认&未确认 -->
      // <!-- 确认报价 进行中>报价中&未确认 -->
      // <!-- 审核报价 进行中>已确认 -->
      // <!-- 取消确认报价 进行中>已确认 -->
      // <!-- 删除竞价 开始竞价 修改竞价 待开始 -->
      if (command === '1') {
        // 查看详情
        this.routerJump(2, result.id)
      } else if (command === '2') {
        // 结束竞价
        this.$confirm('是否确认结束竞价?结束后无法进行报价确认等操作<p>报价: <span style="color: rgba(70, 147, 235, 1)">已确认</span>/<span style="color: rgba(239, 188, 96, 1)">未确认</span></p>', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true
        }).then(async () => {
          this.endAskPrice(result.id)
        })
      } else if (command === '3') {
        // 确认报价
        this.quoteList(result.id)
        this.controlQuote = true
      } else if (command === '4') {
        // 审核报价
        this.approvalAskPrice(result.id)
      } else if (command === '5') {
        // 取消确认寻找
        this.cancelAskPrice(result.id)
      } else if (command === '6') {
        // 开始竞价
        this.beginAskPrice(result.id)
      } else if (command === '7') {
        // 修改竞价
        this.routerJump(3, result.id)
      } else if (command === '8') {
        // 删除竞价
        this.$confirm('是否确认删除该竞价', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(async () => {
          this.delAskPrice(result.id)
        })
      }
    },
    // 获取默认条数
    async getAskPriceListNum() {
      let newPurchaseOrderData = JSON.parse(JSON.stringify(this.askPriceData))
      newPurchaseOrderData.state = 2
      const { data: res } = await askPriceListApi(newPurchaseOrderData)
      // 展示总条数
      this.showTotal.total1 = res.data.data.totalCount
      newPurchaseOrderData.state = 1
      const { data: ress } = await askPriceListApi(newPurchaseOrderData)
      this.showTotal.total2 = ress.data.data.totalCount
      newPurchaseOrderData.state = 3
      const { data: resss } = await askPriceListApi(newPurchaseOrderData)
      this.showTotal.total3 = resss.data.data.totalCount
    },
    // 手动勾选的参数
    handleSelectionChange(selection, row) {
      let arr = []
      selection.forEach(item => {
        arr.push(item.id)
      })
      this.allRowId = arr
    },
    // 全选
    allChoose(selection) {
      let arr = []
      selection.forEach(item => {
        arr.push(item.id)
      })
      this.allRowId = arr
    },
    // 获取竞价列表
    async getAskPriceList(reset) {
      if (reset !== 'reset') {
        this.askPriceData.page = 1
      }
      let newPurchaseOrderData = JSON.parse(JSON.stringify(this.askPriceData))
      const { data: res } = await askPriceListApi(newPurchaseOrderData)
      // 获取表单数据
      this.askPriceList = res.data.data.list
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.askPriceData.size = val
      this.askPriceData.page = 1
      this.getAskPriceList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.askPriceData.page = val
      this.currentPage = val
      this.getAskPriceList('reset')
    },
    // 切换标签页
    changeActive(val) {
      this.active = val
      if (val === 1) {
        this.askPriceData.state = 2
      } else if (val === 2) {
        // 待提交的状态
        this.askPriceData.state = 1
      } else if (val === 3) {
        this.askPriceData.state = 3
      }
      this.getAskPriceList()
      // 解决样式错乱的问题
      this.$nextTick(() => {
        this.$refs.tableHeight.doLayout()
      })
    },
    // -----------------------------------------------------------------------------------------------------------
    // ---------------------------------------
    // 完结
    overPurchaseOrder(id) {
      this.$confirm('是否确认完结该采购单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await overPurchaseOrderApi({ id: id, state: 11, stateType: 1 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('完结成功!')
        this.getAskPriceList()
      })
    },
    // 提交计划单
    async submitPurchaseOrder(id) {
      const { data: res } = await submitPurchaseOrderApi({ id: id, enquiryId: '' })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('提交成功!')
      this.getAskPriceList()
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.askPriceData.approveTimeBegin = ''
        this.askPriceData.approveTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.askPriceData.approveTimeBegin = getYear + '-' + nowMonth + '-' + getDate
          } else {
            this.askPriceData.approveTimeEnd = getYear + '-' + nowMonth + '-' + getDate
          }
        })
      }
      this.getAskPriceList()
    },
    // -----------------------------------------------------------------
    // 删除
    delPurchaseOrder(id) {
      this.$confirm('是否确认删除该采购单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        console.log('????', id)
        const { data: res } = await delPurchaseOrderApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getAskPriceList()
      })
    },
    // 结束
    endPurchaseOrder(id) {
      this.$confirm('是否手动结束该采购单审批流', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await purchaseOrderEndApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('结束成功!')
        this.getAskPriceList()
      })
    }
  }
}
</script>
<style scoped lang='less'>
.purchaseOrder {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  article {
    margin: 20px 20px 10px;
    display: flex;
    .changeBox {
      margin-left: -1px;
      height: 32px;
      width: 88px;
      border: 1px solid #CACACA;
      cursor: pointer;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.60);
    }
    .highlight {
      background-color: rgba(18, 62, 131, 1);
      border-color: rgba(18, 62, 131, 1);
      color: #fff;
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      .table-fixed {
        /deep/.el-table__fixed-right {
            height: 100% !important
        }
        /deep/.el-table__fixed {
            height:100% !important
        }
      }
    }
    .stateBox1, .stateBox2, .stateBox3, .stateBox4 {
      display: block;
      width: 64px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
    .stateBox4 {
      cursor: pointer;
      background-color: rgba(70, 147, 235, 1);
      border-color: rgba(70, 147, 235, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  /deep/ .controlQuoteDialog {
    .el-dialog {
      width: 516px;
      height: 550px;
      border-radius: 10px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 10px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        padding-bottom: 0;
        height: 440px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
