// 付款单详情
<template>
  <div class="paymentDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/paymentManage' }">付款单</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
      <el-button v-if="paymentDetail.status === 0" class="lightDeepStyle" @click="routerJump(2)"><i class="el-icon-edit"></i>付款</el-button>
      <el-button v-if="paymentDetail.status !== 0 && paymentDetail.auditStatus === 0" class="dangerStyle" @click="delPayment('撤回')"><i class="el-icon-delete"></i>撤回</el-button>
      <el-button v-if="paymentDetail.status !== 0 && paymentDetail.auditStatus === 2" class="lightDeepStyle" @click="routerJump(2)"><i class="el-icon-edit"></i>重新付款</el-button>
      <el-button v-if="paymentDetail.status === 0" class="dangerStyle" @click="delPayment('删除')"><i class="el-icon-delete"></i>删除</el-button>
      <el-button v-if="paymentDetail.status !== 0 && paymentDetail.auditStatus === 2" class="dangerStyle" @click="delPayment('删除')"><i class="el-icon-delete"></i>删除</el-button>
    </div>
    <div class="paymentDetailContent">
      <header>
        <div>
          <h5>付款单编号: {{paymentDetail.number + ' (' + paymentDetail.sellerName + ')'}}
            <i v-if="paymentDetail.status === 0" class="statusBox1">待付款</i>
            <i v-else class="statusBox2">已付款</i>
          </h5>
          <p>创建时间: <span>{{paymentDetail.createTime + '(' + paymentDetail.nickName + ')'}}</span></p>
        </div>
      </header>
      <section v-if="paymentDetail.status === 1">
        <h6>付款方式: {{paymentDetail.type === 1 ? '现金' : '转账'}}</h6>
        <p>付款时间: <span>{{paymentDetail.payTime}}</span> | 操作时间: <span>{{paymentDetail.updateTime}}</span> | 银行名称: <span>{{paymentDetail.bank}}</span> | 银行账号: <span>{{paymentDetail.bankNum}}</span></p>
      </section>
      <main style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
        <p>总金额(含税):</p><el-input v-model="paymentDetail.amountTax" disabled></el-input>
        <p style="margin-left: 10px;">总金额(不含税):</p><el-input v-model="paymentDetail.amount" disabled></el-input>
      </main>
      <footer>
        <h6>付款单明细</h6>
          <el-table ref="tableHeight" max-height="241" :data="paymentDetailList" row-key="index" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineList'}" :show-overflow-tooltip="true">
            <el-table-column show-overflow-tooltip prop="orderNum" label="采购单号" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.lineList">-</span>
                <span v-else>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.lineList">-</span>
                <span v-else>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.lineList">-</span>
                <span v-else>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="warrantCount" label="数量" min-wid th="80px"  align="center">
              <template slot-scope="scope">
                <!-- takeDeliveryNumber 已收货数量 warrantCount 入库数量 -->
                <!-- <span v-if="scope.row.detailType == 0">{{scope.row.takeDeliveryNumber}}</span>
                <span v-else-if="scope.row.detailType == 1">{{scope.row.warrantCount}}</span> -->
                <span>{{scope.row.warrantCount}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="returnCount" label="退货数量" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="resAmount" label="产品总价" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="yfkAmount" label="预付款金额" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="totalAmount" label="应付金额" min-width="80px" align="center"></el-table-column>
          </el-table>
      </footer>
      <footer>
        <h6>其他扣款单</h6>
        <el-table ref="tableHeight" max-height="245" :data="paymentDtnList" style="width: 100%;">
          <el-table-column show-overflow-tooltip prop="number" label="扣款单号" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="扣款名称" min-width="80px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="remark" label="扣款理由" min-width="280px"  align="left">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.remark}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="price" label="扣款金额" min-width="80px" align="center"></el-table-column>
        </el-table>
      </footer>
    </div>
  </div>
</template>
<script>
import { delPrePaymentApi, paymentDetailApi, paymentDetailListApi, paymentDtnListApi, moduleApi, delPaymentApi } from '@/api'
export default {
  data() {
    return {
      // 用来判断对账单明细中的数量显示的是收货数量还是入库数量
      detailType: 0,
      id: '',
      // 付款单详情
      paymentDetail: '',
      // 付款单明细
      paymentDetailList: [],
      // 其他扣款明细
      paymentDtnList: []
    }
  },
  created() {
    this.id = JSON.parse(this.$route.query.result)
    this.getPaymentDetail()
    this.getPaymentDetailList()
    this.getPaymentDtnList()
    this.getModuleList()
  },
  methods: {
    // 获取配置模块
    async getModuleList() {
      const { data: res } = await moduleApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      // 0.收货单 1.入库单
      this.detailType = res.data.data[0].state
    },
    // 删除
    delPayment(val) {
      let inner = val
      this.$confirm("是否确认" + inner + "该付款单？", "撤回重选", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
      .then(async () => {
        const { data: res } = await delPaymentApi(this.id)
        if (res.code !== 0) return this.$message.error(res.msg)
        if (inner == '撤回') {
          this.$message.success('撤回成功!')
        } else {
          this.$message.success('删除成功!')
        }
        this.$router.back()
      })
    },
    // 获取付款单详情
    async getPaymentDetail() {
      const { data: res } = await paymentDetailApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.paymentDetail =  res.data.detail
    },
    // 获取付款单明细
    async getPaymentDetailList() {
      const { data: res } = await paymentDetailListApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      // 付款单明细
      let newPaymentDetail = res.data.list
      // 给数据添加唯一值index,用于展开
      if (newPaymentDetail.length !== 0) {
        newPaymentDetail.forEach((item, index) => {
          item.index = index + ''
          if (item.lineList.length !== 0) {
            item.lineList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
      this.paymentDetailList = newPaymentDetail
    },
    // 其他扣款单列表
    async getPaymentDtnList() {
      const { data: res } = await paymentDtnListApi(this.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.paymentDtnList = res.data.list
    },
    routerJump(val) {
      if (val === 1) {
        this.$router.back()
      } else {
        this.$router.push({ path: "editPayment", query: { result: JSON.stringify(this.id) }})
      }
    }
  }
};
</script>
<style lang="less" scoped>
.paymentDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .paymentDetailContent {
    margin-top: 40px;
    header {
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          .statusBox1, .statusBox2{
            padding: 5px 8px;
            width: 52px;
            height: 22px;
            border-radius: 4px;        
            background: rgba(239, 188, 96, 0.05);
            border: 1px solid rgba(239, 188, 96, 0.2);
            font-size: 12px;
            color: rgba(239, 188, 96, 1);
          }
          .statusBox2 {
            background: rgba(70,147,235,0.05);
            border: 1px solid rgba(70,147,235,0.2);
            color: rgba(70, 147, 235, 1);
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    section {
      margin: 2px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
    main {
      margin: 2px 20px;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 14px;
        width: 100px;
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input {
        width: calc(50% - 110px);
        .el-input__inner {
          background-color: rgba(0, 0, 0, 0.05);
          border: 1px solid transparent;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.80);
          cursor: default;
        }
      }
    }
    footer {
      margin: 20px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        span {
          float: right;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
  }
}
</style>