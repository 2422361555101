import { render, staticRenderFns } from "./customDetail.vue?vue&type=template&id=2832714a&scoped=true&"
import script from "./customDetail.vue?vue&type=script&lang=js&"
export * from "./customDetail.vue?vue&type=script&lang=js&"
import style0 from "./customDetail.vue?vue&type=style&index=0&id=2832714a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2832714a",
  null
  
)

export default component.exports