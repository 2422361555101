<!-- 交付地址维护 -->
<template>
  <div class="sitePage">
    <nav>
      <h5>交付地址维护</h5>
      <div>
        <el-button v-if="btnLimit.includes('新增交付地址')" class="lightDeepStyle" @click="dialogVisibleFn(1,'','form')"><i class="el-icon-plus"></i>新增交付地址</el-button>
      </div>
    </nav>
    <div class="matterCategoryContent">
      <div v-for="(item,index) in tableData" :key="index" class="contentBox">
        <div class="leftBox">
            <p><span>{{item.name}} {{item.phone}}</span><span>{{item.province}}</span></p>
            <p>{{item.province}}-{{ item.city }}-{{ item.district }}-{{ item.area }}</p>
        </div>
        <div class="rightBox">
            <el-radio v-if="btnLimit.includes('设为默认')" v-model="item.def"  :label="parseInt(1)" @input="defaultFn(item.id)">默认地址</el-radio>
            <el-button v-if="btnLimit.includes('编辑交付地址')" @click="dialogVisibleFn(2,item.id,'form')"><i class="el-icon-edit"></i>编辑</el-button>
            <el-button v-if="btnLimit.includes('删除交付地址')" @click="delArea(item.id)"><i class="el-icon-delete"></i> 删除</el-button>
        </div>
      </div>
    </div>
    <el-dialog class="dialogClass" :title="stateName" :visible.sync="dialogVisible" :close-on-click-modal=false>
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="收货人" prop="name">
          <el-input id="formId" v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input id="formId" v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-row class="selectRow">
          <el-col :span="6">
            <label><i style="color: red;">*</i>所在地区</label>
          </el-col>
          <el-col :span="6">
            <el-form-item  prop="province" class="selectStyle">
              <div class="grid-content bg-purple">
                <el-select class="custom-select" style="width:80px;" v-model="form.province" placeholder="省" @change="areaChange">
                  <el-option v-for="(item,index) in oneArealist" id="formId" :key="index" :label="item.name" :value="item.name"/>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="city" class="selectStyle">
              <div class="grid-content bg-purple-light">
                <el-select v-model="form.city" placeholder="市" @change="areaChange2" style="width:80px;">
                  <el-option v-for="(item,index) in twoArealist" id="formId" :key="index" :label="item.name" :value="item.name"/>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="district" class="selectStyle">
              <div class="grid-content bg-purple">
                <el-select v-model="form.district" placeholder="区" style="width:80px;">
                  <el-option v-for="(item,index) in treeArealist" id="formId" :key="index" :label="item.name" :value="item.name"/>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="详细地址" prop="area">
          <el-input id="formId" v-model="form.area" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="float: right;">
          <el-button type="primary" @click="okFn('ruleForm')">确 定</el-button>
        <el-button  @click="closeFn('ruleForm')">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { siteListApi, getProvinceAllApi, siteAddApi, siteDeleteApi, siteDetailApi, siteUpdateApi, siteMarkApi } from '@/api'
export default {
  data() {
    return {
      formLabelWidth: 120,
      stateName: '',
      tableData: [],
      dialogVisible: false,
      // 1是对账2是收货单
      isTitle: 1,
      // 变更状态绑定
      paramsData: {
        state1: '',
        state2: '',
        id: '',
        type: ''
      },
      // form 表单
      form: {
        province: '',
        city: '',
        district: '',
        area: '',
        name: '',
        phone: ''
      },
      // 省市区
      // 一级
      oneArealist: [],
      // 二级
      twoArealist: [],
      // 三级
      treeArealist: [],
      // 表单验证
      rules: {
        name: [
          { required: true, message: '请输入收货人名称' }
        ],
        province: [
          { required: true, message: '请选择省份' }
        ],
        city: [
          { required: true, message: '请选择城市' }
        ],
        district: [
          { required: true, message: '请选择区' }
        ],
        area: [
          { required: true, message: '请输入详细地址' }
        ],
        phone: [
          { required: true, message: '请输入手机号' },
          { pattern: /^1[0-9]{10}$/, message: '手机号格式不正确' }
        ]
      },
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
    this.getModuleList()
    this.getDistrictList()
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
  },
  methods: {
    // 省市区联动事件
    areaChange(value) {
      // value就是一级分类code值
      // 先遍历所有的分类 里面包含一级和二级
      for (var i = 0; i < this.oneArealist.length; i++) {
        // 每个一级分类
        var oneSubject = this.oneArealist[i]
        // 判断：所有一级分code和点击一级分code是否一样
        // 如果没code，使用数组内其他属性对比也可以，比如name等
        if (value === oneSubject.name) { //= ==即比较值 还要比较类型
          // 从一级分类中获取所有的二级分类
        //   console.log();
          this.twoArealist = oneSubject.childList
          console.log(this.twoArealist)
          // 把二级分code值清空
          this.form.city = ''
        }
      }
    },
    areaChange2(value) {
      // value就是一级分类code值
      // 先遍历所有的分类 里面包含一级和二级
      for (var i = 0; i < this.twoArealist.length; i++) {
        // 每个一级分类
        var oneSubject = this.twoArealist[i]
        // 判断：所有一级分code和点击一级分code是否一样
        // 如果没code，使用数组内其他属性对比也可以，比如name等
        if (value === oneSubject.name) { //= ==即比较值 还要比较类型
          // 从一级分类中获取所有的二级分类
          this.treeArealist = oneSubject.childList
          // 把二级分code值清空
          this.form.district = ''
        }
      }
    },
    // 获取省市区数据
    async getDistrictList() {
      const { data: res } = await getProvinceAllApi()
      this.oneArealist = res.data.list
    },
    async getModuleList() {
      const { data: res } = await siteListApi()
      if (res.code === 0) {
        this.tableData = res.data
      }
    },
    // 弹出变更弹框
    dialogVisibleFn (type, id, ruleForm) {
      try {
        if (type === 1) {
          this.stateName = '新增交付地址'
          if (this.$refs.ruleForm !== undefined) {
            this.$refs.ruleForm.resetFields()
          }
          this.dialogVisible = true
        } else {
          this.stateName = '修改交付地址'
          this.$nextTick(async() => { // 注意看这里
            const { data: res } = await siteDetailApi(id)
            this.form = res.data
          })

          this.dialogVisible = true
        }
      } catch {
        return false
      }
    },
    // // 弹框关闭事件
    // handleClose(ruleForm) {
    //   this.closeFn(ruleForm)
    // },
    // 确定关闭弹框
    okFn(ruleForm) {
      // console.log(ruleForm)
      if (this.stateName === '新增交付地址') {
        this.$refs[ruleForm].validate(async(valid) => {
          if (valid) {
            const { data: res } = await (siteAddApi(this.form))
            if (res.code === 0) {
              this.$message({
                type: 'success', // success error warning
                message: '添加成功',
                duration: 2000
              })
              this.getModuleList()
              this.$refs[ruleForm].resetFields()
              this.dialogVisible = false
            } else {
              this.$message({
                type: 'error', // success error warning
                message: '添加失败',
                duration: 2000
              })
              this.getModuleList()
              this.$refs[ruleForm].resetFields()
              this.dialogVisible = false
            }
          } else {
            return false
          }
        })
      } else {
        this.$refs[ruleForm].validate(async(valid) => {
          if (valid) {
            const { data: res } = await (siteUpdateApi(this.form))
            if (res.code === 0) {
              this.$message({
                type: 'success', // success error warning
                message: '修改成功',
                duration: 2000
              })
              this.getModuleList()
              this.$refs[ruleForm].resetFields()
              this.dialogVisible = false
            } else {
              this.$message({
                type: 'error', // success error warning
                message: '修改失败',
                duration: 2000
              })
              this.getModuleList()
              this.$refs[ruleForm].resetFields()
              this.dialogVisible = false
            }
          } else {
            return false
          }
        })
      }
    },
    // 取消关闭弹框
    closeFn(ruleForm) {
      // this.$refs[ruleForm].resetFields()
      this.dialogVisible = false
    },
    // 删除地址
    delArea (id) {
      this.$confirm('确定要删除此地址', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await siteDeleteApi(id)
        if (res.code === 0) {
          this.$message({
            type: 'success', // success error warning
            message: '删除成功',
            duration: 1000
          })
          this.getModuleList()
        } else {
          this.$message({
            type: 'error', // success error warning
            message: res.msg,
            duration: 1000
          })
          this.getModuleList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 修改默认地址
    async defaultFn(id) {
      const { data: res } = await siteMarkApi(id)
      if (res.code !== 0) {
        return false
      }
      this.getModuleList()
    }
  }
}
</script>
<style scoped lang="less" >
.sitePage {

  .selectRow{
    display: flex;
  }
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  .matterCategoryContent{
    width: calc(100vw - 260px);
    height: calc(100vh - 260px);
    margin-bottom: 20px;
    padding: 0 20px;
    // overflow-y: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    .contentBox{
        width: 100%;
        background: #f2f2f2;
        margin: 15px 0px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 2.5rem;
       .leftBox{
        padding-left: 20px;
        p:nth-child(1){
            font-weight: bold;
            font-size: 14px;
            span:nth-child(2){
                display: inline-block;
                margin-left: 8px;
                border-radius: 5px;
                padding: 3px;
                color: #9dacc8;
                background: #dbeafb;
            }
        }
        p:nth-child(2){
            // font-weight: bold;
            font-size: 12px;
        }
       }
    }
  }
  .custom-select .el-select {
  width: 80px; // 设置宽度为200px
  .el-input--suffix .el-input__suffix{
    width: 80px;
  }
}
  .el-dialog__header span{
        font-size: .875rem;
    }
}
</style>
