// 出库管理
<template>
  <div class="outManage">
    <nav>
      <h5>出库单</h5>
      <div>
        <el-button class="lightDeepStyle" @click="routerJump(2, '')"><i class="el-icon-plus"></i>新增出库单</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model.trim="outData.code" placeholder="出库单编号搜索" @input="outManageList" prefix-icon="el-icon-search"></el-input>
      <el-select v-model="outData.warehouseId" placeholder="请选择出库仓库" size="mini" clearable @change="outManageList">
        <el-option v-for="item in storeHouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <!-- 审核日期 -->
      <div class="block">
        <el-date-picker
          v-model="chooseTime"
          @change="getTimess"
          type="daterange"
          range-separator="至"
          start-placeholder="选择出库日期"
          end-placeholder="选择出库日期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-select v-model="outData.type" placeholder="选择出库形式" clearable size="mini" @change="outManageList">
        <el-option label="手动出库" :value="1"></el-option>
        <el-option label="转仓出库" :value="2"></el-option>
        <el-option label="盘亏出库" :value="3"></el-option>
        <el-option label="线下销售出库" :value="4"></el-option>
        <el-option label="销售出库" :value="5"></el-option>
      </el-select>
    </section>
    <main>
      <el-table ref="tableHeight" row-key="id" :height="tableHeight" :data="entryList" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'warehouseOutboundLineVoList'}" :show-overflow-tooltip="true">
        <el-table-column show-overflow-tooltip prop="code" label="出库单号" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(3, scope.row.id)">{{scope.row.code}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="type" label="出库类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.type">{{scope.row.type===1?'手动出库':scope.row.type===2?'转仓出库':scope.row.type===3?'盘亏出库': scope.row.type===4 ?'线下销售出库':'销售出库'}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="cgCompanyName" label="客户名称" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.cgCompanyName === null ? '-' : scope.row.cgCompanyName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="130px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productName === null ? '-' : scope.row.productName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.warehouseOutboundLineVoList">-</span>
            <span v-else>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.warehouseOutboundLineVoList">-</span>
            <span v-else>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.warehouseOutboundLineVoList">-</span>
            <span v-else>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="quantity" label="出库数量" min-width="100px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="warehouseName" label="出库仓库" min-width="120px"  align="center"></el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown v-if="scope.row.warehouseOutboundLineVoList" @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item command="1">
                  <i class="el-icon-edit-outline"></i><span>查看</span>
                </el-dropdown-item>
                <!-- <el-dropdown-item>
                  <span style="cursor: pointer;font-size:12px" @click="delEntry(scope.row.id)">删除</span>
                </el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="outData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import { storeHouseListAllApi, outManageListApi } from '@/api'
export default {
  name: 'outManage',
  data() {
    return {
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      outData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 12,
        outboundTimeBegin: '',
        outboundTimeEnd: '',
        productName: '',
        code: '',
        sellName: '',
        warehouseId: '',
        type: ''
      },
      chooseTime: '',
      // 获取仓库列表参数
      storeHouseListData: {
        page: 1,
        // 当前页条数
        size: 9999,
        code: '',
        name: '',
        enabled: 1
      },
      // 仓库列表
      storeHouseList: [],
      // 物料管理列表
      entryList: [],
      // 列表动态高度
      tableHeight: '',
      sellerManageList: [],
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.outManageList()
    this.getStoreHouseList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  activated() {
    if (this.$route.meta.isNeedRefresh || this.isFirstEnter) {
      this.outManageList()
    }
    this.isFirstEnter = false
    this.$route.meta.isNeedRefresh = true
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routerJump(3, result.id)
      }
    },
    // //删除出库单
    // delEntry(id) {
    //   this.$confirm('是否确认删除当前出库单?', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning',
    //       closeOnClickModal: false
    //     }).then(async() => {
    //       const { data: res } = await delEntryApi([id])
    //       if (res.code !== 0) return this.$message.error(res.msg)
    //       this.$message.success({ duration: 1000, message: '删除成功!' })
    //       this.outManageList()
    //     })
    // },
    // 路由跳转
    routerJump(val, result) {
      if (val === 2) {
        this.$router.push({ path: '/addOutbound' })
      } else {
        this.$router.push({ path: '/outDetail', query: { result: JSON.stringify(result) } })
      }
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.outData.size = val
      this.outData.page = 1
      this.outManageList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.outData.page = val
      this.currentPage = val
      this.outManageList('reset')
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result)
      if (result === null) {
        this.outData.outboundTimeBegin = ''
        this.outData.outboundTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.outData.outboundTimeBegin = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.outData.outboundTimeEnd = getYear + '-' + nowMonth + '-' + getDate + ' 23:59:59'
          }
        })
      }
      this.outManageList()
    },
    // 获取仓库列表
    async getStoreHouseList() {
      const { data: res } = await storeHouseListAllApi(this.storeHouseListData)
      this.storeHouseList = res.data.page.list
    },
    // 获取出库列表
    async outManageList(reset) {
      if (reset !== 'reset') {
        this.outData.page = 1
      }
      const { data: res } = await outManageListApi(this.outData)
      // 获取表单数据
      this.entryList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
    }
  }
}
</script>
<style scoped lang='less'>
.outManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .el-select {
      margin: 0 20px 0 0;
    }
    .el-cascader {
      margin: 0 20px 0 0;
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      font-weight: bold;
    }
  }
}
</style>
