// 仓库权限
<template>
  <div class='warehousePermission'>
    <nav>
      <p>仓库权限</p>
      <main>
        <div :class="radio === '1' ? 'leftBox heightLight' : 'leftBox'" @click.prevent="getUserManage">
          <el-radio v-model="radio" label="1">根据用户选择仓库</el-radio>
          <img v-if="radio === '2'" src="../../../assets/img/warehouse.svg" alt="">
          <img v-else src="../../../assets/img/warehouseLight.svg" alt="">
        </div>
        <div :class="radio === '2' ? 'rightBox heightLight' : 'rightBox'" @click.prevent="storeHouseListAll">
          <el-radio v-model="radio" label="2">根据仓库选择用户</el-radio>
          <img v-if="radio === '1'" src="../../../assets/img/minDefaultHead.png" alt="">
          <img v-else src="../../../assets/img/minDefaultHeadLight.svg" alt="">
        </div>
        <!-- 选择用户下拉框 -->
        <el-select v-if="radio === '1'" size="mini" v-model="userId" placeholder="请先选择用户" @change="getChooseWarehouse">
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.infoId">
          </el-option>
        </el-select>
        <!-- 选择仓库下拉框 -->
        <el-select v-if="radio === '2'" size="mini" v-model="warehouseId" placeholder="请先选择仓库" @change="getChooseUser">
          <el-option
            v-for="item in allWarehouseList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <br/>
        <!-- 选择仓库勾选框 -->
        <div v-if="radio === '1'">
          <span>待选清单</span>
          <p v-if="allWarehouseList.length === 0" style="color: #FEB300;">暂无可用仓库,请前去资料-仓库-仓库资料中添加</p>
          <el-checkbox-group v-else v-model="chooseWarehouseList" @change="choosewarehouseKeep()">
            <el-checkbox v-for="item in allWarehouseList" :key="item.id" :label="item.id" :disabled="userId === '' ? true : false">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- 选择用户勾选框 -->
        <div v-if="radio === '2'">
          <span>待选清单</span>
          <el-checkbox-group v-model="chooseUserList" @change="chooseUserKeep()">
            <el-checkbox v-for="item in userList" :key="item.infoId" :label="item.infoId" :disabled="warehouseId === '' ? true : false">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </main>
      <!-- <el-button v-if="radio === '1'" @click="choosewarehouseKeep()">保存<i class="iconfont icon-preserve"></i></el-button>
      <el-button v-if="radio === '2'" @click="chooseUserKeep()">保存<i class="iconfont icon-preserve"></i></el-button> -->
    </nav>
  </div>
</template>
<script>
import { getChooseUserApi, getChooseWarehouseApi, getUserManageApi, chooseUserKeepApi, choosewarehouseKeepApi, storeHouseListAllApi } from '@/api'
export default {
  data() {
    return {
      radio: '1',
      // 所有用户传参
      roleManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 999,
        // 搜索内容
        name: '',
        companyId: window.$cookies.get('companyId'),
        state: '',
        isActivate: '1'
      },
      // 所有用户列表
      userList: [],
      // 选择的用户id
      userId: '',
      // 所有仓库传参
      storeHouseListData: {
        page: 1,
        // 当前页条数
        size: 999,
        code: '',
        name: '',
        enabled: 1
      },
      // 所有仓库列表
      allWarehouseList: [],
      // 选择的仓库id
      warehouseId: '',
      // 保存前勾选的仓库
      chooseWarehouseList: [],
      // 保存前勾选的用户
      chooseUserList: [],
      // 动态获取高度
      mainHeight: ''
    }
  },
  created() {
    this.storeHouseListAll()
    this.getUserManage()
  },
  methods: {
    // 1.获取所有用户
    async getUserManage() {
      this.radio = '1'
      const { data: res } = await getUserManageApi(this.roleManageData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userList = res.data.page.list
      this.userId = ''
      this.warehouseId = ''
      this.chooseWarehouseList = []
      this.chooseUserList = []
    },
    // 2.根据用户获取已选择仓库
    async getChooseWarehouse() {
      this.chooseWarehouseList = []
      const { data: res } = await getChooseWarehouseApi(this.userId)
      if (res.code !== 0) return this.$message.error(res.msg)
      // 将获取到的仓库在所有仓库中勾选
      let currentWarehouse = res.data.page
      if (currentWarehouse.length !== 0) {
        currentWarehouse.forEach(item => {
          this.chooseWarehouseList.push(item.warehouseId)
        })
      }
    },
    // 3.选择仓库后保存
    async choosewarehouseKeep() {
      if (this.userId === '') return this.$message.error('请先选择用户')
      const { data: res } = await choosewarehouseKeepApi({ infoId: this.userId, warehouseIdList: this.chooseWarehouseList })
      if (res.code !== 0) return this.$message.error(res.msg)
    },
    // 1.获取所有仓库
    async storeHouseListAll() {
      this.radio = '2'
      const { data: res } = await storeHouseListAllApi(this.storeHouseListData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.allWarehouseList = res.data.page.list
      this.userId = ''
      this.warehouseId = ''
      this.chooseWarehouseList = []
      this.chooseUserList = []
    },
    // 2.根据仓库获取已选择用户
    async getChooseUser() {
      this.chooseUserList = []
      const { data: res } = await getChooseUserApi(this.warehouseId)
      if (res.code !== 0) return this.$message.error(res.msg)
      let currentUser = res.data.page
      if (currentUser.length !== 0) {
        currentUser.forEach(item => {
          this.chooseUserList.push(item.infoId)
        })
      }
    },
    // 3.选择用户后保存
    async chooseUserKeep() {
      if (this.warehouseId === '') return this.$message.error('请先选择仓库')
      const { data: res } = await chooseUserKeepApi({ warehouseId: this.warehouseId, infoIdList: this.chooseUserList })
      if (res.code !== 0) return this.$message.error(res.msg)
    }
  }
}
</script>
<style scoped lang='less'>
.warehousePermission {
  nav {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 16px 22px;
    width: 100%;
    min-height: 500px;
    background: #fff;
    border-radius: 3px;
    box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    main {
      margin-left: 50%;
      width: 520px;
      transform: translate(-50%);
      .leftBox, .rightBox {
        position: relative;
        display: inline-block;
        margin-bottom: 60px;
        box-sizing: border-box;
        width: 230px;
        height: 83px;
        border-radius: 6px;
        border: 1px solid #E4E4E4;
        cursor: pointer;
        .el-radio {
          margin: 20px 22px 10px;
        }
        img {
          position: absolute;
          top: 28px;
          right: 9px;
          height: 46px;
          width: 46px;
        }
      }
      .leftBox {
        margin-right: 60px;
      }
      .heightLight {
        border: 1px solid rgba(79, 216, 216, 1);
        background-color: rgba(239, 255, 246, 1);
      }
      span {
        display: inline-block;
        margin: 50px 20px 20px 0;
      }
      /deep/ .el-input__inner {
        width: 518px;
        height: 38px;
      }
    }
    .el-button {
      position: absolute;
      top: 16px;
      right: 22px;
      padding: 0 10px;
      height: 32px;
      text-align: center;
      background-color: rgba(0,199,199, 1);
      border: 1px solid rgba(0,199,199, 1);
      line-height: 0;
      color: #fff;
      i {
        font-size: 12px;
        margin: 0 0 0 7px;
      }
    }
    .el-button:hover {
      background-color: rgba(0,199,199, .5);
      border: 1px solid rgba(0,199,199, .5);
    }
  }
}
</style>
