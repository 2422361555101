import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/home.vue'
import Index from './components/index.vue'
// -------------------------------------------------------------------------------------
// 登录
import Login from './components/login.vue'
// 消息中心
import ManageCenter from './components/personalCenter/manageCenter.vue'
// 消息管理
import ManageManage from './components/personalCenter/manageManage.vue'
// 个人中心
import PersonalCenter from './components/personalCenter/personalCenter.vue'
// 我的待办
import MyWait from './components/myWait.vue'

// 供应商
// 供应商列表
import SupplierManage from './components/supplierManage/supplierManage.vue'
// 待我确认供应商列表
import WaitMySupplierManage from './components/supplierManage/waitMySupplierManage.vue'
// 供应商详情
import SupplierDetail from './components/supplierManage/supplierDetail.vue'
// 新增供应商
import AddSupplier from './components/supplierManage/addSupplier.vue'
// 新增客户(先选择供应商)
import BeforeAddSupplier from './components/supplierManage/beforeAddSupplier.vue'
// 修改供应商
import EditSupplier from './components/supplierManage/editSupplier.vue'

// 客户
// 客户列表
import CustomManage from './components/customManage/customManage.vue'
// 待我确认客户列表
import WaitMyCustomManage from './components/customManage/waitMyCustomManage.vue'
// 客户详情
import CustomDetail from './components/customManage/customDetail.vue'
// 新增客户
import AddCustom from './components/customManage/addCustom.vue'
// 新增客户(先选择客户)
import BeforeAddCustom from './components/customManage/beforeAddCustom.vue'
// 修改客户
import EditCustom from './components/customManage/editCustom.vue'

// 采购计划单
// 采购计划单列表
import PurchasePlanManage from './components/purchasePlan/purchasePlanManage.vue'
// 采购计划详情
import PurchasePlanDetail from './components/purchasePlan/purchasePlanDetail.vue'
// 新增采购计划
import AddPurchasePlan from './components/purchasePlan/addPurchasePlan.vue'
// 修改采购计划
import EditPurchasePlan from './components/purchasePlan/editPurchasePlan.vue'

// ------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------王逸start
// 采购单
// 采购单列表
import PurchaseOrder from './components/purchase/purchaseOrder.vue'
// 采购单详情
import PurchaseOrderDetail from './components/purchase/purchaseOrderDetail.vue'
// 新增采购单
import AddPurchaseOrder from './components/purchase/addPurchaseOrder.vue'
// 修改采购单
import EditPurchaseOrder from './components/purchase/editPurchaseOrder.vue'
// 收货检验单
import receiptCheck from './components/purchase/receiptCheck.vue'
// 收货单详情
import receipCheckDetail from './components/purchase/receipCheckDetail.vue'

// 采购询价
// 询价采购列表
import AskPricePurchase from './components/purchasePrice/askPrice/askPricePurchase.vue'
// 询价采购详情
import AskPricePurchaseDetail from './components/purchasePrice/askPrice/askPricePurchaseDetail.vue'
// 发布询价
import AddAskPrice from './components/purchasePrice/askPrice/addAskPrice.vue'
// 询价草稿箱
import AskPriceDraft from './components/purchasePrice/askPrice/askPriceDraft.vue'
// 修改询价
import EditAskPrice from './components/purchasePrice/askPrice/editAskPrice.vue'

// 采购竞价
// 竞价采购列表
import CompetePricePurchase from './components/purchasePrice/competePrice/competePricePurchase.vue'
// 询价采购详情
import CompetePricePurchaseDetail from './components/purchasePrice/competePrice/competePricePurchaseDetail.vue'
// 发布询价
import AddCompetePrice from './components/purchasePrice/competePrice/addCompetePrice.vue'
// 竞价草稿箱
import CompetePriceDraft from './components/purchasePrice/competePrice/competePriceDraft.vue'
// 修改竞价
import EditCompetePrice from './components/purchasePrice/competePrice/editCompetePrice.vue'

// 销售单
// 销售单列表
import SellerOrder from './components/seller/sellerOrder/sellerOrder.vue'
// 销售单详情
import SellerOrderDetail from './components/seller/sellerOrder/sellerOrderDetail.vue'
// 新增销售单
import AddSellerOrder from './components/seller/sellerOrder/addSellerOrder.vue'
// 修改销售单
import EditSellerOrder from './components/seller/sellerOrder/editSellerOrder.vue'
// 关联订单
import RelateOrder from './components/seller/sellerOrder/relateOrder.vue'
// 关联订单
import RelateOrder2 from './components/purchase/relateOrder2.vue'

// 产品
// 产品分类
import MatterCategory from './components/matterManage/matterCategory.vue'
// 产品管理列表
import MatterManage from './components/matterManage/matterManage.vue'
// 产品管理详情
import MatterDetail from './components/matterManage/matterDetail.vue'
// 新增产品
import AddMatter from './components/matterManage/addMatter.vue'
// 修改产品
import EditMatter from './components/matterManage/editMatter.vue'

// 组织架构
// 成员与部门
import MemberAndClass from './components/organization/memberAndClass.vue'
// 部门管理
import ClassManage from './components/organization/classManage.vue'

// 库存
// 入库列表
import EntryManage from './components/warehouse/entryManage.vue'
// 入库详情
import EntryDetail from './components/warehouse/entryDetail.vue'
// 采购单入库
import PurchaseEntry from './components/warehouse/purchaseEntry.vue'
// 自选产品入库
import ChooseEntry from './components/warehouse/chooseEntry.vue'

// 出库列表
import OutManage from './components/warehouse/outManage.vue'
// 出库详情
import OutDetail from './components/warehouse/outDetail.vue'
// 新增出库单
import AddOutbound from './components/warehouse/addOutbound.vue'
// 库存管理
import AllocationManage from './components/warehouse/allocationManage.vue'
// 详情
import detailAllocationManage from './components/warehouse/detailAllocationManage.vue'
// 质量
// 采购退货单列表
import PurchaseReturn from './components/quality/purchaseReturn/purchaseReturn.vue'
// 新增采购退货单
import AddPurchaseReturn from './components/quality/purchaseReturn/addPurchaseReturn.vue'
// 修改采购退货单
import EditPurchaseReturn from './components/quality/purchaseReturn/editPurchaseReturn.vue'

// 销售
// 销售退货单列表
import SellerReturn from './components/seller/sellerReturn/sellerReturn.vue'
// 销售退货单详情
import SellerReturnDetail from './components/seller/sellerReturn/sellerReturnDetail.vue'

// 采购询价(供应端)
// 询价采购(供应端)
import AskPriceSell from './components/sellPrice/askPriceSell.vue'
// 询价采购详情(供应端)
import AskPriceSellDetail from './components/sellPrice/askPriceSellDetail.vue'
// 询价采购报价(供应端)
import AskPriceSellQuote from './components/sellPrice/askPriceSellQuote.vue'
// 竞价采购(供应端)
import CompetePriceSell from './components/sellPrice/competePriceSell.vue'
// 竞价采购(供应端)
import CompetePriceSellDetail from './components/sellPrice/competePriceSellDetail.vue'
// 竞采购报价(供应端)
import CompetePriceSellQuote from './components/sellPrice/competePriceSellQuote.vue'
// 转订单
import SwitchPurchaseOrder from './components/purchasePrice/switchPurchaseOrder.vue'

// 扣款管理(采购端)
import ChargeBackManage from './components/quality/chargeBack/chargeBackManage.vue'
// 扣款详情
import ChargeBackDetail from './components/quality/chargeBack/chargeBackDetail.vue'
// 新增扣款
import AddChargeBack from './components/quality/chargeBack/addChargeBack.vue'
// 修改扣款
import EditChargeBack from './components/quality/chargeBack/editChargeBack.vue'

// 扣款管理(供应端)
import SellChargeBackManage from './components/quality/sellChargeBack/sellChargeBackManage.vue'
// 扣款详情
import SellChargeBackDetail from './components/quality/sellChargeBack/sellChargeBackDetail.vue'

// 预付款管理
import PrePaymentManage from './components/quality/prePayment/prePaymentManage.vue'
// 预付款详情
import PrePaymentDetail from './components/quality/prePayment/prePaymentDetail.vue'
// 新增预付款
import AddPrePayment from './components/quality/prePayment/addPrePayment.vue'
// 修改预付款
import EditPrePayment from './components/quality/prePayment/editPrePayment.vue'

// 预收款管理
import SellPrePaymentManage from './components/quality/sellPrePayment/sellPrePaymentManage.vue'
// 预收款详情
import SellPrePaymentDetail from './components/quality/sellPrePayment/sellPrePaymentDetail.vue'

// 对账单列表
import BalanceAccount from './components/quality/balanceAccount/balanceAccount.vue'
// 对账单详情
import BalanceAccountDetail from './components/quality/balanceAccount/balanceAccountDetail.vue'
// 新增对账单
import AddBalanceAccount from './components/quality/balanceAccount/addBalanceAccount.vue'
// 修改对账单
import EditBalanceAccount from './components/quality/balanceAccount/editBalanceAccount.vue'

// 付款单列表
import PaymentManage from './components/quality/payment/paymentManage.vue'
// 付款单详情
import PaymentDetail from './components/quality/payment/paymentDetail.vue'
// 修改付款单
import EditPayment from './components/quality/payment/editPayment.vue'

// 采购组管理
import PurchaseManage from './components/quality/systemManage/purchaseManage.vue'
// 角色管理
import RoleManage from './components/quality/systemManage/roleManage.vue'
// 修改角色管理
import RoleManageEdit from './components/quality/systemManage/roleManageEdit.vue'
// 用户管理
import UserManage from './components/quality/systemManage/userManage.vue'
// 仓库权限
import WarehousePermission from './components/quality/systemManage/warehousePermission.vue'

// 系统
// 审批流程管理
import ApprovalManage from './components/ApprovalProcessManage/approvalManage.vue'
import ApprovalManageDispose from './components/ApprovalProcessManage/approvalManageDispose.vue'

// 企业资料
import DataUphold from './components/system/dataUphold.vue'

// 仓库
// 仓储管理 仓库资料
import StoreHouseData from './components/storageManage/storeHouseData.vue'
// 仓储管理 初始化仓库
import InitializeStoreHouse from './components/storageManage/initializeStoreHouse.vue'
// 仓储管理 仓库详情
import StoreHouseDetail from './components/storageManage/storeHouseDetail.vue'
// 设置
// 配置模块
import ConfigurationModule from './components/settingPage/ConfigurationModule.vue'
// 对账
// 发货单管理
import dispatchList from './components/Reconciliation/dispatchList.vue'
// 新增发货单
import addDispatchList from './components/Reconciliation/addDispatchList.vue'
// 新增补货单
import replenishment from './components/Reconciliation/replenishment.vue'
// 发货单修改
import updateDispath from './components/Reconciliation/updateDispath.vue'
// 发货单详情
import dispatchDetail from './components/Reconciliation/dispatchDetail.vue'
// 收款单管理
import CollectionManagement from './components/quality/balanceAccount/CollectionManagement.vue'
import CollectionManagementDetail from './components/quality/balanceAccount/CollectionManagementDetail.vue'
// 收货单详情
import receiptCheckUpdate from './components/purchase/receiptCheckUpdate.vue'
// 对账单（供应）
import reconciliation from './components/purchase/reconciliation.vue'
// 对账单详情（供应）
import reconciliationDetail from './components/purchase/reconciliationDetail.vue'
// 交付地址维护
import sitePage from './components/settingPage/sitePage.vue'
// 销售明细
import salesDetail from './components/Reconciliation/salesDetail.vue'
import salesAmount from './components/Reconciliation/salesAmount.vue'
import salesSummary from './components/Reconciliation/salesSummary.vue'
// 打印模版
import printDemo from './components/Reconciliation/printDemo.vue'
// 打印
import printPreview from './components/Reconciliation/printPreview.vue'
// 采购端线下采购单
import offinePurchasing from './components/offinePurchasing/offinePurchasing.vue'
// 新增采购单
import addoffinePurchasing from './components/offinePurchasing/addoffinePurchasing.vue'
import detailOffinePuichesa from './components/offinePurchasing/detailOffinePuichesa.vue'
import updateOffinePuichase from './components/offinePurchasing/updateOffinePuichase.vue'
// 线下供应商
import offineSupplier from './components/offineSupplier/offineSupplier.vue'
import addOffineSupplier from './components/offineSupplier/addOffineSupplier.vue'
import detailOffineSupplier from './components/offineSupplier/detailOffineSupplier.vue'
// 线下客户
import offineCustom from './components/offineCustom/offineCustom.vue'
import addoffineCustom from './components/offineCustom/addoffineCustom.vue'
import detailoffineCustom from './components/offineCustom/detailoffineCustom.vue'
// 价格协议
import Priceagreement from './components/Priceagreement/Priceagreement.vue'
import addPriceagreement from './components/Priceagreement/addPriceagreement.vue'
import detailPriceagreement from './components/Priceagreement/detailPriceagreement.vue'
// 线下销售单
import OnlinesellerOrder from './components/OnlinesellerOrder/OnlinesellerOrder.vue'
import onlineSellerAdd from './components/OnlinesellerOrder/onlineSellerAdd.vue'
import onlineSellerDetail from './components/OnlinesellerOrder/onlineSellerDetail.vue'
import onlineSellerUpdate from './components/OnlinesellerOrder/onlineSellerUpdate.vue'
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    // 主页
    {
      path: '/home',
      name: 'Home',
      component: Home,
      redirect: '/index',
      children: [
        {
          path: '/index',
          name: 'index',
          component: Index
        },
        // 个人中心
        {
          path: '/personalCenter',
          name: 'personalCenter',
          component: PersonalCenter
        },
        // 消息中心
        {
          path: '/manageCenter',
          name: 'manageCenter',
          component: ManageCenter
        },
        // 消息管理
        {
          path: '/manageManage',
          name: 'manageManage',
          component: ManageManage
        },
        // 我的待办
        {
          path: '/myWait',
          name: 'myWait',
          component: MyWait
        },
        // ----------------------------王逸start
        // 产品
        // 1.产品分类
        {
          path: '/matterCategory',
          name: 'matterCategory',
          component: MatterCategory,
          meta: { keepAlive: true }
        },
        // 1.产品详情
        {
          path: '/matterDetail',
          name: 'matterDetail',
          component: MatterDetail,
          meta: { keepAlive: true }
        },
        // 2.产品列表
        {
          path: '/matterManage',
          name: 'matterManage',
          component: MatterManage,
          meta: { keepAlive: true }
        },
        // 3.新增产品
        {
          path: '/addMatter',
          name: 'addMatter',
          component: AddMatter,
          meta: { keepAlive: true }
        },
        // 3.修改产品
        {
          path: '/editMatter',
          name: 'editMatter',
          component: EditMatter,
          meta: { keepAlive: true }
        },
        // 库存
        // 入库单列表
        {
          path: '/entryManage',
          name: 'entryManage',
          component: EntryManage,
          meta: { keepAlive: true }
        },
        // 入库单详情
        {
          path: '/entryDetail',
          name: 'entryDetail',
          component: EntryDetail,
          meta: { keepAlive: true }
        },
        // 采购单入库
        {
          path: '/purchaseEntry',
          name: 'purchaseEntry',
          component: PurchaseEntry,
          meta: { keepAlive: true }
        },
        // 自选产品入库
        {
          path: '/chooseEntry',
          name: 'chooseEntry',
          component: ChooseEntry,
          meta: { keepAlive: true }
        },
        // 出库单列表
        {
          path: '/outManage',
          name: 'outManage',
          component: OutManage,
          meta: { keepAlive: true }
        },
        // 出库单详情
        {
          path: '/outDetail',
          name: 'outDetail',
          component: OutDetail,
          meta: { keepAlive: true }
        },
        // 新增出库单
        {
          path: '/addOutbound',
          name: 'addOutbound',
          component: AddOutbound,
          meta: { keepAlive: true }
        },
        // 库存管理
        {
          path: '/allocationManage',
          name: 'allocationManage',
          component: AllocationManage,
          meta: { keepAlive: true }
        },
        {
          path: '/detailAllocationManage',
          name: 'detailAllocationManage',
          component: detailAllocationManage,
          meta: { keepAlive: true }
        },
        // 质量--------------------
        // 采购退货单
        {
          path: '/receiptCheck',
          name: 'receiptCheck',
          component: receiptCheck,
          meta: { keepAlive: true }
        },
        // 采购退货单详情
        // receipCheckDetail
        {
          path: '/receipCheckDetail',
          name: 'receipCheckDetail',
          component: receipCheckDetail,
          meta: { keepAlive: true }
        },
        {
          path: '/purchaseReturn',
          name: 'purchaseReturn',
          component: PurchaseReturn,
          meta: { keepAlive: true }
        },
        // 新增采购退货单
        {
          path: '/addPurchaseReturn',
          name: 'addPurchaseReturn',
          component: AddPurchaseReturn,
          meta: { keepAlive: true }
        },
        // 修改采购退货单
        {
          path: '/editPurchaseReturn',
          name: 'editPurchaseReturn',
          component: EditPurchaseReturn,
          meta: { keepAlive: true }
        },
        // 销售
        // 销售退货单
        {
          path: '/sellerReturn',
          name: 'sellerReturn',
          component: SellerReturn,
          meta: { keepAlive: true }
        },
        // 销售退货单详情
        {
          path: '/sellerReturnDetail',
          name: 'sellerReturnDetail',
          component: SellerReturnDetail,
          meta: { keepAlive: true }
        },
        // 组织架构---------------
        {
          path: '/memberAndClass',
          name: 'memberAndClass',
          component: MemberAndClass,
          meta: { keepAlive: true }
        },
        {
          path: '/classManage',
          name: 'classManage',
          component: ClassManage,
          meta: { keepAlive: true }
        },
        // 扣款模块-----------------------------(采购端)
        // 扣款管理
        {
          path: '/chargeBackManage',
          name: 'chargeBackManage',
          component: ChargeBackManage,
          meta: { keepAlive: true }
        },
        // 扣款详情
        {
          path: '/chargeBackDetail',
          name: 'chargeBackDetail',
          component: ChargeBackDetail
        },
        // 新增扣款
        {
          path: '/addChargeBack',
          name: 'addChargeBack',
          component: AddChargeBack
        },
        // 修改扣款
        {
          path: '/editChargeBack',
          name: 'editChargeBack',
          component: EditChargeBack
        },
        // 扣款模块----------------------------(供应端)
        // 扣款管理
        {
          path: '/sellChargeBackManage',
          name: 'sellChargeBackManage',
          component: SellChargeBackManage,
          meta: { keepAlive: true }
        },
        // 扣款详情
        {
          path: '/sellChargeBackDetail',
          name: 'sellChargeBackDetail',
          component: SellChargeBackDetail
        },
        // -------------(采购端)
        // 预付款管理
        {
          path: '/prePaymentManage',
          name: 'prePaymentManage',
          component: PrePaymentManage,
          meta: { keepAlive: true }
        },
        // 预付款详情
        {
          path: '/prePaymentDetail',
          name: 'prePaymentDetail',
          component: PrePaymentDetail
        },
        // 新增预付款
        {
          path: '/addPrePayment',
          name: 'addPrePayment',
          component: AddPrePayment
        },
        // 修改预付款
        {
          path: '/editPrePayment',
          name: 'editPrePayment',
          component: EditPrePayment
        },
        // --------------(供应端)
        // 预收款管理
        {
          path: '/sellPrePaymentManage',
          name: 'sellPrePaymentManage',
          component: SellPrePaymentManage,
          meta: { keepAlive: true }
        },
        // 预收款详情
        {
          path: '/sellPrePaymentDetail',
          name: 'sellPrePaymentDetail',
          component: SellPrePaymentDetail
        },
        // 对账单列表
        {
          path: '/balanceAccount',
          name: 'balanceAccount',
          component: BalanceAccount,
          meta: { keepAlive: true }
        },
        // 对账单详情
        {
          path: '/balanceAccountDetail',
          name: 'balanceAccountDetail',
          component: BalanceAccountDetail
        },
        // 新增对账单
        {
          path: '/addBalanceAccount',
          name: 'addBalanceAccount',
          component: AddBalanceAccount
        },
        // 修改对账单
        {
          path: '/editBalanceAccount',
          name: 'editBalanceAccount',
          component: EditBalanceAccount
        },

        // 对账单列表
        {
          path: '/paymentManage',
          name: 'paymentManage',
          component: PaymentManage,
          meta: { keepAlive: true }
        },
        // 对账单详情
        {
          path: '/paymentDetail',
          name: 'paymentDetail',
          component: PaymentDetail
        },
        // 修改对账单
        {
          path: '/editPayment',
          name: 'editPayment',
          component: EditPayment
        },
        // 采购协同
        // 1.采购单列表
        {
          path: '/purchaseOrder',
          name: 'purchaseOrder',
          component: PurchaseOrder,
          meta: { keepAlive: true }
        },
        // 采购单详情
        {
          path: '/purchaseOrderDetail',
          name: 'purchaseOrderDetail',
          component: PurchaseOrderDetail,
          meta: { keepAlive: true }
        },
        // 新增采购单
        {
          path: '/addPurchaseOrder',
          name: 'addPurchaseOrder',
          component: AddPurchaseOrder,
          meta: { keepAlive: true }
        },
        // 修改采购单
        {
          path: '/editPurchaseOrder',
          name: 'editPurchaseOrder',
          component: EditPurchaseOrder,
          meta: { keepAlive: true }
        },
        // 询价采购
        {
          path: '/askPricePurchase',
          name: 'askPricePurchase',
          component: AskPricePurchase,
          meta: { keepAlive: true }
        },
        // 新增询价
        {
          path: '/addAskPrice',
          name: 'addAskPrice',
          component: AddAskPrice,
          meta: { keepAlive: true }
        },
        // 询价详情
        {
          path: '/askPricePurchaseDetail',
          name: 'askPricePurchaseDetail',
          component: AskPricePurchaseDetail,
          meta: { keepAlive: true }
        },
        // 询价草稿箱
        {
          path: '/askPriceDraft',
          name: 'askPriceDraft',
          component: AskPriceDraft,
          meta: { keepAlive: true }
        },
        // 修改询价
        {
          path: '/editAskPrice',
          name: 'editAskPrice',
          component: EditAskPrice,
          meta: { keepAlive: true }
        },
        // 竞价采购
        {
          path: '/competePricePurchase',
          name: 'competePricePurchase',
          component: CompetePricePurchase,
          meta: { keepAlive: true }
        },
        // 新增竞价
        {
          path: '/addCompetePrice',
          name: 'addCompetePrice',
          component: AddCompetePrice,
          meta: { keepAlive: true }
        },
        // 竞价详情
        {
          path: '/competePricePurchaseDetail',
          name: 'competePricePurchaseDetail',
          component: CompetePricePurchaseDetail,
          meta: { keepAlive: true }
        },
        // 竞价草稿箱
        {
          path: '/competePriceDraft',
          name: 'competePriceDraft',
          component: CompetePriceDraft,
          meta: { keepAlive: true }
        },
        // 修改询价
        {
          path: '/editCompetePrice',
          name: 'editCompetePrice',
          component: EditCompetePrice,
          meta: { keepAlive: true }
        },
        // 销售
        // 销售单列表
        {
          path: '/sellerOrder',
          name: 'sellerOrder',
          component: SellerOrder,
          meta: { keepAlive: true }
        },
        // 销售单详情
        {
          path: '/sellerOrderDetail',
          name: 'sellerOrderDetail',
          component: SellerOrderDetail,
          meta: { keepAlive: true }
        },
        // 新增销售单
        {
          path: '/addSellerOrder',
          name: 'addSellerOrder',
          component: AddSellerOrder,
          meta: { keepAlive: true }
        },
        // 修改销售单
        {
          path: '/editSellerOrder',
          name: 'editSellerOrder',
          component: EditSellerOrder,
          meta: { keepAlive: true }
        },
        // 关联订单(销售用)
        {
          path: '/relateOrder',
          name: 'relateOrder',
          component: RelateOrder,
          meta: { keepAlive: true }
        },
        // 关联订单(采购用)
        {
          path: '/relateOrder2',
          name: 'relateOrder2',
          component: RelateOrder2,
          meta: { keepAlive: true }
        },
        // 询价采购(供应端)
        {
          path: '/askPriceSell',
          name: 'askPriceSell',
          component: AskPriceSell,
          meta: { keepAlive: true }
        },
        // 询价采购详情(供应端)
        {
          path: '/askPriceSellDetail',
          name: 'askPriceSellDetail',
          component: AskPriceSellDetail,
          meta: { keepAlive: true }
        },
        // 询价采购报价(供应端)
        {
          path: '/askPriceSellQuote',
          name: 'askPriceSellQuote',
          component: AskPriceSellQuote,
          meta: { keepAlive: true }
        },
        // 竞价采购(供应端)
        {
          path: '/competePriceSell',
          name: 'competePriceSell',
          component: CompetePriceSell,
          meta: { keepAlive: true }
        },
        // 竞价采购详情(供应端)
        {
          path: '/competePriceSellDetail',
          name: 'competePriceSellDetail',
          component: CompetePriceSellDetail,
          meta: { keepAlive: true }
        },
        // 竞价采购详情(供应端)
        {
          path: '/competePriceSellQuote',
          name: 'competePriceSellQuote',
          component: CompetePriceSellQuote,
          meta: { keepAlive: true }
        },
        {
          path: '/switchPurchaseOrder',
          name: 'switchPurchaseOrder',
          component: SwitchPurchaseOrder,
          meta: { keepAlive: true }
        },
        // 供应商
        // 供应商列表
        {
          path: '/supplierManage',
          name: 'supplierManage',
          component: SupplierManage,
          meta: { keepAlive: true }
        },
        // 供应商列表(待我确认)
        {
          path: '/waitMySupplierManage',
          name: 'waitMySupplierManage',
          component: WaitMySupplierManage,
          meta: { keepAlive: true }
        },
        // 供应商详情
        {
          path: '/supplierDetail',
          name: 'supplierDetail',
          component: SupplierDetail,
          meta: { keepAlive: true }
        },
        // 新增供应商(选择界面)
        {
          path: '/beforeAddSupplier',
          name: 'beforeAddSupplier',
          component: BeforeAddSupplier,
          meta: { keepAlive: true }
        },
        // 新增供应商
        {
          path: '/addSupplier',
          name: 'addSupplier',
          component: AddSupplier,
          meta: { keepAlive: true }
        },
        // 修改供应商
        {
          path: '/editSupplier',
          name: 'editSupplier',
          component: EditSupplier,
          meta: { keepAlive: true }
        },
        // 客户
        // 客户列表
        {
          path: '/customManage',
          name: 'customManage',
          component: CustomManage,
          meta: { keepAlive: true }
        },
        // 客户列表(待我确认)
        {
          path: '/waitMyCustomManage',
          name: 'waitMyCustomManage',
          component: WaitMyCustomManage,
          meta: { keepAlive: true }
        },
        // 客户详情
        {
          path: '/customDetail',
          name: 'customDetail',
          component: CustomDetail,
          meta: { keepAlive: true }
        },
        // 新增客户
        {
          path: '/addCustom',
          name: 'addCustom',
          component: AddCustom,
          meta: { keepAlive: true }
        },
        // 新增客户(查询页面)
        {
          path: '/beforeAddCustom',
          name: 'beforeAddCustom',
          component: BeforeAddCustom,
          meta: { keepAlive: true }
        },
        // 修改客户
        {
          path: '/editCustom',
          name: 'editCustom',
          component: EditCustom,
          meta: { keepAlive: true }
        },
        // 采购计划
        // 采购计划列表
        {
          path: '/purchasePlanManage',
          name: 'purchasePlanManage',
          component: PurchasePlanManage,
          meta: { keepAlive: true }
        },
        // 采购计划详情
        {
          path: '/purchasePlanDetail',
          name: 'purchasePlanDetail',
          component: PurchasePlanDetail
        },
        // 新增采购计划
        {
          path: '/addPurchasePlan',
          name: 'addPurchasePlan',
          component: AddPurchasePlan,
          meta: { keepAlive: true }
        },
        // 修改采购计划
        {
          path: '/editPurchasePlan',
          name: 'editPurchasePlan',
          component: EditPurchasePlan,
          meta: { keepAlive: true }
        },

        // 采购组管理
        {
          path: '/purchaseManage',
          name: 'purchaseManage',
          component: PurchaseManage
        },
        // 角色管理
        {
          path: '/roleManage',
          name: 'roleManage',
          component: RoleManage
        },
        // 修改角色管理
        {
          path: '/roleManageEdit',
          name: 'roleManageEdit',
          component: RoleManageEdit
        },
        // 用户管理
        {
          path: '/userManage',
          name: 'userManage',
          component: UserManage
        },
        // 仓库权限
        {
          path: '/warehousePermission',
          name: 'warehousePermission',
          component: WarehousePermission
        },

        // 系统
        // 流程管理
        // 1.审核流程管理
        {
          path: '/approvalManage',
          name: 'approvalManage',
          component: ApprovalManage
        },
        // 2.审批流程配置
        {
          path: '/approvalManageDispose',
          name: 'approvalManageDispose',
          component: ApprovalManageDispose
        },
        // 企业资料
        {
          path: '/dataUphold',
          name: 'dataUphold',
          component: DataUphold
        },
        // 仓库
        // 仓储管理 仓库资料
        {
          path: '/storeHouseData',
          name: 'storeHouseData',
          component: StoreHouseData,
          meta: { keepAlive: true }
        },
        // 仓储管理 仓库详情
        {
          path: '/storeHouseDetail',
          name: 'storeHouseDetail',
          component: StoreHouseDetail
        },
        // 仓储管理 初始化仓库
        {
          path: '/initializeStoreHouse',
          name: 'initializeStoreHouse',
          component: InitializeStoreHouse
        },
        // 设置
        {
          // ConfigurationModule
          path: '/ConfigurationModule',
          name: 'ConfigurationModule',
          component: ConfigurationModule
        },
        // 对账
        // 发货单管理
        // dispatchList
        {
          // ConfigurationModule
          path: '/dispatchList',
          name: 'dispatchList',
          component: dispatchList
        },
        // 新增发货单
        {
          path: '/addDispatchList',
          name: 'addDispatchList',
          component: addDispatchList,
          meta: { keepAlive: true }
        },
        // 新增补货单
        {
          path: '/replenishment',
          name: 'replenishment',
          component: replenishment,
          meta: { keepAlive: true }
        },
        // 发货单修改
        {
          path: '/updateDispath',
          name: 'updateDispath',
          component: updateDispath,
          meta: { keepAlive: true }
        },
        // 发货单详情
        {
          path: '/dispatchDetail',
          name: 'dispatchDetail',
          component: dispatchDetail,
          meta: { keepAlive: true }
        },
        // CollectionManagement
        // 收款单管理
        {
          path: '/CollectionManagement',
          name: 'CollectionManagement',
          component: CollectionManagement,
          meta: { keepAlive: true }
        },
        {
          path: '/CollectionManagementDetail',
          name: 'CollectionManagementDetail',
          component: CollectionManagementDetail,
          meta: { keepAlive: true }
        },
        // 收货单详情
        {
          path: '/receiptCheckUpdate',
          name: 'receiptCheckUpdate',
          component: receiptCheckUpdate,
          meta: { keepAlive: true }
        },
        // 对账单（供应)
        {
          path: '/reconciliation',
          name: 'reconciliation',
          component: reconciliation,
          meta: { keepAlive: true }
        },
        // 对账单详情（供应）
        // reconciliationDetail
        {
          path: '/reconciliationDetail',
          name: 'reconciliationDetail',
          component: reconciliationDetail,
          meta: { keepAlive: true }
        },
        // 交付地址维护
        {
          path: '/sitePage',
          name: 'sitePage',
          component: sitePage,
          meta: { keepAlive: true }
        },
        // 销售明细
        {
          path: '/salesDetail',
          name: 'salesDetail',
          component: salesDetail,
          meta: { keepAlive: true }
        },
        // salesAmount
        {
          path: '/salesAmount',
          name: 'salesAmount',
          component: salesAmount,
          meta: { keepAlive: true }
        },
        {
          path: '/salesSummary',
          name: 'salesSummary',
          component: salesSummary,
          meta: { keepAlive: true }
        },
        // 打印模版 printDemo
        {
          path: '/printDemo',
          name: 'printDemo',
          component: printDemo,
          meta: { keepAlive: true }
        },
        {
          path: '/printPreview',
          name: 'printPreview',
          component: printPreview,
          meta: { keepAlive: true }
        },
        // 采购端线下采购单   offinePurchasing
        {
          path: '/offinePurchasing',
          name: 'offinePurchasing',
          component: offinePurchasing,
          meta: { keepAlive: true }
        },
        // 新增线下采购单
        {
          path: '/addoffinePurchasing',
          name: 'addoffinePurchasing',
          component: addoffinePurchasing,
          meta: { keepAlive: true }
        },
        // 详情
        {
          path: '/detailOffinePuichesa',
          name: 'detailOffinePuichesa',
          component: detailOffinePuichesa,
          meta: { keepAlive: true }
        },
        {
          path: '/updateOffinePuichase',
          name: 'updateOffinePuichase',
          component: updateOffinePuichase,
          meta: { keepAlive: true }
        },
        // 线下供应商
        {
          path: '/offineSupplier',
          name: 'offineSupplier',
          component: offineSupplier,
          meta: { keepAlive: true }
        },
        {
          path: '/addOffineSupplier',
          name: 'addOffineSupplier',
          component: addOffineSupplier,
          meta: { keepAlive: true }
        }, {
          path: '/detailOffineSupplier',
          name: 'detailOffineSupplier',
          component: detailOffineSupplier,
          meta: { keepAlive: true }
        },
        // 线下客户
        {
          path: '/offineCustom',
          name: 'offineCustom',
          component: offineCustom,
          meta: { keepAlive: true }
        },
        {
          path: '/addoffineCustom',
          name: 'addoffineCustom',
          component: addoffineCustom,
          meta: { keepAlive: true }
        }, {
          path: '/detailoffineCustom',
          name: 'detailoffineCustom',
          component: detailoffineCustom,
          meta: { keepAlive: true }
        },
        // 价格协议
        {
          path: '/Priceagreement',
          name: 'Priceagreement',
          component: Priceagreement,
          meta: { keepAlive: true }
        }, {
          path: '/addPriceagreement',
          name: 'addPriceagreement',
          component: addPriceagreement,
          meta: { keepAlive: true }
        }, {
          path: '/detailPriceagreement',
          name: 'detailPriceagreement',
          component: detailPriceagreement,
          meta: { keepAlive: true }
        },
        // 线下销售单
        {
          path: '/OnlinesellerOrder',
          name: 'OnlinesellerOrder',
          component: OnlinesellerOrder,
          meta: { keepAlive: true }
        },
        {
          path: '/onlineSellerAdd',
          name: 'onlineSellerAdd',
          component: onlineSellerAdd,
          meta: { keepAlive: true }
        },
        {
          path: '/onlineSellerDetail',
          name: 'onlineSellerDetail',
          component: onlineSellerDetail,
          meta: { keepAlive: true }
        },
        {
          path: '/onlineSellerUpdate',
          name: 'onlineSellerUpdate',
          component: onlineSellerUpdate,
          meta: { keepAlive: true }
        }
        // --------------------------------------end
      ]
    }
  ]
})
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  const Token = window.$cookies.get('token')
  const CompanyId = window.$cookies.get('companyId')
  const roleType = window.$cookies.get('roleType')
  console.log(roleType)
  if (to.path === '/login' && Token !== null && CompanyId !== null) {
    return next('/index')
  } else if (to.path !== '/login' && Token === null && CompanyId !== null) {
    return next('/login')
  } else {
    return next()
  }
})
export default router
