// 新增预付款
<template>
  <div class="addPrePayment">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/prePaymentManage' }">预付款管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">新增预付款</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="ruleForm" :model="prePaymentData" label-width="102px" :rules="rules">
      <div class="addPrePaymentContent">
        <nav>
          <h5>新增预付款</h5>
          <el-form-item label="预付款单号:" prop="name">
            <el-input disabled v-model.trim="prePaymentData.name" placeholder="系统自动生成"></el-input>
          </el-form-item>
          <el-form-item label="供应商名称:" prop="sellerId">
            <el-select @change="chooseSell" v-model="prePaymentData.sellerId" placeholder="请选择供应商" size="mini" filterable>
              <el-option v-for="(item, index) in sellList" :key="index" :label="item.name" :value="item.sellerId" :value-key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关联采购单:" prop="orderId">
            <el-select v-if="prePaymentData.sellerId === ''" disabled placeholder="请先选择供应商" size="mini"></el-select>
            <el-select v-else @change="chooseOrder()" v-model="chooseList" value-key="id" placeholder="请输入关联单号/产品名称" size="mini" filterable>
              <el-option v-for="(item, index) in purchaseOrderList" :key="index" :label="item.code" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </nav>
        <main>
          <h5>汇总明细<span>总金额: {{allPrice}}</span></h5>
          <el-table ref="tableHeight" :height="320" :data="chooseList.lineEntityList" style="width: 100%;">
            <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="number" label="产品数量" min-width="80px"  align="center">
              <template slot-scope="scope">
                <div>
                  <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.number}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productName" label="退货数量" min-width="80px" align="center">
              <template slot-scope="scope">
                <!-- <span>{{scope.row.residueNumber}}</span> -->
                <span>{{scope.row.returnNumber}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip label="单品总价" min-width="80px" align="center">
              <template slot-scope="scope">
                <div>
                  <span>{{scope.row.number * scope.row.price}}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </main>
      </div>
      <footer>
        <div class="footerBox">
          <el-form-item label="预付金额:" prop="price">
            <el-input v-model.trim="prePaymentData.price" placeholder="请输入预付金额" type="number" @input="formatNum(prePaymentData.price, index)" oninput="if(value.length > 8) value=value.slice(0, 8)"></el-input>
            <i class="priceBox">剩余金额: {{morePrice}}</i>
          </el-form-item>
          <el-form-item label="付款日期:" prop="payTime">
            <el-date-picker v-model="prePaymentData.payTime" type="datetime" placeholder="请选择付款时间" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
          </el-form-item>
          <el-form-item label="付款方式:" prop="type">
            <el-select v-model="prePaymentData.type" placeholder="请选择付款方式" clearable size="mini">
              <el-option label="现金" :value="1"></el-option>
              <el-option label="转账" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="prePaymentData.type === 2" label="银行名称:" prop="bank">
            <el-input type="text" maxlength="30" v-model.trim="prePaymentData.bank" placeholder="请输入银行名称"></el-input>
          </el-form-item>
          <el-form-item v-if="prePaymentData.type === 2" label="银行账号:" prop="bankNum">
            <el-input type="number" oninput="if(value.length > 20) value=value.slice(0, 20)" v-model="prePaymentData.bankNum" placeholder="请输入银行账号"></el-input>
          </el-form-item>
          <el-form-item style="visibility: hidden;" v-else>
            <el-input></el-input>
          </el-form-item>
        </div>
        <el-button class="lightDeepStyle" @click="addPrePayment()">提交</el-button>
        <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
      </footer>
    </el-form>
  </div>
</template>
<script>
import { sellerListApi, purchaseMatterListApi, purchaseOrderListApi, addPrePaymentApi, getMorePriceApi } from '@/api'
export default {
  data() {
    return {
      // 供应商列表
      sellList: [],
      // 产品列表
      purchaseOrderList: [],
      // 传参参数
      purchaseOrderData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        productName: '',
        approveTimeBegin: '',
        approveTimeEnd: '',
        approveStatus: 2,
        state: '0,1,3,8,9,10,11',
        stateType: '',
        sellId: '',
        ifAwait: '',
        companyType: 1
      },
      // 采购单产品列表
      purchaseMatterList: [],
      // 传参参数
      purchaseMatterData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        sellId: '',
        codeAndProductName: '',
        codeAndProductNameAndProductCode: '',
        // -1 查的是0和1的状态
        state: '0,1',
        kkstat: '',
        approveStatus: ''
      },
      // 新增物料数据
      prePaymentData: {
        name: '',
        sellerId: '',
        price: '',
        type: '',
        orderId: '',
        payTime: '',
        bank: '',
        bankNum: ''
      },
      chooseList: [],
      // -------------------
      // 校验
      rules: {
        sellerId: [
          { required: true, message: '请选择供应商!', trigger: 'blur' }
        ],
        orderId: [
          { required: true, message: '请关联采购单!', trigger: 'blur' }
        ],
        remark: [
          { min: 1, max: 15, message: '最多可输入15个字符', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '预付金额不能为空!', trigger: 'blur' }
        ],
        payTime: [
          { required: true, message: '请选择付款日期!', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择付款方式!', trigger: 'blur' }
        ]
      },
      // 仓库列表
      storeHouseList: [],
      // 单位列表
      unitUserList: [],
      // 物料分类列表
      matterClassList: [],
      // 剩余金额
      morePrice: '选择采购单后生成',
      // 总金额
      allPrice: 0
    }
  },
  created() {
    this.sellerList()
  },
  mounted() {
  },
  methods: {
    // 选择订单
    chooseOrder() {
      console.log(this.chooseList.productName, '78979798')
      this.prePaymentData.orderId = this.chooseList.id
      this.getMorePrice()
    },
    // 选择供应商
    chooseSell() {
      this.purchaseMatterData.sellId = this.prePaymentData.sellerId
      this.purchaseOrderData.sellId = this.prePaymentData.sellerId
      this.getMatterManageList()
    },
    // 获取剩余金额
    async getMorePrice() {
      const { data: res } = await getMorePriceApi({ id: '', orderId: this.prePaymentData.orderId })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.morePrice = res.data.balance
      // 同时获取总金额
      if (this.chooseList.lineEntityList.length !== 0) {
        this.allPrice = 0
        this.chooseList.lineEntityList.forEach((item, index) => {
          this.allPrice += item.price * item.number
        })
      }
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.prePaymentData.price = temp;
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await sellerListApi({ search: '', page: 1, size: 9999, roleType: 1, status: 3 })
      this.sellList = res.data.page.list
    },
    // 获取采购单列表
    async getMatterManageList() {
      const { data: res } = await purchaseOrderListApi(this.purchaseOrderData);
      if (res.code !== 0) return this.$message.error(res.msg)
      this.purchaseOrderList = res.data.data.list
    },
    // 页面跳转
    routerJump () {
      this.$router.back()
    },
    // 新增预付款
    addPrePayment() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const { data: res } = await addPrePaymentApi(this.prePaymentData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '保存成功!' })
          setTimeout(() => {
          this.$router.back()
        }, 300)
        } else {
          return false
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
.addPrePayment {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addPrePaymentContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    min-height: 830px;
    overflow-y: auto;
    nav {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 20px;
      height: 218px;
      justify-content: space-between;
      box-sizing: border-box;
      background: #fff;
      border-radius: 10px;
      color: rgba(0, 0, 0, 0.80);
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      /deep/.el-form-item {
        margin: 20px 0 0 0;
        width: 100%;
        .el-form-item__error {
          padding: 5px 0 0 0;
        }
        .el-form-item__content {
          width: calc(100% - 112px);
          .el-input {
            width: 100%;
          }
          .el-select {
            width: 100%;
          }
          .el-cascader {
            width: 100%;
          }
        }
      }
    }
    main {
      margin-top: 20px;
      padding: 20px;
      background: #fff;
      border-radius: 10px;
      overflow-y: auto;
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        span {
          float: right;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 180px;
    width: calc(100% - 150px);
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
    .footerBox {
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      /deep/.el-form-item {
        position: relative;
        margin: 10px 0;
        width: 33.3%;
        .priceBox {
          position: absolute;
          right: 10px;
        }
        .el-form-item__error {
          padding: 5px 0 0 0;
        }
        .el-form-item__content {
          width: calc(100% - 112px);
          .el-input {
            width: 100%;
          }
          .el-select {
            width: 100%;
          }
          .el-cascader {
            width: 100%;
          }
        }
      }
      /deep/.el-form-item:nth-child(5) {
        width: 66.6%;
      }
    }
  }
}
</style>