// 扣款详情
<template>
  <div class="matterDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/sellChargeBackManage' }">扣款管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">扣款详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
      <el-button v-if="chargeBackDetail.auditStatus == '0'" class="lightDeepStyle" @click="editChargeBackStatus(1)"><i class="el-icon-delete"></i>确认</el-button>
      <el-button v-if="chargeBackDetail.auditStatus == '0'" class="lightDeepStyle" @click="editChargeBackStatus(2)"><i class="el-icon-delete"></i>取消</el-button>
      <el-button v-if="chargeBackDetail.auditStatus =='2'" class="lightDeepStyle" @click="editChargeBackStatus(3)"><i class="el-icon-delete"></i>撤回</el-button>
      <el-button v-if="chargeBackDetail.auditStatus == '1' && chargeBackDetail.stauts === '0'" class="dangerStyle" @click="editChargeBackStatus(4)"><i class="el-icon-delete"></i>删除</el-button>
    </div>
    <div class="matterDetailContent">
      <header>
        <div>
          <h5>{{chargeBackDetail.name + '(' + chargeBackDetail.cgCompanyName + ')'}}<i>{{chargeBackDetail.status === '0' ? '未结算' : (chargeBackDetail.status === '2' ? '已结算': (chargeBackDetail.status === '1' ? '待结算': ''))}}</i></h5>
          <p>下单时间: <span>{{chargeBackDetail.createTime + '(' + chargeBackDetail.nickName + ')'}}</span></p>
        </div>
      </header>
      <main>
        <h6>扣款金额: {{chargeBackDetail.price}}</h6>
      </main>
      <main>
        <p>扣款理由: <span>{{chargeBackDetail.remark === '' ? '暂未填写' : chargeBackDetail.remark}}</span></p>
      </main>
      <main style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
        <p >关联数据:
           <span v-if="chargeBackDetail.type===2">{{' 销售单号-' + (code === null ? '暂无' : code)}}{{ ' 产品名称-'+ chargeBackDetail.debitNoteDetailVos[0].name}}{{ ' 要求交货时间-'+ chargeBackDetail.debitNoteDetailVos[0].arriveTime}}</span>
          <span v-if="chargeBackDetail.type===0">暂无</span>
          <span v-if="chargeBackDetail.type===1">{{' 销售单号-' + ( code=== null ? '暂无' : code)}}</span>
        </p>
      </main>
    </div>
  </div>
</template>
<script>
import { sellChargeBackDetailApi, delChargeBackApi } from '@/api'
export default {
  data() {
    return {
      id: '',
      // 获取扣款单详情
      chargeBackDetail: '',
      code: ''
    }
  },
  created() {
    this.id = JSON.parse(this.$route.query.result)
    this.getChargeBackDetail()
  },
  methods: {
    // 修改扣款单状态
    editChargeBackStatus(val) {
      let newText
      if (val === 1) {
        newText = '确认'
      } else if (val === 2) {
        newText = '取消'
      } else if (val === 3) {
        newText = '删除'
      } else if (val === 4) {
        newText = '撤回'
      }
      this.$confirm('是否' + newText + '当前扣款单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        if (val === 1) {
          // 确认
          const { data: res } = await sellConfirmChargeBackApi(this.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '确认成功!' })
        } else if (val === 2) {
          // 取消
          const { data: res } = await sellCancelChargeBackApi(this.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '取消成功!' })
        } else if (val === 3) {
          // 撤销
          const { data: res } = await sellReturnChargeBackApi(this.id)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '撤销成功!' })
        } else if (val === 4) {
          const { data: res } = await sellDelChargeBackApi([this.id])
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '删除成功!' })
        }
        this.getChargeBackDetail()
      })
    },
    // 获取扣款单详情
    async getChargeBackDetail() {
      const { data: res } = await sellChargeBackDetailApi(this.id);
      if (res.code !== 0) return this.$message.error(res.msg)
      this.chargeBackDetail = res.data
        this.code=res.data.debitNoteDetailVos[0].code
    },
    routerJump(val) {
      if (val === 1) {
        this.$router.back()
      } else {
        this.$router.push({ path: "/editChargeBack", query: { result: JSON.stringify(this.id) }})
      }
    }
  }
};
</script>
<style lang="less" scoped>
.matterDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .matterDetailContent {
    margin-top: 40px;
    header {
      margin: 20px 20px 0;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
          i {
            margin-left: 20px;
            display: inline-block;
            width: 52px;
            height: 22px;
            background: rgba(70,147,235,0.05);
            border-radius: 4px;
            border: 1px solid rgba(70,147,235,0.2);
            font-size: 12px;
            font-weight: 400;
            color: rgba(70, 147, 235, 1);
            text-align: center;
            line-height: 22px;
          }
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    main {
      margin: 2px 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      p {
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        span {
          color: rgba(0, 0, 0, 0.80);
        }
      }
    }
  }
}
</style>