// 仓储管理 仓库资料 仓库详情查看
<template>
  <div class="storeHouseDetail">
    <!-- 仓库列表 -->
    <main :style="'height:' + contentHeight + 'px'">
      <el-button class="lightDeepStyle" @click="$router.push('/storeHouseData')"><i class="iconfont icon-fanhui"></i>返 回</el-button>
      <el-table :data="matterManageList" stripe style="width: 100%">
        <el-table-column show-overflow-tooltip prop="warehouseCode" label="仓库编码" min-width="80px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="warehouseName" label="仓库名称" min-width="80px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="fullName" label="产品分类" min-width="110px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productCode" label="产品编码" min-width="90px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格/型号" min-width="120px" align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.productSpecs === '' ? '--' : scope.row.productSpecs}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column show-overflow-tooltip prop="typeName" label="类型" min-width="120px" align="center"></el-table-column> -->
        <el-table-column show-overflow-tooltip prop="productUnit" label="单位" min-width="80px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="number" label="库存数量" min-width="100px"  align="center"></el-table-column>
      </el-table>
    </main>
  </div>
</template>
<script>
import { storeHouseMatterListApi } from '@/api'
export default {
  name: 'storeHouseDetail',
  data() {
    return {
      // 主体动态高度
      contentHeight: '',
      // 初始化仓库列表
      matterManageList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 7,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      storeHouseListData: {
        page: 1,
        // 当前页条数
        size: 99999,
        status: '',
        productCode: '',
        productName: '',
        productSpecs: '',
        warehouseId: JSON.parse(this.$route.query.result).id,
        businessTypes: ''
      },
      companyId: window.$cookies.get('companyId'),
      // 仓库列表传过来的参数
      storeHouseInfo: JSON.parse(this.$route.query.result),
      // 物料分类搜索
      search: '',
      // 判断校验是否正确
      judge: false
    }
  },
  created() {
    this.getMatterManageList()
  },
  mounted() {
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.contentHeight = window.innerHeight - 105
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.contentHeight = window.innerHeight - 105
      })()
    }
  },
  methods: {
    // 获取物料管理列表
    async getMatterManageList() {
      const { data: res } = await storeHouseMatterListApi(this.storeHouseListData)
      // 获取表单数据
      this.matterManageList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
      this.matterManageList.forEach(item => {
        item.initialNum = 0
      })
    }
  }
}
</script>
<style scoped lang='less'>
.storeHouseDetail {
  padding: 20px;
  nav {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: 100%;
    height: 36px;
    background: #fff;
    border-radius: 8px;
    box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }
  section {
    margin-bottom: 26px;
    padding: 0 26px 30px;
    background: #fff;
    border-radius: 8px;
    box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    div {
      display: inline-block;
      margin-top: 12px;
      height: 34px;
      span {
        display: inline-block;
        margin-left: 10px;
        margin-right: 6px;
        width: 85px;
        height: 34px;
        line-height: 34px;
        text-align: center;
      }
      .el-input {
        display: inline-block;
        width: 149px;
        height: 34px;
      }
      .el-select {
        display: inline-block;
        width: 149px;
        height: 34px;
      }
      /deep/ .el-cascader .el-input {
        display: inline-block;
        width: 149px;
      }
    }
  }
  main {
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    .el-button {
      float: right;
      margin: 0 0 20px;
      padding: 0 10px;
      height: 26px;
      text-align: center;
      line-height: 0;
      i {
        margin: 0 7px 0 0;
        font-size: 14px;
      }
    }
    .el-table {
      margin-top: 0;
      span {
        display: inline-block;
        margin: 0 5px;
        width: 150px;
        cursor: pointer;
        a {
          margin: 0 3px;
          color: #FEB300;
        }
      }
    }
    .el-pagination {
      float: right;
      margin: 10px;
    }
    article {
      margin-top: 70px;
      width: 100%;
      p {
        margin-bottom: -1px;
        padding: 6px 20px;
        border-bottom: 1px solid #EBEEF5;
        font-size: 16px;
      }
    }
  }
  /deep/ .upload_box .el-dialog {
    width: 1100px;
    height: 600px;
    .el-dialog__body {
      height: 460px;
    }
    .el-dialog__footer .el-button {
      text-align: center;
      width: 100px;
    }
  }
}
</style>
