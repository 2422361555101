import { render, staticRenderFns } from "./purchaseOrder.vue?vue&type=template&id=910b9e20&scoped=true&"
import script from "./purchaseOrder.vue?vue&type=script&lang=js&"
export * from "./purchaseOrder.vue?vue&type=script&lang=js&"
import style0 from "./purchaseOrder.vue?vue&type=style&index=0&id=910b9e20&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "910b9e20",
  null
  
)

export default component.exports